import React, {Component} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { takeCookie } from 'redux/cookies'
import { actions as SettingsActions } from 'redux/settings'
import { authenticateToken } from 'redux/server'
import history from 'history.js'

import App from './App.jsx'

class AppContainer extends Component {

  constructor(props) {
    super(props)
    console.log(props)
    let cookie = props.takeCookie()
    if (props.authentication.accessToken) {
      this.authenticate(props.authentication.accessToken)
    } else if(cookie && cookie.token) {
      props.authenticateToken(cookie.token).catch((err) => {
        this.authenticate()
      })
    } else {
      this.authenticate()
    }
  }

  componentDidUpdate() {
    this.authenticate(this.props.authentication.accessToken)
  }

  authenticate(accessToken) {
    if(!accessToken) {
      history.push('/admin')
    }
  }

  render() {
    if(!window.AARDVARK_ACCESS) {
      return <div id='app'/>
    } else {
      return <App/>
    }
  }
}

const mapStateToProps = state => ({
  authentication: state.server
})

const mapDispatchToProps = dispatch => bindActionCreators({
  takeCookie,
  authenticateToken,
  ...SettingsActions,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer)
