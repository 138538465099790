import React, {PureComponent} from 'react'
import EventInput from 'components/Schedule/EventTab/EventInput'
import ColorInput from 'components/Schedule/BlockTab/BlockColorInput'
import {Segment, Button, Checkbox, Accordion, List, Dropdown, Label} from 'semantic-ui-react'

import "./Container.css"

export default class BlockTab extends PureComponent {

  handleRenameBlock = (oldName, changes) => {
    this.props.renameBlock(oldName, changes.name)
  }

  render() {
    let {blocksList,
    selectedBlocks,
    showSubseconds,
    goToTime,
    removeBlock,
    recolorBlock,
    createBlock,
    setBlockAnnounce,
    selectBlocks} = this.props

    let blockSegments = []
    if(blocksList) {
      blockSegments = Object.values(blocksList).map((blk) => {
        let selected = selectedBlocks.includes(blk.name)
        let occurences = blk.times.map(({start, end}) => {
          return <List.Item className='BlockOccurence noselect' key={start.toString() + end.toString()} onClick={(e) => {e.stopPropagation(); goToTime(start)}}>
            {`${start.toString({showMilliseconds: showSubseconds, noRepeats: true})} - ${end.toString({showMilliseconds: showSubseconds, noRepeats: true})}`}
          </List.Item>
        })
        return (
          <Segment key={blk.name}
            color={selected ? 'green' : undefined}
            onClick={() => {selectBlocks([blk.name], {exclusive: true})}}>
            <div className='BlockControls'>
              <Checkbox checked={selected} onClick={(e) => {e.stopPropagation(); selectBlocks([blk.name])}}/>
              <div className='BlockControlsSpacer'/>
              <Button negative icon='remove' compact onClick={(e) => {e.stopPropagation(); removeBlock(blk.name)}}/>
            </div>

            <EventInput id={blk.name}
              name='name'
              value={blk.name}
              changeEventInfo={this.handleRenameBlock}
              inputProps={{
                label: 'Name',
                title: 'Name (Must be unique)',
                fluid: true
              }}/>
            <ColorInput name={blk.name}
              value={blk.color}
              recolorBlock={recolorBlock}/>
            <Label size="large">Schedule Announce</Label>
            <Dropdown selection
              value={blk.announce}
              onChange={(e, data) => {setBlockAnnounce(blk.name, data.value)}}
              placeholder="Default"
              options={[
                {key: "default", text: "Default", value: "", title: "Announce the block if it is empty, otherwise announce its items"},
                {key: "block", text: "Block", value: "block", title: "Always announce the block instead of its items"},
                {key: "items", text: "Items", value: "items", title: "Always announce the block's items and not the block itself"}
              ]}/>
            <Accordion panels={[{key: 'occurences', title: 'Scheduled Times', content: {
              key: 'blockTimes',
              content: (
                <List divided>
                  {occurences}
                </List>
              )
            }}]}/>
          </Segment>
        )
      })
    }

    return (
      <Segment.Group>
        {blockSegments}
        <Segment>
          <Button.Group fluid>
            <Button content='New Block' positive icon="add" onClick={createBlock}/>
          </Button.Group>
        </Segment>
      </Segment.Group>
    )
  }

}
