import React, {PureComponent} from 'react'
import {Grid, Container} from 'semantic-ui-react'
import ScheduleDefaultItem from './ScheduleDefaultItem'
import moment from 'moment'

import './ScheduleDefaultsPanel.css'

function getDefaultTime(type, date) {
  let defaultTime = null
  let globalLabel = ''
  switch(type) {
    case 'endless':
      defaultTime = `${date.year()}/${date.month()}/${date.date()}`
      globalLabel = "Global"
      break
    case 'interval':
      defaultTime = date.dayOfYear()
      globalLabel = 'Cruise'
      break
    case 'yearly':
      defaultTime = `${date.month()}/${date.date()}`
      globalLabel = 'Yearly'
      break
    case 'monthly':
      defaultTime = date.date()
      globalLabel = 'Monthly'
      break
    case 'weekly':
      defaultTime = date.day()
      globalLabel = 'Weekly'
      break
    case 'daily':
      defaultTime = 'daily'
      globalLabel = ''
      break
    default:
      break
  }
  return {defaultTime, globalLabel}
}

class ScheduleDefaultsPanel extends PureComponent {

  render() {
    let {defaults,
      type,
      viewTime,
      hasSelectedFiles,
      navigateToFile,
      playingDefault,
      setDefaultItem,
      removeDefaultItem} = this.props
    let dailyDefault = null
    let overlayOne = null
    let overlayTwo = null
    let globalDefault = defaults.global

    let {defaultTime, globalLabel} = getDefaultTime(type, viewTime)

    let {defaultTime: nowTime} = getDefaultTime(type, moment())

    if((defaultTime || defaultTime === 0) && defaults[defaultTime]) {
      dailyDefault = defaults[defaultTime].daily
      overlayOne = defaults[defaultTime].overlayOne
      overlayTwo = defaults[defaultTime].overlayTwo
    }

    let currentlyPlaying = ""
    if(playingDefault && playingDefault.playingItems && playingDefault.playingItems.length) {
      if(dailyDefault && (type === "daily" || playingDefault.isToday)) {
        currentlyPlaying = "daily"
      } else if (globalDefault && (!defaults[nowTime] || !defaults[nowTime].daily)) {
        currentlyPlaying = "global"
      }
    }

    return (
      <Container>
        <Grid columns={globalLabel !== '' ? 4 : 3} textAlign='center' className='scheduleDefaultsPanel' container>
          <Grid.Row>
          {globalLabel !== '' ?
            (<Grid.Column onClick={()=>{setDefaultItem('global')}}>
              <ScheduleDefaultItem item={globalDefault}
                type='global'
                isPlayingOn={currentlyPlaying === "global" ? playingDefault.playingItems : []}
                label={`${globalLabel} Default`}
                hasSelectedFiles={hasSelectedFiles}
                navigateToFile={navigateToFile}
                setDefaultItem={setDefaultItem}
                removeDefaultItem={removeDefaultItem}/>
            </Grid.Column>) :
          ''}
          <Grid.Column onClick={()=>{setDefaultItem('daily')}}>
            <ScheduleDefaultItem item={dailyDefault}
              type='daily'
              label='Daily Default'
              isPlayingOn={currentlyPlaying === "daily" ? playingDefault.playingItems : []}
              hasSelectedFiles={hasSelectedFiles}
              navigateToFile={navigateToFile}
              setDefaultItem={setDefaultItem}
              removeDefaultItem={removeDefaultItem}/>
          </Grid.Column>
          <Grid.Column onClick={()=>{setDefaultItem('overlayOne')}}>
            <ScheduleDefaultItem item={overlayOne}
              type='overlayOne'
              label='Overlay 1'
              hasSelectedFiles={hasSelectedFiles}
              navigateToFile={navigateToFile}
              setDefaultItem={setDefaultItem}
              removeDefaultItem={removeDefaultItem}/>
          </Grid.Column>
          <Grid.Column onClick={()=>{setDefaultItem('overlayTwo')}}>
            <ScheduleDefaultItem item={overlayTwo}
              type='overlayTwo'
              label='Overlay 2'
              hasSelectedFiles={hasSelectedFiles}
              navigateToFile={navigateToFile}
              setDefaultItem={setDefaultItem}
              removeDefaultItem={removeDefaultItem}/>
          </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }

}

export default ScheduleDefaultsPanel
