import {getIn, setIn} from 'helpers/general_helpers'
import {fetchFileFromServer, fetchFromServer} from 'helpers/net_helpers'
import {readScheduleFileToJSON} from 'helpers/scheduleParse'
import {dataPath} from 'helpers/library_helpers'
import {v4 as uuidv4} from 'uuid'
import moment from 'moment'
import Schedule from "helpers/Schedule/Schedule"
import ScheduleItem from "helpers/Schedule/ScheduleItem"
import ScheduleBlock from "helpers/Schedule/ScheduleBlock"
import IntervalPeriod from "helpers/IntervalTime/IntervalPeriod"
import IntervalTime from "helpers/IntervalTime/IntervalTime"
import ExactPeriod from "helpers/ExactPeriod/ExactPeriod"

/*
const PARSE_STRINGS = {
  daily:    ['h:mm a', 'h:mm:ss.SS a'],
  weekly:   ['ddd, h:mm a', 'ddd, h:mm:ss.SS a'],
  monthly:  ['D h:mm a', 'D h:mm:ss.SS a'],
  yearly:   ['YYYY M D h:mm a', 'YYYY M D h:mm:ss.SS a'],
  interval: ['DDD h:mm a', 'DDD h:mm:ss.SS a']
}
*/

export const SCHEDULE_TYPES = [
  'daily',
  'weekly',
  'monthly',
  'yearly',
  'interval',
  'endless'
]

/**
 * Loads a schedule file from the given path, and then converts it into JSON
 * @param {string} path The path to load the file from
 * @returns A promise that resolves with the schedule as an object on success
 */
export const loadScheduleFromPath = async (path) => {
  let scheduleRequest = await fetchFileFromServer(path)
  if(scheduleRequest.ok) {
    let schedule = await scheduleRequest.text()
    return readScheduleFileToJSON(schedule)
  } else {
    if(scheduleRequest.status === 404) {
      let err = new Error(`No schedule file found at ${path}`)
      err.type = "ENOENT"
      throw err
    } else {
      throw new Error(`Error loading schedule from ${path}`)
    }
  }
}

/**
 * Parses json schedule data asynchronously and returns an object containing schedule data for use as store state
 */
export const parseScheduleData = async (data) => {
  let toReturn = new Schedule(data);
  toReturn.schedule = await getItemMetadata(toReturn.schedule);
  toReturn.scheduleMissingFiles = await checkScheduleFilesExist(toReturn.schedule);
  return toReturn
}

/*
const convertJSONDefaultsToInternal = (data, scheduleType) => {
  let toReturn = {}
  let defaults = data[getDefaultsSectionName(scheduleType)]
  Object.entries(defaults).forEach(([key, value]) => {
    let [type, indicator] = key.split(', ')
    // Daily default
    if(!indicator) {
      indicator = type
      type = 'daily'
    }
    let defaultSection = parseJSONDefaultIndicator(indicator, scheduleType)
    let [defaultKey, defaultValue] = parseJSONDefaultEntry(type, value)
    // Just in case we run into a default entry that is bad (no value, for example)
    if(!defaultKey || !defaultValue) {
      return
    }
    if(!toReturn[defaultSection]) {
      toReturn[defaultSection] = {}
    }
    if(!toReturn[defaultSection][defaultKey]) {
      toReturn[defaultSection][defaultKey] = {}
    }
    toReturn[defaultSection][defaultKey] = {
      ...toReturn[defaultSection][defaultKey],
      ...defaultValue
    }
  })
  let globalDefault = data['global default']
  if(globalDefault) {
    toReturn.global = {
      originalItem: globalDefault,
      label: globalDefault.split('/').pop()
    }
  }
  return toReturn
}

const parseJSONDefaultIndicator = (indicator, scheduleType) => {
  switch(scheduleType) {
    case 'daily': {
      return 'daily'
    }
    case 'weekly': {
      return DAYS_OF_THE_WEEK.indexOf(indicator);
    }
    case 'monthly': {
      let match = /dom(\d+)/.exec(indicator)
      if(!match || match.length !== 2) {
        throw new Error(`Tried parsing ${indicator} as a monthly indicator, but it was not formatted correctly`)
      }
      return parseInt(match[1], 10);
    }
    case 'yearly': {
      let match = /m(\d+)d(\d+)/.exec(indicator)
      if(!match || match.length !== 3) {
        throw new Error(`Tried parsing ${indicator} as a yearly indicator, but it was not formatted correctly`)
      }
      let month = parseInt(match[1], 10) - 1
      let day = parseInt(match[2], 10)
      return `${month}/${day}`;
    }
    case 'interval': {
      let match = /doi(\d+)/.exec(indicator)
      if(!match || match.length !== 2) {
        throw new Error(`Tried parsing ${indicator} as an interval indicator, but it was not formatted correctly`)
      }
      return parseInt(match[1], 10);
    }
    default:
      throw new Error(`Unknown schedule type: ${scheduleType}`)
  }
}

const parseJSONDefaultEntry = (type, value) => {
  switch(type) {
    case 'daily':
      return ['daily', {originalItem: value, label: value.split('/').pop()}]
    case 'section':
      return ['daily', {duration: value['item duration'], in: value.in, out: value.out}]
    case 'station logo':
      if(typeof value === 'string') {
        value = {item: value}
      }
      if(!value.item) {
        return []
      }
      return ['overlayOne', {originalItem: value.item, label: value.item.split('/').pop(), duration: value['item duration'], in: value.in, out: value.out}]
    case 'coming up next':
      if(typeof value === 'string') {
        value = {item: value}
      }
      if(!value.item) {
        return []
      }
      return ['overlayTwo', {originalItem: value.item, label: value.item.split('/').pop(), duration: value['item duration'], in: value.in, out: value.out}]
    default:
      throw new Error(`Unknown entry type ${type} with value ${JSON.parse(value)}`)
  }
}
*/

/**
 * Checks whether a given time falls within a given time slot in a schedule
 * @param {object} time A moment.js object
 * @param {object} span The object containing the start and end moments of a time slot
 * @param {string} type The type of the schedule
 * @returns Boolean indicating whether time is within span on the schedule
 */
export const checkTimeInSlot = (time, span, type) => {
  if(!isOnScheduleDay({span}, time, type)) {
    return false
  }
  time = moment({hour: time.hour(), minute: time.minute(), second: time.second(), millisecond: time.millisecond()})
  let start = moment({hour: span.start.hour(), minute: span.start.minute(), second: span.start.second(), millisecond: span.start.millisecond()})
  let end = moment({hour: span.end.hour(), minute: span.end.minute(), second: span.end.second(), millisecond: span.end.millisecond()})
  // span wraps from one day to another
  if(start.isAfter(end)) {
    return (time.isSameOrBefore(end) || time.isSameOrAfter(start))
  } else {
    return time.isBetween(start, end, null, '[]')
  }
}

/**
 * Checks whether a given item is on a given schedule day, based on the schedule type
 * @param {object} item A schedule item/block to check
 * @param {object} time A moment.js object representing the schedule day to check
 * @param {string} type The type of schedule
 * @returns A boolean indicating whether the item falls on the given schedule day or not
 */
export const isOnScheduleDay = (item, time, type) => {
  let {start, end} = item.span
  switch(type) {
    case 'daily':
      return true
    case 'weekly':
      return (
        start.day() === time.day() ||
        (start.day() <= end.day() && end.day() === time.day())
      )
    case 'monthly':
      return (
        start.date() === time.date() ||
        (start.date() <= end.date() && end.date() === time.date())
      )
    case 'yearly':
    case 'interval':
      return (
        (start.month() === time.month() && start.date() === time.date()) ||
        (
          (start.month() < end.month() ||
          (start.month() === end.month() && start.date() <= end.date())) &&
          (end.month() === time.month() && end.date() === time.date())
        )
      )
    case 'endless':
      return (
        item.span.start.clone().startOf("day").valueOf() === time.clone().startOf("day").valueOf() ||
        item.span.end.clone().startOf("day").valueOf() === time.clone().startOf("day").valueOf()
      )
    default:
      return false
  }
}

/**
* Takes time in milliseconds and returns timestring.
* @param {number} time Time in unix time.
* @param {boolean} meridian Whether to format as a 12 hour time or as a duration. If true, time is returned in format h:mm:ss.SS a.
* if false, time is returned in format H:mm:ss with H being any number of hours
* @param {boolean} subseconds Whether to show milliseconds or not
* @returns A time string with optional meridian.
*/
export const convertToHourString = (time, meridian=true, subseconds=false) => {
  let hours = Math.floor(time / 3600000)
  let minutes = Math.floor((time % 3600000) / 60000)
  let seconds = Math.floor((time % 60000) / 1000)
  let milliseconds = Math.floor(time % 1000)
  let period = ''
  if(meridian) {
    period = hours < 12 ? 'am' : 'pm'
    hours = hours % 12
    if(hours === 0) { hours = 12 }
  }
  if(minutes < 10) { minutes = '0' + minutes }
  if(seconds < 10) { seconds = '0' + seconds }
  if(subseconds) {
    milliseconds = '.' + (('' + milliseconds).padStart(3, '0'))
  } else {
    milliseconds = ''
  }
  return `${hours}:${minutes}:${seconds}${milliseconds} ${period}`
}

/**
* Takes moment.js duration object and converts to time string
* @param {object} duration Moment.js duration object to be converted
* @param {boolean} subseconds Whether to show milliseconds or not
* @returns A time string
*/
export const convertDurationToHourString = (duration, subseconds=false) => {
  let hours = Math.floor(duration.asHours())
  let minutes = ('' + duration.minutes()).padStart(2, '0')
  let seconds = ('' + duration.seconds()).padStart(2, '0')
  let milliseconds = ''
  if(subseconds) {
    milliseconds = '.' + ('' + duration.milliseconds()).padStart(3, '0')
  }
  return `${hours}:${minutes}:${seconds}${milliseconds}`
}

/**
 * Parses view data from schedule data to select the initial view point of the schedule
 * @param {object} scheduleData Object containing schedule information
 * @returns View date as a moment date
 */
export const parseScheduleViewTime = (scheduleData) => {
  let type = scheduleData.type
  switch(type) {
    case 'daily':
      return moment()
    case 'interval':
      return moment().dayOfYear(scheduleData.day ? scheduleData.day : 1)
    case 'monthly':
    case 'yearly':
    case 'endless':
      return moment({
        year: scheduleData.year ? scheduleData.year : moment().year,
        month: scheduleData.month ? (scheduleData.month - 1) : moment().month,
        day: scheduleData.day ? scheduleData.day : moment().day
      })
    default:
      return moment().day(scheduleData.day ? scheduleData.day : 1)
  }
}

export const parseSchedule = (schedule, blocks, type, viewTime, timeSlotLength, intervalDuration, intervalBasis) => {
  let invalidItems = []
  // Create array of display schedule items
  let returnSched = schedule.map((item) => new ScheduleItem(item, type, intervalDuration, intervalBasis))
    .filter(item => item.valid)
  // Create array of empty display schedule blocks
  let schedBlocks = blocks.map((block) => new ScheduleBlock(block, type, intervalDuration, intervalBasis))
    .filter(block => block.valid)
  // Combine schedule and schedule blocks
  let toReturn = combineScheduleAndBlocks(returnSched, schedBlocks)
  return {schedule: toReturn, invalid: invalidItems}
}

/*
const checkForWrapAround = (start, end, scheduleType) => {
  switch(scheduleType) {
    case "daily":
      return start.hour() > end.hour()
    case "weekly":
      return start.day() > end.day()
    case "monthly":
      return start.date() > end.date()
    case "yearly":
    case "interval":
      return start.dayOfYear() > end.dayOfYear()
    default:
      return false
  }
}
*/

/**
 * Converts the view time to the required key/value view time pairs for serialization
 * @param {object} viewTime A momentjs object indicating the currently viewed time
 * @param {string} scheduleType The type of schedule being viewed
 * @returns An object CONTAINING the key/value pairs required to indicate the view time within
 *  the serialized schedule (should be merged into json schedule).
 */
export const convertViewTimeToJSON = (viewTime, scheduleType) => {
  let toReturn = {}
  switch(scheduleType) {
    case 'weekly':
      toReturn.day = viewTime.day()
      break
    case 'yearly':
    case 'endless':
      toReturn.year = viewTime.year()
      toReturn.month = viewTime.month() + 1
      toReturn.day = viewTime.date()
      break
    case 'monthly':
      toReturn.day = viewTime.date()
      toReturn.month = viewTime.month() + 1
      break
    case 'interval':
      toReturn.day = viewTime.dayOfYear()
      break
    default:
      break
  }
  return toReturn
}

/**
 *  Helper function to extract the filename of a schedule item from its full path
 *  @param {string} rawName The full path of the item
 *  @returns filename of the item as a string
 */
export const parseItemName = (rawName) => {
  if(typeof rawName === 'string') {
    rawName = rawName.split('/')
  }
  return rawName[rawName.length - 1]
}

/**
 * Adds an array of contiguous schedule items to a schedule in their proper chronological places
 * @param {array} items The items to be added to the schedule.
 * @param {array} schedule The schedule to add items to
 * @returns a copy of schedule with items added to it
 */
export const addItemsToSchedule = (items, schedule, type='', intervalDuration, intervalBasis) => {
  if(items.length === 0) {
    return schedule
  }
  if(!type && schedule.length) {
    type = schedule[0].span.period
  }
  if(type === "interval" && !intervalDuration && schedule.length) {
    intervalDuration = schedule[0].span.intervalDuration.days
  }
  if(type === "interval" && !intervalBasis && schedule.length) {
    intervalBasis = schedule[0].span.intervalBasis.basis
  }
  items = items.map((item) => {
    if(!(item.span instanceof IntervalPeriod) && item.span.start && item.span.end) {
      item.span = new IntervalPeriod(item.span.start, item.span.end, type, {days: intervalDuration, basis: intervalBasis}, {zeroOk: item.span.zeroOk})
    }
    return item
  })
  let tempSchedule = [...schedule]
  if(tempSchedule.length === 0) {
    tempSchedule.concat(items)
  }
  let firstTime = items[0].span.start
  let insertInBlock = tempSchedule.findIndex((schedItem, schedInd) => {
    return (schedItem.type === 'block' &&
      schedItem.span.contains(firstTime, {exclusiveEnd: true}))
  })
  if(insertInBlock > -1) {
    tempSchedule = setIn(tempSchedule, [insertInBlock, 'body', 'contains'], addItemsToSchedule(items, tempSchedule[insertInBlock].body.contains, type, intervalDuration, intervalBasis))
  } else {
    items.forEach((item, index) => {
      let beforeIndex = tempSchedule.findIndex((schedItem, schedInd) => {
        if(schedInd + 1 < tempSchedule.length) {
          let period = new IntervalPeriod(schedItem.span.start, tempSchedule[schedInd + 1].span.start)
          return period.contains(item.span.start, {exclusiveEnd: true})
        } else {
          return item.span.start.value() >= schedItem.span.start.value()
        }
      })
      if(beforeIndex === -1) {
        tempSchedule.unshift(item)
      } else if(beforeIndex + 1 < tempSchedule.length) {
        tempSchedule.splice(beforeIndex + 1, 0, item)
      } else {
        tempSchedule.push(item)
      }
    })
  }
  return tempSchedule
}

/**
 * Adds a schedule block to a schedule, and adds other non-block items to it.
 * @param {object} block Schedule block to be added to the schedule.
 * @param {array} schedule Schedule to add the block to
 * @returns copy of the schedule with the block added to it.
 */
export const addBlockToSchedule = (block, schedule) => {
  if(!block) {
    return schedule
  }
  let tempSchedule = [...schedule]
  if(tempSchedule.length === 0) {
    tempSchedule.concat(block)
  }
  let firstIndex = tempSchedule.findIndex((schedItem, schedInd) => {
    return block.span.start.value() < schedItem.span.end.value()
  })
  if(firstIndex === -1) {
    firstIndex = tempSchedule.length
  }
  tempSchedule.splice(firstIndex, 0, block)
  return blockMunchItems(firstIndex, tempSchedule)
}

/**
 * Have block take any non-block items it overlaps with as children
 * @param {number} index The index of the block within the schedule
 * @param {array} schedule The schedule the block belongs to
 * @returns Copy of schedule with all items overlaping with the block at index
 * removed from the base level of the schedule and added to block's children
 */
export const blockMunchItems = (index, schedule) => {
  if(!schedule[index] || schedule[index].type !== 'block') {
    return schedule
  }
  let tempSchedule = [...schedule]
  let firstIndex = tempSchedule.findIndex((schedItem, schedInd) => {
    return tempSchedule[index].span.start.value() < schedItem.span.end.value()
  })
  let afterIndex = tempSchedule.findIndex((schedItem, schedInd) => {
    return tempSchedule[index].span.end.value() <= schedItem.span.start.value()
  })
  if(firstIndex === -1) {
    firstIndex = tempSchedule.length
  }
  if(afterIndex === -1) {
    afterIndex = tempSchedule.length
  }
  let toAdd = tempSchedule.slice(firstIndex, afterIndex)
  let toRemove = []
  toAdd = toAdd.filter((item, ind) => {
    if(item.type === 'block') {
      return false
    } else {
      toRemove.push(firstIndex + ind)
      return true
    }
  })
  tempSchedule = setIn(tempSchedule, [index, 'body', 'contains'], tempSchedule[index].body.contains.concat(toAdd).sort((a, b) => a.span.start.diff(b.span.start)))
  return tempSchedule.filter((item, ind) => {
    return !(toRemove.includes(ind))
  })
}

/**
 * Expands a schedule block
 * @param {array} schedule The schedule containing the block to be expanded
 * @param {number/object} time The number of milliseconds to expand the schedule block, or a moment.js duration object indicating how much to expand the block by
 * @param {number} index The index of the block to expand within schedule.
 * @param {object} options Options for schedule block expansion
 * @param {boolean} options.backwards If true, expand start of block into the past, otherwise expand end of block into the future. Defaults to false.
 * @param {boolean} options.munch If true, block will take in any items it collides with after expanding as children. If set to false, it will push any items it collides
 * with so that they are no longer colliding. Defaults to false.
 */
export const expandBlock = (schedule, time, index, options={}) => {
  let {backwards=false, munch=false} = options
  if(!index && index !== 0) {
    return schedule
  }
  let tempSched = [...schedule]
  if(!tempSched[index] || tempSched[index].type !== 'block') {
    return schedule
  }
  if(backwards) {
    let expandToTime = tempSched[index].span.start.subtract(time)
    tempSched = alterScheduleItemTime(tempSched, index, expandToTime, null)
    if(munch) {
      tempSched = blockMunchItems(index, tempSched)
    } else {
      tempSched = shoveScheduleItem(tempSched, index, 0, true)
    }
  } else {
    let expandToTime = tempSched[index].span.end.add(time)
    tempSched = alterScheduleItemTime(tempSched, index, null, expandToTime)
    if(munch) {
      tempSched = blockMunchItems(index, tempSched)
    } else {
      tempSched = shoveScheduleItem(tempSched, index, 0, false)
    }
  }
  return tempSched
}

/**
 * Fits a schedule block to its contents
 * @param {array} schedule The schedule containing the block to be fit
 * @param {number} index The index of the schedule block within the schedule
 * @param {object} options Optional arguements
 * @param {boolean} options.dontContract Defaults to false. If set to true, then the schedule will be expanded
 *  to fit any contents that fall outside of its boundaries, but will not be contracted to remove empty space
 *  at the beginning and/or end of the block
 */
export const fitBlockToContents = (schedule, index, options={}) => {
  let {dontContract=false} = options
  if(!index && index !== 0) {
    return schedule
  }
  let tempSched = [...schedule]
  let block = tempSched[index]
  if(!block || block.type !== 'block') {
    return schedule
  }
  let firstChild = block.body.contains[0]
  let lastChild = block.body.contains[block.body.contains.length - 1]
  if(!firstChild || !lastChild) {
    return
  }
  let startMove = block.span.start.diff(firstChild.span.start)
  if(dontContract && startMove < 0) {
    startMove = 0
  }
  let endMove = lastChild.span.end.diff(block.span.end)
  if(dontContract && endMove < 0) {
    endMove = 0
  }
  if(startMove) {
    tempSched = expandBlock(tempSched, startMove, index, {backwards: true})
  }
  if(endMove) {
    tempSched = expandBlock(tempSched, endMove, index, {backwards: false})
  }
  return tempSched
}

/**
 * Replicate one or more items in a block to other blocks of the same name
 * @param {array} childIndicies Array of numerical indicies of children within block to replicate
 * @param {number} blockIndex Index of block within the schedule that contains it
 * @param {array} schedule The schedule containing the block to replicate from and blocks to replicate to
 * @param {string} direction The direction to replicate in, either 'forward', 'backward' or 'both'. Defaults to 'both'.
 * @returns A copy of schedule with the items replicated
 */
export const replicateToBlocks = (childIndicies, blockIndex, schedule, direction='both') => {
  if(!schedule[blockIndex] || schedule[blockIndex].type !== 'block') {
    return schedule
  }
  let toReplicate = childIndicies.map((ind) => {
    let item = schedule[blockIndex].body.contains[ind]
    if(!item) {
      return null
    }
    let fromStart = item.span.start.diff(schedule[blockIndex].span.start)
    return {
      item,
      fromStart
    }
  }).filter((child) => child !== null)
  let nameTo = schedule[blockIndex].body.label
  let beforeSlice = schedule.slice(0, blockIndex)
  let fromBlock = schedule.slice(blockIndex, blockIndex + 1)
  let afterSlice = schedule.slice(blockIndex + 1, schedule.length)
  if(direction === 'backward' || direction === 'both') {
    beforeSlice = beforeSlice.map((item) => {
      if(item.type === 'block' && item.body.label === nameTo) {
        let addItems = toReplicate.map((child) => {
          let childLength = child.item.span.end.diff(child.item.span.start)
          return setIn(child.item, ['span'], new IntervalPeriod(
            item.span.start.add(child.fromStart),
            item.span.start.add(child.fromStart + childLength)
          ))
        })
        return setIn(item, ['body', 'contains'], addItemsToSchedule(addItems, item.body.contains))
      } else {
        return item
      }
    })
  }
  if(direction === 'forward' || direction === 'both') {
    afterSlice = afterSlice.map((item) => {
      if(item.type === 'block' && item.body.label === nameTo) {
        let addItems = toReplicate.map((child) => {
          let childLength = child.item.span.end.diff(child.item.span.start)
          return setIn(child.item, ['span'], new IntervalPeriod(
            item.span.start.add(child.fromStart),
            item.span.start.add(child.fromStart + childLength)
          ))
        })
        return setIn(item, ['body', 'contains'], addItemsToSchedule(addItems, item.body.contains))
      } else {
        return item
      }
    })
  }
  return beforeSlice.concat(fromBlock, afterSlice)
}

/**
 * Replicate all properties of a schedule block to other blocks of the same name.
 * @param {number} blockIndex Index of block within the schedule to replicate
 * @param {array} schedule The schedule containing the block to replicate from and blocks to replicate to
 * @param {string} direction The direction to replicate in, either 'forward', 'backward' or 'both'. Defaults to 'both'.
 * @returns A copy of schedule with the blocks replicated
 */
export const copyScheduleBlock = (blockIndex, schedule, direction='both') => {
  let block = schedule[blockIndex]
  if(!block || block.type !== 'block') {
    return schedule
  }
  let blockDuration = block.span.end.diff(block.span.start)
  let blockContents = block.body.contains.map((child, childInd) => {
    return {
      item: child,
      relativeStart: child.span.start.diff(block.span.start),
      relativeEnd: child.span.end.diff(block.span.start)
    }
  })
  let tempSched = schedule.map((item, index) => {
    if(item.type === 'block' && item.body.label === block.body.label &&
     ((direction === 'both' && index !== blockIndex) ||
      (direction === 'forward' && index > blockIndex) ||
      (direction === 'backward' && index < blockIndex))
      ) {
      return {
        ...item,
        key: uuidv4(),
        body: {
          ...item.body,
          contains: blockContents.map((child) => {
            return {
              ...child.item,
              key: uuidv4(),
              span: new IntervalPeriod(
                item.span.start.add(child.relativeStart),
                item.span.start.add(child.relativeEnd),
                item.span.period
              )
            }
          })
        },
        span: new IntervalPeriod(
          item.span.start,
          item.span.start.add(blockDuration),
          item.span.period
        )
      }
    } else {
      return item
    }
  })
  return tempSched
}

/**
 * Checks a schedule for errors and returns an array of schedule errors
 * @param {array} schedule An array of schedule items or blocks
 * @param {string} type The type of schedule
 * @param {object} block Object containing time span and index of the current block if schedule is a block.
 *  If it is a block, it should not contain other blocks and other blocks encountered are a collision.
 * @returns an array of schedule error objects, which have the following properties:
 *    {number} index The index of the item/block encountering an error
 *    {string} type The type of error encountered. Will be one of the following:
 *      FRONT_END: The item's end occurs after the next item's start
 *      REAR_END: The item's start occurs before any previous item's end
 *      TOTAL: The item occurs entirely within another item
 *      FRONT_DROP_OFF: The item's end occurs after the end of the schedule/block it is in
 *      REAR_DROP_OFF: The item's start occurs before the beginning of the schedule/block it is in
 *      TOTAL_DROP_OFF: The item's start and end enclose its container's start and end
 *      RECURSIVE_BLOCK: A schedule block is inside another schedule block
 *    {number} child Index of item within its parent block, if the error occurs in a block
 */
export const validateSchedule = (schedule, type, block=null, opts={}) => {
  let {noMillis=true} = opts
  let errors = []
  schedule.forEach((item, index) => {
    if(item.type === 'empty') {
      return
    }
    // If validating a block's contents, check for schedule drop-off (see above for which drop_off type is which)
    if(block !== null) {
      let container = block.span
      let overlaps = container.overlaps(item.span, {giveOverlapType: true, containsOk: true, exclusiveStart: true, exclusiveEnd: true, noMillis})
      if(overlaps) {
        let err = {
          itemKey: item.key,
          frontSeverity: overlaps !== "BACK_OVERLAP" ?
            item.span.end.diff(container.end) :
            0,
          rearSeverity: overlaps !== "FRONT_OVERLAP" ?
            container.start.diff(item.span.start) :
            0
        }
        if(overlaps === "FRONT_OVERLAP") {
          err.type = "FRONT_DROP_OFF"
        } else if(overlaps === "BACK_OVERLAP") {
          err.type = "REAR_DROP_OFF"
        } else {
          err.type = "TOTAL_DROP_OFF"
        }
        errors.push(err)
      }
    }
    // If there are items after this one in the schedule, check for overlapping items
    let i = 1
    while(index + i < schedule.length) {
      if(schedule[index + i].type === 'empty') {
        i = i + 1
        continue
      }
      let overlaps = item.span.overlaps(schedule[index + i].span, {giveOverlapType: true, noMillis})
      if(overlaps) {
        if(overlaps === "BACK_OVERLAP") {
          errors.push({type:'REAR_END', itemKey: item.key})
          break
        } else if(overlaps === "CONTAINS" || overlaps === "CONTAINED_BY") {
          errors.push({type:'TOTAL', itemKey: item.key})
          i = i + 1
          continue
        } else {
          errors.push({type:'FRONT_END', itemKey: item.key})
          break
        }
      }
      break
    }
    i = 1
    while(index - i >= 0) {
      if(schedule[index - i].type === 'empty') {
        i = i + 1
        continue
      }
      let overlaps = item.span.overlaps(schedule[index - i].span, {giveOverlapType: true, noMillis})
      if(overlaps) {
        if(overlaps === "BACK_OVERLAP") {
          errors.push({type:'REAR_END', itemKey: item.key})
          break
        } else if(overlaps === "CONTAINS" || overlaps === "CONTAINED_BY") {
          errors.push({type:'TOTAL', itemKey: item.key})
          i = i + 1
          continue
        } else {
          errors.push({type:'FRONT_END', itemKey: item.key})
          break
        }
      }
      break
    }
    // If the item is a block and it is inside another block, mark it as a recursive block. Otherwise, validate its contents
    if(item.type === 'block') {
      if(block !== null) {
        errors.push({type:'RECURSIVE_BLOCK', itemKey: item.key})
      } else {
        let errorsInBlock = validateSchedule(item.body.contains, 'block', {span: item.span, index}, opts)
        errors.push(...errorsInBlock)
      }
    }
  })
  return errors
}

/**
 * Checks whether the files in the schedule exist or not, and if not, adds errors to the items corresponding to non-existant files
 * @param {array} schedule The schedule to check for file existence within
 * @returns An array of missing file errors
 */
export const checkScheduleFilesExist = async (schedule) => {
  let errors = []
  let toCheck = getScheduleItemsToCheck(schedule);
  try {
    let res = await fetchFromServer("/v2/files/identify", {
      method: "POST",
      body: toCheck.join("\n")
    })
    if(!res.ok) {
      let err = await res.text();
      console.error(err);
    } else {
      let fileExistence = await res.json();
      errors = validateScheduleFileExists(schedule, fileExistence);
    }
  } catch (err) {
    console.error(err)
  }
  return errors;
}

export const getItemMetadata = async (schedule) => {
  let metadata = {}
  let toCheck = getScheduleItemsToCheck(schedule);
  let metadataFetches = toCheck.map(async (item) => {
    try {
      let res = await fetchFromServer(`/v2/files/metadata/${item}`);
      if(!res.ok) {
        let err = await res.text();
        console.error(err);
      } else {
        metadata[item] = await res.json()
      }
    } catch (err) {
      console.error(err);
    }
  })
  await Promise.all(metadataFetches);
  return addMetadataToItems(schedule, metadata)
}

export const addMetadataToItems = (schedule, metadata) => {
  return schedule.map((item, index) => {
    if(item.type === "item" && metadata[item.originalItem]) {
      item = item.clone()
      item.fileMetadata = metadata[item.originalItem]
    } else if (item.type === "block") {
      let blockContains = addMetadataToItems(item.body.contains, metadata)
      item = item.clone()
      item.body.contains = blockContains
    }
    return item
  })
}

export const getScheduleItemsToCheck = (schedule, skipCheck=[]) => {
  let toCheck = []
  for(let item of schedule) {
    if(item.type === "item" && !(toCheck.includes(item.originalItem) || skipCheck.includes(item.originalItem))) {
      toCheck.push(item.originalItem)
    } else if (item.type === "block") {
      let toCheckInBlock = getScheduleItemsToCheck(item.body.contains, toCheck);
      toCheck = toCheck.concat(toCheckInBlock)
    }
  }
  return toCheck
}

export const validateScheduleFileExists = (schedule, fileExistence, block=null) => {
  let errors = []
  schedule.forEach((item, index) => {
    if(item.type === "item" && !fileExistence[item.originalItem]) {
      errors.push({type:'FILE_DOES_NOT_EXIST', itemKey: item.key})
    } else if (item.type === "block") {
      let blockErrors = validateScheduleFileExists(item.body.contains, fileExistence, index)
      errors = errors.concat(blockErrors)
    }
  })
  return errors
}

export const updateEvents = (schedule, eventsList, type) => {
  return schedule.map((item) => {
    if(item.type === 'block') {
      return setIn(item, ['body', 'contains'], updateEvents(item.body.contains, eventsList, type))
    } else if (item.type === 'event') {
      let match = eventsList[item.guid]
      if(match) {
        let end = item.span.start.add(Math.floor(match.duration.asMilliseconds()))
        return {
          ...item,
          body: {
            ...item.body,
            label: match.name
          },
          span: new IntervalPeriod(item.span.start, end, type, undefined, {zeroOk: true}),
          originalItem: `/mnt/main/Events/Play/${match.name}`,
          "error status": match.assigned ? "" : "ENOENT"
        }
      } else {
        return null
      }
    }
    return item
  }).filter((item) => item !== null)
}

export const convertDurationString = (duration) => {
  let durationObj = moment(duration, ['m:ss.SSS', 'h:mm:ss.SSS', 'm:ss', 'h:mm:ss'])
  if(!durationObj.isValid()) {
    throw new Error('INVALID_DURATION')
  } else {
    let {hours, minutes, seconds, milliseconds} = durationObj.toObject()
    return moment.duration({hours, minutes, seconds, milliseconds})
  }
}

/**
 * Determines whether two selection objects are selecting the same schedule item
 * @param {object} selectA First selection object
 * @param {object} selectB Second selection object
 * @returns true if the two selection objects select the same schedule item. Otherwise, false
 */
export const compareSelectionObjects = (selectA, selectB) => {
  return selectA.index === selectB.index &&
    selectA.child === selectB.child
}

/**
 * Returns a copy of a schedule item, only with its times displaced. Schedule blocks will move their children as well.
 * @param {object} item An item to be moved
 * @param {object} time A moment object indicating the time to move it to.
 * @param {boolean} end Whether to move the item so that it starts at time (false) or ends at time (true). Defaults to false.
 * @returns a copy of item with its start or end moved to time, with duration and relative times/durations of children being preserved
 */
export const moveScheduleItem = (item, time, end=false) => {
  item = {...item}
  let duration = item.span.duration()
  if(item.lock) {
    return item
  }
  if(end) {
    if(item.type === 'block') {
      let blockMovement = item.span.end.diff(time)
      item = setIn(item, ['body', 'contains'], item.body.contains.map((child) => {
        return {
          ...child,
          span: child.span.subtract(blockMovement)
        }
      }))
    }
    item.span = new IntervalPeriod(time.subtract(duration), time, item.span.period)
    return item
  } else {
    if(item.type === 'block') {
      let blockMovement = time.diff(item.span.start)
      item = setIn(item, ['body', 'contains'], item.body.contains.map((child) => {
        return {
          ...child,
          span: child.span.add(blockMovement),
        }
      }))
    }
    item.span = new IntervalPeriod(time, time.add(duration), item.span.period)
    return item
  }
}

/**
 * Shoves a schedule item a certain amount of time, shoving other items as well to attempt to have no collisions.
 * @param {array} schedule The schedule to shove the item within
 * @param {number} index The index of the item to be shoved within the schedule
 * @param {number} time The number of milliseconds to shove the item.
 * @param {boolean} backwards Whether to shove the item into the future (false) or into the past (true). Defaults to false.
 * @returns The schedule with the appropriate changes made. The function will attempt to generate no errors, but it may shove one or more items
 * past the end of a schedule. The returned schedule should be checked for possible schedule drop offs.
 */
export const shoveScheduleItem = (schedule, index, time, backwards=false) => {
  // Check to make sure item exists within schedule
  if(!schedule[index] || (time > 0 && schedule[index].lock)) {
    return schedule
  }
  // 1. Copy schedule. Work only on copy
  let tempSched = [...schedule]
  // 2. Identify the item to be pushed, add its index to a toPush array
  let toPush = [index]
  // 3. Check for any collisions with items that are not in toPush between the start (or end if backwards) of the first item in toPush and
  //  the end (or start if backwards) of an item block of duration equal to the sum of the durations of all items in toPush at time
  //  milliseconds in a direction indicated by backwards.
  // 4. If a colliding item is detected, add it to toPush and repeat from 3.
  if(backwards) {
    let i = index - 1
    let end = tempSched[toPush[0]].span.end
    while(i >= 0) {
      if(tempSched[i].lock) {
        let err = new Error('Encountered a locked item while pushing, so nothing was pushed.')
        err.type = 'ERR_LOCKED'
        throw err
      }
      let duration = toPush.reduce((accumulator, item) => {
        return accumulator + tempSched[item].span.duration()
      }, 0)
      let start = end.subtract(duration).subtract(time)
      if(new IntervalPeriod(start, end).contains(tempSched[i].span.end, {exclusiveStart: true})) {
        toPush.push(i)
        i = i - 1
      } else {
        break
      }
    }
  } else {
    let i = index + 1
    let start = tempSched[toPush[0]].span.start
    while(i < tempSched.length) {
      if(tempSched[i].lock) {
        let err = new Error('Encountered a locked item while pushing, so nothing was pushed.')
        err.type = 'ERR_LOCKED'
        throw err
      }
      let duration = toPush.reduce((accumulator, item) => {
        return accumulator + tempSched[item].span.duration()
      }, 0)
      let end = start.add(duration).add(time)
      if(new IntervalPeriod(start, end).contains(tempSched[i].span.start, {exclusiveEnd: true})) {
        toPush.push(i)
        i = i + 1
      } else {
        break
      }
    }
  }
  // 5. Once no collisions are detected, set currentTime variable to the start of the block at its new position.
  let currentTime = backwards ?
    tempSched[toPush[0]].span.end.subtract(time) :
    tempSched[toPush[0]].span.start.add(time)
  // 6. Iterate through toPush and move each item:
  toPush.forEach((schedInd) => {
    let item = moveScheduleItem({...tempSched[schedInd]}, currentTime, backwards)
    currentTime = backwards ? item.span.start : item.span.end
    tempSched[schedInd] = item
  })
  // 7. Return copy of schedule
  let toReturn = tempSched.sort((a, b) =>
    backwards ?
    a.span.end.diff(b.span.end) :
    a.span.start.diff(b.span.start)
  )
  return toReturn
}

/**
 * Closes empty gaps in a schedule by pulling all items within a range by pulling all items around a given anchor
 * @param {array} schedule The schedule to operate on
 * @param {number} start The index of the first item in the range to close
 * @param {number} anchor The index of the item to be used as an anchor (this item will not move, other items will be pulled to it).
 *  Anchor must be between start and end.
 * @param {number} end The index of the last item in the range to close (This item is included)
 * @returns The same schedule with items from start index and end index pulled into a contiguous block around the item at anchor index.
 */
export const closeScheduleGap = (schedule, start, anchor, end) => {
  if(!(start <= anchor && anchor <= end) ||
    !schedule[start] ||
    !schedule[anchor] ||
    !schedule[end]) {
    return schedule
  }

  // 1. Clone schedule
  let tempSched = [...schedule]
  // 2. Let currentTime be start time of anchor
  let currentTime = tempSched[anchor].span.start
  // 3. For each item from anchor -1 to start:
  let ind = anchor - 1
  while(ind >= start) {
  //  - move item to end at currentTime
    if(tempSched[ind].lock) {
      let err = new Error('Close schedule gap would have moved a locked item, so no items were moved')
      err.type = 'ERR_LOCKED'
      throw err
    }
    let item = moveScheduleItem(tempSched[ind], currentTime, true)
    tempSched[ind] = item
  //  - Set currentTime to item's new start time
    currentTime = item.span.start
    ind = ind - 1
  }
  // 4. Let currentTime be end time of anchor
  currentTime = tempSched[anchor].span.end
  // 5. For each item from anchor + 1 to end:
  ind = anchor + 1
  while(ind <= end) {
  //  - move item to start at currentTime
    if(tempSched[ind].lock) {
      let err = new Error('Close schedule gap would have moved a locked item, so no items were moved')
      err.type = 'ERR_LOCKED'
      throw err
    }
    let item = moveScheduleItem(tempSched[ind], currentTime, false)
    tempSched[ind] = item
  //  - Set currentTime to item's new end time
    currentTime = item.span.end
    ind = ind + 1
  }
  // 6. Return copy of schedule
  return tempSched
}

/**
 * Alters a schedule item's start or end. Will not shove other items or account for block children.
 * If you want to change a schedule's times and keep the children's times relative to the schedule's start,
 * use moveScheduleItem to move the start and then this function to change when the schedule ends.
 * @param {array} schedule The schedule containing the item to alter
 * @param {number} index The index of the item to alter
 * @param {object} start An IntervalTime or Moment object indicating the time to set the item's start to.
 *  If a falsey value is passed in, the item's start will not be changed.
 * @param {object} end An IntervalTime or Moment object indicating the time to set the item's end to.
 *  If a falsey value is passed in, the item's end will not be changed.
 * @returns A copy of schedule with the item at index's start time changed to start and end time changed to end.
 */
export const alterScheduleItemTime = (schedule, index, start, end) => {
  if(!schedule[index] || schedule[index].lock) {
    return schedule
  }
  let tempSched = [...schedule]
  let type = getIn(tempSched, [index, 'span', 'period'])
  let intervalData = getIn(tempSched, [index, 'span', 'intervalDuration'])
  if(moment.isMoment(start) && start.isValid()) {
    start = new IntervalTime(start, type, intervalData)
  }
  if(start && start instanceof IntervalTime) {
    tempSched = setIn(tempSched, [index, 'span'],
      new IntervalPeriod(start, getIn(tempSched, [index, 'span', 'end']))
    )
  }
  if(moment.isMoment(end) && end.isValid()) {
    end = new IntervalTime(end, type, intervalData)
  }
  if(end && end instanceof IntervalTime) {
    tempSched = setIn(tempSched, [index, 'span'],
      new IntervalPeriod(getIn(tempSched, [index, 'span', 'start']), end))
  }
  return tempSched
}

/**
 * Returns a time format to be passed to moment.js for display of times or durations
 * @param {boolean} subseconds whether to include subseconds in the display or not
 * @param {boolean} duration Whether the time should be displayed as a duration (true) or a time (false)
 * @returns A string if duration is false, and an array of strings if duration is true. These are to be passed
 * to moment() or moment.format() (only pass a string to moment.format())
 */
export const timeFormat = (subseconds=false, duration=false) => {
  if(duration) {
    if(subseconds) {
      return ['m:ss.SSS', 'H:mm:ss.SSS']
    } else {
      return ['m:ss', 'H:mm:ss']
    }
  } else {
    if(subseconds) {
      return 'h:mm:ss.SSS A'
    } else {
      return 'h:mm:ss A'
    }
  }
}

/**
 * Scans a schedule file for events
 * @param {array} schedule A schedule in local json format
 * @param {string} type The schedule type
 * @returns an object where the keys are the ids of events in the schedule and the values
 *  are the those events' properties
 */
export const scanScheduleForEvents = (schedule, type) => {
  let eventInfoList = {}
  schedule.forEach((item) => {
    if(item['event id']) {
      if(!eventInfoList[item.guid]) {
        let duration;
        if(item['item duration']) {
          duration = moment.duration(parseInt(item['item duration'], 10) * 1000)
        } else {
          let span = new IntervalPeriod(item.start, item.end, type, {zeroOk: true})
          duration = moment.duration(span.duration())
        }
        eventInfoList[item.guid] = {
          name: item['event id'],
          duration,
          uuid: item.guid,
          assigned: (item['error status'] !== "ENOENT")
        }
      }
    }
  })
  return eventInfoList
}

/**
 * Compares the list of events from both before and after user edits took place,
 *  and compiles an array of event changes that need to be sent to the server
 * @param {object} newlist The list of events after user edits
 * @param {object} oldlist The original list of events loaded from the schedule file
 * @returns an array of event action objects to be posted to the server
 */
export const checkForEventActions = (newlist, oldlist) => {
  let toReturn = []
  Object.entries(newlist).forEach(([uuid, evnt]) => {
    if(!oldlist[uuid]) {
      toReturn.push({
        action: 'CREATE',
        uuid,
        name: evnt.name
      })
    } else if(oldlist[uuid].name !== evnt.name) {
      toReturn.push({
        action: 'RENAME',
        uuid,
        name: evnt.name,
        oldName: oldlist[uuid].name
      })
    }
    if(evnt.toCopy && (!oldlist[uuid] || evnt.toCopy !== oldlist[uuid].toCopy)) {
      toReturn.push({
        action: 'COPY_FILE',
        name: evnt.name,
        path: evnt.toCopy
      })
    }
  })
  return toReturn
}

/**
 * Helper function that combines a list of schedule blocks and a list of schedule items together, then sorts them by start time
 * @param {array} schedule An array of schedule items. This array is mutated by this function, and should not be used afterwards.
 * @param {array} blocks An array of schedule blocks. This array is mutated by this function, and should not be used afterwards.
 * @returns An array of both schedule items and blocks sorted by start time. Items which fall within a schedule block will be moved from schedule to the block's body.contains param.
 */
const combineScheduleAndBlocks = (schedule, blocks) => {
  blocks = blocks.sort((a, b) => {return a.span.start.diff(b.span.start)})
  schedule = schedule.sort((a, b) => {return a.span.start.diff(b.span.start)})
  let toReturn = []
  while(blocks.length || schedule.length) {
    if(!schedule.length) {
      toReturn = toReturn.concat(blocks)
      blocks = []
    } else if(!blocks.length || blocks[0].span.start.diff(schedule[0].span.start) > 0) {
      let nextItem = schedule.shift()
      let previousItem = toReturn[toReturn.length - 1] || null
      if(previousItem &&
        previousItem.type === "block" &&
        nextItem.block &&
        previousItem.body.label === nextItem.block &&
        previousItem.span.overlaps(nextItem.span)) {
        previousItem.body.contains.push(nextItem)
      } else {
        toReturn.push(nextItem)
      }
    } else {
      toReturn.push(blocks.shift())
    }
  }
  return toReturn
}

/**
 * Given a schedule and the uuid key of an item in that schedule,
 *  returns the selection object for that item.
 * @param {array} schedule The list of schedule items to search
 * @param {string} searchKey The uuid key of the item to locate
 * @returns {?{index: number, child: number}} If the item is found, then an object is returned with the keys:
 *    index: (the index of the item or its parent in schedule),
 *    child: (the index of the item within a parent block if it has one, or -1 if it is a top-level item
 *  If the item is not found, then null is returned instead.
 */
export const selectItemByKey = (schedule, searchKey) => {
  let toReturn = null
  for(let index = 0; index < schedule.length; index++) {
    let item = schedule[index]
    if(item.key === searchKey) {
      toReturn = {index, child: -1}
      break;
    }
    if(item.type === "block") {
      let childIndex = selectItemByKey(item.body.contains, searchKey)
      if(childIndex !== null && "index" in childIndex) {
        toReturn = {index, child: childIndex.index}
        break;
      }
    }
  }
  return toReturn
}

export const constructItemsToAdd = async ({
  intervalBasis,
  intervalDuration,
  tabIndex,
  eventsList,
  showSubseconds,
  fileData,
  scheduleSwap,
  type,
  time,
  selectedEvents,
  selectedFiles,
  selectedBlocks,
  handleNamelessEvent,
  handleNoDuration,
  handleMultipleBlocks}) => {
  let scheduleType = type
  if(type !== "endless" && moment.isMoment(time)) {
    time = new IntervalTime(time, scheduleType, {days: intervalDuration, basis: intervalBasis})
  }
  let selected = []
  if(tabIndex === 2) {
    selected = selectedEvents.map((uuid) => {
      let evt = eventsList[uuid]
      if(evt) {
        if(!evt.name) {
          return handleNamelessEvent(evt)
        } else {
          return {
            "event id": evt.name,
            span: evt.duration,
            item: `/mnt/main/Events/Play/${evt.name}`,
            guid: evt.uuid,
            "error status": evt.assigned ? '' : 'ENOENT'
          }
        }
      } else {
        return null
      }
    })
    return selected.filter(item => item !== null)
    .map((item) => {
      let endTime = time.clone().add(item.span)
      // Needed span to calculate the start/end times, but it isn't a schedule item tag so remove it when we're finished
      delete item.span
      let toReturn = new ScheduleItem({...item, start: time, end: endTime}, {type: scheduleType, intervalDuration, intervalBasis})
      time = endTime
      return toReturn
    })
  } else if (tabIndex === 1) {
    let selected = []
    for (let name of selectedBlocks) {
      let matches = []
      scheduleSwap.schedule.forEach((item) => {
        if(item.type === "block" && item.body.label === name) {
          if(!(matches.find((block) => block.getSignature() === item.getSignature()))) {
            matches.push(item)
          }
        }
      })
      let block = null
      if(matches.length === 1) {
        block = matches[0]
      } else if (matches.length > 1) {
        block = await handleMultipleBlocks(name, matches)
      }
      if(!block) {
        continue
      }
      block = block.clone({newKey: true})
      let difference = block.span.start.diff(time)
      block.span = block.span.subtract(difference)
      block.body.contains = block.body.contains.map((child) => {
        child.span = child.span.subtract(difference)
        return child
      })
      selected.push(block)
    }
    return selected.filter(item => item !== null)
  } else {
    selected = await Promise.all(selectedFiles.map(async (item) => {
      let libraryItem = getIn(fileData, dataPath(item))
      let name = parseItemName(item)
      let span = ''
      let duration, inPoint, outPoint, association, associationName
      let fileMetadata = libraryItem.metadata
      let selectedAssociation = libraryItem.selectedAssociation
      let associations = libraryItem.associations
      if(selectedAssociation) {
        selectedAssociation = associations.find((assoc) => assoc.id === selectedAssociation)
        association = selectedAssociation.id
        associationName = selectedAssociation.name
      }
      if(fileMetadata.duration) {
        duration = parseFloat(fileMetadata.duration)
        span = duration
        if(fileMetadata.out) {
          outPoint = parseFloat(fileMetadata.out)
          span = outPoint
        }
        if(fileMetadata.in) {
          inPoint = parseFloat(fileMetadata.in)
          span = span - inPoint
        }
        span = moment.duration(span, 'seconds')
      } else {
        span = await handleNoDuration({name, showSubseconds})
        if(span === null) {
          return null
        }
      }
      let guid = libraryItem.guid || fileMetadata.guid
      return {
        span,
        in: inPoint,
        out: outPoint,
        guid,
        metadata: fileMetadata,
        item: `/${item.join('/')}`,
        association,
        'association name': associationName
      }
    }))
    return selected.filter(item => item !== null)
    .map((item) => {
      let endTime = time.clone().add(item.span)
      // Needed span to calculate the start/end times, but it isn't a schedule item tag so remove it when we're finished
      delete item.span
      let toReturn = new ScheduleItem({...item, start: time, end: endTime}, {scheduleType, intervalDuration, intervalBasis})
      time = endTime
      return toReturn
    })
  }
}

export const constructItemsFromClipboard = (times, clipboard, scheduleSwap) => {
  let {intervalDuration, intervalBasis, type: scheduleType} = scheduleSwap
  return times.map((addTime) => {
    if(scheduleType !== "endless" && moment.isMoment(addTime)) {
      addTime = new IntervalTime(addTime, scheduleType, {days: intervalDuration, basis: intervalBasis})
    }
    let items = clipboard.map((item, index) => {
      // Items may be coming from another schedule, so need to convert to local schedule type
      if(addTime instanceof IntervalTime) {
        if(item.span instanceof ExactPeriod) {
          item.span = new IntervalPeriod(item.span.start, item.span.end, scheduleType, {days: intervalDuration, basis: intervalBasis})
        } else {
          let duration = item.span.duration()
          let newStart = new IntervalTime({...addTime.time, ...item.span.start.time}, scheduleType, {days: intervalDuration, basis: intervalBasis})
          let newEnd = newStart.add(duration)
          item.span = new IntervalPeriod(
            newStart,
            newEnd,
            scheduleType,
            {days: intervalDuration, basis: intervalBasis})
        }
      } else {
        item.span = new ExactPeriod(item.span.start, item.span.end)
      }
      item = item.clone({newKey: true})
      if(item.type === 'block') {
        item.body.contains = item.body.contains.map((child) => {
          // Items may be coming from another schedule, so also need to convert block children to local schedule type
          child = child.clone({newKey: true})
          if(addTime instanceof IntervalTime) {
            if(child.span instanceof ExactPeriod) {
              child.span = new IntervalPeriod(child.span.start, child.span.end, scheduleType, {days: intervalDuration, basis: intervalBasis})
            } else {
              child.span = new IntervalPeriod(
                {...addTime.time, ...child.span.start.time},
                {...addTime.time, ...child.span.end.time},
                scheduleType,
                {days: intervalDuration, basis: intervalBasis})
            }
          } else {
            child.span = new ExactPeriod(child.span.start, child.span.end)
          }
          return child
        })
      }
      return item
    })
    let timeDifferential = addTime.diff(items[0].span.start)
    let times = items.map((item) => {
      return item.span.start.clone().add(timeDifferential)
    })
    return items.map((item, index) => {
      let timeDifference = times[index].diff(item.span.start)
      if(item.type === 'block') {
        item.body.contains = item.body.contains.map((child) => {
          child.span = child.span.add(timeDifference)
          return child
        })
      }
      if(scheduleType !== "endless" || item.span instanceof IntervalPeriod) {
        item.span = new IntervalPeriod(times[index], item.span.end.clone().add(timeDifference))
      } else {
        item.span = new ExactPeriod(times[index], item.span.end.clone().add(timeDifference))
      }
      return item
    })
  }).flat(1)
}

/**
 * Helper function for parsing an exact endless schedule time into a Moment
 * @param {string} date The date string from the schedule file
 * @returns {Moment} The moment indicated by the given date string
 */
export const parseEndlessDate = (date) => {
  // It's already a moment
  if(moment.isMoment(date)) {
    return date
  }
  if(typeof date === "string") {
    let match = /year (\d{4}) month (\d{1,2}) day (\d{1,2}) (\d{1,2}):(\d{2})(?::(\d{2})(?:\.(\d{2}))?)? ([ap]m)/.exec(date)
    if(!match) {
      // Try just passing it to moment directly as a backup
      let toReturn = moment(date)
      if(!(toReturn.isValid())) {
        throw new Error(`Tried to parse an endless date from the string: ${date}. However, it was not in a format that could be used to produce a valid moment.`)
      }
      return toReturn
    }
    let [year,
      month,
      day,
      hour,
      minute,
      second,
      millisecond,
      meridian] = match.slice(1)
    month = parseInt(month, 10) - 1
    if((meridian === "pm" && hour !== "12") ||
      (meridian === "am" && hour === "12")) {
      hour = (parseInt(hour, 10) + 12) % 24
    }
    if(millisecond) {
      // Subseconds are encoded as microsends (two digits), so convert them to milliseconds
      millisecond = millisecond.padEnd(3, "0")
    }
    return moment({year, month, day, hour, minute, second, millisecond})
  }
  // Try just passing it to moment directly as a backup
  let toReturn = moment(date)
  if(!(toReturn.isValid())) {
    throw new Error(`Tried to parse an endless date from the ${typeof date}: ${date}. However, it could not be used to produce a valid moment.`)
  }
  return toReturn
}

/**
 * Helper function for serializing a moment into an endless schedule string
 * @param {Moment} moment A moment object to be serialized
 * @returns {string} A string representation of the given moment for use in a schedule file
 */
export const serializeEndlessDate = (moment) => {
  if(moment.second()) {
    if(moment.millisecond()) {
      return moment.format("[year] YYYY [month] M [day] D h:mm:ss.SS a")
    } else {
      return moment.format("[year] YYYY [month] M [day] D h:mm:ss a")
    }
  } else {
    return moment.format("[year] YYYY [month] M [day] D h:mm a")
  }
}

// The following two functions are currently unused, but they may be useful in the future, therefore they have been commented out but not removed
/**
 * Helper for finding the earliest time that a given type of schedule can contain
 * @param {string} type The schedule type to get the start of
 * @returns A moment object indicating the earliest time that a schedule can contain
 */
/*
const startOfSchedule = (type) => {
  switch(type) {
    case 'daily':
      return moment().startOf('day')
    case 'weekly':
      return moment().day(0).startOf('day')
    case 'monthly':
      return moment().startOf('month')
    case 'yearly':
      return moment({year: 2016}).startOf('year')
    case 'interval':
    default:
      return moment().startOf('year')
  }
}
*/

/**
 * Helper for finding the latest time that a given type of schedule can contain
 * @param {string} type The schedule type to get the start of
 * @returns A moment object indicating the latest time that a schedule can contain
 */
/*
const endOfSchedule = (type) => {
  switch(type) {
    case 'daily':
      return moment().add(1, 'day').startOf('day')
    case 'weekly':
      return moment().day(0).add(1, 'week').startOf('day')
    case 'monthly':
      return moment().add(1, 'month').startOf('month')
    case 'yearly':
      return moment({year: 2016}).endOf('year')
    case 'interval':
    default:
      return moment().add(1, 'year').endOf('year')
  }
}
*/

/**
 * Convert moment object to Jonathan formatted time string based on schedule type
 * @param {object} date The date object to format
 * @param {string} type The type of the schedule to format for
 * @returns A string representation of date that can be used in schedule files
 */
/*
const convertMomentToScheduleTime = (date, type) => {
  let seconds = ''
  if(date.second() > 0 || date.millisecond() % 10 > 0) {
    seconds = ':ss.SS'
  }
  let toReturn = ''
  switch(type) {
    case 'daily':
      toReturn = date.format('h:mm' + seconds + ' a')
      break
    case 'weekly':
      toReturn = date.format('ddd h:mm' + seconds + ' a')
      break
    case 'monthly':
      toReturn = 'day ' + date.format('D h:mm' + seconds + ' a')
      break
    case 'yearly':
      toReturn = 'month ' + date.format('M') + ' day ' + date.format('D h:mm' + seconds + ' a')
      break
    case 'interval':
      toReturn = 'day ' + date.format('DDD h:mm' + seconds + ' a')
      break
    default:
      break
  }
  return toReturn.toLowerCase()
}
*/
