import {connectToServerSocket} from 'helpers/net_helpers'

import {replaceStatus} from '../helpers/status_manager.js'

export const ESTABLISH_SYSTEM_STATUS_CONNECTION = Symbol('establish system status connection')
export const UPDATE_SYSTEM_SERVICES = Symbol('update system services')
export const UPDATE_SYSTEM_STATUS = Symbol('update system status')
export const UPDATE_SYSTEM_DEVICES = Symbol('update system devices')

// Did it ever occur to this hyper-data-separated code to just store the information for later use?? --J.C.
let lastSystemServices = undefined

export const subscribeToSystemStatus = () => {
  return async (dispatch, getState) => {
    if(getState().system.statusConnection !== null) {
      return
    }
    var connection = connectToServerSocket("/system")
    if(connection === undefined) {
      return
    }
    dispatch({
      type: ESTABLISH_SYSTEM_STATUS_CONNECTION,
      payload: connection
    })
    connection.on('initialize', (data) => {
      dispatch(setSystemStatus(data))
      if(data.hostname) {
        setDocumentTitle(data.hostname)
      }
    })

    connection.on("update_status", (data) => {
      dispatch(setSystemStatus(data))
      if(data.hostname) {
        // If there isn't a hostname or if there is one but the incoming data contains a different hostname, update the document title
        if(!("hostname" in getState().system.stat) ||
          ("hostname" in getState().system.stat && getState().system.stat.hostname !== data.hostname)) {
          setDocumentTitle(data.hostname)
        }
      }
    })

    connection.on('initialize_systemdevices', (data) => {
      dispatch(setSystemDevices(data))
    })

    connection.on('initialize_services', (data) => {
      dispatch(setSystemServices(data,false))
    })

    connection.on('update_services', (data) => {
      dispatch(setSystemServices(data,true)) // NTS: This event transmits only changes to the services
    })
  }
}

const setSystemDevices = (data) => ({
  type: UPDATE_SYSTEM_DEVICES,
  payload: data
})

const setSystemStatus = (data) => ({
  type: UPDATE_SYSTEM_STATUS,
  payload: data
})

const setSystemServices = (data,deltaUpdate) => ({
  type: UPDATE_SYSTEM_SERVICES,
  deltaUpdate: (deltaUpdate !== undefined) ? deltaUpdate : false,
  payload: data
})

const initialState = {
  statusConnection: null,
  services: null,
  stat: null
}

export default (state = initialState, action) => {
  let {type, payload} = action

  switch (type) {
    case ESTABLISH_SYSTEM_STATUS_CONNECTION: {
      return {
        ...state,
        statusConnection: payload
      }
    }
    case UPDATE_SYSTEM_DEVICES: {
      replaceStatus('system_devices',payload)
      return {
        ...state,
        devices: payload
      }
    }
    case UPDATE_SYSTEM_STATUS: {
      replaceStatus('system_status',payload)
      if(payload.raidStatus) {
        payload.raidStatus = parseRaidStatus(payload.raidStatus)
      }
      return {
        ...state,
        stat: payload
      }
    }
    case UPDATE_SYSTEM_SERVICES: {
      if (action.deltaUpdate === true) {
        // only changes are transmitted
        lastSystemServices.supportStatus = {
          ...lastSystemServices.supportStatus,
          ...payload.supportStatus}

        if (payload.changeServiceStatusExt) {
          // NTS: React will not render the service list unless the serviceStatusExt object completely
          //      changes. It would of course be far more efficient to just update patch the object
          //      but as far as React is concerned, the object reference is unchanged, therefore, no
          //      UI update. See where serviceStatusExt is referenced on dispatch and mapping in
          //      source file src/containers/applications/Settings.jsx, line 56, mapStateToProps.
          //      So to appeal to React, we have to basically copy-merge the whole object.
          lastSystemServices.serviceStatusExt = {
            ...lastSystemServices.serviceStatusExt,
            ...payload.changeServiceStatusExt}
        }
        return {
          ...state,
          services: lastSystemServices
        }
      }
      else {
        lastSystemServices = payload // Full update
        return {
          ...state,
          services: payload
        }
      }
    }
    default:
      return state
  }
}

/**
 * Parses the string raid status into an object containing the relevant raid status data
 * @param {string} raidStatus The raid status received from the /proc/mdstat file
 * @returns An array of raid device objects containing the following raid status key/value pairs for each raid:
 *   name: The string device name of the raid
 *   stat: The string status of the raid (active, etc.)
 *   type: The string type of the raid (raid1, raid2, etc.)
 *   drives: An array of drives in the raid as objects, containing a string name and a boolean active.
 *   active: Integer number of active drives in the array
 *   total: Integer total number of drives that are set to be in the array
 *   ok: Boolean whether all drives are active or not
 *   recoveryStatus: If raid is recovering, this will be the string recovery status. Else undefined.
 */
function parseRaidStatus(raidStatus) {
  let lines = raidStatus.split("\n")
  let devices = []
  let currentDevice = null
  for(let line of lines) {
    if(line.startsWith("Personalities")) {
      continue
    } else if (/^(\S*) : (\S*) (\S*) ((?:\S+\[\d+\] ?)+)$/.exec(line)) {
      let match = /^(\S*) : (\S*) (\S*) ((?:\S+\[\d+\] ?)+)$/.exec(line)
      let deviceName = match[1]
      let stat = match[2]
      let type = match[3]
      let drives = match[4]
      let info = {name: deviceName, status: stat, type, drives: []}
      drives = drives.split(' ')
      drives.forEach((drive) => {
        let match = /(\S+)\[(\d+)\]/.exec(drive)
        let driveName = match[1]
        let driveIndex = match[2]
        info.drives[driveIndex] = {name: driveName}
      })
      if(currentDevice) {
        devices.push(currentDevice)
      }
      currentDevice = info
    } else if (currentDevice && /\d+ blocks/.exec(line)) {
      let match = /\[(\d+)\/(\d+)\] \[([U_]+)\]/.exec(line)
      let total = match[1]
      let active = match[2]
      let upStatus = match[3]
      active = parseInt(active, 10)
      total = parseInt(total, 10)
      let ok = (active === total)
      // Why is this one a problem but not the one above...?
      // eslint-disable-next-line no-loop-func
      upStatus = upStatus.split('').map((stat, ind) => {
        let name = ''
        let active = true
        if(stat === '_') {
          active = false
        }
        if(currentDevice.drives && currentDevice.drives[ind]) {
          name = currentDevice.drives[ind].name
        }
        return {name, active}
      })
      let info = {total, active, drives: upStatus, ok}
      if(!currentDevice) {
        currentDevice = {}
      }
      currentDevice = {...currentDevice, ...info}
    } else if (currentDevice && /\[[=>.]+] {2}\S+ = (.+)$/.exec(line)) {
      let recoveryStatus = /\[[=>.]+] {2}\S+ = (.+)$/.exec(line)[1]
      if(!currentDevice) {
        currentDevice = {}
      }
      currentDevice = {...currentDevice, recoveryStatus}
    }
  }
  if(currentDevice) {
    devices.push(currentDevice)
  }
  return devices
}

function setDocumentTitle(hostname) {
  document.title = `${hostname} CASTUS UI`
}
