import React, {PureComponent} from 'react'
import {staticAsset} from 'helpers/net_helpers'
import {Card, Icon} from 'semantic-ui-react'

import LazyLoad from 'react-lazy-load'

import "./LibraryFileTile.css"

import LibraryFileTileImage from './LibraryFileTileImage'
import LibraryItemDropdown from './LibraryItemDropdown'

class LibraryFileTile extends PureComponent {

  componentDidMount() {
    if(this.props.highlight && this.props.spanRef.current) {
      this.props.spanRef.current.scrollIntoView({block: "center"})
    }
  }

  render() {
    let {hasData,
      cc,
      hd,
      path,
      title,
      filename,
      date,
      size,
      filetype,
      icon,
      generatingMetadata,
      duration,
      durationValue,
      active,
      highlight,
      dropdownActions,
      contentWindowData,
      selectedFiles,
      selectedChannel,
      closerToBottom,
      spanRef,
      onClick,
      onDblClick,
      onDropdownSelect} = this.props

    if(!hasData) {
      return null;
    }

    let cls = 'noselect'
    if(highlight) {
      cls = `${cls} highlighted`
    }

    let emblem_cc = null
    if (parseInt(cc) > 0 || cc === '1' || cc === 'Y' || cc === 'T')
        emblem_cc = (<span> <img style={{ height: "0.8em" }} src={staticAsset("/icons/cclogo.png")} alt="Closed Captions" /></span>)

    let emblem_hd = null
    if (hd === '2160') {
        emblem_hd = (<span style={{fontSize: "0.75em", fontWeight: "400", color: "#00007F" }}> (4K<span style={{fontSize: "0.5em", fontWeight: "900"}}>UHD</span>)</span>)
    }
    else if (hd === '1080' || hd === '720') {
        emblem_hd = (<span style={{fontSize: "0.75em", fontWeight: "400", color: "#00007F" }}> ({hd}<span style={{fontSize: "0.75em", fontWeight: "900"}}>HD</span>)</span>)
    }
    else if (hd === '576' || hd === '480' || hd === '360') {
        emblem_hd = (<span style={{fontSize: "0.75em", fontWeight: "400", color: "#00007F" }}> ({hd}<span style={{fontSize: "0.75em", fontWeight: "900"}}>SD</span>)</span>)
    }
    else if (parseInt(hd) > 0 || hd === '1' || hd === 'Y' || hd === 'T') {
        emblem_hd = (<span style={{fontSize: "0.75em", fontWeight: "400", color: "#00007F" }}> (HD)</span>)
    }

    if(active) {
      cls = `${cls} active`
    }

    let contentWindowMessage;
    if(contentWindowData && "closed" in contentWindowData) {
      contentWindowMessage = (<Card.Description title={contentWindowData.message}>
        <Icon name="clock" color={contentWindowData.closed ? "red" : "green"}/> {contentWindowData.message}
      </Card.Description>)
      title = `${title} (${contentWindowData.message})`
      if(contentWindowData.closed) {
        cls = `${cls} closed`
      } else {
        cls = `${cls} opened`
      }
    }

    return (
      <Card onDoubleClick={onDblClick}
        onClick={onClick}
        as='div'
        className={cls}
        raised={active}>
        <LazyLoad height={95} offset={100} once>
          <LibraryFileTileImage duration={durationValue}
            path={path}
            icon={icon}
            generatingMetadata={generatingMetadata}/>
        </LazyLoad>
        <Card.Content>
          <Card.Header className='tileHeader' title={title}>
            {filename}
          </Card.Header>
          <Card.Description>{filetype}{emblem_cc}{emblem_hd}</Card.Description>
          <Card.Description>{duration}</Card.Description>
          <Card.Description>{date}</Card.Description>
          <Card.Description>{size}</Card.Description>
          {contentWindowMessage}
        </Card.Content>
        {dropdownActions && dropdownActions.length > 0 ?
        <>
          <span ref={spanRef}/>
          <LibraryItemDropdown options={dropdownActions}
            handleSelect={onDropdownSelect}
            handleDeselect={() => {}}
            selectedFiles={selectedFiles}
            closerToBottom={closerToBottom}
            selectedChannel={selectedChannel}/>
        </> : null}
      </Card>
    )
  }
}

export default LibraryFileTile
