import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
import store from './store'
import history from './history.js'
import AppContainer from 'containers/AppContainer'
import Authentication from 'components/Authentication'
import './index.css'
import 'react-app-polyfill/ie9';

import 'semantic-ui-offline/semantic.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'semantic-ui-css/semantic.min.css';

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div id='app_container'>
        <Switch>
          <Route path='/admin' component={Authentication}/>
          <Route path='/' component={AppContainer}/>
        </Switch>
      </div>
    </ConnectedRouter>
  </Provider>,
  target
)
