import React, {PureComponent} from 'react';
import {Confirm} from 'semantic-ui-react'

export default class ConfirmModal extends PureComponent {

  handleCancel = (e, data) => {
    this.props.dismiss()
    this.props.onClose(false)
  }

  handleOk = (e, data) => {
    this.props.dismiss()
    this.props.onClose(true)
  }

  render() {
    let {message, options} = this.props

    let confirmButton = options.confirmText || 'Yes'
    let cancelButton = options.cancelText || 'No'

    return (
      <Confirm open={true}
        content={message}
        onCancel={this.handleCancel}
        onConfirm={this.handleOk}
        closeOnDimmerClick={false}
        confirmButton={confirmButton}
        cancelButton={cancelButton}/>
    )
  }

}
