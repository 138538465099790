import React, {PureComponent} from 'react'
import {Form, Input} from 'semantic-ui-react'

export default class NumberPrompt extends PureComponent {

  numberInput = React.createRef()

  componentDidMount() {
    if(this.numberInput.current) {
      this.numberInput.current.focus()
    }
  }

  render() {
    let {value, changeValue, options} = this.props

    let step = '1'
    if(options && options.decimal) {
      step = '0.01'
    }

    return (
      <Form>
        <Form.Field>
          <Input type='number'
            value={value}
            step={step}
            ref={this.numberInput}
            onChange={(e, data) => changeValue(data.value)}/>
        </Form.Field>
      </Form>
    )
  }

}
