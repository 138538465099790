import {getIn, setIn} from 'helpers/general_helpers'

const schemaMap = [
    {frame: ['options', 'enabled'],  schema: ['enable', 'default']},
    {frame: ['options', 'video'],    schema: ['video', 'properties', 'enable', 'default']},
    {frame: ['options', 'audio'],    schema: ['audio', 'properties', 'enable', 'default']},
    {frame: ['options', 'volume'],   schema: ['audio', 'properties', 'volume', 'default']},
    {frame: ['position', 'x'],       schema: ['video', 'properties', 'geometry', 'properties', 'position', 'properties', 'x', 'default']},
    {frame: ['position', 'y'],       schema: ['video', 'properties', 'geometry', 'properties', 'position', 'properties', 'y', 'default']},
    {frame: ['size', 'width'],       schema: ['video', 'properties', 'geometry', 'properties', 'size', 'properties', 'width', 'default']},
    {frame: ['size', 'height'],      schema: ['video', 'properties', 'geometry', 'properties', 'size', 'properties', 'height', 'default']},
    {frame: ['timeline', 0, 'item'], schema: ['media', 'properties', 'path', 'default']}
]

const settingsMap = [
    {frame: ['options', 'enabled'],  setting: ['enable']},
    {frame: ['options', 'video'],    setting: ['video', 'enable']},
    {frame: ['options', 'audio'],    setting: ['audio', 'enable']},
    {frame: ['options', 'volume'],   setting: ['audio', 'volume']},
    {frame: ['position', 'x'],       setting: ['video', 'geometry', 'position', 'x']},
    {frame: ['position', 'y'],       setting: ['video', 'geometry', 'position', 'y']},
    {frame: ['size', 'width'],       setting: ['video', 'geometry', 'size', 'width']},
    {frame: ['size', 'height'],      setting: ['video', 'geometry', 'size', 'height']},
    {frame: ['timeline', 0, 'item'], setting: ['media', 'path']}
]

/**
 * Takes the raw settings and assembles a frame list that is readable by the global media editor and
 *  frame editor
 * @param {array} regions The array of region settings
 * @param {object} schema The region settings schema
 * @returns An array of frames for the global media editor
 */
export function getFrameListFromSettings(regions, schema) {
  let frames = []

  Object.entries(regions).forEach(([type, value]) => {
    let itemSchema = {}
    switch(type) {
      case 'item':
        itemSchema = getIn(schema, ['item', 'properties'])
        frames[0] = getFrameFromRegionSettings(value, itemSchema, 'Item')
        break;
      case 'urgent':
        itemSchema = getIn(schema, ['urgent', 'properties'])
        frames[9] = getFrameFromRegionSettings(value, itemSchema, 'Urgent')
        break;
      case 'global':
        itemSchema = getIn(schema, ['global', 'items', 'properties'])
        value.forEach((globalRegion, index) => {
          frames[index + 1] = getFrameFromRegionSettings(globalRegion, itemSchema, `Region ${index+1}`)
        })
        break;
      case 'alerts':
        itemSchema = getIn(schema, ['alerts', 'properties', 'media', 'properties'])
        frames[10] = getFrameFromRegionSettings(value.media, itemSchema, 'EAS A/V')
        itemSchema = getIn(schema, ['alerts', 'properties', 'text', 'properties'])
        frames[11] = getFrameFromRegionSettings(value.text, itemSchema, 'EAS Text')
        break;
      default:
        break;
    }
  })
  return frames
}

function getFrameFromRegionSettings(region, schema, name='', main=false) {
  let defaults = {}
  schemaMap.forEach((setting) => {
    defaults = setIn(
      defaults,
      setting.frame,
      getIn(schema, setting.schema)
    )
  })
  let frame = {...defaults, timeline: []}
  if(region) {
    settingsMap.forEach((setting) => {
      let value = getIn(region, setting.setting)
      if(value !== undefined && value !== null) {
        frame = setIn(frame, setting.frame, value)
      }
    })
    if(main) {
      frame.options.main = true
    }
    if(!getIn(frame, ['timeline', 0, 'item'])) {
      delete frame.timeline
    }
    if(!frame.name) {
      frame.name = name
    }
  }
  // some things needs translation
  if (frame.options.enabled !== undefined) // integer,  -1 = deleted   0 = not enabled   1 = enabled
    frame.options.enabled = parseInt(frame.options.enabled)
  if (frame.options.audio !== undefined)
    frame.options.audio = parseInt(frame.options.audio) > 0
  if (frame.options.video !== undefined)
    frame.options.video = parseInt(frame.options.video) > 0
  if (frame.options.volume !== undefined)
    frame.options.volume = parseInt(frame.options.volume)
  if (frame.position.x !== undefined)
    frame.position.x = parseFloat(frame.position.x)
  if (frame.position.y !== undefined)
    frame.position.y = parseFloat(frame.position.y)
  if (frame.size.width !== undefined)
    frame.size.width = parseFloat(frame.size.width)
  if (frame.size.height !== undefined)
    frame.size.height = parseFloat(frame.size.height)

  // some regions should be locked in the UI by default
  if (name === "Item" || name === "EAS A/V" || name === "EAS Text") frame.options.locked = true

  // Main region may be replaced thumbnail-wise by alt region info on play now
  if (name === "Item") frame.isMainRegion = true

  return frame
}

/**
 * Converts frames list into an array of settings that can be called with modifySettings
 */
export function getSettingsListFromFrames(frames) {
  let settings = []
  frames.forEach((frame, index) => {
    if(frame) {
      let base = ['regions', ...regionAddress(index)]
      settingsMap.forEach((setting) => {
        let value = getIn(frame, setting.frame)
        if (value !== undefined && value !== null) {
          settings.push({
            key: base.concat(setting.setting),
            value
          })
        }
      })
    }
  })
  return settings
}

export function getThumbInfo(frameSettings) {
  let context = getIn(frameSettings, ['media', 'context'])
  let played = getIn(frameSettings, ['media', 'played'])
  let path = getIn(frameSettings, ['media', 'path'])
  let h = 0
  let w = 0
  let size = getIn(frameSettings, ['video', 'geometry', 'size'])
  if(size) {
    let {height, width} = size
    if(typeof height === 'string') {
      height = parseFloat(height)
    }
    if(typeof width === 'string') {
      width = parseFloat(width)
    }
    h = height
    w = width
  }
  if(path instanceof Array) {
    path = path.join('/')
  }
  return {
    context,
    played,
    path,
    frameHeight: h,
    frameWidth: w
  }
}

/**
 * Helper function that returns the address of a region within a channel's region settings
 * @param {number} id The index of the region within the global frame editor
 * @returns An array that can be used with getIn and the regions settings object from
 *  a channel to get the region represented by frame index id, or null if id is invalid
 */
export function regionAddress(id) {
  if(id === 0) {
    return ['item']
  } else if (id >= 1 && id <= 8) {
    return ['global', (id - 1)]
  } else if (id === 9) {
    return ['urgent']
  } else if (id === 10) {
    return ['alerts', 'media']
  } else if (id === 11) {
    return ['alerts', 'text']
  } else {
    return null
  }
}
