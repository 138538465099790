import React, {PureComponent} from 'react'
import {Modal} from 'semantic-ui-react'
import {dataPath} from 'helpers/library_helpers'
import {getIn} from 'helpers/general_helpers'

import FilePreviewVideo from './FilePreviewVideo'
import FilePreviewImage from './FilePreviewImage'

export default class FilePreviewModal extends PureComponent {

  render() {
    let {path, type, closeModal, fileData, handleSetInOutPoints} = this.props
    let isOpen = !!(path && type)

    let FilePreview = null
    let metadata = {}
    switch(type) {
      case 'video':
        FilePreview = FilePreviewVideo
        metadata = getIn(fileData, [...dataPath(path), "metadata"])
        break;
      case 'image':
        FilePreview = FilePreviewImage
        break;
      default:
        break;
    }

    return (
      <Modal open={isOpen} closeIcon onClose={() => closeModal()}>
        <Modal.Header>File Preview</Modal.Header>
        <Modal.Content>
          {FilePreview ? <FilePreview path={path} metadata={metadata} handleSetInOutPoints={handleSetInOutPoints}/> : ''}
        </Modal.Content>
      </Modal>
    )
  }

}
