import React, {PureComponent} from 'react'

import "./TimeInput.css"

function parseDurationToState(duration, showMilliseconds) {
  duration = Math.floor(duration)
  let milliseconds;
  if(showMilliseconds) {
    milliseconds = ("" + (duration % 1000)).padStart(3, "0")
    duration = Math.floor(duration / 1000)
  } else {
    duration = Math.round(duration / 1000)
  }
  let seconds = ("" + (duration % 60)).padStart(2, "0")
  duration = Math.floor(duration / 60)
  let minutes = ("" + (duration % 60)).padStart(2, "0")
  duration = Math.floor(duration / 60)
  let hours = "" + duration
  return {hours, minutes, seconds, milliseconds}
}

function parseStateToDuration(state) {
  let {hours, minutes, seconds, milliseconds} = state
  // Add each unit and then convert to the next unit until we get milliseconds
  let outerValue = parseInt(hours, 10) || 0
  outerValue *= 60
  outerValue += (parseInt(minutes, 10) || 0)
  outerValue *= 60
  outerValue += (parseInt(seconds, 10) || 0)
  outerValue *= 1000
  outerValue += (parseInt(milliseconds, 10) || 0)
  return outerValue
}

export default class DurationInput extends PureComponent {

  constructor(props) {
    super(props)
    this.hourInput = React.createRef()

    if((props.value || props.value === 0) && typeof props.value === "number") {
      this.state = parseDurationToState(props.value, props.showMilliseconds)
      if(!props.onChange) {
        console.warn(`Warning: a DurationInput was given a value but no onChange prop. This will cause it to be read-only.${"\n"}${this}`)
      }
    } else if(props.defaultValue && typeof props.defaultValue === "number") {
      this.state = parseDurationToState(props.defaultValue, props.showMilliseconds)
    } else {
      this.state = {
        hours: '',
        minutes: '',
        seconds: '',
        milliseconds: ''
      }
    }
    this.focus = this.focus.bind(this)
  }

  componentDidUpdate = (prevprops, prevstate) => {
    let {onChange, value} = this.props
    //let validValue = (typeof value === "number" && !isNaN(value))
    // Allow state changes to be propagated by onChange before value synchronization, so that value can be changed by onChange
    if(onChange && prevstate !== this.state) {
      onChange(parseStateToDuration(this.state))
    }
    // Synchronize value and state
    if(prevprops.value !== value &&
      value !== parseStateToDuration(this.state)) {
      this.setState(parseDurationToState(value))
    }
  }

  focus() {
    if(this.hourInput.current) {
      this.hourInput.current.focus()
      this.hourInput.current.setSelectionRange(0, this.hourInput.current.value.length)
    }
  }

  handleChange = (e) => {
    let {name, value} = e.target
    if(value) {
      value = value.match(/\d+/)
    }
    if(!value) {
      value = ''
    } else {
      value = value[0]
    }
    switch(name) {
      case "hours":
        this.handleChangeHours(e, value);
        break;
      case "minutes":
      case "seconds":
        this.handleChangeMinutesSeconds(e, value);
        break;
      case "milliseconds":
        this.handleChangeMilliseconds(e, value);
        break;
      default:
        break;
    }
  }

  handleChangeHours = (e, value) => {
    let {name} = e.target
    if(value) {
      value = parseInt(value, 10)
      value = Math.max(value, 0)
    }
    this.setState({[name]: `${value}`})
  }

  handleChangeMinutesSeconds = (e, value) => {
    let {name} = e.target
    let originalValue = value
    if(value) {
      value = parseInt(value, 10)
      value = Math.max(Math.min(value, 59), 0)
    }
    this.setState({[name]: `${value}`})
    if(value > 5 || originalValue.length > 1) {
      this.goToNextInput(e)
    }
  }

  handleChangeMilliseconds = (e, value) => {
    let {name} = e.target
    let originalValue = value
    if(value) {
      value = parseInt(value, 10)
      value = Math.max(Math.min(value, 999), 0)
    }
    this.setState({[name]: `${value}`.padStart(Math.min(originalValue.length, 3), "0")})
    if(value > 99 || originalValue.length > 2) {
      this.goToNextInput(e)
    }
  }

  handleFocus = (e) => {
    if(e.target.value !== "") {
      e.target.setSelectionRange(0, e.target.value.length)
    }
  }

  handleBlur = (e) => {
    let {name, value} = e.target
    let strValue = `${value}`
    if(name === "hours") {
      strValue = strValue.padStart(1, "0")
    } else if (name === "minutes" || name === "seconds") {
      strValue = strValue.padStart(2, "0")
    } else if (name === "milliseconds") {
      strValue = strValue.padStart(3, "0")
    }
    this.setState({[name]: strValue})
  }

  handleKeyDown = (e) => {
    if(e.key === "Enter" ||
      ((e.key === ":" || e.key === ".") && e.currentTarget.value)) {
      e.preventDefault()
      e.stopPropagation()
      this.goToNextInput(e)
    }
  }

  goToNextInput = (e) => {
    let next = e.target;
    let tries = 0
    do {
      tries++
      next = next.nextElementSibling
    } while(next && next.tagName !== "INPUT" && tries < 10)
    if (!next) return
    next.focus()
  }

  render() {
    let {
      hours,
      minutes,
      seconds,
      milliseconds
    } = this.state
    let {showMilliseconds, inline} = this.props

    let millisecondsInput = ''
    if(showMilliseconds) {
      millisecondsInput = (
        <>
          <span className="unitDivider">.</span>
          <input
            style={{ fontSize: '0.6em', position: 'relative', top: '0.25em' }}
            className="millisecondsInput"
            placeholder="---"
            autoComplete="off"
            value={milliseconds}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown}
            name="milliseconds"
            size={3}
            maxLength={3}/>
        </>
      )
    }

    let cls = inline ? " inline" : ""

    return (
      <div className={"timeInput" + cls}>
        <input className="hoursInput"
          ref={this.hourInput}
          placeholder="--"
          name="hours"
          autoComplete="off"
          value={hours}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}/>
        <span className="unitDivider">:</span>
        <input
          className="minutesInput"
          placeholder="--"
          autoComplete="off"
          value={minutes}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          name="minutes"
          size={2}
          maxLength={2}/>
        <span className="unitDivider">:</span>
        <input
          className="secondsInput"
          placeholder="--"
          autoComplete="off"
          value={seconds}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          name="seconds"
          size={2}
          maxLength={2}/>
        {millisecondsInput}
        <span className="spacer"/>
      </div>
    )
  }

}
