import React, {PureComponent} from 'react'
import {List} from 'semantic-ui-react'
import {millisToHourString} from 'helpers/general_helpers'

import {DragSource, DropTarget} from 'react-dnd'
import DRAG_CONSTANT from './DragConstant'

import './ListItem.css'

class SimplePlaylistItem extends PureComponent {

  handleAddItemAt = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.addListItem(null, index)
  }

  handleRemoveItemAt = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.removeListItem(index)
  }

  handleChangeDuration = (e, index) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.changeItemDuration(index)
  }

  render() {
    let {clip,
      index,
      connectDragSource,
      connectDropTarget,
      isDragging} = this.props

    let path = clip.item
    let duration = millisToHourString(clip.end - clip.start)
    let displayName = path.slice(-1)
    if(clip.association) {
      displayName = `${displayName} (${clip.association.name})`
    }
    let content = connectDropTarget(connectDragSource(<div>{displayName}</div>))

    return (
      <List.Item className='SimpleListItem noselect'
        title={`/${path.join('/')}`}
        disabled={isDragging}>
        <List.Content floated="right" onClick={(e) => this.handleChangeDuration(e, index)}>
          {duration}
        </List.Content>
        <List.Icon name='remove'
          color='red'
          onClick={(e) => this.handleRemoveItemAt(e, index)}
          link
          title='Remove Item'/>
        <List.Content onClick={(e) => this.handleAddItemAt(e, index)}>
          {content}
        </List.Content>
      </List.Item>
    )
  }

}

const beginDrag = (props, monitor, component) => {
  return {}
}

const endDrag = (props, monitor, component) => {
  if(monitor.didDrop()) {
    let {newIndex} = monitor.getDropResult()
    props.moveListItem(props.index, newIndex)
  }
}

const drop = (props, monitor, component) => {
  return {
    newIndex: props.index
  }
}

const dragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
})

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget()
})

export default DropTarget(DRAG_CONSTANT, {drop}, dropCollect)(
  DragSource(DRAG_CONSTANT, {beginDrag, endDrag}, dragCollect)(SimplePlaylistItem)
)
