import { createSelector } from 'reselect'

const getScheduleSwap = (state) => state.scheduleSwap ? state.scheduleSwap.schedule : []
const getEventsList = (state) => state.scheduleSwap ? state.scheduleSwap.eventsList : {}

export default createSelector([
  getScheduleSwap,
  getEventsList
], (schedule, eventsList) => {
  let toReturn = {...eventsList}
  if(schedule) {
    Object.entries(toReturn).forEach(([id, evt]) => {
      toReturn[id] = {
        ...evt,
        occurences: findEventOccurences(evt.uuid, schedule)
      }
    })
  }
  return toReturn
})

const findEventOccurences = (id, schedule) => {
  let toReturn = []
  schedule.forEach((item) => {
    if(item.type === 'event' && item.guid === id) {
      toReturn.push(item.span.start)
    } else if (item.type === 'block') {
      toReturn = toReturn.concat(findEventOccurences(id, item.body.contains))
    }
  })
  return toReturn
}
