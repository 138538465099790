import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actions} from 'redux/applications/cloud'

import {Button, Segment, Message, Label, Header, Icon} from 'semantic-ui-react'

import "./CloudServices.css"

class CloudServices extends PureComponent {

  componentDidMount() {
    this.props.getVodConfig()
  }

  render() {
    let {vodConfig} = this.props

    let vodConfSection = ""
    if(vodConfig) {
      // Error message
      let errorSection = vodConfig._error ?
        (<Segment key="errorMsg">
          <Message error>
            {vodConfig._error}
          </Message>
        </Segment>) : ""
      // Email
      let emailSection = vodConfig.email ?
        (<Segment key="email">
          <Label horizontal>Email</Label>
          {vodConfig.email}
        </Segment>) : ""
      // Config
      let configSections = []
      if(vodConfig.conf) {
        configSections = Object.entries(vodConfig.conf).map(([key, val]) => (
          <Segment key={"conf." + key}>
            <Label horizontal>{key}</Label>
            {val}
          </Segment>
        ))
      }
      vodConfSection = (<Segment.Group>
        {errorSection}
        {emailSection}
        {configSections}
      </Segment.Group>)
    }
    return (
      <div id="CloudServicesApp">
        <Segment>
          <Header><Icon name="cloud"/>Cloud Services Configuration</Header>
          {vodConfSection}
          <Button content="Setup Cloud Configuration" onClick={this.props.setupVodConfig}/>
          <a href="https://cloud.castus.tv" target="_blank" rel="noopener noreferrer">
            <Button content="Open CASTUS Cloud Portal" positive/>
          </a>
        </Segment>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  ...state.cloud
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...actions
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(CloudServices)
