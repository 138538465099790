

/**
 * TEXT ITEM AND RSS FEED PARSER/SERIALIZER
 */

/**
 * Parses a text item or rss feed and returns the appropriate store state based on the file
 * @param {string} textItem The string file contents of the text item or rss feed
 */
export const parseTextItem = (textItem) => {

  let textItemLines = textItem.split("\n")
  let toReturn = {
    style: {}
  }
  let previewText = []
  textItemLines.forEach((line, lineNumber) => {
    //First line should be type of file, otherwise it is not a text item file
    if(lineNumber === 0) {
      //PARSE FILE TYPE
      if(!line.startsWith('#Castus')) {
        let err = new Error("File is not a text item!")
        err.type = 'NOT_TEXT_ITEM'
        throw err
      // Not using exact values, just in case of "#Castus5RSS" or something in the future
      } else if (line.search(/RSS/) > -1) {
        toReturn.type = 'rss'
      } else if (line.search(/ScrollText/) > -1) {
        toReturn.type = 'scrolling text'
      } else {
        let err = new Error("File is not a text item!")
        err.type = 'NOT_TEXT_ITEM'
        throw err
      }
    } else if(line.startsWith('#')) {
      line = line.slice(1)
      if(line.startsWith('-')) {
        line = line.slice(1)
      }
      if(line.includes('=')) {
        //PARSE STYLE LINE
        let [key, value] = line.split('=', 2)
        // Just in case Jonathan ever put url and interval in lines starting with #
        if(key === 'url') {
          toReturn.url = value
        } else if (key === 'interval') {
          toReturn.interval = value
        } else {
          toReturn.style[key] = value
        }
      }
    } else {
      if(line.includes('=')) {
        //PARSE RSS URL/INTERVAL
        let [key, value] = line.split('=', 2)
        if(key === 'url') {
          toReturn.url = value
        } else if (key === 'interval') {
          toReturn.interval = value
        } else {
          toReturn[key] = value
        }
      } else {
        previewText.push(line)
      }
    }
  })

  toReturn.previewText = previewText.join("\n")
  return toReturn
}

/**
 * Takes content and style information from store state and creates the contents for a
 * scrolling text item based on that state
 * @param {object} textItem The text item data from the state
 * @param {object} textItem.style An object containing the style tags of the text item
 * @param {string} textItem.previewText For scrolling text, the text to be contained in the scrolling text item
 * @param {string} textItem.url For rss feeds, the url to pull the feed from
 * @param {number} textItem.interval For rss feeds, the time interval for performing rss feed updates
 * @param {string} mode Whether to serialize the text item as an rss feed ("rss") or scrolling text item ("scrolling text")
 *  If neither "rss" nor "scrolling text" are passed, this function will throw an error
 */
export const serializeTextItem = (textItem, mode) => {
  let typeLine = ''
  let otherLines = []
  switch(mode) {
    case 'rss':
      typeLine = '#Castus4RSS'
      otherLines.push(`url=${textItem.url}`)
      otherLines.push(`interval=${textItem.interval}`)
      break;
    case 'scrolling text':
      typeLine = '#Castus4ScrollText'
      otherLines = textItem.previewText.split("\n")
      break;
    default:
      throw new Error(`Invalid text item serialization mode: ${mode}! Expected either "rss" or "scrolling text"`);
  }
  let {style} = textItem
  let styleLines = Object.entries(style).map(([key, value]) => `#-${key}=${value}`)
  let lines = [typeLine, ...styleLines, ...otherLines].join("\n")
  return lines
}
