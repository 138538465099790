import React, {PureComponent} from 'react'
import {Segment,
  Button,
  Icon,
  Checkbox,
  Input,
  Label,
  Popup} from 'semantic-ui-react'

import {DragSource, DropTarget} from 'react-dnd'

import './Region.css'

export const DRAG_CONSTANT = Symbol('global region drag')

// patharray is Array [ { object } ]
// where object = {item: patharray}
// where patharray = [ "" ] if not assigned or
//       patharray = [ "", "mnt", "main", "file", "path" ]
// FIXME: The array should be EMPTY if nothing assigned, and get rid of the empty array element at the beginning!
function hasPathItem(patharray) {
  if (patharray === undefined) return false
  if (!(patharray instanceof Array)) return false
  if (patharray.length === 0) return false
  // further checks: an unassigned item will have item = [ "" ], check for that too
  if (patharray[0] === undefined) return false
  if (patharray[0].item === undefined) return false
  if (patharray[0].item.length === 0) return false
  if (patharray[0].item.length === 1) { // NTS: item[0] == "" doesn't mean anything, a valid path has [ "", "mnt", "main", ... ]
    if (patharray[0].item[0] === "") return false // [ "" ]
  }
  return true
}

class GlobalMediaRegion extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {renaming: false}
    this.nameInput = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.renaming &&
      !prevState.renaming &&
      this.nameInput.current) {
      this.nameInput.current.focus()
    }
  }

  handleDeleteRegion = (e, data) => {
    let {changeOptions, moveFrame, resizeFrame, index, assignItem} = this.props
    let {options} = this.props.frame

    // refuse if locked, React native won't refuse onclick on our behalf just because the disabled condition is true
    if (options.locked) return

    if (options.enabled >= 0) {
      // TODO: Prompt to confirm
      changeOptions(index, {enabled: -1})
      assignItem(index, [ "" ])
      // in case the frame was put way out of bounds, restore to normal
      // these values are consistent with the V4 global regions editor,
      // though as a team, a discussion could be brought up what default
      // size and position to use.
      if (index > 0) {
        resizeFrame(index, 30, 30)
        moveFrame(index, 10, 10)
      }
      else { // main region
        resizeFrame(index, 100, 100)
        moveFrame(index, 0, 0)
      }
    }
  }

  handleOptionToggle = (e, data) => {
    let {changeOptions, index, main} = this.props
    let {options} = this.props.frame
    let {frame} = this.props
    let toToggle = data.name
    if(toToggle === 'locked' && main) {
      return
    }
    if (toToggle === 'enabled') {
      // if a region is deleted (enable === -1) clicking the checkbox should create it
      if (options[toToggle] < 0) {
        changeOptions(index, {[toToggle]: 1})
        return
      }
      // if a region is enabled and nothing is assigned, clicking the checkbox should delete it again
      if (options[toToggle] > 0) {
        if (!hasPathItem(frame.timeline)) {
          changeOptions(index, {[toToggle]: -1})
          return
        }
      }
      // else toggle as normal
      changeOptions(index, {[toToggle]: (options[toToggle] > 0 ? 0 : 1)})
      return
    }

    if(options[toToggle] === false ||
      (toToggle === 'locked' && options[toToggle] !== true)) {
      changeOptions(index, {[toToggle]: true})
    } else {
      changeOptions(index, {[toToggle]: false})
    }
  }

  handleVolumeChange = (e, data) => {
    let {changeOptions, index, main} = this.props
    let {options} = this.props.frame
    if(main || (options && options.locked)) {
      return
    }
    let toChange = {'volume': `${data.value}`}
    if(data.value === 0 || data.value === '0') {
      toChange.audio = false
    } else {
      toChange.audio = true
    }
    changeOptions(index, toChange)
  }

  handleAssignItem = () => {
    let {selectedFile,
      changeOptions,
      frame,
      index,
      assignItem} = this.props
    let {options} = this.props.frame

    // refuse if locked, React native won't refuse onclick on our behalf just because the disabled condition is true
    if (options.locked) return

    if(selectedFile && frame.options.locked !== true) {
      if(selectedFile instanceof Array && selectedFile[0] !== '') {
        selectedFile = ['', ...selectedFile]
      }
      assignItem(index, selectedFile)
    }

    // if region was deleted, automatically create the region whether or not this function assigned anything,
    // because the deleted case (with nothing assigned) will show "Create region"
    if (frame.options.enabled < 0)
      changeOptions(index, {enabled: 1})
  }

  handleBeginRenaming = () => {
    let {frame} = this.props
    if(frame.options.locked !== true) {
      this.setState({renaming: true})
    }
  }

  rename = (e) => {
    let {renameFrame, index} = this.props
    renameFrame(index, e.currentTarget.value)
    this.setState({renaming: false})
  }

  render() {
    let {frame,
      index,
      main,
      isDragging,
      connectDragSource,
      connectDropTarget} = this.props
    let {renaming} = this.state

    if(isDragging) {
      return null;
    }

    let name = frame.name || `Region ${index + 1}`
    let hasItem = hasPathItem(frame.timeline) && frame.options.enabled >= 0
    let item = hasItem ? frame.timeline[0].item.slice(-1)[0] : ((frame.options.enabled >= 0) ? '(Nothing Assigned)' : 'Create region')
    let volume = frame.options.volume ? parseInt(frame.options.volume, 10) : 100
    let enabled = frame.options.enabled
    let audio = frame.options.audio !== false
    let video = frame.options.video !== false
    let locked = (main || frame.options.locked) === true

    let audioIcon = audio ? 'volume up' : 'volume off'
    let videoIcon = video ? 'eye' : 'eye slash'
    let lockIcon = locked ? 'lock' : 'unlock'

    let enabledTitle = enabled > 0 ? 'Disable' : 'Enable'

    let segmentSettings = {}
    if(main) {
      name = `${name} (Main Region)`
      segmentSettings = {
        color: 'green'
      }
    }
    if(enabled === 0) {
      segmentSettings = {
        color: 'red',
        inverted: true
      }
    }

    let nameDisplay = renaming ?
      <Input size='small'
        className='RegionName'
        placeholder={`Frame ${index}`}
        defaultValue={frame.name}
        ref={this.nameInput}
        onBlur={this.rename}
        onKeyPress={(e) => {if(e.key === 'Enter') this.rename(e)}}/> :
      <span className="RegionName noselect" onDoubleClick={this.handleBeginRenaming}>{name}</span>

    let contents = connectDropTarget(
      <div className="RegionContents">
        <div className="RegionLabel">
          {main ? null : <Checkbox title={enabledTitle}
            checked={enabled > 0}
            name='enabled'
            onChange={this.handleOptionToggle}/>}
          {nameDisplay}
        </div>
        <div className="RegionControls">
          {main ? null : <Button.Group compact basic size="small">
            <Button icon
              compact
              title='Lock Region'
              name='locked'
              onClick={this.handleOptionToggle}>
              <Icon name={lockIcon} inverted={enabled === 0} color={(locked && enabled >= 0) ? 'red' : undefined}/>
            </Button>
            <Button icon
              compact
              title='Toggle Video'
              name='video'
              onClick={this.handleOptionToggle}>
              <Icon name={videoIcon} inverted={enabled === 0} color={(!video && enabled >= 0) ? 'red' : undefined}/>
            </Button>
            <Popup trigger={
              <Button icon
                compact
                title='Toggle Audio'
                name='audio'>
                <Icon name={audioIcon} inverted={enabled === 0} color={((!audio || volume === 0) && enabled >= 0) ? 'red' : undefined}/>
              </Button>}
              content={
              <Input type="range"
                  min="0"
                  max="100"
                  value={volume}
                  inline
                  onChange={this.handleVolumeChange}>
                  <input style={{ border: '0px', background: 'inherit', flex: 'initial', margin: 'auto'}}/>
                  <Label basic style={{ marginLeft: '-1px', border: '0px', background: 'inherit', paddingLeft: '0'}}>{volume}%</Label>
              </Input>}
              on='click'
              hideOnScroll/>
          </Button.Group>}
        </div>
        <div className="RegionItem noselect">
          <Button title={item}
            onClick={this.handleAssignItem}
            basic
            color={hasItem ? "black" : "grey"}
            inverted={enabled === 0}>
            {item}
          </Button>
        </div>
        <div className="RegionControls noselect">
          <Button icon
            compact
            title='Delete Region'
            name='deleteregion'
            onClick={this.handleDeleteRegion}>
            <Icon name='remove' disabled={enabled < 0 || locked} inverted={enabled === 0} color={(locked && enabled >= 0) ? 'red' : undefined}/>
          </Button>
        </div>
      </div>
    )

    return (
      <Segment {...segmentSettings}>
        {renaming ?
          contents :
          connectDragSource(contents)}
      </Segment>
    )
  }

}

const beginDrag = (props, monitor, component) => {
  return {
    initialIndex: props.index,
  }
}

const endDrag = (props, monitor) => {
  if(monitor.didDrop()) {
    let {index} = monitor.getDropResult()
    props.reorderFrame(props.index, index)
  }
}

const canDrag = (props, monitor) => {
  return (props.frame.options.locked !== true)
}

const dragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
})

const canDrop = (props, monitor) => {
  return (props.frame.options.locked !== true)
}

const drop = (props, monitor) => {
  if(props.frame && props.index !== monitor.getItem().initialIndex) {
    return {index: props.index}
  } else if(!props.frame) {
    return {index: props.frameCount}
  }
}

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget()
})

export default DropTarget(DRAG_CONSTANT, {drop, canDrop}, dropCollect)(
  DragSource(DRAG_CONSTANT, {beginDrag, endDrag, canDrag}, dragCollect)(GlobalMediaRegion)
)
