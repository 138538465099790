import {fetchFromServer, connectToServerSocket} from 'helpers/net_helpers'
import {getIn, setIn} from 'helpers/general_helpers'
import {messages} from 'redux/messages'
import moment from 'moment'

import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'

export const EVENT_MODAL = Symbol('open save modal')
export const CLOSE_EVENT_MODAL = Symbol('close save modal')
export const EVENT_MODAL_TYPE = Symbol('event modal type')
export const OPEN_ERROR_TEXT = Symbol('open error text')
export const CLOSE_ERROR_TEXT = Symbol('close error text')
export const EVENT_NAME_VALUE = Symbol('event name value')
export const EVENT_START_DATE_VALUE = Symbol('event start date value')
export const EVENT_START_TIME_VALUE = Symbol('event start time value')
export const EVENT_FINISH_DATE_VALUE = Symbol('event finish date value')
export const EVENT_FINISH_TIME_VALUE = Symbol('event finish time value')
export const EVENT_REPEAT_VALUE = Symbol('event repeat value')
export const ADD_NEW_EVENT = Symbol('set new event')

const SET_CURRENT_INPUT = Symbol('set current input');
const SET_RECORDING_SOCKET = Symbol('set recording socket');
const UPDATE_RECORDING_STATUS = Symbol('update recording status');
const UPDATE_ASSOCIATIONS = Symbol('update associations');
const UPDATE_EVENTS = Symbol('update events');
const SET_RECORDING_PREVIEW = Symbol('set recording preview');
const SELECT_ASSOCIATION = Symbol('select association');

export const actions = {

    setCurrentInput: (inputName) => {
      return async (dispatch, getState) => {
        let {currentInput, serverSocket} = getState().output_recording
        // If we are currently watching updates from an input, unsubscribe from those updates
        if(currentInput && serverSocket) {
          serverSocket.emit('stop', currentInput);
        }
        dispatch({
          type: SET_CURRENT_INPUT,
          payload: inputName
        })
      }
    },

    connectToRecordingSocket: () => {
      return async (dispatch, getState) => {
        if(getState().output_recording.serverSocket) {
          return
        }
        var connection = connectToServerSocket('/recording');
        if(!connection) {
          return
        }
        connection.on("ready", () => {
          dispatch({
            type: SET_RECORDING_SOCKET,
            payload: connection
          })
        })

        connection.on("update", (name, data) => {
          if(data.thumbnail) {
            if(data.thumbnail instanceof ArrayBuffer) {
              dispatch({
                type: SET_RECORDING_PREVIEW,
                payload: {
                  name,
                  data: data.thumbnail
                }
              })
            }
            delete data.thumbnail
          }
          dispatch({
            type: UPDATE_RECORDING_STATUS,
            payload: {
              name,
              data
            }
          })
        })
      }
    },

    pollRecordingStatus: () => {
      return (dispatch, getState) => {
        let {currentInput, serverSocket} = getState().output_recording
        if(!serverSocket) {
          dispatch(actions.connectToRecordingSocket())
          return
        }
        serverSocket.emit('watch', currentInput)
      }
    },

    startRecording: () => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().output_recording
        let res = await fetchFromServer(`/v2/recording/start/${currentInput}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error starting the recording: ${text}`, {level: 'error'}))
        }
      }
    },

    stopRecording: () => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().output_recording
        let res = await fetchFromServer(`/v2/recording/stop/${currentInput}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error stopping the recording: ${text}`, {level: 'error'}))
        }
      }
    },

    renameRecording: (newName) => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().output_recording
        let res = await fetchFromServer(`/v2/recording/rename/${currentInput}`, {
          method: 'POST',
          body: `${newName}`
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error renaming the recording: ${text}`, {level: 'error'}))
        }
      }
    },

    goLive: (channelNumber) => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().output_recording
        let res = await fetchFromServer(`/v2/recording/live/start/${currentInput}?channel=${channelNumber}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error going live on Channel ${channelNumber}: ${text}`, {level: 'error'}))
        }
      }
    },

    endLive: (channelNumber) => {
      return async (dispatch, getState) => {
        let res = await fetchFromServer(`/v2/recording/live/stop?channel=${channelNumber}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error stopping live on Channel ${channelNumber}: ${text}`, {level: 'error'}))
        }
      }
    },

    fetchAssociations: (input=null) => {
      return async (dispatch, getState) => {
        if(!input) {
          input = getState().output_recording.currentInput
        }
        let res = await fetchFromServer(`/v2/recording/associations/${input}`)
        if(!res.ok) {
          if(res.status === 404) {
            return
          } else {
            let text = await res.text()
            dispatch(messages.alert(`There was an error fetching the input associations: ${text}`, {level: 'error'}))
          }
        } else {
          let associations = await res.json()
          dispatch({
            type: UPDATE_ASSOCIATIONS,
            payload: {
              name: input,
              data: associations
            }
          })
        }
      }
    },

    selectAssociation: (value) => ({
      type: SELECT_ASSOCIATION,
      payload: value
    }),

    applyAssociation: (association=null, input=null) => {
      return async (dispatch, getState) => {
        if(!input) {
          input = getState().output_recording.currentInput
        }
        if(!association) {
          if(getState().output_recording.selectedAssociation) {
            association = getState().output_recording.selectedAssociation
          } else {
            return
          }
        }
        let res = await fetchFromServer(`/v2/recording/associations/${input}`, {
          method: 'POST',
          body: `${association}`
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error applying the input association: ${text}`, {level: 'error'}))
        }
      }
    },

    fetchEvents: () => {
      return async (dispatch, getState) => {
        let res = await fetchFromServer(`/v2/recording/output/events`)
        if(!res.ok) {
          let error = 'Unknown Error'
          if(res.headers.get("Content-Type").startsWith("application/json")) {
            let errObject = await res.json();
            error = errObject.stderr;
          } else {
            error = await res.text();
          }
          dispatch(messages.alert(`There was an error fetching the recording events: ${error}`, {level: 'error'}))
        } else {
          let events = await res.json()
          // Convert event start and end times from string format to moments
          events = Object.entries(events).map(([id, evnt]) => {
            let {start, end} = evnt
            start = moment(start, "YYYY-MM-DD HH:mm:ss")
            end = moment(end, "YYYY-MM-DD HH:mm:ss")
            return {
              ...evnt,
              id: parseInt(id, 10),
              start,
              end
            }
          })
          dispatch({
            type: UPDATE_EVENTS,
            payload: events
          })
        }
      }
    },

    /**
     * Open save Modal
     */
    eventModalOpen: () => (dispatch) =>{
        dispatch({
            type: EVENT_MODAL,
        })
        dispatch({
            type: EVENT_MODAL_TYPE,
            payload: {
              type: 'add',
              index: -1
            }
        });
    },

    /**
     * Close Save Modal
     */
    eventModalClose: () => (dispatch) => {
        dispatch({
            type: CLOSE_EVENT_MODAL,
        })
    },

    /**
     * Opens the event editing modal
     */
    editEventModal: (event) => (dispatch, getState) => {
        dispatch({
            type: EVENT_MODAL,
        });
        dispatch({
            type: EVENT_MODAL_TYPE,
            payload: {
              type: 'edit',
              index: event.id
            }
        });
        dispatch(actions.newEventName(event['recording name']));
        dispatch(actions.newEventStartDate(event.start));
        dispatch(actions.newEventStartTime(event.start));
        dispatch(actions.newEventFinishDate(event.end));
        dispatch(actions.newEventFinishTime(event.end));
        dispatch(actions.newEventRepeat(event.repeat));
    },
    /**
     * Opens modal to confirm event deletion
     */
    deleteEventModal: (event) => (dispatch, getState) => {
        dispatch({
            type: EVENT_MODAL,
        });
        dispatch({
            type: EVENT_MODAL_TYPE,
            payload: {
              type: 'delete',
              index: event.id
            }
        });
    },
    /**
     * Delete an event
     */
    deleteEvent: (id) => async (dispatch, getState) => {
        let {
            output_recording
        } = getState();
        let toDelete = output_recording.events[id]
        let evntName = `${toDelete.output}-${toDelete.start.format("m-H-D-M")}`
        let jobId = dispatch(loading.startLoading(getState().output_recording._loaderID))
        let res = await fetchFromServer(`/v2/recording/output/events/${evntName}`, {
          method: 'DELETE'
        })
        dispatch(loading.finishLoading(getState().output_recording._loaderID, jobId))
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error deleting the event: ${text}`, {level: 'error'}))
        } else {
          dispatch(actions.fetchEvents())
        }
    },
    /**
     * Add a New Event
     */
    addNewEvent: (eventData, currentOutput) => async (dispatch, getState) => {
        let {name, startDate, endDate, repeat} = eventData
        // Convert to moment for now, moment will be removed later
        if(!moment.isMoment(startDate)) {
          startDate = moment(startDate)
        }
        if(!moment.isMoment(endDate)) {
          endDate = moment(endDate)
        }
        // I assume selectedMediaItem is selectedFiles[0]
        let startString = `${startDate.format("MM/DD/YYYY")}, ${startDate.format("HH:mm:ss")}`
        let endString = `${endDate.format("MM/DD/YYYY")}, ${endDate.format("HH:mm:ss")}`
        let newEvent = {
          'recording name': name,
          output: currentOutput,
          encoder: 0,
          start: moment(startString, 'MM/DD/YYYY, hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss'),
          end: moment(endString, 'MM/DD/YYYY, hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss'),
          repeat,
        }
        dispatch({
            type: CLOSE_EVENT_MODAL,
        })
        let jobId = dispatch(loading.startLoading(getState().output_recording._loaderID))
        let res = await fetchFromServer('/v2/recording/output/events', {
          method: 'PUT',
          body: JSON.stringify(newEvent),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        dispatch(loading.finishLoading(getState().output_recording._loaderID, jobId))
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error adding the event: ${text}`, {level: 'error'}))
        } else {
          dispatch(actions.fetchEvents())
        }
    },

    /**
     * Modifies a pre-existing event
     * @param {number} id The id of the event to modify
     * @param {string} field The key of the field to modify
     * @param {*} value The new value to assign to that key
     * @param {string} currentOutput The string id of the output that the edited event belongs to
     */
    editEvent: (id, field, value, currentOutput) => async (dispatch, getState) => {
      let {output_recording} = getState()
      let eventData = getIn(output_recording, ["events", id])
      if(!eventData) {
        console.error(`The event with id ${id} associated with output ${currentOutput} does not exist, so it cannot be edited.`)
        return
      }
      eventData = {
        ...eventData,
        [field]: value
      }
      // Different key names
      eventData.startDate = eventData.start
      eventData.endDate = eventData.end
      await dispatch(actions.deleteEvent(id))
      return dispatch(actions.addNewEvent(eventData, currentOutput))
    },

    eventModalType: (value) => ({
        type: EVENT_START_DATE_VALUE,
        payload: value
    }),

    /**
     * Set Event Name Value
     * @param {string} value
     */
    newEventName: (value) => ({
        type: EVENT_NAME_VALUE,
        payload: value
    }), 

    /**
     * Set New Event Start Date Value
     * @param {string} value
     */
    newEventStartDate: (value) => ({
        type: EVENT_START_DATE_VALUE,
        payload: value
    }),

    /**
     * Set New Event Start Time Value
     * @param {string} value
     */
    newEventStartTime: (value) => ({
        type: EVENT_START_TIME_VALUE,
        payload: value
    }),  

    /**
     * Set New Event Finish Date Value
     * @param {string} value
     */
    newEventFinishDate: (value) => ({
        type: EVENT_FINISH_DATE_VALUE,
        payload: value
    }),

    /**
     * Set New Event Finish Time Value
     * @param {string} value
     */
    newEventFinishTime: (value) => ({
        type: EVENT_FINISH_TIME_VALUE,
        payload: value
    }),  

    /**
     * Set New Event Finish Time Value
     * @param {string} value
     */
    newEventRepeat: (value) => ({
        type: EVENT_REPEAT_VALUE,
        payload: value
    }),    
};

const initialState = {
    currentInput: '',     // Currently viewed input
    recordingStatus: {},  // Hash of recording status objects by input name
    recordingPreview: {}, // Hash of recording preview blob urls by input name
    associations: {},     // Hash of associations by input name
    serverSocket: null,   // Connection to server event socket
    addEventModalStatus: false,
    eventModalId: null,     // ID of the event being edited in the event modal, or null if it is a new event
    events: [],
    selectedAssociation: null,    // Currently selected routing association
}

const reducer = (state = initialState, action) => {

    let {
        type,
        payload
    } = action;

    switch (type) {
        case EVENT_MODAL:
            return {
                ...state,
                eventModalStatus: true
            }
        case CLOSE_EVENT_MODAL:
            return {
                ...state,
                eventModalStatus: false
            }
        case EVENT_MODAL_TYPE:
            return {
                ...state,
                eventModalType: payload.type,
                eventModalId: payload.index
            }
        case EVENT_NAME_VALUE:
            return {
                ...state,
                newEventNameValue: payload
            }
        case EVENT_START_DATE_VALUE:
            return {
                ...state,
                newEventStartDate: payload
            }
        case EVENT_START_TIME_VALUE:
            return {
                ...state,
                newEventStartTime: payload
            }
        case EVENT_FINISH_DATE_VALUE:
            return {
                ...state,
                newEventFinishDate: payload
            }
        case EVENT_FINISH_TIME_VALUE:
            return {
                ...state,
                newEventFinishTime: payload
            }
        case EVENT_REPEAT_VALUE:
            return {
                ...state,
                newEventRepeat: payload
            }
        case OPEN_ERROR_TEXT:
            return {
                ...state,
                errorText: true
            }
        case ADD_NEW_EVENT:
            return {
                ...state,
                events: payload
            }
        case SET_RECORDING_SOCKET:
            return {
                ...state,
                serverSocket: payload
            }
        case SET_CURRENT_INPUT:
            return {
                ...state,
                currentInput: payload
            }
        case UPDATE_RECORDING_STATUS:
          return {
              ...state,
              recordingStatus: {
                ...state.recordingStatus,
                [payload.name]: payload.data
              }
          }
        case UPDATE_ASSOCIATIONS:
          return {
              ...state,
              associations: {
                ...state.associations,
                [payload.name]: payload.data
              }
          }
        case UPDATE_EVENTS:
          return {
            ...state,
            events: payload
          }
        case SET_RECORDING_PREVIEW: {
          let {name, data} = payload
          if(!name) {
            return state
          }
          let thumb = new Blob([data], {type: 'image/jpeg'})
          if(thumb.size === 0) {
            return state
          }
          let url = URL.createObjectURL(thumb)
          if(getIn(state, ['recordingPreview', name, 'current'])) {
            URL.revokeObjectURL(getIn(state, ['recordingPreview', name, 'current']))
          }
          return setIn(state, ['recordingPreview', name, 'current'], url)
        }
        case SELECT_ASSOCIATION: {
          return {
            ...state,
            selectedAssociation: payload
          }
        }
        default:
            return state;
    }
}

export default loaderReducer(reducer, initialState)
