import React, {PureComponent} from 'react'

import {iframeBasename} from 'helpers/net_helpers'

import './Reporting.css'

export default class Reporting extends PureComponent {

  render() {
    let reportingUrl = `${iframeBasename()}/quickroll4/reporting/`
    return (
      <div id='reportingAppContainer'>
        <iframe title='Reporting' id='reportingApp' src={reportingUrl}>
          <div>Loading reporting app...</div>
        </iframe>
      </div>
    )
  }

}
