import React, { PureComponent } from 'react';
import {Button} from 'semantic-ui-react';

class FileTransferButtons extends PureComponent {

  render() {
    let {id,
      type,
      state,
      to,
      ...actions} = this.props

    let showPause = (state === 'ongoing' && type === 'vod')
    let showResume = (state === 'paused' && type === 'vod')
    let showCancel = (state === 'ongoing' || state === 'paused')
    let showRestart = ((state === 'success' || state === 'error') && type === 'vod')
    let showEditMetadata = (type === 'upload' && to)

    return (
      <Button.Group compact>
        {showPause ? <Button key={'pause'}
          icon='pause'
          content='Pause'
          onClick={() => {actions.pauseFileTransfer(id)}}/> : null}
        {showResume ? <Button key={'resume'}
          icon='play'
          content='Resume'
          onClick={() => {actions.resumeFileTransfer(id)}}/> : null}
        {showCancel ? <Button key={'cancel'}
          icon='stop'
          content='Stop'
          onClick={() => {actions.cancelFileTransfer(id)}}/> : null}
        {showRestart ? <Button key={'restart'}
          icon='redo'
          content='Restart'
          onClick={() => {actions.restartFileTransfer(id)}}/> : null}
        <Button key={'clear'}
          icon='remove'
          content='Clear'
          onClick={() => {actions.clearFileTransfer(id)}}/>
        {showEditMetadata ? <Button key={'edit metadata'}
          icon='edit'
          content='Edit Metadata'
          onClick={() => {actions.editMetadata(to)}}/> : null}
      </Button.Group>
    )
  }

}

export default FileTransferButtons
