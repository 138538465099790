import React, {PureComponent} from 'react'
import moment from 'moment'
import TimeInput from 'components/TimeInput/TimeInput'
import {Form} from 'semantic-ui-react'

export default class TimePrompt extends PureComponent {

  firstInput = React.createRef()

  componentDidMount() {
    if(this.firstInput.current) {
      this.firstInput.current.focus()
    }
  }

  handleChange = (newVal) => {
    let {value, changeValue, options} = this.props
    if(moment.isMoment(value) &&
      moment.isMoment(newVal) &&
      newVal._i &&
      (newVal._i.endsWith("a") || newVal._i.endsWith("p"))) {
      newVal = newVal._i.slice(0, -2)
    }
    if(typeof newVal === 'string') {
      let formats = ['h:mm a', 'h:mm:ss a']
      if(options.subseconds) {
        formats = [...formats, 'h:mm:ss.SSS a']
      }
      let momVal = moment(newVal, formats, true)
      if(momVal.isValid()) {
        newVal = momVal
      }
    }
    changeValue(newVal)
  }

  handleBlur = (value) => {
    let {changeValue, options} = this.props
    if(typeof value === 'string') {
      let formats = ['h:mm:ss a', 'h:mm a']
      if(options.subseconds) {
        formats = ['h:mm:ss.SSS a', ...formats]
      }
      let momVal = moment(value, formats, true)
      if(momVal.isValid()) {
        value = momVal
      }
    }
    changeValue(value)
  }

  render() {
    let {options} = this.props

    return (
      <Form>
        <Form.Field>
          <TimeInput showMilliseconds={options.subseconds}
            onChange={this.handleChange}
            ref={this.firstInput}/>
        </Form.Field>
      </Form>
    )
  }

}
