import React, {PureComponent} from 'react'
import {Popup, Menu, Icon, Message, Progress} from 'semantic-ui-react'
import {parseFileSize} from 'helpers/library_helpers'

export default class MenuDownloads extends PureComponent {

  render() {
    let {downloads, downloadDismiss} = this.props

    if(downloads.length <= 0) {
      return null
    }

    let downloadStatus = downloads.map((dl) => {
      let stat = null
      switch(dl.state) {
        case 'ongoing':
          stat = <div>Downloading...</div>
          break
        case 'finished':
          stat = <div>Finished</div>
          break
        case 'error':
          stat = <div>Error</div>
          break
        default:
          break
      }
      let progress = null
      if(dl.state === 'finished' && dl.url) {
        progress = (<div><a href={dl.url} download={dl.path.split('/').pop()}>Download File</a></div>)
      } else if (dl.lengthComputable) {
        progress = (<Progress percent={Math.floor((dl.loaded / dl.total) * 100)}
            progress='percent'
            label={`${parseFileSize(dl.loaded)}/${parseFileSize(dl.total)}`}
            indicating
            size='small'
            error={dl.state === 'error'}
            autoSuccess/>)
      }
      return (<Message success={dl.state === 'finished'}
        key={dl.path}
        error={dl.state === 'error'}
        onDismiss={() => {downloadDismiss(dl.path.split('/'))}}>
        <Message.Header>{stat}</Message.Header>
        {dl.path ? dl.path : 'Unknown Download'}
        {progress}
      </Message>)
    })

    return (
      <Popup wide trigger={
        <Menu.Item>
          <Icon name='download'/>
          Downloads
        </Menu.Item>
        } on="click">
        {downloadStatus}
      </Popup>
    )
  }

}
