import convert from 'xml-js'
import {fetchFromServer, fetchFileFromServer} from 'helpers/net_helpers'
import {parseTextItem, serializeTextItem} from 'helpers/text_item_helpers'
import {messages} from 'redux/messages'
import {saveFile} from 'redux/file_list'
import tabbedReducer from 'redux/higher_order_reducers/tabbedReducer'

export const JUSTIFY = Symbol('justify')
export const SPACE = Symbol('space')
export const SCROLL_SPEED = Symbol('scroll speed')
export const SMOOTH_SCROLL = Symbol('smooth scroll')
export const FONT_COLOR = Symbol('font color')
export const FONT_OPACITY = Symbol('font opacity')
export const BACKGROUND_COLOR = Symbol('background color')
export const BACKGROUND_OPACITY = Symbol('background opacity')
export const SHADOW_COLOR = Symbol('drop shadow color')
export const SHADOW_OPACITY = Symbol('drop shadow opacity')
export const SHADOW_BLUR = Symbol('drop shadow blur')
export const SHADOW_X = Symbol('shadow x offset')
export const SHADOW_Y = Symbol('shadow y offset')
export const FONT_FAMILY = Symbol('font family')
export const FONT_STYLE = Symbol('font style')
export const FONT_SCALE = Symbol('font scale')
export const MULTI_LINE = Symbol('multi line')
export const EMOJI = Symbol('emoji set')
export const SCROLLING_TEXT = Symbol('scrolling text')
export const SCROLLING_TEXT_PREVIEW = Symbol('scrolling text preview')
export const SET_ITEM_NAME = Symbol('set item name')
export const SET_ITEM_PATH = Symbol('set item path')
export const ITEM_NAME_VALUE = Symbol('item name value')
export const OPEN_SAVE_MODAL = Symbol('open save modal')
export const CLOSE_SAVE_MODAL = Symbol('close save modal')
export const OPEN_ERROR_TEXT = Symbol('open error text')
export const CLOSE_ERROR_TEXT = Symbol('close error text')
export const URL = Symbol('url')
export const INTERVAL = Symbol('interval')

export const LOAD_RSS_FILE = Symbol('load rss file')
export const SET_WAITING_TO_SAVE_RSS = Symbol('set waiting to save rss')

export const RSS_FEED_CREATE_TAB = Symbol('scrolling text create tab')
export const RSS_FEED_CHANGE_TAB = Symbol('scrolling text change tab')
export const RSS_FEED_CLOSE_TAB = Symbol('scrolling text close tab')

export const changeTab = (index) => ({
  type: RSS_FEED_CHANGE_TAB,
  payload: index
})

export const createTab = (path) => {
  let payload = {}
  if(path) {
    payload = {
      itemPath: path.slice(0, -1),
      itemName: path.slice(-1)[0]
    }
  }
  return {
    type: RSS_FEED_CREATE_TAB,
    payload
  }
}

export const deleteTab = (index) => ({
  type: RSS_FEED_CLOSE_TAB,
  payload: index
})

export const RSS_FEED_DISPLAY = (props) => {
  if(props.itemName) {
    return props.itemName
  } else {
    return '(NEW TEXT ITEM)'
  }
}

export const actions = {

    /**
     * Fetches the rss feed
     */
    loadData: () => {
      // an async function returns a promise that either resolves with the function's return value,
      //  or rejects if the function throws
      return async (dispatch, getState) => {
        try {
          let url = getState().local.url
          // the await here treats the fetch as synchronous within the context of this function.
          //  When the promise returned by fetch resolves, the value that the promise resolves with is
          //  assigned to str. If the promise rejects, then the rejection value is thrown.
          url = encodeURIComponent(url);
          let str = await fetchFromServer(`/v2/other/rss?url=${url}`);
          if(!str.ok) {
            throw new Error(str)
          }
          str = await str.text();
          // The rest is the same
          dispatch(actions.setScrollingText(str));
          const converted = JSON.parse(convert.xml2json(str, {compact: true, ignoreComment: true, spaces: 4}));
          let itemTitle = '';
          if(converted.rss.channel.item instanceof Array) {
            converted.rss.channel.item.forEach(element => {
                itemTitle += `${element.title._text} * `;
            });
          } else if(typeof converted.rss.channel.item === 'object') {
            itemTitle += `${converted.rss.channel.item.title._text} * `;
          }
          itemTitle = itemTitle.substr(0, parseInt(itemTitle.length - 2, 10));
          dispatch(actions.setScrollingTextPreview(itemTitle));
        } catch(err) {
          // console.log(err);
          dispatch(actions.setScrollingText(''));
          dispatch(actions.setScrollingTextPreview(''));
          dispatch(messages.alert(`Couldn't get the RSS Feed\n ${err}`, {level: 'error'}));
        }
      }
    },

    loadRssFeedFile: (path) => {
      return async (dispatch, getState) => {
        let data = await fetchFileFromServer(path)
        if(data.ok) {
          data = await data.text()
          try {
            data = parseTextItem(data)
          } catch (err) {
            if(err.type === 'NOT_TEXT_ITEM') {
              console.error(`File ${path.join('/')} is not a text item.`)
              return
            } else {
              throw err
            }
          }
          dispatch({
            type: LOAD_RSS_FILE,
            payload: data
          })
        } else {
          console.error("Error Loading Text Item")
        }
      }
    },

    saveRssFeedFile: () => {
      return async (dispatch, getState) => {
        let {itemPath, itemName, url, interval, style} = getState().local
        let textItem = serializeTextItem({url, interval, style}, 'rss')
        let dest = itemPath.join('/')
        let name = itemName
        if(!name) {
          return dispatch(actions.saveRssFeedFileAs())
        }
        if(!dest) {
          dest = 'mnt/main/RSS Feeds'
        }
        let source = new File([textItem], name, {type: 'text/plain'})
        dispatch.global(saveFile(source, dest, {
          createMetadata: true,
          onSuccess: () => {dispatch.global(messages.alert(`${itemName} was saved successfully! It was saved at ${dest}/${name}.`, {level: "success"}))},
          onError: (res) => {dispatch.global(messages.alert("There was an error saving the text item!", {level: 'error'}))}
        }));
      }
    },

    saveRssFeedFileAs: () => {
      return async (dispatch, getState) => {
        let {itemPath, itemName} = getState().local
        let dest = itemPath
        let name = itemName
        let value = await dispatch.global(messages.promptAsync("Save Rss Feed", {
          type: 'path',
          validate: (result) => (result.filename !== ''),
          invalidText: 'That is not a valid file name.',
          initialValue: {filename: name, directory: dest}
        }))
        if(value === null) {
          return
        }
        dispatch({
          type: SET_ITEM_NAME,
          payload: value.filename
        })
        dispatch({
          type: SET_ITEM_PATH,
          payload: value.directory
        })
        return dispatch(actions.saveRssFeedFile())
      }
    },

    setWaitingToSave: (value) => ({
        type: SET_WAITING_TO_SAVE_RSS,
        payload: value
    }),

    /**
     * Set Scrolling Text Preview Value
     * @param {string} value
     */
    setScrollingText: (value) => ({
        type: SCROLLING_TEXT,
        payload: value
    }),

    /**
     * Set Scrolling Text Preview Value
     * @param {string} value
     */
    setScrollingTextPreview: (value) => ({
        type: SCROLLING_TEXT_PREVIEW,
        payload: value
    }),

    /**
     * Set Item Name Value
     * @param {string} value
     */
    setItemNameValue: (value) => ({
        type: ITEM_NAME_VALUE,
        payload: value
    }),

    /**
     * Set Scrolling Text
     */
    setTextItemName: () => (dispatch, getState) => {
        let {itemNameValue} = getState().local
        // If not entered a value, display an error message
        if (!itemNameValue) {
            dispatch({
                type: OPEN_ERROR_TEXT
            })
            return
        }
        // I assume selectedMediaItem is selectedFiles[0]
        dispatch({
            type: SET_ITEM_NAME,
            payload: itemNameValue,
        })
        // close the error text
        actions.saveModalClose();
    },

    setInterval: (value) => ({
      type: INTERVAL,
      payload: value
    }),

    /**
     * Open save Modal
     */
    saveModalOpen: () => ({
        type: OPEN_SAVE_MODAL,
    }),

    /**
     * Close Save Modal
     */
    saveModalClose: () => ({
        type: CLOSE_SAVE_MODAL,
    }),

    /**
     * Set Justify Value
     * @param {string} justify
     */
    xmlUrl: (url) => ({
        type: URL,
        payload: url
    }),

    /**
     * Set Justify Value
     * @param {string} justify
     */
    justify: (justify) => ({
        type: JUSTIFY,
        payload: justify
    }),

    /**
     * Set Space Value
     * @param {float} space
     */
    space: (space) => ({
        type: SPACE,
        payload: space
    }),

    /**
     * Set Scrool Speed Value
     * @param {float} scrollSpeed
     */
    scrollSpeed: (scrollSpeed) => ({
        type: SCROLL_SPEED,
        payload: scrollSpeed
    }),

    /**
     * Set Smooth Scroll Value
     * @param {int} smoothScroll
     */
    smoothScroll: (smoothScroll) => ({
        type: SMOOTH_SCROLL,
        payload: smoothScroll
    }),

    /**
     * Set Font Color Value
     * @param {string} fontColor
     */
    fontColor: (fontColor) => ({
        type: FONT_COLOR,
        payload: fontColor
    }),

    /**
     * Set Font Opactiy Value
     * @param {float} fontOpacity
     */
    fontOpacity: (fontOpacity) => ({
        type: FONT_OPACITY,
        payload: fontOpacity
    }),

    /**
     * Set Background Color Value
     * @param {string} backgroundColor
     */
    backgroundColor: (backgroundColor) => ({
        type: BACKGROUND_COLOR,
        payload: backgroundColor
    }),

    /**
     * Set Background Opactiy Value
     * @param {float} backgroundOpacity
     */
    backgroundOpacity: (backgroundOpacity) => ({
        type: BACKGROUND_OPACITY,
        payload: backgroundOpacity
    }),

    /**
     * Set Drop Shadow Color Value
     * @param {string} shadowColor
     */
    shadowColor: (shadowColor) => ({
        type: SHADOW_COLOR,
        payload: shadowColor
    }),

    /**
     * Set Drop Shadow Opactity Value
     * @param {float} shadowOpacity
     */
    shadowOpacity: (shadowOpacity) => ({
        type: SHADOW_OPACITY,
        payload: shadowOpacity
    }),

    /**
     * Set Drop Shadow Blur Value
     * @param {float} shadowBlur
     */
    shadowBlur: (shadowBlur) => ({
        type: SHADOW_BLUR,
        payload: shadowBlur
    }),

    /**
     * Set Shadow X Offset Value
     * @param {number} shadowX
     */
    shadowX: (shadowX) => ({
        type: SHADOW_X,
        payload: shadowX
    }),

    /**
     * Set Shadow Y Offset Value
     * @param {number} shadowY
     */
    shadowY: (shadowY) => ({
        type: SHADOW_Y,
        payload: shadowY
    }),

    /**
     * Set Font Family Value
     * @param {string} fontFamily
     */
    fontFamily: (fontFamily) => ({
        type: FONT_FAMILY,
        payload: fontFamily
    }),

    /**
     * Set Font Style Value
     * @param {string} fontStyle
     */
    fontStyle: (fontStyle) => ({
        type: FONT_STYLE,
        payload: fontStyle
    }),

    /**
     * Set Font Scale Value
     * @param {number} fontScale
     */
    fontScale: (fontScale) => ({
        type: FONT_SCALE,
        payload: fontScale
    }),

    /**
     * Set Multi Line Value
     * @param {number} multiLine
     */
    multiLine: (multiLine) => ({
        type: MULTI_LINE,
        payload: multiLine
    }),

    /**
     * Sets emoji set
     * @param {string} emoji
     */
    emoji: (emoji) => ({
        type: EMOJI,
        payload: emoji
    }),
};

const initialState = {
    itemPath: ['mnt', 'main', 'RSS Feeds'],
    itemName: '', // Item Name
    itemNameValue: '', // Typed item name
    scrollingText: '', // I'm not sure it should be here. Maybe it should be in the ScrollingTextPreview app.
    scrollingTextPreview: '', // I'm not sure it should be here. Maybe it should be in the ScrollingTextPreview app.
    style: {
      justify: 'left', // It could be only  Left - Right - Center
      space: 1, // It could be only 0.5 - 1 - 1.5  
      scrollSpeed: 1, // Float Number, Min: 0, Max: 3
      smoothScroll: 1, // Number
      fontColor: '#ffffff', // Only Hex Color Codes
      fontOpacity: 1, // Float Number, Min: 0, Max: 1
      backgroundColor: '#000000', // Only Hex Color Codes
      backgroundOpacity: 0, // Float Number, Min: 0, Max: 1
      shadowColor: '#000000', // Only Hex Color Codes
      shadowOpacity: 1, // Float Number, Min: 0, Max: 1
      shadowBlur: 2, // Float Number, Min: 0, Max: 10
      shadowX: 3, // Number, Min: 0, Max: 5
      shadowY: 3, // Float Number, Min: 0, Max: 1
      fontFamily: 'Ubuntu', // String
      fontStyle: '', // String
      fontScale: 0,
      multiLine: 0,
      emoji: '',
    },
    url: 'https://www.nhc.noaa.gov/gtwo.xml',
    saveModalStatus: false,
    errorText: false,
    interval: 900,
    waitingToSave: false
}

const integerStyles = [
  'smoothScroll',
  'shadowX',
  'multiLine'
]

const floatStyles = [
  'space',
  'scrollSpeed',
  'fontOpacity',
  'backgroundOpacity',
  'shadowOpacity',
  'shadowBlur',
  'shadowY',
  'fontScale'
]

export const reducer = (state = initialState, action) => {

    let {
        type,
        payload
    } = action;

    switch (type) {
        case LOAD_RSS_FILE: {
            let {url, interval, style} = payload
            Object.entries(style).forEach(([key, value]) => {
              if(integerStyles.includes(key)) {
                style[key] = parseInt(value, 10)
              } else if (floatStyles.includes(key)) {
                style[key] = parseFloat(value)
              }
            })
            return {
              ...state,
              url,
              interval,
              style: {...state.style, ...style}
            }
        }
        case OPEN_SAVE_MODAL:
            return {
                ...state,
                saveModalStatus: true
            }
        case CLOSE_SAVE_MODAL:
            return {
                ...state,
                saveModalStatus: false
            }
        case OPEN_ERROR_TEXT:
            return {
                ...state,
                errorText: true
            }
        case CLOSE_ERROR_TEXT:
            return {
                ...state,
                errorText: false
            }
        case SET_ITEM_NAME:
            return {
                ...state,
                itemName: payload
            }
        case SET_ITEM_PATH:
            return {
                ...state,
                itemPath: payload
            }
        case SCROLLING_TEXT:
            return {
                ...state,
                scrollingText: payload
            }
        case SCROLLING_TEXT_PREVIEW:
            return {
                ...state,
                scrollingTextPreview: payload
            }
        case JUSTIFY:
            return {
                ...state,
                style: {
                  ...state.style,
                  justify: payload
                }
            }
        case URL:
            return {
                ...state,
                url: payload
            }
        case INTERVAL:
            return {
                ...state,
                interval: payload
            }
        case SPACE:
            return {
                ...state,
                style: {
                  ...state.style,
                  space: payload
                }
            }
        case SCROLL_SPEED:
            return {
                ...state,
                style: {
                  ...state.style,
                  scrollSpeed: payload
                }
            }
        case SMOOTH_SCROLL:
            return {
                ...state,
                style: {
                  ...state.style,
                  smoothScroll: payload
                }
            }
        case FONT_COLOR:
            return {
                ...state,
                style: {
                  ...state.style,
                  fontColor: payload
                }
            }
        case FONT_OPACITY:
            return {
                ...state,
                style: {
                  ...state.style,
                  fontOpacity: payload
                }
            }
        case BACKGROUND_COLOR:
            return {
                ...state,
                style: {
                  ...state.style,
                  backgroundColor: payload
                }
            }
        case BACKGROUND_OPACITY:
            return {
                ...state,
                style: {
                  ...state.style,
                  backgroundOpacity: payload
                }
            }
        case SHADOW_COLOR:
            return {
                ...state,
                style: {
                  ...state.style,
                  shadowColor: payload
                }
            }
        case SHADOW_OPACITY:
            return {
                ...state,
                style: {
                  ...state.style,
                  shadowOpacity: payload
                }
            }
        case SHADOW_BLUR:
            return {
                ...state,
                style: {
                  ...state.style,
                  shadowBlur: payload
                }
            }
        case SHADOW_X:
            return {
                ...state,
                style: {
                  ...state.style,
                  shadowX: payload
                }
            }
        case SHADOW_Y:
            return {
                ...state,
                style: {
                  ...state.style,
                  shadowY: payload
                }
            }
        case FONT_FAMILY:
            return {
                ...state,
                style: {
                  ...state.style,
                  fontFamily: payload
                }
            }
        case FONT_STYLE:
            return {
                ...state,
                style: {
                  ...state.style,
                  fontStyle: payload
                }
            }
        case FONT_SCALE:
            return {
                ...state,
                style: {
                  ...state.style,
                  fontScale: payload
                }
            }
        case MULTI_LINE:
            return {
                ...state,
                style: {
                  ...state.style,
                  multiLine: payload
                }
            }
        case EMOJI:
            return {
                ...state,
                style: {
                  ...state.style,
                  emoji: payload
                }
            }
        case ITEM_NAME_VALUE:
            return {
                ...state,
                itemNameValue: payload
            }
        case SET_WAITING_TO_SAVE_RSS:
            return {
                ...state,
                waitingToSave: payload
            }
        default:
            return state;
    }
}


export default tabbedReducer('rss_item_editor', reducer, initialState, {
  changeTab: RSS_FEED_CHANGE_TAB,
  createTab: RSS_FEED_CREATE_TAB,
  deleteTab: RSS_FEED_CLOSE_TAB,
  display: RSS_FEED_DISPLAY,
  tabActions: actions
})
