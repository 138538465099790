import React, {PureComponent} from 'react'
import {Menu, Input} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import ScheduleIntervalOptions from './ScheduleIntervalOptions'

import moment from 'moment'

import './ScheduleTimeSelector.css'

const DAYS_OF_THE_WEEK = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT'
]

class ScheduleTimeSelector extends PureComponent {

  render() {
    let {type,
      viewDate,
      changeView,
      datelist,
      intervalDuration,
      intervalBasis,
      changeIntervalDuration,
      changeIntervalBasis} = this.props

    let typeHeader = ''
    let timeHeader = ''
    let intervalOptions = ''

    switch(type) {
      case 'daily':
        typeHeader = 'Daily Schedule'
        break
      case 'weekly':
        let changeWeekDay = (e, data) => {
          changeView(data.index)
        }
        let weekButtons = DAYS_OF_THE_WEEK.map((day, dayInd) => {
          let cls = ''
          if(datelist.includes(dayInd)) {
            cls = 'hasItems'
          }
          return (<Menu.Item index={dayInd}
            className={cls}
            key={dayInd}
            active={viewDate.day() === dayInd}
            onClick={changeWeekDay}>{day}</Menu.Item>)
        })
        timeHeader = (
          <Menu fluid widths={7}>
            {weekButtons}
          </Menu>
        )
        typeHeader = 'Weekly Schedule'
        break
      case 'monthly':
      case 'yearly':
      case 'endless':
        let renderDay = (date) => {
          if((type === 'monthly' && datelist.includes(date.getDate())) ||
            (type === 'yearly' && datelist.includes(`${date.getMonth()}/${date.getDate()}`)) ||
            (type === 'endless' && datelist.includes(`${date.getFullYear()}/${date.getMonth()}/${date.getDate()}`))) {
            return 'hasItems'
          }
          return
        }
        viewDate = viewDate.toDate()
        timeHeader = (
          <DatePicker wrapperClassName="ui input fluid"
            customInput={<Input fluid label="Day"/>}
            selected={viewDate}
            shouldCloseOnSelect={false}
            onChange={value => {changeView(moment(value))}}
            dayClassName={renderDay}/>
        )

        typeHeader = type.substring(0, 1).toUpperCase() + type.substring(1) + " Schedule"
        break
      case 'interval':
        typeHeader = 'Cruise Schedule'
        timeHeader = <ScheduleIntervalOptions intervalDuration={intervalDuration}
          datelist={datelist}
          intervalBasis={intervalBasis}
          viewDate={viewDate}
          changeView={changeView}
          changeIntervalDuration={changeIntervalDuration}
          changeIntervalBasis={changeIntervalBasis}/>
        break
      default:
        typeHeader = 'Unknown Schedule Type'
    }

    return (
      <div className='scheduleTimeSelector'>
        <h3>{typeHeader}</h3>
        {timeHeader}
        {intervalOptions}
      </div>
    )

  }
}

export default ScheduleTimeSelector
