import React, {Component} from 'react'
import {Input, Dropdown, Button} from 'semantic-ui-react'

import './CreateScheduleForm.css'

class CreateScheduleForm extends Component {

  constructor(props) {
    super(props)
    let name = ''
    if(props.filename) {
      name = props.filename
    }
    this.state = {name, type: 'daily'}
  }

  SCHEDULE_TYPE_OPTIONS = [
    {text: 'Daily Schedule', value: 'daily'},
    {text: 'Weekly Schedule', value: 'weekly'},
    {text: 'Monthly Schedule', value: 'monthly'},
    {text: 'Yearly Schedule', value: 'yearly'},
    {text: 'Endless Schedule', value: 'endless'},
    {text: 'Cruise Schedule', value: 'interval'}
  ]

  render() {
    let {createSchedule, openApplication} = this.props
    let {name, type} = this.state

    return (
      <form onSubmit={(e) => {
        e.preventDefault()
        handleSubmitNewSchedule(createSchedule, openApplication, name, type)
      }}>
        <div className='createScheduleName'>
          <Input type='text'
            value={name}
            placeholder='(NEW SCHEDULE)'
            onChange={(e, {value}) => {this.setState({name: value})}}
            label='Name'/>
        </div>
        <div className='createScheduleType'>
          <Dropdown selection
            options={this.SCHEDULE_TYPE_OPTIONS}
            placeholder='Schedule Type'
            value={type}
            label='Type'
            onChange={(e, {value}) => {this.setState({type: value})}}/>
        </div>
        <div className='createScheduleButtons'>
          <Button type='submit' positive>Create</Button>
        </div>
      </form>
    )
  }

}

function handleSubmitNewSchedule(createSchedule, openApplication, name, type) {
  createSchedule(name, type)
  openApplication('Schedule', '/mnt/main/Schedules/' + name)
}

export default CreateScheduleForm
