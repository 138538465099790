import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {millisecondsToPixels, pixelsToMilliseconds} from 'helpers/playlist_helpers'

import ClipImage from './ClipImage'

import './ClipImages.css'

const MAX_THUMB_WIDTH = 160

export default class ClipImages extends Component {

  shouldComponentUpdate(nextProps) {
    if(!this.props.shouldLoad) {
      return false
    }
    return (this.props.clipStart !== nextProps.clipStart ||
      this.props.clipEnd !== nextProps.clipEnd ||
      this.props.visibleStart !== nextProps.visibleStart ||
      this.props.visibleEnd !== nextProps.visibleEnd ||
      this.props.source !== nextProps.source ||
      this.props.zoomLevel !== nextProps.zoomLevel ||
      this.props.index !== nextProps.index ||
      (this.props.shouldLoad && !nextProps.shouldLoad))
  }

  render() {
    let {clipStart,
      clipEnd,
      visibleStart,
      visibleEnd,
      source,
      shouldLoad,
      zoomLevel} = this.props

    let positionAdjust = millisecondsToPixels(clipStart, zoomLevel)
    let thumbs = calculateThumbnails(visibleStart, visibleEnd, zoomLevel)
    let images = thumbs.map((thumb) => {
      let thumbWidth = pixelsToMilliseconds(MAX_THUMB_WIDTH, zoomLevel)
      if(thumb.baseTime >= clipStart && (thumb.baseTime + thumbWidth) <= clipEnd) {
        return (<ClipImage key={thumb.baseTime}
          sourceTime={thumb.sourceTime / 1000}
          source={source}
          position={thumb.position - positionAdjust}
          shouldLoad={shouldLoad}/>)
      } else {
        return null
      }
    }).filter((image) => image !== null)

    return (
      <div className='PlaylistFrameClipImages'>
        {images}
      </div>
    )
  }

}

function calculateThumbnails(viewStart, viewEnd, zoomLevel) {
  let thumbSize = pixelsToMilliseconds(MAX_THUMB_WIDTH, zoomLevel)
  thumbSize = Math.max(500, (thumbSize - (thumbSize % 500)))
  let thumbStart = viewStart - (viewStart % thumbSize)
  let thumbEnd = viewEnd
  thumbEnd -= (thumbEnd % thumbSize)

  let thumbs = []

  for(let i = thumbStart; i <= thumbEnd; i += thumbSize) {
    thumbs.push({
      baseTime: i,
      sourceTime: i + (thumbSize / 2),
      position: millisecondsToPixels(i, zoomLevel)
    })
  }
  return thumbs
}

ClipImages.propTypes = {
  source: PropTypes.string.isRequired,
  clipStart: PropTypes.number.isRequired,
  clipEnd: PropTypes.number.isRequired,
  visibleStart: PropTypes.number.isRequired,
  visibleEnd: PropTypes.number.isRequired,
  zoomLevel: PropTypes.number.isRequired,
}
