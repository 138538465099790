import React, {PureComponent} from 'react';
import {Modal, Message} from 'semantic-ui-react'

const LEVEL_HEADERS = {
  info: 'Info',
  warning: 'Warning',
  error: 'Error',
  success: 'Success'
}

export default class Alert extends PureComponent {

  handleClose = (e, data) => {
    this.props.dismiss()
    if(this.props.options &&
      this.props.options.onDismiss) {
      this.props.options.onDismiss()
    }
  }

  render() {
    let {message, options} = this.props
    let {level} = options

    let header = 'Alert'
    if(level in LEVEL_HEADERS) {
      header = LEVEL_HEADERS[level]
    }

    return (
      <Modal open={true} basic onClose={this.handleClose}>
        <Modal.Content>
          <Message info={level === 'info'}
            warning={level === 'warning'}
            error={level === 'error'}
            success={level === 'success'}
            onDismiss={this.handleClose}>
            <Message.Header>
              {header}
            </Message.Header>
            {message}
          </Message>
        </Modal.Content>
      </Modal>
    )
  }

}
