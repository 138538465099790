import React from 'react'

import {Table, Icon, Menu} from 'semantic-ui-react'

export default props => {
  let {name,
    label,
    active,
    type,
    disabledStatus,
    handleSettings,
    handleQueuePage,
    handleControlsPage,
    handleStart,
    handleStop,
    handleRestart,
    handleDelete,
    handleRename} = props

  let icon;
  let disableButtons = false

  if (disabledStatus && !active) {
    if (disabledStatus === 'not configured') label += ' [not configured]'
    else label += ' [disabled]'
    icon = (<Icon name={'ban'} color={'grey'} size='large'/>)
    disableButtons = true
  }
  else {
    icon = (<Icon name={active ? 'play' : 'stop'} color={active ? 'green' : 'red'} size='large'/>)
  }

  return (
    <Table.Row>
      <Table.Cell collapsing>
        {icon}
      </Table.Cell>
      <Table.Cell>
        {label}
      </Table.Cell>
      <Table.Cell textAlign='right'>
        <Menu compact icon='labeled' size='mini'>
          {active ?
            <Menu.Item content='Stop' icon='stop' name='stop' disabled={disableButtons} onClick={() => {handleStop(name)}}/> :
            <Menu.Item content='Start' icon='play' name='play' disabled={disableButtons} onClick={() => {handleStart(name)}}/>}
          <Menu.Item content='Restart' icon='redo' name='redo' disabled={disableButtons} onClick={() => {handleRestart(name)}}/>
          {type !== 'system' ?
            <Menu.Item content='Settings' icon='settings' name='settings' onClick={() => {handleSettings(name)}}/> :
            null}
          {type === 'channel' ?
            <Menu.Item content='Queue' icon='list' name='list' onClick={() => {handleQueuePage(name)}}/> :
            null}
          {(type === 'input' || type === 'router') ?
            <Menu.Item content='Controls' icon='keyboard' name='keyboard' onClick={() => handleControlsPage(name)}/> :
            null}
          {type !== 'system' ?
            <Menu.Item content={type === 'channel' ? 'Renumber' : 'Rename'} icon='pencil' name='pencil' onClick={() => handleRename(name)}/> :
            null}
          {type !== 'system' ?
            <Menu.Item className='serviceDeleteButton' content='Delete' icon='remove' name='remove' onClick={() => handleDelete(name)}/> :
            null}
        </Menu>
      </Table.Cell>
    </Table.Row>
  )
}
