import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actions, TOOLS, changeTab, createTab, deleteTab} from 'redux/applications/playlist_editor'
import {openApplication} from 'redux/menu'
import {loadFileData} from 'redux/file_list'
import PlaylistEndSelector from 'selectors/PlaylistEndSelector'
import PlaylistFrameSelector from 'selectors/PlaylistFrameSelector'
import PlaylistMagnetTimesSelector from 'selectors/PlaylistMagnetTimesSelector'
import PlaylistAddClipPreviewSelector from 'selectors/PlaylistAddClipPreviewSelector'
import PlaylistFullPathSelector from 'selectors/PlaylistFullPathSelector'
import {encodeURIFilepath} from 'helpers/general_helpers'

import FrameEditor from 'components/CanvasFrameEditor/Container'
import PlaylistControls from 'components/PlaylistEditor/Controls'
import CutsMenu from "components/PlaylistEditor/Menu"
import PlaylistTimeline from 'components/PlaylistEditor/Timeline/Container'
import Library from 'containers/components/LibraryComponent'

import {Tab} from "semantic-ui-react"
import tabbedComponent from 'components/higher_order_components/tabbedComponent'
import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'

import './PlaylistEditor.css'

class PlaylistEditor extends PureComponent {

  constructor(props) {
    super(props)
    this.libraryUpdate = this.libraryUpdate.bind(this)
    props.loadFileData(props.present.playlistPath);
  }

  componentDidMount() {
    this.updateURL()
  }

  componentDidUpdate(prevProps) {
    let {previewTime, isFetching, frames, playlistPath, playlistName} = this.props.present
    if(prevProps.present.playlistPath !== playlistPath ||
      prevProps.present.playlistName !== playlistName) {
      this.updateURL()
    }
    if(frames !== prevProps.present.frames) {
      let reload = false
      if(frames.length !== prevProps.present.frames.length) {
        reload = true
      }
      if(!reload) {
        for(let i = 0; i < frames.length; i++) {
          if(frames[i].size !== prevProps.present.frames[i].size ||
          frames[i].timeline !== prevProps.present.frames[i].timeline) {
            reload = true
            break
          }
        }
      }
      if(reload) {
        this.props.reloadPreviewBuffer()
      }
      this.props.checkPreviewFrames()
    } else if(previewTime !== prevProps.present.previewTime) {
      if(!isFetching) {
        this.props.checkPreviewFrames()
      }
    }
  }

  updateURL = () => {
    let {present, openApplication} = this.props
    let fullPath = [...present.playlistPath, present.playlistName]
    if(present.playlistName && fullPath.length > 0) {
      fullPath = encodeURIFilepath(fullPath)
      openApplication('Playlist', `/${fullPath.join('/')}`)
    } else {
      openApplication('Playlist')
    }
  }

  /**
   * On unmounting, revoke any stored image urls to prevent them from continuing to take up memory
   */
  componentWillUnmount() {
    this.props.reloadPreviewBuffer()
  }

  libraryUpdate(selected, navpath) {
    let {playlistLibrarySelect, selectPlaylistTool, libraryUpdate} = this.props
    if(selected) {
      playlistLibrarySelect(selected)
      if(selected.length > 0) {
        selectPlaylistTool(-1)
      }
    }
    libraryUpdate(selected, navpath)
  }

  handlePlaylistPlay = () => {
    this.props.togglePlaylistPlaying()
  }

  // Handler for edit playlist
  handleFileEditPlaylist = (path) => {
    path = path || this.props.selectedFiles[0]
    if(!path || !(path instanceof Array)) {
      return
    }
    let compareFunction = ([key, value]) => {
      let fullPath = PlaylistFullPathSelector(value.props.present)
      return fullPath.join('/') === path.join('/')
    }
    let createArgs = [path]
    this.props.openTab(compareFunction, createArgs)
  }

  handleClick = (e) => {
    if(e.target.className === "FrameEditorContainer" ||
      e.target.id === "PlaylistControls" ||
      e.target.id === "PlaylistTimelineContainer" ||
      e.target.id === "PlaylistTimeline") {
      this.props.deselectAllFrames()
    }
  }

  handleOpenFile = (path, type) => {
    if(type.application) {
      if(type.application === 'Playlist') {
        this.handleFileEditPlaylist(path)
      } else {
        path = encodeURIFilepath(path)
        this.props.openApplication(type.application, `/${path.join('/')}`)
      }
    }
  }

  render() {
    let {filelist,
      index,
      loadFileData,
      unsaved,
      ...actions} = this.props
    let playlist = this.props.present

    let frames = PlaylistFrameSelector(playlist)
    let endOfPlaylist = PlaylistEndSelector(playlist)
    let magnetTimes = PlaylistMagnetTimesSelector(playlist)
    let libraryPreview = PlaylistAddClipPreviewSelector({...playlist, ...filelist})
    let fullPath = PlaylistFullPathSelector(playlist)

    let shouldUndo = this.props.past.length > 0
    let shouldRedo = this.props.future.length > 0

    return (
      <div id='PlaylistEditorPane' onClick={this.handleClick}>
        <div id='PlaylistFrameContent'>
          <div id='PlaylistFrameEditor'>
            <FrameEditor frames={frames}
              selectedFrames={playlist.selectedFrames}
              previewTime={playlist.previewTime}
              snapToGrid={playlist.snapToGrid}
              aspectRatio={playlist.aspectRatio}
              moveFrame={actions.moveFrame}
              resizeFrame={actions.resizeFrame}
              selectFrame={actions.selectFrame}/>
          </div>
          <div id='PlaylistTimelineContainer'>
            <PlaylistTimeline {...playlist}
              playlistPath={fullPath}
              frames={frames}
              endOfPlaylist={endOfPlaylist}
              magnetTimes={magnetTimes}
              libraryPreview={libraryPreview}
              {...actions}
              togglePlaylistPlaying={this.handlePlaylistPlay}
              toolsList={TOOLS}/>
          </div>
        </div>
        <div id='OtherPlaylistContent'>
          <CutsMenu  {...actions}
            shouldUndo={shouldUndo}
            shouldRedo={shouldRedo}
            unsaved={unsaved}/>
          <Tab id="CastusCutsMenu" panes={[
            {menuItem: "Library", render: () => (
              <Tab.Pane id="CutsLibraryTab">
                <Library update={this.libraryUpdate}
                  deselect={playlist.libraryDeselect}
                  overridePath={this.props._library.navpath}
                  handleOpenFile={this.handleOpenFile}
                  inputAssociations={true}
                  compact
                  selectOne/>
              </Tab.Pane>)
            },
            {menuItem: "Options", render: () => (
              <Tab.Pane id="CutsControlsTab">
                <PlaylistControls frames={frames}
                    {...playlist}
                    shouldUndo={shouldUndo}
                    shouldRedo={shouldRedo}
                    unsaved={unsaved}
                    index={index}
                    filelist={filelist}
                    {...actions}/>
              </Tab.Pane>)
            },
          ]}/>
        </div>
      </div>
    )
  }

}

const initialize = (props) => {
  if(props.match.params.path) {
    let {_tabs, _tabData} = props
    let path = decodeURIComponent(props.match.params.path)
    let tabId = Object.entries(_tabData).find(([key, value]) => {
      let fullPath = PlaylistFullPathSelector(value.props.present)
      return fullPath.join('/') === path
    })
    if(tabId instanceof Array) {
      tabId = tabId[0]
    }
    let tabInd = _tabs.findIndex((tab) => tab === tabId)
    if(tabInd > -1) {
      props._changeTab(tabInd);
    } else {
      props._createTab(path.split('/'));
    }
    return
  }
  if(props._tabs.length === 0) {
    props._createTab();
  }
}

let mapStateToProps = (state) => ({
  _tabs: state.playlist_editor.tabs,
  _tabData: state.playlist_editor.tabData,
  _activeTab: state.playlist_editor.activeTab,
  _initialize: initialize,
  _tabActions: actions,
  filelist: state.file_list
})

let mapDispatchToProps = (dispatch) => {
  return {
    ...(bindActionCreators({
      loadFileData,
      openApplication,
      _changeTab: changeTab,
      _createTab: createTab,
      _deleteTab: deleteTab
    }, dispatch)),
    _dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(tabbedComponent(loadingIndicator(PlaylistEditor)))
