import React, {Component} from 'react'

import FrameWindow from './Window'

import './Panel.css'

const frameContent = React.createRef()

class FramePanel extends Component {

  render() {
    let {aspectRatio} = this.props

    let aspect = [16, 9]
    if(aspectRatio && aspectRatio.length === 2) {
      aspect = aspectRatio
    }

    let ratioPadding = `${Math.floor((aspect[1] / aspect[0]) * 10000) / 100}%`
    let editorWidth = `calc(${(51 / aspect[1]) * aspect[0]}vh - ${(6 / aspect[1]) * aspect[0]}em)`

    return (
      <div className='FrameEditorContainer'>
        <div className='FrameEditor' style={{maxWidth: editorWidth}}>
          <div className='ratio16_9' style={{paddingBottom: ratioPadding}}/>
          <div className='FrameEditorContent' ref={frameContent}>
            <FrameWindow {...this.props}/>
          </div>
        </div>
      </div>
    )

  }

}

export default FramePanel
