import React, {PureComponent} from 'react'
import {Form, Radio, List} from 'semantic-ui-react'

export default class MultipleChoicePrompt extends PureComponent {

  render() {
    let {value, changeValue} = this.props
    let {choices} = this.props.options

    let radioButtons = choices.map((choice, choiceInd) => {
      let {label} = choice
      if(!label) {
        label = ""
      }
      let key = label
      if(label instanceof Array) {
        key = label.join("")
        label = <List items={label}/>
      }
      return (<Form.Field key={key}
        control={Radio}
        label={{children: label, onClick: () => {changeValue(choiceInd)}}}
        value={choiceInd}
        checked={value === choiceInd}
        onChange={(e, data) => {changeValue(data.value)}}/>)
    })

    return (
      <Form>
        {radioButtons}
      </Form>
    )
  }

}
