import {v4 as uuidv4} from 'uuid'
import {getIn, setIn} from 'helpers/general_helpers'

const initialTabbedState = {
  tabs: [],
  tabData: {},
  activeTab: 0
}

class DeletedTabError extends Error {

  constructor(...params) {
    super(...params)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, DeletedTabError);
    }
  }

}

export const CHANGE_TAB = Symbol('change tab')
export const CREATE_TAB = Symbol('create tab')
export const DELETE_TAB = Symbol('delete tab')
export const PASS_THROUGH = Symbol('pass through')
export const TAB_CREATED = Symbol('tab created')

const wrapActionCreator = (actionCreator, id, reducerName) => {
  return function() {
    let action = actionCreator(...arguments)
    return tabAction(action, id, reducerName)
  }
}

const tabAction = (action, id, reducerName) => {
  if(typeof action === 'object') {
    return {type: PASS_THROUGH, name: reducerName, tab: id, action}
  } else if (typeof action === 'function') {
    return (dispatch, getState) => {
      let tabDispatch = (act) => {
        return dispatch(tabAction(act, id, reducerName))
      }
      tabDispatch.global = dispatch;
      let getTabState = () => {
        let local = getIn(getState(), [reducerName, 'tabData', id, 'props']);
        if(!local) {
          throw new DeletedTabError("Tried to access data from a tab that no longer exists.")
        }
        return {
          local,
          global: getState()
        }
      }
      try {
        let retVal = action(tabDispatch, getTabState)
        if(retVal instanceof Promise) {
          retVal = retVal.catch(err => {
            if(err instanceof DeletedTabError) {
              return err;
            } else {
              throw err;
            }
          })
        }
        return retVal
      } catch (err) {
        if (err instanceof DeletedTabError) {
          return;
        } else {
          throw err;
        }
      }
    }
  }
  return action
}

const defaultIsUnsaved = () => {
  return false;
}

const onTabCreatedWrapper = (onTabCreated, tabId, reducerName) => {
  return () => {
    return (dispatch, getState) => {
      dispatch(wrapActionCreator(onTabCreated, tabId, reducerName)())
      dispatch({
        type: TAB_CREATED,
        id: tabId,
        name: reducerName
      })
    }
  }
}

/**
 * Higher order reducer that wraps another reducer, adding tabbing to that reducer for use with tabbedComponent
 * @param {string} name The name of the reducer within the rootReducer
 * @param {function} reducer The reducer to be wrapped
 * @param {object} initialState The initial state for each newly created tab
 * @param {object} opts Optional parameters that may be passed in
 * @param {(string|Symbol)} opts.changeTab The action type to use for changing tabs. Uses CHANGE_TAB by default
 * @param {(string|Symbol)} opts.createTab The action type to use for creating new tabs. Uses CREATE_TAB by default
 * @param {(string|Symbol)} opts.deleteTab The action type for removing tabs. Uses DELETE_TAB by default
 * @param {(Function|string)} opts.display Used to determine what to display in the menu item text for each tab.
 *  If a function, it will be called with the tab's current state, and the return value will be used as the menu item label for that tab
 *  If not a function then the value of display will be used as the menu item label instead
 * @param {Function} opts.isUnsaved Optional function used to determine whether a tab contains unsaved changes or not. When the function
 *  returns a truthy value, then the tab it is called for contains unsaved changes and will be treated accordingly. If not function is provided, then the
 *  tabs will never be considered to have unsaved changes.
 * @param {Function} opts.onTabCreated Optional thunk action creator that is called once after a tab is first created. Used for things such as
 *  loading data from the server for the tab. Use this instead of the constructor if you need to do something, but don't want it to be done
 *  every time the tab is changed to.
 * @param {object} opts.tabActions A map of function names to action creator functions. Each action creator passed in to this map will be
 *  wrapped so that calling it will return a PASS_THROUGH action with the calling tab's id, so that the action itself is passed down to
 *  the calling tab's reducer.
 */
const tabbedReducer = (name, reducer, initialState={}, opts={}) => {

  let {
    changeTab = CHANGE_TAB,
    createTab = CREATE_TAB,
    deleteTab = DELETE_TAB,
    display,
    isUnsaved = defaultIsUnsaved,
    onTabCreated,
    tabActions
  } = opts

  return function(state=initialTabbedState, action) {
    let {type, ...rest} = action

    let {payload} = rest

    switch(type) {
      case PASS_THROUGH:
        if(rest.name === name) {
          let {tab, action} = rest
          if(state.tabData[tab]) {
            return setIn(state, ['tabData', tab, 'props'], state.tabData[tab].reducer(state.tabData[tab].props, action));
          }
        }
        return state
      case changeTab:
        return {
          ...state,
          activeTab: payload
        }
      case TAB_CREATED:
        if(rest.name === name) {
          return setIn(state, ['tabData', rest.id, 'tabCreated'], true)
        }
        return state
      case createTab:
        let tabID = ''
        do {
          tabID = uuidv4()
        } while(state.tabs.includes(tabID))
        let actions = {}
        if(typeof tabActions === 'object' && tabActions) {
          Object.keys(tabActions).forEach((key) => {
            actions[key] = wrapActionCreator(tabActions[key], tabID, name)
          })
        }
        let props = reducer(initialState, {type: 'INITIALIZE'})
        if(payload && typeof payload === 'object') {
          if(props.present) {
            props.present = {...props.present, ...payload}
          } else {
            props = {...props, ...payload}
          }
        }
        let tabCreated = true
        let onCreate = null
        if(onTabCreated) {
          onCreate = onTabCreatedWrapper(onTabCreated, tabID, name)
          tabCreated = false
        }
        return {
          ...state,
          tabs: state.tabs.concat(tabID),
          tabData: {...state.tabData, [tabID]: {
            display,
            isUnsaved,
            onCreate,
            tabCreated,
            reducer,
            props,
            actions},
          },
          activeTab: state.tabs.length
        }
      case deleteTab:
        if(payload < 0 || payload > state.tabs.length) {
          return state
        }
        let activeTab = state.activeTab
        if(activeTab >= payload) {
          activeTab = Math.max(activeTab - 1, 0);
        }
        let id = state.tabs[payload]
        let newData = {...state.tabData}
        delete newData[id]
        return {
          ...state,
          tabs: state.tabs.filter((tab, ind) => ind !== payload),
          tabData: newData,
          activeTab
        }
      default:
        return state;
    }
  }

}

export default tabbedReducer
