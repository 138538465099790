import React, {useState} from 'react'
import {Menu, Icon} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'

import IntervalTime from 'helpers/IntervalTime/IntervalTime'
import moment from "moment"

const MAX_VIEW_RANGE = 7

function ScheduleIntervalDayPicker(props) {
  let {intervalBasis,
    intervalDuration,
    viewDate,
    datelist,
    selectDay,
    menuProps={}} = props

  const [centerDate, setCenterDate] = useState(1)

  let renderDay = (currentDate) => {
    let match = datelist.find((day) => {
      if(day instanceof IntervalTime) {
        return day.isOnSameDay(currentDate)
      } else {
        return day === `${currentDate.getMonth()}/${currentDate.getDate()}`
      }
    })
    if(match) {
      return 'hasItems'
    }
    return
  }

  let dayNumber = centerDate
  if(viewDate) {
    dayNumber = (Math.trunc(viewDate.diff(intervalBasis, "days")) % intervalDuration)
    if(dayNumber < 0) {
      dayNumber += intervalDuration
    }
    dayNumber += 1
  }

  let start = Math.max(1, (dayNumber - Math.floor(MAX_VIEW_RANGE / 2)))
  let end = Math.min(intervalDuration, (start + MAX_VIEW_RANGE - 1))
  start = Math.min(start, Math.max(1, end - (MAX_VIEW_RANGE - 1)))
  let dayItems = []
  for(let i = start; i <= end; i++) {
    let className = ""
    if(datelist && datelist.find((day) => (day instanceof IntervalTime && day.intervalday() === i))) {
      className = "hasItems"
    }
    dayItems.push(
      <Menu.Item key={i} active={(viewDate && dayNumber === i)} className={className} onClick={() => {
        let newDate = moment(intervalBasis).add((i - 1), "days")
        selectDay(newDate)
      }}>
        {i}
      </Menu.Item>
    )
  }

  let timeHeader = (
    <DatePicker wrapperClassName='vertically fitted item intervalOptionsCalendarButton'
      customInput={<div><Icon name="calendar"/></div>}
      selected={viewDate && viewDate.toDate()}
      onChange={(value) => selectDay(moment(value))}
      dayClassName={renderDay}/>
  )

  return(
    <Menu widths={dayItems.length + 3} size="small" borderless {...menuProps}>
      <Menu.Item icon="chevron left" onClick={() => {
        if(viewDate) {
          let newDate = viewDate.clone()
          newDate.subtract(1, "days")
          selectDay(newDate)
        } else {
          let middle = Math.round((start + end) / 2)
          setCenterDate(middle - 1)
        }}
      }/>
      {dayItems}
      <Menu.Item icon="chevron right" position="right" onClick={() => {
        if(viewDate) {
          let newDate = viewDate.clone()
          newDate.add(1, "days")
          selectDay(newDate)
        } else {
          let middle = Math.round((start + end) / 2)
          setCenterDate(middle + 1)
        }}
      }/>
      {timeHeader}
    </Menu>
  )
}

export default ScheduleIntervalDayPicker
