import Moment from 'moment'

// ported from V4 interface
export const slashesToUnicodeSlashes = (n) => {
  return n.replace(/\//g,"\u2215")
}

export const dataPath = (filepath, contents=false) => {
  let basepath = [];
  if(typeof filepath === 'string') {
    filepath = filepath.split('/')
  }
  filepath.forEach((path, ind) => {
    if(ind === filepath.length - 1 && !contents) {
      basepath.push('data')
    } else {
      basepath.push('contents')
    }
    basepath.push(path)
  });
  if(contents) {
    basepath.push('data')
  }
  return basepath
}

export const parseFileSize = (size) => {
  if(typeof size === 'string') {
    size = parseFloat(size)
  }
  let i = 0
  while (size >= 1000 && i < SIZE_SUFFIXES.length - 1) {
    size = Math.floor(size / 100) / 10
    i++
  }
  return `${size} ${SIZE_SUFFIXES[i]}`
}

export const parseMtime = (mtime) => {
  if(typeof mtime === 'string') {
    mtime = parseInt(mtime, 10)
  }
  return Moment(mtime).format('MM/DD/YY')
}

export const getContentWindowStatus = (metadata, overrideDate=null) => {
  let contentWindowData = {}

  if(!metadata) {
    return contentWindowData
  }

  let compareDate = overrideDate || Date.now()

  if("content window close" in metadata) {
    let closeDate = Moment.utc(metadata["content window close"], "YYYY-MM-DD hh:mm:ss").toDate()
    contentWindowData.closed = (closeDate <= compareDate)
    if(contentWindowData.closed) {
      contentWindowData.message = (overrideDate && overrideDate > Date.now()) ? `Content Window will be closed at ${new Date(closeDate)}` : `Content Window closed at ${new Date(closeDate)}`
    } else {
      contentWindowData.message = `Content Window will close at ${new Date(closeDate)}`
    }
  }
  if("content window open" in metadata) {
    let openDate = Moment.utc(metadata["content window open"], "YYYY-MM-DD hh:mm:ss").toDate()
    let notOpened = openDate > compareDate
    // If closed from above, keep it closed.
    contentWindowData.closed = (contentWindowData.closed || notOpened)
    if(contentWindowData.closed && notOpened) {
      contentWindowData.message = (overrideDate && overrideDate > Date.now()) ? `Content Window will not be opened until ${new Date(openDate)}` : `Content Window will open at ${new Date(openDate)}`
    } else if(!contentWindowData.closed && !notOpened && !contentWindowData.message) {
      contentWindowData.message = `Content Window opened at ${new Date(openDate)}`
    }
  }

  return contentWindowData
}

/**
 * Gets the icons for special folders
 * @param {array} path The path of the folder being checked as an array of string path components
 * @param {string} currentIcon Optional string name of current icon of folder. Will be returned if
 *  the path does not match a known path for a special folder.
 * @returns If the path matches a known path for a special folder, the string name of that folder's
 *  icon will be returned. Otherwise, currentIcon will be returned (null by default)
 */
export const specialFolderIcons = (path, currentIcon=null) => {
  let container = path.slice(0, -1).join('/')
  let filename = path.slice(-1)[0]
  if(!(container === 'mnt/main' ||
    container === 'mnt/^data' ||
    container === '/mnt/main' ||
    container === '/mnt/^data')) {
    return currentIcon
  }
  switch(filename) {
    case "Schedules":
      return "schedulefolder"
    case "Playlists":
      return "simpleplaylistfolder"
    case "Projects":
      return "castuscutsfolder"
    case "Text Items":
      return "scrollingtextfolder"
    case "Metadata Templates":
      return "metadatafolder"
    default:
      return currentIcon
  }
}

/**
 * Gets info about a given file type
 * @param {string} filetype The raw string type of the file
 * @returns An object containing a string type, string subtype, string display, and string icon
 *  for the given type
 */
export const fileTypeInfo = (filetype) => {
  /* hack compat: identify endpoint changed schema a bit */
  let fobj = undefined
  let fsbt = undefined
  if(typeof(filetype) === 'object' && filetype !== null/*Why does Firefox consider null an object???*/) {
    fobj = filetype
    filetype = fobj["file type"]
    fsbt = fobj["fs base type"]
    if (filetype === undefined && fsbt !== undefined)
      filetype = fsbt
  }
  /* end hack */
  if(!filetype) {
    return {
      type: 'unknown',
      subtype: 'unknown',
      display: 'Unknown',
      icon: ''
    }
  }

  let rv = FILE_TYPES[filetype]
  if (!rv) {
    let [type, subtype] = filetype.split('/');

    type = type.toLowerCase();
    if(subtype) subtype = subtype.toLowerCase();

    let display = type
    if(subtype) display += ('/' + subtype)

    rv = {
      type,
      subtype,
      display,
      icon: ''
    }
  }

  /* hack compat and bugfix: if the fs base type is a folder,
   * then regardless of metadata, handle as a folder. This
   * fixes the bug where someone can create metadata on a folder,
   * mark it as a QuickTime file, and then the V5 library interface
   * can no longer show the contents of the folder. */
  if (fsbt === "folder") {
    rv.type = fsbt
    rv.subtype = ''
  }

  return rv
}

// NOTICE: Setting the application values to the appropriate children from the applicationsList is not working.
// The applciation values are set to raw strings, so if you change the applicationList, you also may need to change the
// associated application values below.
const FILE_TYPES = {
  "file": {
    type: 'file',
    subtype: '',
    display: 'File',
  },
  "folder": {
    type: 'folder',
    subtype: '',
    display: 'Folder',
    icon: 'folder'
  },
  "filesystem/boot": {
    type: 'filesystem',
    subtype: 'mounted',
    display: 'File System (Boot)',
    icon: 'folder'
  },
  "filesystem/main": {
    type: 'filesystem',
    subtype: 'mounted',
    display: 'File System (Main)',
    icon: 'folder'
  },
  "filesystem/mounted": {
    type: 'filesystem',
    subtype: 'mounted',
    display: 'File System',
    icon: 'folder'
  },
  "filesystem/unmounted": {
    type: 'filesystem',
    subtype: 'unmounted',
    display: 'File System (Not Mounted)'
  },
  "cdrom/mounted": {
    type: 'cdrom',
    subtype: 'mounted',
    display: 'CD-ROM',
    icon: 'folder'
  },
  "cdrom/unmounted": {
    type: 'cdrom',
    subtype: 'unmounted',
    display: 'CD-ROM (Not Mounted)'
  },
  "network/mounted": {
    type: 'network',
    subtype: 'mounted',
    display: 'Network Share',
    icon: 'folder'
  },
  "network/unmounted": {
    type: 'network',
    subtype: 'unmounted',
    display: 'Network Share (Not Mounted)'
  },
  "text/plain": {
    type: 'text',
    subtype: 'plain',
    display: 'Text File',
    icon: 'text'
  },
  "application/x-castus-playlist-folder": {
    type: 'folder',
    subtype: 'playlist',
    display: 'Folder',
    icon: 'videofolder'
  },
  "application/x-castus-playlist": {
    type: 'playlist',
    subtype: 'simple',
    display: 'Playlist',
    icon: 'playlist editor',
    application: 'SimplePlaylist',
    indeterminateSize: true,
  },
  "application/x-castus-daily-schedule": {
    type: 'schedule',
    subtype: 'daily',
    display: "Daily Schedule",
    icon: 'schedule small',
    indeterminateSize: true,
    application: 'Schedule',
  },
  "application/x-castus-weekly-schedule": {
    type: 'schedule',
    subtype: 'weekly',
    display: "Weekly Schedule",
    indeterminateSize: true,
    application: 'Schedule',
    icon: 'schedule small'
  },
  "application/x-castus-monthly-schedule": {
    type: 'schedule',
    subtype: 'monthly',
    display: "Monthly Schedule",
    indeterminateSize: true,
    application: 'Schedule',
    icon: 'schedule small'
  },
  "application/x-castus-yearly-schedule": {
    type: 'schedule',
    subtype: 'yearly',
    display: "Yearly Schedule",
    indeterminateSize: true,
    application: 'Schedule',
    icon: 'schedule small'
  },
  "application/x-castus-interval-schedule": {
    type: 'schedule',
    subtype: 'interval',
    display: 'Cruise Schedule',
    indeterminateSize: true,
    application: 'Schedule',
    icon: 'schedule small'
  },
  "application/x-castus-endless-schedule": {
    type: 'schedule',
    subtype: 'endless',
    display: 'Endless Schedule',
    indeterminateSize: true,
    application: 'Schedule',
    icon: 'schedule small'
  },
  "application/x-spark-media-xml-schedule": {
    type: 'schedule',
    subtype: 'spark media xml',
    display: 'Spark Media Xml Schedule',
    indeterminateSize: true,
    icon: 'schedule small'
  },
  "application/x-castus-multiregion-playlist": {
    type: 'playlist',
    subtype: 'multiregion',
    display: 'CASTUS Cuts Project',
    indeterminateSize: true,
    application: 'Playlist',
    icon: 'castus_cuts_library'
  },
  "application/x-castus-scrolling-text": {
    type: 'scrolling-text',
    subtype: '',
    display: 'Scrolling Text',
    indeterminateSize: true,
    application: 'ScrollingText',
    icon: 'text crawl'
  },
  "application/x-castus-rss-feed": {
    type: 'rss-feed',
    subtype: '',
    display: 'RSS Feed',
    indeterminateSize: true,
    application: 'RSSItemEditor',
    icon: 'rss feed'
  },
  "application/x-castus-video-passthru": {
    type: 'video-passthru',
    subtype: '',
    display: 'Video Passthrough',
    icon: 'input'
  },
  "application/x-castus-video-passthru-v2": {
    type: 'video-passthru',
    subtype: 'v2',
    display: 'Video Passthrough V2',
    icon: 'input'
  },
  "application/x-castus-routing-control": {
    type: 'recording-control',
    subtype: '',
    display: 'Recording Control',
    icon: 'inputs'
  },
  "application/x-castus-metadata-template": {
    type: 'metadata-template',
    subtype: '',
    display: 'Metadata Template',
    application: 'MetadataTemplate',
    icon: 'metadata tag'
  },
  "application/vnd.tv.castus.text-item": {
    type: 'text-item',
    subtype: '',
    display: 'Text Item',
    indeterminateSize: true,
    application: 'ScrollingText',
    icon: 'text'
  },
  "video/x-mpeg-video-m1v": {
    type: "video",
    subtype: 'x-mpeg-video-m1v',
    display: 'Video, MPEG-1',
    icon: 'video'
  },
  "video/x-mpeg-video-m2v": {
    type: "video",
    subtype: 'x-mpeg-video-m2v',
    display: 'Video, MPEG-2',
    icon: 'video'
  },
  "video/quicktime": {
    type: "video",
    subtype: 'quicktime',
    display: 'Video, Quicktime',
    icon: 'video'
  },
  "video/x-flv": {
    type: "video",
    subtype: 'x-flv',
    display: 'Video, FLV',
    icon: 'video'
  },
  "video/mpeg": {
    type: "video",
    subtype: 'mpeg',
    display: 'Video, MPEG',
    icon: 'video'
  },
  "video/avi": {
    type: "video",
    subtype: 'avi',
    display: 'Video, AVI',
    icon: 'video'
  },
  "video/m2ts": {
    type: "video",
    subtype: 'm2ts',
    display: 'Video, M2TS',
    icon: 'video'
  },
  "video/M2TS": {
    type: "video",
    subtype: 'm2ts',
    display: 'Video, M2TS',
    icon: 'video'
  },
  "image/png": {
    type: 'image',
    subtype: 'png',
    display: 'Image, PNG',
    icon: 'image'
  },
  "image/tiff": {
    type: 'image',
    subtype: 'tiff',
    display: 'Image, TIFF',
    icon: 'image'
  },
  "image/jpeg": {
    type: 'image',
    subtype: 'jpeg',
    display: 'Image, JPEG',
    icon: 'image'
  },
  "image/gif": {
    type: 'image',
    subtype: 'gif',
    display: 'Image, GIF',
    icon: 'image'
  },
  "image/webp": {
    type: 'image',
    subtype: 'webp',
    display: 'Image, WebP',
    icon: 'image'
  },
  "image/heif": {
    type: 'image',
    subtype: 'heif',
    display: 'Image, HEIF',
    icon: 'image'
  },
  "image/avif": {
    type: 'image',
    subtype: 'avif',
    display: 'Image, AVIF',
    icon: 'image'
  },
  "application/psd": {
    type: 'image',
    subtype: 'psd',
    display: 'Image, PSD',
    icon: 'image'
  },
  "audio/x-mpegurl": {
    type: 'audio',
    subtype: 'x-mpegurl',
    display: 'Audio, HLS',
    icon: 'sound'
  },
  "audio/x-scpls": {
    type: 'audio',
    subtype: 'x-scpls',
    display: 'Audio, PLS',
    icon: 'sound'
  },
  "audio/mpeg": {
    type: 'audio',
    subtype: 'mpeg',
    display: 'Audio, MPEG',
    icon: 'sound'
  },
  "audio/wav": {
    type: 'audio',
    subtype: 'wav',
    display: 'Audio, WAV',
    icon: 'sound'
  },
  "audio/ac3": {
    type: 'audio',
    subtype: 'ac3',
    display: 'Audio, AC3',
    icon: 'sound'
  },
  "audio/aac": {
    type: 'audio',
    subtype: 'aac',
    display: 'Audio, AAC',
    icon: 'sound'
  },
  "audio/ogg": {
    type: 'audio',
    subtype: 'aac',
    display: 'Audio, Ogg',
    icon: 'sound'
  },
  "audio/flac": {
    type: 'audio',
    subtype: 'aac',
    display: 'Audio, FLAC',
    icon: 'sound'
  },
  "application/aiff": {
    type: 'audio',
    subtype: 'aac',
    display: 'Audio, AIFF',
    icon: 'sound'
  },
  "application/x-aiff": {
    type: 'audio',
    subtype: 'aac',
    display: 'Audio, AIFF',
    icon: 'sound'
  },
  "application/mxf": {
    type: "video",
    subtype: 'mxf',
    display: 'Video, MXF',
    icon: 'video'
  },
  "video/x-ms-asf": {
    type: "video",
    subtype: 'asf',
    display: 'Video, ASF',
    icon: 'video'
  },
  "video/x-matroska": {
    type: "video",
    subtype: 'mkv',
    display: 'Video, Matroska',
    icon: 'video'
  }
}

const SIZE_SUFFIXES = [
  'Bytes',
  'KB',
  'MB',
  'GB',
  'TB',
  'PB'
]
