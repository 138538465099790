import React, {PureComponent} from 'react'

import {Popup,
  Menu,
  Form,
  Segment,
  Dropdown,
  Button} from 'semantic-ui-react'
import DateTimeInput from 'components/TimeInput/DateTimeInput'
import Clock from 'react-live-clock'

import ServerTime from 'selectors/ServerTime'

export default class MenuClock extends PureComponent {

  render() {
    let {canChangeTime,
      timeSync,
      timeOffset,
      timezoneList,
      timezone,
      changeSystemTime,
      changeTimeFormData,
      setChangeTimeData} = this.props

    let time = timeSync && 'now' in timeSync ? timeSync.now() : Date.now()
    time = Math.floor(time)

    let timezoneOptions = timezoneList.map((zone) => ({text: zone, value: zone}))

    let clockItem = (
      <Menu.Item id='clock' className='noselect'>
        <div id='timeClock'>
          <Clock key={timeOffset} date={time} format='hh:mm:ss A' ticking={true} timezone={timezone}/>
        </div>
        <div>
          <Clock key={`date_${timeOffset}`} date={time} format='ddd, MMM Do, YYYY' ticking={true}/>
        </div>
        <div>{timezone}</div>
      </Menu.Item>
    )
    if(canChangeTime) {
      let changeTime = changeTimeFormData.time || time
      let changeZone = changeTimeFormData.timezone || timezone
      changeTime = ServerTime({time: changeTime, timezone: changeZone})
      return (
        <Popup wide trigger={clockItem} content={
          <Form onSubmit={changeSystemTime}>
            <Segment id='TimeChangeDateTime'>
              <DateTimeInput inline
                value={changeTime}
                onChange={(val) => {setChangeTimeData('time', val)}}/>
            </Segment>
            <Dropdown selection
              scrolling
              fluid
              options={timezoneOptions}
              value={changeZone}
              onChange={(e, data) => {setChangeTimeData('timezone', data.value)}}/>
            <div id='TimeChangeSubmit'>
              <Button type='submit' positive>Change Time</Button>
            </div>
          </Form>
        } on="click" onOpen={() =>{
          let time = timeSync && 'now' in timeSync ? timeSync.now() : Date.now()
          time = Math.floor(time)
          let changeTime = ServerTime({time, timezone})
          setChangeTimeData('time', changeTime)
        }}/>
      )
    } else {
      return clockItem
    }
  }

}
