import React, {useState} from 'react'
import {Modal, Input, Button} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'

import "./SetScheduleIntervalsModal.css"

function SetScheduleIntervalsModal(props) {
  let {open, closeModal, setChannelIntervals} = props
  const [state, setState] = useState({
    basis: new Date(),
    duration: 3
  })

  return (
    <Modal open={open} closeIcon onClose={() => closeModal()} size={"tiny"}>
      <Modal.Header>Set Schedule Interval for All Channels</Modal.Header>
      <Modal.Content>
        <Input value={state.basis.toDateString()} label="Start Day" readOnly/>
        <DatePicker inline
            wrapperClassName='ui input fluid'
            selected={state.basis}
            shouldCloseOnSelect={false}
            onChange={(value) => setState({...state, basis: value})}/>
        <Input className="setSIMDaysInput"
            value={state.duration}
            type='number'
            label='Day(s)'
            min={1}
            onChange={(e, data) => {setState({...state, duration: parseInt(data.value, 10)})}}/>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={() => {
          setChannelIntervals(state.basis, state.duration)
          closeModal()
        }}>Set Interval</Button>
        <Button onClick={closeModal}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default SetScheduleIntervalsModal;
