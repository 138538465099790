import Moment from 'moment'
import IntervalTime from 'helpers/IntervalTime/IntervalTime'
import {parseEndlessDate} from 'helpers/schedule_helpers'

const MINUTE = 60000

/**
 * Class for tracking an exact period of time, from a start to an end.
 * Used in order to have the IntervalPeriod interface while using moments instead of IntervalTimes as dates
 */

export default class ExactPeriod {

  //constructor
  constructor(start, end) {
    // If we are given an IntervalTime start or end, then we have no basis
    //  for precisely where they indicate, so the best we can do is get the
    //  closest occurence to today
    if(start instanceof IntervalTime) {
      start = start.closestMoment(new Date())
    }
    if(end instanceof IntervalTime) {
      end = end.closestMoment(new Date())
    }
    if(typeof start === "string") {
      this.start = parseEndlessDate(start)
      this.end = parseEndlessDate(end)
    } else {
      this.start = Moment(start)
      this.end = Moment(end)
    }
  }

  //contains
  contains(compareTo, opts={}) {
    let {exclusiveStart=false, exclusiveEnd=false, noMillis=false} = opts
    if(compareTo instanceof IntervalTime) {
      compareTo = compareTo.closestDate(this.start, {forceDirection: "after"})
    }
    let unit = noMillis ? "second" : ""
    // Rule out target being before (or equal to if exclusiveStart) the start time
    if(exclusiveStart ? this.start.isSameOrAfter(compareTo, unit) : this.start.isAfter(compareTo, unit)) {
      return false
    }
    // Rule out target being after (or equal to if exclusiveStart) the end time
    if(exclusiveEnd ? this.end.isSameOrBefore(compareTo, unit) : this.end.isBefore(compareTo, unit)) {
      return false
    }
    return true
  }

  //isOnSameDay
  isOnSameDay(compareTo) {
    return (this.contains(compareTo, {exclusiveEnd: true}) ||
      this.start.isSame(compareTo, "day") ||
      (this.end.isSame(compareTo, "day") && !(this.end.isSame(this.end.clone().startOf("day")))))
  }

  //duration
  duration() {
    return this.end.diff(this.start)
  }

  //overlaps
  overlaps(compareTo, opts={}) {
    let {giveOverlapType=false, containsOk=false, noMillis=false} = opts
    if(!(compareTo instanceof ExactPeriod)) {
      if("" + compareTo === "[object Object]") {
        throw new Error(`Trying to check overlap between an ExactPeriod and something that is not an ExactPeriod:
          ${JSON.stringify(compareTo, null, 2)}
          ExactPeriod.overlaps can only check for overlap between two ExactPeriods`)
      } else {
        throw new Error(`Trying to check overlap between an ExactPeriod and something that is not an ExactPeriod: ${compareTo}. ExactPeriod.overlaps can only check for overlap between two ExactPeriods`)
      }
    }
    if(this.contains(compareTo.start, {exclusiveEnd: true, noMillis}) && this.contains(compareTo.end, {exclusiveStart: true, noMillis})) {
      if(containsOk) {
        return false
      } else {
        return giveOverlapType ? "CONTAINS" : true
      }
    } else if (compareTo.contains(this.start, {exclusiveEnd: true, noMillis}) && compareTo.contains(this.end, {exclusiveStart: true, noMillis})) {
      return giveOverlapType ? "CONTAINED_BY" : true
    } else if (this.contains(compareTo.start, {exclusiveEnd: true, noMillis})) {
      return giveOverlapType ? "FRONT_OVERLAP" : true
    } else if (this.contains(compareTo.end, {exclusiveStart: true, noMillis})) {
      return giveOverlapType ? "BACK_OVERLAP" : true
    } else {
      return false
    }
  }

  //add
  add(addValue, unit="ms") {
    return new ExactPeriod(this.start.clone().add(addValue, unit), this.end.clone().add(addValue, unit))
  }

  //subtract
  subtract(subtractValue, unit="ms") {
    return new ExactPeriod(this.start.clone().subtract(subtractValue, unit), this.end.clone().subtract(subtractValue, unit))
  }

  /**
   * Moves the interval period so that it starts at a new time but keeps the same duration
   * @param {Moment} newStart The new start time for this period. Also accepts anything that the Moment constructor accepts
   * @returns {ExactPeriod} a new copy of this ExactPeriod that starts at newStart but has the same duration
   */
  moveToStart(newStart) {
    let duration = this.duration()
    newStart = Moment(newStart)
    return new ExactPeriod(newStart, newStart.clone().add(duration, "ms"))
  }

  /**
   * Moves the interval period so that it ends at a new time but keeps the same duration
   * @param {Moment} newEnd The new end time for this period. Also accepts anything that the Moment constructor accepts
   * @returns {ExactPeriod} a new copy of this IntervalPeriod that ends at newEnd but has the same duration
   */
  moveToEnd(newEnd) {
    let duration = this.duration()
    newEnd = Moment(newEnd)
    return new ExactPeriod(newEnd.clone().subtract(duration, "ms"), newEnd)
  }

  //divideIntoSlots
  divideIntoSlots(timeslotLength) {
    if(60 % timeslotLength !== 0 && timeslotLength % 60 !== 0) {
      throw new Error(`Tried to divide an IntervalPeriod into timeslots of length ${timeslotLength} minutes, but only timeslot lengths that are a factor or multiple of 60 are accepted.`)
    }
    let start = this.start.clone()
    let end = this.end.clone()
    let toReturn = [start]
    let startMark = start.valueOf()
    let endMark = end.valueOf()
    if(startMark === endMark) {
      return toReturn
    } else if(startMark > endMark) {
      let temp = endMark
      endMark = startMark
      startMark = temp
    }
    for(let i = startMark + (timeslotLength * MINUTE); i < endMark; i += (timeslotLength * MINUTE)) {
      let nextTime = start.clone().add(i - startMark, "ms")
      nextTime = nextTime.subtract((nextTime.minute() % timeslotLength) * MINUTE, "ms").startOf("minute")
      if(this.contains(nextTime)) {
        toReturn.push(nextTime)
      }
    }
    // End did not get added; expected if end was not on the timeslotLength mark
    if(toReturn[toReturn.length - 1].diff(endMark) !== 0) {
      toReturn.push(end)
    }
    return toReturn
  }

  printStartTime(opts={}) {
    let {showMeridian = true, showMilliseconds = false, subsecondDigits = 3, allowNoSeconds = false} = opts
    let format = ""
    // hours
    if(showMeridian) {
      format += "h"
    } else {
      format += "H"
    }
    // minutes
    format += ":mm"
    // seconds
    if(!allowNoSeconds || this.start.second() !== 0 || (showMilliseconds && this.start.millisecond() !== 0)) {
      format += ":ss"
    }
    // milliseconds
    if(showMilliseconds && subsecondDigits) {
      format += "." + "".padEnd(subsecondDigits, "S")
    }
    // meridian
    if(showMeridian) {
      format += " a"
    }
    return this.start.format(format)
  }

  printEndTime(opts={}) {
    let {showMeridian = true, showMilliseconds = false, subsecondDigits = 3, allowNoSeconds = false} = opts
    let format = ""
    // hours
    if(showMeridian) {
      format += "h"
    } else {
      format += "H"
    }
    // minutes
    format += ":mm"
    // seconds
    if(!allowNoSeconds || this.end.second() !== 0 || (showMilliseconds && this.end.millisecond() !== 0)) {
      format += ":ss"
    }
    // milliseconds
    if(showMilliseconds && subsecondDigits) {
      format += "." + "".padEnd(subsecondDigits, "S")
    }
    // meridian
    if(showMeridian) {
      format += " a"
    }
    return this.end.format(format)
  }

  printStartDay() {
    return this.start.format("[Year] YYYY [Month] M [Day] D")
  }

  printEndDay() {
    return this.end.format("[Year] YYYY [Month] M [Day] D")
  }

}
