import { createSelector } from 'reselect'
import Moment from 'moment-timezone'
import {extendMoment} from 'moment-range'
import {convertToHourString, isOnScheduleDay, checkTimeInSlot} from 'helpers/schedule_helpers'
import {getIn, setIn} from 'helpers/general_helpers'
import IntervalPeriod from 'helpers/IntervalTime/IntervalPeriod'
import ExactPeriod from 'helpers/ExactPeriod/ExactPeriod'
import IntervalTime from 'helpers/IntervalTime/IntervalTime'

const getScheduleSwap = (state) => state.scheduleSwap
const getTimeSlotLength = (state) => state.timeSlotLength
const getViewTime = (state) => state.viewTime
const getSelectedScheduleItems = (state) => state.selectedScheduleItems
const getShowSubseconds = (state) => state.showSubseconds
const getSchedulePath = (state) => {return [...state.filepath, state.filename].join('/')}
const getChannelItems = (state) => state.playingItems
const getCurrentTime = (state) => {
  let {timezone} = state
  let now = getIn(state, ['timeSync', 'now']) || Date.now
  if(timezone) {
    return Moment.tz(now(), timezone)
  } else {
    return Moment(now())
  }
}
const getEmptyTimeslots = (state) => state.emptyTimeslots

const DAY = 86400000

const moment = extendMoment(Moment)

export default createSelector([
  getScheduleSwap,
  getTimeSlotLength,
  getViewTime,
  getSelectedScheduleItems,
  getShowSubseconds,
  getSchedulePath,
  getChannelItems,
  getCurrentTime,
  getEmptyTimeslots,
], (scheduleSwap,
  timeSlotLength,
  viewTime,
  selected,
  showSubseconds,
  schedulePath,
  playingItems,
  currentTime,
  emptyTimeslots) => {
  if(!scheduleSwap) {
    return []
  }
  let {schedule, type, intervalDuration, intervalBasis, errors, eventsList} = scheduleSwap
  if(schedule === null || schedule === undefined || (schedule.length <= 0 && timeSlotLength === 0 && viewTime === null)) {
    return []
  }
  // Fill empty time slots with empty items and return
  /*
  let startTime = viewTime.clone()
  let endTime = viewTime.clone().add(1, 'day')
  startTime.startOf('day')
  endTime.startOf('day')
  */
  let checkNowPlaying = isOnScheduleDay({
    span: {
      start: currentTime,
      end: currentTime
    }
  }, viewTime, type)
  let itemAfterViewed = null
  schedule = schedule.filter((item) => {
    if(item.span.isOnSameDay(viewTime)) {
      return true
    }
    if(!itemAfterViewed && ((type === "endless" && item.span.start.isAfter(viewTime)) || item.span.start.diff(viewTime) > 0)) {
      itemAfterViewed = item
    }
    return false
  }).map((item, ind) => {
    item = parseItemErrors(item, errors)
    if(checkNowPlaying) {
      item = checkIfPlayingNow(item, playingItems, currentTime, type)
      if(item.type === "block") {
        item.body.contains = item.body.contains.map((child) => {
          return checkIfPlayingNow(child, playingItems, currentTime, type)
        })
      }
    }
    if(item.type === "event") {
      let match = eventsList[item.guid]
      if(match) {
        if(match.toCopy) {
          item.assigned = {...item.assigned, toCopy: match.toCopy}
        }
        if(match.assigned) {
          item.assigned = {...item.assigned, ...match.assigned}
        }
      }
    }
    return numberScheduleItem(
      handleScheduleSelect(item, ind, selected),
    ind)
  })
  let startTime = type === "endless" ?
    viewTime.clone().startOf('day') :
    new IntervalTime(viewTime.clone().startOf('day'), type, {basis: intervalBasis, days: intervalDuration})
  let endTime = startTime.clone().add(DAY, "milliseconds")
  if(type === "daily") {
    endTime.set("hour", 24)
  }
  let nextTime = null
  if(schedule.length) {
    let lastViewed = schedule[schedule.length - 1].key
    let lastViewedParent = scheduleSwap.findParentOf(lastViewed)
    if(lastViewedParent.type === "block") {
      lastViewed = lastViewedParent.key
    }
    let nextItem = scheduleSwap.nextSiblingOf(lastViewed)
    if(!nextItem && type !== "endless") {
      nextItem = scheduleSwap.schedule[0]
    }
    if(nextItem) {
      nextTime = nextItem.span.start
    }
  } else if(itemAfterViewed) {
    nextTime = itemAfterViewed.span.start
  } else if(scheduleSwap.schedule.length && type !== "endless") {
    nextTime = scheduleSwap.schedule[0].span.start
  }
  return fillSchedule(schedule, type, timeSlotLength, startTime, DAY, nextTime, viewTime, emptyTimeslots, showSubseconds, intervalBasis, intervalDuration)
})

/**
 * Helper function that fills all times within a schedule that have no associated item with a blank schedule item.
 * @param {array} inputSchedule An array of schedule items/schedule blocks
 * @param {number} timeSlotLength Optional length of time slots in minutes. Defaults to 30 minutes, cannot be more than 60 minutes or less than 1 minute.
 * @param {object} startTime Optional moment object to indicate start of schedule. Defaults to start of the current day.
 * @param {object} duration Duration of schedule to fill in milliseconds
 * @param {boolean} subseconds Whether to consider time gaps smaller than 1 second or not
 * @returns A copy of schedule with additional empty items in any unfilled time slots.
 */
const fillSchedule = (inputSchedule, type, slotLength, startTime=null, duration, nextTime, viewTime, emptyTimeslots, subseconds=false, intervalBasis, intervalDuration) => {
  inputSchedule = [...inputSchedule]
  startTime = startTime ? startTime : moment({h: 0, m: 0, s: 0})
  let intervalData = {days: intervalDuration, basis: intervalBasis}
  startTime = type === "endless" ?
    moment(startTime) :
    new IntervalTime(startTime, type, intervalData)
  let endTime = startTime.clone().add(duration, "milliseconds")
  let containerPeriod = type === "endless" ?
    new ExactPeriod(startTime, endTime) :
    new IntervalPeriod(startTime, endTime, type, intervalData)
  // Used for time remaining in empty timeslots
  let nextDuration = null
  if(nextTime) {
    let periodUntilNext = type === "endless" ?
      new ExactPeriod(startTime, nextTime) :
      new IntervalPeriod(startTime, nextTime, type, intervalData)
    nextDuration = periodUntilNext.duration()
    // This cannot happen in endless schedules
    if(nextDuration < (duration || DAY)) {
      let startDate = startTime.closestDate(viewTime)
      let nextDate = nextTime.closestDate(startDate, {forceDirection: "after"})
      nextDate = nextTime.closestDate(nextDate, {forceDirection: "after", notSameTime: true})
      nextDuration = nextDate.valueOf() - startDate.valueOf()
    }
  }
  // Make sure timeSlotLength is 1 or more
  slotLength = Math.max(slotLength, 1)
  let minimumTimeGap = 1000
  if(subseconds) {
    minimumTimeGap = 1
  }
  // Create schedule full of blank slots
  let defaultBlankSlots = containerPeriod.divideIntoSlots(slotLength)
  //blankSlots = [...blankSlots, ...emptyTimeslots].sort((a, b) => a.diff(b))
  // Merge emptyTimeslots and defaultBlankSlots together. No using sort, as that will mess up the ordering for daily schedules
  let blankSlots = []
  emptyTimeslots = [...emptyTimeslots].filter((slotTime) => containerPeriod.contains(slotTime))
  while(defaultBlankSlots.length || emptyTimeslots.length) {
    // If there are no more custom timeslots left, just fill in the remaining default slots
    if(!emptyTimeslots.length) {
      blankSlots = [...blankSlots, ...defaultBlankSlots]
      break
    // If there are no more default timeslots left, just fill in the remaining custom slots
    } else if (!defaultBlankSlots.length) {
      blankSlots = [...blankSlots, ...emptyTimeslots]
      break
    // If the next default slot is first
    } else if(defaultBlankSlots[0].valueOf() <= emptyTimeslots[0].valueOf()) {
      // Ugh, special case for 0-0
      if(blankSlots.length && defaultBlankSlots[0].valueOf() === 0 && blankSlots[blankSlots.length - 1].valueOf() === 0) {
        while(emptyTimeslots.length) {
          if(emptyTimeslots[0].valueOf() === 0) {
            emptyTimeslots.shift()
          } else {
            blankSlots.push(emptyTimeslots.shift())
          }
        }
      // Might be less suddenly due to wrap around
      } else if(blankSlots.length && defaultBlankSlots[0].valueOf() < blankSlots[blankSlots.length - 1].valueOf() && emptyTimeslots[0].valueOf() >= blankSlots[blankSlots.length - 1].valueOf()) {
        blankSlots.push(emptyTimeslots.shift())
      } else if(!(blankSlots.length) || defaultBlankSlots[0].valueOf() !== blankSlots[blankSlots.length - 1].valueOf()) {
        blankSlots.push(defaultBlankSlots.shift())
      } else {
        defaultBlankSlots.shift()
      }
    // If the next custom slot is first
    } else {
      // Might be less suddenly due to wrap around
      if(blankSlots.length && emptyTimeslots[0].valueOf() < blankSlots[blankSlots.length - 1].valueOf() && defaultBlankSlots[0].valueOf() >= blankSlots[blankSlots.length - 1].valueOf()) {
        blankSlots.push(defaultBlankSlots.shift())
      } else if(!(blankSlots.length) || emptyTimeslots[0].valueOf() !== blankSlots[blankSlots.length - 1].valueOf()) {
        blankSlots.push(emptyTimeslots.shift())
      } else {
        emptyTimeslots.shift()
      }
    }
  }
  let blankSchedule = []
  for(let i = 0; i < blankSlots.length - 1; i++) {
    let label = "empty slot"
    if(nextDuration) {
      let remaining = nextDuration - (blankSlots[i].valueOf() - startTime.valueOf())
      if(remaining < 0 && type === "interval") {
        remaining += intervalDuration * DAY
      }
      remaining = convertToHourString(remaining, false, subseconds)
      label = 'empty slot, ' + remaining + ' remaining.'
    }
    let span = type === "endless" ?
      new ExactPeriod(blankSlots[i], blankSlots[i + 1]) :
      new IntervalPeriod(
        blankSlots[i],
        blankSlots[i + 1],
        type,
        intervalData,
        {zeroOk: true}
      )
    blankSchedule.push({
      type: "empty",
      span,
      body: {label}
    })
  }
  // Next, combine the existing schedule and blank schedule together
  let toReturn = []
  let useSmallestIntervalDifference = (type !== "daily")
  while(blankSchedule.length || inputSchedule.length) {
    // No more empty slots left, so push next schedule item
    if(!blankSchedule.length) {
      toReturn.push(...inputSchedule)
      inputSchedule = []
    // No more schedule items, so push next empty slot
    } else if (!inputSchedule.length) {
      toReturn.push(...blankSchedule)
      blankSchedule = []
    // There are schedule items and empty slots left
    } else {
      // The next chronological item is an empty slot (or an empty slot and an item are at the same time
      if(blankSchedule[0].span.start.diff(inputSchedule[0].span.start, {useSmallestIntervalDifference}) <= 0) {
        let nextBlank = blankSchedule.shift()
        let overlaps = nextBlank.span.overlaps(inputSchedule[0].span, {giveOverlapType: true})
        // If the blank item intersects with the next real schedule item
        if(overlaps === "CONTAINS") {
          let afterSpan = type === "endless" ?
            new ExactPeriod(inputSchedule[0].span.end, nextBlank.span.end) :
            new IntervalPeriod(inputSchedule[0].span.end, nextBlank.span.end, type, intervalData, {zeroOk: true})
          if(afterSpan.duration() >= minimumTimeGap) {
            let label = "empty slot"
            if(nextDuration) {
              let remaining = nextDuration - Math.abs(startTime.diff(afterSpan.start))
              if(remaining < 0 && type === "interval") {
                remaining += intervalDuration * DAY
              }
              remaining = convertToHourString(remaining, false, subseconds)
              label = 'empty slot, ' + remaining + ' remaining.'
            }
            let afterBlank = {
              ...nextBlank,
              span: afterSpan,
              body: {label}
            }
            blankSchedule.unshift(afterBlank)
          }
        }
        if(overlaps) {
          let beforeSpan = type === "endless" ?
            new ExactPeriod(nextBlank.span.start, inputSchedule[0].span.start) :
            new IntervalPeriod(nextBlank.span.start, inputSchedule[0].span.start, type, intervalData, {zeroOk: true})
          let label = "empty slot"
          if(nextDuration) {
            let remaining = nextDuration - (beforeSpan.start.valueOf() - startTime.valueOf())
            if(remaining < 0 && type === "interval") {
              remaining += intervalDuration * DAY
            }
            remaining = convertToHourString(remaining, false, subseconds)
            label = 'empty slot, ' + remaining + ' remaining.'
          }
          nextBlank = {
            ...nextBlank,
            span: beforeSpan,
            body: {label}
          }
        }
        if(nextBlank.span.duration() >= minimumTimeGap) {
          let nextRealItem = inputSchedule.find((item) => item.type !== "empty")
          if(nextRealItem) {
            let remaining = nextRealItem.span.start.diff(nextBlank.span.start, {useSmallestIntervalDifference})
            if(remaining < 0 && type === "interval") {
              remaining += intervalDuration * DAY
            }
            // FIXME! Do not send empty slots for remaining == 0! Apparently it can happen despite the minimum gap code above!
            if (remaining >= 1) {
              remaining = convertToHourString(remaining, false, subseconds)
              nextBlank.body = {
                label: 'empty slot, ' + remaining + ' remaining.'
              }
            }
            else {
              nextBlank.ignoreMe = true
            }
          }
          if (nextBlank.ignoreMe !== true)
            toReturn.push(nextBlank)
        }
      } else {
        let nextItem = inputSchedule.shift()
        let addToBlank = []
        while(blankSchedule.length && nextItem.span.overlaps(blankSchedule[0].span)) {
          let overlaps = nextItem.span.overlaps(blankSchedule[0].span, {giveOverlapType: true})
          if(overlaps === "CONTAINS") {
            blankSchedule.shift()
          } else if(overlaps === "FRONT_OVERLAP") {
            let newSpan = type === "endless" ?
              new ExactPeriod(nextItem.span.end, blankSchedule[0].span.end) :
              new IntervalPeriod(nextItem.span.end, blankSchedule[0].span.end, type, intervalData, {zeroOk: true})
            let label = "empty slot"
            if(nextDuration) {
              let remaining = nextDuration - (newSpan.start.valueOf() - startTime.valueOf())
              if(remaining < 0 && type === "interval") {
                remaining += intervalDuration * DAY
              }
              remaining = convertToHourString(remaining, false, subseconds)
              label = 'empty slot, ' + remaining + ' remaining.'
            }
            addToBlank.push({
              ...blankSchedule[0],
              body: {label},
              span: newSpan
            })
            blankSchedule.shift()
          } else {
            console.log(overlaps)
            break;
          }
        }
        if(addToBlank.length) {
          blankSchedule.unshift(...addToBlank)
        }
        if(nextItem.type === "block") {
          nextItem = setIn(nextItem, ['body', 'contains'], fillSchedule(
            nextItem.body.contains,
            type,
            slotLength,
            nextItem.span.start,
            nextItem.span.duration(),
            nextItem.span.end,
            viewTime,
            emptyTimeslots,
            subseconds,
            intervalBasis,
            intervalDuration))
        }
        if(nextItem.type !== "empty") {
          toReturn.push(nextItem)
        }
      }
    }
  }
  return toReturn
}

const handleScheduleSelect = (item, ind, selected) => {
  if(item.type === 'block') {
    if(item.body.contains.length === 0) {
      return {
        ...item,
        selected: false
      }
    }
    let allChildrenSelected = true
    item = setIn(item, ['body', 'contains'], item.body.contains.map((child, childInd) => {
      if(child.type === 'empty') {
        return child
      }
      let childIsSelected = selected.includes(child.key)
      if(!childIsSelected) {
        allChildrenSelected = false
      }
      return {
        ...child,
        selected: childIsSelected
      }
    }))
    return {
      ...item,
      selected: allChildrenSelected
    }
  } else if(item.type === 'empty') {
    return item
  } else {
    return {
      ...item,
      selected: selected.includes(item.key)
    }
  }
}

/**
 */
const parseItemErrors = (item, errors) => {
  let errs = errors.filter(error => error.itemKey === item.key)
  if(errs && errs.length) {
    item = {
      ...item,
      errors: errs
    }
  }
  if(item.type === "block") {
    item = setIn(item, ["body", "contains"], item.body.contains.map((child) => parseItemErrors(child, errors)))
  }
  return item
}

const numberScheduleItem = (item, ind) => {
  if(item.type === 'block') {
    item = setIn(item, ['body', 'contains'], item.body.contains.map(numberScheduleItem))
  }
  return {
    ...item,
    rootIndex: ind
  }
}

const checkIfPlayingNow = (item, playingOn, currentTime, type) => {
  let playing = []
  if(checkTimeInSlot(currentTime, item.span, type)) {
    playing = playingOn.map(([key, val]) => val.name)
  }
  return {
    ...item,
    playingOn: playing
  }
}
