import React, {PureComponent} from 'react'
import SettingPanel from './SettingPanel'
import SettingsArray from './SettingsArray'
import {Segment,
  Accordion,
  Icon} from 'semantic-ui-react'

import {camelToCapitalized} from 'helpers/general_helpers'
import {handleOneOf, typeDefault, matchesSearchString} from 'helpers/settings_helpers'

import { connect } from 'react-redux'

import './SettingsGroup.css'

export class SettingsGroup extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {open: false}
  }

  handleToggle = () => {
    this.setState((state) => ({...state, open: !state.open}))
  }

  render() {
    let {service,
      serviceType,
      setting,
      value,
      schema,
      address,
      modifySettings,
      settingsSearchString,
      children,
      settingListView} = this.props
    let {open} = this.state

    // Renders settings that are basic
    // settingListView is coming from redux store... This gets updated when a toggle settings button is pressed
    if (schema.advanced && settingListView === true) {
      return null
    }

    if(settingsSearchString) {
      if(!matchesSearchString(address.join(', '), schema, settingsSearchString)) {
        return null
      }
    }

    let schemaProperties = schema.properties ? schema.properties : {}
    let head = camelToCapitalized(setting)
    if(schema.default && typeof schema.default === 'object') {
      Object.entries(schema.default).forEach(([key, value]) => {
        if(schemaProperties[key]) {
          schemaProperties[key].default = value;
        }
      })
    }
    let childrens = []
    let type = schema.type
    if(type instanceof Array && value === null) {
      type = type.find(possibility => possibility !== "null")
      value = typeDefault(type)
    }
    if(value !== null && open) {
      childrens = parseSettingsToComponents(value, schemaProperties, address, {service, serviceType, modifySettings, settingsSearchString})
    }

    let description = schema.description ? <div className='Setting Description'>{schema.description}</div> : null

    return (<Segment key={setting}>
      <Accordion fluid>
        <Accordion.Title className='SettingsCategoryHeader' active={open} onClick={this.handleToggle}>
          <Icon name="dropdown"/>
          {head}
          {children}
          {description}
        </Accordion.Title>
        <Accordion.Content active={open}>
          <Segment.Group>
            {childrens}
          </Segment.Group>
        </Accordion.Content>
      </Accordion>
    </Segment>)
  }

}

const parseSettingsToComponents = (settings, schema, address, props) => {
  let rest = {...settings};
  let toReturn = Object.entries(schema).map(([key, value]) => {
    if(key in rest) {
      delete rest[key]
    }
    if(typeof value === "object" && value !== null && Object.keys(value).includes("oneOf")) {
      let knownValues = settings[key] ? settings[key] : {}
      let defaults = value.default ? value.default : {}
      let selectedSet = handleOneOf(value.oneOf, {...defaults, ...knownValues})
      value = {
        ...value,
        ...selectedSet
      }
    }
    if(value.type === 'object' ||
      (value.type instanceof Array &&
      value.type.includes('object'))) {
      return (<SettingsGroup key={key}
      address={[...address, key]}
      setting={key}
      value={settings[key] ? settings[key] : {}}
      schema={value}
      {...props}/>)
    } else if(value.type === 'array' ||
      (value.type instanceof Array &&
      value.type.includes('array'))) {
      return (<SettingsArray key={key}
        address={[...address, key]}
        setting={key}
        value={settings[key]}
        schema={value}
        {...props}/>)
    } else {
      return (<SettingPanel key={key}
        address={[...address, key]}
        setting={key}
        value={settings[key]}
        schema={value}
        {...props}/>)
    }
  })
  let restSettings = Object.entries(rest).filter(([key, value]) => typeof value === "string")
    .map(([key, value]) => {
      return (<SettingPanel key={key}
        address={[...address, key]}
        setting={key}
        value={value}
        schema={{type: "string", advanced: true}}
        extra={true}
        {...props}/>)
    })
  return [...toReturn, ...restSettings]
}


const mapStateToProps = state => ({
  settingListView: state.settings_list_render.basicView
})

export default connect(
  mapStateToProps,
)(SettingsGroup)
