
export const numberawareLocaleCompare = (a,b) => {
  a = a.toLowerCase()
  b = b.toLowerCase()
  let filterfunc = (f) => { // f is an array element
    if (f === "")
      return false
    if (f.match(/^[0-9]+$/))
      return parseInt(f)
    return f
  }
  let filterexcess = (a) => { // a is an array
    let r = [ ]
    a.forEach((e) => {
      if (e !== false)
        r.push(e)
    })
    return r
  }
  // FIXME: For something like "X11 group 1" we want [ "X11", " ", "group", " ", "1" ].
  //        This instead gives us [ "", "X11", "", " ", "", "group", "", " ", "", "1" ].
  //        We're only doing this for string compare so it's no big deal but just be aware of it.
  let aa = filterexcess(a.split(/([0-9]+|[ \t]+|[a-zA-Z\-_]+|[^0-9]+)/g).map(filterfunc))
  let bb = filterexcess(b.split(/([0-9]+|[ \t]+|[a-zA-Z\-_]+|[^0-9]+)/g).map(filterfunc))
  // We need to do the compare by element ourself. Relying on Javascript to do array comparisons will
  // still produce incorrect results like we are comparing strings despite conversion to integers.
  let count = Math.max(aa.length,bb.length)
  let i = 0
  while (i < count) {
    let sa = (i < aa.length) ? aa[i] : ""
    let sb = (i < bb.length) ? bb[i] : ""
    if (sa < sb) return -1
    if (sa > sb) return 1
    i++
  }
  return 0
}

