import React, {PureComponent} from 'react'
import {millisToHourString} from 'helpers/general_helpers'

import './TimeIndicator.css'

export default class TimeIndicator extends PureComponent {

  render() {
    let {margin, time} = this.props
    let style = {left: margin}
    let cls = 'timeMarker'
    return (
      <span style={style} className={cls}>
        <span className='timeMarkerLabel'>
          {millisToHourString(time)}
        </span>
      </span>
    )
  }

}
