import Cookies from 'js-cookie'

export const BAKE_COOKIE = Symbol('bake cookie')
export const TAKE_COOKIE = Symbol('take cookie')

/**
* Adds a new key and value to the castus_ui_state cookie and then saves it to the browser
* @param {string} key The key to save to the cookie
* @param {string} value The value of the key to save to the cookie
*/
export const bakeCookie = function(key, value) {
  return {
    type: BAKE_COOKIE,
    payload: {
      [key]: value
    }
  }
}

/**
* Retrieves the castus_ui_state cookie from the browser and saves its data as the state
*/
export const takeCookie = function() {
  return (dispatch, getState) => {
    let nom = Cookies.get('castus_ui_state')
    if(nom && typeof(nom) === 'string') { try { nom = JSON.parse(nom) } catch(e) { nom=undefined } }
    if(nom) {
      dispatch({
        type: TAKE_COOKIE,
        payload: nom
      })
      return nom
    }
  }
}

// Reducer
/**
* State is equivalent to the contents of the cookie castus_ui_state
*/
export const initialState = {
}
export default function (state = initialState, action) {
  let {type, payload} = action

  switch (type) {
    case BAKE_COOKIE:
      let newState = Object.assign({}, state, payload)
      Cookies.set('castus_ui_state', JSON.stringify(newState), {expires: 30})
      return newState
    case TAKE_COOKIE:
      Cookies.set('castus_ui_state', JSON.stringify(payload), {expires: 30})
      return payload
    default:
      return state
  }
}
