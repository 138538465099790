import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {actions, changeTab, createTab, deleteTab} from 'redux/applications/metadata_editor'
import {loadFileData} from 'redux/file_list'
import {openApplication} from 'redux/menu'
import {messages} from 'redux/messages'
import {mergeMetadata, capitalizeMetadata} from 'helpers/metadata_helpers'
import {staticAsset} from 'helpers/net_helpers'
import {Table, Dropdown, Header, Divider, Menu, Icon, Image} from 'semantic-ui-react'
import MetadataEntry from 'components/Metadata/MetadataEntry'

import tabbedComponent from 'components/higher_order_components/tabbedComponent'
import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'

import './MetadataEditor.css'

class MetadataEditor extends Component {

  constructor(props) {
    super(props)
    let {filepath, filename} = props
    let fullpath = [...filepath, filename]
    if(filename) {
      props.loadFileMetadata(fullpath)
      props.openApplication('MetadataEditor', `/${fullpath.join('/')}`)
    }
  }

  addMetadata = (tag='') => {
    let {changeMetadata} = this.props
    if(tag) {
      changeMetadata(tag, '')
    } else {
      this.props.prompt('Add which metadata tag?', (tag) => {
        if(tag) {
          changeMetadata(tag, '')
        }
      })
    }
  }

  handleRevert = () => {
    this.props.resetSwapMetadata()
  }

  handleSaveMetadata = () => {
    console.log("handleSaveMetadata")
    this.props.saveMetadata()
    .catch((err) => {console.error(err)})
  }

  applyTemplate = () => {
    this.props.applyMetadataTemplate()
  }

  render() {
    let {filepath,
      filename,
      swapData,
      metadata,
      changeMetadata,
      timezone} = this.props
    let {metadataTags} = this.props.file_list

    let data = mergeMetadata(metadata, swapData)

    let MetadataItems = Object.entries(data).map(([name, value]) => {
      let tag = metadataTags[name]
      return (
        <MetadataEntry key={filepath + name}
          name={name}
          value={value}
          metadataTag={tag}
          onChange={changeMetadata}
          timezone={timezone}/>
      )
    })

    let MetadataOptions = Object.entries(metadataTags).map(([key, value]) => {
      if(key === "guid") {
        return null
      }
      let tagDisplayName = capitalizeMetadata(value.display)
      let handleMetaClick = () => {this.addMetadata(key)}
      return <Dropdown.Item key={key} onClick={handleMetaClick}>{tagDisplayName}</Dropdown.Item>
    }).filter((item) => item !== null)

    let displayName = filename ?
      [...filepath, filename].join('/') :
      'NO FILE SELECTED'

    return (
      <div id='metadataEditorPane'>
        <Header as='h2' icon='font' content='Metadata Editor' id='metadataHeader'/>
        <Divider fitted/>
        <Header as='h3' content={displayName} id='metadataFileDisplay'/>
        <div id='metadataButtonPane'>
          <Menu size="mini" icon="labeled">
            <Dropdown icon={null} scrolling item trigger={
              <Menu.Item size="mini" fitted title="Add Metadata Field" className="menuDropdown">
                <Icon name="plus" size="tiny"/>
                Add Field
              </Menu.Item>
            }>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.addMetadata()}>Custom Tag...</Dropdown.Item>
                {MetadataOptions}
              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item title='Save Metadata' onClick={this.handleSaveMetadata} size="mini">
              <Icon name="save" size="tiny"/>
              Save
            </Menu.Item>
            <Menu.Item title='Revert' onClick={this.handleRevert} size="mini">
              <Icon name="undo" size="tiny"/>
              Revert
            </Menu.Item>
            <Menu.Item title="Apply Template" onClick={this.applyTemplate} size="mini">
              <Icon className="svg-icon">
                <Image src={staticAsset("/icons/metadatatemplate_black.svg")}/>
              </Icon>
              Apply Template
            </Menu.Item>
          </Menu>
        </div>
        <Table columns='15' attached='top'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width='3'>Field</Table.HeaderCell>
              <Table.HeaderCell width='11'>Value</Table.HeaderCell>
              <Table.HeaderCell width='1'/>
            </Table.Row>
          </Table.Header>
        </Table>
        <div id='metadataTablePane'>
          <Table attached='bottom' columns='15'>
            <Table.Body>
              {MetadataItems}
            </Table.Body>
          </Table>
        </div>
      </div>
    )
  }
}

const initialize = (props) => {
  if(props.match.params.path) {
    let {_tabs, _tabData} = props
    let path = decodeURIComponent(props.match.params.path)
    let tabId = Object.entries(_tabData).find(([key, value]) => {
      let fullPath = [...value.props.filepath, value.props.filename]
      return fullPath.join('/') === path
    })
    if(tabId instanceof Array) {
      tabId = tabId[0]
    }
    let tabInd = _tabs.findIndex((tab) => tab === tabId)
    if(tabInd > -1) {
      props._changeTab(tabInd);
    } else {
      props._createTab(path.split('/'));
    }
    return
  }
  if(props._tabs.length === 0) {
    props._createTab();
  }
}

let mapStateToProps = (state) => ({
  _tabs: state.metadata_editor.tabs,
  _tabData: state.metadata_editor.tabData,
  _activeTab: state.metadata_editor.activeTab,
  _initialize: initialize,
  _tabActions: actions,
  file_list: state.file_list,
  timezone: state.menu.timezone
})

let mapDispatchToProps = (dispatch) => {
  return {
    ...(bindActionCreators({
      loadFileData,
      openApplication,
      ...messages,
      _changeTab: changeTab,
      _createTab: createTab,
      _deleteTab: deleteTab
    }, dispatch)),
    _dispatch: dispatch,
    _noNewTabButton: true
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(tabbedComponent(loadingIndicator(MetadataEditor)))
