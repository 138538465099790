import React, {PureComponent} from 'react'
import {Segment, Form, Button} from 'semantic-ui-react'
import DurationInput from 'components/TimeInput/DurationInput'

import {serverPath} from 'helpers/net_helpers'

export default class FilePreviewVideo extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      inPoint: null,
      outPoint: null,
      constrain: true
    }
    this.videoElement = React.createRef()
  }

  currentInPoint = () => {
    let {metadata} = this.props
    let {inPoint} = this.state
    if(inPoint || inPoint === 0) {
      return inPoint
    } else if(metadata && metadata.in) {
      let videoIn = parseFloat(metadata.in)
      if(videoIn || videoIn === 0) {
        return videoIn
      }
    }
    return null
  }

  currentOutPoint = () => {
    let {metadata} = this.props
    let {outPoint} = this.state
    if(outPoint || outPoint === 0) {
      return outPoint
    } else if(metadata && metadata.out) {
      let videoOut = parseFloat(metadata.out)
      if(videoOut || videoOut === 0) {
        return videoOut
      }
    }
    return null
  }

  onVideoLoad = (e) => {
    if(this.currentInPoint()) {
      e.currentTarget.currentTime = this.currentInPoint()
    }
  }

  onVideoTimeUpdate = (e) => {
    if(!this.state.constrain) {
      return
    }
    let inPoint = this.currentInPoint()
    let outPoint = this.currentOutPoint()
    // Handle this to prevent potential infinite looping
    if(inPoint && outPoint &&
      inPoint > outPoint &&
      !e.currentTarget.paused &&
      !e.currentTarget.ended &&
      e.currentTarget.readyState > 2) {
      e.currentTarget.pause()
    }
    if(inPoint &&
      inPoint < e.currentTarget.duration &&
      inPoint > e.currentTarget.currentTime) {
      e.currentTarget.currentTime = inPoint
    }
    if(outPoint && e.currentTarget.currentTime > outPoint) {
      e.currentTarget.currentTime = outPoint
    }
    if(outPoint &&
      !e.currentTarget.paused &&
      !e.currentTarget.ended &&
      e.currentTarget.readyState > 2 &&
      e.currentTarget.currentTime === outPoint) {
      e.currentTarget.pause()
    }
  }

  setInToPlayback = () => {
    if(this.videoElement.current) {
      this.setState((state) => ({inPoint: this.videoElement.current.currentTime}))
    }
  }

  seekToIn = () => {
    let inPoint = this.currentInPoint()
    if(inPoint === null) {
      return
    }
    if(this.videoElement.current) {
      this.videoElement.current.currentTime = inPoint
    }
  }

  setOutToPlayback = () => {
    if(this.videoElement.current) {
      this.setState((state) => ({outPoint: this.videoElement.current.currentTime}))
    }
  }

  seekToOut = () => {
    let outPoint = this.currentOutPoint()
    if(outPoint === null) {
      return
    }
    if(this.videoElement.current) {
      this.videoElement.current.currentTime = outPoint
    }
  }

  submitInOut = () => {
    this.props.handleSetInOutPoints(this.props.path, this.currentInPoint(), this.currentOutPoint())
  }

  render() {
    let inPoint = this.currentInPoint()
    let outPoint = this.currentOutPoint()

    return (
      <Segment index="FilePreviewVideo">
        <video autoPlay
          controls
          ref={this.videoElement}
          style={{width:'100%', maxHeight:'80vh'}}
          onLoadedMetadata={this.onVideoLoad}
          onTimeUpdate={this.onVideoTimeUpdate}
          src={serverPath(`/v2/files/get/${this.props.path.map(encodeURIComponent).join('/')}?auth_token=${window.AARDVARK_ACCESS}`)}>
          Video not supported
        </video>
        <Form index="FilePreviewVideoInOutControls">
          <Form.Checkbox checked={this.state.constrain} label="Constrain Preview to In/Out Points" onChange={(e, data) => this.setState({constrain: data.checked})}/>
          <Form.Group widths="equal">
            <Form.Field inline>
              <label>In</label>
              <DurationInput value={inPoint * 1000}
                inline
                onChange={(value) => {this.setState((state) => ({...state, inPoint: (value / 1000)}))}}
                showMilliseconds={true}/>
              <Button icon="pin" title="Set to Video Playhead Time" onClick={this.setInToPlayback}/>
              <Button icon="step backward" title="Seek to In Point" onClick={this.seekToIn}/>
            </Form.Field>
            <Form.Field inline>
              <label>Out</label>
              <DurationInput value={outPoint * 1000}
                inline
                onChange={(value) => {this.setState({outPoint: (value / 1000)})}}
                showMilliseconds={true}/>
              <Button icon="pin" title="Set to Video Playhead Time" onClick={this.setOutToPlayback}/>
              <Button icon="step forward" title="Seek to Out Point" onClick={this.seekToOut}/>
            </Form.Field>
            <Form.Button positive content="Set In/Out" floated="right" onClick={this.submitInOut}/>
          </Form.Group>
        </Form>
      </Segment>
    )
  }

}
