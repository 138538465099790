import React, {PureComponent} from 'react'

import RegionSidebar from './RegionSidebar'
import RegionTimeline from './RegionTimeline'

import MessageContext from 'containers/MessageContext'

import './Region.css'

class Region extends PureComponent {

  constructor(props) {
    super(props)
    this.regionRef = React.createRef();
  }

  componentDidMount = () => {
    let {index, regionBox, updateRegionBox} = this.props
    if(this.regionRef.current && index !== undefined) {
      let rect = this.regionRef.current.getBoundingClientRect()
      if(regionBox === undefined || (
        rect.top !== regionBox.top &&
        rect.bottom !== regionBox.bottom)) {
        requestAnimationFrame(() => {updateRegionBox(index, rect.top, rect.bottom)})
      }
    }
  }

  componentDidUpdate = () => {
    let {index, regionBox, updateRegionBox} = this.props
    if(this.regionRef.current && index !== undefined) {
      let rect = this.regionRef.current.getBoundingClientRect()
      if(regionBox === undefined || (
        rect.top !== regionBox.top &&
        rect.bottom !== regionBox.bottom)) {
        requestAnimationFrame(() => {updateRegionBox(index, rect.top, rect.bottom)})
      }
    }
  }

  render() {
    let {frame,
      timelineViewStart,
      zoomLevel,
      lineWidth,
      index,
      magnetic,
      magnetTimes,
      libraryPreview,
      libraryOffset,
      previewTime,
      selectedTool,
      frameCount,
      selectedClips,
      selected,
      playInterval,
      firstBlank,
      addFrame,
      removeFrame,
      reorderFrame,
      setFrameName,
      setFrameOptions,
      addFrameClip,
      modifyFrameClip,
      removeFrameClip,
      moveFrameClip,
      cutFrameClip,
      setDragUpdateRequest,
      isDragging,
      setIsDragging,
      selectFrame,
      selectFrameClip,
      startSelectRange} = this.props

    let cls = 'PlaylistRegionRow'
    if(selected) {
      cls += ' selected'
    }

    return (
      <div className={cls} ref={this.regionRef}>
        <RegionSidebar frame={frame}
          index={index}
          frameCount={frameCount}
          firstBlank={firstBlank}
          selected={selected}
          removeFrame={removeFrame}
          setFrameName={setFrameName}
          setFrameOptions={setFrameOptions}
          reorderFrame={reorderFrame}
          addFrame={addFrame}
          selectFrame={selectFrame}/>
        <MessageContext.Consumer>
          {value => <RegionTimeline
            frame={frame}
            frameCount={frameCount}
            viewStart={timelineViewStart}
            zoomLevel={zoomLevel}
            lineWidth={lineWidth}
            index={index}
            magnetic={magnetic}
            magnetTimes={magnetTimes}
            libraryPreview={libraryPreview}
            libraryOffset={libraryOffset}
            previewTime={previewTime}
            selectedTool={selectedTool}
            selectedClips={selectedClips}
            playInterval={playInterval}
            addFrame={addFrame}
            removeFrame={removeFrame}
            addFrameClip={addFrameClip}
            modifyFrameClip={modifyFrameClip}
            removeFrameClip={removeFrameClip}
            moveFrameClip={moveFrameClip}
            cutFrameClip={cutFrameClip}
            setDragUpdateRequest={setDragUpdateRequest}
            isDragging={isDragging}
            setIsDragging={setIsDragging}
            selectFrameClip={selectFrameClip}
            startSelectRange={startSelectRange}
            messages={value}/>}
        </MessageContext.Consumer>
      </div>
    )

  }

}

export default Region
