import React, {Component} from 'react'
import {Modal, Button, Menu, Segment, Dimmer, Loader, Table, Message, Icon} from 'semantic-ui-react'
import {fetchFromServer} from 'helpers/net_helpers'

export default class NDIModal extends Component {

  constructor(props) {
    super(props)
    this.state = {open: false, loading: true, sources: [], error: null}
  }

  scanForSources = async () => {
    this.setState((state) => ({...state, open: true, loading: true, sources: [], error: null}))
    let {id} = this.props
    let res = await fetchFromServer(`/v2/services/input/${id}/ndi_sources`)
    if(!res.ok) {
      let errMsg = await res.text()
      this.setState((state) => ({...state, loading: false, error: {code: res.status, message: errMsg}}))
    } else {
      let sources = await res.json()
      sources = sources.map((source) => {
        let match = /\(([^)]+)\)$/.exec(source.name)
        if(!match) {
          console.warn(`No source name for ndi input ${source.name}. Falling back to using url.`)
        }
        let [ndiName] = match.slice(1)
        return {
          ...source,
          ndiName
        }
      })
      this.setState((state) => ({...state, loading: false, sources}))
    }
  }

  setUrl = (source) => {
    let {type, id, modifySettings} = this.props
    let url = `url:${source.url.replace("ndi://", "")}`
    if(source.ndiName) {
      url = `name:${source.ndiName}`
    }
    modifySettings(type, id, ["url"], url)
    this.setState((state) => ({...state, open: false}))
  }

  render() {
    let {id} = this.props
    let {open, loading, error, sources} = this.state

    let content = null
    if(error) {
      content = (<Message error>
        <Message.Header>Error: {error.code ? `Code ${error.code}` : "Unknown"}</Message.Header>
        <Message.Content>{error.message}</Message.Content>
      </Message>)
    } else if(sources && sources.length) {
      let entries = sources.map((source) => {
        return (<Table.Row key={`${source.name}:${source.url}`} onClick={() => {this.setUrl(source)}}>
          <Table.Cell>{source.name}</Table.Cell>
          <Table.Cell>{source.ndiName}</Table.Cell>
          <Table.Cell>{source.url}</Table.Cell>
        </Table.Row>)
      })
      content = (<Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Key</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>URL</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {entries}
        </Table.Body>
      </Table>)
    }

    return (
      <Modal trigger={<Menu.Item icon="search" content="Scan for NDI Sources"/>}
        closeIcon
        open={open}
        onOpen={this.scanForSources}
        onClose={() => {this.setState((state) => ({...state, open: false}))}}>
        <Modal.Header>NDI Sources for {id}</Modal.Header>
        <Modal.Content>
          <Segment basic>
            <Dimmer active={loading}>
              <Loader/>
            </Dimmer>

            {content}
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.scanForSources}><Icon name="refresh"/>Refresh</Button>
        </Modal.Actions>
      </Modal>
    )
  }

}
