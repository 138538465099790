import {setIn} from 'helpers/general_helpers'

import LibraryManager from 'redux/higher_order_reducers/libraryManager'

export const AUDIO_POLICY = Symbol('audio policy')
export const DEFAULT_POLICY = Symbol('default policy')
export const VOLUME = Symbol('volume')
export const CURRENTLY_EDITED_CHANNEL = Symbol('currently edited channel')
export const SELECTED_MEDIA_ITEM  = Symbol('selected media item')
export const SET_BACKGROUND_MUSIC = Symbol('set background music')
export const OPEN_ERROR_MODAL     = Symbol('open error modal')
export const CLOSE_ERROR_MODAL    = Symbol('close error modal')

export const SET_GLOBAL_MUSIC_CHANGES = Symbol ('set background music changes')
export const CLEAR_GLOBAL_MUSIC_CHANGES = Symbol('clear background music changes')

const GlobalMusicLibraryManager = new LibraryManager()

/**
 * Sets the background music to the selected media item
 * If no item is selected, it displays an error modal
 */
export const setBackgroundMusic = () => (dispatch, getState) => {
  let { global_music }  = getState()

  // If no item is selected, display an error message
  if (!global_music.selectedMediaItem) {
    // The action could be returned rather than dispatched
    // dispatch is used in case an async action is needed later
    dispatch({type: OPEN_ERROR_MODAL})
    return
  }

  // I assume selectedMediaItem is selectedFiles[0]
  dispatch({
    type: SET_BACKGROUND_MUSIC,
    payload: global_music.selectedMediaItem,
  })
}

export const changeSettings = (path, value) => ({
  type: SET_GLOBAL_MUSIC_CHANGES,
  payload: {
    path,
    value
  }
})

/**
 * Close or hide the error model
 */
export const closeErrorModal = () => (
  {
    type: CLOSE_ERROR_MODAL,
  }
)

export const audioPolicy = (audioPolicy) => (
  {
    type: AUDIO_POLICY,
    payload: audioPolicy
  }
)

export const defaultPolicy = (defaultPolicy) => (
  {
    type: DEFAULT_POLICY,
    payload: defaultPolicy
  }
)

export const volume = (volume) => (
  {
    type: VOLUME,
    payload: volume
  }
)

export const currentlyEditedChannel = (currentlyEditedChannel) => (
  {
    type: CURRENTLY_EDITED_CHANNEL,
    payload: currentlyEditedChannel
  }
)

export const selectedMediaItem = (mediaItems) => {
  return {
    type: SELECTED_MEDIA_ITEM,
    payload: mediaItems[0]
  }
}

export const clearChanges = () => ({
  type: CLEAR_GLOBAL_MUSIC_CHANGES
})

export const libraryUpdate = GlobalMusicLibraryManager.libraryUpdate

const initialState = {
  changes: {},
  currentlyEditedChannel: '',
  selectedMediaItem: undefined,
  errorModalOpen: false,
}

const reducer = (state = initialState, action) => {
  
  let { type, payload } = action;

  switch(type) {
    case OPEN_ERROR_MODAL :
      return {
        ...state,
        errorModalOpen: true
      }
    case CLOSE_ERROR_MODAL :
      return {
        ...state,
        errorModalOpen: false
      }
  case AUDIO_POLICY :
      return {
        ...state,
        audioPolicy: payload
      }
    case DEFAULT_POLICY :
      return {
        ...state,
        defaultPolicy: payload
      }    
    case VOLUME :
      return {
        ...state,
        volume: payload
      }
    case CURRENTLY_EDITED_CHANNEL :
      return {
        ...state,
        currentlyEditedChannel: payload
      }
    case SELECTED_MEDIA_ITEM :
      return {
        ...state,
        selectedMediaItem: payload
      }
    case SET_BACKGROUND_MUSIC:
      return {
        ...state,
        backgroundMusic: payload
      }
    case SET_GLOBAL_MUSIC_CHANGES: {
      return {
        ...state,
        changes: setIn(state.changes, payload.path, payload.value)
      }
    }
    case CLEAR_GLOBAL_MUSIC_CHANGES: {
      return {
        ...state,
        changes: {}
      }
    }
    default:
      return state;
  }
}

export default GlobalMusicLibraryManager.wrap(reducer, initialState)
