import React, {Component} from 'react'
import {Modal, Button, Menu, TextArea, Form} from 'semantic-ui-react'
import {copySettings, parseTextToSettings} from 'helpers/settings_helpers'

import './CopyPasteSettingsModal.css'

export default class CopyPasteSettingsModal extends Component {

  constructor(props) {
    super(props)
    this.state = {open: false}
  }

  shouldComponentUpdate(prevprops, prevstate) {
    if(prevstate.open !== this.state.open) {
      return true
    } else if(!this.state.open) {
      return false
    } else if(Object.keys(this.props).length !== Object.keys(prevprops).length) {
      return true
    }
    for(let [key, val] of Object.entries(this.props)) {
      if(val !== prevprops[key]) {
        return true
      }
    }
    return false
  }

  textarea = React.createRef()
  copytextarea = React.createRef()

  handleCopy = () => {
    if(this.copytextarea.current && this.copytextarea.current.ref.current) {
      this.copytextarea.current.ref.current.select()
      document.execCommand('copy')
    }
  }

  handleClear = () => {
    if(this.textarea.current && this.textarea.current.ref.current) {
      this.textarea.current.ref.current.value = ''
      this.textarea.current.ref.current.focus()
    }
  }

  handleApply = () => {
    let {type, id, saveServiceSettings} = this.props
    if(this.textarea.current && this.textarea.current.ref.current) {
      let text = this.textarea.current.ref.current.value
      let toSave = {
        settings: parseTextToSettings(text)
      }
      saveServiceSettings(type, id, toSave)
    }
    this.setState({open: false})
  }

  render() {
    let {settings, schema} = this.props
    let {open} = this.state

    return (
      <Modal trigger={<Menu.Item icon="copy" content="Copy/Paste Settings"/>}
        closeIcon
        size="large"
        open={open}
        onOpen={() => {this.setState({open: true})}}
        onClose={() => {this.setState({open: false})}}>
        <Modal.Header>Copy or Paste Settings</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Current Settings</label>
                  <TextArea value={copySettings(settings, schema)} id='CopySettingsText' ref={this.copytextarea}/>
                </Form.Field>
                <Form.Field>
                  <label>Paste Settings</label>
                  <TextArea id='PasteSettingsText' ref={this.textarea}/>
                </Form.Field>
              </Form.Group>
              <Button onClick={this.handleCopy}>
                Copy
              </Button>
              <Button onClick={this.handleClear}>
                Clear
              </Button>
              <Button floated='right' positive onClick={this.handleApply} type='submit'>
                Apply
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }

}
