import React from "react";
import { Dropdown, Label, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";

const ItemSettingHandler = (schema) => {
  /* This component is handling the setting called "Item".
  schema is coming from SettingPanel.jsx
  Path for channels: /mnt/main/tv/rendermgr/channel${NUMBER} and inputs: /mnt/main/tv/inputs/${NAME} 
  */

  // This is getting the current channels and inputs from Redux which will be used to populate the dropdown.
  const channels = useSelector((state) => state.settings.services.channel);
  const inputs = useSelector((state) => state.settings.services.input);

  let channelEntries = Object.entries(channels).sort((a, b) => {
    // Convert to channel numbers
    let aNum = parseInt(a[0].match(/\d+/)[0], 10)
    let bNum = parseInt(b[0].match(/\d+/)[0], 10)
    return aNum - bNum
  });
  let inputKeys = Object.keys(inputs);

  let valuesToSend = [];

  // Pushing the channel name and the path to be sent to the back-end in one
  for (let i = 0; i < channelEntries.length; i++) {
    valuesToSend.push(
      `/${channelEntries[i][1].settings.name}/mnt/main/tv/rendermgr/${channelEntries[i][0]}`
    );
  }

  // Pushing the input path
  for (let i = 0; i < inputKeys.length; i++) {
    valuesToSend.push(`/mnt/main/tv/inputs/${inputKeys[i]}`);
  }

  let itemSettingOptions = valuesToSend.map((service) => {
    let splitService = service.split("/");
    let name;
    let value; // path to send
    // Handling of Channel Name and Path
    if (splitService.length === 7) {
      // Length is 7 because we are adding the Channel Name into the path
      name = splitService[1]; // Grabbing the Channel name
      splitService.splice(1, 1); // Removing the Channel name from the path
      value = splitService.join("/"); // Recreating the path to be sent to the back-end
      // Handling of Inputs
    } else {
      name = splitService[5]; // the 5th element holds the inputs name
      value = service; // service path is unchanged for inputs
    }

    return { key: `path - ${name}`, text: name, value: value };
  });

  let dropdownValue = schema.value;
  // We are having to filter out null / undefined values otherwise
  // an error gets thrown since dropdown values can't contain null or undefined types
  if (!dropdownValue) {
    dropdownValue = " ";
  }

  return (
    <>
      <Segment>
        <Label size="large">{schema.display_name}</Label>
        <Dropdown
          options={itemSettingOptions}
          placeholder=""
          onChange={schema.onChange}
          value={dropdownValue}
          selection
        />
        <div style={{ padding: "0.5em 0 0 0.5em" }}>{schema.description}</div>
      </Segment>
    </>
  );
};

export default ItemSettingHandler;
