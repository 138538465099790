import {getIn, setIn} from 'helpers/general_helpers'
import {parseSchema} from 'helpers/settings_helpers'
import {fetchFromServer, connectToServerSocket} from 'helpers/net_helpers'
import deepmerge from 'deepmerge'
import {messages} from 'redux/messages'

const LOAD_SETTINGS_SCHEMA = Symbol("load settings schema");
const LOAD_SERVICE_LIST = Symbol("load service list");
const LOAD_SERVICE_SETTINGS = Symbol("load service settings");
const UPDATE_SERVICE_SETTINGS = Symbol("update service settings");
const SAVE_SERVICE_SETTINGS = Symbol("save service settings");
const MODIFY_SETTINGS = Symbol("modify settings");
const SET_SETTINGS_SOCKET = Symbol('set settings socket');
const CHANGE_MAPPING = Symbol('change mapping');
const REVERT_MAPPING = Symbol('revert mapping');
const SET_OUTPUT_RECORDING_STATUS = Symbol('set output recording status');
export const SET_SETTINGS_SEARCH_STRING = Symbol('set settings search string');

const SCHEMA_ROOT = 'v2/schemata/'

const SCHEMA_LIST = [
  {name: "alsa.schema.json", type: "output"},
  {name: "blackmagic_decklink_sdi.schema.json", type: "output"},
  {name: "blackmagic_videohub.schema.json", type: "router"},
  {name: "channel.schema.json", type: "channel"},
  {name: "drmkms.schema.json", type: "output"},
  {name: "fbdev.schema.json", type: "output"},
  {name: "internet_mpeg.schema.json", type: "output"},
  {name: "knox_rsii.schema.json", type: "router"},
  {name: "knox_rs16x16hb.schema.json", type: "router"},
  {name: "knox_rs8x8hb.schema.json", type: "router"},
  {name: "newtek_ndi.schema.json", type: "output"},
  {name: "oss.schema.json", type: "output"},
  {name: "output_default.schema.json", type: "output"},
  {name: "pulse_audio.schema.json", type: "output"},
  {name: "router_default.schema.json", type: "router"},
  {name: "schedule_push.schema.json", type: "output"},
  {name: "shout_cast.schema.json", type: "output"},
  {name: "sierra_video_aspen_hdsdi.schema.json", type: "router"},
  {name: "trigger_rs232.schema.json", type: "input"},
  {name: "video_passthru.schema.json", type: "input"},
  {name: "video_passthru_v2.schema.json", type: "input"},
  {name: "x11.schema.json", type: "output"}
]

export const INPUT_TYPES = [
  'video-passthru',
  'video-passthru-v2',
  'trigger-rs232'
]

export const actions = {

  connectToSettingsSocket: () => {
    return async (dispatch, getState) => {
      if(getState().settings.settings_socket) {
        return
      }
      var connection = connectToServerSocket('/settings')
      if(!connection) {
        return
      }
      dispatch({type: SET_SETTINGS_SOCKET, payload: connection})
      connection.on("initialize", (data) => {
        Object.entries(data).forEach(([type, services]) => {
          Object.entries(services).forEach(([id, options]) => {
            dispatch({
              type: LOAD_SERVICE_SETTINGS,
              payload: {
                type,
                id,
                data: options
              }
            })
          })
        })
      })
      connection.on('channel', (data) => {
        dispatch({
          type: UPDATE_SERVICE_SETTINGS,
          payload: {
            type: 'channel',
            data
          }
        })
      })
      connection.on('output', (data) => {
        dispatch({
          type: UPDATE_SERVICE_SETTINGS,
          payload: {
            type: 'output',
            data
          }
        })
      })
      connection.on('input', (data) => {
        dispatch({
          type: UPDATE_SERVICE_SETTINGS,
          payload: {
            type: 'input',
            data
          }
        })
      })
      connection.on('router', (data) => {
        dispatch({
          type: UPDATE_SERVICE_SETTINGS,
          payload: {
            type: 'router',
            data
          }
        })
      })
    }
  },

  startService: (type, id) => {
    return async (dispatch, getState) => {
      let response = await fetchFromServer(`/v2/services/${type}/doss/start?id=`+encodeURIComponent(id), {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error starting ${type} ${id}!`)
      }
    }
  },

  stopService: (type, id) => {
    return async (dispatch, getState) => {
      let response = await fetchFromServer(`/v2/services/${type}/doss/stop?id=`+encodeURIComponent(id), {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error stopping ${type} ${id}!`)
      }
    }
  },

  restartService: (type, id) => {
    return async (dispatch, getState) => {
      let response = await fetchFromServer(`/v2/services/${type}/doss/restart?id=`+encodeURIComponent(id), {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error restarting ${type} ${id}!`)
      }
    }
  },

  doSupportCommand: (what) => {
    return async (dispatch, getSate) => {
      let promptMsg = '?'
      if (what === 'start')
        promptMsg = 'Connect to Castus to enable a technician to service your playout system?'
      else if (what === 'stop')
        promptMsg = 'If a technician is working on your unit, this will interrupt their access.\nDrop support connection?'
      let conf = await dispatch(messages.confirmAsync(
        promptMsg
      ))
      if(!conf) {
        return
      }
      // Yes, this is a request to kill all media items.
      //  They will be restarted automatically by the channels
      let response = await fetchFromServer(`/v2/support/${what}`, {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error controlling support connection`)
      }
    }
  },

  makeV5default: () => {
    return async (dispatch, getSate) => {
      let conf = await dispatch(messages.confirmAsync(
        "Make this version the default interface?"
      ))
      if(!conf) {
        return
      }
      let response = await fetchFromServer(`/v2/services/makev5default`, {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error setting default interface!`)
      }
    }
  },

  resetMediaItems: () => {
    return async (dispatch, getSate) => {
      let conf = await dispatch(messages.confirmAsync(
        "This will reset all running media items. If any of them are currently on air, there will be an interruption. Continue?"
      ))
      if(!conf) {
        return
      }
      // Yes, this is a request to kill all media items.
      //  They will be restarted automatically by the channels
      let response = await fetchFromServer(`/v2/services/kill/all`, {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error resetting media items!`)
      }
    }
  },

  resetPlaylistScheduleItems: () => {
    return async (dispatch, getSate) => {
      let conf = await dispatch(messages.confirmAsync(
        "This will reset all running playlists/schedules. If any of them are currently on air, there will be an interruption. Continue?"
      ))
      if(!conf) {
        return
      }
      // Same as with resetMediaItems.
      let response = await fetchFromServer(`/v2/services/kill/playlists`, {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error resetting playlists/schedules!`)
      }
    }
  },

  createService: (type, inputType='') => {
    return async (dispatch, getState) => {
      let newName = ''
      if(type === "input") {
        if(!INPUT_TYPES.includes(inputType)) {
          dispatch(messages.alert(`Tried to create an input of type: ${inputType}, but that is not a valid input type. Inputs must be one of the following types: ${INPUT_TYPES.join(', ')}`, {level: 'error'}))
          return
        } else {
          inputType = `&type=${inputType}`
        }
      } else {
        inputType = ''
      }
      let names = Object.keys(getIn(getState(), ['settings', 'services', type]))
      if(type === 'channel') {
        if(!(await dispatch(messages.confirmAsync("Create a new channel?")))) {
          return
        }
        let num = 1
        while(true) {
          if(names.includes(`channel${num}`)) {
            num++
          } else {
            break
          }
        }
        newName = `channel${num}`
      } else {
        newName = await dispatch(messages.promptAsync(`What should the new ${type} be called?`, {validate: (name) => {
          return !(names.includes(name))
        }, invalidText: `That ${type} already exists!`}))
        if(newName === null) {
          return
        }
      }
      let response = await fetchFromServer(`/v2/services/${type}/create?id=${newName}${inputType}`, {
        method: 'POST'
      })
      if(!response.ok) {
        dispatch(messages.alert(`There was an error creating the ${type}!`, {level: 'error'}))
      }
    }
  },

  deleteService: (type, id) => {
    return async (dispatch, getState) => {
      if(!await dispatch(messages.confirmAsync(`Are you sure you want to delete ${type} ${id}? It cannot be undone!`))) {
        return
      }
      let response = await fetchFromServer(`/v2/services/${type}/${id}`, {
        method: 'DELETE'
      })
      if(!response.ok) {
        dispatch(messages.alert(`There was an error deleting ${type} ${id}!`))
      }
    }
  },

  renameService: (type, id) => {
    return async (dispatch, getState) => {
      let newName = ''
      let names = Object.keys(getIn(getState(), ['settings', 'services', type]))
      if(type === 'channel') {
        newName = await dispatch(messages.promptAsync(`What should the new ${type}'s number be?`, {validate: (name) => {
          return !(names.includes(`channel${name}`))
        }, invalidText: `That ${type} already exists!`, type: 'number'}))
        if(newName === null) {
          return
        }
        newName = `channel${newName}`
      } else {
        newName = await dispatch(messages.promptAsync(`What should the new ${type} be called?`, {validate: (name) => {
          return !(names.includes(name))
        }, invalidText: `That ${type} already exists!`}))
        if(newName === null) {
          return
        }
      }
      let response = await fetchFromServer(`/v2/services/${type}/${id}/rename?to=${newName}`, {
        method: 'POST'
      })
      if(!response.ok) {
        dispatch(messages.alert(`There was an error renaming ${type} ${id} to ${newName}!`, {level: 'error'}))
      }
    }
  },

  loadSettingsSchema: () => {
    return async (dispatch, getState) => {
      let schema = await Promise.all(SCHEMA_LIST.map(async (schema) => {
        // Try schema type directory first
        let res = await fetchFromServer(`/${SCHEMA_ROOT}${schema.type}/${schema.name}`)
        if(res.ok) {
          return res
        } else {
          if(res.status !== 404) {
            return null
          }
        }
        // If the response was not ok with status 404, try top-level schema directory
        res = await fetchFromServer(`/${SCHEMA_ROOT}${schema.name}`)
        if(res.ok) {
          return res
        } else {
          return null
        }
      }))
      schema = schema.filter((file) => (file !== null))
      schema = await Promise.all(schema.map(response => response.json()))
      let jsonSchema = {}
      schema.forEach((service) => {
        let typeCheck = /(?:(channel|output|input|router)\/)?([^/.]*).schema.json/.exec(service["$id"])
        let type = typeCheck[1] ? typeCheck[1] : typeCheck[2]
        let subtype = typeCheck[2]
        jsonSchema = setIn(jsonSchema, [type, subtype], parseSchema(service))
      })
      dispatch({type: LOAD_SETTINGS_SCHEMA, payload: jsonSchema})
    }
  },

  /**
   * Loads the list of services from the server
   */
  loadServiceList: () => {
    return async (dispatch, getState) => {
      // TODO: Get list from server here
      let data = await fetch("/test_service_list.json")
      data = await data.json()
      Object.entries(data).forEach(([type, services]) => {
        Object.entries(services).forEach(([id, initialOptions]) => {
          dispatch(actions.loadServiceSettings(type, id, initialOptions))
        })
      })
    }
  },

  /**
   * Loads the settings for a given service from the server
   * @param {string} type The type of the service to load settings for.
   *  Possible values are: channel, output, input, and router
   * @param {string} id The identifier for the service (such as channel number, output name, etc.)
   * @param {object} initialOptions An optional object containing any initial state for the given
   *  service
   */
  loadServiceSettings: (type, id, initialOptions={}) => {
    return async (dispatch, getState) => {
      let data = initialOptions
      try {
        // let results = await fetch("/channel1.json")
        let results = await fetchFromServer(`/v2/other/${type}/${id}`)
        if(results.ok) {
          let newData = await results.json()
          data = deepmerge(data, newData, {arrayMerge: combineMerge})
        } else {
          console.error(`Error fetching ${type} ${id}.`)
        }
      } catch(err) {
        // TODO: Replace this with something more descriptive
        console.error(err);
      }
      dispatch({
        type: LOAD_SERVICE_SETTINGS,
        payload: {
          type,
          id,
          data
        }
      })
    }
  },

  /**
   * Saves changes to a given service's settings to the server
   * @param {string} type The type of service to save the setting of.
   *  Possible values are: channel, output, input, and router
   * @param {string} id The identifier for the service (such as channel number, output name, etc.)
   * @param {object} settings An optional object containing changes to the service's settings to save to the server.
   *  If passed in, the settings argument will be used and the serviceChanges in state will NOT be used.
   *  If not passed in, the serviceChanges will be saved
   */
  saveServiceSettings: (type, id, settings=null) => {
    return async (dispatch, getState) => {
      let fromState = false
      if(!settings) {
        settings = getIn(getState().settings.serviceChanges, [type, id])
        fromState = true
      }
      if(!settings) {
        console.error(`Tried to save settings changes for ${type} ${id}, but no changes were passed and no changes were in state either`)
        return
      }
      if('settings' in settings) {
        settings = settings.settings
      }
      let driver = null
      if(type === 'channel') {
        driver = 'channel'
      } else {
        driver = getIn(getState(), ['settings', 'serviceChanges', type, id, 'settings', 'driver']) ||
          getIn(getState(), ['settings', 'services', type, id, 'settings', 'driver'])
      }
      if(driver) {
        let response = await fetchFromServer(`/v2/services/${type}/${driver}/${id}`, {
          method: 'PATCH',
          body: JSON.stringify(settings),
          headers: {
            'Content-Type': 'application/json'
            }
          })
        if(response.ok) {
          dispatch({
            type: SAVE_SERVICE_SETTINGS,
            payload: {
              type,
              id,
              settings,
              fromState
            }
          })
        } else {
          console.error("Error saving settings!")
        }
      } else {
        console.error("No driver, cannot save")
        return
      }
    }
  },

  /**
   * Changes a service setting
   * @param {string} type The type of service to modify
   * @param {string} id The identifier for the service to modify
   * @param {array} setting The full address of the setting to change within the service's settings object
   *  (EX: ['video', 'aspect_ratio', 'width'])
   * @param value The value to set the setting to
   */
  modifySettings: (type, id, setting, value) => ({
    type: MODIFY_SETTINGS,
    payload: {
      type,
      id,
      setting,
      value
    }
  }),

  /**
   * Sets queue item looping for a given channel
   * @param {number} id The id of the channel to set looping for
   * @param {boolean} loop What to set looping to
   */
  setQueueItemLooping: (id, loop) => {
    return async (dispatch, getState) => {
      let looping = loop ? '1' : '0'
      let response = await fetchFromServer(`/v2/services/channel/${id}/queue/loop?loop=${looping}`, {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error setting looping for channel ${id}`)
        return
      }
    }
  },

  /**
   * Goes to the next item in the queue for a given channel
   * @param {number} id The id of the channel to go to the next item of
   */
  nextQueueItem: (id) => {
    return async (dispatch, getState) => {
      let response = await fetchFromServer(`/v2/services/channel/${id}/queue/next`, {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error going to next item in queue for channel ${id}`)
        return
      }
    }
  },

  /**
   * Empties the queue and returns to the assigned item
   * @param {number} id The id of the channel to end the queue of
   */
  endQueue: (id) => {
    return async (dispatch, getState) => {
      let response = await fetchFromServer(`/v2/services/channel/${id}/queue/end`, {
        method: 'POST'
      })
      if(!response.ok) {
        console.error(`Error going to next item in queue for channel ${id}`)
        return
      }
    }
  },

  /**
   * Starts recording an output
   * @param {string} id The id of the output to start recording
   */
  startOutputRecording: (id) => {
    return async (dispatch, getState) => {
      let response = await fetchFromServer(`/v2/recording/output/start/${id}`, {
        method: "POST"
      })
      if(!response.ok) {
        let err = await response.text()
        console.error(`Error starting recording of output ${id}`)
        console.error(err)
        return
      }
    }
  },

  /**
   * Stops recording an output
   * @param {string} id The id of the output to stop recording
   */
  stopOutputRecording: (id) => {
    return async (dispatch, getState) => {
      let response = await fetchFromServer(`/v2/recording/output/stop/${id}`, {
        method: "POST"
      })
      if(!response.ok) {
        let err = await response.text()
        console.error(`Error stopping recording of output ${id}`)
        console.error(err)
        return
      }
    }
  },

  renameOutputRecording: (id) => {
    return async (dispatch, getState) => {
      let newName = await dispatch(messages.promptAsync("What do you want the name of the recording to be?"))
      if(newName === null) {
        return
      }
      let response = await fetchFromServer(`/v2/recording/output/rename/${id}`, {
        method: "POST",
        body: `${newName}`
      })
      if(!response.ok) {
        let err = await response.text()
        console.error(`Error renaming recording of output ${id}`)
        console.error(err)
        return
      }
    }
  },

  outputRecordingStatus: (id) => {
    return async (dispatch, getState) => {
      let response = await fetchFromServer(`/v2/recording/output/status/${id}`)
      if(!response.ok) {
        let err = await response.text()
        console.error(`Error getting recording status of output ${id}`)
        console.error(err)
        return
      } else {
        let result = await response.json()
        dispatch({
          type: SET_OUTPUT_RECORDING_STATUS,
          payload: {
            id,
            stats: result
          }
        })
      }
    }
  },

  /**
   * Sets a change to the router mappings
   * @param {string} id The id of the router to be edited
   * @param {number} output The output to change the mapping of
   * @param {number/array} input The input to set the output's mapping to. If a number
   *  is passed, all components of the mapping will be set to that input. If an array is passed,
   *  each component will be set individually based on the array.
   */
  changeMapping: (id, output, input) => {
    return (dispatch, getState) => {
      if(typeof input === 'number') {
        let channels = getIn(getState().settings, ['services', 'router', id, 'settings', 'routing', 'inputData', 'channels'])
        if(channels) {
          channels = channels.length
        } else {
          channels = 1
        }
        let inNumber = input
        input = []
        for(let i = 0; i < channels; i++) {
          input.push(inNumber)
        }
      }
      dispatch({
        type: CHANGE_MAPPING,
        payload: {
          id,
          output,
          input
        }
      })
    }
  },

  /**
   * Saves changes to the mapping of a router to the server
   * @param {string} id The id of the router to save changes of
   */
  saveMapping: (id) => {
    return async (dispatch, getState) => {
      let changes = getIn(getState(), ['settings', 'routingChanges', id, 'settings', 'routing', 'outputData', 'mappings'])
      let response = await fetchFromServer(`/v2/services/router/${id}/routing`, {
        method: 'POST',
        body: JSON.stringify(changes),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if(!response.ok) {
        console.error(`Error saving changes to routings for router ${id}`)
      }
    }
  },

  /**
   * Reverts local changes to the mapping of a router
   * @param {string} id The id of the router to revert changes of
   */
  revertMapping: (id) => ({
    type: REVERT_MAPPING,
    payload: id
  }),

  /**
   * Sets the string to search/filter the settings of the viewed service by
   * @param {string} string The new string to search by (an empty string indicates no search being performed)
   */
  setSettingsSearchString: (string) => ({
    type: SET_SETTINGS_SEARCH_STRING,
    payload: string
  }),

  /**
   * Sets-up the user's vod.json config file
   */
  setupVodConfig: () => {
    return async (dispatch, getState) => {
      let email = await dispatch(messages.promptAsync("Please enter the email address of your CASTUS cloud account."));
      if(!email) {
        return
      }
      return fetchFromServer("/v2/uploads/vod-config", {
        method: "POST",
        body: JSON.stringify({email}),
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }
  }

}

export const initialState = {
  /*
   * Contains lists of individual services for each service type.
   * Each service list is an object containing service objects, where the keys are the service ids and the values are the service properties.
   *  Currently the following types of services exist: channels, outputs, inputs, and routers
   * In addition to the properties defined by the json schema in the control repository, each
   *  service object also has a status field, which is the string status of the service (Running, Stopped, Restarting, etc.)
   */
  services: {
    channel: {},
    output: {},
    input: {},
    router: {}
  },
  serviceChanges: {
    channel: {},
    output: {},
    input: {},
    router: {}
  },
  schema: {
    channel: {},
    output: {},
    input: {},
    router: {}
  },
  settingsSearchString: "", // Search string for searching the settings
  routingChanges: {},    // Changes to router routings in controls
  settings_socket: null, // Server socket for settings updates
  global_settings: {} // Settings that are universal, as opposed to settings that are specific to a service
}

export default (state=initialState, action) => {

  let {type, payload} = action

  switch(type) {
    case SET_SETTINGS_SOCKET: {
      return {
        ...state,
        settings_socket: payload
      }
    }
    case LOAD_SETTINGS_SCHEMA: {
      return {
        ...state,
        schema: payload
      }
    }
    case LOAD_SERVICE_LIST: {
      return {
        ...state,
        services: payload
      }
    }
    case LOAD_SERVICE_SETTINGS: {
      let {type, id, data} = payload
      if(typeof id === 'number') {
        id = `${id}`
      }
      let currentData = getIn(state, ['services', type, id, 'settings'])
      if(currentData) {
        data = deepmerge(currentData, data, {arrayMerge: combineMerge})
      }
      return setIn(state, ['services', type, id, 'settings'], data)
    }
    case UPDATE_SERVICE_SETTINGS: {
      let {type, data} = payload
      if(!data) {
        return state
      }
      let currentData = getIn(state, ['services', type])
      // If a service was deleted, it needs to be removed from the serviceChanges as well, or it will keep showing up in the list.
      let currentChanges = getIn(state, ['serviceChanges', type])
      let deleted = false
      /*
      data.forEach((change) => {
        let [id, ...path] = change.path
        if(change.kind === "D") {
          if(path.length === 0 && currentData[id]) {
            delete currentData[id]
            if(currentChanges[id]) {
              delete currentChanges[id]
              deleted = true
            }
          } else {
            currentData = deleteIn(currentData, [id, 'settings', ...path])
          }
        } else if (change.kind === "A") {
          path = [...path, change.index]
          let internalChange = change.item
          if(internalChange.kind === "D") {
            currentData = deleteIn(currentData, [id, 'settings', ...path])
          } else {
            currentData = setIn(currentData, [id, 'settings', ...path], internalChange.rhs)
          }
        } else {
          currentData = setIn(currentData, [id, 'settings', ...path], change.rhs)
        }
      })*/
      for(let [id, settings] of Object.entries(data)) {
        if(settings === null) {
          delete currentData[id]
          delete currentChanges[id]
          deleted = true
        } else if (!currentData[id]) {
          currentData = setIn(currentData, [id], settings)
        } else {
          // Make sure we aren't setting global regions to null. They cannot really be deleted, so a null is meaningless for them
          if(getIn(settings, ["settings", "regions", "global"])) {
            let regions = getIn(settings, ["settings", "regions", "global"])
            settings = setIn(settings, ["settings", "regions", "global"], regions.map((region) => {
              if(region === null) {
                return {}
              }
              return region
            }))
          }
          currentData = setIn(currentData, [id], deepmerge(currentData[id], settings, {arrayMerge: combineMerge}))
        }
      }
      state = setIn(state, ['services', type], currentData)
      if(deleted) {
        state = setIn(state, ['serviceChanges', type], currentChanges)
      }
      return state
    }
    case SAVE_SERVICE_SETTINGS: {
      let {type, id, settings, fromState} = payload
      let currentSettings = getIn(state.services, [type, id])
      let changes = state.serviceChanges
      if(fromState) {
        changes = setIn(state.serviceChanges, [type, id], {})
      }
      if(currentSettings.settings && !settings.settings) {
        settings = {settings: settings}
      }
      return {
        ...state,
        services: setIn(state.services, [type, id], deepmerge(currentSettings, settings, {arrayMerge: combineMerge})),
        serviceChanges: changes
      }
    }
    case MODIFY_SETTINGS: {
      let {type, id, setting, value} = payload
      let dest = [type, id, 'settings', ...setting]
      return {
        ...state,
        serviceChanges: setIn(state.serviceChanges, dest, value)
      }
    }
    case CHANGE_MAPPING: {
      let {id, output, input} = payload
      return setIn(state, ['routingChanges', id, 'settings', 'routing', 'outputData', 'mappings', `${output}`], input)
    }
    case REVERT_MAPPING: {
      return setIn(state, ['routingChanges', payload, 'settings', 'routing', 'outputData', 'mappings'], {})
    }
    case SET_SETTINGS_SEARCH_STRING: {
      return {
        ...state,
        settingsSearchString: payload
      }
    }
    case SET_OUTPUT_RECORDING_STATUS: {
      let {id, stats} = payload
      return setIn(state, ['services', 'output', id, 'recording_status'], stats)
    }
    default:
      return state
  }
}



const emptyTarget = value => Array.isArray(value) ? [] : {}
const clone = (value, options) => deepmerge(emptyTarget(value), value, options)

function combineMerge(target, source, options) {
	const destination = target.slice()

  let hasObject = destination.findIndex((item) => {
    return options.isMergeableObject(item)
  })

  if(hasObject === -1) {
    return source
  }

	source.forEach(function(e, i) {
		if (typeof destination[i] === 'undefined') {
			const cloneRequested = options.clone !== false
			const shouldClone = cloneRequested && options.isMergeableObject(e)
			destination[i] = shouldClone ? clone(e, options) : e
    } else if (target[i] === null) {
      destination[i] = null
		} else if (options.isMergeableObject(e)) {
			destination[i] = deepmerge(target[i], e, options)
		} else {
			destination[i] = e
		}
	})
	return destination
}
