import React, {Component} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toggle_sidebar } from 'redux/channel'
import { actions as SettingsActions } from 'redux/settings'
import { actions as CloudActions } from 'redux/applications/cloud'
import { messages } from 'redux/messages'
import { setSearchNext, searchFileData } from 'redux/file_list'
import { fetchInterfaceConfig } from 'redux/server'
import { getTimezoneListFromServer } from 'redux/menu'
import { subscribeToSystemStatus } from 'redux/system'
import { actions as AboutActions } from 'redux/applications/about'
import MenuBar from './MenuBar'
import WidgetBar from './WidgetBar'
import ApplicationPane from './ApplicationPane'
import MessageSystem from './MessageSystem'
import {
  Button,
  Label,
  Segment
} from 'semantic-ui-react'

import {getIn} from 'helpers/general_helpers'
import {checkHasAccessToApp} from 'helpers/authentication_helpers'

import {BETA} from "config.js"

import './App.css'

import Messages from './MessageContext'

class App extends Component {

  componentDidMount() {
    this.props.connectToSettingsSocket()
    this.props.loadSettingsSchema()
    this.props.subscribeToSystemStatus()
    this.props.fetchAboutInformation()
    this.props.getVodConfig()
    this.props.fetchInterfaceConfig()
  }

  componentDidUpdate(prevprops) {
    if(!this.props.file_list.isSearching &&
      this.props.file_list.searchNext &&
      (this.props.file_list.searchNext !== prevprops.file_list.searchNext ||
      this.props.file_list.isSearching !== prevprops.file_list.isSearching)) {
      this.props.searchFileData(this.props.file_list.searchNext)
      this.props.setSearchNext(null)
    }
  }

  render() {
    let {sidebar_out, toggle_sidebar, about, appAccess} = this.props
    let icon = sidebar_out ? 'caret left' : 'caret right'
    let sidebarClassname = sidebar_out ? 'sidebar sidebar_out' : 'sidebar sidebar_in'

    // Beta label
    let betaLabel = ""
    // Build type from version file
    let buildType = getIn(about, ["versions", "platform", "Build type"])
    if(buildType) {
      buildType = buildType.trim()
    }
    if(BETA || buildType === "beta") {
      betaLabel = <Label color="red" attached="top left" size="mini" id="BetaLabel">BETA</Label>
    } else if (buildType === "alpha") {
      betaLabel = <Label color="red" attached="top left" size="mini" id="BetaLabel">ALPHA</Label>
    }

    // Widget Sidebar
    let sidebar = <ApplicationPane/>
    if(checkHasAccessToApp(appAccess, 'Settings')) {
      sidebar = (
        <div className='sidebar_container'>
          <div className={sidebarClassname}>
            <WidgetBar/>
          </div>
          <div id={'content_container'}>
            <Button compact
              id='sidebar_visibility_toggle'
              icon={icon}
              size='mini'
              onClick={toggle_sidebar}/>
            <ApplicationPane/>
          </div>
        </div>
      )
    }

    return (
      <Segment id='app'>
        <Messages.Provider value={{
          alert: this.props.alert,
          confirm: this.props.confirm,
          confirmAsync: this.props.confirmAsync,
          prompt: this.props.prompt,
          promptAsync: this.props.promptAsync,
        }}>
          <header>
            <MenuBar/>
          </header>

          {sidebar}
          <MessageSystem/>
        </Messages.Provider>
        {betaLabel}
      </Segment>
    )
  }
}

const mapStateToProps = state => ({
  sidebar_out: state.channel.sidebar_out,
  routing: state.router,
  file_list: state.file_list,
  about: state.about,
  appAccess: state.server && state.server.appAccess,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  toggle_sidebar,
  getTimezoneListFromServer,
  subscribeToSystemStatus,
  setSearchNext,
  searchFileData,
  fetchInterfaceConfig,
  fetchAboutInformation: AboutActions.fetchAboutInformation,
  getVodConfig: CloudActions.getVodConfig,
  ...SettingsActions,
  ...messages
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
