import React, {PureComponent} from 'react'
import {Button, Icon} from 'semantic-ui-react'

class ScheduleItemErrorButtons extends PureComponent {

  constructor(props) {
    super(props)
    this.handleBeShifted = this.handleBeShifted.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
  }

  handleBeShifted(backwards) {
    let {shiftItem, scheduleItemKey} = this.props
    shiftItem(0, backwards, scheduleItemKey)
  }

  handleDeleteItem() {
    let {removeScheduleItems, scheduleItemKey} = this.props
    removeScheduleItems([scheduleItemKey])
  }

  render() {
    let {errors,
      inBlock,
      scheduleItemKey,
      shiftItem,
      itemBeShifted,
      expandScheduleBlock} = this.props

    let frontEnd = null
    let frontDrop = null
    let rearEnd = null
    let rearDrop = null

    let pushUpBtn = ''
    let pushDownBtn = ''
    let expandUpBtn = ''
    let expandDownBtn = ''

    errors.forEach((err) => {
      switch(err.type) {
        case 'FRONT_END':
          frontEnd = err
          break
        case 'REAR_END':
          rearEnd = err
          break
        case 'TOTAL':
          frontEnd = err
          rearEnd = err
          break
        case 'FRONT_DROP_OFF':
          frontDrop = err
          break
        case 'REAR_DROP_OFF':
          rearDrop = err
          break
        case 'TOTAL_DROP_OFF':
          frontDrop = err
          rearDrop = err
          break
        default:
          break
      }
    })

    if(frontEnd) {
      pushUpBtn = (<Button compact
        size='mini'
        key='push up'
        title='Move This Item Back'
        onClick={(e) => {
          e.stopPropagation()
          itemBeShifted(true, scheduleItemKey)
        }}
        ><Icon className='long arrow up'/> Move Back</Button>)
    }
    if(rearEnd) {
      pushDownBtn = (<Button compact
        size='mini'
        title='Move This Item Forward'
        key='push down'
        onClick={(e) => {
          e.stopPropagation()
          itemBeShifted(false, scheduleItemKey)
        }}
        ><Icon className='long arrow down'/> Move Forward</Button>)
    }
    if(frontDrop) {
      if(inBlock) {
        expandDownBtn = (<Button compact
          size='mini'
          key='expand down'
          title='Expand Schedule Forward'
          onClick={(e) => {
            e.stopPropagation()
            expandScheduleBlock(frontDrop.frontSeverity, {backwards: false, key: scheduleItemKey})
          }}
          ><Icon className='long arrow down'/> Expand Schedule</Button>)
      }
      if(frontEnd === null) {
        pushUpBtn = (<Button compact
          size='mini'
          title='Move This Item Back'
          key='push up'
          onClick={(e) => {
            e.stopPropagation()
            shiftItem(frontDrop.frontSeverity, true, scheduleItemKey)
          }}
          ><Icon className='long arrow up'/> Move Back</Button>)
      }
    }
    if(rearDrop) {
      if(inBlock) {
        expandUpBtn = (<Button compact
          size='mini'
          key='expand up'
          title='Expand Schedule Back'
          onClick={(e) => {
            e.stopPropagation()
            expandScheduleBlock(rearDrop.rearSeverity, {backwards: true, key: scheduleItemKey})
          }}
          ><Icon className='long arrow up'/> Expand Schedule</Button>)
      }
      if(rearEnd === null) {
        pushDownBtn = (<Button compact
          size='mini'
          key='push down'
          title='Move This Item Forward'
          onClick={(e) => {
            e.stopPropagation()
            shiftItem(rearDrop.rearSeverity, false, scheduleItemKey)
          }}
          ><Icon className='long arrow down'/> Move Forward</Button>)
      }
    }

    return (
      <div className='scheduleItemErrorButtons'>
        <Button compact
          size='mini'
          key='remove'
          title='Delete This Item'
          onClick={(e) => {
            e.stopPropagation()
            this.handleDeleteItem()
          }}>
          <Icon name='remove'/> Delete
        </Button>
        {pushUpBtn}
        {pushDownBtn}
        {expandUpBtn}
        {expandDownBtn}
      </div>
    )
  }

}

export default ScheduleItemErrorButtons
