import React from 'react'
import {Form} from 'semantic-ui-react'
import TimeInput from 'components/TimeInput/TimeInput'
import DurationInput from 'components/TimeInput/DurationInput'
import DateTimeInput from 'components/TimeInput/DateTimeInput'

import "./RequestFormField.css"

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE

const durationToString = (duration) => {
  let hours = Math.floor(duration / HOUR)
  let minutes = Math.floor((duration % HOUR) / MINUTE)
  let seconds = Math.floor((duration % MINUTE) / SECOND)
  let toReturn = ""
  if(hours > 0) {
    toReturn = `${hours}:${("" + minutes).padStart(2, "0")}:${("" + seconds).padStart(2, "0")}`
  } else {
    toReturn = `${minutes}:${("" + seconds).padStart(2, "0")}`
  }
  return toReturn
}

const stringToDuration = (str) => {
  let results = /(?:(\d+):)?(\d{1,2}):(\d{2})/.exec(str)
  if(!results) {
    return 0
  }
  let [hours, minutes, seconds] = results.slice(1)
  let toReturn = 0
  if(hours) {
    hours = parseInt(hours, 10)
    if(!isNaN(hours)) {
      toReturn += (hours * HOUR)
    }
  }
  if(minutes) {
    minutes = parseInt(minutes, 10)
    if(!isNaN(minutes)) {
      toReturn += (minutes * MINUTE)
    }
  }
  if(seconds) {
    seconds = parseInt(seconds, 10)
    if(!isNaN(seconds)) {
      toReturn += (seconds * SECOND)
    }
  }
  return toReturn
}

function RequestFormField(props) {
  let {value, onChange} = props
  switch(props.type) {
    case "text":
      return (
        <Form.Input label={props.label || props.name}
          name={props.name || props.label}
          placeholder={props.placeholder || props.label || props.name}
          required={props.required}
          value={value || ""}
          onChange={(e, data) => {onChange(props.name, data.value)}}/>
      );
    case "textarea":
      return (
        <Form.TextArea label={props.label || props.name}
          name={props.name || props.label}
          placeholder={props.placeholder || props.label || props.name}
          required={props.required}
          value={value || ""}
          onChange={(e, data) => {onChange(props.name, data.value)}}/>
      )
    case "checkbox":
      return (
        <Form.Checkbox label={props.label || props.name}
          name={props.name || props.label}
          required={props.required}
          checked={value || false}
          onChange={(e, data) => {onChange(props.name, data.checked)}}/>
      )
    case "radio": {
      let options = props.options.map((name) => (
        <Form.Radio label={name}
          key={name}
          value={name}
          checked={value === name}
          onChange={(e, data) => {onChange(props.name, data.value)}}/>
      ))
      return (
        <Form.Group inline className={(props.required ? "grouprequired" : "")}>
          <label>{props.name || props.label}</label>
          {options}
        </Form.Group>
      )
    }
    case "select":
      return (
        <Form.Select label={props.label || props.name}
          name={props.name || props.label}
          required={props.required}
          placeholder={props.placeholder || props.label || props.name}
          options={props.options}
          value={value || ""}
          onChange={(e, data) => {onChange(props.name, data.value)}}/>
      )
    case "select_multiple": {
      value = value || {}
      let checkboxes = props.options.map((name) => (
        <Form.Checkbox label={name}
          key={name}
          checked={value[name] || false}
          onChange={(e, data) => {onChange(props.name, {...value, [name]: data.checked})}}/>
      ))
      return (
        <Form.Field label={props.label || props.name} required={props.required} control={() => (
          <Form.Group className="RequestFormSelectMultipleGroup" inline>
            {checkboxes}
          </Form.Group>
        )}/>
      )
    }
    case "time": {
      return (
        <Form.Field label={props.label || props.name}
          name={props.name || props.label}
          required={props.required}
          value={value}
          onChange={(val) => {onChange(props.name, val)}}
          control={TimeInput}/>
      )
    }
    case "datetime": {
      return (
        <Form.Field label={props.label || props.name}
          name={props.name || props.label}
          required={props.required}
          value={value}
          onChange={(val) => {onChange(props.name, val)}}
          control={DateTimeInput}/>
      )
    }
    case "duration": {
      if(value && typeof value === "string") {
        value = stringToDuration(value)
      }
      return (
        <Form.Field label={props.label || props.name}
          name={props.name || props.label}
          required={props.required}
          value={value}
          onChange={(val) => {
            val = durationToString(val)
            onChange(props.name, val)}
          }
          control={DurationInput}/>
      )
    }
    case "group":
      let fields = props.fields.map((field) => {
        let childValue = value[field.name]
        if(field.type === "group") {
          childValue = value
        }
        return (<RequestFormField {...field} key={`${props.name}/${field.name}`} value={childValue} onChange={onChange}/>)
      })
      let label = null
      if(props.label) {
        label = (<label>{props.label}</label>)
      }
      return (
        <Form.Group widths="equal" className="RequestFormGroup" {...(props.props || {})}>
          {label}
          {fields}
        </Form.Group>
      )
    default:
      return null;
  }
}

export default RequestFormField
