import React, {useState, useEffect} from 'react'
import {Modal,
  Input,
  Button,
  Container,
  Menu,
  Icon,
  Loader,
  Dimmer,
  Message} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'

import "./ScheduleSetsModal.css"

const INITIAL_STATE = {
  showCreate: false,
  loading: true,
  name: "",
  basis: new Date(),
  duration: 3,
  errorMsg: ""
}

const NAME_REGEX = /[ a-zA-Z0-9_.-]/g

function ScheduleSetsModal(props) {
  let {open,
    closeModal,
    scheduleSets=[],
    listScheduleSets,
    createScheduleSet,
    applyScheduleSet} = props
  const [state, setState] = useState(INITIAL_STATE)

  useEffect(() => {
    if(open) {
      setState({...state, loading: true})
      listScheduleSets()
        .finally(() => {
          setState({...state, loading: false})
        })
    }
  }, [open])

  let sets = scheduleSets.map((name) => (
    <Menu.Item key={name}
      name={name}
      onClick={(e, data) => {
        setState({...state, loading: true})
        applyScheduleSet(data.name)
          .then(() => {
            setState({INITIAL_STATE})
            closeModal()
          })
          .catch((err) => {
            console.error(err)
            setState({...state, loading: false})
          })
      }}>
      {name}
    </Menu.Item>
  ))

  let formClassName = "createNewSetForm"
  if(!state.showCreate) {
    formClassName = formClassName + " hidden"
  }

  return (
    <Modal open={open} closeIcon onClose={() => {setState(INITIAL_STATE); closeModal()}}>
      <Modal.Header>Schedule Sets</Modal.Header>
      <Modal.Content>
        <Dimmer active={state.loading}>
          <Loader/>
        </Dimmer>
        <Menu vertical fluid>
          <Menu.Item>
            <Menu.Header>
              Change to which schedule set?
            </Menu.Header>
          </Menu.Item>
          {sets}
        </Menu>
        <Message attached
          error
          content={state.errorMsg}
          hidden={!state.errorMsg}
          onDismiss={() => {setState({...state, errorMsg: ""})}}/>
        <Container className={formClassName}>
          <Input value={state.name}
            label="Name"
            onChange={(e, data) => setState({...state, name: [...data.value.matchAll(NAME_REGEX)].join("")})}
            fluid/>
          {/*<Input value={state.basis.toDateString()} label="Start Day" readOnly/>*/}
          <DatePicker wrapperClassName='ui labeled input fluid'
              selected={state.basis}
              customInput={<Input label="Start Day" fluid/>}
              onChange={(value) => setState({...state, basis: value})}/>
          <Input className="setSetDaysInput"
              value={state.duration}
              type='number'
              label='Day(s)'
              min={1}
              onChange={(e, data) => {setState({...state, duration: parseInt(data.value, 10)})}}
              fluid/>
        </Container>
        <Button fluid positive icon onClick={() => {
          if(state.showCreate) {
            let exists = scheduleSets.find((set) => set === state.name)
            if(exists) {
              setState({...state, errorMsg: "A schedule set with that name already exists. Schedule sets must have unique names."})
              return
            }
            setState({...state, loading: true})
            createScheduleSet(state.name, state.basis, state.duration)
              .then(() => {
                setState({...state, showCreate: !state.showCreate, loading: false})
              })
              .catch((err) => {
                setState({...state, loading: false})
                console.error(err)
              })
          } else {
            setState({...state, showCreate: !state.showCreate})
          }
        }}>
          <Icon name="plus"/>
          Create New Set
        </Button>
      </Modal.Content>
    </Modal>
  )
}

export default ScheduleSetsModal;
