import React, {PureComponent} from 'react'
import {staticAsset} from 'helpers/net_helpers'
import {getStatus} from 'helpers/status_manager.js'

import "./ServicePage.css"

import {Header,
  Divider,
  Image,
  Icon,
  Dropdown,
  Tab} from 'semantic-ui-react'
import ServiceTable from 'components/Settings/ServiceTable'
import LogsPanel from 'components/Settings/Logs/LogsPanel'
import {camelToCapitalized} from 'helpers/general_helpers'

const SERVICE_TYPES = [
  'channel',
  'output',
  'input',
  'router',
  'system',
  'logs'
]

export default class ServicesPage extends PureComponent {

  handleTabChange = (e, data) => {
    let {history} = this.props
    history.push(`/services/${SERVICE_TYPES[data.activeIndex]}`);
  }

  handleSettingsPage = (name) => {
    this.props.openApplication('Settings', `/${name}`)
  }

  handleQueuePage = (name) => {
    this.props.openApplication('Settings', `/${name}/queue`)
  }

  handleControlsPage = (name) => {
    this.props.openApplication('Settings', `/${name}/controls`)
  }

  handleStartService = (name) => {
    let [type, id] = name.split('/')
    this.props.startService(type, id)
  }

  handleStopService = (name) => {
    let [type, id] = name.split('/')
    this.props.stopService(type, id)
  }

  handleRestartService = (name) => {
    let [type, id] = name.split('/')
    this.props.restartService(type, id)
  }

  handleDeleteService = (name) => {
    let [type, id] = name.split('/')
    this.props.deleteService(type, id)
  }

  handleRenameService = (name) => {
    let [type, id] = name.split('/')
    this.props.renameService(type, id)
  }

  render() {
    let {services,
      createService,
      makeV5default,
      resetMediaItems,
      doSupportCommand,
      resetPlaylistScheduleItems,
      fetchLogs,
      changeLogsType,
      match} = this.props

    let activeIndex = SERVICE_TYPES.indexOf(match.params.type)

    let tabPanes = SERVICE_TYPES.map((type) => {
      if(type === "logs") {
        return {
          key: type,
          menuItem: "Logs",
          render: () => (
            <div className="SettingsTabContents">
              <LogsPanel {...services.logs} fetchLogs={fetchLogs} changeLogsType={changeLogsType}/>
            </div>
          )
        }
      }
      let create = createService
      let label = `${camelToCapitalized(type)}s`
      if (type === 'system') {
        create = null;
        label = "System Services"
      }
      return {
        key: type,
        menuItem: label,
        render: () => (
          <div className="SettingsTabContents">
          <ServiceTable type={type}
            services={services[type]}
            serviceDisplayOrder={services.serviceDisplayOrder}
            createService={create}
            handleSettings={this.handleSettingsPage}
            handleStart={this.handleStartService}
            handleStop={this.handleStopService}
            handleRestart={this.handleRestartService}
            handleDelete={this.handleDeleteService}
            handleRename={this.handleRenameService}
            handleQueuePage={this.handleQueuePage}
            handleControlsPage={this.handleControlsPage}/>
          </div>
        )
      }
    })

    let moreItem1 = ''

    let sysstat = getStatus('system_status')
    if (sysstat) {
        if (sysstat.redirect) {
            if (sysstat.redirect !== '/quickroll5') {
                moreItem1 = <><Dropdown.Divider /><Dropdown.Item onClick={makeV5default}>Make this V5 interface default</Dropdown.Item></>
            }
        }
    }

    return (
      <div id="ServicePage">
        <Header as='h2' id='SettingsHeader' content='Services' icon={
          <Icon className='svg-icon'>
            <Image src={staticAsset("/icons/settings_black.svg")}/>
          </Icon>
        }/>
        <Divider fitted/>
        <div>
        <Dropdown text="Diagnostics" icon='stethoscope' labeled button floating className='icon' id='SettingsDiagnostics'>
          <Dropdown.Menu>
            <Dropdown.Item onClick={function(){doSupportCommand('start')}}>
              Start support connection
            </Dropdown.Item>
            <Dropdown.Item onClick={function(){doSupportCommand('stop')}}>
              Stop support connection
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={resetPlaylistScheduleItems}>
              Reset All Playlists/Schedules
            </Dropdown.Item>
            <Dropdown.Item onClick={resetMediaItems}>
              Reset All Media Items
            </Dropdown.Item>{moreItem1}
          </Dropdown.Menu>
        </Dropdown>
        </div>
        <Tab id='ServicesList'
          panes={tabPanes}
          activeIndex={activeIndex}
          onTabChange={this.handleTabChange}/>
      </div>
    )
  }

}
