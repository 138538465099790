import {getIn, setIn} from './general_helpers'
import {camelToCapitalized} from 'helpers/general_helpers'
import deepmerge from 'deepmerge'

/**
 * Determines which set of properties should be used when handling oneOf cases
 * in the schema
 * @param {array} oneOf The array of property sets to check
 * @param {object} values The known current property values
 */
export const handleOneOf = (oneOf, values) => {

  let determinators = []
  oneOf.forEach((set, index) => {
    if(index === 0) {
      determinators = Object.entries(set.properties).map(([key, value]) => {
        let val = value.enum || value.const || value.value
        if(!(val instanceof Array)) {
          val = [val]
        }
        return [key, val]
      })
    } else {
      let setKeys = Object.keys(set.properties)
      determinators = determinators.filter(([key, value]) => setKeys.includes(key))
      determinators.forEach(([key, value], index) => {
        if(set.properties[key]) {
          let setValue = set.properties[key].enum || set.properties[key].const
          determinators[index][1] = value.concat(setValue)
        }
      })
    }
  })
  if(determinators.length === 0) {
    throw new Error(`There are no keys that can be used to determine which property set should be used in this oneOf case: \n${JSON.stringify(oneOf, null, 2)}`)
  }

  let toReturn = oneOf.find((set) => {
    let matches = true;
    for(let [determinator] of determinators) {
      if(!set.properties[determinator] ||
        (set.properties[determinator].enum ?
          !set.properties[determinator].enum.includes(values[determinator]) :
          set.properties[determinator].const !== values[determinator])) {
        matches = false;
        break;
      }
    }
    return matches;
  })

  determinators.forEach(([key, value]) => {
    toReturn = setIn(toReturn, ['properties', key, 'enum'], value)
  })
  return toReturn

}

/**
 * Combines all sets within an allOf set in the json schema
 * @param {array} allOf The array of property sets to combine
 */
export const handleAllOf = (allOf) => {
  return deepmerge.all(allOf);
}

/**
 * Recursively replaces refs in the json schema with the appropriate entries from the references section,
 *  as well as merging allOf sections
 * @param {object} current The current section of the schema being acted upon
 * @param {object} schema The whole json schema to use as a lookup for the references. If null,
 *  will assume top level and use current as schema
 */
export const parseSchema = (current, schema=null) => {
  if(schema === null) {
    schema = current;
  }
  if(typeof current !== "object" || current === null) {
    return current;
  } else if(current instanceof Array) {
    return current.map((element) => {
      return parseSchema(element, schema);
    })
  } else {
    let replace = {}
    Object.entries(current).forEach(([key, value]) => {
      if(key === "$ref") {
        let address = value.split('/');
        let lookup = null;
        if(address[0] === "#") {
          lookup = schema;
          address.shift();
        } else {
          lookup = current;
        }
        let replaceAddress = getIn(lookup, address)
        replace = {
          ...replace,
          ...parseSchema(replaceAddress, schema)
        };
        if(value.endsWith("path")) {
          if(replace.type && replace.type instanceof Array) {
            replace.type = replace.type.map((type) => {
              if(type === "array") {
                return "path"
              }
              return type
            })
          } else {
            replace.type = "path"
          }
        }
      } else if(key === "$comment" && value === "Status value") {
        replace.readOnly = true;
      } else if(typeof value === "object") {
        let newVal = parseSchema(value, schema);
        if(key === "allOf" && newVal instanceof Array) {
          newVal = handleAllOf(newVal);
          replace = deepmerge(replace, newVal);
        } else {
          replace[key] = newVal;
        }
      } else {
        replace[key] = value;
      }
    })
    return replace;
  }
}

/**
 * Gets a placeholder value for a given type. Primarily used for coercing null values into non-null values
 * @param {string} type The type to get the value for. Accepts object, array, boolean, string, number, integer, or path
 * @returns A default empty/zero value for the given type. If the type given is not an accepted type, null is returned instead.
 */
export const typeDefault = (type) => {
  switch(type) {
    case 'object':
      return {}
    case 'array':
    case 'path':
      return []
    case 'boolean':
      return false
    case 'string':
      return ''
    case 'number':
    case 'integer':
      return 0
    default:
      return null
  }
}

export const copySettings = (settings, schema) => {
  let lines = serializeSettingsToText(settings, schema)
  let display = [
    '// Changed Properties',
    ...(lines.changed.sort((a, b) => a.localeCompare(b))),
    '',
    '// Default Properties',
    ...(lines.unchanged.sort((a, b) => a.localeCompare(b))),
  ]
  return display.join("\n")
}

/**
 * Recursively serializes settings into a text format to be copy/pasted
 * @param {object} settings The current settings of the service
 * @param {object} schema The schema of the service
 * @param {array} address The address of parent settings, to be used internally only
 * @returns An object with key "changed" having an array of string lines of format addressPartA/addressPartB/.../key=value
 *  representing the non-default settings, and key "unchanged" having a similar array representing the default settings.
 */
export const serializeSettingsToText = (settings, schema, address=[]) => {
  let changedLines = []
  let defaultLines = []
  if(schema && settings) {
    Object.entries(schema).forEach(([key, val]) => {
      if(val.readOnly) {
        return
      }
      if(val.oneOf) {
        val = handleOneOf(val.oneOf, settings[key])
      }
      if(val.type === 'object') {
        let {changed, unchanged} = serializeSettingsToText(settings[key], val.properties, [...address, key])
        changedLines = changedLines.concat(changed)
        defaultLines = defaultLines.concat(unchanged)
      } else if(val.type === 'array') {
        if(key in settings) {
          settings[key].forEach((item, ind) => {
            let {changed, unchanged} = serializeSettingsToText(item, val.items.properties, [...address, key, ind])
            changedLines = changedLines.concat(changed)
            defaultLines = defaultLines.concat(unchanged)
          })
        } else {
          let def = val['default'] || ''
          if(def instanceof Array) {
            def = `[${def.join(',')}]`
          }
          defaultLines.push(`${[...address, key].join('/')}=${def}`)
        }
      } else {
        let def = val['default'] || ''
        if(def instanceof Array) {
          def = `[${def.join(',')}]`
        }
        let value = settings[key] || ''
        if(value instanceof Array) {
          value = `${value.join(',')}`
          if(value) {
            value = `[${value}]`
          }
        }
        if(value === def || !value) {
          defaultLines.push(`${[...address, key].join('/')}=${def}`)
        } else {
          changedLines.push(`${[...address, key].join('/')}=${value}`)
        }
      }
    })
  }
  return {changed: changedLines, unchanged: defaultLines}
}

export const parseTextToSettings = (text) => {
  let toReturn = {}
  text.split("\n").forEach((line) => {
    if(!line || line.startsWith('//')) {
      return
    }
    let [key, value] = line.split('=', 2)
    let address = key.split('/').map((level) => {
      if(/^\d+$/.exec(level)) {
        return parseInt(level, 10)
      }
      return level
    })
    if(/\[.*\]/.exec(value)) {
      value = value.slice(1, -1).split(',')
    }
    toReturn = setIn(toReturn, address, value)
  })
  return toReturn
}

export const matchesSearchString = (setting, schema, searchString) => {
  searchString = searchString.toLowerCase()
  let search = searchString.match(/(?:"[^"]+"|[^ ]+)/g)
  if(schema.type === 'object' ||
      (schema.type instanceof Array &&
      schema.type.includes('object'))) {
    for(let [name, val] of Object.entries(schema.properties)) {
      if(matchesSearchString(`${setting}, ${name}`, val, searchString)) {
        return true
      }
    }
  } else if(schema.type === 'array' ||
      (schema.type instanceof Array &&
      schema.type.includes('array'))) {
    if(matchesSearchString(`${setting}, item`, schema.items, searchString)) {
      return true
    }
  }
  for(let str of search) {
    if(str.startsWith("\"") && str.endsWith("\"")) {
      str = str.slice(1)
      str = str.slice(-1)
    }
    if(!(camelToCapitalized(setting).toLowerCase().includes(str) ||
    (schema.description && schema.description.toLowerCase().includes(str)))) {
      return false
    }
  }
  return true
}
