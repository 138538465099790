import undoable, {includeAction} from 'redux-undo'
import {getIn, setIn} from 'helpers/general_helpers'
import {fetchFromServer} from 'helpers/net_helpers'
import {getFrameListFromSettings,
  getSettingsListFromFrames,
  getThumbInfo,
  regionAddress} from 'helpers/global_media_helpers'
import {createFrame,
  removeFrame} from 'helpers/frame_helpers'
import {actions as SettingsActions} from 'redux/settings'
import LibraryManager from 'redux/higher_order_reducers/libraryManager'

import SettingsSelector from 'selectors/SettingsSelector'
import SchemaSelector from 'selectors/SchemaSelector'

export const LOAD_GLOBAL_FRAMES = Symbol('load global frames')
export const CLEAR_GLOBAL_FRAMES = Symbol('clear global frames')
export const MOVE_GLOBAL_FRAME = Symbol('move global frame')
export const RESIZE_GLOBAL_FRAME = Symbol('resize global frame')
export const RENAME_GLOBAL_FRAME = Symbol('rename global frame')
export const ADD_GLOBAL_FRAME = Symbol('add global frame')
export const REMOVE_GLOBAL_FRAME = Symbol('remove global frame')
export const REORDER_GLOBAL_FRAME = Symbol('reorder global frame')
export const ASSIGN_GLOBAL_FRAME_ITEM = Symbol('assign global frame item')
export const CHANGE_GLOBAL_FRAME_OPTIONS = Symbol('change global frame options')
export const GLOBAL_MEDIA_FILE_SELECT = Symbol('global media file select')
export const SET_GLOBAL_MEDIA_SNAP_TO_GRID = Symbol('set global media snap to grid')

export const SET_IS_FETCHING_GLOBAL = Symbol('set is fetching global')
export const SET_THUMBNAIL_BUFFER = Symbol('set thumbnail buffer')
export const SET_DISPLAY = Symbol('set display')

export const GLOBAL_MEDIA_UNDO = Symbol('global media undo')
export const GLOBAL_MEDIA_REDO = Symbol('global media redo')

const GlobalMediaLibraryManager = new LibraryManager()

/**
 * Helper function for fetching the thumbnail of a region. Returns a promise
 * that resolves with either the response from the server, or nothing if a server request
 * is not made (such as if the region contains no item). Dispatches actions as appropriate.
 * @param {function} dispatch The dispatch function from the redux store. Used for handling
 *  the retrieved thumbnail
 * @param {object} region The settings object of the region to fetch the thumbnail for, from the settings
 * @param {number} id The index of the region within the frame editor's frames
 * @param {array} aspectRatio The aspect ratio of the channel being edited, as [<width>, <height>]
 * @param {object} frame Optional. The frame of the region in the global media editor, if it has one. (used for
 *  things like scrolling text where the size of the frame matters.)
 */
async function fetchRegionThumbnail(dispatch, region, id, aspectRatio, size, signal, frame, queueObject) {
  let {path, frameHeight, frameWidth, context, played} = getThumbInfo(region)
  let altOverride = false

  if(!played && played !== 0) {
    // Magic value from Jonathan's code
    played = `${(Date.now() / 1000) % (12 * 31 * 24 * 60 * 60)}`
  }
  if(frame) {
    if(frame.isMainRegion && queueObject) {
      if (queueObject.currentAlt) {
        if (queueObject.currentAlt.item) {
          path = queueObject.currentAlt.item.join('/')
          played = queueObject.currentAlt.played
          context = queueObject.currentAlt['context id']
          if (context === "-1") context = undefined;
          altOverride = true
        }
      }
    }
    if(!altOverride) { // unless overriden by alt play now item, do not fetch anything if region is disabled
      if (frame.options.enabled < 0) {
        // Do not fetch thumbnails if region is disabled
        dispatch(actions.setThumbnailBuffer(id, null))
        return
      }
    }
    if(frame.size) {
      frameHeight = frame.size.height
      frameWidth = frame.size.width
    }
    if (!altOverride) {
      let framePath = getIn(frame, ["timeline", 0, "item"])
      if(framePath) {
        framePath = framePath.join('/')
        if(framePath && framePath !== path) {
          path = framePath
          context = null
        }
      }
    }
  }
  if(!path) {
    dispatch(actions.setThumbnailBuffer(id, null))
    return
  }
  let height, width;
  if(size) {
    height = size.height
    width = size.width
  } else {
    height = 240
    width = Math.floor(height / aspectRatio[1]) * aspectRatio[0]
  }
  let w = `&w=${Math.floor(((frameWidth * width) / 100)+0.5)}`
  let h = `&h=${Math.floor(((frameHeight * height) / 100)+0.5)}`
  if(context) {
    context = `&context=${context}`
  } else {
    context = ''
  }
  path = path.split("/").map(part => encodeURIComponent(part)).join("/")
  let res = await fetchFromServer(`/v2/files/grab-thumbnail${path}?timeout=2000${context}&t=${played}${w}${h}`, {signal})
  if(!res.ok) {
    let errMsg = await res.text()
    console.error(`Fetch of thumbnail for region with id number ${id} failed with status ${res.status}: ${errMsg}`)
    return
  }
  let thumb = await res.blob()
  if(thumb.size === 0) {
    dispatch(actions.setThumbnailBuffer(id, null))
    return
  }
  dispatch(actions.setThumbnailBuffer(id, thumb))
  return
}

export const actions = {

  /**
   * Loads the global region data from a channel
   * @param {number} channel The number of the channel to load data from
   */
  loadGlobalFrames: (channel) => {
    return async (dispatch, getState) => {
      let settings = SettingsSelector({...getState().settings, type: 'channel', id: channel})
      let targetSchema = SchemaSelector({...getState().settings, type: 'channel', id: channel})
      let regions = getIn(settings, ['regions'])
      let schema = getIn(targetSchema, ['regions', 'properties'])
      let frames = []
      if(!schema) {
        await new Promise((resolve, reject) => {
          let timeout = 5000
          let schemaCheck = setInterval(() => {
            let target = {
              settings: SettingsSelector({...getState().settings, type: 'channel', id: channel}),
              targetSchema: SchemaSelector({...getState().settings, type: 'channel', id: channel})
            }
            regions = getIn(target.settings, ['regions'])
            schema = getIn(target.targetSchema, ['regions', 'properties'])
            if(schema && regions) {
              clearInterval(schemaCheck)
              resolve()
            } else {
              timeout -= 250
              if(timeout <= 0) {
                clearInterval(schemaCheck)
                reject("Timeout of 5000ms waiting for schema exceeded.")
              }
            }
          }, 250)
        })
      }
      if(schema) {
        let main = getIn(settings, ['regions', 'main'])
        frames = getFrameListFromSettings(regions, schema, main)
      }
      dispatch({
        type: LOAD_GLOBAL_FRAMES,
        payload: {
          data: frames,
          aspectRatio: [16, 9]
        }
      })
    }
  },

  clearGlobalFrames: () => ({
    type: CLEAR_GLOBAL_FRAMES
  }),

  saveGlobalFrames: () => {
    return (dispatch, getState) => {
      let channelID = getState().channel.active_channel
      let settingsList = getSettingsListFromFrames(getState().global_media.present.frames);
      let regionSettings = {}
      settingsList.forEach((setting) => {
        regionSettings = setIn(regionSettings, ['settings', ...setting.key], setting.value)
      })

      let itemRegion = getIn(regionSettings, ['settings', 'regions', 'item'])
      if (itemRegion) {
        if(!getIn(regionSettings, ['settings', 'regions', 'item'])) {
          regionSettings = setIn(regionSettings, ['settings', 'regions', 'item'], {})
        }
      }

      let globalRegions = getIn(regionSettings, ['settings', 'regions', 'global'])
      if (globalRegions) {
        for(let i = 0; i < globalRegions.length; i++) {
          if(!getIn(regionSettings, ['settings', 'regions', 'global', i])) {
            regionSettings = setIn(regionSettings, ['settings', 'regions', 'global', i], {})
          }
        }
      }
      dispatch(SettingsActions.saveServiceSettings('channel', channelID, regionSettings))
    }
  },

  /**
   * Fetches the thumbnails for the global region editor from the server,
   *  based on the current channel settings
   */
  fetchGlobalThumbnails: (frames) => {
    return async (dispatch, getState) => {
      let {isFetching, aspectRatio, abortController} = getState().global_media.present
      if(isFetching) {
        return
      }
      let signal = abortController.signal
      dispatch(actions.setIsFetchingGlobal(true))
      try {
        let channelID = getState().channel.active_channel
        let settings = SettingsSelector({...getState().settings, type: 'channel', id: channelID})
        let queueObject = settings ? settings.queue : {} // NTS: It is entirely possible to call this function before the channel is loaded
        let regions = getIn(settings, ['regions'])
        let size = getIn(settings, ['video', 'size'])
        if (size) {
          /* size is a string i.e. "720x480" */
          let t = size.split('x')
          if (t.length >= 2) size = {width:parseInt(t[0]),height:parseInt(t[1])}
        }
        let fetches = frames.map((frame, index) => {
          let address = regionAddress(index)
          let region = getIn(regions, address)
          return fetchRegionThumbnail(dispatch, region, index, aspectRatio, size, signal, frame, queueObject)
        })
        await Promise.all(fetches)
        dispatch(actions.setDisplay())
      } catch (err) {
        if(err.name !== "AbortError") {
          console.error(err)
        }
      }
      dispatch(actions.setIsFetchingGlobal(false))
    }
  },

  setIsFetchingGlobal: (value) => ({
    type: SET_IS_FETCHING_GLOBAL,
    payload: value
  }),

  setThumbnailBuffer: (index, thumb) => ({
    type: SET_THUMBNAIL_BUFFER,
    payload: {
      index,
      thumb
    }
  }),

  setDisplay: () => ({type: SET_DISPLAY}),

  /**
   * Adds a new frame to the end of the list of frames.
   * @param {object} frame Optional object containing any parameters to set on the newly created frame.
   *  Any parameters that are not specified will be given default values.
   */
  addGlobalFrame: (frame={}) => ({
    type: ADD_GLOBAL_FRAME,
    payload: frame
  }),

  /**
   * Removes a frame from the list of frames.
   * @param {number} index The index of the frame to be removed within the list of frames
   */
  removeGlobalFrame: (index) => ({
    type: REMOVE_GLOBAL_FRAME,
    payload: index
  }),

  /**
   * Changes a frame's position
   * @param {number} index The index of the frame to act on
   * @param {number} x The new x coordinate of the frame, relative to the upper-left corner of the frame editor
   * @param {number} y The new y coordinate of the frame, relative to the upper-left corner of the frame editor
   */
  moveGlobalFrame: (index, x, y) => ({
    type: MOVE_GLOBAL_FRAME,
    payload: {
      index,
      x,
      y
    }
  }),

  /**
   * Changes a frame's name
   * @param {number} index The index of the frame to rename
   * @param {string} newName The new name of the frame
   */
  renameGlobalFrame: (index, newName) => ({
    type: RENAME_GLOBAL_FRAME,
    payload: {
      index,
      newName
    }
  }),

  /**
   * Changes a frame's size
   * @param {number} index The index of the frame to act on
   * @param {number} width The new width of the frame in percent of screen size
   * @param {number} height The new height of the frame in percent of screen size
   */
  resizeGlobalFrame: (index, width, height) => ({
    type: RESIZE_GLOBAL_FRAME,
    payload: {
      index,
      width,
      height
    }
  }),

  /**
   * Changes a frame's position in the frame list
   * @param {number} index The current index of the frame to move
   * @param {number} new_index The index to move the frame to
   */
  reorderGlobalFrame: (index, new_index) => ({
    type: REORDER_GLOBAL_FRAME,
    payload: {
      index,
      new_index
    }
  }),

  /**
   * Assigns the item to play on a given frame
   * @param {number} index The index of the frame to assign an item to
   * @param {string} item The string identifier of the item to be played (usually path of the item)
   */
  assignGlobalFrameItem: (index, item) => ({
    type: ASSIGN_GLOBAL_FRAME_ITEM,
    payload: {
      index,
      item
    }
  }),

  /**
   * Changes a frame's options by merging in given changes
   * @param {number} index The numeric index of the frame to change the options of
   * @param {object} options An object containing the options to be passed in
   * @param {array} delete_keys An array of keys to be deleted from the frame's options
   */
  changeGlobalFrameOptions: (index, options={}, delete_keys=[]) => ({
    type: CHANGE_GLOBAL_FRAME_OPTIONS,
    payload: {
      index,
      options,
      delete_keys
    }
  }),

  /**
   * Changes the currently selected library file
   * @param {string} item The path of the file to select
   */
  globalMediaFileSelect: (item) => ({
    type: GLOBAL_MEDIA_FILE_SELECT,
    payload: item
  }),

  /**
   * Sets whether frames should snap to grid in the global media frame editor or not
   * @param {bool} value The new value for snapToGrid
   */
  setGlobalMediaSnapToGrid: (value) => ({
    type: SET_GLOBAL_MEDIA_SNAP_TO_GRID,
    payload: value
  }),

  globalMediaUndo: () => ({type: GLOBAL_MEDIA_UNDO}),
  globalMediaRedo: () => ({type: GLOBAL_MEDIA_REDO}),

  libraryUpdate: GlobalMediaLibraryManager.libraryUpdate

}

export const initialState = {
  /**
   * Array of frame objects, in order of z-index
   * Frame objects have the following properties:
   * {object} position Object containing x/y position
   * {number} position.x horizontal position of frame's upper left corner
   * {number} position.y vertical position of frame's upper left corner
   * {object} size Object containing height/width values
   * {number} size.width frame width in percent of screen size
   * {number} size.height frame height in percent of screen size
   * {string} name The name of the frame
   * {object} options Any options attached to the frame by the frame editor or its parent
   * {string} display A string indicating what is to be displayed in this frame
   * {array} timeline An array of clip objects that occur within this frame
   * {object} timeline[#] A single clip object is defined with the following properties
   * {string} timeline[#].item A string indicating what item is occuring (usually rpath of item)
   * {number} timeline[#].start The start time of the item in milliseconds
   * {number} timeline[#].in The time into the item that the clip should start
   * {number} timeline[#].end The end time of the item in milliseconds
   */
  // NOTE: For internal use only, the regions are indexed as follows:
  // 0: The item region
  // 1-8: The eight global regions
  // 9: The urget region
  // 10: The EAS A/V region
  // 11: The EAS Text region
  frames: [],
  thumbnailBuffer: [],
  isFetching: false,                      // Whether global region thumbnails are currently being fetched or not
  snapToGrid: false,                      // Whether frames should snap to a grid when resizing/moving or not
  aspectRatio: [16, 9],                   // Aspect ratio of edited channel. Format is [width, height]
  globalMediaSelectedFile: null,          // Which file in the global media app's library is selected
  globalFrameLimit: 8,                    // The number of global regions allowed per channel
  abortController: new AbortController()  // Abort controller for frame thumbnail requests
}

const reducer = (state=initialState, action) => {
  let {type, payload} = action
  switch(type) {
    case LOAD_GLOBAL_FRAMES: {
      let {data, aspectRatio, limit} = payload
      // Lock Main Region by default
      data = data.map((region) => {
        if(region) {
          let locked = false
          if(region.options && region.options.main) {
            locked = true
          }
          return {
            ...region,
            display: null,
            options: {
              ...region.options,
              locked
            }
          }
        } else {
          return null
        }
      })
      let {thumbnailBuffer} = state
      Object.values(thumbnailBuffer).forEach((section) => {
        if(section instanceof Array) {
          section.forEach((thumbnail) => {
            if(thumbnail) {
              URL.revokeObjectURL(thumbnail)
            }
          })
        } else {
          URL.revokeObjectURL(section)
        }
      })
      return {
        ...state,
        frames: data,
        aspectRatio: aspectRatio,
        globalFrameLimit: limit ? limit : 8,
        thumbnailBuffer: []
      }
    }
    case CLEAR_GLOBAL_FRAMES: {
      let {frames, thumbnailBuffer, abortController} = state
      abortController.abort()
      thumbnailBuffer.forEach((thumb) => {
        if(thumb) {
          URL.revokeObjectURL(thumb)
        }
      })
      frames = frames.map((frame) => ({
        display: null
      }))
      return {
        ...state,
        frames,
        thumbnailBuffer: [],
        abortController: new AbortController()
      }
    }
    case ADD_GLOBAL_FRAME: {
      let frames = state.frames
      if(frames.length < state.globalFrameLimit) {
        frames = createFrame(frames, payload)
      }
      return {
        ...state,
        frames
      }
    }
    case REMOVE_GLOBAL_FRAME: {
      return {
        ...state,
        frames: removeFrame(state.frames, payload)
      }
    }
    case MOVE_GLOBAL_FRAME: {
      let {index, x, y} = payload
      let newFramesList = setIn(state.frames, [index, 'position'], {x, y})
      return {
        ...state,
        frames: newFramesList
      }
    }
    case RENAME_GLOBAL_FRAME: {
      let {index, newName} = payload
      let newFramesList = setIn(state.frames, [index, 'name'], newName)
      return {
        ...state,
        frames: newFramesList
      }
    }
    case RESIZE_GLOBAL_FRAME: {
      let {index, width, height} = payload
      let newFramesList = setIn(state.frames, [index, 'size'], {width, height})
      return {
        ...state,
        frames: newFramesList
      }
    }
    case REORDER_GLOBAL_FRAME: {
      let {index, new_index} = payload
      let newFramesList = [...state.frames]
      let reorderFrame = newFramesList.splice(index, 1)
      if(reorderFrame.length > 0) {
        newFramesList.splice(new_index, 0, ...reorderFrame)
      }
      return {
        ...state,
        frames: newFramesList
      }
    }
    case ASSIGN_GLOBAL_FRAME_ITEM: {
      let {index, item} = payload
      let newFramesList = setIn(state.frames, [index, 'timeline'], [{item}])
      return {
        ...state,
        frames: newFramesList
      }
    }
    case CHANGE_GLOBAL_FRAME_OPTIONS: {
      let {index, options, delete_keys} = payload
      let frameOptions = getIn(state.frames, [index, 'options']) || {}
      let newOptions = Object.assign({}, frameOptions, options)
      if(delete_keys) {
        delete_keys.forEach((key) => {
          if(newOptions[key]) {
            delete newOptions[key]
          }
        })
      }
      let newFramesList = setIn(state.frames, [index, 'options'], newOptions)
      return {
        ...state,
        frames: newFramesList
      }
    }
    case GLOBAL_MEDIA_FILE_SELECT: {
      return {
        ...state,
        globalMediaSelectedFile: payload
      }
    }
    case SET_GLOBAL_MEDIA_SNAP_TO_GRID: {
      return {
        ...state,
        snapToGrid: payload
      }
    }
    case SET_THUMBNAIL_BUFFER: {
      let {index, thumb} = payload
      if(getIn(state.thumbnailBuffer, [index])) {
        URL.revokeObjectURL(state.thumbnailBuffer[index])
      }
      if(thumb) {
        thumb = URL.createObjectURL(thumb)
      }
      return {
        ...state,
        thumbnailBuffer: setIn(state.thumbnailBuffer, [index], thumb)
      }
    }
    case SET_DISPLAY: {
      let {frames} = state
      state.thumbnailBuffer.forEach((thumbnail, index) => {
        if(getIn(frames, [index, 'display'])) {
          URL.revokeObjectURL(getIn(frames, [index, 'display']))
        }
        frames = setIn(frames, [index, 'display'], thumbnail)
      })
      return {
        ...state,
        frames,
      }
    }
    case SET_IS_FETCHING_GLOBAL: {
      return {
        ...state,
        isFetching: payload
      }
    }
    default:
      return state;
  }
}

const undoReducer = undoable(reducer, {
  limit: 25,
  undoType: GLOBAL_MEDIA_UNDO,
  redoType: GLOBAL_MEDIA_REDO,
  filter: includeAction([
     ADD_GLOBAL_FRAME,
     REMOVE_GLOBAL_FRAME,
     MOVE_GLOBAL_FRAME,
     RENAME_GLOBAL_FRAME,
     RESIZE_GLOBAL_FRAME,
     REORDER_GLOBAL_FRAME,
     ASSIGN_GLOBAL_FRAME_ITEM,
  ])
})

export default GlobalMediaLibraryManager.wrap(undoReducer, {present: initialState, past: [], future: []})
