import React, {PureComponent} from 'react'
import {Segment, Label, Container, Icon, Dropdown, Popup, List} from 'semantic-ui-react'

export default class ScheduleDefaultItem extends PureComponent {

  render() {
    let {item,
      type,
      label,
      isPlayingOn,
      hasSelectedFiles,
      setDefaultItem,
      removeDefaultItem,
      navigateToFile} = this.props

    let itemName = item ? item.label : '(none)'
    let removeButton = item ?
      (<Icon name='remove'
        color='red'
        className='scheduleDefaultsRemoveIcon'
        onClick={(e) => {e.stopPropagation(); removeDefaultItem(type)}}/>) :
      ''

    let color;
    let playingIcon = null;

    if(isPlayingOn && isPlayingOn.length) {
      let playingOn = isPlayingOn.map(([key, val]) => val.name)
      color = "green"
      playingIcon = (<Popup header='Currently Playing on:'
          content={<List items={playingOn}/>}
          trigger={<Icon title='Now Playing' name='play'/>}/>)
    }

    return (
      <Segment color={color}>
        <Label attached='top' color={color}>
          {playingIcon}
          <Dropdown text={label}>
            <Dropdown.Menu>
              <Dropdown.Item disabled={!hasSelectedFiles}
                onClick={() => setDefaultItem(type)}
                icon="add"
                text="Set Default"/>
              <Dropdown.Item disabled={!item}
                onClick={() => {removeDefaultItem(type)}}
                icon="remove"
                text="Remove Default"/>
              <Dropdown.Item disabled={!item || !("originalItem" in item)}
                onClick={() => {navigateToFile(item.originalItem)}}
                icon="search"
                text="Locate File"/>
            </Dropdown.Menu>
          </Dropdown>
        </Label>
        <Container className='scheduleDefaultsTextContainer' onClick={() => setDefaultItem(type)}>
          <span className='scheduleDefaultsText' title={itemName}>
            {itemName}
          </span>
          {removeButton}
        </Container>
      </Segment>
    )
  }

}
