import {serverPath, fetchFromServer} from 'helpers/net_helpers'
import {bakeCookie, TAKE_COOKIE} from 'redux/cookies'
import history from 'history.js'
import {messages} from 'redux/messages'
import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'
import {setIn} from 'helpers/general_helpers'

export const AUTHENTICATE_USER = Symbol('authenticate user')
export const AUTHENTICATION_ERROR = Symbol('authentication error')
export const START_AUTHENTICATION = Symbol('start authentication')
export const REDIRECT_PATH = Symbol('redirect path')
export const SET_INTERFACE_CONFIG = Symbol('set interface config')

/*
const TEST_FORM = {
  title: "Playback Request Form",
  fields: [
    {type: "text", name: "Title", required: true},
    {type: "text", name: "Episode", required: true},
    {type: "group", name: "Group3", fields: [
      {type: "text", name: "Cue Time"},
      {type: "text", name: "Exact Run Time"},
    ]},
    {type: "group", name: "Group2", fields: [
      {type: "select", name: "File Format", options: [
        {key: "mpeg4", text: "MPEG4", value: "mpeg4"},
        {key: "mov", text: "MOV", value: "mov"},
        {key: "mpeg2", text: "MPEG2", value: "mpeg2"},
        {key: "avi", text: "AVI", value: "avi"},
      ]},
      {type: "select", name: "Produced", options: [
        {key: "at ac/sac", text: "at Ac/Sac", value: "at ac/sac"},
        {key: "locally", text: "Locally", value: "locally"},
        {key: "non-locally", text: "Non-Locally", value: "non-locally"},
      ]},
      {type: "select", name: "Program Owner", options: [
        {key: "ac/sac", text: "Ac/Sac", value: "ac/sac"},
        {key: "producer/provider", text: "Producer/Provider", value: "producer/provider"},
      ]},
    ]},
    {type: "radio", name: "Has this show ever played on Ac/Sac before?", options: ["yes", "no"]},
    {type: "text", name: "Preferred Date/Time"},
    {type: "textarea", name: "Description"},
    {type: "text", name: "Viewer Contact"},
    {type: "group", name: "Group4", fields: [
      {type: "checkbox", name: "Indecent Content"},
      {type: "checkbox", name: "Adult Content"},
    ]},
    {type: "radio", name: "O.K. to schedule repeats?", options: ["yes", "no"]},
    {type: "group", name: "Group5", fields: [
      {type: "select", name: "Appropriate to repeat for:", options: [
          {key: "1 mo.", text: "1 mo.", value: "1 mo."},
          {key: "2 mo.", text: "2 mo.", value: "2 mo."},
          {key: "3 mo.", text: "3 mo.", value: "3 mo."},
          {key: "indefinitely", text: "Indefinitely", value: "indefinitely"},
      ]},
      {type: "text", name: "Kill Date"},
    ]},
    {type: "select_multiple", name: "Audience", options: [
      "General", "Asian", "Black", "Blind", "Children", "Deaf", "Gays/Lesb.", "Hispanic", "Men", "Nat. Amer.", "Disabled", "Org. Labor", "Parents", "Seniors", "Teens", "Veterans", "White", "Women"
    ]},
    {type: "select_multiple", name: "Language", options: [
      "English", "Spanish", "ASL", "Chinese", "Farsi", "French", "Hindi", "Hmong", "Japanese", "Lao", "Mienh", "Punjabi", "Romanian", "Russian", "Samoan", "Tongan", "Vietnamese", "Other"
    ]},
    {type: "select_multiple", name: "Subject", options: [
      "Arts", "Business", "Comedy", "Cultural", "Drama", "Education", "Government", "Health", "History", "Local News", "Hobbies", "Holidays", "Music", "New Age", "News", "Pgm Promo", "Public Aff", "PSA", "Religious", "Science", "Sports", "Station ID", "Vignette", "Other"
    ]},
    {type: "group", name: "Group6", fields: [
      {type: "select", name: "Your Age", options: [
        {key: "<18", text: "<18", value: "<18"},
        {key: "18-24", text: "18-24", value: "18-24"},
        {key: "25-34", text: "25-34", value: "25-34"},
        {key: "35-44", text: "35-44", value: "35-44"},
        {key: "45-64", text: "45-64", value: "45-64"},
        {key: "65-84", text: "65-84", value: "65-84"},
        {key: ">85", text: ">85", value: ">85"},
        {key: "did not state", text: "Did not state", value: "did not state"},
      ]},
      {type: "select", name: "Your Ethnicity", options: [
        {key: "White", text: "White", value: "White"},
        {key: "Black", text: "Black", value: "Black"},
        {key: "Asian", text: "Asian", value: "Asian"},
        {key: "Hispanic", text: "Hispanic", value: "Hispanic"},
        {key: "Native American", text: "Native American", value: "Native American"},
        {key: "Other", text: "Other", value: "Other"},
        {key: "did not state", text: "Did not state", value: "did not state"},
      ]},
      {type: "select_multiple", name: "Are you Physically Challenged?", options: [
        "Blind", "Deaf", "Other physical disability", "Other"
      ]},
    ]},
    {type: "group", name: "Group7", fields: [
      {type: "text", name: "Program Provider", placeholder: "First Name Last Name"},
      {type: "text", name: "Phone"}
    ]},
    {type: "group", name: "Group1", fields: [
      {type: "text", name: "Address"},
      {type: "text", name: "State"}
    ]},
    {type: "group", name: "Group8", fields: [
      {type: "text", name: "City"},
      {type: "text", name: "Zip Code"}
    ]},
    {type: "checkbox", name: "Agreed to Terms", label: "I hereby agree not to hold Access Sacramento responsible for any and all damage, loss, theft of this media (program), or any media submitted for playback.  I have read, am thoroughly familiar with, and agree to comply with “Part III, Programming” section of the current Operating Rules and Procedures.  I have signed the required “Statement of Compliance” regarding the Access Sacramento Operating Rules and Procedures.  Under penalty of perjury, I affirm all the information I provided on this form is correct.", required: true},
  ]
}
*/
export const authenticateUser = (user, pass) => {
  return async (dispatch, getState) => {
    dispatch({type: START_AUTHENTICATION})
    let jobId = dispatch(loading.startLoading(getState().server._loaderID))
    let res = await fetch(serverPath("/v2/login"), {
      method: 'POST',
      body: JSON.stringify({username: user, password: pass}),
      headers: {
        'Content-Type': 'application/json',
        'Accepts': 'application/json'
      }
    });
    dispatch(loading.finishLoading(getState().server._loaderID, jobId))
    if(!res.ok) {
      if(res.status === 403) {
        dispatch(authenticationError('Username or password was incorrect.'))
        throw new Error('Username or password was incorrect.')
      } else {
        let text = await res.text();
        text = text || 'Unknown Error'
        let message = `An error occured while attempting to log in: ${text}`
        dispatch(authenticationError(message))
        throw new Error(`An error occured while attempting to log in: ${text}`)
      }
    } else {
      let info = await res.json();
      window.AARDVARK_ACCESS = info.token;
      dispatch(bakeCookie('token', info.token))
      return dispatch({
        type: AUTHENTICATE_USER,
        payload: info
      })
    }
  }
}

export const authenticateToken = (token) => {
  return async (dispatch, getState) => {
    let res = await fetch(serverPath("/v2/refresh"), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accepts': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
    if(!res.ok) {
      if(res.status === 403) {
        dispatch(authenticationError("Authentication token was invalid or expired. Please log in again."))
        throw new Error('Token was invalid.')
      } else {
        let text = await res.text();
        text = text || 'Unknown Error'
        let message = `An error occured while attempting to log in: ${text}`
        dispatch(authenticationError(message))
        throw new Error(`An error occured while attempting to log in: ${text}`)
      }
    } else {
      let info = await res.json();
      window.AARDVARK_ACCESS = info.token;
      dispatch(bakeCookie('token', info.token))
      return dispatch({
        type: AUTHENTICATE_USER,
        payload: info
      })
    }
  }
}

export const authenticationError = (message) => {
  return (dispatch, getState) => {
    window.AARDVARK_ACCESS = null;
    dispatch(bakeCookie('token', null))
    dispatch({
      type: AUTHENTICATION_ERROR,
      payload: message
    })
  }
}

export const logOut = () => {
  return (dispatch, getState) => {
    window.AARDVARK_ACCESS = null;
    dispatch(bakeCookie('token', null));
    history.push("/admin");
  }
}

export const fetchInterfaceConfig = () => {
  return async (dispatch, getState) => {
    let jobId = dispatch(loading.startLoading(getState().server._loaderID))
    try {
      let res = await fetchFromServer("/v2/other/config")
      if(!res.ok) {
        if(res.status !== 404) {
          console.error("Failed to fetch config file:")
          console.error(res)
          let errText = await res.text()
          dispatch(messages.alert(`There was an error fetching the configuration file: ${errText || res.statusText}. Try reloading the page; if you are still experiencing the issue then contact support.`, {level: "error"}))
        }
      } else {
        let config = await res.json();
        dispatch({
          type: SET_INTERFACE_CONFIG,
          payload: config
        })
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(loading.finishLoading(getState().server._loaderID, jobId))
  }
}

export const changeInterfaceConfig = (path, value) => {
  return (dispatch, getState) => {
    let config = getState().server.config
    dispatch({
      type: SET_INTERFACE_CONFIG,
      payload: setIn(config, path, value)
    })
  }
}

export const saveInterfaceConfig = (changes={}) => {
  return async (dispatch, getState) => {
    let jobId = dispatch(loading.startLoading(getState().server._loaderID))
    try {
      let {config} = getState().server
      let newConfig = {...config, ...changes}
      let res = await fetchFromServer("/v2/other/config", {
        method: 'POST',
        body: JSON.stringify(newConfig),
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if(!res.ok) {
        console.error("Failed to save config file:")
        console.error(res)
        let errText = await res.text()
        dispatch(messages.alert(`There was an error fetching the configuration file: ${errText || res.statusText}.`, {level: "error"}))
      } else {
        dispatch(fetchInterfaceConfig())
      }
    } catch (err) {
      console.error(err)
    }
    dispatch(loading.finishLoading(getState().server._loaderID, jobId))
  }
}

export const initialState = {
  admin: false,
  appAccess: [],
  accessToken: null,
  errorMessage: '',
  config: {}
}

const reducer = (state=initialState, action) => {
  let {type, payload} = action

  switch(type) {
    case TAKE_COOKIE:
      return {
        ...state,
        accessToken: payload.token
      }
    case START_AUTHENTICATION:
      return {
        ...state,
        errorMessage: ''
      }
    case AUTHENTICATE_USER:
      return {
        ...state,
        admin: payload.admin,
        accessToken: payload.token,
        appAccess: payload.access,
        errorMessage: ''
      }
    case AUTHENTICATION_ERROR:
      return {
        ...state,
        errorMessage: payload,
        accessToken: null,
        appAccess: []
      }
    case SET_INTERFACE_CONFIG:
      return {
        ...state,
        config: payload
      }
    default:
      return state
  }
}

export default loaderReducer(reducer, initialState)
