/**
 * Reads a schedule file and returns it as a json object representation
 * @param {string} scheduleText The schedule in string form as read from a schedule file
 * @returns A json object with similar key/values as were originally written in the schedule file itself
 */
export const readScheduleFileToJSON = (scheduleText) => {
  let scheduleJSON = {type: 'weekly'}
  let linesToParse = scheduleText.split('\n')
  let items = []
  let blocks = []
  let currentObject = ''
  let objectBuffer = null
  linesToParse.forEach((line, lineNum) => {
    // Split key and value on line
    line = line.split('=')
    // If line is not a key/value pair
    if(line.length === 1) {
      line = line[0].trim()
      // Schedule Type
      if(line.startsWith('*')) {
        scheduleJSON.type = line.slice(1)
      // Start of a block section
      } else if(line.endsWith('{')) {
        // Get the type of the block
        currentObject = line.slice(0, line.length - 1).trim()
        // If not type specified, then it is a schedule item
        if(currentObject === '') {
          currentObject = '_ITEM'
        }
        objectBuffer = {}
      // End of a block section
      } else if(line === '}') {
        if(currentObject === '_ITEM') {
          items.push(objectBuffer)
        } else if(currentObject === 'schedule block') {
          blocks.push(objectBuffer)
        } else {
          scheduleJSON[currentObject] = objectBuffer
        }
        currentObject = ''
        objectBuffer = null
      }
    // If line is a key/value pair
    } else {
      let [key, ...value] = line
      key = key.trim()
      value = value.join('=').trimLeft()
      if(value.includes('=') &&
        currentObject !== '_ITEM' &&
        currentObject !== 'schedule block') {
        let valObject = {}
        value = value.split(';').forEach((param) => {
          param = param.trim()
          if(param === '') {
            return
          }
          param = param.split('=')
          if(param.length === 1 && param[0] !== '') {
            valObject.item = param[0]
          } else {
            valObject[param[0]] = param[1]
          }
        })
        value = valObject
      }
      // Handle syntax of intervals/triggers
      if(currentObject === 'events' ||
        currentObject === 'intervals' ||
        currentObject === 'triggers') {
        let match = /^([^[]+)\[(\d+)\]/.exec(key)
        if(match && match.length === 3) {
          let index = parseInt(match[2], 10)
          key = match[1]
          let newVal = objectBuffer[key] || []
          newVal[index] = value
          value = newVal
        }
      }
      if(currentObject !== '' && objectBuffer !== null) {
        objectBuffer[key] = value
      } else {
        scheduleJSON[key] = value
      }
    }
  })
  scheduleJSON.items = items
  scheduleJSON.blocks = blocks
  return scheduleJSON
}

/**
 * Writes a schedule json object into text schedule form
 * @param {object} schedule A JSON object representing the schedule to be written
 * @param {object} options Additional options for writing the schedule
 * @param {boolean} options.strictMode If set to true, function will throw an error when encountering unknown key/value pairs in the schedule or any
 *  of its items. Defaults to false.
 * @returns A schedule in text form as written in a schedule file
 */
export const writeJSONScheduleToText = (schedule, options={}) => {
  schedule = {...schedule}
  let {strictMode=false} = options
  let buffer = []
  let postBuffer = []
  let scheduleKeys = Object.keys(schedule)
  let scheduleType = schedule.type
  // type
  let typeLine = formatTypeScheduleLine(scheduleType)
  if(typeLine !== '') {
    buffer.push(typeLine)
  }
  delete schedule.type
  // defaults
  let defaultKeyInd = scheduleKeys.findIndex((key) => {
    return key.startsWith('defaults,')
  })
  let defaultKey = scheduleKeys[defaultKeyInd]
  if(schedule[defaultKey]) {
    buffer = buffer.concat(formatDefaultsScheduleLines(defaultKey, schedule[defaultKey], scheduleType))
    delete schedule[defaultKey]
  }
  // year
  if(scheduleType === 'yearly' || scheduleType === 'monthly') {
    buffer.push(formatSimpleScheduleLine('year', schedule.year))
    delete schedule.year
  // month
    buffer.push(formatSimpleScheduleLine('month', schedule.month))
    delete schedule.month
  }
  // day
  if(scheduleType !== 'daily') {
    buffer.push(formatSimpleScheduleLine('day', schedule.day))
    delete schedule.day
  }
  // interval duration
  if(scheduleType === 'interval') {
    buffer.push(formatSimpleScheduleLine('interval duration', schedule['interval duration']))
    delete schedule['interval duration']
  // interval base
    buffer.push(formatSimpleScheduleLine('interval base', schedule['interval base']))
    delete schedule['interval base']
  }
  // time slot length
  buffer.push(formatSimpleScheduleLine('time slot length', schedule['time slot length']))
  delete schedule['time slot length']
  // scrolltime
  buffer.push(formatSimpleScheduleLine('scrolltime', schedule.scrolltime))
  delete schedule.scrolltime
  // filter script
  buffer.push(formatSimpleScheduleLine('filter script', schedule['filter script']))
  delete schedule['filter script']
  // global default
  buffer.push(formatSimpleScheduleLine('global default', schedule['global default']))
  delete schedule['global default']
  // text encoding
  if(schedule['global default section']) {
    buffer.push(formatObjectScheduleLine('global default section', schedule['global default section'], true))
    delete schedule['global default section']
  }
  buffer.push(formatSimpleScheduleLine('text encoding', schedule['text encoding']))
  delete schedule['text encoding']
  // schedule format version
  if (schedule['schedule format version']) {
    buffer.push(formatSimpleScheduleLine('schedule format version', schedule['schedule format version']))
    delete schedule['schedule format version']
  }
  // filter script event triggers
  if(schedule.triggers) {
    postBuffer = postBuffer.concat(formatScriptTriggerLines(schedule.triggers, 'triggers'))
    delete schedule.triggers
  }
  // filter script event triggers
  if(schedule.intervals) {
    postBuffer = postBuffer.concat(formatScriptTriggerLines(schedule.intervals, 'intervals'))
    delete schedule.intervals
  }
  // schedule blocks
  if(schedule.blocks) {
    postBuffer = postBuffer.concat(formatAllScheduleBlockLines(schedule.blocks, options))
    delete schedule.blocks
  }
  // items
  if(schedule.items) {
    postBuffer = postBuffer.concat(formatAllScheduleItemLines(schedule.items, options))
    delete schedule.items
  }
  if(Object.keys(schedule).length > 0) {
    if(strictMode) {
      throw new Error(`Unknown key/value pairs were detected in the schedule JSON while writing to text, and strict mode is enabled: ${JSON.stringify(schedule)}`)
    } else {
      Object.entries(schedule).forEach(([key, val]) => {
        console.warn(`Unknown key in schedule: ${key}`)
        if(typeof val === 'object') {
          buffer.push(formatObjectScheduleLine(key, val))
        } else {
          buffer.push(formatSimpleScheduleLine(key, val))
        }
      })
    }
  }
  buffer = buffer.concat(postBuffer)
  let toReturn = buffer.join('\n')
  toReturn = toReturn + '\n'
  return toReturn
}

const formatTypeScheduleLine = (type) => {
  if(type !== 'weekly') {
    return ('*' + type)
  }
  return ''
}

const formatSimpleScheduleLine = (key, value) => {
  if(!value) {
    value = ''
  }
  let equals = ' = '
  if(key === 'global default') {
    equals = '='
  }
  return '' + key + equals + value
}

const formatDefaultsScheduleLines = (key, value, type) => {
  let buffer = []
  buffer.push('' + key + '{')
  let subBuffer = []
  let defaultKeys = Object.keys(value)
  switch(type) {
    case 'interval':
      defaultKeys = defaultKeys.sort(sortIntervalDefaults)
      break
    case 'yearly':
      defaultKeys = defaultKeys.sort(sortYearlyDefaults)
      break
    case 'monthly':
      defaultKeys = defaultKeys.sort(sortMonthlyDefaults)
      break
    case 'weekly':
      defaultKeys = defaultKeys.sort(sortWeeklyDefaults)
      break
    case 'daily':
    default:
      defaultKeys = defaultKeys.sort(sortDefaultTypes)
      break
  }
  defaultKeys.forEach((defaultItemKey) => {
    if(typeof value[defaultItemKey] === 'object') {
      subBuffer.push(formatObjectScheduleLine(defaultItemKey, value[defaultItemKey], true))
    } else {
      subBuffer.push('' + defaultItemKey + '=' + value[defaultItemKey])
    }
  })
  subBuffer = subBuffer.map((bufferEntry) => {
    return '\t' + bufferEntry
  })
  buffer = buffer.concat(subBuffer)
  buffer.push('}')
  return buffer
}

const formatObjectScheduleLine = (key, value, separateItem=false) => {
  let defBuffer = ''
  let item = ''
  Object.keys(value).forEach((subDefKey) => {
    if(value[subDefKey] !== undefined) {
      if(separateItem && subDefKey === 'item') {
        item = value[subDefKey]
      } else {
        defBuffer = defBuffer + subDefKey + '=' + value[subDefKey] + ';'
      }
    }
  })
  if(item !== '') {
    defBuffer = defBuffer + ';' + item
  }
  return ('' + key + '=' + defBuffer)
}

const formatAllScheduleBlockLines = (value, options={}) => {
  let subBuffer = []
  value.forEach((block) => {
    subBuffer.push('schedule block {')
    let blockBuffer = formatBlockScheduleLines(block, options)
    blockBuffer = blockBuffer.map(line => '\t' + line)
    subBuffer = subBuffer.concat(blockBuffer)
    subBuffer.push('}')
  })
  return subBuffer
}

const formatBlockScheduleLines = (formatBlock, options={}) => {
  let {strictMode=false} = options
  let subBuffer = []
  let block = {...formatBlock}
  subBuffer.push('start=' + block.start)
  delete block.start
  subBuffer.push('end=' + block.end)
  delete block.end
  subBuffer.push('block=' + block.block)
  delete block.block
  // checks that the key exists even if the value is undefined, to prevent it getting picked up by the extra keys check below
  if("color" in block) {
    if(block.color) {
      subBuffer.push('color=' + block.color)
    }
    delete block.color
  }
  // checks that the key exists even if the value is undefined, to prevent it getting picked up by the extra keys check below
  if("announce" in block) {
    if(block.announce) {
      subBuffer.push('announce=' + block.announce)
    }
    delete block.announce
  }
  if(Object.keys(block).length > 0) {
    let message = 'The following extra keys were found in a schedule block:\n' +
        Object.keys(block).join('\n') +
        '\nThey were found in this block:\n' +
        JSON.stringify(formatBlock, null, '  ')
    if(strictMode) {
      throw new Error(message)
    } else {
      console.warn(message)
      Object.entries(block).forEach(([key, val]) => {
        subBuffer.push(`${key}=${val}`)
      })
    }
  }
  return subBuffer
}

const formatAllScheduleItemLines = (value, options={}) => {
let subBuffer = []
  value.forEach((item) => {
    subBuffer.push('{')
    let itemBuffer = formatItemScheduleLines(item, options)
    itemBuffer = itemBuffer.map(line => '\t' + line)
    subBuffer = subBuffer.concat(itemBuffer)
    subBuffer.push('}')
  })
  return subBuffer
}

const formatItemScheduleLines = (formatItem, options={}) => {
  let {strictMode=false} = options
  let subBuffer = []
  let item = {...formatItem}
  if(item.item) subBuffer.push('item=' + item.item)
  delete item.item
  if(item['event id']) subBuffer.push('event id=' + item['event id'])
  delete item['event id']
  if(item['item duration'] !== undefined && item['item duration'] > 0) subBuffer.push('item duration=' + item['item duration'])
  delete item['item duration']
  if(item.in !== undefined && item.in >= 0) subBuffer.push('in=' + item.in)
  delete item.in
  if(item.out !== undefined && item.out >= 0) subBuffer.push('out=' + item.out)
  delete item.out
  if(item.loop) subBuffer.push('loop=' + item.loop)
  delete item.loop
  if(item.label) subBuffer.push('label=' + item.label)
  delete item.label
  if(item.guid) subBuffer.push('guid=' + item.guid)
  delete item.guid
  if(item.locked) subBuffer.push('locked=' + item.locked)
  delete item.locked
  if(item.start) subBuffer.push('start=' + item.start)
  delete item.start
  if(item.end) subBuffer.push('end=' + item.end)
  delete item.end
  if(item['error status']) subBuffer.push('error status=' + item['error status'])
  delete item['error status']
  if(item.block) subBuffer.push('block=' + item.block)
  delete item.block
  if(item.association) subBuffer.push('association=' + item.association)
  delete item.association
  if(item['association name']) subBuffer.push('association name=' + item["association name"])
  delete item["association name"]
  if(item["scte-35"]) subBuffer.push("scte-35=" + item["scte-35"])
  delete item["scte-35"]
  // check
  if(Object.keys(item).length > 0) {
    let message = 'The following extra keys were found in an item:\n' +
      Object.keys(item).join('\n') +
      '\nThey were found in this item:\n' +
      JSON.stringify(formatItem, null, '  ')
    if(strictMode) {
      throw new Error(message)
    } else {
      console.warn(message)
      Object.entries(item).forEach(([key, val]) => {
        subBuffer.push(`${key}=${val}`)
      })
    }
  }
  return subBuffer
}

const formatScriptTriggerLines = (triggers, type) => {
  if(type !== 'triggers' && type !== 'intervals') {
    let err = new Error(`${type} is not a valid kind of event trigger. Only 'triggers' or 'intervals' are valid types`);
    err.code = 'ERR_WRONGTYPE';
    throw err;
  }
  let buffer = []
  Object.entries(triggers).forEach(([key, val]) => {
    val.forEach((child, index) => {
      buffer.push(`${"\t"}${key}[${index}]=${child}`);
    })
  })
  if(buffer.length > 0) {
    buffer = [
      `${type} {`,
      ...buffer,
      '}'
    ]
  }
  return buffer
}

const sortIntervalDefaults = (a, b) => {
  let aMatch = /doi(\d+)/.exec(a)
  let bMatch = /doi(\d+)/.exec(b)
  let aVal = 0
  let bVal = 0
  if(aMatch && aMatch[1]) {
    aVal = parseInt(aMatch[1], 10)
  }
  if(bMatch && bMatch[1]) {
    bVal = parseInt(bMatch[1], 10)
  }
  if(aVal !== bVal) {
    return aVal - bVal
  } else {
    return sortDefaultTypes(a, b)
  }
}

const sortYearlyDefaults = (a, b) => {
  let aMatch = /m(\d+)d(\d+)/.exec(a)
  let bMatch = /m(\d+)d(\d+)/.exec(b)
  let aMonth = 0
  let bMonth = 0
  let aDay = 0
  let bDay = 0
  if(aMatch) {
    if(aMatch[1]) {
      aMonth = parseInt(aMatch[1], 10)
    }
    if(aMatch[2]) {
      aDay = parseInt(aDay[2], 10)
    }
  }
  if(bMatch) {
    if(bMatch[1]) {
      bMonth = parseInt(bMatch[1], 10)
    }
    if(bMatch[2]) {
      bDay = parseInt(bDay[2], 10)
    }
  }
  if(aMonth !== bMonth) {
    return aMonth - bMonth
  } else if (aDay !== bDay) {
    return aDay - bDay
  } else {
    return sortDefaultTypes(a, b)
  }
}

const sortMonthlyDefaults = (a, b) => {
  let aMatch = /dom(\d+)/.exec(a)
  let bMatch = /dom(\d+)/.exec(b)
  let aVal = 0
  let bVal = 0
  if(aMatch && aMatch[1]) {
    aVal = parseInt(aMatch[1], 10)
  }
  if(bMatch && bMatch[1]) {
    bVal = parseInt(bMatch[1], 10)
  }
  if(aVal !== bVal) {
    return aVal - bVal
  } else {
    return sortDefaultTypes(a, b)
  }
}

const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const sortWeeklyDefaults = (a, b) => {
  let aMatch = a.split(',')
  let bMatch = b.split(',')
  let aVal = DAYS_OF_WEEK.indexOf(aMatch.pop().trim())
  let bVal = DAYS_OF_WEEK.indexOf(bMatch.pop().trim())
  if(aVal !== bVal) {
    return aVal - bVal
  } else {
    return sortDefaultTypes(a, b)
  }
}

const sortDefaultTypes = (a, b) => {
  let aMatch = a.split(',')[0].trim()
  let bMatch = b.split(',')[0].trim()
  let aVal = 0
  let bVal = 0
  switch(aMatch) {
    case 'section':
      aVal = 1
      break
    case 'station logo':
      aVal = 2
      break
    case 'coming up next':
      aVal = 3
      break
    default:
      break
  }
  switch(bMatch) {
    case 'section':
      bVal = 1
      break
    case 'station logo':
      bVal = 2
      break
    case 'coming up next':
      bVal = 3
      break
    default:
      break
  }
  return aVal - bVal
}
