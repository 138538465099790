import { createSelector } from 'reselect'
import moment from 'moment-timezone'

const getTime = (state) => state.time
const getTimezone = (state) => state.timezone

export default createSelector([getTime, getTimezone], (time, tz) => {
  if(moment.isMoment(time)) {
    let timeValue = time.format("D M YYYY H:mm:ss");
    return moment.tz(timeValue, "D M YYYY H:mm:ss", tz);
  } else {
    return moment.tz(time, tz);
  }
})
