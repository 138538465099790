import React, {PureComponent} from 'react'
import {Redirect} from 'react-router'
import ServiceSettingsHeader from './ServiceSettingsHeader'
import SettingsGroup from './SettingsGroup'
import SettingPanel from './SettingPanel'
import SettingsArray from './SettingsArray'
import {Segment, Search, Button} from 'semantic-ui-react'
import SettingsSelector from 'selectors/SettingsSelector'
import SchemaSelector from 'selectors/SchemaSelector'
import SettingIdSelector from 'selectors/SettingIdSelector'

// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { settingsView } from '../../redux/components/settings_list_render'

import './ServiceSettings.css'

export class ServiceSettings extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      loadFailed: false,
    }
  }

  handleChangeSearchString = (e, data) => {
    this.props.setSettingsSearchString(data.value)
  }

  handleDownloadSettings = () => {
    let {type} = this.props.match.params
    let id = SettingIdSelector(this.props)
    let {downloadFile} = this.props
    switch(type) {
      case "channel":
        downloadFile(["mnt", "main", "tv", "rendermgr", id]);
        break;
      case "output":
        downloadFile(["mnt", "main", "tv", "outputs", id]);
        break;
      case "input":
        downloadFile(["mnt", "main", "tv", "inputs", id]);
        break;
      case "router":
        downloadFile(["mnt", "main", "tv", "routing", id]);
        break;
      default:
        break;
    }
  }

  // These two functions handle toggle between basic or advanced settings view
  // settingsView is an action coming from Redux
  handleBasicSettingsToggle = () => {
    let view = true
    this.props.settingsView(view)
  }

  handleAdvancedSettingsToggle = () => {
    let view = false
    this.props.settingsView(view)
  }

  render() {
    let {match,
      services,
      origServices,
      schema,
      settingsSearchString,
      modifySettings,
      saveServiceSettings,
      openApplication,
      startOutputRecording,
      stopOutputRecording,
      renameOutputRecording,
      outputRecordingStatus,
      settingListView} = this.props
    let {loadFailed} = this.state

    if(loadFailed) {
      return (<Redirect to="/settings"/>)
    }
    let {type} = match.params
    let id = SettingIdSelector(this.props)
    let settings = SettingsSelector({type, id, services})
    let targetSchema = SchemaSelector({type, id, services: origServices, schema}) || {driver: {const: "unknown"}}
    let children = []
    if(settings && targetSchema) {
      let settingProps = {service: id, serviceType: type, settingsSearchString, modifySettings, settingListView}
      children = parseSettingsToComponents(settings, targetSchema, settingProps)
    }

    return (
      <div>
        <ServiceSettingsHeader type={type}
          id={id}
          settings={settings}
          targetSchema={targetSchema}
          openApplication={openApplication}
          saveServiceSettings={saveServiceSettings}
          handleDownloadSettings={this.handleDownloadSettings}
          startOutputRecording={startOutputRecording}
          stopOutputRecording={stopOutputRecording}
          renameOutputRecording={renameOutputRecording}
          outputRecordingStatus={outputRecordingStatus}
        />
        {/* The two buttons will render a grey color when basic or advanced settings is triggered */}
        <Segment>
          {settingListView === true ?
          <Button content="Basic Settings" onClick={this.handleBasicSettingsToggle} color="grey"/>
          : <Button content="Basic Settings" onClick={this.handleBasicSettingsToggle}/>
        }
          {settingListView === false ?
          <Button content="Advanced Settings" onClick={this.handleAdvancedSettingsToggle} color="grey"/>
          : <Button content="Advanced Settings" onClick={this.handleAdvancedSettingsToggle}/>
        }
        </Segment>
          <Search value={settingsSearchString}
          onSearchChange={this.handleChangeSearchString}
          showNoResults={false}
          size="small"/>
        <Segment.Group>
          {children}
        </Segment.Group>
      </div>
    )
  }
}

const parseSettingsToComponents = (settings, schema, props) => {
  let rest = {...settings};
  let toReturn = Object.entries(schema).map(([key, value]) => {
    if(key in rest) {
      delete rest[key]
    }
    if(value.type === 'object') {
      return (<SettingsGroup key={key}
      address={[key]}
      setting={key}
      value={settings[key] ? settings[key] : {}}
      schema={value}
      topLevel
      {...props}/>)
    } else if(value.type === 'array' ||
      (value.type instanceof Array &&
      value.type.includes('array'))) {
      return (<SettingsArray key={key}
        address={[key]}
        setting={key}
        value={settings[key]}
        schema={value}
        {...props}/>)
    } else {
      return (<SettingPanel key={key}
        address={[key]}
        setting={key}
        value={settings[key]}
        schema={value}
        {...props}/>)
    }
  })
  let restSettings = Object.entries(rest).filter(([key, value]) => typeof value === "string")
    .map(([key, value]) => {
      return (<SettingPanel key={key}
        address={[key]}
        setting={key}
        value={value}
        schema={{type: "string", advanced: true}}
        extra={true}
        {...props}/>)
    })
  return [...toReturn, ...restSettings]
}

const mapStateToProps = state => {
  // console.log(state)
  return {
    settingListView: state.settings_list_render.basicView
  }
}

const mapDispatchToProps = dispatch => ({...bindActionCreators({
    settingsView
  }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceSettings)
