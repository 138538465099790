import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actions} from 'redux/applications/global_media'
import {actions as SettingsActions} from 'redux/settings'
import {openApplication} from 'redux/menu'
import {loadFileData, setSearchNext} from 'redux/file_list'
import FrameEditor from 'components/CanvasFrameEditor/Container'
import Library from 'containers/components/LibraryComponent'
import Controls from 'components/GlobalMedia/Controls/Container'
import {Header, Divider} from 'semantic-ui-react'

import SettingsSelector from 'selectors/SettingsSelector'
import SchemaSelector from 'selectors/SchemaSelector'
import GlobalMediaFrameSelector from 'selectors/GlobalMediaFrameSelector'

import {getIn} from 'helpers/general_helpers'

import './GlobalMedia.css'

class GlobalMedia extends PureComponent {

  componentDidMount() {
    this.props.fetchGlobalThumbnails(this.props.frames)
    this.thumbTimer = setTimeout(this.handleFetchThumbnail, 2000)
  }

  componentWillUnmount() {
    clearTimeout(this.thumbTimer)
    this.thumbTimer = null;
  }

  handleFetchThumbnail = async () => {
    await this.props.fetchGlobalThumbnails(this.props.frames)
    if(this.thumbTimer) {
      this.thumbTimer = setTimeout(this.handleFetchThumbnail, 2000)
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.activeChannel !== this.props.activeChannel) {
      this.props.clearGlobalFrames()
    }
  }

  updateLibrary = (selected=null, navpath) => {
    if(selected && selected[0]) {
      this.props.globalMediaFileSelect(selected[0])
    }
    this.props.libraryUpdate(selected, navpath)
  }

  render() {
    let {filelist,
      library,
      running,
      loadFileData,
      channelName,
      activeChannel,
      setSearchNext,
      ...global_media} = this.props

    return (
      <div id="GlobalMediaEditor">
        {channelName ?
          <Header as='h2' icon='globe' content={channelName}/> :
          null}
        <Divider fitted/>
        <div id="GlobalMediaFrameEditor">
          <FrameEditor key={activeChannel}
            activeChannel={activeChannel}
            running={running}
            frames={global_media.frames}
            moveFrame={global_media.moveGlobalFrame}
            resizeFrame={global_media.resizeGlobalFrame}
            snapToGrid={global_media.snapToGrid}/>
        </div>
        <div id="GlobalMediaManager">
          <div id="GlobalMediaLibraryContainer">
            <Library update={this.updateLibrary}
              overridePath={library.navpath}
              compact
              selectOne/>
          </div>
          <div id="GlobalMediaControlsContainer">
            <Controls {...global_media}/>
          </div>
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  let ch = state.channel.active_channel
  let settings = SettingsSelector({...state.settings, type: 'channel', id: ch})
  let schema = SchemaSelector({...state.settings, type: 'channel', id: ch})
  let frames = GlobalMediaFrameSelector({
    settings,
    schema,
    frames: state.global_media.present.frames
  })
  let name = getIn(settings, ['name'])
  let running = getIn(settings, ['running'])
  return {
    ...state.global_media.present,
    library: state.global_media._library,
    frames,
    running,
    filelist: state.file_list,
    channelName: name,
    activeChannel: state.channel.active_channel,
    shouldUndo: state.global_media.past.length > 0,
    shouldRedo: state.global_media.future.length > 0
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
  ...actions,
  ...SettingsActions,
  loadFileData,
  openApplication,
  setSearchNext
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GlobalMedia)
