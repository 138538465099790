import React, {PureComponent} from 'react'
import {Card} from 'semantic-ui-react'

import LibraryFile from './LibraryFile'

import './LibraryFileTiles.css'

class LibraryFileTiles extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {y: 0}
    this.frame = React.createRef()
  }

  componentDidMount() {
    if(this.frame.current) {
      this.setState({y: this.frame.current.getBoundingClientRect().y})
    }
  }

  render() {
    let {
      filelist,
      fileData,
      selectedFiles,
      navpath,
      changeLibraryPath,
      selectOne,
      compact,
      selectFile,
      selectFileOnly,
      selectFileRange,
      previewFile,
      handleOpenFile,
      actionDropdown,
      highlightFile,
      selectedChannel
    } = this.props
    let {y} = this.state

    // populate table rows from filelist if it exists
    let cardTiles = filelist ? filelist.map((item) => {
      let path = navpath.concat(item)
      let active = selectedFiles.find((val) => {
        return (val.join('/') === path.join('/'))
      })
      active = !!active
      let highlight = false
      if(highlightFile) {
        highlight = (highlightFile === item)
      }
      let ismntmain = false

      if((navpath instanceof Array && navpath.join('/') === "mnt/main") || navpath === "mnt/main")
        ismntmain = true

      return (<LibraryFile displayType='tile'
                key={item}
                filename={item}
                path={path}
                data={fileData[item]}
                ismntmain={ismntmain}
                ismntmaintv={ismntmain && item === "tv"}
                changeLibraryPath={changeLibraryPath}
                active={active}
                selectOne={selectOne}
                selectedFiles={selectedFiles}
                compact={compact}
                selectFile={selectFile}
                selectFileOnly={selectFileOnly}
                selectFileRange={selectFileRange}
                previewFile={previewFile}
                handleOpenFile={handleOpenFile}
                actionDropdown={actionDropdown}
                highlight={highlight}
                yAdjustment={y}
                selectedChannel={selectedChannel}/>)
    }) : []

    return (
      <div className='libraryTilesPanel' ref={this.frame}>
        <Card.Group centered>
          {cardTiles}
        </Card.Group>
      </div>
    )
  }

}

export default LibraryFileTiles
