import React, {PureComponent} from 'react';
import {Button, Menu, Header, Grid, List} from 'semantic-ui-react'
import TimeInput from 'components/TimeInput/TimeInput'
import IntervalTime from 'helpers/IntervalTime/IntervalTime'
import moment from "moment"

import ScheduleReplicateTimeSelector from 'components/Schedule/ScheduleReplicateTimeSelector'

import "./ScheduleDatePrompt.css"

const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
]

const WEEKDAYS_SHORT = [
  "Su",
  "Mo",
  "Tu",
  "We",
  "Th",
  "Fr",
  "Sa"
]

export default class ScheduleDatePrompt extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {addTimes: [{id: 0, value: "12:00:00 am"}]}
  }

  componentDidUpdate(prevprops, prevstate) {
    // Handler for daily schedules
    if(this.props.options.scheduleType === "daily" &&
      this.state.addTimes !== prevstate.addTimes) {
      let dailyTimes = this.state.addTimes.map((addTime) => {
        try {
          return new IntervalTime(addTime.value, "daily")
        } catch (err) {
          return null
        }
      }).filter((time) => time !== null)
      this.props.changeValue(dailyTimes)
    }
  }

  toggleDate = (selectedDate) => {
    let {scheduleType, intervalDuration, intervalBasis} = this.props.options
    switch(scheduleType) {
      case "yearly":
      case "monthly":
        selectedDate = new IntervalTime(selectedDate, scheduleType)
        break;
      case "weekly":
        selectedDate = new IntervalTime({weekday: selectedDate}, scheduleType)
        break;
      case "interval":
        selectedDate = new IntervalTime(selectedDate, scheduleType, {days: intervalDuration, basis: intervalBasis})
        break;
      case "endless":
        // selectedDate is already a moment, so it doesn't need to be converted.
        break;
      default:
        console.log(selectedDate)
        return
    }
    this.selectTimes([selectedDate])
  }

  selectMonthWeekday = (e) => {
    let {viewTime} = this.props
    // Detect when the day of week headers are clicked in the date selector
    if(`${e.target.tagName}.${e.target.className}` === "TH.dow") {
      let selectWeekday = WEEKDAYS_SHORT.findIndex((dow) => dow === e.target.innerHTML)
      let viewDate = document.querySelector("div.scheduleReplicateTimeSelector th.rdtSwitch").innerHTML
      if(viewDate) {
        viewTime = moment(viewDate, "MMMM YYYY")
      }
      this.selectAllInPeriod("month", selectWeekday, viewTime)
    }
  }

  selectAllYear = () => {
    this.selectAllInPeriod("year")
  }

  selectYearWeekday = (weekday) => {
    this.selectAllInPeriod("year", weekday)
  }

  selectAllInPeriod = (period, weekday=null, overrideDate=null) => {
    let {scheduleType, viewTime, intervalDuration, intervalBasis} = this.props.options
    if(period !== "year" && period !== "month") {
      throw new Error(`Period for selectAllInPeriod in ScheduleDayReplicateModal must be exactly either year or month, but got ${period} instead.`)
    }
    if(overrideDate) {
      viewTime = overrideDate
    }
    // First, get the days that we are selecting as interval times
    let periodStart = viewTime.clone().startOf(period)
    let periodEnd = viewTime.clone().endOf(period)
    let toSelect = []
    for(; periodStart.valueOf() < periodEnd.valueOf(); periodStart.add(1, 'd')) {
      if(scheduleType === "interval" && toSelect.find((alreadySelected) => alreadySelected.isOnSameDay(periodStart))) {
        continue
      }
      if(weekday === null || periodStart.day() === weekday) {
        if(scheduleType === "endless") {
          toSelect.push(periodStart.clone())
        } else {
          toSelect.push(new IntervalTime(periodStart, scheduleType, {days: intervalDuration, basis: intervalBasis}))
        }
      }
    }
    this.selectTimes(toSelect)
  }

  selectTimes = (toSelect) => {
    let {value, changeValue} = this.props
    let {scheduleType} = this.props.options
    let {addTimes} = this.state
    // Set time based on addTimes
    toSelect = toSelect.map((selectedDate) => {
      let timeChecks = addTimes.map((addTime) => /^(?:(\d{1,2}))?:(?:(\d{1,2}))?:(?:(\d{1,2}))?(?:\.(\d{1,3}))?(?: ([ap]m))?/.exec(addTime.value))
        .filter((addTime) => !!addTime)
      if(timeChecks.length) {
        return timeChecks.map((timeCheck) => {
          let [hours, minutes, seconds, millis, meridian] = timeCheck.slice(1)
          hours = hours ? parseInt(hours, 10) : 0
          minutes = minutes ? parseInt(minutes, 10) : 0
          seconds = seconds ? parseInt(seconds, 10) : 0
          millis = millis ? parseInt(millis, 10) : 0
          if((meridian === "pm" && hours !== 12) || (hours === 12 && meridian === "am")) {
            hours += 12
            hours = hours % 24
          }
          return selectedDate.set("hour", hours)
            .set("minute", minutes)
            .set("second", seconds)
            .set("millisecond", millis)
        })
      } else {
        return selectedDate.set("hour", 0)
          .set("minute", 0)
          .set("second", 0)
          .set("millisecond", 0)
      }
    }).flat()
    // Now we need to figure out if any of them are already selected
    let toSelectFiltered = toSelect.filter((day) => {
      if(scheduleType === "interval") {
        // ew, this is messy...
        return !(value.find((selectedDay) => day.printTimeOfDay() === selectedDay.printTimeOfDay() && day.isOnSameDay(selectedDay.closestDate(new Date()))))
      }
      return !(value.find((selectedDay) => selectedDay.toString() === day.toString()))
    })
    // They are ALL selected, so deselect instead
    if(toSelectFiltered.length === 0) {
      value = value.filter((selectedDay) => {
        if(scheduleType === "interval") {
          // ew, this is messy...
          return !(toSelect.find((day) => selectedDay.printTimeOfDay() === day.printTimeOfDay() && day.isOnSameDay(selectedDay.closestDate(new Date()))))
        }
        return !(toSelect.find((day) => day.toString() === selectedDay.toString()))
      })
    // Otherwise, add ones that aren't already selected
    } else {
      value = [...value, ...toSelectFiltered]
    }
    changeValue(value)
  }

  changeAddTime = (index, newVal) => {
    let {addTimes} = this.state
    addTimes = [...addTimes]
    addTimes[index].value = newVal
    this.setState((state) => ({...state, addTimes}))
  }

  newAddTime = () => {
    let {addTimes} = this.state
    let nextId = addTimes[addTimes.length - 1].id + 1
    addTimes = [...addTimes, {id: nextId, value: ""}]
    this.setState((state) => ({...state, addTimes}))
  }

  removeAddTime = (id) => {
    let {addTimes} = this.state
    addTimes = addTimes.filter((time) => time.id !== id)
    this.setState((state) => ({...state, addTimes}))
  }

  deselectTime = (toDeselect) => {
    let {value, changeValue} = this.props
    let newValue = value.filter((date) => date.toString() !== toDeselect.toString())
    changeValue(newValue)
  }

  render() {
    let {value=[]} = this.props
    let {scheduleType,
      viewTime,
      intervalBasis,
      intervalDuration,
      showMilliseconds=false} = this.props.options
    let {addTimes} = this.state

    let intervalView
    if(scheduleType === "endless") {
      intervalView = viewTime.clone()
    } else {
      intervalView = new IntervalTime(viewTime, scheduleType, {days: intervalDuration, basis: intervalBasis})
    }

    let fullButtonGroup = ""
    if(scheduleType === "yearly" || scheduleType === "endless") {
      let fullButtons = []
      fullButtons.push(<Menu.Item key="selectAll" onClick={this.selectAllYear}>
        Year
      </Menu.Item>)
      WEEKDAYS_SHORT.forEach((day, index) => {
        fullButtons.push(<Menu.Item key={`${day}Button`} title={`Select every ${WEEKDAYS[index]} of the entire current year`} onClick={() => {this.selectYearWeekday(index)}}>
          {day}
        </Menu.Item>)
      })
      fullButtonGroup = (
      <>
        <Header size="tiny">Select All (Based on year of day being replicated from):</Header>
        <Menu compact>
          {fullButtons}
        </Menu>
      </>
      )
    }


    let listItems = value.sort((a, b) => a.diff(b)).map((date) => (
      <List.Item key={date.valueOf()}>
        <List.Content floated="right">
          <Button compact color="red" icon="remove" onClick={() => {this.deselectTime(date)}}/>
        </List.Content>
        <List.Content>
          {date.toString({showMeridian: true, showMilliseconds, noBasis: true})}
        </List.Content>
      </List.Item>
    ))

    let timeInputs = addTimes.map((addTime, index) => {
      return (<List.Item key={addTime.id}>
        {addTimes.length > 1 ? (<List.Content floated="right">
          <Button compact color="red" icon="remove" onClick={() => {this.removeAddTime(addTime.id)}}/>
        </List.Content>) : ""}
        <List.Content>
          <TimeInput value={addTimes[index].value} onChange={(newVal) => {this.changeAddTime(index, newVal)}} showMilliseconds={showMilliseconds}/>
        </List.Content>
      </List.Item>
    )})
    timeInputs.push(<List.Item key="addButton">
      <Button fluid color="green" icon="plus" content="Add Time" onClick={this.newAddTime}/>
    </List.Item>)

    return (
      <Grid>
        <Grid.Column width={4}>
          <List className="replicateModalList">
            {timeInputs}
          </List>
        </Grid.Column>
        <Grid.Column width={7}>
          <ScheduleReplicateTimeSelector viewDate={viewTime}
            noViewHighlight={true}
            intervalView={intervalView}
            type={scheduleType}
            selectedDates={value}
            toggleDate={this.toggleDate}
            datelist={value}
            intervalBasis={intervalBasis}
            intervalDuration={intervalDuration}
            selectWeekday={this.selectMonthWeekday}/>
          {fullButtonGroup}
        </Grid.Column>
        <Grid.Column width={5}>
          <List divided
            verticalAlign="middle"
            className="replicateModalList">
            {listItems}
          </List>
        </Grid.Column>
      </Grid>
    )
  }

}
