import React, {PureComponent} from 'react'

import {
  Label,
  Button,
  List
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';

import "./StatusLines.css"

export default class StatusLines extends PureComponent {

  render() {
    let {lines,
      data} = this.props

    if(!lines || lines.length === 0) {
      return null
    }

    let statusLines = lines.map((line, index) => {
      let {label,
        value,
        title,
        link,
        onQueue} = line
      let lineText = value
      if(link) {
        lineText = (
          <Link to={link} className='monitorLink'>
            {value}
          </Link>
        )
      }
      let queueButton = ''
      if(onQueue && data.hasQueue) {
        queueButton = <Button compact size='mini' icon='ellipsis horizontal' onClick={() => onQueue(data.key)}/>
      }
      return (
        <List.Item className='statusLine' title={title} key={`${label}${index}`}>
          <Label horizontal>
            {label}
          </Label>
          {lineText}
          {queueButton}
        </List.Item>
      )
    })

    return(
      <List>
        {statusLines}
      </List>
    )
  }

}
