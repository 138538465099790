// import sampleInformation from './sample_about_info'
import {fetchFromServer} from 'helpers/net_helpers'
import {messages} from 'redux/messages'
export const FETCH_ABOUT_INFORMATION = Symbol('fetch about information');

export const actions = {

  fetchAboutInformation: () => {
    return async (dispatch, getState) => {
      let res = await fetchFromServer("/v2/other/license")
      if(res.ok) {
        let info = await res.json()
        let license = info['license']
        delete info.license
        dispatch({
          type: FETCH_ABOUT_INFORMATION,
          payload: {
            versions: info,
            license
          }
        })
      } else {
        let err = await res.text();
        dispatch(messages.alert(`There was an error fetching the versioning and license information: ${err}`, {level: 'error'}))
      }
    }
  },

}

export const initialState = {
  versions: {},        // Object containing individual component version information.
                       //  Keys are software, values are the versions of those pieces of software
  license: {},         // Contains liscense information as key/value pairs
  release_version: "", // Current castus version
}

export default (state=initialState, action) => {
  let {type, payload} = action
  switch(type) {
    case FETCH_ABOUT_INFORMATION: {
      let {release_version} = state
      if("platform" in payload.versions &&
        "Revision" in payload.versions.platform) {
        release_version = payload.versions.platform['Revision'].trim()
      }
      return {
        ...state,
        ...payload,
        release_version
      }
    }
    default:
      return state
  }
}
