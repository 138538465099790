import React, {PureComponent} from 'react'
import {Form} from 'semantic-ui-react'
import DirectorySelector from './DirectorySelector'

export default class PathPrompt extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {deselect: 0}
  }

  handleChange = (e, data) => {
    let {value, changeValue} = this.props
    if(!value) {
      value = {filename: '', directory: []}
    }
    let newValue = {...value}
    if(data.name === 'filename') {
      newValue.filename = data.value
      this.setState((state) => ({deselect: state.deselect + 1}))
    } else if (data.name === 'directory') {
      if(data.value) {
        newValue.directory = data.value
      } else if (data.filename) {
        newValue.filename = data.filename
      }
    }
    changeValue(newValue)
  }

  render() {
    let {value, options={}} = this.props
    let {deselect} = this.state
    let {filename, directory} = value
    let {noFilename} = options

    return (
      <Form>
        <Form.Input control={DirectorySelector}
          value={directory}
          name='directory'
          onChange={this.handleChange}
          deselect={deselect}/>
        {noFilename ?
          null :
          <Form.Input value={filename}
            onChange={this.handleChange}
            label='Filename'
            name='filename'
            fluid/>}
      </Form>
    )
  }

}
