import moment from "moment"

const CONTENT_WINDOW_REGEXP = /(\d{4})-(\d{1,2})-(\d{1,2}) (\d{1,2}):(\d{1,2}):(\d{1,2})/
const UNIXTIMESTAMP_SECONDS_REGEXP = /^\d{10,11}$/
const UNIXTIMESTAMP_MILLIS_REGEXP = /^\d{13,14}$/

/**
 * Merges swap metadata into metadata and returns it as a new object. Null values are deleted.
 * @param {object} metadata Original metadata
 * @param {object} swapData Object containing only changes to the original data
 * @returns New metadata object with swap changes applied and null values removed.
 */
export const mergeMetadata = (metadata, swapData) => {
  let toReturn = Object.assign({}, metadata, swapData)
  for(let [key, value] of Object.entries(toReturn)) {
    if(value === null) {
      delete toReturn[key]
    }
  }
  return toReturn
}

/**
 * @param {string} tag Capitalizes the given metadata tag
 */
export const capitalizeMetadata = (tag) => {
  return tag.split(/\s/).map((word) => {
    let temp = word.split('')
    if(temp[0]) {
      temp[0] = temp[0].toUpperCase()
    }
    return temp.join('')
  }).join(' ')
}

/**
 * Parses YYYY-MM-DD HH:mm:ss UTC into a date manually. Firefox (and possibly other browsers) will not parse that format natively, so it must be done by hand
 * @param {string} dateString A date string in the format YYYY-MM-DD HH:mm:ss UTC
 * @returns {Date} A date object with the date and time indicated by the passed string
 */
export const parseContentWindow = (dateString) => {
  let match = CONTENT_WINDOW_REGEXP.exec(dateString)
  if(match) {
    let [year, month, date, hour, minute, second] = match.slice(1).map((strVal) => parseInt(strVal, 10))
    // Months in javascript dates are zero-indexed (and are the only value that is for some unknowable reason)
    month = month - 1
    return Date.UTC(year, month, date, hour, minute, second);
  }
  let val;
  if(UNIXTIMESTAMP_SECONDS_REGEXP.exec(dateString)) {
    val = parseInt(dateString, 10) * 1000
  } else if (UNIXTIMESTAMP_MILLIS_REGEXP.exec(dateString)) {
    val = parseInt(dateString, 10)
  }
  if(val) {
    return new Date(val);
  }
  throw new Error(`The passed string did not match the format YYYY-MM-DD HH:mm:ss or was not a unix timestamp in string format. The passed string was: ${dateString}`)
}

/**
 * Converts Date into proper string format for content window metadata tag (YYYY-MM-DD HH:mm:ss UTC)
 * @param {Date} date The date to be serialized
 * @returns {string} A string representation of the date in format YYYY-MM-DD HH:mm:ss UTC
 */
export const serializeContentWindow = (date) => {
  

  if(!date instanceof Date) {
    throw new Error(`A value other than a Date was passed to serializeContentWindow. This function only accepts Date values. Value passed was: ${date}`)
  }
  let YYYY = date.getUTCFullYear()
  let MM = `${date.getUTCMonth() + 1}`.padStart(2, "0")
  let DD = `${date.getUTCDate()}`.padStart(2, "0")
  let HH = `${date.getUTCHours()}`.padStart(2, "0")
  let mm = `${date.getUTCMinutes()}`.padStart(2, "0")
  let ss = `${date.getUTCSeconds()}`.padStart(2, "0")
  return `${YYYY}-${MM}-${DD} ${HH}:${mm}:${ss} UTC`
}

/**
 * Parses a metadata template file from text to internal json object
 * @param {string} file The string contents of the metadata template
 * @returns object with metadata tag names as keys and the values of those tags as values
 */
export const parseMetadataTemplate = (file) => {
  let lines = file.split("\n")
  let firstLine = lines.shift()
  if(firstLine !== '#CastusMetadataTemplate') {
    let err = new Error("Not a metadata template!")
    err.type = 'NOT_METADATA_TEMPLATE'
    throw err
  }
  let toReturn = {}
  lines.forEach((line) => {
    let [key, value] = line.split('=')
    if(key) {
      toReturn[key] = value
    }
  })
  return toReturn
}

/**
 * Serializes internal json object metadata to string metadata template file
 * @param {object} metadata The internal metadata object
 * @returns string file contents of metadata template file
 */
export const serializeMetadataTemplate = (metadata) => {
  let lines = Object.entries(metadata).map(([key, value]) => {
    if(typeof value === "object" && moment.isMoment(value)) {
      value = value.utc().format("YYYY-MM-DD HH:mm:ss z")
    }
    return `${key.toLowerCase()}=${value}`
  })
  lines.unshift('#CastusMetadataTemplate')
  return lines.join("\n")
}

// Object containing hash of all metadata tags with tag names as keys and an object containing display name and description as values
export const metadataList = {
  "artist": {
    "display": "Artist",
    "description": "The Artist of the media item."
  },
  "aspect ratio": {
    "display": "Aspect Ratio",
    "description": "The Aspect Ratio of the media item.",
    "type": "list",
    "options": [
      {value: "16:9", key: "16:9"},
      {value: "4:3", key: "4:3"},
      {value: "1:1", key: "1:1"},
      {value: "3:4", key: "3:4"},
      {value: "9:16", key: "9:16"}
    ]
  },
  "audio channel selection": {
    "display": "Audio channel selection",
    "description": "This setting is used to chose which channel should be played.",
    "type": "select",
    "options": [
      {key: "default", text: "Default", value: ""},
      {key: "left", text: "Left", value: "left"},
      {key: "right", text: "Right", value: "right"},
      {key: "mono downmix", text: "Mono Downmix", value: "mono downmix"},
      {key: "mute", text: "Mute", value: "mute"}
    ]
  },
  "author": {
    "display": "Author",
    "description": "The Author of the media item."
  },
  "background music": {
    "display": "Background music",
    "description": "This setting changes whether background music should be played with this media item.",
    "type": "select",
    "options": [
      {key: "on", text: "On", value: "on"},
      {key: "off", text: "Off", value: "off"},
      {key: "if no audio", text: "If No Audio", value: "if no audio"}
    ]
  },
  "category": {
    "display": "Category",
    "description": "A free form generic category setting."
  },
  "cc": {
    "display": "Closed Caption",
    "description": "Indicates (1) whether closed captions are present in the file",
    "type": "select",
    "options": [
      {text: "(not set)", value: "", key: "" },
      {text: "Does not have closed captions", value: "0", key: "0" },
      {text: "Has closed captions", value: "1", key: "1" }
    ]
  },
  "content window open": {
    "display": "Go live",
    "description": "This is for the start of automatic content validation.",
    "type": "date"
  },
  "content window close": {
    "display": "Expiration",
    "description": "This is for the end of automatic content validation.",
    "type": "date"
  },
  "copyright": {
    "display": "Copyright",
    "description": "The legal copyright owner of the media item"
  },
  "description": {
    "display": "Description",
    "description": "Detailed description of the media item."
  },
  "downloadable": {
    "display": "Downloadable",
    "description": "When uploaded to Video on Demand, specifies whether or not the user is permitted to download the video.",
    "type": "boolean"
  },
  "duration": {
    "display": "Duration",
    "description": "Duration of the media item in seconds.",
    "type": "duration"
  },
  "dvb content identifier": {
    "display": "DVB content identifier code",
    "description": "This code conveys the general content type of the program",
    "type": "select",
    "options": [
      {text: "adult movie/drama", value: "0x0108", key: "0x0108" },
      {text: "adventure/western/war", value: "0x0102", key: "0x0102" },
      {text: "athletics", value: "0x0406", key: "0x0406" },
      {text: "comedy", value: "0x0104", key: "0x0104" },
      {text: "detective/thriller", value: "0x0101", key: "0x0101" },
      {text: "discussion/interview/debate", value: "0x0204", key: "0x0204" },
      {text: "documentary", value: "0x0203", key: "0x0203" },
      {text: "equestrian", value: "0x040A", key: "0x040A" },
      {text: "football/soccer", value: "0x0403", key: "0x0403" },
      {text: "game show/quiz/contest", value: "0x0301", key: "0x0301" },
      {text: "martial sports", value: "0x040B", key: "0x040B" },
      {text: "motor sport", value: "0x0407", key: "0x0407" },
      {text: "movie/drama (general)", value: "0x0100", key: "0x0100" },
      {text: "news magazine", value: "0x0202", key: "0x0202" },
      {text: "news/current affairs (general)", value: "0x0200", key: "0x0200" },
      {text: "news/weather report", value: "0x0201", key: "0x0201" },
      {text: "romance", value: "0x0106", key: "0x0106" },
      {text: "science fiction/fantasy/horror", value: "0x0103", key: "0x0103" },
      {text: "serious/classical/religious/historical movie/drama",	value: "0x0107", key: "0x0107" },
      {text: "show/game show (general)", value: "0x0300", key: "0x0300" },
      {text: "soap/melodrama/folkloric", value: "0x0105", key: "0x0105" },
      {text: "special events (Olympic Games, World Cup, etc.)",	value: "0x0401", key: "0x0401" },
      {text: "sports (general)", value: "0x0400", key: "0x0400" },
      {text: "sports magazines", value: "0x0402", key: "0x0402" },
      {text: "talk show", value: "0x0303", key: "0x0303" },
      {text: "team sports (excluding football)", value: "0x0405", key: "0x0405" },
      {text: "tennis/squash", value: "0x0404", key: "0x0404" },
      {text: "variety show", value: "0x0302", key: "0x0302" },
      {text: "water sport", value: "0x0408", key: "0x0408" },
      {text: "winter sports", value: "0x0409", key: "0x0409" }
    ]
  },
  "episode": {
    "display": "Episode",
    "description": "A free form generic episode setting."
  },
  "external link": {
    "display": "External Link",
    "description": "A url linking to the file on another website (for example, a video on youtube).",
    "type": "url"
  },
  "field order": {
    "display": "Field order",
    "description": "Override the media items field order for badly encoded media items.",
    "type": "select",
    "options": [
      {text: "Default", key: "default", value: ""},
      {text: "None", value: "none", key: "none"},
      {text: "Top", value: "top", key: "top"},
      {text: "Bottom", value: "bottom", key: "bottom"},
      {text: "Inverse", value: "inverse", key: "inverse"},
      {text: "Even Fields", value: "even fields", key: "even fields"},
      {text: "Odd Fields", value: "odd fields", key: "odd fields"},
    ]
  },
  "file type": {
    "display": "File type",
    "description": "File type, usually generated automatically",
    "type": "list",
    "options": [
      {label: "Text File", value: "text/plain", key: "text/plain"},
      {label: "Castus Playlist", value: "application/x-castus-playlist", key: "application/x-castus-playlist"},
      {label: "Daily Schedule", value: "application/x-castus-daily-schedule", key: "application/x-castus-daily-schedule"},
      {label: "Weekly Schedule", value: "application/x-castus-weekly-schedule", key: "application/x-castus-weekly-schedule"},
      {label: "Monthly Schedule", value: "application/x-castus-monthly-schedule", key: "application/x-castus-monthly-schedule"},
      {label: "Yearly Schedule", value: "application/x-castus-yearly-schedule", key: "application/x-castus-yearly-schedule"},
      {label: "Spark Media Xml Schedule", value: "application/x-spark-media-xml-schedule", key: "application/x-spark-media-xml-schedule"},
      {label: "Castus Cuts Project", value: "application/x-castus-multiregion-playlist", key: "application/x-castus-multiregion-playlist"},
      {label: "Scrolling Text", value: "application/x-castus-scrolling-text", key: "application/x-castus-scrolling-text"},
      {label: "Metadata Template", value: "application/x-castus-metadata-template", key: "application/x-castus-metadata-template"},
      {label: "Text Item", value: "application/vnd.tv.castus.text-item", key: "application/vnd.tv.castus.text-item"},
      {label: "Video, MPEG-1", value: "video/x-mpeg-video-m1v", key: "video/x-mpeg-video-m1v"},
      {label: "Video, MPEG-2", value: "video/x-mpeg-video-m2v", key: "video/x-mpeg-video-m2v"},
      {label: "Video, Quicktime", value: "video/quicktime", key: "video/quicktime"},
      {label: "Video, FLV", value: "video/x-flv", key: "video/x-flv"},
      {label: "Video, MPEG", value: "video/mpeg", key: "video/mpeg"},
      {label: "Video, AVI", value: "video/avi", key: "video/avi"},
      {label: "Image, GIF", value: "image/gif", key: "image/gif"},
      {label: "Image, PNG", value: "image/png", key: "image/png"},
      {label: "Image, TIFF", value: "image/tiff", key: "image/tiff"},
      {label: "Image, JPEG", value: "image/jpeg", key: "image/jpeg"},
      {label: "Image, WebP", value: "image/webp", key: "image/webp"},
      {label: "Image, HEIF", value: "image/heif", key: "image/heif"},
      {label: "Image, AVIF", value: "image/avif", key: "image/avif"},
      {label: "Audio, HLS", value: "audio/x-mpegurl", key: "audio/x-mpegurl"},
      {label: "Audio, PLS", value: "audio/x-scpls", key: "audio/x-scpls"},
      {label: "Audio, MPEG", value: "audio/mpeg", key: "audio/mpeg"},
      {label: "Audio, WAV", value: "audio/wav", key: "audio/wav"},
      {label: "Audio, AC3", value: "audio/ac3", key: "audio/ac3"},
      {label: "Audio, AAC", value: "audio/aac", key: "audio/aac"}
    ]
  },
  "guid": {
    "display": "Guid",
    "description": "This is an internal system field for reference"
  },
  "hd": {
    "display": "High definition",
    "description": "Indicates high definition resolution (720p, etc)",
    "type": "select",
    "options": [
      {text: "(not set)", value: "", key: "" },
      {text: "Minimally high definition", value: "1", key: "1" },
      {text: "At least 720p", value: "720", key: "720" },
      {text: "At least 1080i/p", value: "1080", key: "1080" },
      {text: "At least 4K", value: "2160", key: "2160" }
    ]
  },
  "in": {
    "display": "In",
    "description": "The start time in the media item to skip to in seconds.",
    "type": "duration"
  },
  "inner crop": {
    "display": "Inner crop",
    "description": "A percentage of scaling for the video 0-no zoom, 100-fully zoom at the expense of losing outer parts of he video.",
    "type": "number",
    "min": 0,
    "max": 100
  },
  "offline": {
    "display": "Offline",
    "description": "If on (nonzero), Castus will not start the media item",
    "type": "boolean"
  },
  "order": {
    "display": "Order (of playback)",
    "description": "Specifies the play order of items in this media item",
    "type": "select",
    "options": [
      {key: 'default', text: 'Default', value: ''},
      {key: 'lexicographical', text: 'Lexicographical order (0 to 9, A to Z)', value: 'lexicographical order' },
      {key: 'random', text: 'Random', value: 'random' }
    ]
  },
  "out": {
    "display": "Out",
    "description": "The end time to truncate the media item",
    "type": "duration"
  },
  "overlay item": {
    "display": "Overlay item",
    "description": "Media item to overlay on top while playing this item (for use with Folder items only). This is the full path of the item."
  },
  "producer": {
    "display": "Producer",
    "description": "Name of the producer for the program"
  },
  "program": {
    "display": "Program",
    "description": "A free form generic program setting."
  },
  "program language": {
    "display": "Program language",
    "description": "The primary language of the program (ISO 639-2 code)",
    "type": "list",
    "options": [
      {label: "English", value: "eng", key: "eng" },
      {label: "French", value: "fre", key: "fre" },
      {label: "German", value: "ger", key: "ger" }
    ]
  },
  "rating": {
    "display": "Rating",
    "description": "The rating to display a logo in the video.",
    "type": "list",
    "options": [
      /* United States, television */
      {label:'United States: TY-Y (child-oriented, all ages)', value: 'united states/TV-Y', key: 'united states/TV-Y'},
      {label:'United States: TY-Y7 (child-oriented, 7 or older)', value: 'united states/TV-Y7', key: 'united states/TV-Y7'},
      {label:'United States: TY-Y7 FV (child-oriented, 7+, fantasy violence)',	value: 'united states/TV-Y7 FV', key: 'united states/TV-Y7 FV'},
      {label:'United States: TV-G (suitable for all ages)', value: 'united states/TV-G', key: 'united states/TV-G'},
      {label:'United States: TV-PG (parental guidance recommended)', value: 'united states/TV-PG', key: 'united states/TV-PG'},
      {label:'United States: TV-14 (unsuitable for children under 14)', value: 'united states/TV-14', key: 'united states/TV-14'},
      {label:'United States: TV-MA (for adults, searchable for under 17)',	value: 'united states/TV-MA', key: 'united states/TV-MA'},
      /* United States, MPAA */
      {label:'United States (MPAA): G (General Audiences)', value: 'united states/MPAA-G', key: 'united states/MPAA-G'},
      {label:'United States (MPAA): PG (Parental Guidance Suggested)', value: 'united states/MPAA-PG', key: 'united states/MPAA-PG'},
      {label:'United States (MPAA): PG-13 (Parents Strongly Cautioned)',	value: 'united states/MPAA-PG-13', key: 'united states/MPAA-PG-13'},
      {label:'United States (MPAA): R (Restricted)', value: 'united states/MPAA-R', key: 'united states/MPAA-R'},
      {label:'United States (MPAA): NC-17 (No One 17 & Under Admitted)',	value: 'united states/MPAA-NC-17', key: 'united states/MPAA-NC-17'},
      /* Australia, standard ratings */
      {label:'Australia: TV-P (Preschoolers)', value: 'australia/TV-P', key: 'australia/TV-P'},
      {label:'Australia: TV-C (Children)', value: 'australia/TV-C', key: 'australia/TV-C'},
      {label:'Australia: TV-G (General)', value: 'australia/TV-G', key: 'australia/TV-G'},
      {label:'Australia: TV-PG (Parental Guidance Recommended)', value: 'australia/TV-PG', key: 'australia/TV-PG'},
      {label:'Australia: TV-M (Mature)', value: 'australia/TV-M', key: 'australia/TV-M'},
      {label:'Australia: TV-MA15+ (Mature Accompanied)', value: 'australia/TV-MA15+', key: 'australia/TV-MA15+'},
      {label:'Australia: TV-AV15+ (Mature Accompanied, Adult Violence)',	value: 'australia/TV-AV15+', key: 'australia/TV-AV15+'},
      {label:'Australia: TV-R18+ (Restricted)', value: 'australia/TV-R18+', key: 'australia/TV-R18+'}
    ]
  },
  "recommended minimum age": {
    "display": "Recommended minimum age",
    "description": "A recommendation for the minimum age of the viewer in years"
  },
  "schedule announce": {
    "display": "Schedule announce",
    "description": "Whether or not to announce this item when scheduled",
    "type": "select",
    "options": [
      {key: "default", text: "Default (if video or audio)", value: ""},
      {key: "never", text: "Never", value: "0"},
      {key: "always", text: "Always", value: "1"}
    ]
  },
  "sd": {
    "display": "Standard definition",
    "description": "Indicates standard definition resolution (480p, etc)",
    "type": "select",
    "options": [
      {text: "(not set)", value: "", key: "" },
      {text: "Minimally standard definition", value: "1", key: "1" },
      {text: "At least 360i/p", value: "360", key: "360" },
      {text: "At least 480i/p", value: "480", key: "480" },
      {text: "At least 576i/p", value: "576", key: "576" }
    ]
  },
  "search tags": {
    "display": "Search tags",
    "description": "A list of additional words, separated by spaces, that playout and VOD users can use to search for content"
  },
  "season": {
    "display": "Season",
    "description": "For television episodes, the season this program belongs to"
  },
  "sort order": {
    "display": "Sort order",
    "description": "A numeric value that controls where the item appears when sorted in the Video On Demand widget. Higher numbers appear first."
  },
  "stretch to fit": {
    "display": "Stretch to fit",
    "description": "Whether or not to distort the picture by stretching it to fit",
    "type": "select",
    "options": [
      {key: "default", text: "Default", value: ""},
      {key: "never", text: "Never", value: "0"},
      {key: "always", text: "Always", value: "1"}
    ]
  },
  "summary": {
    "display": "Summary",
    "description": "A free form generic Summary setting."
  },
  "title": {
    "display": "Title",
    "description": "A free form generic Title setting."
  },
  "upload time": {
    "display": "Upload time",
    "description": "The upload time of the media item, usually generated automatically."
  }
}
