import {fetchFromServer} from 'helpers/net_helpers'

export const REFRESH_ALERTS = Symbol('refresh alerts')

export const refresh = () => {
    return async (dispatch) => {
        let ret = await refreshBackend()

        dispatch({
            type: REFRESH_ALERTS,
            payload: {
                ret: [...ret]
            }
        })
    }
}

export const initialState = {
    alertQueue: []
}

let createDateTimeString = (str) => {
    let month = str.substr(0, 2)
    let day = str.substr(3, 2)
    let year = str.substr(6, 4)
    let hour = str.substr(11, 2)
    let min = str.substr(14, 2)
    let sec = str.substr(17, 2)

    return `${month}/${day}/${year} ${hour}:${min}:${sec}`
}

let refreshBackend = async () => {
    const date = new Date();
    const formattedDateF = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const formattedDateL = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 2}`;

    let fetch = `/v2/other/search-library?eventf=${formattedDateF}&eventl=${formattedDateL}`

    console.log(`Fetching from ${fetch}`)

    let res = await fetchFromServer(fetch,
    {
        method: 'GET'
    })

    if(!res.ok)
        console.log('Failed to fetch alerts from server!')

    let out = ""
    for await (const chunk of res.body) {
        for (const num of chunk) {
            out += String.fromCharCode(num)
        }
    }

    console.log(out)

    let arr = []
    let data = JSON.parse(out)

    // Loop through data and pick out what is needed
    for (const key in data["content"]) {
        let newDateTime = createDateTimeString(data["content"][key].time)

        arr.push({
            text: data["content"][key].name,
            path: data["content"][key].path,
            action: data["content"][key].type,
            time: newDateTime
        })
    }

    return arr
}

export default (state = initialState, action) => {
    let { type, payload } = action

    switch (type) {
        case REFRESH_ALERTS:
            return {
                alertQueue: payload.ret
            }
        default:
            return state
    }
}
