import Upload from 'containers/applications/Upload'
import Schedule from 'containers/applications/Schedule'
import PlaylistEditor from 'containers/applications/PlaylistEditor'
import Library from 'containers/applications/Library'
import Settings from 'containers/applications/Settings'
import About from 'containers/applications/About'
import GlobalMedia from 'containers/applications/GlobalMedia';
import GlobalMusic from 'containers/applications/GlobalMusic';
import ScrollingText from 'containers/applications/ScrollingText';
import RSSItemEditor from 'containers/applications/RSSItemEditor';
import MetadataEditor from 'containers/applications/MetadataEditor';
import TemplateEditor from 'containers/applications/TemplateEditor';
import SimplePlaylistEditor from 'containers/applications/SimpleListEditor';
import UserList from 'containers/applications/UserList';
import Reporting from 'containers/applications/Reporting';
import CloudServices from 'containers/applications/CloudServices';
//import Preferences from 'containers/applications/Preferences';
import Alerts from 'containers/applications/Alerts'
//import EmbeddedScheduleEditor from 'containers/applications/EmbeddedScheduleEditor';

// BEWARE: If you change the names of applications, you may also need to update the application values
// for file types in src/helpers/library_helpers.js
export default {
    Upload: {
      name: 'Upload',
      link: '/upload',
      icon: 'upload',
      path: "/upload",
      component: Upload
    },
    Schedule: {
      name: 'Schedule',
      link: '/schedule',
      icon: 'schedule',
      path: "/schedule/:path*",
      component: Schedule,
      reducer: 'schedule'
    },
    Library: {
      name: 'Library',
      link: '/library',
      icon: 'library',
      path: "/library/:path*",
      component: Library,
      reducer: 'library'
    },
    Settings: {
      name: 'Services',
      link: '/services',
      icon: 'settings',
      path: "/services/:path*",
      component: Settings
    },
    Playlist: {
      name: 'CASTUS Cuts',
      link: '/playlist',
      icon: 'castus_cuts',
      path: "/playlist/:path*",
      component: PlaylistEditor,
      reducer: 'playlist_editor'
    },
    About: {
      name: 'About',
      link: '/about',
      icon: 'about',
      path: "/about",
      component: About
    },
    MetadataEditor: {
      name: 'Metadata Editor',
      link: '/metadata',
      icon: 'metadataeditor',
      path: "/metadata/:path*",
      component: MetadataEditor,
      reducer: 'metadata_editor'
    },
    MetadataTemplate: {
      name: 'Metadata Template',
      link: '/template',
      icon: 'metadatatemplate',
      path: "/template/:path*",
      component: TemplateEditor,
      reducer: 'template_editor'
    },
    GlobalMedia: {
      name: 'Global Media',
      link: '/global_media',
      icon: 'globalmedia',
      path: "/global_media",
      component: GlobalMedia
    },
    GlobalMusic: {
      name: 'Background Music',
      link: '/global_music',
      icon: 'globalmusic',
      path: "/global_music",
      component: GlobalMusic
    },
    ScrollingText: {
      name: 'Scrolling Text',
      link: '/scrolling_text',
      icon: 'scrollingtext',
      path: "/scrolling_text/:path*",
      component: ScrollingText,
      reducer: 'scrolling_text'
    },
    RSSItemEditor: {
      name: 'RSS Item Editor',
      link: '/rss_item_editor',
      icon: 'rssfeed',
      path: "/rss_item_editor/:path*",
      component: RSSItemEditor,
      reducer: 'rss_item_editor'
    },
    SimplePlaylist: {
      name: 'Simple Playlist',
      link: '/simple_list',
      icon: 'simpleplaylist',
      path: '/simple_list/:path*',
      component: SimplePlaylistEditor,
      reducer: 'simple_playlist_editor'
    },
    Reporting: {
      name: 'Reporting',
      link: '/reporting',
      icon: 'chart bar',
      path: '/reporting',
      component: Reporting,
      noSvg: true
    },
    /*Preferences: {
      name: 'Preferences',
      link: '/preferences',
      icon: 'edit',
      path: '/preferences',
      component: Preferences,
      noSvg: true
    },*/
    UserList: {
      name: 'User List',
      link: '/user_list',
      icon: 'user',
      path: '/user_list',
      component: UserList,
      noSvg: true
    },
    /*
    Support: {
      name: "Wizards",
      link: "/support",
      icon: "wizard_hat",
      path: "/support",
      component: MainSupportPage
    },
    */
    CloudServices: {
      name: 'Cloud Services',
      link: '/cloud',
      icon: 'cloud',
      path: '/cloud',
      component: CloudServices,
      noSvg: true
    },
    Alerts: {
      name: 'Alerts',
      link: '/alerts',
      icon: 'alerts_bell_app',
      path: '/alerts',
      component: Alerts
    }
    /*
    EmbeddedSchedule: {
      name: 'Embedded Schedule',
      link: '/embed_sched',
      icon: 'code',
      path: '/embed_sched',
      component: EmbeddedScheduleEditor,
      noSvg: true
    }
    */
}
