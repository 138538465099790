import React, { PureComponent } from 'react';
import { Menu, Dropdown, Icon, Label } from 'semantic-ui-react';
import ScheduleDayReplicateModal from './ScheduleDayReplicateModal'

import './ScheduleButtonPanel.css';

class ScheduleButtonPanel extends PureComponent {

  handleExport = async (type) => {
    let {confirmAsync, unsaved, saveScheduleData, exportSchedule} = this.props
    if(unsaved) {
      let res = await confirmAsync("The schedule needs to be saved before it can be exported. Save the schedule?")
      if(res) {
        await saveScheduleData();
        return exportSchedule(type)
      }
    } else {
      return exportSchedule(type)
    }
  }

  render() {
    let { hasErrors,
      addScheduleItems,
      pasteClipboardItems,
      scrollToTime,
      scrollToError,
      selectNone,
      selectAll,
      clipboard,
      unselected,
      shouldUndo,
      shouldRedo,
      undoScheduleChange,
      redoScheduleChange,
      revertSchedule,
      clearScheduleDay,
      clearScheduleAll,
      clearMissingFiles,
      saveScheduleData,
      replicateDay,
      unsaved,
      type,
      viewTime,
      intervalBasis,
      intervalDuration} = this.props;

    return (
      <div>
        <Menu fluid size='tiny' icon='labeled' className='scheduleEditorButtonMenu' widths={10}>
          {unselected ?
            (<Menu.Item onClick={selectAll} className='scheduleEditorButton' size='tiny' title='Select All Items in Current Day'>
              <Icon name='square outline' />
              Select
            </Menu.Item>) :
            (<Menu.Item onClick={selectNone} className='scheduleEditorButton' size='tiny' title='Deselect All Items'>
              <Icon name='check square' />
              Deselect
            </Menu.Item>)
          }
          <Menu.Item onClick={undoScheduleChange} className='scheduleEditorButton' size='tiny' disabled={!shouldUndo} title='Undo'>
            <Icon name='undo' />
            Undo
          </Menu.Item>
          <Menu.Item onClick={redoScheduleChange} className='scheduleEditorButton' size='tiny' disabled={!shouldRedo} title='Redo'>
            <Icon name='repeat' />
            Redo
          </Menu.Item>
          <Menu.Item onClick={revertSchedule} className='scheduleEditorButton' size='tiny' disabled={!unsaved} title='Revert Unsaved Changes'>
            <Icon name='refresh' />
            Revert
          </Menu.Item>
          <Dropdown trigger={
              <Menu.Item size='tiny' fitted className='scheduleMenuDropdown'>
                <Icon name='add'/>
                Add
              </Menu.Item>
            }
            item
            icon={null}
            title='Add New'>
            <Dropdown.Menu>
              <Dropdown.Item text='Add To Times' icon='add' onClick={addScheduleItems} />
              <Dropdown.Item text='Paste To Times' icon='paste' onClick={pasteClipboardItems} disabled={!clipboard.length}/>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown trigger={
              <Menu.Item size='tiny' fitted className='scheduleMenuDropdown'>
                <Icon name='trash'/>
                Clear
              </Menu.Item>
            }
            item
            icon={null}
            title='Clear Items'>
            <Dropdown.Menu>
              <Dropdown.Item text='Missing Files' icon='remove' onClick={clearMissingFiles} />
              <Dropdown.Item text='Current Day' icon='sun' onClick={clearScheduleDay} />
              <Dropdown.Item text='Whole Schedule' icon='calendar' onClick={clearScheduleAll} />
            </Dropdown.Menu>
          </Dropdown>
          <ScheduleDayReplicateModal trigger={
            <Menu.Item size='tiny' fitted className='scheduleMenuDropdown' disabled={type === "daily"}>
              <Icon name='copy'/>
              Replicate Day
            </Menu.Item>
          }
          type={type}
          viewTime={viewTime}
          intervalBasis={intervalBasis}
          intervalDuration={intervalDuration}
          replicateDay={replicateDay}/>
          <Menu.Item onClick={scrollToTime} className='scheduleEditorButton' size='tiny' title='Scroll to Time'>
            <Icon name='clock' />
            Scroll to
          </Menu.Item>
          <Menu.Item onClick={scrollToError}
            className='scheduleEditorButton'
            size='tiny'
            disabled={!hasErrors}
            title='Scroll to Error'>
            <Icon name='warning sign' />
            Errors
            <Label size='mini' attached='top right' color={hasErrors ? 'red' : null}>{hasErrors}</Label>
          </Menu.Item>
          <Dropdown trigger={
              <Menu.Item className='scheduleMenuDropdown' fitted size='tiny'>
               <Icon name='save' />
               Save
              </Menu.Item>
            }
            item
            icon={null}
            title='Save Schedule'>
            <Dropdown.Menu direction='left'>
              <Dropdown.Item text='Save' icon='save' onClick={() => {saveScheduleData()}} />
              <Dropdown.Item text='Save As' icon='pencil' onClick={() => {saveScheduleData(true)}} />
              <Dropdown.Item text='Export As XML' onClick={() => {this.handleExport("xml")}} />
              <Dropdown.Item text='Export As HTML' onClick={() => {this.handleExport("html")}} />
              <Dropdown.Item text='Export As JSON' onClick={() => {this.handleExport("json")}} />
              <Dropdown.Item text='Export As Gracenote' onClick={() => {this.handleExport("gracenote")}} />
              <Dropdown.Item text='Export As Rovi' onClick={() => {this.handleExport("rovi")}} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu>
      </div>
    );
  }
}

export default ScheduleButtonPanel;
