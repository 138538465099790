import React, {useState} from 'react'
import {Modal, Form, Button} from 'semantic-ui-react'
import RequestFormField from './RequestFormField'

function RequestForm(props) {
  let {form={},
    open,
    closeModal,
    upload} = props

  const [formData, setFormData] = useState({})

  let fields = []
  if(form.fields) {
    for(let field of form.fields) {
      let value = formData[field.name]
      if(field.type === "group") {
        value = formData
      }
      fields.push(<RequestFormField {...field} key={field.name} value={value} onChange={(name, value) => {
        if(!value) {
          let data = {...formData}
          delete data[name]
          setFormData(data)
        } else {
          setFormData({...formData, [name]: value})}
        }
      }/>)
    }
  }

  const verifyFormFilled = (fields) => {
    if(!fields) {
      return true
    }
    for(let field of fields) {
      if(field.type === "group" && !verifyFormFilled(field.fields)) {
        return false
      } else if(field.required) {
        if(!formData[field.name]) {
          return false
        }
        if(field.type === "select_multiple") {
          let opts = formData[field.name]
          let atLeastOne = false
          for(let value of Object.values(opts)) {
            if(value) {
              atLeastOne = true
              break
            }
          }
          if(!atLeastOne) {
            return false
          }
        }
      }
    }
    return true
  }

  const submitForm = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if(!verifyFormFilled(form.fields)) {
      return
    }
    upload({formData})
    closeModal()
    setFormData({})
  }

  return (
    <Modal open={open} closeOnDimmerClick={false}>
      <Modal.Header>{form.title || "Playback Request Form"}</Modal.Header>
      <Modal.Content scrolling>
        <Form>
          {fields}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button positive disabled={!verifyFormFilled(form.fields)} onClick={submitForm}>Upload</Button>
        <Button onClick={closeModal}>Cancel</Button>
      </Modal.Actions>
    </Modal>
  )
}

export default RequestForm
