import {fetchFromServer, connectToServerSocket} from 'helpers/net_helpers'
import {getIn, setIn} from 'helpers/general_helpers'
import {messages} from 'redux/messages'
import moment from 'moment-timezone'

import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'

export const EVENT_MODAL = Symbol('open save modal')
export const CLOSE_EVENT_MODAL = Symbol('close save modal')
export const EVENT_MODAL_TYPE = Symbol('event modal type')
export const OPEN_ERROR_TEXT = Symbol('open error text')
export const CLOSE_ERROR_TEXT = Symbol('close error text')
export const EVENT_NAME_VALUE = Symbol('event name value')
export const EVENT_START_DATE_VALUE = Symbol('event start date value')
export const EVENT_START_TIME_VALUE = Symbol('event start time value')
export const EVENT_FINISH_DATE_VALUE = Symbol('event finish date value')
export const EVENT_FINISH_TIME_VALUE = Symbol('event finish time value')
export const EVENT_REPEAT_VALUE = Symbol('event repeat value')
export const ADD_NEW_EVENT = Symbol('set new event')

const SET_CURRENT_INPUT = Symbol('set current input');
const SET_RECORDING_SOCKET = Symbol('set recording socket');
const UPDATE_RECORDING_STATUS = Symbol('update recording status');
const UPDATE_ASSOCIATIONS = Symbol('update associations');
const UPDATE_EVENTS = Symbol('update events');
const SET_RECORDING_PREVIEW = Symbol('set recording preview');
const SELECT_ASSOCIATION = Symbol('select association');

export const actions = {

    setCurrentInput: (inputName) => {
      return async (dispatch, getState) => {
        let {currentInput, serverSocket} = getState().input_recording
        // If we are currently watching updates from an input, unsubscribe from those updates
        if(currentInput && serverSocket) {
          serverSocket.emit('stop', currentInput);
        }
        dispatch({
          type: SET_CURRENT_INPUT,
          payload: inputName
        })
      }
    },

    connectToRecordingSocket: () => {
      return async (dispatch, getState) => {
        if(getState().input_recording.serverSocket) {
          return
        }
        var connection = connectToServerSocket('/recording');
        if(!connection) {
          return
        }
        connection.on("ready", () => {
          dispatch({
            type: SET_RECORDING_SOCKET,
            payload: connection
          })
        })

        connection.on("update", (name, data) => {
          if(data.thumbnail) {
            if(data.thumbnail instanceof ArrayBuffer) {
              dispatch({
                type: SET_RECORDING_PREVIEW,
                payload: {
                  name,
                  data: data.thumbnail
                }
              })
            }
            delete data.thumbnail
          }

          /* common live caption status */
          data.liveCaption = {
                  liveCaptionConfigured: false,
                  liveCaptionEnabled: false,
                  liveCaptionRunning: null,
                  liveCaptionRunningUF: null,
                  liveCaptionIsRunning: false,
                  liveCaptionButtonStart: false,
                  liveCaptionRuntime: -1,
                  liveCaptionBytesSent: -1
          }

          let value
          let asvalue

          value = data['live caption']
          if (value === "not configured" || value === "" || value === undefined) {
          }
          else {
                  data.liveCaption.liveCaptionConfigured = true
                  if (value === "not enabled") {
                  }
                  else {
                          data.liveCaption.liveCaptionEnabled = true;
                          data.liveCaption.liveCaptionRunning = value

                          if (value === "stopped") {
                                  data.liveCaption.liveCaptionRunningUF = "Live captioning not running";
                                  data.liveCaption.liveCaptionButtonStart = true;
                          }
                          else if (value === "starting") {
                                  data.liveCaption.liveCaptionRunningUF = "Starting...";
                                  data.liveCaption.liveCaptionIsRunning = true;
                          }
                          else if (value === "stopping") {
                                  data.liveCaption.liveCaptionRunningUF = "Stopping...";
                                  data.liveCaption.liveCaptionIsRunning = true;
                                  data.liveCaption.liveCaptionButtonStart = true;
                          }
                          else if (value === "running") {
                                  data.liveCaption.liveCaptionRunningUF = "Live captioning in progress";
                                  data.liveCaption.liveCaptionIsRunning = true;
                          }

                          asvalue = data['live caption active status']
                          if (asvalue !== undefined && asvalue !== "") {
                                  let seta = asvalue.split(',');
                                  let p,aname,avalue,ai,ei;

                                  for (ai=0;ai < seta.length;ai++) {
                                          p = seta[ai];
                                          ei = p.indexOf('=');
                                          if (ei > 0) {
                                                  aname = p.substr(0,ei);
                                                  avalue = p.substr(ei+1);

                                                  if (aname === "rectm")
                                                          data.liveCaption.liveCaptionRuntime = parseFloat(avalue);
                                                  else if (aname === "ebc")
                                                          data.liveCaption.liveCaptionBytesSent = parseInt(avalue);
                                          }
                                  }
                          }
                  }
          }

          dispatch({
            type: UPDATE_RECORDING_STATUS,
            payload: {
              name,
              data
            }
          })
        })
      }
    },

    pollRecordingStatus: () => {
      return (dispatch, getState) => {
        let {currentInput, serverSocket} = getState().input_recording
        if(!serverSocket) {
          dispatch(actions.connectToRecordingSocket())
          return
        }
        serverSocket.emit('watch', currentInput)
      }
    },

    startLiveCaptions: () => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().input_recording
        let res = await fetchFromServer(`/v2/recording/startlivecap/${currentInput}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error starting the recording: ${text}`, {level: 'error'}))
        }
      }
    },

    stopLiveCaptions: () => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().input_recording
        let res = await fetchFromServer(`/v2/recording/stoplivecap/${currentInput}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error stopping the recording: ${text}`, {level: 'error'}))
        }
      }
    },

    startRecording: () => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().input_recording
        let res = await fetchFromServer(`/v2/recording/start/${currentInput}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error starting the recording: ${text}`, {level: 'error'}))
        }
      }
    },

    stopRecording: () => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().input_recording
        let res = await fetchFromServer(`/v2/recording/stop/${currentInput}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error stopping the recording: ${text}`, {level: 'error'}))
        }
      }
    },

    renameRecording: (newName) => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().input_recording
        let res = await fetchFromServer(`/v2/recording/rename/${currentInput}`, {
          method: 'POST',
          body: `${newName}`
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error renaming the recording: ${text}`, {level: 'error'}))
        }
      }
    },

    goLive: (channelNumber) => {
      return async (dispatch, getState) => {
        let {currentInput} = getState().input_recording
        let res = await fetchFromServer(`/v2/recording/live/start/${currentInput}?channel=${channelNumber}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error going live on Channel ${channelNumber}: ${text}`, {level: 'error'}))
        }
      }
    },

    endLive: (channelNumber) => {
      return async (dispatch, getState) => {
        let res = await fetchFromServer(`/v2/recording/live/stop?channel=${channelNumber}`, {
          method: 'POST'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error stopping live on Channel ${channelNumber}: ${text}`, {level: 'error'}))
        }
      }
    },

    fetchAssociations: (input=null) => {
      return async (dispatch, getState) => {
        if(!input) {
          input = getState().input_recording.currentInput
        }
        let res = await fetchFromServer(`/v2/recording/associations/${input}`)
        if(!res.ok) {
          if(res.status === 404) {
            return
          } else {
            let text = await res.text()
            dispatch(messages.alert(`There was an error fetching the input associations: ${text}`, {level: 'error'}))
          }
        } else {
          let associations = await res.json()
          dispatch({
            type: UPDATE_ASSOCIATIONS,
            payload: {
              name: input,
              data: associations
            }
          })
        }
      }
    },

    selectAssociation: (value) => ({
      type: SELECT_ASSOCIATION,
      payload: value
    }),

    applyAssociation: (association=null, input=null) => {
      return async (dispatch, getState) => {
        if(!input) {
          input = getState().input_recording.currentInput
        }
        if(!association) {
          if(getState().input_recording.selectedAssociation) {
            association = getState().input_recording.selectedAssociation
          } else {
            return
          }
        }
        let res = await fetchFromServer(`/v2/recording/associations/${input}`, {
          method: 'POST',
          body: `${association}`
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error applying the input association: ${text}`, {level: 'error'}))
        }
      }
    },

    fetchEvents: () => {
      return async (dispatch, getState) => {
        let jobId = dispatch(loading.startLoading(getState().input_recording._loaderID))
        let res = await fetchFromServer(`/v2/recording/events`)
        if(!res.ok) {
          let error = 'Unknown Error'
          if(res.headers.get("Content-Type").startsWith("application/json")) {
            let errObject = await res.json();
            error = errObject.stderr;
          } else {
            error = await res.text();
          }
          let errmsg = `There was an error fetching the recording events: ${error}. (Make sure that the "Timer record service" under the System Services tab of the Services app is running.)`
          console.log(errmsg)
          let events = [
                {
                        id: 999999999,
                        busy: 0,
                        enable: 1,
                        repeat: "never",
                        errorMessage: true,
                        "recording name": errmsg,
                        input: getState().input_recording.currentInput,
                        "start": moment.unix("1646182189"),
                        "end": moment.unix("1646182189")
                }
          ]
          dispatch({
            type: UPDATE_EVENTS,
            payload: events
          })
        } else {
          let events = await res.json()
          // Convert event start and end times from string format to moments
          events = Object.entries(events).map(([id, evnt]) => {
            let {start, end} = evnt
            start = start.split(' ')[0].slice(1)
            end = end.split(' ')[0].slice(1)
            start = moment.unix(parseInt(start, 10))
            end = moment.unix(parseInt(end, 10))
            return {
              ...evnt,
              id: parseInt(id, 10),
              start,
              end
            }
          })
          dispatch({
            type: UPDATE_EVENTS,
            payload: events
          })
        }
        dispatch(loading.finishLoading(getState().input_recording._loaderID, jobId))
      }
    },

    /**
     * Open save Modal
     */
    eventModalOpen: () => (dispatch) =>{
        dispatch({
            type: EVENT_MODAL,
        })
        dispatch({
            type: EVENT_MODAL_TYPE,
            payload: {
              type: 'add',
              index: -1
            }
        });
    },

    /**
     * Close Save Modal
     */
    eventModalClose: () => (dispatch) => {
        dispatch({
            type: CLOSE_EVENT_MODAL,
        })
    },

    /**
     * Opens the event editing modal
     */
    editEventModal: (event) => (dispatch, getState) => {
        dispatch({
            type: EVENT_MODAL,
        });
        dispatch({
            type: EVENT_MODAL_TYPE,
            payload: {
              type: 'edit',
              index: event.id
            }
        });
        dispatch(actions.newEventName(event['recording name']));
        dispatch(actions.newEventStartDate(event.start.format('MM/DD/YYYY')));
        dispatch(actions.newEventStartTime(event.start.format('hh:mm:ss A')));
        dispatch(actions.newEventFinishDate(event.end.format('MM/DD/YYYY')));
        dispatch(actions.newEventFinishTime(event.end.format('hh:mm:ss A')));
        dispatch(actions.newEventRepeat(event.repeat));
    },
    /**
     * Opens modal to confirm event deletion
     */
    deleteEventModal: (event) => (dispatch, getState) => {
        dispatch({
            type: EVENT_MODAL,
        });
        dispatch({
            type: EVENT_MODAL_TYPE,
            payload: {
              type: 'delete',
              index: event.id
            }
        });
    },
    /**
     * Delete an event
     */
    deleteEvent: (id) => async (dispatch, getState) => {
        let res = await fetchFromServer(`/v2/recording/events/${id}`, {
          method: 'DELETE'
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error deleting the event: ${text}.
          (Make sure that the "Timer record service" under the System Services tab of the Services app is running.)`, {level: 'error'}))
        } else {
          dispatch(actions.fetchEvents())
        }
    },
    /**
     * Add a New Event
     */
    addNewEvent: (eventData) => {
      return async (dispatch, getState) => {
        let {input_recording} = getState()
        let {name, startDate, endDate, repeat} = eventData
        // I assume selectedMediaItem is selectedFiles[0]
        // Convert to moment for now, moment will be removed later
        if(!moment.isMoment(startDate)) {
          startDate = moment(startDate)
        }
        if(!moment.isMoment(endDate)) {
          endDate = moment(endDate)
        }
        let startString = `${startDate.format("MM/DD/YYYY")}, ${startDate.format("HH:mm:ss")}`
        let endString = `${endDate.format("MM/DD/YYYY")}, ${endDate.format("HH:mm:ss")}`
        let newEvent = {
          'recording name': name,
          input: input_recording.currentInput,
          start: moment(startString, 'MM/DD/YYYY, hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss'),
          end: moment(endString, 'MM/DD/YYYY, hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss'),
          repeat: repeat,
        }
        dispatch({
            type: CLOSE_EVENT_MODAL,
        })
        let jobId = dispatch(loading.startLoading(getState().input_recording._loaderID))
        let res = await fetchFromServer('/v2/recording/events', {
          method: 'PUT',
          body: JSON.stringify(newEvent),
          headers: {
            'Content-Type': 'application/json'
          }
        })
        if(!res.ok) {
          let text = await res.text()
          dispatch(messages.alert(`There was an error adding the event: ${text}.
          (Make sure that the "Timer record service" under the System Services tab of the Services app is running.)`, {level: 'error'}))
        } else {
          dispatch(actions.fetchEvents())
        }
        dispatch(loading.finishLoading(getState().input_recording._loaderID, jobId))
      }
    },

    /**
     * Modifies a pre-existing event
     * @param {number} id The id of the event to modify
     */
    editEvent: (id, field, value) => async (dispatch, getState) => {
      let {
          input_recording
      } = getState()
      /*
      if (!input_recording.newEventNameValue ||
        !input_recording.newEventStartDate ||
        !input_recording.newEventStartTime ||
        !input_recording.newEventFinishDate ||
        !input_recording.newEventFinishTime ||
        !input_recording.newEventRepeat) {
          dispatch({
              type: OPEN_ERROR_TEXT
          })
          return
      }
      let startString = `${input_recording.newEventStartDate}, ${input_recording.newEventStartTime}`
      let endString = `${input_recording.newEventFinishDate}, ${input_recording.newEventFinishTime}`
      let editedEvent = {
        'recording name': input_recording.newEventNameValue,
        input: input_recording.currentInput,
        start: moment(startString, 'MM/DD/YYYY, hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss'),
        end: moment(endString, 'MM/DD/YYYY, hh:mm:ss A').format('YYYY-MM-DD HH:mm:ss'),
        repeat: input_recording.newEventRepeat,
      }
      dispatch({
          type: CLOSE_EVENT_MODAL,
      })
      */
      let toEdit = input_recording.events.find((evnt) => evnt.id === id)
      if(!toEdit) {
        dispatch(messages.alert(`Tried to edit the recording event with id ${id}, but no such event was found.`, {level: "error"}))
        return
      }
      let editedEvent = {...toEdit, [field]: value}
      // Convert to moment for now, moment will be removed later
      if(!moment.isMoment(editedEvent.start)) {
        editedEvent.start = moment(editedEvent.start)
      }
      if(!moment.isMoment(editedEvent.end)) {
        editedEvent.end = moment(editedEvent.end)
      }
      editedEvent.start = editedEvent.start.format('YYYY-MM-DD HH:mm:ss')
      editedEvent.end = editedEvent.end.format('YYYY-MM-DD HH:mm:ss')
      let jobId = dispatch(loading.startLoading(getState().input_recording._loaderID))
      let res = await fetchFromServer(`/v2/recording/events/${id}`, {
        method: 'POST',
        body: JSON.stringify(editedEvent),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if(!res.ok) {
        let text = await res.text()
        dispatch(messages.alert(`There was an error editing the event: ${text}.
        (Make sure that the "Timer record service" under the System Services tab of the Services app is running.)`, {level: 'error'}))
      }
      dispatch(loading.finishLoading(getState().input_recording._loaderID, jobId))
      dispatch(actions.fetchEvents())
    },

    eventModalType: (value) => ({
        type: EVENT_START_DATE_VALUE,
        payload: value
    }),

    /**
     * Set Event Name Value
     * @param {string} value
     */
    newEventName: (value) => ({
        type: EVENT_NAME_VALUE,
        payload: value
    }), 

    /**
     * Set New Event Start Date Value
     * @param {string} value
     */
    newEventStartDate: (value) => ({
        type: EVENT_START_DATE_VALUE,
        payload: value
    }),

    /**
     * Set New Event Start Time Value
     * @param {string} value
     */
    newEventStartTime: (value) => ({
        type: EVENT_START_TIME_VALUE,
        payload: value
    }),  

    /**
     * Set New Event Finish Date Value
     * @param {string} value
     */
    newEventFinishDate: (value) => ({
        type: EVENT_FINISH_DATE_VALUE,
        payload: value
    }),

    /**
     * Set New Event Finish Time Value
     * @param {string} value
     */
    newEventFinishTime: (value) => ({
        type: EVENT_FINISH_TIME_VALUE,
        payload: value
    }),  

    /**
     * Set New Event Finish Time Value
     * @param {string} value
     */
    newEventRepeat: (value) => ({
        type: EVENT_REPEAT_VALUE,
        payload: value
    }),    
};

const initialState = {
    currentInput: '',     // Currently viewed input
    recordingStatus: {},  // Hash of recording status objects by input name
    recordingPreview: {}, // Hash of recording preview blob urls by input name
    associations: {},     // Hash of associations by input name
    serverSocket: null,   // Connection to server event socket
    addEventModalStatus: false,
    eventModalId: null,     // ID of the event being edited in the event modal, or null if it is a new event
    newEventNameValue: '',
    newEventStartDate: moment(),
    newEventStartTime: moment(),
    newEventFinishDate: moment(),
    newEventFinishTime: moment(),
    newEventRepeat: 'never',
    events: [],
    selectedAssociation: null,    // Currently selected routing association
}

const reducer = (state = initialState, action) => {

    let {
        type,
        payload
    } = action;

    switch (type) {
        case EVENT_MODAL:
            return {
                ...state,
                eventModalStatus: true
            }
        case CLOSE_EVENT_MODAL:
            return {
                ...state,
                eventModalStatus: false
            }
        case EVENT_MODAL_TYPE:
            return {
                ...state,
                eventModalType: payload.type,
                eventModalId: payload.index
            }
        case EVENT_NAME_VALUE:
            return {
                ...state,
                newEventNameValue: payload
            }
        case EVENT_START_DATE_VALUE:
            return {
                ...state,
                newEventStartDate: payload
            }
        case EVENT_START_TIME_VALUE:
            return {
                ...state,
                newEventStartTime: payload
            }
        case EVENT_FINISH_DATE_VALUE:
            return {
                ...state,
                newEventFinishDate: payload
            }
        case EVENT_FINISH_TIME_VALUE:
            return {
                ...state,
                newEventFinishTime: payload
            }
        case EVENT_REPEAT_VALUE:
            return {
                ...state,
                newEventRepeat: payload
            }
        case OPEN_ERROR_TEXT:
            return {
                ...state,
                errorText: true
            }
        case ADD_NEW_EVENT:
            return {
                ...state,
                events: payload
            }
        case SET_RECORDING_SOCKET:
            return {
                ...state,
                serverSocket: payload
            }
        case SET_CURRENT_INPUT:
            return {
                ...state,
                currentInput: payload
            }
        case UPDATE_RECORDING_STATUS:
          return {
              ...state,
              recordingStatus: {
                ...state.recordingStatus,
                [payload.name]: payload.data
              }
          }
        case UPDATE_ASSOCIATIONS:
          return {
              ...state,
              associations: {
                ...state.associations,
                [payload.name]: payload.data
              }
          }
        case UPDATE_EVENTS:
          return {
            ...state,
            events: payload
          }
        case SET_RECORDING_PREVIEW: {
          let {name, data} = payload
          if(!name) {
            return state
          }
          let thumb = new Blob([data], {type: 'image/jpeg'})
          if(thumb.size === 0) {
            return state
          }
          let url = URL.createObjectURL(thumb)
          if(getIn(state, ['recordingPreview', name, 'current'])) {
            URL.revokeObjectURL(getIn(state, ['recordingPreview', name, 'current']))
          }
          return setIn(state, ['recordingPreview', name, 'current'], url)
        }
        case SELECT_ASSOCIATION: {
          return {
            ...state,
            selectedAssociation: payload
          }
        }
        default:
            return state;
    }
}

export default loaderReducer(reducer, initialState)
