import React, {PureComponent} from 'react'
import {Table, Button} from 'semantic-ui-react'

const getOutputName = (out_num,outData,iocell=false) => {
	let name = undefined
	if (outData.names) {
		if (outData.names[out_num] !== "" && outData.names[out_num] !== undefined && outData.names[out_num] !== ("Output "+out_num.toString()))
			name = outData.names[out_num]
	}
	if (name !== undefined) return (iocell?"Output ":"")+`#${out_num} "${name}"`;
	else return `Output ${out_num}`
}

const getInputName = (in_num,inData,iocell=false) => {
	let name = undefined
	if (inData.names) {
		if (inData.names[in_num] !== "" && inData.names[in_num] !== undefined && inData.names[in_num] !== ("Input "+in_num.toString()))
			name = inData.names[in_num]
	}
	if (name !== undefined) return (iocell?"Input ":"")+`#${in_num} "${name}"`;
	else return `Input ${in_num}`
}

const getInputOutputName = (in_num,out_num,routingData) => {
	return getOutputName(out_num,routingData.outputData,true)+', '+getInputName(in_num,routingData.inputData,true)
}

export default class RoutingTable extends PureComponent {

  render() {
    let {routingData, setMapping} = this.props

    if(!routingData ||
      !routingData.inputData ||
      !routingData.outputData) {
      return null
    }

    let {inputData, outputData} = routingData

    let heads = inputData.range.map((in_num) => (
      <Table.HeaderCell key={in_num} className='noselect'>{getInputName(in_num,routingData.inputData)}</Table.HeaderCell>
    ))

    let rows = outputData.range.map((out_num) => {
      let outMap;
      if(outputData.mappings) {
        let output = outputData.mappings[out_num]
        outMap = output[0]
      }
      if(typeof outMap === 'string') {
        outMap = parseInt(outMap, 10)
      }
      let cells = inputData.range.map((in_num) => (
        <Table.Cell collapsing key={in_num} title={getInputOutputName(in_num,out_num,routingData)}>
          <Button active={outMap === in_num}
            positive={outMap === in_num}
            content={in_num}
            onClick={() => setMapping(out_num, in_num)}
            size='mini'/>
        </Table.Cell>
      ))
      return (<Table.Row key={out_num}>
        <Table.Cell className='noselect'>{getOutputName(out_num,routingData.outputData)}</Table.Cell>
        {cells}
      </Table.Row>)
    })

    return (
      <div id='RecordingControlsTable'>
        <Table collapsing compact selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell/>
              {heads}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows}
          </Table.Body>
        </Table>
      </div>
    )
  }

}
