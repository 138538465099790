import React, {PureComponent} from 'react'
import moment from 'moment'

export default class LicenseTab extends PureComponent {

  render() {
    let {license} = this.props
    if(typeof license !== "object") {
      return null
    }
    let licenseStatus = ""
    if(!license.exists) {
      licenseStatus = "does not exist"
    } else if(!license.valid) {
      licenseStatus = "is not valid"
    } else if(license.expired) {
      licenseStatus = "is expired"
    } else {
      licenseStatus = "is valid"
    }

    let expiration = ""
    if(license.expiration) {
      expiration = license.expired ? "expired on" : "expires on";
      expiration = expiration + " " + moment(license.expiration).format("MMMM Do, YYYY")
    }

    return (
      <div id="AboutLicenseTab">
        <div>License file {licenseStatus}</div>
        {expiration ?
          (<div>License {expiration}</div>) :
          ""}
        {license.sd_channels ?
          (<div>SD Channels Allowed: {license.sd_channels}</div>) :
          ""}
        {license.hd_channels ?
          (<div>HD Channels Allowed: {license.hd_channels}</div>) :
          ""}
        {license.preview_channels ?
          (<div>Preview Channels Allowed: {license.preview_channels}</div>) :
          ""}
        {license.audio_channels ?
          (<div>Audio Channels Allowed: {license.sd_channels}</div>) :
          ""}
        {license.restricted_inputs ?
          (<div>Restricted Inputs Allowed: {license.restricted_inputs}</div>) :
          ""}
        {license.id ?
          (<div>License ID: {license.id}</div>) :
          ""}
        {license.quickcast ?
          (<div>QuickCast Unit</div>) :
          ""}
      </div>
    )
  }

}
