import React, {PureComponent} from 'react'
import {Segment, Button, Checkbox, Accordion, List} from 'semantic-ui-react'
import EventInput from './EventInput'
import ExistingEventModal from './ExistingEventModal'

import './Container.css'

export default class EventTab extends PureComponent {

  render() {
    let {eventsList,
      scheduleType,
      selectedEvents,
      showSubseconds,
      goToTime,
      createEvent,
      changeEvent,
      removeEvent,
      selectEvents} = this.props

    let eventSegments = []
    if(eventsList) {
      eventSegments = Object.values(eventsList).map((evt) => {
        let selected = selectedEvents.includes(evt.uuid)
        let occurences = evt.occurences.map((time) => {
          return <List.Item className='EventOccurence noselect' key={time.toString()} onClick={(e) => {e.stopPropagation(); goToTime(time)}}>
            {`${scheduleType === 'interval' ? 'Day ' : ''}${time.toString({showMilliseconds: showSubseconds})}`}
          </List.Item>
        })
        let playFolderItem, uploadFolderItem, toCopyItem
        if(evt.assigned) {
          playFolderItem = evt.assigned.play ? (evt.assigned.play["original filename"] || "unknown") : ""
          if(evt.assigned.upload) {
            let uploadItems = Object.keys(evt.assigned.upload)
            if(uploadItems.length === 1) {
              uploadFolderItem = uploadItems[0]
            } else {
              uploadFolderItem = (<List items={uploadItems}/>)
            }
          }
        }
        toCopyItem = evt.toCopy ? `/${evt.toCopy.join("/")}` : ""
        return (
          <Segment key={evt.uuid}
            color={selected ? 'green' : undefined}
            onClick={() => {selectEvents([evt.uuid], {exclusive: true, mode: 'select'})}}>
            <div className='EventControls'>
              <Checkbox checked={selected} onClick={(e) => {e.stopPropagation(); selectEvents([evt.uuid])}}/>
              <div className='EventControlsSpacer'/>
              <Button negative icon='remove' compact onClick={(e) => {e.stopPropagation(); removeEvent(evt.uuid)}}/>
            </div>
            <EventInput id={evt.uuid}
              name='name'
              value={evt.name}
              changeEventInfo={changeEvent}
              inputProps={{
                label: 'Name',
                title: 'Name (Must be unique)',
                placeholder: `Event ${evt.uuid}`,
                fluid: true
              }}/>
            <EventInput id={evt.uuid}
              name='duration'
              value={evt.duration}
              changeEventInfo={changeEvent}
              inputProps={{
                label: 'Duration',
                title: 'Duration (H:MM:SS)',
                placeholder: 'Duration',
                fluid: true
              }}/>
            <Segment className="eventAssignedSegment" attached="bottom">
              <List divided>
                <List.Item title="Event will currently play this item">
                  <List.Content>
                    <List.Header>Play</List.Header>
                    <List.Description title={playFolderItem || "(none)"}>
                      {playFolderItem || "(none)"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item title="Item(s) in Upload folder waiting to be copied to Play folder">
                  <List.Content>
                    <List.Header>Queued</List.Header>
                    <List.Description>
                      {uploadFolderItem || "(none)"}
                    </List.Description>
                  </List.Content>
                </List.Item>
                <List.Item title="Assigned in editor, but not saved">
                  <List.Content>
                    <List.Header>To Assign</List.Header>
                    <List.Description title={toCopyItem || "(none)"}>
                      {toCopyItem || "(none)"}
                    </List.Description>
                  </List.Content>
                </List.Item>
              </List>
            </Segment>
            <Accordion panels={[{key: 'occurences', title: 'Scheduled Times', content: {
              key: 'eventTimes',
              content: (
                <List divided>
                  {occurences}
                </List>
              )
            }}]}/>
          </Segment>
        )
      })
    }

    return (
      <Segment.Group>
        {eventSegments}
        <Segment>
          <Button.Group fluid>
            <Button content='New Event' positive icon="add" onClick={() => {createEvent()}}/>
            <ExistingEventModal createEvent={createEvent}/>
          </Button.Group>
        </Segment>
      </Segment.Group>
    )

  }

}
