import tabbedReducer from 'redux/higher_order_reducers/tabbedReducer'
export const LIBRARY_FILE_SELECT = Symbol('library file select')
export const LIBRARY_FILE_DESELECT = Symbol('library file deselect')
export const LIBRARY_NAVIGATE = Symbol('library navigate')
export const LIBRARY_CHANGE_TAB = Symbol('library change tab')
export const LIBRARY_CREATE_TAB = Symbol('library create tab')
export const LIBRARY_DELETE_TAB = Symbol('library delete tab')
export const LIBRARY_VIEW_MODE = Symbol('library view mode')

export const libraryFileSelect = (files) => (
  {
    type: LIBRARY_FILE_SELECT,
    payload: files
  }
)

export const libraryNavigate = (navpath) => (
  {
    type: LIBRARY_NAVIGATE,
    payload: navpath
  }
)

export const libraryFileDeselect = () => ({type: LIBRARY_FILE_DESELECT})

export const changeTab = (index) => ({
  type: LIBRARY_CHANGE_TAB,
  payload: index
})

export const createTab = (path=['mnt', 'main']) => ({
  type: LIBRARY_CREATE_TAB,
  payload: {navPath: path}
})

export const deleteTab = (index) => ({
  type: LIBRARY_DELETE_TAB,
  payload: index
})

export const libraryViewMode = (mode='list') => ({
  type: LIBRARY_VIEW_MODE,
  payload: mode
})

const LIBRARY_DISPLAY = (props) => {
  if(props.navPath.length > 0) {
    return props.navPath.slice(-1)
  } else {
    return '/'
  }
}

export const initialState = {
  selectedFiles: [], // Array of rpaths of selected files
  deselect: 0,       // Increment this to cause inner library component to deselect.
  navPath: ['mnt', 'main'], // Current path of inner library
  viewMode: 'list',   // View mode of library (list or tile)
}

const reducer = (state=initialState, action) => {

  let {type, payload} = action

  switch(type) {
    case LIBRARY_FILE_SELECT:
      return {
        ...state,
        selectedFiles: payload
      }
    case LIBRARY_FILE_DESELECT:
      return {
        ...state,
        deselect: state.deselect + 1
      }
    case LIBRARY_NAVIGATE:
      return {
        ...state,
        navPath: payload
      }
    case LIBRARY_VIEW_MODE:
      return {
        ...state,
        viewMode: payload
      }
    default:
      return state
  }

}

export default tabbedReducer('library', reducer, initialState, {
  changeTab: LIBRARY_CHANGE_TAB,
  createTab: LIBRARY_CREATE_TAB,
  deleteTab: LIBRARY_DELETE_TAB,
  display: LIBRARY_DISPLAY,
  tabActions: {
    libraryFileSelect,
    libraryNavigate,
    libraryFileDeselect,
    libraryViewMode
  }
})
