import React, {PureComponent} from 'react'
import {Form, Input} from 'semantic-ui-react'

export default class StringPrompt extends PureComponent {

  stringInput = React.createRef()

  componentDidMount() {
    if(this.stringInput.current) {
      this.stringInput.current.focus()
    }
  }

  render() {
    let {value, changeValue} = this.props
    return (
      <Form>
        <Form.Field>
          <Input value={value}
            ref={this.stringInput}
            onChange={(e, data) => {changeValue(data.value)}}/>
        </Form.Field>
      </Form>
    )
  }

}
