import React from 'react'
import {Card, Image} from 'semantic-ui-react'

import './Tile.css'

export default props => {
  let {disabled,
    openApplication,
    application,
    label,
    image} = props

  let cls = 'SplashTile'
  let clickAction;
  if(openApplication && application && !disabled) {
    clickAction = () => openApplication(application);
  }
  if(disabled) {
    cls = `${cls} disabled`
  }

  return (
    <Card onClick={clickAction} link={!disabled} className={cls}>
      <Image src={image} className='SplashIcon'/>
      <Card.Content>
        <Card.Header>{label}</Card.Header>
      </Card.Content>
    </Card>
  )

}
