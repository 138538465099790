import React, {PureComponent} from 'react'
import {Checkbox, Button, Segment} from 'semantic-ui-react'
import Region from './Region'

import './Container.css'

export default class GlobalMediaControls extends PureComponent {

  handleSnapToGrid = (e, data) => {
    this.props.setGlobalMediaSnapToGrid(data.checked)
  }

  handleAddNewRegion = () => {
    this.props.addGlobalFrame()
  }

  render() {
    let {snapToGrid,
      frames,
      globalMediaSelectedFile,
      changeGlobalFrameOptions,
      reorderGlobalFrame,
      assignGlobalFrameItem,
      renameGlobalFrame,
      resizeGlobalFrame,
      moveGlobalFrame,
      globalMediaUndo,
      globalMediaRedo,
      shouldUndo,
      shouldRedo,
      saveGlobalFrames} = this.props

    let regions = frames.map((frame, index) => {
      if(frame !== null) {
        return (<Region key={index}
          frame={frame}
          index={index}
          main={frame.options.main}
          selectedFile={globalMediaSelectedFile}
          changeOptions={changeGlobalFrameOptions}
          assignItem={assignGlobalFrameItem}
          renameFrame={renameGlobalFrame}
          resizeFrame={resizeGlobalFrame}
          moveFrame={moveGlobalFrame}
          reorderFrame={reorderGlobalFrame}/>)
      } else {
        return null
      }
    })

    return (
      <div id="GlobalMediaControls">
        <div id="GlobalMediaControlButtons">
          <Checkbox label="Snap To Grid"
            floated="left"
            checked={snapToGrid}
            onChange={this.handleSnapToGrid}/>
          <div className='spacer'/>
          <Button.Group floated="right">
            <Button disabled={!shouldUndo} icon='undo' onClick={globalMediaUndo}/>
            <Button disabled={!shouldRedo} icon='redo' onClick={globalMediaRedo}/>
            <Button onClick={saveGlobalFrames}>Apply</Button>
          </Button.Group>
        </div>
        <div id="GlobalMediaRegionContainer">
          <Segment.Group>
            {regions}
          </Segment.Group>
        </div>
      </div>
    )

  }

}
