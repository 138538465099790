import React, {Component} from 'react'
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import reducer from 'redux/components/library'
import PropTypes from 'prop-types'

import LibraryFrame from './LibraryFrame'

class Library extends Component {

  constructor(props) {
    super(props)
    this.store = createStore(reducer)
  }

  render() {
    return (
      <Provider store={this.store}>
        <div className='libraryComponent'>
          <LibraryFrame {...this.props}/>
        </div>
      </Provider>
    )
  }

}

Library.propTypes = {
  update: PropTypes.func, // Callback function to retrieve selected file(s) from library
  search: PropTypes.func, // Optional function for searching the library that takes the string to search by. If passed, a search bar will be rendered.
  overridePath: PropTypes.array, // Pass in array to override the library's current path
  deselect: PropTypes.number, // When this value changes, the library will deselect all currently selected files
  compact: PropTypes.any,  // If this is truthy, only icon, filename and duration columns will be displayed
  selectOne: PropTypes.any, // If this is truthy, select boxes for selecting multiple items will be disabled
  noOpen: PropTypes.any,    // If this is truthy, files will not be openable from this library
  inputAssociations: PropTypes.any, // If this is truthy, the association dropdown will be shown for inputs that have associations.
  fileData: PropTypes.object,
  /**
   * @object dropdownAction
   * @param {function} action The function to be called on selecting this option
   * @param {string} key The key of the dropdown item
   * @param {string} icon The icon to display in the dropdown's list item in the menu
   * @param {string} display The display name of the action in the menu
   * @param {object} conditions Conditions that must be met for a given file to have this action. Optional
   * @param {string} conditions.type If set to a string, file must have this type (as determined by fileTypeData helper) to receive action
   * @param {string} conditions.subtype If set to a string, file must have this subtype (as determined by fileTypeData helper) to receive action
   */
  actionDropdown: PropTypes.array, // Array of dropdownAction objects to be used for the file's action dropdown.
  handleOpenFile: PropTypes.func,  // Function for handling opening a non-folder non-media file. Will be passed the file type information of the file.
  viewMode: PropTypes.string,      // String view mode of library, either "list" or "tile". Defaults to "list"
  loadFileData: PropTypes.func.isRequired, // Action for loading the file data from the server
}

export default Library
