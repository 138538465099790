
// Keeper of the status and setting objects across this project.

let statusObject = {}

const getStatus = (name) => {
  return statusObject[name]
}

const createStatus = (name,object) => {
  if (name in statusObject) {
    if (statusObject[name] !== object) {
      console.error(`Attempt to create setting ${name} when it already exists, and new object is different`)
      return
    }
  }
  statusObject[name] = object
}

const deleteStatus = (name) => {
  if (name in statusObject) {
    delete statusObject[name]
  }
}

const replaceStatus = (name,object) => {
  statusObject[name] = object
}

module.exports = {
  getStatus,
  createStatus,
  deleteStatus,
  replaceStatus
}

