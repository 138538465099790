import React, {Component} from 'react'
import {Button, Header, Divider} from 'semantic-ui-react'
import {getIn} from 'helpers/general_helpers'

import SettingIdSelector from 'selectors/SettingIdSelector'

import RoutingTable from './RoutingTable.jsx'

import './RoutingControls.css'

export default class RoutingControls extends Component {

  componentDidMount() {
    let router = SettingIdSelector(this.props)
    this.props.startService("router", router)
  }

  shouldComponentUpdate(newprops) {
    let oldId = SettingIdSelector(this.props)
    let newId = SettingIdSelector(newprops)
    return (oldId !== newId ||
      getIn(this.props.services, ['router', oldId, 'settings']) !==
      getIn(newprops.services, ['router', newId, 'settings']))
  }

  setMapping = (output, input) => {
    let {changeMapping} = this.props
    let router = SettingIdSelector(this.props)
    changeMapping(router, output, input)
  }

  saveMapping = () => {
    let {saveMapping} = this.props
    let router = SettingIdSelector(this.props)
    saveMapping(router)
  }

  revertMapping = () => {
    let {revertMapping} = this.props
    let router = SettingIdSelector(this.props)
    revertMapping(router)
  }

  render() {
    let {services, openApplication} = this.props
    let router = SettingIdSelector(this.props)
    let routingData = getIn(services, ['router', router, 'settings', 'routing'])

    return (
      <div id='RecordingControls'>
        <Header as='h2' icon='random' content={router} id='RecordingControlsHeader'/>
        <Divider fitted/>
        <div>
          <Button.Group>
            <Button icon='arrow left' content='Back' onClick={() => openApplication('Settings', '/router')}/>
            <Button icon='save' content='Apply' onClick={this.saveMapping}/>
            <Button icon='refresh' content='Revert' onClick={this.revertMapping}/>
          </Button.Group>
        </div>
        <RoutingTable routingData={routingData}
          setMapping={this.setMapping}/>
      </div>
    )
  }

}
