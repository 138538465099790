import React, {PureComponent} from 'react'
import {Dropdown} from 'semantic-ui-react'

import './ScheduleGapSelector.css'

const OPTIONS = [
  {text: '15 min', value: 15, key: 15},
  {text: '30 min', value: 30, key: 30},
  {text: '1 hr', value: 60, key: 60},
  {text: '24 hr', value: 1440, key: 1440},
]

class ScheduleGapSelector extends PureComponent {

  render() {
    let {timeSlotLength, changeTimeSlotLength} = this.props

    if(typeof timeSlotLength === 'string') {
      timeSlotLength = parseInt(timeSlotLength, 10)
    }

    return (
      <div className='scheduleGapSelector'>
        Maximum Empty Timeslot Length: <Dropdown onChange={(e, {value}) => {changeTimeSlotLength(value)}}
          options={OPTIONS}
          selection
          compact
          value={timeSlotLength}/>
      </div>
    )
  }

}

export default ScheduleGapSelector
