/**
 * Global clipboard for the schedule application
 * This is NOT an application by itself; it is part of the schedule application
 */
export const CLIPBOARD_SCHEDULE_ITEMS = Symbol("clipboard schedule items")

export const clipboardScheduleItems = (items=[]) => ({
  type: CLIPBOARD_SCHEDULE_ITEMS,
  payload: items
})

const initialState = {
  clipboard: []
}

export default (state=initialState, action) => {

  let {type, payload} = action

  switch(type) {
    case CLIPBOARD_SCHEDULE_ITEMS:
      return {
        ...state,
        clipboard: payload
      }
    default:
      return state
  }

}
