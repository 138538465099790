import React, {PureComponent} from 'react'
import {VALID_LOG_TYPES, VALID_LOG_LABELS} from 'redux/components/logs'
import {Tab, Segment, List, Button} from 'semantic-ui-react'

import "./LogsPanel.css"

export default class LogsPanel extends PureComponent {

  constructor(props) {
    super(props)
    this.logTextBox = React.createRef()
    this.fetching = false
    this.currentHeight = 0
  }

  componentDidMount() {
    this.fetchInitialLogs()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.currentlyViewedLog !== this.props.currentlyViewedLog) {
      this.fetchInitialLogs()
    } else if(this.fetching && prevProps.logs[prevProps.currentlyViewedLog].entries.length < this.props.logs[this.props.currentlyViewedLog].entries.length) {
      if(this.currentHeight) {
        if(this.props.logs[this.props.currentlyViewedLog].from_end) {
          this.logTextBox.current.scrollTop = this.logTextBox.current.scrollHeight - this.currentHeight
        }
      }
      this.fetching = false
    }
  }

  fetchInitialLogs = async () => {
    let {currentlyViewedLog, logs, fetchLogs} = this.props
    if(logs[currentlyViewedLog].entries.length === 0) {
      await fetchLogs()
      // Scroll to bottom
      if(this.logTextBox.current) {
        this.logTextBox.current.scrollTop = this.logTextBox.current.scrollHeight - this.logTextBox.current.clientHeight
      }
    }
  }

  fetchMoreLogs = async () => {
    if(this.logTextBox.current) {
      this.currentHeight = this.logTextBox.current.scrollHeight
    }
    await this.props.fetchLogs()
  }

  handleRefresh = async () => {
    await this.props.fetchLogs({count: 1000, from_end: true})
    // Scroll to bottom
    if(this.logTextBox.current) {
      this.logTextBox.current.scrollTop = this.logTextBox.current.scrollHeight - this.logTextBox.current.clientHeight
    }
  }

  handleChangeTab = (e, data) => {
    let nextView = VALID_LOG_TYPES[data.activeIndex]
    this.props.changeLogsType(nextView)
  }

  handleScroll = (e) => {
    if(!this.fetching && ((this.props.logs[this.props.currentlyViewedLog].from_end && this.logTextBox.current.scrollTop === 0) ||
      (!(this.props.logs[this.props.currentlyViewedLog].from_end) && this.logTextBox.current.scrollTop >= this.logTextBox.current.scrollHeight - this.logTextBox.current.clientHeight))) {
        this.fetching = true
        this.fetchMoreLogs()
    }
  }

  changeDirection = async () => {
    let {logs, currentlyViewedLog, fetchLogs} = this.props
    let newDirection = !(logs[currentlyViewedLog].from_end)
    await fetchLogs({count: 1000, from_end: newDirection})
    if(newDirection) {
      this.logTextBox.current.scrollTop = this.logTextBox.current.scrollHeight - this.logTextBox.current.clientHeight
    } else {
      this.logTextBox.current.scrollTop = 0
    }
  }

  render() {
    let {logs, currentlyViewedLog} = this.props
    let currentlyViewedIndex = 0
    let tabs = VALID_LOG_TYPES.map((logType, ind) => {
      let data = logs[logType]
      if (logType === currentlyViewedLog) {
        currentlyViewedIndex = ind
      }
      return {
        key: logType,
        menuItem: VALID_LOG_LABELS[logType],
        render: () => (
          <div className="logsSection">
            <div>
              <Button icon="refresh" content="Refresh" onClick={this.handleRefresh}/>
              {data.from_end ?
                <Button icon="arrow circle up" content="First Entry" onClick={this.changeDirection}/> :
                <Button icon="arrow circle down" content="Last Entry" onClick={this.changeDirection}/>}
            </div>
            <div className="logsTextContainer" ref={this.logTextBox} onScroll={this.handleScroll}>
              <Segment className="logsText">
                <List items={data.entries} divided relaxed/>
              </Segment>
            </div>
          </div>
        )
      }
    })
    return (
      <Tab id="LogsTabs"
        panes={tabs}
        activeIndex={currentlyViewedIndex}
        onTabChange={this.handleChangeTab}/>
    )
  }

}
