import moment from "moment"
import {tzToUTC, UTCToTz, addPeriodUTC, subtractPeriodUTC} from "./DateHelpers"

/**
 * @constant
 * @type {array}
 * @default
 */
const DAYS_PER_MONTH = [
  31,
  28,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
]

/**
 * @constant
 * @type {array}
 * @default
 */
const LEAPYEAR_DAYS_PER_MONTH = [
  31,
  29,
  31,
  30,
  31,
  30,
  31,
  31,
  30,
  31,
  30,
  31
]

// NOTE: Empty capturing groups are in these regexes on purpose, so that the indexes of the match array from exec are always the same unit
/**
 * @constant
 * @type {RegExp}
 * @default
 */
const YEARLY_TIME_REGEX = /^month (\d{1,2}) day (\d{1,2}) ()(\d{1,2}):(\d{1,2})(?::(\d{1,3})(?:\.(\d{1,3}))?)? ([aApP][mM])$/

/**
 * @constant
 * @type {RegExp}
 * @default
 */
const MONTHLY_TIME_REGEX = /^()day (\d{1,2}) ()(\d{1,2}):(\d{1,2})(?::(\d{1,3})(?:\.(\d{1,3}))?)? ([aApP][mM])$/

/**
 * @constant
 * @type {RegExp}
 * @default
 */
const WEEKLY_TIME_REGEX = /^()()(sun|mon|tue|wed|thu|fri|sat) (\d{1,2}):(\d{1,2})(?::(\d{1,3})(?:\.(\d{1,3}))?)? ([aApP][mM])$/

/**
 * @constant
 * @type {RegExp}
 * @default
 */
const DAILY_TIME_REGEX = /^()()()(\d{1,2}):(\d{1,2})(?::(\d{1,3})(?:\.(\d{1,3}))?)? ([aApP][mM])$/

/**
 * @constant
 * @type {array}
 * @default
 */
const WEEKDAYS_SHORT = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]

/**
 * @constant
 * @type {number}
 * @default
 */
const YEAR = 31622400000

/**
 * @constant
 * @type {number}
 * @default
 */
const MONTH = 2678400000

/**
 * @constant
 * @type {number}
 * @default
 */
const WEEK = 604800000

/**
 * @constant
 * @type {number}
 * @default
 */
const DAY = 86400000

/**
 * @constant
 * @type {number}
 * @default
 */
const HOUR = 3600000

/**
 * @constant
 * @type {number}
 * @default
 */
const MINUTE = 60000

/**
 * Class for keeping track of date intervals with both date and time information.
 * For use with scheduling.
 * Needs to do the following:
 *  * Keep track of a specific time that occurs on every certain day of the week, day of the month, day of the year, or just every day. Does NOT represent a single exact point in time like moment.
 *  * Able to be printed as a string
 *  * Able to compare to moments and/or Date objects to determine if it occurs on the same day or time
 */
class IntervalTime {

  /**
   * Constructs new interval date.
   * @param {(moment.Moment|Date|number|string|Object)} time Value representing the time and day that the interval time occurs on. If a period is given,
   *  time will be interpreted based on that period. Time accepts the following values:
   *  Moment: Will be interpreted as the moment's time of day occurring on the moment's day (based on period, with the period being "yearly" by default)
   *  Date: Will be interpreted the Date's time of day occurring on the Date's day (based on period, with the period being "yearly" by default)
   *  Number: Interpreted as utc time in milliseconds; will be converted to a Date.
   *  String: First attempts to interpret as one of the Castus Schedule date/time formats. If that fails, it then tries to interpret with Date.parse().
   *  Object: Specify "month", "date", "weekday", "hour", "minute", "second", and "millisecond" to set the values directly
   * @param {string} [period] The time period that the date occurs within. Can be "daily", "weekly", "monthly", or "yearly".
   *  "daily" indicates that the time occurs every day
   *  "weekly" indicates that the time occurs every week on a certain day of the week
   *  "monthly" indicates that the time occurs every month on a certain day of the month (skipping months if they don't have the given day)
   *  "yearly" indicates that the time occurs every year on a certain date of the year (skipping years if they don't have the given date)
   *  If no period is given, then period will guessed based on the given time.
   *  If a period is explicitly given, then it is assumed that the caller only wants that period. If a format of time was given that conflicts with the given period, an error will be thrown.
   * @param {Object} [intervalDuration] Description of the period used for "interval" period IntervalTimes
   */
  constructor(time, period, intervalDuration) {
    // Make sure something weird isn't being passed for period
    if(period) {
      period = period.trim()
    }
    if(period &&
      period !== "daily" &&
      period !== "weekly" &&
      period !== "monthly" &&
      period !== "yearly" &&
      period !== "interval") {
      throw new Error(`When trying to construct an IntervalTime, a period was passed that was not recognized: ${period}.
        You must either pass a string value of "daily", "weekly", "monthly", "yearly", or "interval" or you must pass nothing for the period in order for it to be auto-detected`)
    }
    let params = {}
    if(period === "interval" && !(time instanceof IntervalTime) && (!isAnIntervalDuration(intervalDuration))) {
      throw new Error(`Trying to construct an IntervalTime with an "interval" period without specifying a duration for the interval. ` +
        `An object containing the duration of the interval in time units ("years", "months", "weeks", "days", "hours", "minutes", "seconds", and/or "milliseconds") ` +
        `must be given when constructing an IntervalTime with an "interval" period.`)
    }
    // Pass args to the correct interpreter based on what time was given as
    if(time instanceof IntervalTime) {
      params = {
        time: {...time.time},
        period: time.period,
        intervalDuration: {...time.intervalDuration}
      }
    } else if(moment.isMoment(time)) {
      params = interpretMomentTime(time, period, intervalDuration)
    } else if(moment.isDate(time)) {
      params = interpretDateTime(time, period, intervalDuration)
    } else if (typeof time === "number") {
      params = interpretNumberTime(time, period, intervalDuration)
    } else if (typeof time === "string") {
      params = interpretStringTime(time, period, intervalDuration)
    } else if (typeof time === "object") {
      params = interpretObjectTime(time, period, intervalDuration)
    } else {
      // Time is something that we don't interpret, so throw an error
      throw new Error(`An unsupported type of value was passed as the time when trying to construct an IntervalTime: ${time}. You must pass either a moment, date object, number, string, or object with time key/value pairs.`);
    }
    // Milliseconds are maximum precision. Don't allow fractional milliseconds
    params.time.millisecond = Math.floor(params.time.millisecond)
    this.time = params.time;
    this.period = params.period;
    this.intervalDuration = params.intervalDuration;
  }

  /**
   * Returns a copy of this interval time
   */
  clone() {
    return new IntervalTime(this)
  }

  /**
   * Gets the milliseconds unit value of this IntervalTime (0-999).
   * @returns {number}
   */
  millisecond() {
    return this.time.millisecond
  }

  /**
   * Gets the seconds unit value of this IntervalTime (0-59).
   * @returns {number}
   */
  second() {
    return this.time.second
  }

  /**
   * Gets the minutes unit value of this IntervalTime (0-59).
   * @returns {number}
   */
  minute() {
    return this.time.minute
  }

  /**
   * Gets the hours unit value of this IntervalTime (0-23).
   * @returns {number}
   */
  hour() {
    return this.time.hour
  }

  /**
   * Gets the day of the month of this IntervalTime (1-31).
   * @returns {number}
   */
  date() {
    return this.time.date
  }

  /**
   * Gets the month of this IntervalTime (0-11).
   * @returns {number}
   */
  month() {
    return this.time.month
  }

  // For consistency with Date and Moment
  /**
   * Gets the day of the week of this IntervalTime (0-6).
   * @returns {number}
   */
  day() {
    return this.time.weekday
  }

  /**
   * Gets the interval schedule day of this IntervalTime.
   * @returns {number}
   */
  intervalday() {
    return this.time.intervalday
  }

  /**
   * Gets the day of the week of this IntervalTime (0-6).
   * @returns {number}
   * @see IntervalTime.day
   */
  weekday() {
    return this.time.weekday
  }

  /**
   * Gets the year of this IntervalTime.
   * For use with custom intervals
   * @returns {number}
   */
  year() {
    return this.time.year
  }

  /**
   * Gets the interval basis as a Date, if one exists.
   * @returns {Date|undefined}
   */
  basis() {
    if(!this.intervalDuration || !this.intervalDuration.basis) {
      return
    }
    let {basis} = this.intervalDuration
    if(moment.isMoment(basis)) {
      basis = basis.toDate()
    }
    return basis
  }

  /**
   * Adds a number of milliseconds to this IntervalTime.
   * @param {number} addMilliseconds Number of milliseconds to add
   * @returns {IntervalTime} a new copy of this with a number of milliseconds added to it
   */
  add(addMilliseconds) {
    let toReturn = new IntervalTime(this)
    if(addMilliseconds === 0) {
      return toReturn
    }
    let [addSeconds, millisecond] = carryUnits(toReturn.millisecond() + addMilliseconds, 1000)
    let [addMinutes, second] = carryUnits(toReturn.second() + addSeconds, 60)
    let [addHours, minute] = carryUnits(toReturn.minute() + addMinutes, 60)
    let [addDays, hour] = carryUnits(toReturn.hour() + addHours, 24)
    let newTime = {
      ...toReturn.time,
      millisecond,
      second,
      minute,
      hour
    }
    switch(toReturn.period) {
      case "daily":
        break;
      case "weekly":
        newTime.weekday = boundUnits(toReturn.weekday() + addDays, 7);
        break;
      case "monthly": {
        let newDate = boundUnits(toReturn.date() + addDays, 32);
        if(newDate === 0) {
          newDate = 1
        }
        newTime.date = newDate
        break;
      }
      case "yearly": {
        let newDay = toReturn.date() + addDays;
        let newMonth = toReturn.month();
        while(newDay > LEAPYEAR_DAYS_PER_MONTH[newMonth] || newDay <= 0) {
          if(newDay > LEAPYEAR_DAYS_PER_MONTH[newMonth]) {
            newDay = newDay - LEAPYEAR_DAYS_PER_MONTH[newMonth];
            newMonth = boundUnits(newMonth + 1, 12);
          } else if(newDay <= 0) {
            newMonth = boundUnits(newMonth - 1, 12);
            newDay = newDay + LEAPYEAR_DAYS_PER_MONTH[newMonth];
          }
        }
        newTime.date = newDay;
        newTime.month = newMonth;
        break;
      }
      case "interval": {
        if(toReturn.intervalday() && toReturn.basis()) {
          newTime.intervalday = toReturn.intervalday() + addDays
          // TODO: Account for intervals in amounts other than days
          if(this.intervalDuration.days) {
            let {days} = this.intervalDuration
            newTime.intervalday = newTime.intervalday % days
            if(newTime.intervalday <= 0) {
              newTime.intervalday += days
            }
          }
        } else {
          let newDay = toReturn.date() + addDays;
          let newMonth = toReturn.month();
          let newYear = toReturn.year();
          let daysPerMonth = (newYear & 4 === 0) ? LEAPYEAR_DAYS_PER_MONTH : DAYS_PER_MONTH
          while(newDay > daysPerMonth[newMonth] || newDay <= 0) {
            if(newDay > daysPerMonth[newMonth]) {
              newDay = newDay - daysPerMonth[newMonth];
              let [addYears, month] = carryUnits(newMonth + 1, 12);
              newMonth = month
              if(addYears !== 0) {
                newYear = newYear + addYears
                daysPerMonth = (newYear & 4 === 0) ? LEAPYEAR_DAYS_PER_MONTH : DAYS_PER_MONTH
              }
            } else if(newDay <= 0) {
              let [addYears, month] = carryUnits(newMonth - 1, 12);
              newMonth = month
              if(addYears !== 0) {
                newYear = newYear + addYears
                daysPerMonth = (newYear & 4 === 0) ? LEAPYEAR_DAYS_PER_MONTH : DAYS_PER_MONTH
              }
              newDay = newDay + daysPerMonth[newMonth];
            }
          }
          newTime.date = newDay;
          newTime.month = newMonth;
          newTime.year = newYear;
        }
        break;
      }
      default:
        break;
    }
    toReturn.time = {...toReturn.time, ...newTime}
    return toReturn
  }

  /**
   * Subtract a number of milliseconds from this IntervalTime.
   * @param {number} subtractMilliseconds Number of milliseconds to subtract
   * @returns {IntervalTime} a new copy of this with a number of milliseconds subtracted from it
   */
  subtract(subtractMilliseconds) {
    return this.add(-1 * subtractMilliseconds)
  }

  /**
   * Sets one of the time units of this IntervalTime
   * @param {string} units The unit to set. Can be either "millisecond", "second", "minute", "hour", "date", "month", "weekyday", or "year"
   * @param {number} number The new value to set the unit to
   * @returns {IntervalTime} a new copy of this IntervalTime with the given unit set to the value of number
   */
  set(units, number) {
    let toReturn = new IntervalTime(this);
    toReturn.time[units] = number;
    return toReturn
  }

  /**
   * Returns this IntervalTime's time of day as a string
   * @param {Object} [opts={}] Object containing options for printing the time
   * @param {boolean} [opts.showMeridian=true] If true, give time as 12 hour time with meridian. Otherwise, give time as 24 hour time.
   * @param {boolean} [opts.showMilliseconds=false] If true, show milliseconds. Otherwise, do not.
   * @param {number} [opts.subsecondDigits=3] Sets the number of digits of subseconds to show.
   * @param {boolean} [opts.allowNoSeconds=false] If true, will not print seconds if seconds are 0 and milliseconds are either 0 or not shown.
   * @returns {string} A string representation of this.time.
   */
  printTimeOfDay(opts={}) {
    if(!this.time) {
      return ""
    }
    let {showMeridian = true, showMilliseconds = false, subsecondDigits = 3, allowNoSeconds = false} = opts
    let {hour, minute, second, millisecond} = this.time
    let meridian = ""
    let hourString = ""
    if(!showMeridian) {
      hourString = `${hour}`
    } else {
      let ispm = Math.floor(hour / 12)
      if(ispm) {
        meridian = " pm"
      } else {
        meridian = " am"
      }
      hourString = `${(hour % 12)}`
      if(hourString === "0") {
        hourString = "12"
      }
    }
    let minuteString = `${minute}`.padStart(2, "0");
    let secondColon = ":"
    let secondString = `${second}`.padStart(2, "0");
    if(allowNoSeconds && second === 0 && (millisecond === 0 || !showMilliseconds || !opts.subsecondDigits)) {
     secondColon = ""
     secondString = ""
    }
    let millisString = ""
    if(showMilliseconds || opts.subsecondDigits) {
      millisString = `${Math.floor(millisecond)}`.padStart(3, "0").slice(0, subsecondDigits).padStart(subsecondDigits, "0");
      millisString = `.${millisString}`
    }
    return `${hourString}:${minuteString}${secondColon}${secondString}${millisString}${meridian}`
  }

  printDay(opts={}) {
    switch(this.period) {
      case "yearly":
        return `Month ${this.month() + 1} Day ${this.date()}`
      case "monthly":
        return `Day ${this.date()}`
      case "weekly":
        return `${WEEKDAYS_SHORT[this.weekday()]}`
      case "interval":
        if((this.intervalday() || this.intervalday() === 0) && this.basis()) {
          return `Day ${this.intervalday()}`
        } else {
          return `Starts on ${this.year()}/${this.month() + 1}/${this.date()}`
        }
      default:
        return ""
    }
  }

  /**
   * Returns a string representation of how often an IntervalTime repeats itself
   * @param {Object} opts Optional arguements
   * @param {boolean} [opts.showMilliseconds=true] If false, don't print milliseconds
   * @returns {string} a string representation of how often this repeats
   */
  printRepeats(opts={}) {
    let {showMilliseconds=true} = opts
    switch(this.period) {
      case "daily":
        return "repeats every day"
      case "weekly":
        return "repeats every week"
      case "monthly":
        return "repeats every month"
      case "yearly":
        return "repeats every year"
      case "interval":
        let repeats = []
        if(this.intervalDuration.years) {
          repeats.push(`${this.intervalDuration.years} years`)
        }
        if(this.intervalDuration.months) {
          repeats.push(`${this.intervalDuration.months} months`)
        }
        if(this.intervalDuration.weeks) {
          repeats.push(`${this.intervalDuration.weeks} weeks`)
        }
        if(this.intervalDuration.days) {
          repeats.push(`${this.intervalDuration.days} days`)
        }
        if(this.intervalDuration.hours) {
          repeats.push(`${this.intervalDuration.hours} hours`)
        }
        if(this.intervalDuration.minutes) {
          repeats.push(`${this.intervalDuration.minutes} minutes`)
        }
        if(this.intervalDuration.seconds) {
          repeats.push(`${this.intervalDuration.seconds} seconds`)
        }
        if(this.intervalDuration.milliseconds && showMilliseconds) {
          repeats.push(`${this.intervalDuration.milliseconds} milliseconds`)
        }
        if(repeats.length > 1) {
          repeats[repeats.length - 1] = `and ${repeats[repeats.length - 1]}`
        }
        return `repeats every ${repeats.join(", ")}`
      default:
        return ""
    }
  }

  /**
   * Returns a string representation of this IntervalTime
   * @param {Object} opts Optional arguements
   * @param {boolean} [opts.showMilliseconds=true] If false, don't print milliseconds
   * @param {boolean} [opts.showMeridian=false] If true, print time of day as am/pm rather than in military time
   * @param {boolean} [opts.noRepeats=false] If true, do not show how often an IntervalTime of type interval repeats
   * @returns {string} a string representation of this
   */
  toString(opts={}) {
    let {showMilliseconds=true, showMeridian=false, noRepeats=false} = opts
    let day = this.printDay(opts)
    if(day) {
      day = `${day} `
    }
    let toReturn = `${day}${this.printTimeOfDay({showMilliseconds, showMeridian})}`
    if(this.period === "interval" && !noRepeats) {
      toReturn = `${toReturn}, ${this.printRepeats(opts)}`
    }
    return toReturn
  }

  /**
   * Returns a string representation of this IntervalTime in the same format used by Castus Schedules
   * @returns {string} A string representaiton of this, for use in a schedule
   */
  toScheduleString() {
    let toReturn = this.printTimeOfDay({
      subsecondDigits: this.millisecond() ? 3 : 0,
      showMilliseconds: !!this.millisecond(),
      allowNoSeconds: true
    })
    switch(this.period) {
      case "yearly":
        toReturn = `month ${this.month() + 1} day ${this.date()} ${toReturn}`
        break;
      case "monthly":
        toReturn = `day ${this.date()} ${toReturn}`
        break;
      case "weekly":
        toReturn = `${WEEKDAYS_SHORT[this.weekday()]} ${toReturn}`
        break;
      case "interval":
        if(this.intervalday()) {
          toReturn = `day ${this.intervalday()} ${toReturn}`
        } else {
          let basis = this.basis()
          if(!basis) {
            throw new Error(`Tried to create a schedule string out of an "interval" period IntervalTime that did not have a basis. If you are using IntervalTime with interval schedules, make sure that the interval basis of the interval schedule is passed in as the "basis" attribute of the intervalDuration when constructing the IntervalTime`);
          }
          let closestAfter = this.closestDate(basis, {forceDirection: "after"})
          let day = Math.floor((closestAfter.valueOf() - basis.valueOf()) / DAY)
          toReturn = `day ${day + 1} ${toReturn}`
        }
        break;
      default:
        break;
    }
    return toReturn
  }

  /**
   * Returns whether this IntervalTime occurs on the same day as the given Date or Moment
   * @param {(moment.Moment|Date)} compareTo A Date or Moment to compare this IntervalTime to
   * @returns {boolean} whether this IntervalTime and the given date occur on the same day or not
   */
  isOnSameDay(compareTo) {
    if(moment.isMoment(compareTo)) {
      compareTo = compareTo.toDate()
    }
    switch(this.period) {
      // Daily by definition occurs every day
      case "daily":
        return true;
      case "weekly":
        return compareTo.getDay() === this.weekday();
      case "monthly":
        return compareTo.getDate() === this.date();
      case "yearly":
        return (compareTo.getDate() === this.date() &&
          compareTo.getMonth() === this.month())
      case "interval":
        let closest = this.closestDate(compareTo, {trySameDay: true})
        return closest && (closest.getFullYear() === compareTo.getFullYear() &&
          closest.getMonth() === compareTo.getMonth() &&
          closest.getDate() === compareTo.getDate())
      default:
        return false;
    }
  }

  /**
   * Creates a javascript Date object representing the closest date that matches this IntervalTime to a given date.
   * @param {(moment.Moment|Date)} target The date to which the returned Date object should be closest. Can be a moment or a date.
   * @param {Object} [opts={}] Optional arguements
   * @param {string} [opts.forceDirection] If opts.forceDirection is "after", then the closest date that occurs after
   *  toDate will be returned. If opts.forceDirection is "before", then the closest date that occurs before toDate will
   *  be returned. Otherwise, the closest Date will be returned whether it occurs before or after toDate (with priority given to
   *  after in the case of a tie).
   * @param {boolean} [opts.trySameDay=false] If opts.trySameDay is true, then the closest date/time that occurs on the same day as target will
   *  be returned, if possible. If it is not possible, then the closest date/time will be returned as normal.
   * @param {boolean} [opts.notSameTime=false] If opts.notSameTime is true, then the closest date/time that is not the same as target will be returned.
   *  Defaults to false
   * @returns {Date}
   */
  closestDate(target, opts={}) {
    let {forceDirection, trySameDay=false, notSameTime=false} = opts;
    if(moment.isMoment(target)) {
      target = target.clone().toDate()
    }
    if(!(target instanceof Date)) {
      throw new Error(`Trying to find the closest date to something that isn't a date: ${target} which is of type ${typeof target}. IntervalTime.closestDate and IntervalTime.closestMoment can only find the closest date to a Date or Moment object`);
    }
    if(this.period === "interval") {
      return this.closestIntervalDate(target, opts)
    }
    target = tzToUTC(target)
    let year, month, date;
    year = target.getUTCFullYear();
    switch(this.period) {
      case "monthly":
        month = target.getUTCMonth();
        date = this.date();
        break;
      case "weekly":
        month = target.getUTCMonth();
        let dayDifference = target.getUTCDay() - this.day()
        date = target.getUTCDate() - dayDifference;
        break;
      case "daily":
        month = target.getUTCMonth();
        date = target.getUTCDate();
        break;
      default:
        month = this.month();
        date = this.date();
        break;
    }
    let beforeDate, afterDate;
    let possibleDate = new Date(Date.UTC(
      year,
      month,
      date,
      this.hour(),
      this.minute(),
      this.second(),
      this.millisecond()
    ));
    // Leap year
    if(this.month() === 1 && this.date() === 29 && year % 4 !== 0) {
      year = year - (year % 4)
      possibleDate = new Date(Date.UTC(
        year,
        month,
        date,
        this.hour(),
        this.minute(),
        this.second(),
        this.millisecond()
      ));
    }
    // Make sure we're still on the right day of the month for monthly periods
    if(this.period === "monthly") {
      while(possibleDate.getUTCDate() !== this.date()) {
        possibleDate.setUTCDate(this.date())
      }
    }
    // They are the same date
    if(possibleDate.valueOf() === target.valueOf()) {
      if(notSameTime) {
        beforeDate = subtractPeriodUTC(possibleDate, this.period)
        afterDate = addPeriodUTC(possibleDate, this.period)
      } else {
        return UTCToTz(possibleDate);
      }
    // The generated date is before
    } else if (possibleDate.valueOf() < target.valueOf()) {
      beforeDate = possibleDate
    // The generated date is after
    } else {
      afterDate = possibleDate
    }

    // Generate the closest date on the other side
    if(beforeDate && !afterDate) {
      afterDate = addPeriodUTC(beforeDate, this.period)
    } else if (afterDate && !beforeDate) {
      beforeDate = subtractPeriodUTC(afterDate, this.period)
    }

    // If forceDirection is after or backward, return the appropriate generated date
    if(forceDirection === "after" && beforeDate.valueOf() < target.valueOf()) {
      return UTCToTz(afterDate);
    } else if (forceDirection === "before" && afterDate.valueOf() > target.valueOf()) {
      return UTCToTz(beforeDate)
    // Otherwise, see which generated date is closer and return that one
    } else {
      // If trySameDay is true, and only one of the two possible dates is on the same day as target,
      //  return that date regardless of distance.
      if(trySameDay) {
        if((afterDate.getUTCFullYear() !== target.getUTCFullYear() || afterDate.getUTCMonth() !== target.getUTCMonth() || afterDate.getUTCDate() !== target.getUTCDate()) &&
          beforeDate.getUTCFullYear() === target.getUTCFullYear() && beforeDate.getUTCMonth() === target.getUTCMonth() && beforeDate.getUTCDate() === target.getUTCDate()) {
          return UTCToTz(beforeDate)
        }
        if((beforeDate.getUTCFullYear() !== target.getUTCFullYear() || beforeDate.getUTCMonth() !== target.getUTCMonth() || beforeDate.getUTCDate() !== target.getUTCDate()) &&
          afterDate.getUTCFullYear() === target.getUTCFullYear() && afterDate.getUTCMonth() === target.getUTCMonth() && afterDate.getUTCDate() === target.getUTCDate()) {
          return UTCToTz(afterDate)
        }
      }
      let diffBefore = Math.abs(beforeDate.valueOf() - target.valueOf())
      let diffAfter = Math.abs(afterDate.valueOf() - target.valueOf())
      if(diffAfter <= diffBefore) {
        return UTCToTz(afterDate)
      } else {
        return UTCToTz(beforeDate)
      }
    }
  }

  closestIntervalDate(target, opts={}) {
    if(this.period !== "interval") {
      console.warn("Trying to use closestIntervalDate with an IntervalTime that has a period other than interval. Using closestDate instead")
      return this.closestDate(target, opts)
    }
    let {forceDirection, trySameDay=false} = opts;
    if(moment.isMoment(target)) {
      target = target.toDate()
    }
    if(!(target instanceof Date)) {
      throw new Error(`Trying to find the closest date to something that isn't a date: ${target} which is of type ${typeof target}. IntervalTime.closestDate and IntervalTime.closestMoment can only find the closest date to a Date or Moment object`);
    }
    if(this.intervalday() && this.basis()) {
      if(this.value() > periodMillis("interval", this.intervalDuration)) {
        // This IntervalDate falls outside of the interval, so there isn't one
        return null
      }
      let basis = this.basis()
      let millis = periodMillis("interval", this.intervalDuration)
      let possibilities = []
      let closest = basis.valueOf() + this.value()
      while(Math.abs(target.valueOf() - closest) > millis) {
        if(closest > target.valueOf()) {
          closest -= millis
        } else if(closest < target.valueOf()) {
          closest += millis
        }
      }
      possibilities.push(closest)
      possibilities.push(closest + millis)
      possibilities.push(closest - millis)
      possibilities.push(closest + 2 * millis)
      possibilities.push(closest - 2 * millis)
      // Eliminate based on options
      if(forceDirection === "after") {
        possibilities = possibilities.filter((pos) => {
          return pos > target.valueOf()
        })
      }
      if(forceDirection === "before") {
        possibilities = possibilities.filter((pos) => {
          return pos < target.valueOf()
        })
      }
      if(trySameDay) {
        let sameDayPossibilities = possibilities.filter((pos) => {
          let posDate = new Date(pos)
          return (posDate.getFullYear() === target.getFullYear()) &&
            (posDate.getMonth() === target.getMonth()) &&
            (posDate.getDate() === target.getDate())
        })
        if(sameDayPossibilities.length) {
          possibilities = sameDayPossibilities
        }
      }
      // sort based on closest, then return
      possibilities = possibilities.sort((a, b) => {
        return Math.abs(a - target.valueOf()) - Math.abs(b - target.valueOf())
      })
      return new Date(possibilities[0])
    } else {
      target = tzToUTC(target)
      let {milliseconds = 0, seconds = 0, minutes = 0, hours = 0, days = 0, weeks = 0, months = 0, years = 0} = this.intervalDuration
      let currentDate = new Date(Date.UTC(this.year(), this.month(), this.date(), this.hour(), this.minute(), this.second(), this.millisecond()))
      let nextDate = new Date(currentDate.valueOf())
      let currentDifference = target.valueOf() - currentDate.valueOf()
      let nextDifference = currentDifference
      while(Math.abs(currentDifference) >= Math.abs(nextDifference)) {
        currentDate = new Date(nextDate.valueOf());
        currentDifference = nextDifference;
        // same date, just return currentDate
        if(currentDifference === 0) {
          break;
        // behind target, move forward
        } else if(currentDifference > 0) {
          if(milliseconds) {
            nextDate.setUTCMilliseconds(nextDate.getUTCMilliseconds() + milliseconds)
          }
          if(seconds) {
            nextDate.setUTCSeconds(nextDate.getUTCSeconds() + seconds)
          }
          if(minutes) {
            nextDate.setUTCMinutes(nextDate.getUTCMinutes() + minutes)
          }
          if(hours) {
            nextDate.setUTCHours(nextDate.getUTCHours() + hours)
          }
          if(days) {
            nextDate.setUTCDate(nextDate.getUTCDate() + days)
          }
          if(weeks) {
            nextDate.setUTCDate(nextDate.getUTCDate() + (weeks * 7))
          }
          if(months) {
            nextDate.setUTCMonth(nextDate.getUTCMonth() + months)
          }
          if(years) {
            nextDate.setUTCFullYear(nextDate.getUTCFullYear() + years)
          }
        // ahead of target, move backwards
        } else {
          if(milliseconds) {
            nextDate.setUTCMilliseconds(nextDate.getUTCMilliseconds() - milliseconds)
          }
          if(seconds) {
            nextDate.setUTCSeconds(nextDate.getUTCSeconds() - seconds)
          }
          if(minutes) {
            nextDate.setUTCMinutes(nextDate.getUTCMinutes() - minutes)
          }
          if(hours) {
            nextDate.setUTCHours(nextDate.getUTCHours() - hours)
          }
          if(days) {
            nextDate.setUTCDate(nextDate.getUTCDate() - days)
          }
          if(weeks) {
            nextDate.setUTCDate(nextDate.getUTCDate() - (weeks * 7))
          }
          if(months) {
            nextDate.setUTCMonth(nextDate.getUTCMonth() - months)
          }
          if(years) {
            nextDate.setUTCFullYear(nextDate.getUTCFullYear() - years)
          }
        }
        // get new difference
        nextDifference = target.valueOf() - nextDate.valueOf()
        // either we got farther away or we crossed the target. Either way, break now.
        if(Math.abs(currentDifference) < Math.abs(nextDifference) ||
          Math.sign(currentDifference) !== Math.sign(nextDifference)) {
          break;
        }
      }
      if(nextDifference === 0) {
        return UTCToTz(nextDate)
      }
      if(currentDifference === 0) {
        return UTCToTz(currentDate)
      }
      if(trySameDay) {
        let currentSameDay = (currentDate.getUTCFullYear() === target.getUTCFullYear() &&
          currentDate.getUTCMonth() === target.getUTCMonth() &&
          currentDate.getUTCDate() === target.getUTCDate())
        let nextSameDay = (nextDate.getUTCFullYear() === target.getUTCFullYear() &&
          nextDate.getUTCMonth() === target.getUTCMonth() &&
          nextDate.getUTCDate() === target.getUTCDate())
        if(currentSameDay && !nextSameDay) {
          return UTCToTz(currentDate)
        } else if (nextSameDay && !currentSameDay) {
          return UTCToTz(nextDate)
        }
      }
      if(forceDirection === "after") {
        if(currentDifference > 0 && nextDifference < 0) {
          return UTCToTz(nextDate)
        } else if(currentDifference < 0 && nextDifference > 0) {
          return UTCToTz(currentDate)
        }
      } else if(forceDirection === "before") {
        if(currentDifference > 0 && nextDifference < 0) {
          return UTCToTz(currentDate)
        } else if(currentDifference < 0 && nextDifference > 0) {
          return UTCToTz(nextDate)
        }
      }
      if(Math.abs(currentDifference) <= Math.abs(nextDifference)) {
        return UTCToTz(currentDate)
      } else {
        return UTCToTz(nextDate)
      }
    }
  }

  /**
   * Creates a Moment object representing the closest date that matches this IntervalTime to a given date.
   * @param {(moment.Moment|Date)} target The date to which the returned Moment should be closest. Can be a moment or a date.
   * @param {Object} [opts={}] Optional arguements
   * @param {string} [opts.forceDirection] If opts.forceDirection is "after", then the closest date that occurs after
   *  toDate will be returned. If opts.forceDirection is "before", then the closest date that occurs before toDate will
   *  be returned. Otherwise, the closest date will be returned whether it occurs before or after toDate (with priority given to
   *  after in the case of a tie).
   * @param {boolean} [opts.trySameDay=false] If opts.trySameDay is true, then the closest date/time that occurs on the same day as target will
   *  be returned, if possible. If it is not possible, then the closest date/time will be returned as normal.
   * @param {boolean} [opts.notSameTime=false] If opts.notSameTime is true, then the closest date/time that is not the same as target will be returned.
   *  Defaults to false
   * @returns {Moment} the closest occurrence of this IntervalTime to the target
   */
  closestMoment(target, opts={}) {
    return moment(this.closestDate(target, opts))
  }

  /**
   * Returns the number of milliseconds since the beginning of the period that this IntervalTime occurs at.
   * @param {Object} [opts={}] Optional arguements
   * @param {boolean} [opts.notLeapYear=false] When getting the value of a yearly IntervalTime, the value will differ
   *  between leapYears and non-leapYears. By default, this function assumes that it IS a leap year. If
   *  you want the value for when it is not a leap year, set this boolean to true.
   * @returns {number} a number of seconds since the beginning of the period (year, month, week, or day) that this IntervalTime
   *  occurs at
   */
  value({...opts}) {
    if(this.period === "interval") {
      return this.valueInterval(opts)
    }
    let {notLeapYear} = opts
    let value = 0

    // days
    if(this.month()) {
      for(let i = 0; i < this.month(); i++) {
        value += notLeapYear ? DAYS_PER_MONTH[i] : LEAPYEAR_DAYS_PER_MONTH[i]
      }
    }
    if((this.date() - 1) > 0) {
      value += (this.date() - 1)
    }
    if(this.weekday()) {
      value += this.weekday()
    }
    // hours
    value = value * 24
    value += this.hour()
    // minutes
    value = value * 60
    value += this.minute()
    // seconds
    value = value * 60
    value += this.second()
    // milliseconds
    value = value * 1000
    value += this.millisecond()

    return value
  }

  valueInterval({...opts}) {
    if(this.period !== "interval") {
      return this.value(opts)
    }
    // If not given a basis, then the IntervalTime's time is assumed to be the basis,
    //  in which case the value is always 0 (as the time of the IntervalTime is 0 milliseconds
    //  after the start of its repeating interval)
    if(!this.intervalDuration.basis) {
      return 0
    }
    let basis = this.basis()
    if(this.intervalday()) {
      let toReturn = 0
      toReturn += (this.millisecond() || 0)
      toReturn += ((this.second() || 0) * 1000)
      toReturn += ((this.minute() || 0) * 60 * 1000)
      toReturn += ((this.hour() || 0) * 60 * 60 * 1000)
      toReturn += (((this.intervalday() - 1) || 0) * DAY)
      return toReturn
    } else {
      let closestAfter = this.closestDate(basis, {forceDirection: "after"})
      return closestAfter.valueOf() - basis.valueOf()
    }
  }

  // For extra interchangeability with moment.js
  valueOf(...args) {
    return this.value(...args)
  }

  /**
   * Gets the difference in milliseconds between this IntervalTime and another IntervalTime, a Moment, or a Date.
   * @param {(IntervalTime|moment.Moment|Date)} against The time to diff this IntervalTime against.
   * @param {Object} [opts={}] Optional arguements
   * @param {boolean} [opts.trySameDay=false] If set to true, and against is a Date or Moment, then this function will always try to diff
   *  against an occurrence of this IntervalTime that occurs on the same day as against, even if another occurrence would technically be
   *  closer.
   * @param {boolean} [opts.useSmallestIntervalDifference=false] If set to true, and two IntervalTimes are being compared, then
   *  the smallest difference between any two occurences of those IntervalTimes will be returned.
   * @returns {number} A number of milliseconds difference between this IntervalTime and against.
   *  If against occurs after this IntervalTime within a given period span (i.e. within a given month), the returned value will be negative.
   */
  diff(against, opts={}) {
    let {trySameDay=false, useSmallestIntervalDifference=false} = opts
    if(against instanceof IntervalTime) {
      if(this.period !== against.period) {
        //throw new Error(`Tried to diff two IntervalTimes whose periods did not match; this is not allowed. One was ${this.period} and the other was ${against.period}.`)
        let closestAgainst = against.closestDate(new Date())
        let thisAgainst = this.closestDate(closestAgainst)
        closestAgainst = against.closestDate(thisAgainst)
        return thisAgainst.valueOf() - closestAgainst.valueOf()
      }
      if(this.period === "interval") {
        let basisDate
        if(this.intervalday() && this.basis()) {
          if(against.intervalday()) {
            return this.value() - against.value()
          }
          basisDate = this.basis()
        } else {
          basisDate = new Date(this.year(), this.month(), this.date(), this.hour(), this.minute(), this.second(), this.millisecond())
        }
        let closestAgainst = against.closestDate(basisDate, {trySameDay: true})
        let thisAgainst = this.closestDate(closestAgainst, {trySameDay: true})
        return thisAgainst.valueOf() - closestAgainst.valueOf()
      }
      if(useSmallestIntervalDifference) {
        let diffA = this.value() - against.value()
        let diffB;
        if(diffA === 0) {
          return diffA
        } else if (diffA < 0) {
          diffB = (this.value() + periodMillis(this.period)) - against.value()
        } else {
          diffB = this.value() - (against.value() + periodMillis(against.period))
        }
        if(Math.abs(diffA) <= Math.abs(diffB)) {
          return diffA
        } else {
          return diffB
        }
      } else {
        return this.value() - against.value()
      }
    } else if(moment.isMoment(against)) {
      let thisMoment = this.closestMoment(against, {trySameDay})
      return thisMoment.diff(against)
    } else if(against instanceof Date) {
      let thisDate = this.closestDate(against, {trySameDay})
      return thisDate.valueOf() - against.valueOf()
    }
  }

  /**
   * Changes the basis of an "interval" period IntervalTime.
   *  If this is not an "interval" period IntervalTime, then this method does nothing
   *  This function will also change this IntervalTime's time so that it is the same
   *  distance from its new basis as it was from its old basis
   * @param {(Date|moment.Moment)} newBasis A Date or Moment to use as the new basis. Passing anything
   *  other than a Date or Moment will throw.
   * @returns {IntervalTime} a copy of this with the basis changed
   */
  setIntervalBasis(newBasis) {
    if(this.period !== "interval") {
      return this
    }
    let toReturn = new IntervalTime(this);
    if(!(newBasis instanceof Date) && !(moment.isMoment(newBasis))) {
      throw new Error(`Tried to set the basis of an interval period IntervalTime to ${newBasis}. Trying to set the basis of an interval period IntervalTime to something other than a Date or Moment is not allowed.`)
    }
    if(moment.isMoment(newBasis)) {
      newBasis = newBasis.toDate()
    }
    let basis = this.basis()
    let difference = 0
    if(basis && !this.time.intervalday) {
      let closestAfterBasis = toReturn.closestDate(basis, {forceDirection: "after"})
      let currentDifference = closestAfterBasis.valueOf() - basis.valueOf()
      let nextTime = new Date(newBasis.valueOf() + currentDifference)
      difference = nextTime.valueOf() - closestAfterBasis.valueOf()
    }
    toReturn.intervalDuration.basis = newBasis
    return toReturn.add(difference)
  }

  /**
   * Sets the intervalDuration of an "interval" period IntervalTime.
   * @param {Object} newDuration An object containing a map of duration values with one or more of the following keys:
   *  "years", "months", "weeks", "days", "hours", "minutes", "seconds", "milliseconds". May also include a "basis"
   *  for the interval. If a basis is not included, and the current intervalDuration does possess a basis, then the basis
   *  will automatically be carried over to the new duration. No other keys will be automatically carried over. If a basis
   *  is included, it will replace the old basis, but the difference between the old basis and time will not be carried
   *  over to the new basis, as it is with setIntervalBasis.
   * @returns {IntervalTime} a copy of this with the intervalDuration changed.
   */
  setIntervalDuration(newDuration) {
    if(this.period !== "interval") {
      return this
    }
    let toReturn = new IntervalTime(this);
    if(!isAnIntervalDuration(newDuration)) {
      throw new Error(`Tried to set the interval duration of an interval period IntervalTime to something that wasn't a valid interval duration: ${newDuration}`)
    }
    toReturn.intervalDuration = {basis: toReturn.intervalDuration.basis, ...newDuration}
    return toReturn
  }

}

function isAnIntervalDuration(maybeIntervalDuration) {
  return (maybeIntervalDuration &&
    typeof maybeIntervalDuration === "object" && (
    maybeIntervalDuration.years ||
    maybeIntervalDuration.months ||
    maybeIntervalDuration.weeks ||
    maybeIntervalDuration.days ||
    maybeIntervalDuration.hours ||
    maybeIntervalDuration.minutes ||
    maybeIntervalDuration.seconds ||
    maybeIntervalDuration.milliseconds
  ))
}

function interpretMomentTime(time, period, intervalDuration={}) {
  return interpretDateTime(time.toDate(), period, intervalDuration)
  /*
  let timeData = {
    hour: time.hour(),
    minute: time.minute(),
    second: time.second(),
    millisecond: time.millisecond()
  }
  switch(period) {
    case "interval":
      timeData.year = time.year();
      timeData.month = time.month();
      timeData.date = time.date();
      break;
    case "yearly":
      intervalDuration = {years: 1}
      timeData.month = time.month();
      timeData.date = time.date();
      break;
    case "monthly":
      intervalDuration = {months: 1}
      timeData.date = time.date();
      break;
    case "weekly":
      intervalDuration = {weeks: 1}
      timeData.weekday = time.day();
      break;
    case "daily":
      intervalDuration = {days: 1}
      break;
    default:
      period = "yearly"
      intervalDuration = {years: 1}
      timeData.month = time.month();
      timeData.date = time.date();
      break;
  }
  return {
    time: timeData,
    period,
    intervalDuration
  }
  */
}

function interpretDateTime(time, period, intervalDuration={}) {
  let timeData = {
    hour: time.getHours(),
    minute: time.getMinutes(),
    second: time.getSeconds(),
    millisecond: time.getMilliseconds()
  }
  switch(period) {
    case "interval":
      let {basis} = intervalDuration
      if(basis) {
        if(moment.isMoment(basis)) {
          basis = basis.toDate()
        }
        let difference = time.valueOf() - basis.valueOf()
        let intervalMillis = periodMillis(period, intervalDuration)
        while(difference < 0 && intervalMillis > 0) {
          difference += intervalMillis
        }
        difference = difference % intervalMillis
        let intervalday = 1 + Math.floor(difference / DAY)
        let hour = Math.floor((difference % DAY) / HOUR)
        let minute = Math.floor((difference % HOUR) / MINUTE)
        let second = Math.floor((difference % MINUTE) / 1000)
        let millisecond = difference % 1000
        timeData = {
          ...timeData,
          intervalday,
          hour,
          minute,
          second,
          millisecond
        }
      } else {
        timeData.year = time.getFullYear();
        timeData.month = time.getMonth();
        timeData.date = time.getDate();
      }
      break;
    case "yearly":
      intervalDuration = {years: 1}
      timeData.month = time.getMonth();
      timeData.date = time.getDate();
      break;
    case "monthly":
      intervalDuration = {months: 1}
      timeData.date = time.getDate();
      break;
    case "weekly":
      intervalDuration = {weeks: 1}
      timeData.weekday = time.getDay();
      break;
    case "daily":
      intervalDuration = {days: 1}
      break;
    default:
      intervalDuration = {years: 1}
      period = "yearly"
      timeData.month = time.getMonth();
      timeData.date = time.getDate();
      break;
  }
  return {
    time: timeData,
    period,
    intervalDuration
  }
}

function interpretNumberTime(time, period, intervalDuration={}) {
  return interpretDateTime(new Date(time), period, intervalDuration)
}

function interpretStringTime(time, period, intervalDuration={}) {
  // Support for schedule string formats
  // yearly
  if(YEARLY_TIME_REGEX.test(time) && (!period || period === "yearly")) {
    if(!period) {
      period = "yearly"
    }
    let matchData = YEARLY_TIME_REGEX.exec(time)
    return {
      time: compileScheduleStringMatchData(matchData),
      period,
      intervalDuration: {"years": 1}
    }
  // interval (string format is the same as monthly, so the period must be specified as "interval")
  } else if(MONTHLY_TIME_REGEX.test(time) && period === "interval") {
    let {basis} = intervalDuration
    if(!basis || (!(basis instanceof Date) && !(moment.isMoment(basis)))) {
      throw new Error(`Trying to construct an "interval" period IntervalTime from a string containing "doi" (date of interval), but no Date or Moment basis was given to use to determine on which the given date of interval actually falls.`);
    }
    if(moment.isMoment(basis)) {
      basis = basis.toDate()
    }
    let matchData = MONTHLY_TIME_REGEX.exec(time)
    let intervalTime = compileScheduleStringMatchData(matchData)
    //basis.setDate(basis.getDate() + (intervalTime.date - 1))
    return {
      time: {
        ...intervalTime,
        intervalday: intervalTime.date,
        //year: basis.getFullYear(),
        //month: basis.getMonth(),
        //date: basis.getDate()
      },
      period,
      intervalDuration
    }
  // monthly
  } else if(MONTHLY_TIME_REGEX.test(time) && (!period || period === "monthly")) {
    if(!period) {
      period = "monthly"
    }
    let matchData = MONTHLY_TIME_REGEX.exec(time)
    return {
      time: compileScheduleStringMatchData(matchData),
      period,
      intervalDuration: {"months": 1}
    }
  // weekly
  } else if(WEEKLY_TIME_REGEX.test(time) && (!period || period === "weekly")) {
    if(!period) {
      period = "weekly"
    }
    let matchData = WEEKLY_TIME_REGEX.exec(time)
    return {
      time: compileScheduleStringMatchData(matchData),
      period,
      intervalDuration: {"weeks": 1}
    }
  // daily
  } else if(DAILY_TIME_REGEX.test(time) && (!period || period === "daily")) {
    if(!period) {
      period = "daily"
    }
    let matchData = DAILY_TIME_REGEX.exec(time)
    return {
      time: compileScheduleStringMatchData(matchData),
      period,
      intervalDuration: {"days": 1}
    }
  // unknown
  } else {
    // If not a supported schedule string format, fallback to Date.parse
    let dateTime = Date.parse(time);
    if(isNaN(dateTime)) {
      throw new Error(`The browser was not able to interpret ${time} as a string date/time.`);
    }
    return interpretDateTime(new Date(dateTime), period, intervalDuration);
  }
}

function compileScheduleStringMatchData(matchData) {
  let [month, date, weekday, hour, minute, second, millisecond, meridian] = matchData.slice(1)
  month = month ? parseInt(month, 10) - 1 : undefined
  date = date ? parseInt(date, 10) : undefined
  weekday = weekday ? WEEKDAYS_SHORT.indexOf(weekday) : undefined
  hour = parseInt(hour, 10)
  minute = parseInt(minute, 10)
  second = second ? parseInt(second, 10) : 0
  millisecond = millisecond ? parseInt(millisecond.padEnd(3, "0")) : 0
  if(meridian.toLowerCase() === "pm" && hour !== 12) {
    hour = hour + 12
  } else if(meridian.toLowerCase() === "am" && hour === 12) {
    hour = 0
  }
  if((date || date === 0) &&
    (month || month === 0) &&
    date > LEAPYEAR_DAYS_PER_MONTH[month]) {
    throw new Error(`Got an invalid month/date combination in a yearly schedule string: month ${month} date ${date}`)
  }
  return {
    month,
    date,
    weekday,
    hour,
    minute,
    second,
    millisecond
  }
}

function interpretObjectTime(time, period, intervalDuration={}) {
  let {hour = 0, minute = 0, second = 0, millisecond = 0} = time
  let returnTime = {hour, minute, second, millisecond}
  // If there is a period specified, try to get the time components for it
  if(period) {
    switch(period) {
      case "interval": {
        if(time.day || time.intervalday) {
          returnTime = {...returnTime, intervalday: (time.day || time.intervalday)}
        } else {
          let {year = 1970, month = 0, date = 1} = time
          returnTime = {...returnTime, year, month, date}
        }
        break;
      }
      case "yearly": {
        intervalDuration = {"years": 1}
        let {month = 0, date = 1} = time
        returnTime = {...returnTime, month, date}
        break;
      }
      case "monthly": {
        intervalDuration = {"months": 1}
        let {date = 1} = time
        returnTime = {...returnTime, date}
        break;
      }
      case "weekly": {
        intervalDuration = {"weeks": 1}
        let {weekday = 0} = time
        returnTime = {...returnTime, weekday}
        break;
      }
      case "daily": {
        intervalDuration = {"days": 1}
        break;
      }
      default:
        break;
    }
  // If there is no period, see if time components for a period were specified
  //  and autodetect period based on that
  } else {
    if("month" in time && "date" in time) {
      period = "yearly"
      intervalDuration = {"years": 1}
      let {month = 0, date = 1} = time
      returnTime = {...returnTime, month, date}
    } else if("date" in time) {
      period = "monthly"
      intervalDuration = {"months": 1}
      let {date = 1} = time
      returnTime = {...returnTime, date}
    } else if("weekday" in time) {
      period = "weekly"
      intervalDuration = {"weeks": 1}
      let {weekday = 0} = time
      returnTime = {...returnTime, weekday}
    } else {
      period = "daily"
      intervalDuration = {"days": 1}
    }
  }
  return {
    time: returnTime,
    period,
    intervalDuration
  }
}

function carryUnits(value, maximumUnits) {
  return [
    Math.floor(value / maximumUnits),
    boundUnits(value, maximumUnits)
  ]
}

function boundUnits(value, maximumUnits) {
  value = value % maximumUnits
  if(value < 0) {
    value = maximumUnits + value
  }
  if(value === 0) {
    value = 0
  }
  return value
}

/**
 * Helper that gets the number of milliseconds in a given schedule period
 * @param {string} period Period to get milliseconds of (either "yearly", "monthly", "weekly", or "daily")
 * @returns {number} Integer number of milliseconds in the given schedule period. Assumes 31 days in a month, and assumes
 *  it is a leap year.
 */
function periodMillis(period, intervalDuration={}) {
  switch(period) {
    case "interval":
      if(!intervalDuration || !isAnIntervalDuration(intervalDuration)) {
        throw new Error("Trying to get millis of an interval duration, but the passed in value for intervalDuration is not a valid intervalDuration")
      }
      let totalTime = 0
      totalTime += (intervalDuration.milliseconds || 0)
      totalTime += ((intervalDuration.seconds || 0) * 1000)
      totalTime += ((intervalDuration.minutes || 0) * 60 * 1000)
      totalTime += ((intervalDuration.hours || 0) * 1000 * 60 * 60)
      totalTime += ((intervalDuration.days || 0) * DAY)
      totalTime += ((intervalDuration.weeks || 0) * WEEK)
      totalTime += ((intervalDuration.months || 0) * MONTH)
      totalTime += ((intervalDuration.years || 0) * YEAR)
      return totalTime
    case "yearly":
      return YEAR
    case "monthly":
      return MONTH
    case "weekly":
      return WEEK
    case "daily":
      return DAY
    default:
      throw new Error(`Unrecognized interval period: ${period}. Period must be a string of "yearly", "monthly", "weekly", or "daily"`)
  }
}

/* Code related to manual parsing of string time/date; commented out for now, may revisit later.
function interpretStringTime(time, period) {
  // Separate time and date strings
  let timeData = {}
  let [timeOfDay, ...date] = time.split(' ');
  timeOfDay = timeOfDay.trim().toLowerCase()
  if(!date) {
    // If there is no date string detected, and no period, auto-detect period as "daily"
    if(!period) {
      period = "daily"
    // If a period was given other than "daily" and no date string was detected, throw an error.
    } else if(period !== "daily") {
      throw new Error(`The period given was ${period}, but no date was detected in the time given: ${time}.
        When using a string value for a non-daily period, time must be given followed by date, and the two must be separated by a space`);
    }
  }
  // Interpret the time string
  timeData = {...timeData, ...timeStringToObject(timeOfDay)};
  // If the period is daily, then we are done
  if(period === "daily") {
    return {
      time: timeData,
      period
    }
  }
  // Interpret the date string
  date = date.join(' ').trim().toLowerCase()
  // Handle no date string
  if(!date) {
    // If there is no date string detected and no period given, period is auto-set to "daily" and we are done.
    if(!period) {
      period = "daily"
      return {
        time: timeData,
        period
      }
    // If a period was given other than "daily" and no date string was detected, throw an error.
    } else {
      throw new Error(`The period given was ${period}, but no date was detected in the time given: ${time}.
        When using a string value for a non-daily period, time must be given followed by date, and the two must be separated by a space`);
    }
  // Handle plain number
  } else if(date.match(/^\d{1,2}$/)) {
    switch(period) {
      case "yearly":
        // If the period is yearly, just one number is ambiguous so throw
        throw new Error(`The period was ${period}, and the value detected for date was just a number: ${date}. This is too ambiguous to be used for constructing a yearly reoccuring date.`);
      case "weekly":
        date = parseInt(date, 10);
        date = boundUnits(date, 7);
        timeData.weekday = date;
        break;
      // Handling both "monthly" and blank period values
      default:
        // Autodetect period as monthly
        if(period !== "monthly") {
          period = "monthly"
        }
        // If monthly, treat it as the day of the month.
        date = parseInt(date, 10);
        date = boundUnits(date, 31) + 1;
        timeData.date = date;
        break;
    }
  }
}

function timeStringToObject(timeString) {
  // See if it has a meridian or not
  let meridianMatch = timeString.match(/^([\d:.]+)(?:\s*([ap])m)?$/)
  let meridian = null
  let timeNoMeridian = meridianMatch[1]
  if(meridianMatch.length === 3) {
    meridian = meridianMatch[2]
  }
  // Next, split the time into hours, minutes, and seconds
  let [hours, minutes, seconds] = timeNoMeridian.split(":")
  let milliseconds = 0
  // If there are no seconds, then set seconds to 0
  if(!seconds) {
    seconds = 0
  // Split seconds into seconds and milliseconds (if there are any)
  } else {
    let [sec, millis] = seconds.split(".")
    seconds = parseInt(sec, 10)
    if(millis) {
      milliseconds = parseInt(millis, 10)
    }
  }
  // Parse hours/minutes as int
  hours = parseInt(hours, 10)
  minutes = parseInt(minutes, 10)
  // Add 12 hours if time is in 12 hour time pm to get 24 hour time
  if(meridian === "p") {
    hours = hours + 12
  }
  // Handle time wrapping for minutes/seconds/milliseconds
  let [addSeconds, newMillis] = carryUnits(milliseconds, 1000);
  milliseconds = newMillis;
  let [addMinutes, newSecs] = carryUnits(seconds + addSeconds, 60);
  seconds = newSecs;
  let [addHours, newMins] = carryUnits(minutes + addMinutes, 60);
  minutes = newMins;
  // Additional days are dropped
  hours = boundUnits(hours + addHours, 24)
  return {
    hours,
    minutes,
    seconds,
    milliseconds
  }
}
*/

export default IntervalTime
