import React, {PureComponent} from 'react'
import {Image} from 'semantic-ui-react'
import {serverPath} from 'helpers/net_helpers'

export default class FilePreviewVideo extends PureComponent {

  render() {
    return (
      <Image src={serverPath(`/v2/files/get/${this.props.path.map(encodeURIComponent).join('/')}?auth_token=${window.AARDVARK_ACCESS}`)} alt="Preview for this image is not available"/>
    )
  }

}
