import React from 'react'
import {staticAsset} from 'helpers/net_helpers'

import {
  Item,
} from 'semantic-ui-react'

import {checkHasAccessToApp} from 'helpers/authentication_helpers'

import './Create.css'

export default props => {
  let {openApplication, appAccess} = props

  let access = {
    playlist: checkHasAccessToApp(appAccess, 'Playlist'),
    simple_playlist: checkHasAccessToApp(appAccess, 'SimplePlaylist'),
    schedule: checkHasAccessToApp(appAccess, 'Schedule'),
    scrolling: checkHasAccessToApp(appAccess, 'ScrollingText'),
    rss: checkHasAccessToApp(appAccess, 'RSSItemEditor'),
    template: checkHasAccessToApp(appAccess, 'MetadataTemplate'),
    report: checkHasAccessToApp(appAccess, 'Reporting'),
  }

  return (
    <div id='create'>
      <div id='create_menu'>
        <Item.Group divided link>
          {access.playlist ? <Item className='noselect' onClick={() => openApplication('Playlist', "", "/?new=1")}>
            <Item.Image src={staticAsset("/icons/castus_cuts_library.svg")} size='tiny'/>
            <Item.Content verticalAlign='middle'>
              <Item.Header>Create a project to arrange a composition of media items</Item.Header>
            </Item.Content>
          </Item> : null}
          {access.simple_playlist ? <Item className='noselect' onClick={() => openApplication('SimplePlaylist', "", "/?new=1")}>
            <Item.Image src={staticAsset("/icons/playlist editor.svg")} size='tiny'/>
            <Item.Content verticalAlign='middle'>
              <Item.Header>Create a playlist to group together media items</Item.Header>
            </Item.Content>
          </Item> : null}
          {access.schedule ? <Item className='noselect' onClick={() => openApplication('Schedule', "", "/?new=1")}>
            <Item.Image src={staticAsset("/icons/schedule small.svg")} size='tiny'/>
            <Item.Content verticalAlign='middle'>
              <Item.Header>Create a schedule to synchronize media items</Item.Header>
            </Item.Content>
          </Item> : null}
          {access.scrolling ? <Item className='noselect' onClick={() => openApplication('ScrollingText', "", "/?new=1")}>
            <Item.Image src={staticAsset("/icons/text crawl.svg")} size='tiny'/>
            <Item.Content verticalAlign='middle'>
              <Item.Header>Create a scrolling text item</Item.Header>
            </Item.Content>
          </Item> : null}
          {access.rss ? <Item className='noselect' onClick={() => openApplication('RSSItemEditor', "", "/?new=1")}>
            <Item.Image src={staticAsset("/icons/rss feed.svg")} size='tiny'/>
            <Item.Content verticalAlign='middle'>
              <Item.Header>Create an RSS feed of media items</Item.Header>
            </Item.Content>
          </Item> : null}
          {access.template ? <Item className='noselect' onClick={() => openApplication('MetadataTemplate', "", "/?new=1")}>
            <Item.Image src={staticAsset("/icons/metadata tag.svg")} size='tiny'/>
            <Item.Content verticalAlign='middle'>
              <Item.Header>Create a metadata template item</Item.Header>
            </Item.Content>
          </Item> : null}
          {access.report ? <Item className='noselect' onClick={() => openApplication('Reporting')}>
            <Item.Image src={staticAsset("/icons/reports.svg")} size='tiny'/>
            <Item.Content verticalAlign='middle'>
              <Item.Header>Create a report</Item.Header>
            </Item.Content>
          </Item> : null}
        </Item.Group>
      </div>
    </div>
  )
}
