import React, {PureComponent} from 'react'

import {Menu} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import ScheduleIntervalDaySelector from './ScheduleIntervalDaySelector'

import moment from 'moment'

import './ScheduleReplicateTimeSelector.css'

const DAYS_OF_THE_WEEK = [
  'SUN',
  'MON',
  'TUE',
  'WED',
  'THU',
  'FRI',
  'SAT'
]

class ScheduleReplicateTimeSelector extends PureComponent {

  render() {
    let {type,
      viewDate,
      noViewHighlight,
      selectedDates,
      toggleDate,
      selectWeekday} = this.props

    let timeHeader = ''
    let intervalOptions = ''

    switch(type) {
      case 'daily':
        break
      case 'weekly':
        let changeWeekDay = (e, data) => {
          toggleDate(data.index)
        }
        let weekButtons = DAYS_OF_THE_WEEK.map((day, dayInd) => {
          let cls = ''
          if(selectedDates.find((date) => date.weekday() === dayInd)) {
            cls = "hasItems"
          }
          return (<Menu.Item index={dayInd}
            className={cls}
            key={dayInd}
            active={viewDate.day() === dayInd}
            onClick={changeWeekDay}>{day}</Menu.Item>)
        })
        timeHeader = (
          <Menu fluid widths={7}>
            {weekButtons}
          </Menu>
        )
        break
      case 'monthly':
      case 'yearly':
      case 'endless':
        let renderDay = (currentDate) => {
          let toReturn = ""
          if((type === "endless" && selectedDates.find((date) => date.date() === currentDate.getDate() && date.month() === currentDate.getMonth() && date.year() === currentDate.getYear())) ||
            (type === "monthly" && selectedDates.find((date) => date.date() === currentDate.getDate())) ||
            (type === "yearly" && selectedDates.find((date) => date.date() === currentDate.getDate() && date.month() === currentDate.getMonth()))) {
            toReturn =  "isSelected"
          }
          if(noViewHighlight) {
            toReturn += " noViewHighlight"
          }
          return toReturn
        }
        timeHeader = (
          <DatePicker inline
            selected={viewDate.toDate()}
            onChange={(value) => toggleDate(moment(value))}
            dayClassName={renderDay}/>
        )
        break
      case 'interval': {
        let {intervalBasis, intervalDuration} = this.props
        timeHeader = (
          <ScheduleIntervalDaySelector intervalBasis={intervalBasis}
            intervalDuration={intervalDuration}
            datelist={selectedDates}
            selectDay={(value) => toggleDate(moment(value))}/>
        )
        break
      }
      default:
        break
    }

    return (
      <div className='scheduleReplicateTimeSelector' onClick={selectWeekday}>
        {timeHeader}
        {intervalOptions}
      </div>
    )

  }
}

export default ScheduleReplicateTimeSelector
