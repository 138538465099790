import React, {PureComponent} from 'react'
import {Modal,
  Image,
  Menu,
  Button,
  Message,
  Loader,
  Segment,
  Dimmer} from 'semantic-ui-react'

import {fetchFromServer} from 'helpers/net_helpers'

export default class GrabSnapshotModal extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {src: '', err: ''}
  }

  refreshSnapshot = async () => {
    let {type, id} = this.props
    this.setState({src: '', err: ''})
    console.log(type)
    console.log(id)
    let res = await fetchFromServer(`/v2/services/${type}/${encodeURIComponent(id)}/snapshot`)
    if(!res.ok) {
      let text = await res.text()
      if(!text) {
        text = 'Image failed to load.'
      }
      this.setState((state) => ({src: '', err: text}))
    } else {
      let snapshot = await res.blob()
      if(snapshot.size === 0) {
        this.setState((state) => ({src: '', err: 'Received no image data'}))
      } else {
        this.setState((state) => ({src: URL.createObjectURL(snapshot), err: ''}))
      }
    }
  }

  revokeSrc = () => {
    URL.revokeObjectURL(this.state.src)
  }

  render() {
    let {src, err} = this.state

    let isLoading = !(src || err)
    let errorMessage = ''

    if(err) {
      errorMessage = <Message header='Load Failed!' content={err} error/>
    }

    return (
      <Modal trigger={<Menu.Item icon="camera" content='Grab Snapshot'/>}
        closeIcon
        onOpen={this.refreshSnapshot}
        onClose={this.revokeSrc}>
        <Modal.Header>Grab Snapshot</Modal.Header>
        <Modal.Content>
          <Segment>
            <Dimmer active={isLoading}>
              <Loader/>
            </Dimmer>

            {errorMessage}
            <Image src={src} hidden={!(src)} alt='Image Load Failed' onLoad={this.revokeSrc} centered/>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button content='Refresh' icon='refresh' onClick={this.refreshSnapshot}/>
        </Modal.Actions>
      </Modal>
    )
  }

}
