import React, {Component} from 'react'
import PropTypes from 'prop-types'

import FrameEditorPanel from './Panel'

class FrameEditor extends Component {

  render() {
    return (
      <FrameEditorPanel {...this.props}/>
    )
  }

}

FrameEditor.propTypes = {
  frames: PropTypes.array.isRequired,
  resizeFrame: PropTypes.func.isRequired,
  moveFrame: PropTypes.func.isRequired
}

export default FrameEditor
