import { createSelector } from 'reselect'

import {getIn} from 'helpers/general_helpers'

const getServices = (state) => (state.services)
const getSchemas = (state) => (state.schema)
const getType = (state) => (state.type)
const getId = (state) => (state.id)

// createSelector is a function from a library called reselect 
// which is acting as a HOC and it is taking an array and a function
export default createSelector([getServices, getSchemas, getType, getId], (services, schemaList, type, id) => {

  let settings = getIn(services, [type, id, 'settings'])
  let targetSchema = null

  if(settings) {
    switch(type) {
      case 'channel':
        targetSchema = getIn(schemaList, ['channel', 'channel', 'properties'])
        break;
      case 'output':
      case 'input':
      case 'router':
        let driver = settings.driver
        targetSchema = findSchema(type, driver, schemaList)
        break
      default:
        throw new Error(`${type} is not a known type of service!`)
    }
  }

  if(schemaList && schemaList.length && targetSchema === null) {
    console.warn(`No schema found for service ${id} of type ${type} and driver ${settings ? settings.driver : "undefined"}`)
  }

  return targetSchema

})

function findSchema(type, driver, schemaList) {
  if(!schemaList[type]) {
    console.error(`No schemas exist of type ${type}`)
    return null
  }
  if(!driver) {
    return getIn(schemaList, [type, `${type}_default`, 'properties'])
  }
  let foundSchema = Object.values(schemaList[type]).find((schema) => {
    return (getIn(schema, ['properties', 'driver', 'const']) === driver)
  })
  if(foundSchema) {
    return foundSchema.properties
  } else {
    // Some schemata don't have the driver field in the schema itself for some reason.
    //  Use the driver as identified from the filename as fallback.
    //  If that doesn't exist either, null will be returned
    return getIn(schemaList, [type, driver, 'properties'])
  }
}
