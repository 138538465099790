import IntervalTime from './IntervalTime'
import Moment from 'moment'

const YEAR = 31622400000
const MONTH = 2678400000
const WEEK = 604800000
const DAY = 86400000
const HOUR = 3600000
const MINUTE = 60000
const SECOND = 1000

/**
 * Class for tracking a recurring period of time, from a start to an end.
 */
class IntervalPeriod {

  /**
   * Constructs a new IntervalPeriod
   * @param {(moment.Moment|Date|number|string|Object)} start A time value, as accepted by the constructor of the IntervalTime class. Indicates the start of the period.
   * @param {(moment.Moment|Date|number|string|Object)} end A time value, as accepted by the constructor of the IntervalTime class. Indicates the end of the period.
   * @param {string} [period] A time period indicating how often this interval period recurs. Accepts daily, weekly, monthly, or yearly.
   *  If left blank, the period will be auto-detected by the constructor of the start IntervalTime.
   * @param {Object} [intervalDuration] Description of interval for "interval" period IntervalPeriods
   * @param {Object} [opts={}] Optional Arguements
   * @param {boolean} [opts.zeroOk=false] In the event that the start IntervalTime and end IntervalTime are the same,
   *  this boolean will determine whether the duration of this IntervalPeriod is 0 (true) or equal to the duration of its period (false).
   */
  constructor(start, end, period, intervalDuration, opts={}) {
    let {zeroOk=false} = opts
    if(period) {
      period = period.trim()
    }
    if(period &&
      period !== "daily" &&
      period !== "weekly" &&
      period !== "monthly" &&
      period !== "yearly" &&
      period !== "interval") {
      throw new Error(`When trying to construct an IntervalPeriod, a period was passed that was not recognized: ${period}.
        You must either pass a string value of "daily", "weekly", "monthly", "yearly", or "interval" or you must pass nothing for the period in order for it to be auto-detected`)
    }
    this.start = new IntervalTime(start, period, intervalDuration)
    if(!period) {
      period = this.start.period
    }
    if(period === "interval" && !intervalDuration) {
      intervalDuration = {...this.start.intervalDuration}
    }
    this.end = new IntervalTime(end, period, intervalDuration)
    this.period = period
    this.intervalDuration = intervalDuration
    this.zeroOk = zeroOk
  }

  /**
   * Determines if a given date occurs within any instance of this IntervalPeriod
   * @param {(Date|moment.Moment|IntervalTime)} compareTo A Date, Moment, or IntervalTime to check against this IntervalPeriod
   * @param {Object} [opts={}] Optional arguements
   * @param {boolean} [opts.exclusiveStart=false] If opts.exclusiveStart is true, then
   *  compareTo will not be counted as being contained within this IntervalPeriod when compareTo is
   *  the same as this IntervalPeriod's start time
   * @param {boolean} [opts.exclusiveEnd=false] If opts.exclusiveEnd is true, then
   *  compareTo will not be counted as being contained within this IntervalPeriod when compareTo is
   *  the same as this IntervalPeriod's end time
   * @param {boolean} [opts.noMillis=false] Defaults to false. If true, don't count being contained by less than
   *  1 second
   * @returns {boolean} whether compareTo is within any occurence of this IntervalPeriod
   */
  contains(compareTo, opts={}) {
    let {exclusiveStart=false, exclusiveEnd=false, noMillis=false} = opts
    if(Moment.isMoment(compareTo)) {
      compareTo = compareTo.toDate()
    }
    if(compareTo instanceof IntervalTime) {
      if(compareTo.period !== this.period ||
        compareTo.period === "interval" ||
        this.period === "interval") {
        compareTo = compareTo.closestDate(new Date())
      } else {
        compareTo = compareTo.value()
        let startValue = this.start.value()
        let endValue = this.end.value()
        if(startValue === endValue && !this.zeroOk) {
          endValue += periodMillis(this.period)
        }
        if(noMillis) {
          compareTo = compareTo - (compareTo % 1000)
          startValue = startValue - (startValue % 1000)
          endValue = endValue - (endValue % 1000)
        }
        if(exclusiveStart && compareTo === startValue) {
          return false
        }
        if(exclusiveEnd && compareTo === endValue) {
          return false
        }
        if(startValue <= endValue) {
          return compareTo >= startValue && compareTo <= endValue
        } else {
          return compareTo >= startValue || compareTo <= endValue
        }
      }
    }
    // For intervaldays that fall outside interval; explicitly being supported
    if(compareTo === null) {
      return false
    }
    let testStart = this.start.closestDate(compareTo, {forceDirection: "before"})
    // For intervaldays that fall outside interval; explicitly being supported
    if(testStart === null) {
      return false
    }
    // If exclusiveStart, rule out the target being the same as the start
    if(exclusiveStart && testStart.valueOf() === compareTo.valueOf()) {
      return false
    }
    // If noMillis, rule out the target being within less than one second of the start
    if(noMillis && Math.abs(testStart.valueOf() - compareTo.valueOf()) < 1000) {
      return false
    }
    let testEnd = new Date(testStart.valueOf() + this.duration())
    // For intervaldays that fall outside interval; explicitly being supported
    if(testEnd === null) {
      return false
    }
    // If noMillis, rule out the target being within less than one second of the end
    if(noMillis && Math.abs(testEnd.valueOf() - compareTo.valueOf()) < 1000) {
      return false
    }
    if(exclusiveEnd) {
      return (testEnd.valueOf() > compareTo.valueOf())
    } else {
      return (testEnd.valueOf() >= compareTo.valueOf())
    }
  }

  /**
   * Returns whether any part IntervalPeriod occurs on the same day as the given Date or Moment
   * @param {(Date|moment.Moment)} compareTo A Date or Moment to compare this IntervalTime to
   * @returns {boolean} whether this IntervalPeriod and the given date occur on the same day or not
   */
  isOnSameDay(compareTo) {
    return this.contains(compareTo, {exclusiveEnd: true}) ||
      this.start.isOnSameDay(compareTo) ||
      (this.end.isOnSameDay(compareTo) && this.end.printTimeOfDay({showMilliseconds: true, showMeridian: false}) !== "0:00:00.000")
  }

  /**
   * Gets the duration of this IntervalPeriod
   * @returns {number} The duration of this IntervalPeriod in milliseconds
   */
  duration() {
    let startVal = this.start.value()
    let endVal = this.end.value()
    if(this.zeroOk && startVal === endVal) {
      return 0
    }
    if(startVal >= endVal) {
      if(this.period === "interval") {
        endVal = endVal + intervalMillis(this.intervalDuration)
      } else {
        endVal = endVal + periodMillis(this.period)
      }
    }
    return endVal - startVal
  }

  /*
   *  Here are some visual examples for giveOverlapType (doesn't work with JSDoc):
   *
   *  FRONT_OVERLAP:
   *    |-------this---------|
   *                      |-----compareTo--------|
   *
   *  BACK_OVERLAP:
   *                 |-----this-------|
   *    |-----compareTo-------|
   *
   *  CONTAINS:
   *    |----------this--------------|
   *         |-----compareTo-----|
   *
   *  CONTAINED_BY:
   *             |---this---|
   *    |-------compareTo--------|
   */
  /**
   * Determines if this IntervalPeriod and another IntervalPeriod overlap
   * @param {Object} compareTo The IntervalPeriod to check for overlap with
   * @param {Object} [opts={}] Optional arguments
   * @param {boolean} [opts.giveOverlapType=false] If set to true, and an overlap occurs, then this function will return a string
   *  indicating how the overlap is occurring. It will return "FRONT_OVERLAP" if the end of this IntervalPeriod
   *  overlaps the start of compareTo, "BACK_OVERLAP" if the start of this IntervalPeriod overlaps the end of compareTo,
   *  "CONTAINS" if this IntervalPeriod fully contains compareTo, or "CONTAINED_BY" if compareTo fully contains this IntervalPeriod.
   * @param {boolean} [opts.containsOk=false] If set to true, then this IntervalPeriod entirely containing compareTo will not be counted as an overlap.
   *  Conversely IntervalPeriod is contained by compareTo, it will still be counted as an overlap. Defaults to false.
   * @param {boolean} [opts.noMillis=false] Defaults to false. If set to true, then don't count overlaps of less than 1 second
   * @returns {boolean} whether this IntervalPeriod and compareTo overlap or not. If they do overlap and opts.giveOverlapType is true,
   *  then returns a string indicating how the overlap is taking place instead.
   */
  overlaps(compareTo, opts={}) {
    let {giveOverlapType=false, containsOk=false, noMillis=false} = opts
    if(!(compareTo instanceof IntervalPeriod)) {
      throw new Error(`Trying to check overlap between an IntervalPeriod and something that is not an IntervalPeriod: ${compareTo}. IntervalPeriod.overlaps can only check for overlap between two IntervalPeriods`)
    }
    if(this.period !== compareTo.period) {
      throw new Error(`Currently, checking overlap of two IntervalPeriods that don't have the same period of occurrence is not supported
        (this IntervalPeriod was ${this.period}, and the IntervalPeriod it was being compared to was ${compareTo.period})`)
    }
    if(this.contains(compareTo.start, {exclusiveEnd: true, noMillis}) && this.contains(compareTo.end, {exclusiveStart: true, noMillis})) {
      if(containsOk) {
        return false
      } else {
        return giveOverlapType ? "CONTAINS" : true
      }
    } else if (compareTo.contains(this.start, {exclusiveEnd: true, noMillis}) && compareTo.contains(this.end, {exclusiveStart: true, noMillis})) {
      return giveOverlapType ? "CONTAINED_BY" : true
    } else if (this.contains(compareTo.start, {exclusiveEnd: true, noMillis})) {
      return giveOverlapType ? "FRONT_OVERLAP" : true
    } else if (this.contains(compareTo.end, {exclusiveStart: true, noMillis})) {
      return giveOverlapType ? "BACK_OVERLAP" : true
    } else {
      return false
    }
  }

  /**
   * Adds a number of milliseconds to both the start and end of this IntervalPeriod
   * @param {number} addValue The number of milliseconds to add
   * @returns {IntervalPeriod} a new copy of this IntervalPeriod with addValue milliseconds added to it
   */
  add(addValue) {
    return new IntervalPeriod(this.start.add(addValue), this.end.add(addValue), this.period)
  }

  /**
   * Subtracts a number of milliseconds from both the start and end of this IntervalPeriod
   * @param {number} subtractValue The number of milliseconds to subtract
   * @returns {IntervalPeriod} a new copy of this IntervalPeriod with subtractValue milliseconds subtracted from it
   */
  subtract(subtractValue) {
    return new IntervalPeriod(this.start.subtract(subtractValue), this.end.subtract(subtractValue), this.period)
  }

  /**
   * Moves the interval period so that it starts at a new time but keeps the same duration
   * @param {IntervalTime} newStart The new start time for this period. Also accepts anything that the IntervalTime constructor accepts
   * @returns {IntervalPeriod} a new copy of this IntervalPeriod that starts at newStart but has the same duration
   */
  moveToStart(newStart) {
    let duration = this.duration()
    newStart = new IntervalTime(newStart, this.period)
    return new IntervalPeriod(newStart, newStart.add(duration), this.period)
  }

  /**
   * Moves the interval period so that it ends at a new time but keeps the same duration
   * @param {IntervalTime} newEnd The new end time for this period. Also accepts anything that the IntervalTime constructor accepts
   * @returns {IntervalPeriod} a new copy of this IntervalPeriod that ends at newEnd but has the same duration
   */
  moveToEnd(newEnd) {
    let duration = this.duration()
    newEnd = new IntervalTime(newEnd, this.period)
    return new IntervalPeriod(newEnd.subtract(duration), newEnd, this.period)
  }

  /**
   * Changes the basis of an "interval" period IntervalPeriod.
   *  If this is not an "interval" period IntervalPeriod, then this method does nothing
   *  This function will also change this IntervalPeriod's start and end so that it is the same
   *  distance from its new basis as it was from its old basis while maintaining the same duration
   * @param {Object} newBasis A Date or Moment to use as the new basis. Passing anything
   *  other than a Date or Moment will throw.
   * @returns {IntervalPeriod} a copy of this IntervalPeriod with the basis changed
   */
  setIntervalDuration(newDuration) {
    return new IntervalPeriod(this.start.setIntervalDuration(newDuration), this.end.setIntervalDuration(newDuration), this.period, {basis: this.intervalDuration.basis, ...newDuration})
  }

  /**
   * Sets the intervalDuration of an "interval" period IntervalPeriod.
   * @param {Object} newDuration An object containing a map of duration values with one or more of the following keys:
   *  "years", "months", "weeks", "days", "hours", "minutes", "seconds", "milliseconds". May also include a "basis"
   *  for the interval. If a basis is not included, and the current intervalDuration does possess a basis, then the basis
   *  will automatically be carried over to the new duration. No other keys will be automatically carried over. If a basis
   *  is included, it will replace the old basis, but the difference between the old basis and time will not be carried
   *  over to the new basis, as it is with setIntervalBasis.
   * @returns {IntervalPeriod} a copy of this IntervalPeriod with the intervalDuration changed.
   */
  setIntervalBasis(newBasis) {
    return new IntervalPeriod(this.start.setIntervalBasis(newBasis), this.end.setIntervalBasis(newBasis), this.period, {...this.intervalDuration, basis: newBasis})
  }

  /**
   * Divides this IntervalPeriod into an array of IntervalTimes corresponding to the borders of timeslots of a given length. Timeslots will be oriented such that
   *  they occur on the hour mark when possible (starting from the first hour mark, if the timeslotLength does not evenly divide into an hour).
   *  For example, if a period goes from 4:40 - 6:50 and the timeslotLength given is 30 minutes,
   *  then the IntervalTimes returned would be [4:40, 5:00, 5:30, 6:00, 6:30, 6:50].
   * @param {number} timeslotLength The length of the timeslots in minutes. Must be either a factor or multiple of 60.
   * @returns {Array[IntervalTime]} An array of timeslot bounderies within this intervalPeriod for the given timeslotLength
   */
  divideIntoSlots(timeslotLength) {
    if(60 % timeslotLength !== 0 && timeslotLength % 60 !== 0) {
      throw new Error(`Tried to divide an IntervalPeriod into timeslots of length ${timeslotLength} minutes, but only timeslot lengths that are a factor or multiple of 60 are accepted.`)
    }
    let start = new IntervalTime(this.start)
    let end = new IntervalTime(this.end)
    let toReturn = [start]
    let startMark = start.value()
    let endMark = end.value()
    if(startMark === endMark) {
      if(this.zeroOk) {
        // Period is 0, there's only one time that could be returned
        return toReturn
      } else if(this.period !== "interval") {
        // Period encompasses whole interval, so set endMark accordingly
        endMark = periodMillis(this.period)
      } else {
        endMark = intervalMillis(this.intervalDuration)
      }
    } else if(startMark > endMark) {
      if(this.period !== "interval") {
        endMark = endMark + periodMillis(this.period)
      } else {
        endMark = endMark + intervalMillis(this.intervalDuration)
      }
    }
    for(let i = startMark + (timeslotLength * MINUTE); i < endMark; i += (timeslotLength * MINUTE)) {
      let nextTime = start.add(i - startMark)
      nextTime = nextTime.subtract((nextTime.minute() % timeslotLength) * MINUTE).set("second", 0).set("millisecond", 0)
      if(this.contains(nextTime)) {
        toReturn.push(nextTime)
      }
    }
    // End did not get added; expected if end was not on the timeslotLength mark
    if(toReturn[toReturn.length - 1].diff(endMark) !== 0) {
      toReturn.push(end)
    }
    return toReturn
  }

  printStartTime(opts={}) {
    return this.start.printTimeOfDay(opts)
  }

  printEndTime(opts={}) {
    return this.end.printTimeOfDay(opts)
  }

  printStartDay(opts={}) {
    return this.start.printDay(opts)
  }

  printEndDay(opts={}) {
    return this.end.printDay(opts)
  }

}

/**
 * Helper that gets the number of milliseconds in a given schedule period
 * @param {string} period Period to get milliseconds of (either "yearly", "monthly", "weekly", or "daily")
 * @returns {number} Integer number of milliseconds in the given schedule period. Assumes 31 days in a month, and assumes
 *  it is a leap year.
 */
function periodMillis(period) {
  switch(period) {
    case "yearly":
      return YEAR
    case "monthly":
      return MONTH
    case "weekly":
      return WEEK
    case "daily":
      return DAY
    default:
      throw new Error(`Unrecognized interval period: ${period}. Period must be a string of "yearly", "monthly", "weekly", or "daily"`)
  }
}

function intervalMillis(intervalDuration) {
  let toReturn = 0
  if(intervalDuration.years) {
    toReturn += intervalDuration.years * YEAR
  }
  if(intervalDuration.months) {
    toReturn += intervalDuration.months * MONTH
  }
  if(intervalDuration.weeks) {
    toReturn += intervalDuration.weeks * WEEK
  }
  if(intervalDuration.days) {
    toReturn += intervalDuration.days * DAY
  }
  if(intervalDuration.hours) {
    toReturn += intervalDuration.hours * HOUR
  }
  if(intervalDuration.minutes) {
    toReturn += intervalDuration.minutes * MINUTE
  }
  if(intervalDuration.seconds) {
    toReturn += intervalDuration.seconds * SECOND
  }
  if(intervalDuration.milliseconds) {
    toReturn += intervalDuration.milliseconds
  }
  return toReturn
}

export default IntervalPeriod
