/**
 * Selector that returns the current framelist with the display param of each frame.
 */
import { createSelector } from 'reselect'
import deepmerge from 'deepmerge'
import { getFrameListFromSettings } from 'helpers/global_media_helpers'
import { getIn } from 'helpers/general_helpers'
const getRegions = (state) => (getIn(state, ['settings', 'regions']))
const getSchema = (state) => (state.schema)
const getFrames = (state) => (state.frames)

export default createSelector([getRegions, getSchema, getFrames],
(regions, schema, frames) => {
  if(regions && schema) {
    let baseFrames = getFrameListFromSettings(regions, schema)
    return deepmerge(baseFrames, frames, {arrayMerge: combineMerge})
  } else {
    return []
  }
})

const emptyTarget = value => Array.isArray(value) ? [] : {}
const clone = (value, options) => deepmerge(emptyTarget(value), value, options)

function combineMerge(target, source, options) {
	const destination = target.slice()

  let hasObject = destination.findIndex((item) => {
    return options.isMergeableObject(item)
  })

  if(hasObject === -1) {
    return source
  }

	source.forEach(function(e, i) {
		if (typeof destination[i] === 'undefined') {
			const cloneRequested = options.clone !== false
			const shouldClone = cloneRequested && options.isMergeableObject(e)
			destination[i] = shouldClone ? clone(e, options) : e
    } else if (typeof e === 'undefined') {
      return
		} else if (options.isMergeableObject(e)) {
			destination[i] = deepmerge(target[i], e, options)
		} else {
			destination[i] = e
		}
	})
	return destination
}
