import React, {PureComponent} from 'react'
import {Table, Button, Dropdown} from 'semantic-ui-react'
import ServiceItem from 'components/Settings/ServiceItem'
import {getIn, camelToCapitalized} from 'helpers/general_helpers'
import {numberawareLocaleCompare} from 'helpers/numawaresorting'
import {INPUT_TYPES} from 'redux/settings'

import "./ServiceTable.css"

import PropTypes from 'prop-types'

// The labels in this must match up to their corresponding types in INPUT_TYPES at the same index
const INPUT_LABELS = [
  "Video Input",
  "IP/Internet Radio Input",
  "RS-232 Trigger"
]

const systemServiceGetNameKeyValue = ([key,value]) => {
  if (value) {
    if (value.ufname) return value.ufname
    if (value.name) return value.name
  }

  return key
}

class ServiceTable extends PureComponent {

  render() {
    let {services,
      serviceDisplayOrder,
      type,
      createService,
      ...actions} = this.props

    let sortedEntries

    // Object.entries(...) returns an array of [key,value]
    if (type === "system") {
      if (serviceDisplayOrder) {
        let match = {}
        sortedEntries = []
        serviceDisplayOrder.forEach((service) => {
          let sobj = services[service]
          if (sobj) {
            match[service] = true
            sortedEntries.push([service,sobj])
          }
        })
        // in case the server-side misses a few things
        let missed = []
        Object.keys(services).forEach((service) => {
          if (match[service] !== true) {
            let sobj = services[service]
            if (sobj) {
              match[service] = true
              sortedEntries.push([service,sobj])
              missed.push(service)
            }
          }
        })
        if (missed.length > 0) console.warn('Server-side provides services not listed in display list',missed)
      }
      else {
        sortedEntries = Object.entries(services).sort((aService, bService) => {
           return numberawareLocaleCompare(systemServiceGetNameKeyValue(aService),systemServiceGetNameKeyValue(bService))
        })
      }
    }
    else {
      sortedEntries = Object.entries(services).sort((aService, bService) => {
         return numberawareLocaleCompare(aService[0],bService[0])
      })
    }

    let serviceItems = sortedEntries.map(([key, value]) => {
      let label = ''
      let subtype = ''
      if(type === 'channel') {
        let channelNumber = key.replace(/^channel/,'')
        label = getIn(value, ['settings', 'name']) || value.name || `Channel #${channelNumber}`
      } else {
        label = systemServiceGetNameKeyValue([key,value])
        subtype = value.settings && value.settings.driver ? value.settings.driver : ""
      }

      let activeRaw = undefined
      let disabled = false
      let active = false

      if (type === 'system') {
        if (value.disabled !== undefined) disabled = value.disabled
        activeRaw = value.running
      }
      else {
        activeRaw = getIn(value, ['settings', 'running']) // can be false, "0" or "1"
      }

      if (activeRaw === true || activeRaw === false)
        active = activeRaw
      else
        active = parseInt(activeRaw) > 0

      let name = `${type}/${key}`
      return (<ServiceItem key={key}
        name={name}
        label={label}
        active={active}
        type={type}
        disabledStatus={disabled}
        subtype={subtype}
        {...actions}
        />)
    })

    let createServiceButton = ''
    if(createService) {
      if(type === "input") {
        let options = INPUT_TYPES.map((inputType, ind) => ({
          key: inputType,
          text: `New ${INPUT_LABELS[ind]}`,
          onClick: () => {this.props.createService(type, inputType)}
        }))
        createServiceButton = (<Dropdown trigger={
            <Button positive
            icon='add'
            content={`New ${camelToCapitalized(type)}`}/>
          }
          id="createInputDropdownButton"
          icon={null}
          direction="left"
          options={options}/>)
      } else {
        createServiceButton = (<Button positive
          icon='add'
          content={`New ${camelToCapitalized(type)}`}
          floated='right'
          onClick={() => {this.props.createService(type)}}/>)
      }
    }

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan='3'>
              {createServiceButton}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {serviceItems}
        </Table.Body>
      </Table>
    )
  }

}

ServiceTable.propTypes = {
  services: PropTypes.object.isRequired, // Map of service ids to settings objects for each service to be shown in table
  type: PropTypes.string.isRequired,     // Which type of services are being displayed in this table, either "channel", "output", "input", or "router"
  createService: PropTypes.func,         // Function for creating a new service, if creating a new service is possible
  actions: PropTypes.object              // Map of actions that can be performed by each service item in the table
}

export default ServiceTable
