import React, {PureComponent} from 'react'
import {millisecondsToPixels} from 'helpers/playlist_helpers'

import './ClipDragPreview.css'

class ClipDragPreview extends PureComponent {

  render() {
    let {start,
      length,
      viewStart,
      zoomLevel,
      colliding,
      hovering} = this.props

    if(hovering === false) {
      return null
    }

    let cls = 'PlaylistFrameClipPreview noselect'

    let width = length ? millisecondsToPixels(length, zoomLevel) : '5em'
    let left = millisecondsToPixels((start - viewStart), zoomLevel)
    let zeroLeft = millisecondsToPixels((0 - viewStart), zoomLevel)
    left = Math.max(left, zeroLeft)
    left = '' + left + 'px'

    let style = {width, left}

    if(colliding) {
      cls = cls + ' colliding'
    }

    if(hovering === null) {
      cls = cls + ' checkHover'
    }

    if(length === 0) {
      cls = cls + ' unknownDuration'
    }

    return (
      <div className={cls} style={style}/>
    )
  }

}

export default ClipDragPreview
