import React, {Component} from 'react'
import {Dropdown} from 'semantic-ui-react'

class ScheduleItemDropdown extends Component {

  shouldComponentUpdate(nextProps) {
    let {options, scrollSize, fileType} = this.props
    return (!compareOptions(options, nextProps.options) ||
      scrollSize !== nextProps.scrollSize ||
      fileType !== nextProps.fileType)
  }

  render() {
    let {options, handleSelect, handleDeselect, scrollSize} = this.props

    let dropdownOptions = options.map((item) => (<Dropdown.Item {...item}/>))

    let menuStyle = {}
    if(scrollSize) {
      menuStyle = {
        maxHeight: `${scrollSize / 2.2}px`
      }
    }

    let dropdownProps = {
      onOpen: () => {if(handleSelect) {handleSelect()}},
      onClose: handleDeselect,
      scrolling: true
    }

    return (
      <>
      <Dropdown {...dropdownProps}>
        <Dropdown.Menu style={menuStyle}>
          {dropdownOptions}
        </Dropdown.Menu>
      </Dropdown>
      <span ref={this.item}/>
      </>
    )
  }

}

function compareOptions(options, nextOptions) {
  if(options.length !== nextOptions.length) {
    return false
  }
  for(let i = 0; i < options.length; i++) {
    if(options[i].key !== nextOptions[i].key ||
      options[i].text !== nextOptions[i].text ||
      options[i].disabled !== nextOptions[i].disabled) {
      return false
    }
  }
  return true
}

export default ScheduleItemDropdown
