/**
 * Selector that returns a clip based on the currently selected library file
 */
import { createSelector } from 'reselect'
import {dataPath} from 'helpers/library_helpers'
import {getIn} from 'helpers/general_helpers'
const getFileList = (state) => (state.fileData)
const getSelectedFile = (state) => (state.selectedLibraryFile)

export default createSelector([getFileList, getSelectedFile],
(filelist, selectedFile) => {
  if(!filelist || !selectedFile) {
    return null
  }
  let file = getIn(filelist, dataPath(selectedFile))
  if(!file) {
    return null
  } else {
    let inPoint = null
    let outPoint = null
    let end = 0
    let duration = 0
    let association
    if(file.metadata) {
      duration = parseDuration(file.metadata.duration)
      end = duration
      if(file.metadata.out) {
        outPoint = (parseFloat(file.metadata.out) * 1000)
        end = outPoint
      }
      if(file.metadata.in) {
        inPoint = (parseFloat(file.metadata.in) * 1000)
        end = end - inPoint
      }
    }
    // Handle input associations
    if(file.associations && file.selectedAssociation) {
      let selectedAssociation = file.associations.find((assoc) => assoc.id === file.selectedAssociation)
      association = {
        id: selectedAssociation.id,
        name: selectedAssociation.name
      }
    }
    let toReturn = {
      item: selectedFile,
      start: 0,
      end,
      association,
      data: {
        guid: file.metadata ? file.metadata.guid : undefined,
        'file type': file.metadata ? file.metadata['file type'] : undefined,
        'item duration': duration / 1000,
      }
    }
    if(inPoint) {
      toReturn.in = inPoint
    }
    if(outPoint) {
      toReturn.out = outPoint
    }
    return toReturn
  }
})

function parseDuration(duration) {
  if(typeof duration === 'string') {
    return parseFloat(duration) * 1000
  } else if(typeof duration === 'number') {
    return duration
  }
  return null
}
