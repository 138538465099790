import React, {Component} from 'react'
import {Image, Icon, Container, Loader} from 'semantic-ui-react'
import {fetchFromServer} from 'helpers/net_helpers'
import {staticAsset} from 'helpers/net_helpers'

export default class LibraryFileTileImage extends Component {

  constructor(props) {
    super(props)
    this.state = {imageURL: null, controller: null}
  }

  componentDidMount() {
    this.handleFetchThumbnail()
  }

  componentWillUnmount() {
    this.revokeThumbnail()
  }

  handleFetchThumbnail = async () => {
    let {duration, path} = this.props
    let {imageURL} = this.state
    if(imageURL) {
      return
    }
    if(!duration) {
      duration = 0
    }
    if(typeof duration === 'string') {
      duration = parseFloat(duration)
    }
    let imageTime = Math.max(Math.min((duration / 2), 30), 1)
    let controller = new AbortController();
    let signal = controller.signal;
    this.setState({...this.state, controller});
    try {
      let response = await fetchFromServer(`/v2/files/grab-thumbnail/${path.join('/')}?t=${imageTime}`, {signal})
      if(response.ok) {
        let blob = await response.blob()
        if(blob.size === 0) {
          return
        }
        this.setState((state) => ({...this.state, imageURL: URL.createObjectURL(blob)}))
      } else {
        this.setState((state) => ({...this.state, imageURL: ''}))
      }
    } catch (err) {
      if(err.name !== "AbortError") {
        throw err;
      }
    }
  }

  revokeThumbnail = () => {
    let {imageURL, controller} = this.state
    if(controller) {
      controller.abort();
    }
    if(!imageURL) {
      return
    }
    URL.revokeObjectURL(imageURL)
    this.setState((state) => ({imageURL: null}))
  }

  render() {
    let {icon, generatingMetadata} = this.props
    let {imageURL} = this.state

    let content = ''

    if(imageURL) {
      content = (
        <div className='tileImage'>
          <Image src={imageURL} alt=''/>
        </div>
      )
    } else if (icon) {
      content = (
        <Container textAlign='center' className='tileTypeIcon'>
          <Icon size='huge' className='svg-icon'>
            <Image src={staticAsset(`/icons/${icon}.svg`)}/>
          </Icon>
        </Container>
      )
    } else if (generatingMetadata) {
      content = (
        <Container textAlign='center' className='tileTypeIcon'>
          <Icon size='huge'>
            <Loader active inline size="huge"/>
          </Icon>
        </Container>
      )
    } else {
      content = (
        <Container textAlign='center' className='tileTypeIcon'>
          <Icon size='huge' name='file'/>
        </Container>
      )
    }

    return (
      <div className='tileIcon'>
        {content}
      </div>
    )
  }

}
