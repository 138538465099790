import React, {PureComponent} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import applicationList from 'applicationList'
import { openApplication,
  openPinnedApplications,
  pinApplication,
  closeApplication,
  setActiveApplication,
  startTimeSync,
  setChangeTimeData,
  changeSystemTime,
  getTimezoneListFromServer} from 'redux/menu'
import { downloadDismiss } from 'redux/file_list'
import {getIn} from 'helpers/general_helpers'
import {logOut} from 'redux/server'
import {
  Menu,
} from 'semantic-ui-react';
import history from 'history.js'
import { checkHasAccessToApp } from 'helpers/authentication_helpers'

import MenuTabCountSelector from 'selectors/MenuTabCountSelector'

import MenuDropdown from 'components/MenuBar/MenuDropdown'
import MenuDownloads from 'components/MenuBar/MenuDownloads'
import MenuSystemStatus from 'components/MenuBar/MenuSystemStatus'
import MenuClock from 'components/MenuBar/MenuClock'
import ApplicationIcons from 'components/MenuBar/ApplicationIcons'

import './MenuBar.css'

class MenuBar extends PureComponent {

  constructor(props) {
    super(props)
    props.getTimezoneListFromServer()
    props.startTimeSync()
    if(props.menu.openApplications.length === 0) {
      props.openPinnedApplications()
    }
    // console.log(props)
    let currentPath = /(^\/[^/]*)(.*)/.exec(props.routing.location.pathname)
    if(currentPath && currentPath[1]) {
      let appToOpen = null
      for(let [key, value] of Object.entries(applicationList)) {
        if(value.link === currentPath[1]) {
          appToOpen = key
        }
      }
      if(appToOpen) {
        let extraUrl = currentPath[2] ? currentPath[2] : ''
        props.openApplication(appToOpen, extraUrl)
      }
    }
  }

  closeApp = (e, index) => {
    if(e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.props.closeApplication(index)
  }

  pinApp = (e, item) => {
    if(e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.props.pinApplication(item)
  }

  render() {
    let {menuList,
      openApplications,
      pinnedApplications,
      activeApplication,
      timeSync,
      timeOffset,
      timezone,
      timezoneList,
      changeTimeFormData} = this.props.menu

    let {openApplication,
      setActiveApplication,
      downloadDismiss,
      setChangeTimeData,
      changeSystemTime,
      logOut,
      appAccess,
      system,
      support,
      tabCounts} = this.props

    let {downloads} = this.props.filelist

    let supportMsg = ''
    let supportMsg2 = ''

    if (support.isActive) {
      supportMsg = `Support connection #${support.supportID} `
      if (support.message.match(/^[0-9]+$/)) {
        supportMsg += 'active';
        /* Nathan thinks a singular second count is yucky and icky and user unfriendly.
         * Never mind it was never intended to be anything but a number counting up to
         * show connectivity. It's a Bash shell script incrementing by 1 after "sleep 1".
         * I doubt that it remains accurate at all past 10 minutes. Anyway, convert to H:MM:SS. */
        let t = parseInt(support.message);
        let s = t % 60; t = Math.floor((t - s) / 60);
        let m = t % 60; t = Math.floor((t - m) / 60);
        let h = t % 24; t = Math.floor((t - h) / 24);
        let d = t;

        s = s.toString();
        if (s.length < 2) s = '0' + s;

        m = m.toString();
        if (m.length < 2) m = '0' + m;

        h = h.toString();

        supportMsg2 = ''
        if (d > 0) {
          supportMsg2 += d.toString()+' days, ';
        }

        supportMsg2 += h+':'+m+':'+s;
      }
      else {
        supportMsg += 'pending';
        supportMsg2 = support.message
      }
    }

    let hasSettingsAccess = checkHasAccessToApp(appAccess, 'Settings')

    return(
      <Menu icon='labeled'
          color="blue"
          inverted={true}
          id='taskbar'
          activeIndex={activeApplication}>
        <MenuDropdown menuList={menuList}
          openApplication={openApplication}
          logOut={logOut}
          push={history.push}
          setActiveApplication={setActiveApplication}/>

        <ApplicationIcons openApplications={openApplications}
          pinnedApplications={pinnedApplications}
          pinApplication={this.pinApp}
          openApplication={openApplication}
          closeApplication={this.closeApp}
          activeApplication={activeApplication}
          tabCounts={tabCounts}
          />

        <Menu.Menu position='right'>
          <div style={{color: 'white', paddingLeft: '1.5rem', paddingRight: '1.5rem', paddingTop: '1.2rem', cursor: 'default', textAlign: 'right'}}>{supportMsg}<span style={{fontSize: '0.8rem'}}><br/>{supportMsg2}</span></div>
          <MenuDownloads downloads={downloads} downloadDismiss={downloadDismiss}/>
          <MenuSystemStatus systemStatus={system} hide={!hasSettingsAccess}/>
          <MenuClock canChangeTime={hasSettingsAccess}
            timeSync={timeSync}
            timeOffset={timeOffset}
            timezone={timezone}
            timezoneList={timezoneList}
            changeSystemTime={changeSystemTime}
            changeTimeFormData={changeTimeFormData}
            setChangeTimeData={setChangeTimeData}/>
        </Menu.Menu>

      </Menu>
    )
  }
}

const mapStateToProps = state => ({
  tabCounts: MenuTabCountSelector(state),
  routing: state.router,
  menu: state.menu,
  filelist: state.file_list,
  system: state.system.stat,
  support: getIn(state, ['system', 'services', 'supportStatus']) || {},
  appAccess: state.server.appAccess,
})

const mapDispatchToProps = dispatch => bindActionCreators({
  openApplication,
  openPinnedApplications,
  pinApplication,
  closeApplication,
  setActiveApplication,
  downloadDismiss,
  startTimeSync,
  setChangeTimeData,
  changeSystemTime,
  getTimezoneListFromServer,
  logOut,
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuBar)
