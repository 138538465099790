import React, {PureComponent} from 'react'
import {List} from 'semantic-ui-react'

import SimpleListItem from './ListItem'

export default class SimplePlaylist extends PureComponent {

  render() {
    let {videoList, addListItem, removeListItem, moveListItem, changeItemDuration} = this.props

    let items = videoList.map((clip, index) => (
      <SimpleListItem key={index}
        index={index}
        clip={clip}
        addListItem={addListItem}
        removeListItem={removeListItem}
        moveListItem={moveListItem}
        changeItemDuration={changeItemDuration}/>
    ))

    return (
      <List relaxed size='big' divided>
        {items}
        <List.Item disabled>
          <List.Content>
            (Click list to add items)
          </List.Content>
        </List.Item>
      </List>
    )
  }

}
