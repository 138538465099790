/**
 * Performs a deep non-mutating set on an object or array
 * @param {object/array} target An object or array to set a value within
 * @param {array} dest An array of keys leading to the child to be changed
 * @param value The value to set dest to
 * @returns A copy of target with the child at dest changed to value
 */
export const setIn = (target, dest, value) => {
  let current = dest.length > 0 ? dest[0] : null
  let next = dest.slice(1, dest.length)
  if(current === null) {
    return value
  } else if(typeof target !== "object" || target === null) {
    if(typeof current === 'string') {
      return {
        [current]: setIn(null, next, value)
      }
    } else if(typeof current === 'number') {
      let temp = []
      temp[current] = setIn(null, next, value)
      return temp
    }
  } else {
    if((Array.isArray && Array.isArray(target)) || target instanceof Array) {
      let temp = [...target]
      temp[current] = setIn(target[current], next, value)
      return temp
    } else {
      return {
        ...target,
        [current]: setIn(target[current], next, value)
      }
    }
  }
}

/**
 * Deeply retrieves a value from an object or array from an array of keys
 * @param {object/array} target An object or array to retrieve from
 * @param {array} dest An array of keys leading to the child to retrieve
 * @returns The value of dest within target. Note that if you retrieve an object or array, it will
 * return an actual reference to that object/array, not a copy.
 */
export const getIn = (target, dest) => {
  let current = dest.length > 0 ? dest[0] : null
  let next = dest.slice(1, dest.length)
  if(current === null) {
    return target
  } else if(typeof target !== 'object' || target === null) {
    return null
  } else {
    return getIn(target[current], next)
  }
}

/**
 * Deeply deletes a value from an object or array, based on an array of keys
 * @param {object/array} target An object or array to delete a value from within
 * @param {array} dest An array of keys leading to the child to be deleted
 * @returns A copy of target with the child at dest deleted
 */
export const deleteIn = (target, dest) => {
  let current = dest.length > 0 ? dest[0] : null
  let next = dest.slice(1, dest.length)
  if(current === null) {
    return;
  } else if(next.length === 0 && target[current]) {
    let temp;
    if(target instanceof Array) {
      temp = [...target];
      temp.splice(current, 1);
    } else {
      temp = {...target};
      delete temp[current];
    }
    return temp;
  } else if(typeof target[current] === 'object') {
    let temp;
    if(target instanceof Array) {
      temp = [...target];
    } else {
      temp = {...target};
    }
    temp[current] = deleteIn(target[current], next)
    if(current in temp && temp[current] === undefined) {
      delete temp[current]
    }
    return temp
  } else {
    return target
  }
}

/**
* @function objectCompare
* @param {object} a Any non-null object or array.
* @param {object} b Any non-null object or array.
* @returns A boolean indicating whether all elements of a and b are equivalent.
*   If either a or b are not objects or null,
* then false is returned.
*/
export const objectCompare = (a, b) => {
  if (typeof a !== "object" || typeof b !== "object" ||
     a === null || b === null) {
    return false;
  }
  let toReturn = true;
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }
    a.forEach((val, i) => {
      if (!elementCompare(a[i], b[i])) {
        toReturn = false;
      }
    });
  } else if (Array.isArray(a) || Array.isArray(b)) {
    return false;
  } else {
    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }
    for (let key in a) {
      if (!elementCompare(a[key], b[key])) {
        toReturn = false;
      }
    }
  }
  return toReturn;
};

/**
* @function elementCompare
* @param elemA The first element to compare.
* @param elemB The second element to compare.
* @returns A boolean indicating if elemA and elemB are equivalent in value. If typeof elemA
* and typeof elemB do not match, returns false. If elemA and elemB are of
* type object and non-null, calls objectCompare on both. Otherwise uses '==='
*/
const elementCompare = (elemA, elemB) => {
  if (typeof elemA !== typeof elemB) {
    return false;
  } else if (typeof elemA === "object") {
    if (elemA === null || elemB === null) {
      return elemA === elemB;
    } else {
      return objectCompare(elemA, elemB);
    }
  // Don't bother comparing uuids, chances are they won't be equal.
  } else if (typeof elemA === "string" && typeof elemB === "string" &&
            /[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}/.exec(elemA) !== null &&
            /[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}/.exec(elemB) !== null) {
    return true;
  } else {
    return elemA === elemB;
  }
};

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE

/**
 * Converts milliseconds into a string formatted as (H:M)M:SS(.AAA)
 * @param {number} time The time to convert in milliseconds
 * @param {boolean} showMilliseconds Whether to show milliseconds or not
 * @returns A string representation of a millisecond time
 */
export const millisToHourString = (time, showMilliseconds=false) => {
  let hours = Math.floor(time / HOUR)
  let minutes = Math.floor((time % HOUR) / MINUTE)
  // If we are not showing milliseconds, then round the time instead of flooring it
  let seconds = showMilliseconds ?
    Math.floor((time % MINUTE) / SECOND) :
    Math.round((time % MINUTE) / SECOND)
  let minuteString = `${minutes}:`.padStart(2, '0')
  let secondString = `${seconds}`.padStart(2, '0')
  let hourString = ''
  if(hours) {
    minuteString = minuteString.padStart(3, '0')
    hourString = `${hours}:`
  }
  let toReturn = `${hourString}${minuteString}${secondString}`
  if(showMilliseconds) {
    let millis = Math.trunc(time % SECOND)
    let millisString = '.' + (`${millis}`.padStart(3, '0'))
    toReturn = toReturn + millisString
  }
  return toReturn
}

/**
 * Returns a time format to be passed to moment.js for display of times or durations
 * @param {boolean} subseconds whether to include subseconds in the display or not
 * @param {boolean} duration Whether the time should be displayed as a duration (true) or a time (false)
 * @returns A string if duration is false, and an array of strings if duration is true. These are to be passed
 * to moment() or moment.format() (only pass a string to moment.format())
 */
export const timeFormat = (subseconds=false, duration=false) => {
  if(duration) {
    if(subseconds) {
      return ['m:ss.SSS', 'H:mm:ss.SSS']
    } else {
      return ['m:ss', 'H:mm:ss']
    }
  } else {
    if(subseconds) {
      return 'h:mm:ss.SSS A'
    } else {
      return 'h:mm:ss A'
    }
  }
}

/**
 * Converts a string from camel case to capitalized words with spaces in between
 * @param {string} string The string to convert
 * @returns a string converted to capital case (ex: "loopPlayNow" -> "Loop Play Now")
 */
export const camelToCapitalized = (string) => {
  string = string.substring(0, 1).toLocaleUpperCase() + string.substring(1)
  return string.split(/([A-Z][a-z]*)/).filter(word => !!word).join(' ')
}

/**
 * Encodes a file path to be used in a url
 * @param {string/array} path The file path to be encoded
 * @returns A string/array path (same type as was passed in) with each part of the url
 *  encoded with encodeURIComponent (so that the slashes in the path are not encoded)
 */
export const encodeURIFilepath = (path) => {
  let isArray = (path instanceof Array)
  if(!isArray) {
    path = path.split('/')
  }
  path = path.map(encodeURIComponent)
  if(!isArray) {
    path = path.join('/')
  }
  return path
}

/**
 * Checks if the string given is a valid url
 * @param {string} toCheck A string that may or may not be a valid url
 * @returns A boolean indicating if the given string is a valid url
 */
export const validateURL = (toCheck) => {
  try {
    let urlCheck = new URL(toCheck)
    return !!urlCheck
  } catch (err) {
    return false
  }
}
