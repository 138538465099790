import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import {serverPath} from 'helpers/net_helpers'

import './ClipImages.css'

const IMAGE_WIDTH = 160

export default class ClipImage extends PureComponent {

  render() {
    let {source, sourceTime, position} = this.props
    return (
      <div className='ClipImageContainer' style={{width: `${IMAGE_WIDTH}px`, left: `${position}px`}}>
        <img className='ClipImage'
          src={serverPath(`/v2/files/grab-thumbnail/${source}?t=${sourceTime}&auth_token=${window.AARDVARK_ACCESS}`)}
          alt=""/>
      </div>
    )
  }

}

ClipImage.propTypes = {
  source: PropTypes.string,
  position: PropTypes.number.isRequired
}
