import React, {PureComponent} from 'react'
import {Button, Icon, Input} from 'semantic-ui-react'
import {DragSource, DropTarget} from 'react-dnd'

import {REGION_DRAG} from './DragConstant'

import './RegionSidebar.css'

class RegionSidebar extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {renaming: false}
    this.nameInput = React.createRef()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.renaming === true &&
      prevState.renaming === false &&
      this.nameInput.current) {
      this.nameInput.current.focus()
    }
  }

  toggleVideo = () => {
    let {frame, index, setFrameOptions} = this.props
    if(frame.options.video === false) {
      setFrameOptions(index, {video: true})
    } else {
      setFrameOptions(index, {video: false})
    }
  }

  toggleAudio = () => {
    let {frame, index, setFrameOptions} = this.props
    if(frame.options.audio === false) {
      setFrameOptions(index, {audio: true})
    } else {
      setFrameOptions(index, {audio: false})
    }
  }

  rename = (e) => {
    let {index, setFrameName} = this.props
    setFrameName(index, e.currentTarget.value)
    this.setState({renaming: false})
  }

  frameSelect = () => {
    let {selectFrame, index} = this.props
    selectFrame(index);
  }

  render() {
    let {frame,
      index,
      firstBlank,
      removeFrame,
      isDragging,
      connectDragSource,
      connectDropTarget,
      addFrame} = this.props
    let {renaming} = this.state

    if(!frame) {
      return (connectDropTarget(
        <div className='PlaylistRegion Sidebar'>
          {firstBlank ? (<Button id='PlaylistAddRegionButton' icon positive fluid onClick={() => {addFrame()}}>
            <Icon name='add'/> Add Frame
          </Button>) : ""}
        </div>
      ))
    } else if(isDragging) {
      return (<div className='PlaylistRegion Sidebar'/>)
    }

    let name = frame.name || `Region ${index + 1}`
    let audio = frame.options.audio !== false
    let video = frame.options.video !== false

    let audioIcon = audio ? 'volume up' : 'volume off'
    let videoIcon = video ? 'eye' : 'eye slash'

    let nameDisplay = renaming ?
      (<Input size='small'
        className='PlaylistRegionName'
        placeholder={`Frame ${index}`}
        defaultValue={name}
        ref={this.nameInput}
        onBlur={this.rename}
        onKeyPress={(e) => {e.stopPropagation(); if(e.key === 'Enter') this.rename(e)}}
        fluid/>) :
      (<div className='PlaylistRegionName noselect'
        onDoubleClick={() => {this.setState({renaming: true})}}
        title={name}>{name}</div>)

    let contents = connectDropTarget(
      <div className='PlaylistRegion Sidebar' onClick={this.frameSelect}>
        {nameDisplay}
        <Button.Group compact floated='right' basic size='small'>
          <Button icon
            compact
            onClick={this.toggleAudio}
            title={'Toggle Audio'}>
            <Icon name={audioIcon} color={audio ? undefined : 'red'}/>
          </Button>
          <Button icon
            compact
            onClick={this.toggleVideo}
            title={'Toggle Video'}>
            <Icon name={videoIcon} color={video ? undefined : 'red'}/>
          </Button>
          <Button icon
            compact
            onClick={() => {removeFrame(index)}}
            title={'Delete Region'}>
            <Icon name='delete'/>
          </Button>
        </Button.Group>
      </div>
    )

    if(renaming) {
      return contents
    } else {
      return connectDragSource(contents)
    }

  }

}

const beginDrag = (props, monitor, component) => {
  return {
    initialIndex: props.index,
  }
}

const endDrag = (props, monitor) => {
  if(monitor.didDrop()) {
    let {index} = monitor.getDropResult()
    props.reorderFrame(props.index, index)
  }
}

const dragCollect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
})

const drop = (props, monitor) => {
  if(props.frame && props.index !== monitor.getItem().initialIndex) {
    return {index: props.index}
  } else if(!props.frame) {
    return {index: props.frameCount}
  }
}

const dropCollect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget()
})

export default DropTarget(REGION_DRAG, {drop}, dropCollect)(
  DragSource(REGION_DRAG, {beginDrag, endDrag}, dragCollect)(RegionSidebar)
)
