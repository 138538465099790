import React, {PureComponent} from 'react'
import {Icon,
  Menu,
  Dropdown} from 'semantic-ui-react'

export default class CutsMenu extends PureComponent {

  handleSavePlaylist = (rename=false) => {
    let {savePlaylist} = this.props
    savePlaylist(rename)
  }

  render() {
    let {playlistUndo,
      shouldUndo,
      playlistRedo,
      shouldRedo} = this.props

    return (
      <Menu compact size='mini' icon='labeled'>
        <Menu.Item onClick={playlistUndo} disabled={!shouldUndo} title='Undo'>
          <Icon name='undo'/>
          Undo
        </Menu.Item>
        <Menu.Item onClick={playlistRedo} disabled={!shouldRedo} title='Redo'>
          <Icon className='redo'/>
          Redo
        </Menu.Item>
        <Dropdown trigger={
            <Menu.Item title='Save'>
              <Icon name='save'/>
              Save
            </Menu.Item>
          }
          icon={null}>
          <Dropdown.Menu>
            <Dropdown.Item text='Save' icon='save' onClick={() => {this.handleSavePlaylist()}} />
            <Dropdown.Item text='Save As' icon='pencil' onClick={() => {this.handleSavePlaylist(true)}} />
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    )
  }

}
