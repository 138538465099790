import React, {PureComponent} from 'react'

import {Image} from 'semantic-ui-react'

export default class ChannelConfidenceMonitor extends PureComponent {

  render() {
    let {onAir, channelKey, currentImage, backupImage, onLoad} = this.props

    if(!onAir) {
      return null
    }

    return (
      <div className="confidenceMonitor">
        <Image src={currentImage} size="medium" className='monitorImage' bordered={true} alt='' onLoad={() => onLoad(channelKey, currentImage)} onError={(e) => {URL.revokeObjectURL(currentImage)}}/>
        <Image src={backupImage} size='medium' className='monitorBackupImage' bordered={true} alt=''/>
      </div>
    )
  }

}
