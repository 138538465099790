import React, {PureComponent} from 'react'
import {Modal, Dimmer, Loader, Table} from 'semantic-ui-react'
import {fetchFromServer} from 'helpers/net_helpers'

export default class ConsentFormModal extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      formData: [],
      loading: true
    }
  }

  componentDidUpdate(prevprops) {
    let {path} = this.props
    if(path && path !== prevprops.path) {
      this.fetchConsentForm(path)
    }
  }

  fetchConsentForm = async (path) => {
    this.setState({loading: true})
    try {
      if(typeof path !== "string") {
        path = `/${path.join("/")}`
      }
      let res = await fetchFromServer(`/v2/files/consent-form/${encodeURI(path)}`)
      if(res.ok) {
        let formData = await res.text()
        formData = formData.split("\n").map((line) => {
          let [key, ...value] = line.split("=")
          value = value.join("=")
          return {key, value}
        })
        this.setState({formData})
      } else if(res.status !== 404) {
        let err = await res.text()
        console.error("Error fetching consent form:")
        console.error(`${res.status}: ${err}`)
      }
    } catch (err) {
      console.error("Error fetching consent form:")
      console.error(err)
    }
    this.setState({loading: false})
  }

  render() {
    let {path, closeModal} = this.props
    let {formData=[], loading} = this.state
    let isOpen = !!(path)

    let rows = formData.map(({key, value}) => (
      <Table.Row key={key}>
        <Table.Cell collapsing>{key}</Table.Cell>
        <Table.Cell>{value}</Table.Cell>
      </Table.Row>
    ))

    return (
      <Modal open={isOpen} closeIcon onClose={() => closeModal()}>
        <Modal.Header>Consent Form</Modal.Header>
        <Modal.Content scrolling>
          <Dimmer active={loading}>
            <Loader/>
          </Dimmer>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  Field
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Value
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    )
  }

}
