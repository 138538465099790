/**
 * Selector that returns an array of all start and/or end times of all clips from all frames
 */
import { createSelector } from 'reselect'
import { getTimesFromFrames } from 'helpers/playlist_helpers'
const getFrames = (state) => (state.frames)

export default createSelector([getFrames], (frames) => {
  return getTimesFromFrames(frames)
})
