import React, {PureComponent, Fragment} from 'react'
import {Table, Checkbox, Icon, Button} from 'semantic-ui-react'
//import moment from 'moment'
//import {timeFormat} from 'helpers/schedule_helpers'
import ScheduleItem from './ScheduleTableItem'
import ScheduleItemDropdown from './ScheduleItemDropdown'
import {millisToHourString} from 'helpers/general_helpers'
import moment from "moment"

class ScheduleTableBlock extends PureComponent {

  /**
   * When select box is clicked, select all children
   */
  handleSelect = () => {
    let {body, selectScheduleItems} = this.props
    let toSelect = body.contains.map((child) => {
      return child.key
    })
    selectScheduleItems(toSelect)
  }

  /**
   * Handler for changing the block's duration. Passed to dropdown menu
   */
  handleChangeBlockDuration = () => {
    let {scheduleBlock, itemActions, span, showSubseconds} = this.props
    let oldDuration = millisToHourString(span.duration())
    itemActions.messages.prompt('How long should this block be?', (newDuration) => {
      if(newDuration === null) {
        return
      }
      newDuration.subtract(span.end.diff(span.start))
      itemActions.expandScheduleBlock(newDuration, {key: scheduleBlock.key, munch: true})
    }, {type: 'duration', subseconds: showSubseconds, initialValue: oldDuration})
  }

  /**
   * Handler for removing block, optionally keeping or discarding children. Passed to dropdown menu
   */
  handleRemoveBlock = () => {
    let {scheduleBlock, itemActions} = this.props
    itemActions.removeBlock(scheduleBlock.key)
  }

  /**
   * Handler for moving block to another timeslot
   */
  handleMoveToTimeslot = () => {
    let {scheduleBlock, itemActions, type, span, showSubseconds} = this.props
    if(type === "empty") {
      return
    }
    itemActions.messages.prompt('What time should the block be moved to?', (timeToPaste) => {
      if(timeToPaste === null) {
        return
      }
      let {itemActions} = this.props
      let timeObject = span.start
      if(!(moment.isMoment(timeObject))) {
        timeObject = timeObject.closestMoment(timeToPaste)
      }
      let {years, months, date} = timeObject.toObject()
      timeToPaste.set({years, months, date})
      itemActions.moveKeysToTime([scheduleBlock.key], timeToPaste)
    }, {type: 'time', subseconds: showSubseconds})
  }

  render() {
    let {span,
      body,
      announce,
      selected,
      errors,
      clipboard,
      addScheduleItems,
      pasteScheduleItems,
      selectScheduleItems,
      itemActions,
      showSubseconds,
      hasSelectedFiles,
      scrollEvent,
      scrollSize,
      scheduleBlock} = this.props

    let cls = 'tableBlockLabel'
    if(selected) {
      cls = cls + ' selected'
    }

    let startTimeLabel = span.printStartTime({showMilliseconds: showSubseconds})
    let endTimeLabel = ''
    let durationLabel = ''
    let errs = false
    let header = null
    let rows = []
    let footer = null

    let quickButtonsInverted = false
    let headerFooterStyle = {}
    if(!selected && body.color) {
      let colors = /#([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})/.exec(body.color)
      if(colors) {
        let [R, G, B] = colors.slice(1).map(color => parseInt(color, 16))
        let luminence = (0.2126*R + 0.7152*G + 0.0722*B)
        headerFooterStyle = {
          backgroundColor: body.color
        }
        if(luminence < 100) {
          headerFooterStyle.color = "white"
          quickButtonsInverted = true
        }
      }
    }

    if(span) {
      let duration = millisToHourString(span.duration())
      durationLabel = (<>
        <Icon name="clock"/>
        {duration}
      </>)
    }

    if(errors && errors.length > 0) {
      errs = true
      errors.forEach((error) => {
        switch(error.type) {
          case 'FRONT_END':
          case 'FRONT_DROP_OFF':
            endTimeLabel = span.printEndTime({showMilliseconds: showSubseconds})
            break
          case 'REAR_END':
          case 'REAR_DROP_OFF':
            startTimeLabel = span.printStartTime({showMilliseconds: showSubseconds})
            break
          case 'TOTAL':
          case 'TOTAL_DROP_OFF':
          case 'RECURSIVE_BLOCK':
            endTimeLabel = span.printEndTime({showMilliseconds: showSubseconds})
            startTimeLabel = span.printStartTime({showMilliseconds: showSubseconds})
            break
          default:
            break
        }
      })
    }

    let announceIcon = ""
    if(announce === "block") {
      announceIcon = <Icon name="rss" title="Block will be announced in schedule instead of its items."/>
    } else if(announce === "items") {
      announceIcon = <Icon.Group title="Block will not be announced if it is empty.">
        <Icon name="rss"/>
        <Icon corner color="red" name="delete"/>
      </Icon.Group>
    }

    let dropdownOptions = [
      {key:'change block duration', text:'Change Block Duration', icon:'clock', onClick:this.handleChangeBlockDuration},
      {key:'replicate forward', text:'Replicate Block Backward', icon:'sort ascending', onClick:(()=>{itemActions.replicateBlock('backward', scheduleBlock.key)})},
      {key:'replicate backward', text:'Replicate Block Forward', icon:'sort descending', onClick:(()=>{itemActions.replicateBlock('forward', scheduleBlock.key)})},
      {key:'move to timeslot', text:'Move To Timeslot', icon:'share', onClick:this.handleMoveToTimeslot},
      {key:'cut block', text:'Cut Block', icon:'cut', onClick:(() => {itemActions.moveScheduleItems([scheduleBlock.key])})},
      {key:'copy block', text:'Copy Block', icon:'copy', onClick:(() => {itemActions.copyScheduleItems([scheduleBlock.key])})},
      {key:'remove block', text:'Delete Block', icon:'remove', onClick:this.handleRemoveBlock},
    ]

    let quickButtons = (
      <Button.Group size="tiny" className="blockQuickButtons" icon compact basic inverted={quickButtonsInverted} attached="left">
        <Button icon="compress" title="Fit Block to Contents" onClick={() => {itemActions.fitScheduleBlock(scheduleBlock.key)}}/>
        <Button icon="sort" title="Replicate Block" onClick={()=>{itemActions.replicateBlock('both', scheduleBlock.key)}}/>
      </Button.Group>
    )

    header = (<Table.Row key={body.label + ' ' + span.printStartTime({showMilliseconds: showSubseconds})} className={cls} error={errs} style={headerFooterStyle}>
        <Table.Cell collapsing textAlign='center' onClick={this.handleSelect}><Checkbox checked={selected}/></Table.Cell>
        <Table.Cell collapsing textAlign='center'>
          {quickButtons}
          <ScheduleItemDropdown options={dropdownOptions}/>
        </Table.Cell>
        <Table.Cell width={2} className='blockTime' textAlign="center" onClick={this.handleMoveToTimeslot}>
          <span className='blockTimeLabel'>{startTimeLabel}</span>
        </Table.Cell>
        <Table.Cell width={2} textAlign="center" onClick={this.handleChangeBlockDuration}>
          {durationLabel}
        </Table.Cell>
        <Table.Cell width={10} title={body.label}><span>{body.label}{announceIcon}</span></Table.Cell>
      </Table.Row>)
    body.contains.forEach((item) => {
        rows.push(<ScheduleItem {...item}
          scheduleItem={item}
          blockSelected={selected}
          className={item.type === 'empty' ? 'tableBlockItem empty' : 'tableBlockItem'}
          blockColor={body.color}
          key={item.type !== 'empty' ? item.key : item.body.label + ' ' + item.span.start.toString()}
          addScheduleItems={addScheduleItems}
          pasteScheduleItems={pasteScheduleItems}
          hasSelectedFiles={hasSelectedFiles}
          itemActions={itemActions}
          clipboard={clipboard}
          inBlock={true}
          scrollEvent={scrollEvent}
          selectScheduleItems={selectScheduleItems}
          showSubseconds={showSubseconds}
          scrollSize={scrollSize}/>)
    })
    footer = (<Table.Row key={'END of ' + body.label + ' ' + span.printEndTime({showMilliseconds: showSubseconds})} className={cls + " blockFooter"} error={errs} style={headerFooterStyle}>
        <Table.Cell collapsing/>
        <Table.Cell collapsing/>
        <Table.Cell width={1} className='blockTime' textAlign="center">
          <span className='blockTimeLabel'>{endTimeLabel}</span>
        </Table.Cell>
        <Table.Cell width={1}/>
        <Table.Cell width={10} title={'END of ' + body.label}><span>{'END of ' + body.label}</span></Table.Cell>
      </Table.Row>)

    return (
      <Fragment>
        {header}
        {rows}
        {footer}
      </Fragment>
    )
  }

}

export default ScheduleTableBlock
