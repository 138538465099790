import React, {PureComponent} from 'react'
import {Segment,
  Accordion,
  Icon,
  Button} from 'semantic-ui-react'
import {camelToCapitalized} from 'helpers/general_helpers'
import {typeDefault, matchesSearchString} from 'helpers/settings_helpers'

import { connect } from 'react-redux'

import SettingsGroup from './SettingsGroup'
import SettingPanel from './SettingPanel'

export class SettingsArray extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {open: false}
  }

  handleToggle = () => {
    this.setState((state) => ({...state, open: !state.open}))
  }

  addItem = () => {
    let {service,
      serviceType,
      address,
      modifySettings,
      value,
      schema} = this.props
    let currentValue = value !== undefined ? value : schema.default
    let {type} = schema.items
    if(schema.maxItems === undefined || currentValue.length < schema.maxItems) {
      let newValue = currentValue.concat(typeDefault(type))
      modifySettings(serviceType, service, address, newValue)
    }
  }

  removeItem = (e, data) => {
    let {service,
      serviceType,
      address,
      modifySettings,
      value,
      schema} = this.props
    let currentValue = value !== undefined ? value : schema.default
    if(!schema.minItems || currentValue.length > schema.minItems) {
      let newValue = [...currentValue]
      newValue.splice(data.index, 1)
      modifySettings(serviceType, service, address, newValue)
    }
  }

  render() {
    let {service,
      serviceType,
      setting,
      value,
      schema,
      address,
      settingsSearchString,
      modifySettings,
      children,
      settingListView} = this.props
    let {open} = this.state
    let {items, minItems, maxItems} = schema

    // Renders settings that are basic
    // settingListView is coming from redux store... This gets updated when a toggle settings button is pressed
    if (schema.advanced && settingListView === true) {
      return null
    }

    if(settingsSearchString) {
      if(!matchesSearchString(address.join(', '), schema, settingsSearchString)) {
        return null
      }
    }

    let currentValue = value !== undefined ? value : schema.default
    let head = camelToCapitalized(setting)
    let childrens = []

    if(!currentValue) {
      currentValue = []
    }

    if(minItems) {
      for(let i = 0; i < minItems; i++) {
        if(!currentValue[i]) {
          currentValue[i] = typeDefault(items.type)
        }
      }
    }

    let childrenProps = {service, serviceType, modifySettings, settingsSearchString}
    let removeButton = null

    if(open) {
      childrens = currentValue.map((value, index) => {
        if(minItems && currentValue.length > minItems) {
          removeButton = <Button negative
            icon='remove'
            className='SettingRemoveButton'
            floated='right'
            index={index}
            onClick={this.removeItem}/>
        }
        if(items.type === 'object' ||
          (items.type instanceof Array &&
          items.type.includes('object'))) {
          return (<SettingsGroup key={index}
          address={[...address, index]}
          setting={`${setting} ${index + 1}`}
          value={value}
          schema={items}
          {...childrenProps}>
            {removeButton}
          </SettingsGroup>)
        } else if(items.type === 'array' ||
          (items.type instanceof Array &&
          items.type.includes('array'))) {
          return (<SettingsArray key={index}
            address={[...address, index]}
            setting={`${setting} ${index + 1}`}
            value={value}
            schema={items}
            {...childrenProps}>
            {removeButton}
          </SettingsArray>)
        } else {
          return (<SettingPanel key={index}
            address={[...address, index]}
            setting={`${setting} ${index + 1}`}
            value={value}
            schema={items}
            {...childrenProps}>
            {removeButton}
          </SettingPanel>)
        }
      })

      if(maxItems && childrens.length < maxItems) {
        childrens.push(<Segment key={'add'}>
          <Button positive icon='add' fluid onClick={this.addItem}/>
        </Segment>)
      }
    }

    let description = schema.description ? <div className='Setting Description'>{schema.description}</div> : null

    return (<Segment key={setting}>
      <Accordion fluid>
        <Accordion.Title className='SettingsCategoryHeader' active={open} onClick={this.handleToggle}>
          <Icon name="dropdown"/>
          {head}
          {children}
          {description}
        </Accordion.Title>
        <Accordion.Content active={open}>
          <Segment.Group>
            {childrens}
          </Segment.Group>
        </Accordion.Content>
      </Accordion>
    </Segment>)
  }

}


const mapStateToProps = state => ({
  settingListView: state.settings_list_render.basicView
})

export default connect(
  mapStateToProps,
)(SettingsArray)