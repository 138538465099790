import React, {PureComponent} from 'react'

import TimeBar from './TimeBar'
import TimeHeader from './TimeHeader'
import Region from './Region'
import HorizontalScrollbar from './HorizontalScrollbar'
import Toolbar from './Toolbar'
import SelectionBox from './SelectionBox'

import MessageContext from 'containers/MessageContext'

import {cursorTime, pixelsToMilliseconds, millisecondsToPixels, magnetize} from 'helpers/playlist_helpers'

import './Container.css'

class TimelineContainer extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      mouseX: 0,
      mouseY: 0,
      lineWidth: 0,
      lineLeft: 0,
      dragTime: -1,
      isDragging: false,
      updateRequest: -1,
      selectStart: null,
      selectEnd: null,
      regionBoxes: []
    }
    this.setMouseState = this.setMouseState.bind(this)
    this.updatePreviewTime = this.updatePreviewTime.bind(this)
    this.updateTimelineStart = this.updateTimelineStart.bind(this)
    this.setLineState = this.setLineState.bind(this)
    this.setDragUpdateRequest = this.setDragUpdateRequest.bind(this)
    this.setIsDragging = this.setIsDragging.bind(this)
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.setMouseState)
    window.addEventListener('mouseup', this.endSelectRange)
    window.addEventListener('keydown', this.handleKeyShortcuts)
  }

  componentDidUpdate(prevProps, prevState) {
    let {
      previewFollow,
      previewTime,
      zoomLevel,
    } = this.props
    let {
      mouseX,
      mouseY,
      selectStart,
      selectEnd,
      updateRequest,
      dragTime,
      isDragging,
      lineWidth
    } = this.state
    if(previewFollow && updateRequest === -1) {
      this.setState({updateRequest: requestAnimationFrame(this.updatePreviewTime)})
    }
    if(previewTime !== prevProps.previewTime) {
      this.updateTimelineStart(previewTime)
    }
    if(selectStart !== null && selectEnd !== null) {
      if (mouseX !== prevState.mouseX || mouseY !== prevState.mouseY) {
        this.modifySelectRange();
      }
      this.setDragUpdateRequest(mouseX);
    }
    if(dragTime !== prevState.dragTime && isDragging) {
      let scrollMargin = pixelsToMilliseconds(lineWidth / 10, zoomLevel)
      this.updateTimelineStart(dragTime - scrollMargin, dragTime + scrollMargin)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.setMouseState)
    window.removeEventListener('mouseup', this.endSelectRange)
    window.removeEventListener('keydown', this.handleKeyShortcuts)
  }

  updateRegionBox = (index, top, bottom) => {
    let boxes = [...this.state.regionBoxes]
    boxes[index] = {top, bottom}
    this.setState((state) => ({...state, regionBoxes: boxes}))
  }

  startSelectRange = (index) => {
    let {zoomLevel, timelineViewStart} = this.props
    let {mouseX, mouseY, lineLeft, selectStart, regionBoxes} = this.state
    if(selectStart === null) {
      let time = cursorTime(mouseX, lineLeft, zoomLevel, timelineViewStart)
      if(index === undefined) {
        regionBoxes.forEach((box) => {
          if(mouseY < box.top) {
            index = 0
          } else if (mouseY > box.bottom) {
            index = (regionBoxes.length - 1)
          }
        })
      }
      this.setState((state) => ({...state, selectStart: {index, time}, selectEnd: {index, time}}))
      this.setDragUpdateRequest(mouseX)
    }
  }

  modifySelectRange = () => {
    let {zoomLevel, timelineViewStart} = this.props
    let {selectStart,
      lineLeft,
      mouseX,
      mouseY,
      regionBoxes} = this.state
    if(selectStart !== null) {
      let time = cursorTime(mouseX, lineLeft, zoomLevel, timelineViewStart)
      let index = regionBoxes.findIndex((box) => (mouseY >= box.top && mouseY <= box.bottom))
      if(index === -1) {
        regionBoxes.forEach((box) => {
          if(mouseY < box.top) {
            index = 0
          } else if (mouseY > box.bottom) {
            index = (regionBoxes.length - 1)
          }
        })
      }
      this.setState((state) => ({...state, selectEnd: {index, time}, isDragging: true}))
    }
  }

  endSelectRange = () => {
    let {selectTimelineRange} = this.props
    let {selectStart, selectEnd} = this.state
    if(selectStart !== null) {
      let {index:startIndex, time:startTime} = selectStart
      let {index:endIndex, time:endTime} = selectEnd
      selectTimelineRange(startTime, endTime, startIndex, endIndex)
      this.setState((state) => ({...state, selectStart: null, selectEnd: null, isDragging: false}))
    }
  }

  setDragUpdateRequest(cursorPos) {
    let {lineLeft} = this.state
    let {zoomLevel, timelineViewStart} = this.props
    if(this.state.updateRequest === -1) {
      let dragTime = cursorTime(cursorPos, lineLeft, zoomLevel, timelineViewStart)
      this.setState((state) => this.dragTimeRequest(state, this.setState, dragTime, cursorPos))
    }
  }

  // requestAnimationFrame is used to delay changing the state (and thus the re-render) for a frame.
  // If this is not done, the component will re-render infinitely and crash.
  dragTimeRequest(state, setState, dragTime, cursorPos) {
    if(state.updateRequest === -1) {
      return {
        ...state,
        updateRequest: requestAnimationFrame(() => {this.setState({dragTime, updateRequest: -1})})
      }
    } else {
      return state
    }
  }

  setIsDragging(dragging) {
    this.setState((state) => ({...state, isDragging: dragging}))
  }

  setMouseState(e) {
    this.setState({mouseX: e.clientX, mouseY: e.clientY})
  }

  setLineState(newState) {
    let lineState = {lineWidth: newState.width, lineLeft: newState.left}
    this.setState(lineState)
  }

  updatePreviewTime() {
    let {zoomLevel, timelineViewStart, setPreviewTime, magnetic, magnetTimes} = this.props
    let {lineLeft, mouseX} = this.state
    let mouseTime = cursorTime(mouseX, lineLeft, zoomLevel, timelineViewStart)
    if(magnetic) {
      mouseTime = magnetize(mouseTime, zoomLevel, magnetTimes)
    }
    setPreviewTime(mouseTime)
    this.setState({updateRequest: -1})
  }

  updateTimelineStart(startAdjust, endAdjust=null) {
    let {zoomLevel, timelineViewStart, setTimelineViewStart} = this.props
    if(!endAdjust && endAdjust !== 0) {
      endAdjust = startAdjust
    }
    let {lineWidth} = this.state
    let timeWidth = pixelsToMilliseconds(lineWidth, zoomLevel)
    let endTime = (timelineViewStart + timeWidth)
    if(endAdjust > endTime) {
      let newStart = endAdjust - timeWidth
      setTimelineViewStart(newStart)
    } else if(startAdjust < timelineViewStart) {
      setTimelineViewStart(startAdjust)
    }
  }

  handleKeyShortcuts = (e) => {
    let {zoomLevel,
      previewTime,
      frameRate,
      magnetTimes,
      clipboardSelectedClips,
      pasteClipsAtPreview,
      deselectFrameClips,
      removeSelectedClips,
      playlistUndo,
      playlistRedo,
      setZoomLevel,
      setPreviewTime,
      togglePlaylistPlaying} = this.props
    if(e.target.tagName === 'INPUT') {
      return;
    }
    switch(e.key) {
      case 'c':
        if(e.ctrlKey) {
          clipboardSelectedClips()
        }
        break
      case 'v':
        if(e.ctrlKey) {
          pasteClipsAtPreview()
        }
        break
      case 'z':
        if(e.ctrlKey) {
          playlistUndo()
        }
        break
      case 'y':
        if(e.ctrlKey) {
          playlistRedo()
        }
        break
      case ']':
        if(e.ctrlKey) {
          setZoomLevel(zoomLevel / (1.1 ** 3))
        }
        break
      case '[':
        if(e.ctrlKey) {
          setZoomLevel(zoomLevel * (1.1 ** 3))
        }
        break
      case 'ArrowRight': {
        let fps = frameRate
        if(typeof fps === 'string') {
          fps = parseFloat(fps)
        }
        if(typeof fps === 'object') {
          let frameWidth = 1000 * fps.d / fps.n
          let newTime = previewTime + frameWidth
          newTime = (newTime + (fps.d / fps.n)) - (newTime % frameWidth)
          setPreviewTime(newTime)
        } else {
          let frameWidth = 1000 / fps
          let newTime = previewTime + frameWidth
          newTime = (newTime + (1 / fps)) - (newTime % frameWidth)
          setPreviewTime(newTime)
        }
        break
      }
      case 'ArrowLeft': {
        let fps = frameRate
        if(typeof fps === 'string') {
          fps = parseFloat(fps)
        }
        if(typeof fps === 'object') {
          let frameWidth = 1000 * fps.d / fps.n
          let newTime = previewTime - frameWidth
          newTime = (newTime + (fps.d / fps.n)) - (newTime % frameWidth)
          setPreviewTime(newTime)
        } else {
          let frameWidth = 1000 / fps
          let newTime = previewTime - frameWidth
          newTime = (newTime + (1 / fps)) - (newTime % frameWidth)
          setPreviewTime(newTime)
        }
        break
      }
      case 'ArrowUp': {
        let times = magnetTimes.sort((a, b) => (a - b))
        let goToTime = times.find((time) => (time > previewTime))
        if(goToTime !== undefined) {
          setPreviewTime(goToTime)
        }
        break
      }
      case 'ArrowDown': {
        let times = magnetTimes.sort((a, b) => (b - a))
        let goToTime = times.find((time) => (time < previewTime))
        if(goToTime !== undefined) {
          setPreviewTime(goToTime)
        } else if (previewTime > 0) {
          setPreviewTime(0)
        }
        break
      }
      case 'Escape':
        deselectFrameClips()
        break
      case 'Delete':
      case 'Backspace':
        removeSelectedClips()
        break
      case ' ':
        togglePlaylistPlaying()
        break
      default:
        break
    }
  }

  render() {
    let {previewTime,
    timelineViewStart,
    zoomLevel,
    frames,
    endOfPlaylist,
    magnetic,
    magnetTimes,
    libraryPreview,
    previewFollow,
    playlistScrollEnd,
    toolsList,
    selectedTool,
    selectedClips,
    clipboard,
    playInterval,
    selectedFrames,
    addFrame,
    removeFrame,
    reorderFrame,
    setFrameName,
    setFrameOptions,
    addFrameClip,
    removeFrameClip,
    moveFrameClip,
    modifyFrameClip,
    cutFrameClip,
    setZoomLevel,
    setPreviewTime,
    setTimelineViewStart,
    setPlaylistScrollEnd,
    setPreviewIndicatorFollow,
    setMagnetic,
    selectPlaylistTool,
    selectFrameClip,
    selectFrame,
    clipboardSelectedClips,
    pasteClipsAtPreview,
    togglePlaylistPlaying} = this.props

    let {mouseX,
      lineLeft,
      lineWidth,
      isDragging,
      regionBoxes,
      selectStart,
      selectEnd} = this.state

    let libraryOffset = null
    if(libraryPreview) {
      libraryOffset = mouseX - lineLeft
    }

    let regions = frames.map((frame, index) => {
      let selected = selectedFrames.includes(index)
      return (<Region
        frame={frame}
        timelineViewStart={timelineViewStart}
        zoomLevel={zoomLevel}
        lineWidth={lineWidth}
        index={index}
        key={index}
        selectedClips={selectedClips}
        selected={selected}
        playInterval={playInterval}
        removeFrame={removeFrame}
        setFrameName={setFrameName}
        setFrameOptions={setFrameOptions}
        addFrameClip={addFrameClip}
        modifyFrameClip={modifyFrameClip}
        reorderFrame={reorderFrame}
        removeFrameClip={removeFrameClip}
        moveFrameClip={moveFrameClip}
        cutFrameClip={cutFrameClip}
        setDragUpdateRequest={this.setDragUpdateRequest}
        setIsDragging={this.setIsDragging}
        magnetic={magnetic}
        magnetTimes={magnetTimes}
        selectedTool={selectedTool}
        previewTime={previewTime}
        libraryPreview={libraryPreview}
        libraryOffset={libraryOffset}
        selectFrameClip={selectFrameClip}
        selectFrame={selectFrame}
        regionBox={regionBoxes[index]}
        updateRegionBox={this.updateRegionBox}
        isDragging={isDragging}
        startSelectRange={this.startSelectRange}
        modifySelectRange={this.modifySelectRange}
        endSelectRange={this.endSelectRange}/>)
    }).reverse()
    regions.unshift(<Region
      frame={null}
      index={regions.length}
      firstBlank={true}
      frameCount={frames.length}
      timelineViewStart={timelineViewStart}
      zoomLevel={zoomLevel}
      key={regions.length}
      selectedClips={selectedClips}
      playInterval={playInterval}
      reorderFrame={reorderFrame}
      magnetic={magnetic}
      magnetTimes={magnetTimes}
      libraryPreview={libraryPreview}
      libraryOffset={libraryOffset}
      selectedTool={selectedTool}
      previewTime={previewTime}
      addFrame={addFrame}
      setDragUpdateRequest={this.setDragUpdateRequest}
      selectFrameClip={selectFrameClip}
      regionBox={regionBoxes[regions.length]}
      updateRegionBox={this.updateRegionBox}
      isDragging={isDragging}
      startSelectRange={this.startSelectRange}
      modifySelectRange={this.modifySelectRange}
      endSelectRange={this.endSelectRange}/>)

    let selectPosition = {display: 'none'}

    if(selectStart !== null && selectEnd !== null) {
      let {index:startIndex, time:startTime} = selectStart
      let {index:endIndex, time:endTime} = selectEnd
      if(startTime > endTime) {
        let temp = startTime
        startTime = endTime
        endTime = temp
      }
      if(startIndex < endIndex) {
        let temp = startIndex
        startIndex = endIndex
        endIndex = temp
      }
      let leftPx = lineLeft + millisecondsToPixels(startTime - timelineViewStart, zoomLevel)
      let widthPx = millisecondsToPixels(endTime - startTime, zoomLevel)
      if(leftPx < lineLeft) {
        widthPx = widthPx + (leftPx - lineLeft)
        leftPx = lineLeft
      }
      if((leftPx + widthPx) > (lineLeft + lineWidth)) {
        widthPx = (lineLeft + lineWidth) - leftPx
      }
      let left = `${leftPx}px`
      let width = `${widthPx}px`
      let topPx = regionBoxes[startIndex].top
      let heightPx = regionBoxes[endIndex].bottom - topPx
      let top = `${topPx}px`
      let height = `${heightPx}px`
      selectPosition = {top, left, width, height}
    }

    let showTimelineCursor = (!isDragging && !previewFollow)

    return (
      <div id='PlaylistTimeline'>
        <Toolbar toolsList={toolsList}
          selectedTool={selectedTool}
          magnetic={magnetic}
          isPlaying={playInterval !== null}
          setMagnetic={setMagnetic}
          selectPlaylistTool={selectPlaylistTool}
          shouldClipboard={selectedClips.length}
          shouldPaste={clipboard.length}
          clipboardSelectedClips={clipboardSelectedClips}
          pasteClipsAtPreview={pasteClipsAtPreview}
          togglePlaylistPlaying={togglePlaylistPlaying}/>
        <div id='PlaylistTimelineContents' className='noselect'>
          <div id='PlaylistHeader'>
            <MessageContext.Consumer>
              {value => <TimeHeader previewTime={previewTime}
                setPreviewTime={setPreviewTime}
                messages={value}/>}
            </MessageContext.Consumer>
            <TimeBar previewTime={previewTime}
              zoomLevel={zoomLevel}
              timelineViewStart={timelineViewStart}
              setZoomLevel={setZoomLevel}
              mouseX={mouseX}
              showTimelineCursor={showTimelineCursor}
              previewFollow={previewFollow}
              setPreviewTime={setPreviewTime}
              setTimelineViewStart={setTimelineViewStart}
              setPreviewIndicatorFollow={setPreviewIndicatorFollow}
              setLineState={this.setLineState}
              width={lineWidth}
              left={lineLeft}
              magnetic={magnetic}
              magnetTimes={magnetTimes}/>
          </div>
          <div id='PlaylistBody'>
            <SelectionBox position={selectPosition}/>
            <div id='PlaylistRegionContainer'>
              {regions}
            </div>
          </div>
        </div>
        <HorizontalScrollbar endOfPlaylist={endOfPlaylist}
          playlistScrollEnd={playlistScrollEnd}
          zoomLevel={zoomLevel}
          lineWidth={lineWidth}
          timelineViewStart={timelineViewStart}
          setPlaylistScrollEnd={setPlaylistScrollEnd}
          setTimelineViewStart={setTimelineViewStart}
          setZoomLevel={setZoomLevel}/>
      </div>
    )

  }

}

export default TimelineContainer
