import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {actions, changeTab, createTab, deleteTab} from 'redux/applications/simple_playlist_editor'
import {openApplication} from 'redux/menu'
import {loadFileData, setSearchNext} from 'redux/file_list'
import {messages} from 'redux/messages'
import {millisToHourString, encodeURIFilepath} from 'helpers/general_helpers'

import {Grid, Menu, Segment, Header, Dropdown, Icon} from 'semantic-ui-react'
import Library from 'containers/components/LibraryComponent'
import SimplePlaylist from 'components/SimpleListEditor/List'

import tabbedComponent from 'components/higher_order_components/tabbedComponent'
import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'

import './SimpleListEditor.css'

class SimplePlaylistEditor extends PureComponent {

  componentDidMount() {
    this.updateURL()
  }

  componentDidUpdate(prevProps) {
    let {listPath, listName} = this.props.present
    if(prevProps.present.listPath !== listPath ||
      prevProps.present.listName !== listName) {
      this.updateURL()
    }
  }

  updateURL = () => {
    let {present, openApplication} = this.props
    let fullPath = [...present.listPath, present.listName]
    if(present.listName && fullPath.length > 0) {
      fullPath = encodeURIFilepath(fullPath)
      openApplication('SimplePlaylist', `/${fullPath.join('/')}`)
    } else {
      openApplication('SimplePlaylist')
    }
  }

  handleLibraryUpdate = (selected, navpath) => {
    let {selectListLibraryFile, libraryUpdate} = this.props
    if(selected) {
      selectListLibraryFile(selected)
    }
    libraryUpdate(selected, navpath)
  }

  // Handler for edit playlist
  handleFileEditPlaylist = (path) => {
    path = path || this.props.selectedFiles[0]
    if(!path || !(path instanceof Array)) {
      return
    }
    let compareFunction = ([key, value]) => {
      let fullPath = [...value.props.present.listPath, value.props.present.listName]
      return fullPath.join('/') === path.join('/')
    }
    let createArgs = [path]
    this.props.openTab(compareFunction, createArgs)
  }

  handleOpenFile = (path, type) => {
    if(type.application) {
      if(type.application === 'SimplePlaylist') {
        this.handleFileEditPlaylist(path)
      } else {
        path = encodeURIFilepath(path)
        this.props.openApplication(type.application, `/${path.join('/')}`)
      }
    }
  }

  handleSavePlaylist = async (saveAs=false) => {
    let {saveSimplePlaylist} = this.props
    saveSimplePlaylist(saveAs)
  }

  handleRenamePlaylist = async () => {
    let {renameList} = this.props
    let {listName} = this.props.present
    let newName = await this.props.promptAsync('What do you want to name this playlist?', {initialValue: listName})
    if(newName) {
      renameList(newName)
    }
    return newName
  }

  render() {
    let {addListItem,
      removeListItem,
      moveListItem,
      changeItemDuration,
      simpleListUndo,
      simpleListRedo,
      switchRandomize,
      switchMissingExpired,
      switchInvisible,
      switchMute} = this.props
    let {videoList, settings} = this.props.present

    let randomizeCls = settings.randomize ? 'randomOn' : ''
    let skipMEICls = settings.skipMissingExpired ? 'randomOn' : ''
    let MuteCls = settings.mute ? 'randomOn' : '';
    let HideCls = settings.invisible ? 'randomOn' : '';

    let totalDuration = millisToHourString(videoList.reduce((acc, val) => (acc + (val.end - val.start)), 0))

    let canUndo = (this.props.past.length > 0)
    let canRedo = (this.props.future.length > 0)

    return(
      <div id='SimpleListEditor'>
        <Grid columns={2} id='SimpleListGrid'>
          <Grid.Column>
            <div id='SimpleListPanel'>
              <Menu icon='labeled' fluid size='tiny' widths={8} attached='top'>
                <Menu.Item icon='undo' content='Undo' onClick={simpleListUndo} disabled={!canUndo}/>
                <Menu.Item icon='redo' content='Redo' onClick={simpleListRedo} disabled={!canRedo}/>
                <Dropdown trigger={
                    <Menu.Item title='Save'>
                      <Icon name='save'/>
                      Save
                    </Menu.Item>
                  }
                  item
                  id='SimpleListSaveDropdown'
                  icon={null}>
                  <Dropdown.Menu>
                    <Dropdown.Item text='Save' icon='save' onClick={() => {this.handleSavePlaylist()}} />
                    <Dropdown.Item text='Save As' icon='pencil' onClick={() => {this.handleSavePlaylist(true)}} />
                  </Dropdown.Menu>
                </Dropdown>
                <Menu.Item icon='edit' content='Rename' onClick={this.handleRenamePlaylist}/>
                <Menu.Item icon='random' content='Randomize' onClick={() => switchRandomize()} className={randomizeCls}/>
                <Menu.Item icon='angle double right' content="Skip Missing/Expired Items" onClick={() => switchMissingExpired()} className={skipMEICls}/>
                <Menu.Item icon='mute' content="Mute" onClick={() => switchMute()} className={MuteCls}/>
                <Menu.Item icon='hide' content="Invisible" onClick={() => switchInvisible()} className={HideCls}/>
              </Menu>
              <Segment id='SimpleListItems' onClick={() => addListItem()} attached>
                <SimplePlaylist videoList={videoList}
                  addListItem={addListItem}
                  removeListItem={removeListItem}
                  moveListItem={moveListItem}
                  changeItemDuration={changeItemDuration}/>
              </Segment>
              <Header attached='bottom' block size='medium'>
                {videoList.length} items, {totalDuration} total duration
              </Header>
            </div>
          </Grid.Column>
          <Grid.Column>
            <Library update={this.handleLibraryUpdate}
              handleOpenFile={this.handleOpenFile}
              overridePath={this.props._library.navpath}
              inputAssociations
              compact
              selectOne/>
          </Grid.Column>
        </Grid>
      </div>
    )
  }

}

const initialize = (props) => {
  if(props.match.params.path) {
    let {_tabs, _tabData} = props
    let path = decodeURIComponent(props.match.params.path)
    let tabId = Object.entries(_tabData).find(([key, value]) => {
      let fullPath = [...value.props.present.listPath, value.props.present.listName]
      return fullPath.join('/') === path
    })
    if(tabId instanceof Array) {
      tabId = tabId[0]
    }
    let tabInd = _tabs.findIndex((tab) => tab === tabId)
    if(tabInd > -1) {
      props._changeTab(tabInd);
    } else {
      props._createTab(path.split('/'));
    }
    return
  }
  if(props._tabs.length === 0) {
    props._createTab();
  }
}

let mapStateToProps = (state) => ({
  _tabs: state.simple_playlist_editor.tabs,
  _tabData: state.simple_playlist_editor.tabData,
  _activeTab: state.simple_playlist_editor.activeTab,
  _initialize: initialize,
  _tabActions: actions,
  filelist: state.file_list,
})

let mapDispatchToProps = (dispatch) => {
  return {
    ...(bindActionCreators({
      loadFileData,
      openApplication,
      setSearchNext,
      ...messages,
      _changeTab: changeTab,
      _createTab: createTab,
      _deleteTab: deleteTab
    }, dispatch)),
    _dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(tabbedComponent(loadingIndicator(SimplePlaylistEditor)))
