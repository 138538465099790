import React, { PureComponent } from 'react'
import { Header,
  Divider,
  Icon,
  Button,
  Table,
  Image} from 'semantic-ui-react'
import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'
import moment from 'moment'

import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { openApplication } from 'redux/menu'
import { messages } from 'redux/messages'
import { actions } from 'redux/components/output_recording'

import { getIn, millisToHourString } from 'helpers/general_helpers'
import { parseFileSize } from 'helpers/library_helpers'

import SettingIdSelector from 'selectors/SettingIdSelector'

import InputRecordingModal from 'components/Settings/InputControls/InputRecordingModal'
import RecordingEventRow from 'components/Settings/InputControls//RecordingEventRow'

import './OutputRecording.css';
// import 'semantic-ui-css/semantic.min.css';

export class OutputRecordingApp extends PureComponent {

    componentDidMount() {
      this.props.outputRecordingStatus(this.props.id)
      this.poll()
      this.pollUpdate = setTimeout(this.poll, 2000)
    }

    poll = () => {
      this.props.outputRecordingStatus(this.props.id)
      this.props.fetchEvents()
      this.pollUpdate = setTimeout(this.poll, 2000)
    }

    componentWillUnmount() {
      clearTimeout(this.pollUpdate)
    }

    submitEventEditModal = () => {
      let { output_recording, ...actions } = this.props
      if(output_recording.eventModalType === 'add') {
        actions.addNewEvent(this.props.id)
      } else if (output_recording.eventModalType === 'edit') {
        actions.editEvent(this.props.id)
      }
    }

    handleRenameRecording = () => {
      let { output_recording, ...actions } = this.props
      actions.prompt("What do you want to call this recording?", (val) => {
        if(val === null) {
          return
        }
        actions.renameRecording(val)
      })
    }

    handleGoLive = (chan) => {
      this.props.confirm(`Click OK when you are ready to go live on Channel ${chan}.`, (val) => {
        if(val) {
          this.props.goLive(chan)
        }
      }, {confirmText: 'OK', cancelText: 'Cancel'})
    }

    handleEndLive = (chan) => {
      this.props.confirm(`Click OK when you are ready to end live on Channel ${chan}.`, (val) => {
        if(val) {
          this.props.endLive(chan)
        }
      }, {confirmText: 'OK', cancelText: 'Cancel'})
    }

    formatTimeValue = (data) => {
      if(typeof data === 'string') {
        let formats = ['h:mm:ss a', 'h:mm:ssa', 'h:mm a', 'h:mma']
        let momVal = moment(data, formats, true)
        if(momVal.isValid()) {
          data = momVal
        }
      }
      return data
    }

    formatDateValue = (data) => {
      if(typeof data === 'string') {
        let formats = ['M/D/YYYY']
        let momVal = moment(data, formats, true)
        if(momVal.isValid()) {
          data = momVal
        }
      }
      return data
    }

    handleAddNewEvent = (eventData) => {
      let outputId = this.props.id
      this.props.addNewEvent(eventData, outputId)
    }

    handleEditEvent = (id, field, value) => {
      let outputId = this.props.id
      // Handle difference in key names from inputs to outputs due to reused component
      if(field === "recording name") {
        field = "name"
      }
      this.props.editEvent(id, field, value, outputId)
    }

    handleDeleteEvent = (recordingEvent) => {
      this.props.confirm(`Are you sure you want to delete the event ${recordingEvent.name} from input ${recordingEvent.output}?`, (val) => {
        if(val) {
          this.props.deleteEvent(recordingEvent.id)
        }
      }, {confirmText: "Delete", cancelText: "Cancel"})
    }

    handleAssociationSelect = (e, data) => {
      this.props.selectAssociation(data.value)
    }

    handleApplyAssociation = () => {
      if(this.props.output_recording.selectedAssociation) {
        this.props.applyAssociation()
      }
    }

    render() {
        let {openApplication, startOutputRecording, stopOutputRecording, renameOutputRecording, output_recording, ...actions} = this.props
        let outputId = this.props.id
        let recordingStatus = getIn(this.props, ['services', 'output', outputId, 'recording_status', "enc0"])
        let recordingLabel = '(Unknown)'
        let recordingName = '(Untitled)'
        let recordButton = ''
        let recordTime = ''
        let recordSize = ''
        let previewContainerCls = 'blackArea'

        let { events } = output_recording

        events = events.filter((evnt) => evnt.output === outputId)

        const listItems = events.map((event, index) => (
            <RecordingEventRow recordingEvent={event}
            onDelete={this.handleDeleteEvent}
            onChange={this.handleEditEvent}
            key={event.id}/>
        ));

        if(recordingStatus) {
          if(getIn(recordingStatus, ["active", 'running']) === '1') {
            recordingLabel = "(Recording)"
            recordTime = getIn(recordingStatus, ["active", 'rectm']) || "0"
            recordTime = millisToHourString(parseFloat(recordTime) * 1000)
            recordSize = getIn(recordingStatus, ["active", 'ebc']) || "0"
            recordSize = parseFileSize(recordSize)
            recordButton = <Button icon='stop' onClick={() => {stopOutputRecording(outputId)}}/>
            if(recordingLabel === "(waiting)") {
             previewContainerCls = 'blackArea waiting'
            } else {
             previewContainerCls = 'blackArea recording'
            }
          } else {
            recordingLabel = "(Not Recording)"
            recordButton = <Button icon={<Icon name='circle' color='red'/>} onClick={() => {startOutputRecording(outputId)}}/>
          }
          recordingName = recordingStatus.name || '(Untitled)'
        }

        let modalActions = {
          ...actions,
          addNewEvent: this.handleAddNewEvent
        }

        return (
            <div className="inputRecording">
                <div className="inputRecording__MainContainer">
                    <Header as='h2' icon='record' content={outputId} id='RecordingControlsHeader'/>
                    <Divider fitted/>
                    <div className="inputRecording__Row noselect">
                        <div className="inputRecording--div">
                            <div className={previewContainerCls}>
                              <Image id='recordingPreview' alt=''/>
                            </div>
                        </div>
                        <div className="inputRecording--div noselect">
                            <div className="inputRecording--buttonMenu">
                              <Button.Group>
                                <Button icon='arrow left' content='Back' onClick={() => openApplication('Settings', '/output')}/>
                                <Button icon='settings' content='Settings' onClick={() => openApplication('Settings', `/output/${outputId}`)}/>
                              </Button.Group>
                            </div>
                            <div>
                                <Button.Group style={{marginRight: 10}}>
                                  {recordButton}
                                </Button.Group>
                                {recordingLabel}
                            </div>
                            <div>
                              {recordTime}
                            </div>
                            <div>
                              {recordSize}
                            </div>
                            <div className="inputRecording--buttonMargin5">
                                <Button icon labelPosition='left' style={{marginRight: 10}} onClick={() => {renameOutputRecording(outputId)}}>
                                    <Icon name='edit' />
                                    Rename
                                </Button>
                                {recordingName}
                            </div>
                        </div>
                    </div>
                    <div className="inputRecording__listArea noselect">
                        Timer record events:
                        <Button icon labelPosition='right' onClick={actions.eventModalOpen} style={{marginLeft: 10}}>
                            <Icon name='edit' />
                            Add event
                        </Button>
                        <div className="inputRecording__table">
                            <Table celled padded>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell singleLine>Name</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Start Date</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Finish Date</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {listItems}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
                <InputRecordingModal input_recording={output_recording} actions={modalActions}/>
            </div>
        );
    }
}

let mapStateToProps = (state, ownProps) => ({
    output_recording: state.output_recording,
    id: SettingIdSelector(ownProps),
    state,
    _loader: state.output_recording._loader,
    _loaderID: state.output_recording._loaderID
})

let mapDispatchToProps = (dispatch) => bindActionCreators({...messages, openApplication, ...actions}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(loadingIndicator(OutputRecordingApp))
