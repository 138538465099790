import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {changeTab, createTab, deleteTab} from 'redux/applications/schedule'
import {openApplication} from 'redux/menu'
import {messages} from 'redux/messages'

import {getIn} from 'helpers/general_helpers'
import {fileTypeInfo} from 'helpers/library_helpers'
import {SCHEDULE_TYPES} from "helpers/schedule_helpers"

import scheduleSelector from 'selectors/ScheduleSelector'
import scheduleCurrentItemSelector from 'selectors/ScheduleCurrentItemSelector'
import dateSelector from 'selectors/ScheduleDateFormatSelector'
import ChannelPlayingSelector from 'selectors/ChannelPlayingSelector'

import SchedulePanel from 'components/Schedule/SchedulePanel'
import ScheduleSidePane from 'components/Schedule/ScheduleSidePane'

import tabbedComponent from 'components/higher_order_components/tabbedComponent'
import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'

import './Schedule.css'

class ScheduleEditor extends PureComponent {

  componentDidMount() {
    this.updateURL()
    this.props.checkScheduleMissingFiles()
  }

  componentDidUpdate(prevProps) {
    let {filepath, filename} = this.props.present
    if(prevProps.present.filepath !== filepath ||
      prevProps.present.filename !== filename) {
      this.updateURL()
    }
    if(prevProps.file_list.fileData !== this.props.file_list.fileData) {
      this.props.checkScheduleMissingFiles()
    }
  }

  updateURL = () => {
    let {filepath, filename} = this.props.present
    if(filepath.length > 0 && filename) {
      let fullpath = [...filepath, filename]
      this.props.openApplication('Schedule', `/${fullpath.join('/')}`)
    } else {
      this.props.openApplication('Schedule', '/')
    }
  }

  // Handler for edit schedule
  handleFileEditSchedule = (path) => {
    let {filepath, filename} = this.props.present
    let fullpath = [...filepath, filename]
    path = path || this.props.selectedFiles[0]
    if(!path || !(path instanceof Array)) {
      return
    }
    let compareFunction = ([key, value]) => {
      return fullpath.join('/') === path.join('/')
    }
    let createArgs = [path]
    this.props.openTab(compareFunction, createArgs)
  }

  handleOpenFile = (path, type) => {
    if(type.application) {
      if(type.application === 'Schedule') {
        this.handleFileEditSchedule(path)
      } else {
        this.props.openApplication(type.application, `/${path.join('/')}`)
      }
    }
  }

  handleSaveAs = (name, dir) => {
    this.props.toggleSaveAsModal()
    this.props.changeScheduleFilepath(name, dir)
    this.props.saveScheduleData()
  }

  handleReplicate = async () => {
    let {promptAsync, replicate, present} = this.props
    let toReplicate = present.selectedScheduleItems
    if(!present.scheduleSwap) {
      return
    }
    let selectedTimes = await promptAsync("Select times to replicate to.", {
      type: "scheduleDate",
      scheduleType: present.scheduleSwap.type,
      viewTime: present.viewTime,
      showMilliseconds: present.showSubseconds,
      intervalBasis: present.scheduleSwap.intervalBasis,
      intervalDuration: present.scheduleSwap.intervalDuration
    })
    if(!selectedTimes) {
      return
    }
    replicate(selectedTimes, toReplicate)
    return
  }

  handleNewBlock = async () => {
    let {promptAsync, createScheduleBlock, present} = this.props
    if(!present.scheduleSwap) {
      return
    }
    let name = await promptAsync("What should the block be called?")
    if(!name) {
      return
    }
    let duration = await promptAsync("How long should this block be?", {
      type: "duration",
      subseconds: present.showSubseconds
    })
    if(!duration) {
      return
    }
    let selectedTimes = await promptAsync("What times do you want the block to be added to?", {
      type: "scheduleDate",
      scheduleType: present.scheduleSwap.type,
      viewTime: present.viewTime,
      showMilliseconds: present.showSubseconds,
      intervalBasis: present.scheduleSwap.intervalBasis,
      intervalDuration: present.scheduleSwap.intervalDuration
    })
    if(!selectedTimes) {
      return
    }
    createScheduleBlock(name, selectedTimes, duration)
    return
  }

  render() {
    let {file_list,
      match,
      present,
      past,
      future,
      global,
      unsaved,
      channelItems,
      timeSync,
      timezone,
      clipboard,
      ...actions} = this.props
    // Get full path of schedule file
    let schedPath = [...present.filepath, present.filename].join('/')
    // Find if any of the channels are playing the current schedule
    let playingItems = Object.entries(channelItems).filter(([key, items]) => {
      return items.onAir && ((!items.queue && (schedPath === items.assigned || `/${schedPath}` === items.assigned)) ||
        (items.queue && (schedPath === items.file || `/${schedPath}` === items.file)))
    })
    // Get display schedule
    let displaySchedule = scheduleSelector({...present, timeSync, timezone, playingItems})
    // Get which, if any, items in the schedule are now playing
    let currentItem = scheduleCurrentItemSelector({...present, timeSync})
    let playingDefault = null
    // Skip the findIndex if not playing on any channels
    if(playingItems && currentItem && currentItem.type === "default") {
      playingDefault = {...currentItem, playingItems}
    }
    // Get a list of days/dates that have at least one schedule item in them
    let datelist = dateSelector(present)

    let scheduleData = present.scheduleSwap
    let shouldUndo = past.length > 0
    let shouldRedo = future.length > 0

    let activeSchedule = scheduleData && SCHEDULE_TYPES.includes(scheduleData.type)

    let hasSelectedFiles = false
    switch(present.tabIndex) {
      // LIBRARY TAB
      case 0:
        hasSelectedFiles = !!(present.selectedFiles.length)
        break;
      // BLOCK TAB
      case 1:
        hasSelectedFiles = !!(present.selectedBlocks.length)
        break;
      // EVENT TAB
      case 2:
        hasSelectedFiles = !!(present.selectedEvents.length)
        break;
      default:
        break;
    }
    /*
    present.tabIndex ?
      !!(present.selectedEvents.length) :
      !!(present.selectedFiles.length)
    */
    let selectCount = present.selectedScheduleItems.length

    let schedulePath = match.params.path ? '/' + match.params.path : ''

    let hasErrors = 0
    let scheduleType

    if(activeSchedule) {
      hasErrors = present.scheduleSwap.errors.length
      scheduleType = scheduleData.type
    }

    actions = {...actions, openReplicateModal: this.handleReplicate}

    return (
      <div id='scheduleEditor'>
        <SchedulePanel scheduleData={scheduleData}
          displaySchedule={displaySchedule}
          shouldUndo={shouldUndo}
          shouldRedo={shouldRedo}
          datelist={datelist}
          clipboard={clipboard}
          filename={present.filename}
          viewTime={present.viewTime}
          showSubseconds={present.showSubseconds}
          scrollEvent={present.scrollEvent}
          scrollTop={present.scrollTop}
          unsaved={unsaved}
          actions={actions}
          hasErrors={hasErrors}
          hasSelectedFiles={hasSelectedFiles}
          selectCount={selectCount}
          playingDefault={playingDefault}
          schedulePath={schedulePath}/>
        <ScheduleSidePane
          tabIndex={present.tabIndex}
          changeSidebarTab={actions.changeSidebarTab}
          activeSchedule={activeSchedule}
          scheduleSwap={present.scheduleSwap}
          scheduleLibraryUpdate={actions.scheduleLibraryUpdate}
          libraryDeselect={present.libraryDeselect}
          libraryPath={present.libraryPath}
          handleOpenFile={this.handleOpenFile}
          file_list={file_list}
          scheduleType={scheduleType}
          selectedEvents={present.selectedEvents}
          showSubseconds={present.showSubseconds}
          goToTime={actions.goToTime}
          createEvent={actions.createEvent}
          changeEvent={actions.changeEvent}
          removeEvent={actions.removeEvent}
          selectEvents={actions.selectEvents}
          selectedBlocks={present.selectedBlocks}
          removeBlock={actions.removeBlocksByName}
          renameBlock={actions.renameBlocksByName}
          recolorBlock={actions.recolorBlocksByName}
          createBlock={this.handleNewBlock}
          setBlockAnnounce={actions.setBlocksAnnounceByName}
          selectBlocks={actions.selectBlocks}
          setSubsecondVisibility={actions.setSubsecondVisibility}
          timeSlotLength={present.timeSlotLength}
          changeTimeSlotLength={actions.changeTimeSlotLength}
        />
      </div>
    )
  }
}

const initialize = (props) => {
  if(props.match.params.path) {
    let {_tabs, _tabData} = props
    let path = decodeURIComponent(props.match.params.path)
    let tabId = Object.entries(_tabData).find(([key, value]) => {
      let fullpath = [...value.props.present.filepath, value.props.present.filename]
      return fullpath.join('/') === path
    })
    if(tabId instanceof Array) {
      tabId = tabId[0]
    }
    let tabInd = _tabs.findIndex((tab) => tab === tabId)
    if(tabInd > -1) {
      props._changeTab(tabInd);
    } else {
      props._createTab(path.split('/'));
    }
    return
  } else {
    let path = []
    if((!path || path.length === 0) && !(props.location.search && props.location.search.includes("new=1"))) {
      // Get schedule of currently selected channel
      let {activeChannel, channelItems} = props
      let item = getIn(channelItems, [activeChannel, 'assigned'])
      let itemType = getIn(channelItems, [activeChannel, 'assignedType'])
      if(item &&
        fileTypeInfo(itemType).type === 'schedule') {
        path = item
        if(!(path instanceof Array)) {
          path = path.split("/")
        }
        // Avoid double slash in schedule url, which causes issues with reloading
        if(path[0] === "") {
          path = path.slice(1)
        }
      }
    }
    if(path.length > 0) {
      let {_tabs, _tabData} = props
      let tabId = Object.entries(_tabData).find(([key, value]) => {
        let fullpath = [...value.props.present.filepath, value.props.present.filename]
        return fullpath.join('/') === path.join('/');
      })
      if(tabId instanceof Array) {
        tabId = tabId[0]
      }
      let tabInd = _tabs.findIndex((tab) => tab === tabId)
      if(tabInd > -1) {
        props._changeTab(tabInd);
      } else {
        props._createTab(path);
      }
    } else if (props._tabs.length === 0) {
      props._createTab();
    }
  }
}

let mapStateToProps = (state) => {
  return {
  file_list: state.file_list,
  activeChannel: state.channel.active_channel,
  channelItems: ChannelPlayingSelector(state.settings),
  timeSync: state.menu.timeSync,
  timezone: state.menu.timezone,
  clipboard: state.schedule_clipboard.clipboard,
  _tabs: state.schedule.tabs,
  _tabData: state.schedule.tabData,
  _activeTab: state.schedule.activeTab,
  _initialize: initialize
  }
}

let mapDispatchToProps = (dispatch) => ({...(bindActionCreators(
  {
    _changeTab: changeTab,
    _createTab: createTab,
    _deleteTab: deleteTab,
    openApplication,
    ...messages
  }, dispatch)),
  _dispatch: dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(tabbedComponent(loadingIndicator(ScheduleEditor)))
