import React, {Component} from 'react'

import {Stage, Layer, Rect, Text} from 'react-konva'
import { withResizeDetector } from 'react-resize-detector';

import Frame from './Frame'
import FrameGroup from './FrameGroup'

import './Window.css'

// patharray is Array [ { object } ]
// where object = {item: patharray}
// where patharray = [ "" ] if not assigned or
//       patharray = [ "", "mnt", "main", "file", "path" ]
// FIXME: The array should be EMPTY if nothing assigned, and get rid of the empty array element at the beginning!
function hasPathItem(patharray) {
  if (patharray === undefined) return false
  if (!(patharray instanceof Array)) return false
  if (patharray.length === 0) return false
  // further checks: an unassigned item will have item = [ "" ], check for that too
  if (patharray[0] === undefined) return false
  if (patharray[0].item === undefined) return false
  if (patharray[0].item.length === 0) return false
  if (patharray[0].item.length === 1) { // NTS: item[0] == "" doesn't mean anything, a valid path has [ "", "mnt", "main", ... ]
    if (patharray[0].item[0] === "") return false // [ "" ]
  }
  return true
}

function buildFrameComponent(frame, index, props) {
  if(frame) {
    let {width, height, previewTime, snapToGrid, resizeFrame, moveFrame, selectFrame, activeChannel, running} = props
    let timeline = frame.timeline || []
    //
    if (frame.options.enabled < 0) return null
    if (!hasPathItem(timeline)) return null
    //
    let rect = {
      left: ((frame.position.x * width) / 100),
      top: ((frame.position.y * height) / 100),
      width: ((frame.size.width * width) / 100),
      height: ((frame.size.height * height) / 100)
    }
    return (<Frame key={index}
      snapToGrid={snapToGrid}
      rect={rect}
      container={{width, height}}
      {...frame}
      running={running}
      activeChannel={activeChannel}
      previewTime={previewTime}
      zIndex={index}
      resizeFrame={resizeFrame}
      moveFrame={moveFrame}
      selectFrame={selectFrame}/>)
  } else {
    return null
  }
}

class FrameEditor extends Component {

  constructor(props) {
    super(props)
    this.state = {groupUp: false}
  }

  handleMouseOver = (e) => {
    this.actionSafeBoundary.setAttrs({opacity: 0.4})
    this.actionSafeText.setAttrs({opacity: 0.6, stroke: "black"})
    this.titleSafeBoundary.setAttrs({opacity: 0.4})
    this.titleSafeText.setAttrs({opacity: 0.6, stroke: "black"})
    e.target.draw()
    this.setState({groupUp: true})
  }

  handleMouseOut = (e) => {
    this.actionSafeBoundary.setAttrs({opacity: 0})
    this.actionSafeText.setAttrs({opacity: 0})
    this.titleSafeBoundary.setAttrs({opacity: 0})
    this.titleSafeText.setAttrs({opacity: 0})
    e.target.draw()
    this.setState({groupUp: false})
  }

  render() {
    let {width,
      height,
      frames,
      previewTime,
      snapToGrid,
      resizeFrame,
      moveFrame} = this.props
    let {groupUp} = this.state
    let selectedGroup = null

    // Sort selected and unselected frames into two groups so we can both group and show selected frames on top,
    frames = frames.map((frame, index) => {frame.index = index; return frame})
    if(groupUp) {
      let selectedFrames = frames.filter((frame) => frame.selected)
      let unselectedFrames = frames.filter((frame) => !frame.selected)

      if(selectedFrames.length) {
        if(groupUp) {
          selectedGroup = (<FrameGroup frames={selectedFrames}
            container={{width, height}}
            snapToGrid={snapToGrid}
            resizeFrame={resizeFrame}
            moveFrame={moveFrame}
            previewTime={previewTime}/>)
        }
      }
      frames = unselectedFrames.map((frame, index) => buildFrameComponent(frame, frame.index, this.props)).filter((frame) => frame)
    } else {
      frames = frames.map((frame, index) => buildFrameComponent(frame, frame.index, this.props)).filter((frame) => frame)
    }

    return (
      <div className='frameEditorPanel'>
        <Stage width={width}
          height={height}
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseOut}>
          <Layer>
            {frames}
            {selectedGroup}
          </Layer>
          {width && height ? (<Layer>
            <Rect width={width * 0.9} height={height * 0.9}
              x={width * 0.05}
              y={height * 0.05}
              stroke={"white"}
              opacity={0}
              fillEnabled={false}
              ref={node => {this.actionSafeBoundary = node}}
              />
            <Text text={"Action Safe"} opacity={0}
              fill={"white"}
              strokeWidth={2}
              fontWeight={"bold"}
              fillAfterStrokeEnabled={true}
              x={width * 0.05 + 2}
              y={(height * 0.95) - 14}
              fontSize={16}
              ref={node => {this.actionSafeText = node}}/>
            <Rect width={width * 0.8} height={height * 0.8}
              x={width * 0.1}
              y={height * 0.1}
              stroke={"white"}
              opacity={0}
              fillEnabled={false}
              ref={node => {this.titleSafeBoundary = node}}
              />
            <Text text={"Title Safe"} opacity={0}
              fill={"white"}
              fillAfterStrokeEnabled={true}
              x={width * 0.1 + 2}
              y={(height * 0.9) - 14}
              fontSize={16}
              ref={node => {this.titleSafeText = node}}/>
          </Layer>) : null}
        </Stage>
      </div>
    )
  }

}

export default withResizeDetector(FrameEditor)
