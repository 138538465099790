import React, { useState } from "react";
import {getStatus} from '../../../helpers/status_manager.js'
import { Label, Dropdown, Radio } from "semantic-ui-react";

// Components' job is to handle schema.ui.presentation and render radio buttons or a dropdown list
const ButtonOrList = (schema) => {
  // Receiving ALL schema from SettingPanel.jsx
  let visual = schema.ui.presentation;
  let isNull = true;

  let sysdev = getStatus('system_devices') || {}

  // This function is handling schema.value
  // schema.value is not always passsed 100% of the time but schema.default is.
  // In this function we are setting radioState to schema.value if it exists or schema.default
  const handleInitialRadioState = () => {
    if (schema.value || schema.value === 0) {
      return schema.value;
    } else {
      return schema.default;
    }
  };

  const [customInput, setCustomInput] = useState(schema.ui.values);
  const [radioState, setRadioState] = useState(handleInitialRadioState);

  // Handles types that are "number" and fills the dropdown with different options
  let numberOptions = schema.ui.values.map(({ name, value }) => {
    return { key: `option${name}`, text: name, value: value };
  });

  // Handles types that are "objects" and fills the dropdown with different options
  let enumOptions = schema.ui.values.map(({ name, value }) => {
    return { key: `enum${name}`, text: name, value: value };
  });

  // Some settings use the system devices list
  if (schema.ui.substitute === "system.alsa_list") {
    numberOptions = [ ]
    enumOptions = [ ]
    let lookat = (sysdev.alsa_list || {});
    for (let key in lookat) {
      let obj = lookat[key]
      let ioid = obj.ioid || ""
      if (ioid === "Output" || ioid === "") {
        let txt = key
        if (obj.desc !== undefined) txt = obj.desc + " [" + key + "]"
        enumOptions.push({ key: `enum${key}`, text: txt, value: key })
      }
    }
  }
  else if (schema.ui.substitute === "system.pulseaudio_list") {
    numberOptions = [ ]
    enumOptions = [ ]
    let lookat = (sysdev.pulseaudio_list || {});
    for (let key in lookat) {
      let obj = lookat[key]
      let txt

      if (obj.alsa_card_name !== undefined && obj.alsa_name !== undefined)
        txt = obj.alsa_card_name+", "+obj.alsa_name
      else if (obj.alsa_card_name !== undefined)
        txt = obj.alsa_card_name
      else if (obj.alsa_name !== undefined)
        txt = obj.alsa_name
      else
        txt = "?"

      txt += " [" + key + "]"
      enumOptions.push({ key: `enum${key}`, text: txt, value: key })
    }
  }
  else if (schema.ui.substitute === "system.interface_list") {
    numberOptions = [ ]
    enumOptions = [ ]
    let addrorder = [ "ipv4", "ipv6", "packet" ]
    let lookat = (sysdev.interface_list || {});
    let byaddr = { }
    for (let key in lookat) {
      let obj = lookat[key]
      let addrs = obj.addr || {}
      for (let addrproto in addrs) {
        let addrstr = addrs[addrproto]
        if (byaddr[addrproto] === undefined)
          byaddr[addrproto] = [ ]
        byaddr[addrproto].push({ iface: key, proto: addrproto, addr: addrstr })
      }
    }
    for (let key in byaddr) byaddr[key].sort((a,b) => {
      return a.iface.localeCompare(b.iface)
    })
    for (let key of addrorder) {
      if (!(key in byaddr)) continue
      let arr = byaddr[key]
      for (let obj of arr) {
        let txt,val

        if (key === "packet") {
          val = "mac:"+obj["addr"]
          txt = "MAC address "+obj["addr"]+" ["+obj["iface"]+"]"
        }
        else if (key === "ipv4") {
          val = obj["addr"]
          txt = obj["addr"]+" [IPv4 on "+obj["iface"]+"]"
        }
        else if (key === "ipv6") {
          val = obj["addr"]
          txt = obj["addr"]+" [IPv6 on "+obj["iface"]+"]"
        }
        else {
          val = obj["addr"]
        }

        enumOptions.push({ key: `enum${val}`, text: txt, value: val })
      }
    }
  }

  if (schema.disable_auto) isNull = false

  // Creating an extra 'Default/None' dropdown item
  if (isNull) {
    enumOptions.unshift({ text: "Auto", value: schema.default || "" });
    // Allows the option to get out of the dropdown for any dropdowns that are a number type
    numberOptions.unshift({ text: "Auto", value: ""});
  }

  let radioButtonSettingName = (
    <div key="radioButtonSettingName">
      <Label size="large">{schema.display_name}</Label>
    </div>
  );

  // Updating radio state dependent on what radio button has been clicked
  const updateRadioState = (e, value) => {
    setRadioState(value);
    // Extract the data needed to pass the value to the redux store
    // then store it.
    let { modifySettings, serviceType, service, address } = schema;
    modifySettings(serviceType, service, address, value);
  };

  let radioButtonsMap = schema.ui.values.map(({ name, value }) => {
    return (
      <div
        key={name}
        style={{
          display: "inline-block",
          paddingLeft: "6px",
          paddingRight: "15px",
        }}
      >
        <br />
        <Radio
          label={name}
          value={value}
          checked={radioState === value}
          onChange={(e) => updateRadioState(e, value)}
        />
        <br />
      </div>
    );
  });

  // This function is for handling custom number inputs
  const handleAddition = (e, { value }) => {
    if (
      value < schema.ui["min-value"] ||
      value < schema.minimum ||
      value > schema.ui["max-value"] ||
      value > schema.maximum
    ) {
      alert(
        `The custom value entered needs to be greater than ${
          schema.ui["min-value"] || schema.minimum
        } and cannot exceed ${schema.ui["max-value"] || schema.maximum}.`
      );
    } else {
      setCustomInput(value);
    }
  };

  let dropdownValue = schema.value;
  // We are having to filter out null / undefined values otherwise
  // an error gets thrown since dropdown values can't contain null or undefined types
  if (!dropdownValue) {
    dropdownValue = " ";
  }

  let placeholder = "Auto"
  if(schema.disable_auto) placeholder = ""
  if(schema.placeholder) placeholder = schema.placeholder
  if(schema.default) {
    placeholder = schema.default
  }
  // Rendering a dropdown list with type number or type enum
  let listDropdownFill = (
    <>
      {schema.ui.type === "number" ? (
        <div className="SettingsDropdown">
          <Label size="large">{schema.display_name}</Label>
          <Dropdown fluid
            options={numberOptions}
            placeholder={placeholder}
            search
            allowAdditions
            additionLabel="Custom Input "
            onAddItem={handleAddition}
            onChange={schema.onChange}
            value={dropdownValue || customInput}
            selection
          />
        </div>
      ) : (
        // Will render other types such as enum
        <div className="SettingsDropdown">
          <Label size="large">{schema.display_name}</Label>
          <Dropdown fluid
            options={enumOptions}
            placeholder={placeholder}
            onChange={schema.onChange}
            value={dropdownValue}
            selection
          />
        </div>
      )}
    </>
  );

  // Throwing an error if radio buttons or list don't populate
  if (visual !== "radio buttons" && visual !== "list") {
    throw new Error("Radio buttons or list did not load correctly");
  }

  return (
    <>
      {visual === "radio buttons" ? radioButtonSettingName : ""}
      {visual === "radio buttons" ? radioButtonsMap : ""}
      {visual === "list" ? listDropdownFill : ""}
    </>
  );
};

export default ButtonOrList;
