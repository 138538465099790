import React, {PureComponent} from 'react'
import {Menu, Input} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import ScheduleIntervalDaySelector from './ScheduleIntervalDaySelector'

import moment from "moment"

import './ScheduleIntervalOptions.css'

class ScheduleIntervalOptions extends PureComponent {
  render() {
    let {intervalBasis,
      intervalDuration,
      viewDate,
      datelist,
      changeView,
      changeIntervalBasis,
      changeIntervalDuration} = this.props

    return(
      <div id="intervalScheduleOptions">
        <Menu widths={2} size='small' borderless attached="top">
          <Menu.Item fitted="vertically">
            <DatePicker
              wrapperClassName='ui input fluid'
              customInput={<Input fluid label='Start Date'/>}
              selected={intervalBasis.toDate()}
              shouldCloseOnSelect={false}
              onChange={(value) => changeIntervalBasis(moment(value))}/>
          </Menu.Item>
          <Menu.Item fitted="vertically">
            <Input value={intervalDuration}
              type='number'
              label='Duration'
              fluid
              min={1}
              onChange={(e, data) => {changeIntervalDuration(parseInt(data.value, 10))}}/>
          </Menu.Item>
        </Menu>
        <ScheduleIntervalDaySelector intervalBasis={intervalBasis}
          intervalDuration={intervalDuration}
          viewDate={viewDate}
          datelist={datelist}
          selectDay={changeView}
          menuProps={{attached: "bottom", id: "intervalViewSelector"}}/>
      </div>
    )
  }
}

export default ScheduleIntervalOptions
