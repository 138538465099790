import React, {PureComponent} from 'react';
import { actions } from 'redux/messages'
import { connect } from 'react-redux'
import Alert from 'components/Messages/Alert'
import Confirm from 'components/Messages/Confirm'
import Prompt from 'components/Messages/Prompt'

class MessageSystem extends PureComponent {

  componentDidUpdate() {
    if(this.props.currentMessage === null &&
      this.props.messageQueue.length > 0) {
      this.props.nextMessage()
    }
  }

  render() {
    let {currentMessage, dismissMessage} = this.props

    if(!currentMessage) {
      return null
    }

    switch(currentMessage.type) {
      case 'alert':
        return <Alert {...currentMessage} dismiss={dismissMessage}/>
      case 'confirm':
        return <Confirm {...currentMessage} dismiss={dismissMessage}/>
      case 'prompt':
        return <Prompt {...currentMessage} dismiss={dismissMessage}/>
      default: {
        return null
      }
    }
  }

}

const mapStateToProps = state => ({
  ...state.messages
})

const mapDispatchToProps = actions

export default connect(mapStateToProps, mapDispatchToProps)(MessageSystem)
