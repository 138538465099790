import React, {PureComponent} from 'react'
import FrameImage from "./FrameImage"
import {Group, Rect, Text, Transformer} from 'react-konva'

import './Frame.css'

class Frame extends PureComponent {

  constructor(props) {
    super(props);
    this.previewImage = React.createRef();
    this.loadImage = React.createRef();
    this.transformerRef = React.createRef();
    this.groupRef = React.createRef()
    this.state = {isFetching: false, currentSrc: '', scaleX: 1, scaleY: 1}
  }

  componentDidMount() {
    if(this.transformerRef.current) {
      this.transformerRef.current.nodes([this.groupRef.current])
      this.transformerRef.current.getLayer().batchDraw()
    }
  }

  componentDidUpdate(prevProps) {
    if(this.props.display === null &&
      prevProps.display !== null &&
      this.previewImage.current) {
        this.previewImage.current.src = ''
    }
    if(this.transformerRef.current) {
      this.transformerRef.current.nodes([this.groupRef.current])
      this.transformerRef.current.getLayer().batchDraw()
    }
  }

  handleLoad = (e) => {
    if(this.previewImage.current) {
      this.previewImage.current.src = e.currentTarget.src
    }
  }

  dragSnap = (e) => {
    let {snapToGrid, container} = this.props
    if(snapToGrid) {
      let percentX = container.width * 0.05
      let percentY = container.height * 0.05
      let newX = e.target.x() - e.target.x() % percentX
      let newY = e.target.y() - e.target.y() % percentY
      e.target.x(newX)
      e.target.y(newY)
    }
  }

  dragEnd = (e) => {
    let {moveFrame, container, zIndex} = this.props
    let percentX = (e.target.x() / container.width) * 100
    let percentY = (e.target.y() / container.height) * 100
    moveFrame(zIndex, percentX, percentY)
  }

  transformSnap = (e) => {
    let {container, snapToGrid} = this.props
    if(snapToGrid) {
      let gridX = container.width * 0.05
      let gridY = container.height * 0.05
      // left and top
      let newX = Math.round(e.target.x() / gridX) * gridX
      let newY = Math.round(e.target.y() / gridY) * gridY
      // right and bottom
      let right = newX + (e.target.width() * e.target.scaleX())
      let bottom = newY + (e.target.height() * e.target.scaleY())
      right = Math.round(right / gridX) * gridX
      bottom = Math.round(bottom / gridY) * gridY
      let newScaleX = (right - newX) / e.target.width()
      let newScaleY = (bottom - newY) / e.target.height()
      // Do not ever set scaleX or scaleY to 0, this causes all measurements to become NaN for some reason
      if(newScaleX && newScaleY) {
        e.target.setAttrs({
          x: newX,
          y: newY,
          scaleX: newScaleX,
          scaleY: newScaleY
        })
      }
    }
    this.setState((state) => ({...state, scaleX: e.target.scaleX(), scaleY: e.target.scaleY()}))
  }

  transformEnd = (e) => {
    let {moveFrame, resizeFrame, rect, container, zIndex} = this.props
    let absolutePercentWidth = (rect.width / container.width) * e.target.scaleX() * 100
    let absolutePercentHeight = (rect.height / container.height) * e.target.scaleY() * 100
    e.target.scaleX(1)
    e.target.scaleY(1)
    this.setState((state) => ({...state, scaleX: e.target.scaleX(), scaleY: e.target.scaleY()}))
    resizeFrame(zIndex, absolutePercentWidth, absolutePercentHeight)
    // Also update x and y, as they can change with resize
    let percentX = (e.target.x() / container.width) * 100
    let percentY = (e.target.y() / container.height) * 100
    moveFrame(zIndex, percentX, percentY)
  }

  handleSelect = (e) => {
    let {zIndex, selectFrame} = this.props
    if(selectFrame) {
      selectFrame(zIndex, !(e.evt.shiftKey || e.evt.ctrlKey))
    }
  }

  render() {
    let {name,
      rect,
      options,
      display,
      running,
      zIndex,
      isDragging,
      selected} = this.props
    let {scaleX, scaleY} = this.state
    if(this.props.scaleX) {
      scaleX = this.props.scaleX
    }
    if(this.props.scaleY) {
      scaleY = this.props.scaleY
    }

    let displayName = name || `Region ${zIndex + 1}`

    if(display === null) {
      display = ''
    }

    if(isDragging) {
      return null
    }

    if(options.enabled < 0) {
      return null
    }

    // Do not show main region if channel is not running
    if (name === "Item" && (running === false || running === "0"))
      return null

    // NTS: This can happen if regions are not yet loaded from server
    if(rect.top === undefined || Number.isNaN(rect.top) ||
       rect.left === undefined || Number.isNaN(rect.left) ||
       rect.width === undefined || Number.isNaN(rect.width) ||
       rect.height === undefined || Number.isNaN(rect.height)) {
      return null
    }

    let frameColor = "white"

    if(selected) {
      frameColor = "#21ba45"
    }

    let transformer = null
    if(!options.locked && !selected) {
      transformer = <Transformer ref={this.transformerRef}
        rotateEnabled={false}
        keepRatio={false}/>
    }

    return(<>
      <Group x={rect.left}
        y={rect.top}
        width={rect.width}
        height={rect.height}
        scaleX={1}
        scaleY={1}
        onDragMove={this.dragSnap}
        onDragEnd={this.dragEnd}
        onTransform={this.transformSnap}
        onTransformEnd={this.transformEnd}
        onClick={this.handleSelect}
        draggable={!selected && !options.locked}
        ref={this.groupRef}>
        <Rect x={0}
          y={0}
          width={rect.width}
          height={rect.height}
          fillEnabled={true}
          stroke={frameColor}/>
        <Text text={displayName}
          stroke={"black"}
          fill={frameColor}
          fillAfterStrokeEnabled={true}
          fontSize={16}
          fontWeight={"bold"}
          strokeWidth={2}
          x={4}
          y={rect.height - 20}/>
        <FrameImage rect={rect}
          scaleX={scaleX}
          scaleY={scaleY}
          visible={options.video}
          src={display}/>
      </Group>
      {transformer}
    </>)

  /*
    return (<div className={cls} style={contentStyle}>
      <div className='FrameImageContainer' style={{visibility: imageVisibility}}>
      </div>
      <div className='FrameStatusIcons'>
        {lockedIcon}
        {audioOffIcon}
        {videoOffIcon}
      </div>
      <span className='FrameLabel'>{displayName}</span>
    </div>)*/
  }

}

export default Frame
