import React, { PureComponent } from 'react'
import { Icon, TextArea, Button, Modal, Form, Input} from 'semantic-ui-react'

import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { changeTab, createTab, deleteTab, actions } from 'redux/applications/scrolling_text'

import {openApplication} from 'redux/menu'
import {loadFileData, loadFonts} from 'redux/file_list'

import TextGrid from '../../components/TextGrid/TextGrid';
import TextPreview from '../../components/TextPreview/TextPreview';

import tabbedComponent from 'components/higher_order_components/tabbedComponent'

import './ScrollingText.css';

export class ScrollingTextApp extends PureComponent {

    constructor(props) {
      super(props)
      let {itemPath, itemName} = props
      props.loadFonts()
      if(itemName && itemPath) {
        let fullpath = [...itemPath, itemName]
        props.loadScrollingTextFile(fullpath.join('/'))
        props.openApplication('ScrollingText', `/${fullpath.join('/')}`)
      } else {
        props.openApplication('ScrollingText')
      }
    }

    handleRename = () => {
      let {waitingToSave, ...actions} = this.props
      actions.setTextItemName()
      actions.saveModalClose()
      if(waitingToSave) {
        actions.setWaitingToSave(false)
        actions.saveScrollingTextFile()
      }
    }

    handleCloseRenameModal = () => {
      let {waitingToSave, ...actions} = this.props
      if(waitingToSave) {
        actions.setWaitingToSave(false)
      }
      actions.saveModalClose()
    }

    render () {
        const { itemPath,
          itemName,
          scrollingText,
          scrollingTextPreview,
          style,
          errorText,
          saveModalStatus,
          waitingToSave,
          fonts,
          ...actions } = this.props

        let scrolling_text = {
          itemPath,
          scrollingText,
          scrollingTextPreview,
          errorText,
          saveModalStatus,
          ...style
        }

        let renameText = 'Rename'
        let renameIcon = 'edit'
        if(waitingToSave) {
          renameText = 'Save'
          renameIcon = 'save'
        }

        return (
            <div className="scrollingText">
                <div className="scrollingText__MainContainer">

                    <div className="scrollingText__Row">
                        <div className="scrollingText--div">
                            <TextArea 
                                onChange={
                                    (e, data) => {
                                        actions.setScrollingText(data.value)
                                    }
                                }
                                value={scrolling_text.scrollingText}
                                />
                                <div className="scrollingText--previewDiv">
                                    <TextPreview properties={scrolling_text}/>
                                </div>
                        </div>
                        <div className="scrollingText--div">
                        <TextGrid properties={scrolling_text} actions={actions} fonts={fonts}/>
                            <div className="scrollingText__buttonGroup">
                                <Button color="orange" onClick={actions.setScrollingTextPreview}><Icon name="eye" style={{marginRight: '5px'}}/>Preview</Button>
                                <Button color="green" onClick={actions.saveScrollingTextFile}><Icon name="save" style={{marginRight: '5px'}}/>Save</Button>
                                <Button color="green" onClick={actions.saveScrollingTextFileAs}>
                                  <Icon.Group style={{marginRight: '5px'}}>
                                    <Icon name="save"/>
                                    <Icon name="pencil" corner/>
                                  </Icon.Group>
                                  Save As
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal size='mini' open={scrolling_text.saveModalStatus} onClose={actions.saveModalClose}>
                    <Modal.Header>Text Item Name</Modal.Header>
                    <Modal.Content>
                        <Modal.Description style={{marginBottom: '10px'}}>
                            {scrolling_text.errorText === true &&
                                <div className="scrollingText__errorText">Please enter an item name</div>
                            }
                            What should I name the text item?
                        </Modal.Description>
                        <Form onSubmit={this.handleRename}>
                            <Form.Field>
                                <Input 
                                    placeholder={itemName ? itemName : 'NEW_TEXT_ITEM'}
                                    onChange = {(e, data) => actions.itemNameValue(data.value) }
                                    />
                            </Form.Field>
                        </Form>                        
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative icon labelPosition='right' onClick={this.handleCloseRenameModal}>Close<Icon name="close" style={{marginRight: '5px'}}/></Button> 
                        <Button positive icon labelPosition='right' onClick={this.handleRename}>{renameText}<Icon name={renameIcon} style={{marginRight: '5px'}}/></Button>
                    </Modal.Actions>
                </Modal>                
            </div>
        );
    };
}

const initialize = (props) => {
  if(props.match.params.path) {
    let {_tabs, _tabData} = props
    let path = decodeURIComponent(props.match.params.path)
    let tabId = Object.entries(_tabData).find(([key, value]) => {
      return value.props.itemPath.join('/') === path
    })
    if(tabId instanceof Array) {
      tabId = tabId[0]
    }
    let tabInd = _tabs.findIndex((tab) => tab === tabId)
    if(tabInd > -1) {
      props._changeTab(tabInd);
    } else {
      props._createTab(path.split('/'));
    }
    return
  }
  if(props._tabs.length === 0) {
    props._createTab();
  }
}

let mapStateToProps = state => ({
    _tabs: state.scrolling_text.tabs,
    _tabData: state.scrolling_text.tabData,
    _activeTab: state.scrolling_text.activeTab,
    _initialize: initialize,
    _tabActions: actions,
    fonts: state.file_list.fonts,
    state,
})

let mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({
      _changeTab: changeTab,
      _createTab: createTab,
      _deleteTab: deleteTab,
      openApplication,
      loadFileData,
      loadFonts
    }, dispatch),
    _dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(tabbedComponent(ScrollingTextApp))
