import React, { PureComponent } from 'react'
import { Header,
  Divider,
  Icon,
  Button,
  Dropdown,
  Table,
  Image} from 'semantic-ui-react'
import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'
import moment from 'moment'

import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { actions } from 'redux/components/input_recording'
import { openApplication } from 'redux/menu'
import { messages } from 'redux/messages'

import { getIn, millisToHourString } from 'helpers/general_helpers'
import { parseFileSize } from 'helpers/library_helpers'

import SettingIdSelector from 'selectors/SettingIdSelector'

import './InputRecording.css';
// import 'semantic-ui-css/semantic.min.css';

import InputLiveControls from './InputLiveControls'
import InputRecordingModal from './InputRecordingModal'
import RecordingEventRow from './RecordingEventRow'

export class InputRecordingApp extends PureComponent {

    componentDidMount() {
      this.props.setCurrentInput(this.props.id)
      this.props.connectToRecordingSocket()
      this.props.fetchAssociations(this.props.id)
      this.props.fetchEvents()
      this.pollUpdate = setTimeout(this.poll, 10) /* Get the input going, now! */
    }

    /* copied from V4 */
    filterCaptionStatus608 = (x) => {
      if (x === undefined) return '';

      var CC1 = undefined;
      var TEXT = undefined;
      var a = x.split('\n');
      var l,i;

      for (i=0;i < a.length;i++) {
        l = a[i];
        if (l.substr(0,8) === "Odd CC1:") {
          if (CC1 === undefined) CC1 = l.substr(4).trim(); /* CC1:...*/
          if (CC1 !== undefined) CC1 = CC1.replace(/ 8080/g,"").trim();
          if (CC1 === "CC1:") CC1 = '';
        }
        else if (l.substr(0,7) === "^ TEXT:") {
          if (TEXT === undefined) TEXT = l.substr(7).trim(); /* Strip ^ TEXT: entirely */
          /* strip quotation marks */
          if (TEXT !== undefined) {
            if (TEXT.substr(0,1) === '"' && TEXT.substr(TEXT.length-1,1) === '"') {
              TEXT = TEXT.substr(1,TEXT.length-2);
              TEXT = TEXT.trim();
              if (TEXT.length > 30) TEXT = TEXT.substr(TEXT.length-30);
            }
          }
        }
      }

      if (TEXT !== undefined && TEXT !== "")
        return '"' + TEXT + '"';
      if (CC1 !== undefined && CC1 !== "")
        return CC1;

      return '';
    }

    /* copied from V4 */
    filterCaptionStatus708 = (x) => {
      if (x === undefined) return '';

      var RAW = undefined;
      var Service = undefined;
      var a = x.split('\n');
      var l,i;

      for (i=0;i < a.length;i++) {
        l = a[i];
        if (l.substr(0,4) === "RAW:") {
          if (RAW === undefined) RAW = l.trim();
          if (RAW === "RAW:") RAW = '';
        }
        else if (l.substr(0,10) === "Service 1:") {
          if (Service === undefined) Service = l.substr(10).trim();
          if (Service !== undefined) {
            Service = Service.trim();
            if (Service.length > 31) Service = Service.substr(Service.length-31);
          }
        }
      }

      if (Service !== undefined && Service !== "")
        return Service;
      if (RAW !== undefined && RAW !== "")
        return RAW;

      return '';
    }

    poll = () => {
      this.props.pollRecordingStatus()
      this.pollUpdate = setTimeout(this.poll, 5000)
    }

    componentWillUnmount() {
      clearTimeout(this.pollUpdate)
      this.props.setCurrentInput('')
    }

    componentDidUpdate(oldProps) {
      if(oldProps.id !== this.props.id) {
        this.props.setCurrentInput(this.props.id)
        this.props.connectToRecordingSocket()
        this.props.fetchAssociations(this.props.id)
        this.props.fetchEvents()
      }
    }

    handleRenameRecording = () => {
      let { input_recording, ...actions } = this.props
      actions.prompt("What do you want to call this recording?", (val) => {
        if(val === null) {
          return
        }
        actions.renameRecording(val)
      })
    }

    handleGoLive = (chan) => {
      this.props.confirm(`Click OK when you are ready to go live on Channel ${chan}.`, (val) => {
        if(val) {
          this.props.goLive(chan)
        }
      }, {confirmText: 'OK', cancelText: 'Cancel'})
    }

    handleEndLive = (chan) => {
      this.props.confirm(`Click OK when you are ready to end live on Channel ${chan}.`, (val) => {
        if(val) {
          this.props.endLive(chan)
        }
      }, {confirmText: 'OK', cancelText: 'Cancel'})
    }

    formatTimeValue = (data) => {
      if(typeof data === 'string') {
        let formats = ['h:mm:ss a', 'h:mm:ssa', 'h:mm a', 'h:mma']
        let momVal = moment(data, formats, true)
        if(momVal.isValid()) {
          data = momVal
        }
      }
      return data
    }

    formatDateValue = (data) => {
      if(typeof data === 'string') {
        let formats = ['M/D/YYYY']
        let momVal = moment(data, formats, true)
        if(momVal.isValid()) {
          data = momVal
        }
      }
      return data
    }

    handleAssociationSelect = (e, data) => {
      this.props.selectAssociation(data.value)
    }

    handleApplyAssociation = () => {
      if(this.props.input_recording.selectedAssociation) {
        this.props.applyAssociation()
      }
    }

    handleDeleteEvent = (recordingEvent) => {
      this.props.confirm(`Are you sure you want to delete the event ${recordingEvent["recording name"]} from input ${recordingEvent.input}?`, (val) => {
        if(val) {
          this.props.deleteEvent(recordingEvent.id)
        }
      }, {confirmText: "Delete", cancelText: "Cancel"})
    }

    render() {
        let tmp;
        let options = [];

        const { input_recording, channels, inputs, timezone, ...actions } = this.props

        let { currentInput, recordingStatus, recordingPreview, associations, events, selectedAssociation } = input_recording

        let thisInput = inputs[currentInput] || {}
        let thisInputSettings = thisInput.settings || {}

        events = events.filter((evnt) => evnt.input === currentInput)

        let listItems;

        if (Object.keys(events).length === 1 && events[0].id === 999999999 && events[0].errorMessage === true) {
          listItems = <Table.Row><Table.Cell style={{paddingLeft: "1em", color: "#AF0000"}} colSpan="4">{events[0]['recording name']}</Table.Cell></Table.Row>
        }
        else {
          listItems = events.map((event, index) =>
            <RecordingEventRow recordingEvent={event}
              onDelete={this.handleDeleteEvent}
              onChange={actions.editEvent}
              timezone={timezone}
              key={event.id}/>
          );
        }

        let caption708 = ''
        let caption608 = ''

        tmp = thisInputSettings['cea-708_caption_status']
        if (tmp && tmp !== "") {
          caption708 = this.filterCaptionStatus708(tmp)
          if (caption708.length > 0 && caption708.charAt(0) !== '"') caption708 = "\"" + caption708;
        }

        tmp = thisInputSettings['eia_608_caption_status'] /* Oh God, why? "eia-608..." vs "cea-708..." and yet inconsistent */
        if (tmp && tmp !== "") {
          caption608 = this.filterCaptionStatus608(tmp)
          if (caption608.length > 0 && caption608.charAt(0) !== '"') caption608 = "\"" + caption608;
        }

        let recordingLabel = '(Unknown)'
        let recordingName = '(Untitled)'
        let recordButton = ''
        let recordTime = ''
        let recordSize = ''
        let previewContainerCls = 'blackArea'
        let liveCaptionButton = ''
        let liveCaptionStatusLabel = ''
        if(recordingStatus) {
          if(getIn(recordingStatus, [currentInput, 'recording']) === '1') {
            recordingLabel = getIn(recordingStatus, [currentInput, 'active recording file']) || "(Recording)"
            recordTime = getIn(recordingStatus, [currentInput, 'active recording time']) || "0"
            recordTime = millisToHourString(parseFloat(recordTime) * 1000)
            recordSize = getIn(recordingStatus, [currentInput, 'active recording file size']) || "0"
            recordSize = parseFileSize(recordSize)
            recordButton = <Button icon='stop' onClick={actions.stopRecording}/>
            if(recordingLabel === "(waiting)") {
             previewContainerCls = 'blackArea waiting'
            } else {
             previewContainerCls = 'blackArea recording'
            }
          } else {
            recordingLabel = "(Not Recording)"
            recordButton = <Button icon={<Icon name='circle' color='red'/>} onClick={actions.startRecording}/>
          }
          recordingName = getIn(recordingStatus, [currentInput, 'recording name']) || '(Untitled)'
        }

        let liveCaption = getIn(recordingStatus, [currentInput, 'liveCaption']) || { }
        let liveCapBtnComStyle = { paddingLeft: "0px", paddingRight: "0px", textAlign: "center", width: "38px", height: "36px", fontWeight: 900, fontSize: "10px" }

        if (!liveCaption.liveCaptionConfigured) {
                liveCaptionButton = <Button disabled={true} content="(CC)" style={liveCapBtnComStyle} />
                liveCaptionStatusLabel = ''
        }
        else if (!liveCaption.liveCaptionEnabled) {
                liveCaptionButton = <Button disabled={true} content="(CC)" style={liveCapBtnComStyle} />
                liveCaptionStatusLabel = 'Not enabled in settings'
        }
        else {
                let onclick;

                if (liveCaption.liveCaptionButtonStart)
                        onclick = actions.startLiveCaptions;
                else
                        onclick = actions.stopLiveCaptions;

                if (liveCaption.liveCaptionIsRunning) {
                        liveCapBtnComStyle = {
                                textShadow: '0px 0px 2px #5F0000',
                                color: '#FF0000',
                                ...liveCapBtnComStyle
                        }
                }

                let more = ''

                if (liveCaption.liveCaptionIsRunning) {
                        if (liveCaption.liveCaptionRuntime > 0) {
                                var t = Math.floor(liveCaption.liveCaptionRuntime + 0.5);
                                var s = t % 60; t = Math.floor(t / 60);
                                var m = t % 60; t = Math.floor(t / 60);
                                var h = t;

                                s = s.toString();
                                if (s.length < 2) s = '0' + s;

                                m = m.toString();
                                if (m.length < 2) m = '0' + m;

                                more += ' [' + h + ':' + m + ':' + s + ']';
                        }
                }

                liveCaptionButton = <Button content="(CC)" style={liveCapBtnComStyle} onClick={onclick} />
                liveCaptionStatusLabel = (liveCaption.liveCaptionRunningUF || liveCaption.liveCaptionRunning) + more
        }

        if(associations && currentInput in associations) {
          options = associations[currentInput].map((assoc) => ({
            text: assoc.name,
            value: assoc.association
          }))
        }

        let recordTimeAndSize = ''

        if (recordTime !== undefined && recordTime !== "") {
                if (recordTimeAndSize !== "") recordTimeAndSize += " / "
                recordTimeAndSize += recordTime
        }

        if (recordSize !== undefined && recordSize !== "") {
                if (recordTimeAndSize !== "") recordTimeAndSize += " / "
                recordTimeAndSize += recordSize
        }

        return (
            <div className="inputRecording">
                <div className="inputRecording__MainContainer">
                    <Header as='h2' icon='record' content={currentInput} id='RecordingControlsHeader'/>
                    <Divider fitted/>
                    <div className="inputRecording__Row noselect">
                        <div className="inputRecording--div">
                            <div className={previewContainerCls}>
                              <Image src={getIn(recordingPreview, [currentInput, 'current'])} id='recordingPreview' alt=''/>
                            </div>
                        </div>
                        <div className="inputRecording--div noselect">
                            <div className="inputRecording--buttonMenu">
                              <Button.Group>
                                <Button icon='arrow left' content='Back' onClick={() => actions.openApplication('Settings', '/input')}/>
                                <Button icon='settings' content='Settings' onClick={() => actions.openApplication('Settings', `/input/${currentInput}`)}/>
                              </Button.Group>
                            </div>
                            <div>
                                <Button.Group style={{marginRight: 10}}>
                                  {recordButton}
                                </Button.Group>
                                {recordingLabel}
                            </div>
                            <div>
                                <Button.Group style={{marginRight: 10}}>
                                  {liveCaptionButton}
                                </Button.Group>
                                {liveCaptionStatusLabel}
                            </div>
                            <div>
                              {recordTimeAndSize}
                            </div>
                            <div className="inputRecording--buttonMargin5">
                                <Button icon labelPosition='left' style={{marginRight: 10}} onClick={this.handleRenameRecording}>
                                    <Icon name='edit' />
                                    Rename
                                </Button>
                                {recordingName}
                            </div>
                            <div className="inputRecording--buttonMargin5">
                                <InputLiveControls channels={channels}
                                  currentInput={currentInput}
                                  goLive={this.handleGoLive}
                                  endLive={this.handleEndLive}/>
                            </div>
                            <div style={{fontName: "Liberation Sans, sans-serif", fontSize: 10, lineHeight: '1.3em' }}>
                                <div style={{fontWeight: 900}}>Caption status:</div>
                                {(caption708 !== "") ? <div>708: {caption708}</div> : <div/>}
                                {(caption608 !== "") ? <div>608: {caption608}</div> : <div/>}
                            </div>
                            {options.length ? <div className="inputRecording--buttonMargin">
                                <Dropdown placeholder='Select' selection options={options} style={{width: '50%'}} value={selectedAssociation} onChange={this.handleAssociationSelect}/>
                                <Button content='Apply Route' style={{marginLeft: 10}} onClick={this.handleApplyAssociation}/>
                            </div> : ''}
                        </div>
                    </div>
                    <div className="inputRecording__listArea noselect">
                        Timer record events (click on a value to edit it):
                        <Button icon labelPosition='right' onClick={actions.eventModalOpen} style={{marginLeft: 10}}>
                            <Icon name='edit' />
                            Add event
                        </Button>
                        <div className="inputRecording__table">
                            <Table celled padded>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell singleLine>Name</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Repeat</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Start Date</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>Finish Date</Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {listItems}
                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </div>
                <InputRecordingModal input_recording={input_recording} channels={channels} actions={actions}/>
            </div>
        );
    }
}

let mapStateToProps = (state, ownProps) => ({
    input_recording: state.input_recording,
    channels: state.settings.services.channel,
    inputs: state.settings.services.input,
    id: SettingIdSelector(ownProps),
    timezone: state.menu.timezone,
    state,
    _loader: state.input_recording._loader,
    _loaderID: state.input_recording._loaderID
})

let mapDispatchToProps = (dispatch) => bindActionCreators({...actions, ...messages, openApplication}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(loadingIndicator(InputRecordingApp))
