/*
* Component for the Splash Page
*/

import React from 'react'

import {Segment, Grid, Label} from 'semantic-ui-react'
import {Link, } from 'react-router-dom'
import Tile from 'components/Home/Tile'

import {staticAsset} from 'helpers/net_helpers'

import {checkHasAccessToApp} from 'helpers/authentication_helpers'

import {BETA} from "config.js"

import './Home.css'

export default props => {
  let {openApplication, appAccess, buildType} = props

  // Auto-redirect if there is only one app they can go to
  if(appAccess.length === 1 && appAccess[0] !== "*") {
    openApplication(appAccess[0])
    return ""
  }

  let access = {
    upload: checkHasAccessToApp(appAccess, 'Upload'),
    schedule: checkHasAccessToApp(appAccess, 'Schedule'),
    library: checkHasAccessToApp(appAccess, 'Library'),
    settings: checkHasAccessToApp(appAccess, 'Settings'),
    about: checkHasAccessToApp(appAccess, 'About'),
    playlist: checkHasAccessToApp(appAccess, 'Playlist'),
    scrolling: checkHasAccessToApp(appAccess, 'ScrollingText'),
    rss: checkHasAccessToApp(appAccess, 'RSSItemEditor'),
    template: checkHasAccessToApp(appAccess, 'MetadataTemplate'),
    report: checkHasAccessToApp(appAccess, 'Reporting'),
    global: checkHasAccessToApp(appAccess, 'GlobalMedia')
  }

  let accessCreate = (access.schedule ||
    access.playlist ||
    access.scrolling ||
    access.rss ||
    access.template ||
    access.report)

  let createButton = (<Tile label='CREATE' image={staticAsset("/icons/create.svg")}/>)

  // Beta label
  let betaLabel = ""
  if(BETA || buildType === "beta") {
    betaLabel = <Label color="red" attached="bottom" size="big" id="HomeBetaLabel">BETA</Label>
  } else if (buildType === "alpha") {
    betaLabel = <Label color="red" attached="bottom" size="big" id="HomeBetaLabel">Alpha</Label>
  }

  return (
    <Segment id='Home'>
      <Grid columns={3} id='SplashButtonGrid' verticalAlign='middle' container stackable>
        <Grid.Row>
          <Grid.Column textAlign='center'>
            <Tile label='UPLOAD'
              image={staticAsset("/icons/upload.svg")}
              application='Upload'
              openApplication={openApplication}
              disabled={!access.upload}/>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Tile label='SCHEDULE'
              image={staticAsset("/icons/schedule.svg")}
              application='Schedule'
              openApplication={openApplication}
              disabled={!access.schedule}/>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            {accessCreate ? <Link to='/create'>
              {createButton}
            </Link> : createButton}
          </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column textAlign='center'>
            <a href='http://forum.castus.tv' target='#'>
              <Tile label='CASTUS FORUM' image={staticAsset("/icons/castus_forum.svg")}/>
            </a>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Tile label='LIBRARY'
              image={staticAsset("/icons/library.svg")}
              application='Library'
              openApplication={openApplication}
              disabled={!access.library}/>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Tile label='SERVICES'
              image={staticAsset("/icons/settings.svg")}
              application='Settings'
              openApplication={openApplication}
              disabled={!access.settings}/>
          </Grid.Column>
          </Grid.Row>
          <Grid.Row>
          <Grid.Column textAlign='center'>
            <Tile label='CASTUS CUTS'
              image={staticAsset("/icons/castus_cuts.svg")}
              application='Playlist'
              openApplication={openApplication}
              disabled={!access.playlist}/>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Tile label='GLOBAL MEDIA'
              image={staticAsset("/icons/global_media.svg")}
              application='GlobalMedia'
              openApplication={openApplication}
              disabled={!access.global}/>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Tile label='ALERTS'
              image={staticAsset("/icons/alerts_bell_app.svg")}
              application='Alerts'
              openApplication={openApplication}
              disabled={!access.global}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {betaLabel}
    </Segment>
  )
}
