import React, {PureComponent} from 'react'
import ViewIndicator from './ViewIndicator'
import TimeIndicatorGroup from './TimeIndicatorGroup'
import {cursorTime, millisecondsToPixels, magnetize} from 'helpers/playlist_helpers'

import './TimeBar.css'

class TimeBar extends PureComponent {

  constructor(props) {
    super(props)
    this.timeBarDiv = React.createRef()
    this.updateState = this.updateState.bind(this)
    this.updateZoom = this.updateZoom.bind(this)
    this.startPreviewFollow = this.startPreviewFollow.bind(this)
    this.endPreviewFollow = this.endPreviewFollow.bind(this)
  }

  componentDidUpdate() {
    this.updateState()
  }

  componentDidMount() {
    this.updateState()
    window.addEventListener('resize', this.updateState)
    window.addEventListener('mouseup', this.endPreviewFollow)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateState)
    window.removeEventListener('mouseup', this.endPreviewFollow)
  }

  updateState() {
    let {setLineState, width, left} = this.props
    let boundingBox = this.timeBarDiv.current.getBoundingClientRect()
    let newState = {width: boundingBox.width, left: boundingBox.left}
    if(newState.width !== width ||
      newState.left !== left) {
      setLineState(newState)
    }
  }

  updateZoom(e) {
    let {deltaY} = e
    if(deltaY) {
      let {zoomLevel, setZoomLevel} = this.props
      let zoomMult = 1 * (1.1 ** Math.sign(deltaY))
      setZoomLevel(zoomLevel / zoomMult)
    }
  }

  startPreviewFollow(e) {
    let {previewFollow, setPreviewIndicatorFollow} = this.props
    if(!previewFollow) {
      setPreviewIndicatorFollow(true)
    }
  }

  endPreviewFollow(e) {
    let {previewFollow, setPreviewIndicatorFollow} = this.props
    if(previewFollow) {
      setPreviewIndicatorFollow(false)
    }
  }

  render() {
    let {previewTime,
      timelineViewStart,
      zoomLevel,
      mouseX,
      width,
      left,
      showTimelineCursor,
      magnetic,
      magnetTimes} = this.props

    let indicatorAdjustment = millisecondsToPixels(timelineViewStart, zoomLevel)
    let adjustmentStyle = {left: `-${indicatorAdjustment}px`, width: '100%'}

    let cursorIndicatorTime = cursorTime(mouseX, left, zoomLevel, timelineViewStart)
    if(magnetic) {
      cursorIndicatorTime = magnetize(cursorIndicatorTime, zoomLevel, magnetTimes.concat([0, previewTime]))
    }

    return (
      <div id='PlaylistTimeBar'
        className='noselect'
        ref={this.timeBarDiv}
        onWheel={this.updateZoom}
        onMouseDown={this.startPreviewFollow}>
        <ViewIndicator previewTime={previewTime}
          startTime={timelineViewStart}
          zoomLevel={zoomLevel}/>
        <ViewIndicator previewTime={cursorIndicatorTime}
          startTime={timelineViewStart}
          zoomLevel={zoomLevel}
          preview={true}
          hide={!showTimelineCursor}/>
        <div id='PlaylistTimeIndicators'>
          <div id='PlaylistTimeIndicatorsPositioner' style={adjustmentStyle}>
            <TimeIndicatorGroup startTime={timelineViewStart}
              zoomLevel={zoomLevel}
              barWidth={width}/>
          </div>
        </div>
      </div>
    )

  }

}

export default TimeBar
