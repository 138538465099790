import { createSelector } from 'reselect'
import { getIn } from 'helpers/general_helpers'

const getInputs = (state) => state.services.input

const isRunning = (rs) => {
  // FIXME: Yechh... clean this up so we don't have to check around like this
  if (rs === "0" || rs === "" || rs === false) return false;
  if (rs === true) return true;
  return parseInt(rs) > 0;
}

export default createSelector([getInputs], (inputs) => {

  return Object.entries(inputs).map(([key, input]) => {
    return {
      name: key,
      key,
      running: isRunning(getIn(input, ['settings', 'running']))
    }
  }).sort((a, b) => a.key.toUpperCase().localeCompare(b.key.toUpperCase()))

})
