import React, {PureComponent} from 'react'
import {Input} from 'semantic-ui-react'
import { millisecondsToPixels, pixelsToMilliseconds } from 'helpers/playlist_helpers'

import './HorizontalScrollbar.css'

class HorizontalScrollbar extends PureComponent {

  constructor(props) {
    super(props)
    this.scrollBarDiv = React.createRef()
    this.updateScroll = this.updateScroll.bind(this)
  }

  componentDidUpdate() {
    let {timelineViewStart, zoomLevel} = this.props
    if(this.scrollBarDiv.current) {
      this.scrollBarDiv.current.scrollLeft = millisecondsToPixels(timelineViewStart, zoomLevel)
    }
  }

  updateScroll(e) {
    let {zoomLevel, setTimelineViewStart} = this.props
    let newTime = pixelsToMilliseconds(e.target.scrollLeft, zoomLevel)
    setTimelineViewStart(newTime)
  }

  updateZoomSlider = (e, data) => {
    let {lineWidth, endOfPlaylist, setZoomLevel} = this.props
    let zoomPercent = data.value
    let newZoom = (lineWidth * 1000) / (zoomPercent * endOfPlaylist * 1.2)
    setZoomLevel(newZoom)
  }

  render() {
    let {endOfPlaylist,
        playlistScrollEnd,
        lineWidth,
        zoomLevel} = this.props

    let scrollEnd = Math.max(endOfPlaylist, playlistScrollEnd)

    let viewTotal = millisecondsToPixels(scrollEnd, zoomLevel)

    let scrollSize = `${viewTotal}px`

    let interiorStyle = {width: scrollSize}

    let currentZoomPercent = (lineWidth * 1000) / (endOfPlaylist * zoomLevel)
    currentZoomPercent = Math.min(Math.max(currentZoomPercent, 0.05), 1)

    return (
      <div id='PlaylistHorizontalScrollbarContainer'>
        <div id='PlaylistHorizontalScrollbarPad'>
          <div id='PlaylietZoomSlider'>
            <Input type='range'
              min={0.05}
              max={1}
              step={0.05}
              size='mini'
              fluid
              icon='search'
              iconPosition='left'
              disabled={endOfPlaylist <= 0}
              onChange={this.updateZoomSlider}
              value={currentZoomPercent}/>
          </div>
        </div>
        <div id='PlaylistHorizontalScrollbar' ref={this.scrollBarDiv} onScroll={this.updateScroll}>
          <div id='PlaylistHorizontalScrollbarInterior' style={interiorStyle}/>
        </div>
      </div>
    )

  }

}

export default HorizontalScrollbar
