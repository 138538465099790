import {v4 as uuidv4} from 'uuid'
import path from "path"

export const initialLibraryState = {
  navpath: ['mnt', 'main'],
  selectedFiles: []
}

export const LIBRARY_UPDATE = Symbol("LIBRARY MANAGER UPDATE")

export default class LibraryManager {

  constructor() {
    this.id = uuidv4()
  }

  libraryUpdate = (selectedFiles=null, navpath=null) => ({
    type: LIBRARY_UPDATE,
    id: this.id,
    payload: {
      selectedFiles,
      navpath
    }
  })

  wrap = (reducer, initialState) => {
    let initState = {
      _library: initialLibraryState,
      ...initialState
    }

    let myid = this.id
    let initLibState = initialLibraryState

    return function(state=initState, action) {
      let {type, id, payload} = action

      if(!("_library" in state)) {
        state = {
          ...state,
          _library: initLibState
        }
      }

      switch(type) {
        case LIBRARY_UPDATE: {
          if(id === myid) {
            let libraryPath = payload.navpath || state._library.navpath
            let librarySelectedFiles = payload.selectedFiles || state._library.navpath
            if(libraryPath instanceof Array) {
              libraryPath = libraryPath.join("/")
            }
            libraryPath = path.normalize(libraryPath)
            libraryPath = libraryPath.split("/")
            return {
              ...state,
              _library: {
                navpath: libraryPath,
                selectedFiles: librarySelectedFiles
              }
            }
          } else {
            return state
          }
        }
        default: {
          return reducer(state, action);
        }
      }
    }
  }

}
