import React, {PureComponent} from 'react'
import {Group, Transformer} from 'react-konva'

import Frame from "./Frame"

function buildFrameComponent(frame, index, props, state) {
  if(frame && (!frame.options || frame.options.enabled !== false)) {
    let {container, previewTime, snapToGrid, resizeFrame, moveFrame} = props
    let {width, height} = container
    let {scaleX, scaleY} = state
    let rect = {
      left: ((frame.position.x * width) / 100),
      top: ((frame.position.y * height) / 100),
      width: ((frame.size.width * width) / 100),
      height: ((frame.size.height * height) / 100)
    }
    return (<Frame key={index}
      snapToGrid={snapToGrid}
      rect={rect}
      container={container}
      {...frame}
      previewTime={previewTime}
      zIndex={index}
      scaleX={scaleX}
      scaleY={scaleY}
      resizeFrame={resizeFrame}
      moveFrame={moveFrame}/>)
  } else {
    return null
  }
}

class FrameGroup extends PureComponent {

  constructor(props) {
    super(props)
    this.transformerRef = React.createRef();
    this.groupRef = React.createRef();
    this.state = {scaleX: 1, scaleY: 1}
  }

  componentDidMount() {
    if(this.transformerRef.current) {
      this.transformerRef.current.nodes([this.groupRef.current])
      this.transformerRef.current.getLayer().batchDraw()
    }
  }

  componentDidUpdate(prevProps) {
    if(this.transformerRef.current) {
      this.transformerRef.current.nodes([this.groupRef.current])
      this.transformerRef.current.getLayer().batchDraw()
    }
  }

  dragSnap = (e) => {
    let {snapToGrid, container} = this.props
    if(snapToGrid) {
      let rect = this.getRect()
      let percentX = container.width * 0.05
      let percentY = container.height * 0.05
      let x = rect.left + e.target.x()
      let y = rect.top + e.target.y()
      let newX = e.target.x() - (x % percentX)
      let newY = e.target.y() - (y % percentY)
      e.target.x(newX)
      e.target.y(newY)
    }
  }

  dragEnd = (e) => {
    let {moveFrame, container, frames} = this.props
    let offsetX = (e.target.x() / container.width) * 100
    let offsetY = (e.target.y() / container.height) * 100
    // Move child frames
    for(let frame of frames) {
      moveFrame(frame.index, frame.position.x + offsetX, frame.position.y + offsetY)
    }
    // Put groupRef back
    e.target.x(0)
    e.target.y(0)
  }

  transformSnap = (e) => {
    let {container, snapToGrid} = this.props
    if(snapToGrid) {
      let transformRect = this.getRect()
      let gridX = container.width * 0.05
      let gridY = container.height * 0.05
      // X
      // Get rectangle for the frame group with the scaling applied
      let derivedLeft = e.target.x() + (transformRect.left * e.target.scaleX())
      let derivedWidth = (transformRect.width * e.target.scaleX())
      let derivedRight = derivedLeft + derivedWidth
      let derivedTop = e.target.y() + (transformRect.top * e.target.scaleY())
      let derivedHeight = (transformRect.height * e.target.scaleY())
      let derivedBottom = derivedTop + derivedHeight
      // Figure out what rectangle we want for the frame group after it has been snapped to the grid
      let snappedLeft = Math.round(derivedLeft / gridX) * gridX
      let snappedRight = Math.round(derivedRight / gridX) * gridX
      let snappedWidth = snappedRight - snappedLeft
      let snappedTop = Math.round(derivedTop / gridY) * gridY
      let snappedBottom = Math.round(derivedBottom / gridY) * gridY
      let snappedHeight = snappedBottom - snappedTop
      // Get new container scaling values that will give us the width/height that we want...
      let newScaleX = snappedWidth / transformRect.width
      let newScaleY = snappedHeight / transformRect.height
      // ...but changing the container scaling will also change the frame group's x and y.
      // So adjust to compensate for that.
      let wouldBeLeft = e.target.x() + (transformRect.left * newScaleX)
      let diffX = (snappedLeft - wouldBeLeft)
      let newX = e.target.x() + diffX
      let wouldBeTop = e.target.y() + (transformRect.top * newScaleY)
      let diffY = (snappedTop - wouldBeTop)
      let newY = e.target.y() + diffY
      // Do not ever set scaleX or scaleY to 0, this causes all measurements to become NaN for some reason
      if(newScaleX && newScaleY) {
        e.target.setAttrs({
          x: newX,
          y: newY,
          scaleX: newScaleX,
          scaleY: newScaleY
        })
      }
    }
    this.setState((state) => ({...state, scaleX: e.target.scaleX(), scaleY: e.target.scaleY()}))
  }

  transformEnd = (e) => {
    let {resizeFrame, moveFrame, container, frames} = this.props
    for(let frame of frames) {
      let newX = (e.target.x() + ((frame.position.x * container.width * e.target.scaleX()) / 100)) / container.width * 100
      let newY = (e.target.y() + ((frame.position.y * container.height * e.target.scaleY()) / 100)) / container.height * 100
      let newWidth = (frame.size.width * e.target.scaleX())
      let newHeight = (frame.size.height * e.target.scaleY())
      moveFrame(frame.index, newX, newY)
      resizeFrame(frame.index, newWidth, newHeight)
    }
    e.target.setAttrs({
      x: 0,
      y: 0,
      scaleX: 1,
      scaleY: 1
    })
    this.setState((state) => ({...state, scaleX: 1, scaleY: 1}))
  }

  getRect = () => {
    let {frames, container} = this.props
    let {width, height} = container
    let rect = {}
    // Go through frames. Try to make rect as small as possible while encompassing all frames
    for(let frame of frames) {
      let frameRect = {
        left: ((frame.position.x * width) / 100),
        top: ((frame.position.y * height) / 100),
        right: ((frame.position.x * width) / 100) + ((frame.size.width * width) / 100),
        bottom: ((frame.position.y * height) / 100) + ((frame.size.height * height) / 100)
      }
      for(let [key, val] of Object.entries(frameRect)) {
        if(!rect[key] ||
          ((key === "left" || key === "top") && rect[key] > val) ||
          ((key === "right" || key === "bottom") && rect[key] < val)) {
          rect[key] = val
        }
      }
    }
    rect.width = rect.right - rect.left
    rect.height = rect.bottom - rect.top
    return rect
  }

  render() {
    let {frames, container} = this.props
    let {width, height} = container
    let selectedGroupParams = {
      draggable: true,
      x: 0,
      y: 0,
      width,
      height
    }
    frames = frames.map((frame, index) => buildFrameComponent(frame, frame.index, this.props, this.state)).filter((frame) => frame)
    return (
      <>
        <Group {...selectedGroupParams}
          ref={this.groupRef}
          onDragMove={this.dragSnap}
          onDragEnd={this.dragEnd}
          onTransform={this.transformSnap}
          onTransformEnd={this.transformEnd}>
          {frames}
        </Group>
        <Transformer rotateEnabled={false}
          keepRatio={false}
          ref={this.transformerRef}/>
      </>
    )
  }
}

export default FrameGroup
