import {SOURCE, SERVER_URL, URL_BASE} from 'config'
import io from 'socket.io-client'
import history from "history.js"

/**
 * Creates a fetch request to the server, including the server's domain name and the necessary auth header
 * @param {string} path The relative path to send the request to on the server
 * @param {string} token The authentication token to use with the server
 * @param {object} opts Options to pass as the second argument of fetch
 * @returns A promise that resolves with a Response object, as fetch
 */
export async function fetchFromServer(path, opts={}) {
  let url = ''
  let token = window.AARDVARK_ACCESS
  if(SOURCE === 'server') {
    url = serverPath(path);
  } else if (SOURCE === 'local') {
    url = path
  } else {
    throw new Error("Invalid Source Setting! Expected SOURCE to be either local or server, but it was " + SOURCE + " instead. Check src/config.js or SOURCE environment variable.")
  }
  let headers = {Authorization: `Bearer ${token}`}
  if(opts.headers) {
    headers = {...headers, ...opts.headers}
  }
  let res = await fetch(url, Object.assign(opts, {headers}))
  if(res.status === 401) {
    history.push("/admin?error=401");
  }
  return res;
}

/**
 * Helper for fetching a file from the server
 * @param {string} fpath The string file path of the file to fetch
 * @param {object} opts Options to pass as the second argument of fetch
 * @returns A promise that resolves with a Response object, as fetch
 */
export function fetchFileFromServer(fpath, opts={}) {
  fpath = fpath.split('/').map(encodeURIComponent).join('/')
  let path = `/v2/files/get/${fpath}`
  let headers = opts.headers || {}
  opts = {
    ...opts,
    headers: {
      ...headers,
      "Cache-Control": "no-cache"
    }
  }
  return fetchFromServer(path, opts);
}

/**
 * Attaches the necessary auth info to an XMLHttpRequest
 * @param {object} req The XMLHttpRequest to attach the auth header to
 * @param {string} token The authentication token to use with the server
 * @returns The XMLHttpRequest with the Authorization header attached
 */
export function serverXMLRequest(req) {
  let token = window.AARDVARK_ACCESS
  return req.setRequestHeader("Authorization", `Bearer ${token}`)
}

export function iframeBasename() {
  let loc = document.location
  if(SERVER_URL) {
    loc = new URL(SERVER_URL)
  }
  return loc.origin
}

/**
 * Connects to the server's socket.io server at a given namespace
 * @param {string} namespace The namespace to connect to
 * @param {string} token The authentication token to use with the server
 * @returns A socket.io connection to the server
 */
export function connectToServerSocket(namespace) {
  let url = ''
  let token = window.AARDVARK_ACCESS
  if(!token) {
    return undefined
  }
  url = namespace
  if(SOURCE === 'server' && process.env.NODE_ENV !== "production") {
    url = `${SERVER_URL}${namespace}`;
  } else {
    url = namespace
  } /*else {
    throw new Error("Invalid Source Setting! Expected SOURCE to be either local or server, but it was " + SOURCE + " instead. Check src/config.js or SOURCE environment variable.")
  }*/
  return io(`${url}`, {
    transportOptions: {
      polling: {
        extraHeaders: {
          Authorization: token
        }
      }
    },
    path: '/quickroll5/socket.io'
  });
}

/**
 * Takes a relative url path and adds the server domain/port to it
 * @param {string} path A relative url path on the server side
 * @returns The server url concatenated with the given path
 */
export const serverPath = (path) => {
  return `${SERVER_URL}${URL_BASE}${path}`
}

/**
 * Takes the url for a static asset, and returns the correct location based on NODE_ENV
 * @param {string} url The url for a static asset relative to the public directory
 * @returns A string url for the static asset with the URL_BASE added in production but not in dev
 */
export function staticAsset(url) {
  if(process.env.NODE_ENV === "production") {
    return `${URL_BASE}${url}`
  } else {
    return url
  }
}
