import undoable, {includeAction} from 'redux-undo'
import tabbedReducer from 'redux/higher_order_reducers/tabbedReducer'
import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'
import LibraryManager from 'redux/higher_order_reducers/libraryManager'
import {fetchFileFromServer} from 'helpers/net_helpers'
import {parsePlaylistJSON,
  serializeSingleRegionPlaylist} from 'helpers/playlist_helpers'
import {saveFile, saveMetadata} from 'redux/file_list'
import {messages} from 'redux/messages'
import {getIn, setIn} from 'helpers/general_helpers'

import PlaylistAddClipSelector from 'selectors/PlaylistAddClipPreviewSelector'

export const LOAD_SIMPLE_PLAYLIST = Symbol('load simple playlist')
export const RENAME_LIST = Symbol('rename list')
export const SET_LIST_PATH = Symbol('set list path')
export const ADD_LIST_ITEM = Symbol('add list item')
export const MOVE_LIST_ITEM = Symbol('move list item')
export const REMOVE_LIST_ITEM = Symbol('remove list item')
export const CHANGE_LIST_ITEM_DURATION = Symbol('change list item duration')
export const SELECT_LIST_LIBRARY_FILE = Symbol('select list library file')
export const SIMPLE_LIST_RANDOMIZE = Symbol('simple list randomize')
export const SIMPLE_LIST_SKIPMEI = Symbol('simple list skip mei')
export const SIMPLE_LIST_INVISIBLE = Symbol('simple list invisible')
export const SIMPLE_LIST_MUTE = Symbol('simple list mute')

export const SIMPLE_LIST_UNDO = Symbol('simple list undo')
export const SIMPLE_LIST_REDO = Symbol('simple list redo')

export const SIMPLE_LIST_CHANGE_TAB = Symbol('simple list change tab')
export const SIMPLE_LIST_CREATE_TAB = Symbol('simple list create tab')
export const SIMPLE_LIST_DELETE_TAB = Symbol('simple list delete tab')

const SimplePlaylistLibraryManager = new LibraryManager()

export const actions = {

  /**
   * Asynchronously loads a single-region playlist file from the server for editing
   * @param {array} path The path of the playlist file to load
   */
  loadSimplePlaylist: (path) => {
    return async (dispatch, getState) => {
      let jobId = dispatch(loading.startLoading(getState().local._loaderID))
      let data = await fetchFileFromServer(path.join('/'));
      dispatch(loading.finishLoading(getState().local._loaderID, jobId))
      if(data.ok) {
        data = await data.text();
        data = JSON.parse('{' + data + '}');
        if(data['multiregion playlist description']) {
          dispatch.global(messages.alert(`The playlist being loaded is a multi-region playlist. Only single-region playlists can be edited in the simple playlist editor.`, {level: 'error'}))
          return
        } else if (data['playlist description']) {
          data = data['playlist description']
          data.sections = [{
            list: data.list
          }]
        }
        data = parsePlaylistJSON(data)
        let {frames, ...rest} = data
        let list = getIn(frames, [0, 'timeline'])
        dispatch({
          type: LOAD_SIMPLE_PLAYLIST,
          payload: {
            list,
            settings: rest,
            path: path.slice(0, -1),
            name: path[path.length - 1]
          }
        })
      } else {
        if(data.status !== 404) {
          let err = await data.text()
          dispatch.global(messages.alert(`There was an error loading /${path.join('/')}: ${err}`, {level: 'error'}))
        }
      }
    }
  },

  /**
   * Saves the currently opened single-region playlist file to the server
   */
  saveSimplePlaylist: (rename=false) => {
    return async (dispatch, getState) => {
      let {videoList, settings, listPath, listName} = getState().local.present
      let data = {
        ...settings,
        list: videoList
      }
      let playlist = serializeSingleRegionPlaylist(data)
      let duration = playlist.duration
      playlist = '"playlist description":' + JSON.stringify(playlist, null, 2)
      let dest = listPath
      let name = listName
      if(rename || !name) {
        let val = await dispatch.global(messages.promptAsync("Save Playlist", {
          type: 'path',
          validate: (result) => (result.filename !== ''),
          invalidText: 'That is not a valid playlist name.',
          initialValue: {filename: name, directory: dest}
        }))
        if(val === null) {
          return
        }
        name = val.filename
        dest = val.directory
        dispatch(actions.renameList(name))
        dispatch(actions.setListPath(dest))
      }
      if(!dest) {
        dest = ['mnt', 'main', 'Playlists']
      }
      dest = dest.join('/')
      let source = new File([playlist], name, {type: 'text/plain'})
      let jobId = dispatch(loading.startLoading(getState().local._loaderID))
      dispatch.global(saveFile(source, dest, {
        createMetadata: true,
        onSuccess: async () => {
          let fullpath = dest.split('/').concat([name])
          await dispatch.global(saveMetadata({duration, 'file type': 'application/x-castus-playlist'}, fullpath))
          getState().local.lastSaved = getState().local.past[getState().local.past.length - 1]
          dispatch(loading.finishLoading(getState().local._loaderID, jobId))
          dispatch.global(messages.alert(`${name} was successfully saved! It was saved at /${dest}`, {level: 'success'}))
        },
        onError: async (res) => {
          dispatch(loading.finishLoading(getState().local._loaderID, jobId))
          let err = await res.text()
          dispatch.global(messages.alert(`There was an error saving ${name}: ${err}`, {level: 'error'}))
        }
      }));
    }
  },

  /**
   * Sets the currently selected library file
   * @param {array} selected Array of selected library files from library component
   */
  selectListLibraryFile: (selected) => ({
    type: SELECT_LIST_LIBRARY_FILE,
    payload: selected[0]
  }),

  /**
   * Renames the current simple playlist
   */
  renameList: (newName) => ({
    type: RENAME_LIST,
    payload: newName
  }),

  /**
   * Sets the path to save the current simple playlist to
   * @param {array} newPath An array path that the simple playlist is to be saved to
   */
  setListPath: (newPath) => ({
    type: SET_LIST_PATH,
    payload: newPath
  }),

  /**
   * Adds a new item to the video list
   * @param {array} path The path of the file to add to the list, as an array of strings.
   *  If not given, the currently selected library file is used.
   * @param {number} index The index to add the item at. If not given, it will be added to the end.
   */
  addListItem: (path=null, index=-1) => {
    return (dispatch, getState) => {
      path = path || getState().local.present.selectedLibraryFile
      if(!path) {
        return
      }
      let item = PlaylistAddClipSelector({...getState().global.file_list, selectedLibraryFile: getState().local.present.selectedLibraryFile})
      let list = [...getState().local.present.videoList]
      if(index >= 0) {
        list.splice(index, 0, item)
      } else {
        list.push(item)
      }
      dispatch({
        type: ADD_LIST_ITEM,
        payload: list
      })
    }
  },

  /**
   * Moves an item in the list to a new position
   * @param {number} oldIndex The current index of the item to be moved
   * @param {number} newIndex The index to move the item to
   */
  moveListItem: (oldIndex, newIndex) => {
    return (dispatch, getState) => {
      let list = [...getState().local.present.videoList]
      if(oldIndex === newIndex ||
        oldIndex >= list.length ||
        oldIndex < 0 ||
        newIndex >= list.length ||
        newIndex < 0) {
        return
      }
      let item = list.splice(oldIndex, 1)[0]
      list.splice(newIndex, 0, item)
      dispatch({
        type: MOVE_LIST_ITEM,
        payload: list
      })
    }
  },

  /**
   * Removes an item from the list
   * @param {number} index The index of the item to remove
   */
  removeListItem: (index) => {
    return (dispatch, getState) => {
      let list = [...getState().local.present.videoList]
      if(index < 0 || index >= list.length) {
        return
      }
      list.splice(index, 1)
      dispatch({
        type: REMOVE_LIST_ITEM,
        payload: list
      })
    }
  },

  /**
   * Changes the duration of an item to a duration that the user is prompted for
   * @param {number} index The index of the item to change
   */
  changeItemDuration: (index) => {
    return async (dispatch, getState) => {
      let currentStart = (getIn(getState().local.present, ["videoList", index, "start"]) || 0)
      let currentEnd = (getIn(getState().local.present, ["videoList", index, "end"]) || 0)
      let currentDuration = currentEnd - currentStart
      let newDuration = await dispatch.global(messages.promptAsync("What should the duration of the item be?", {type: "duration", defaultValue: currentDuration}))
      if(newDuration === null) {
        return
      }
      dispatch({
        type: CHANGE_LIST_ITEM_DURATION,
        payload: {
          index,
          newDuration: newDuration.asMilliseconds()
        }
      })
    }
  },

  /**
   * Sets or toggles the value of the randomize setting.
   * @param {boolean} value If true/false are passed, the value of randomize will be set to value.
   *  Otherwise, randomize will be toggled between true and false.
   */
  switchRandomize: (value=null) => {
    if(value !== null && !(typeof value === 'boolean')) {
      value = null
    }
    return {
      type: SIMPLE_LIST_RANDOMIZE,
      payload: value
    }
  },

  /**
   * Sets or toggles the value of the skip missing and expired items setting.
   * @param {boolean} value If true/false are passed, the value of skip missing/expired items will be set to value.
   *  Otherwise, skip missing/expired items will be toggled between true and false.
   */
  switchMissingExpired: (value=null) => {
    if(value !== null && !(typeof value === 'boolean')) {
      value = null
    }
    return {
      type: SIMPLE_LIST_SKIPMEI,
      payload: value
    }
  },

  switchInvisible: (value=null) => {
    if(value !== null && !(typeof value === 'boolean')) {
      value = null
    }
    return {
      type: SIMPLE_LIST_INVISIBLE,
      payload: value
    }
  },

  switchMute: (value=null) => {
    if(value !== null && !(typeof value === 'boolean')) {
      value = null
    }
    return {
      type: SIMPLE_LIST_MUTE,
      payload: value
    }
  },

  simpleListUndo: () => ({type: SIMPLE_LIST_UNDO}),
  simpleListRedo: () => ({type: SIMPLE_LIST_REDO}),

  libraryUpdate: SimplePlaylistLibraryManager.libraryUpdate,

}

export const initialState = {
  listPath: ['mnt', 'main', 'Playlists'], // The path of the directory containing the playlist to be edited
  listName: '',                           // The name of the playlist currently being edited
  videoList: [],                          // List of rpaths of videos in the playlist
  selectedLibraryFile: null,              // Either an rpath of the currently selected library file as an array, or null if nothing is selected
  settings: {                             // Settings for the playlist as a whole, mainly loaded from file to be preserved
    frameRate: {'n': 30000, 'd': 1001},
    previewTime: 0,
    timelineViewStart: 0,
    zoomLevel: 100,
    aspectRatio: [16, 9],
    randomize: false,
    skipMissingExpired: true,
    invisible: false,
    mute: false
  }
}

const reducer = (state=initialState, action) => {
  let {type, payload} = action

  switch(type) {
    case LOAD_SIMPLE_PLAYLIST:
      return {
        ...state,
        videoList: payload.list,
        settings: payload.settings,
        listPath: payload.path,
        listName: payload.name
      }
    case RENAME_LIST:
      return {
        ...state,
        listName: payload
      }
    case SET_LIST_PATH:
      return {
        ...state,
        listPath: payload
      }
    case SELECT_LIST_LIBRARY_FILE:
      return {
        ...state,
        selectedLibraryFile: payload
      }
    case ADD_LIST_ITEM:
    case REMOVE_LIST_ITEM:
    case MOVE_LIST_ITEM:
      return {
        ...state,
        videoList: payload
      }
    case CHANGE_LIST_ITEM_DURATION: {
      let {index, newDuration} = payload
      let videoList = setIn(state.videoList, [index, "end"], (getIn(state.videoList, [index, "start"]) || 0) + newDuration)
      return {
        ...state,
        videoList
      }
    }
    case SIMPLE_LIST_RANDOMIZE: {
      let newValue = !(state.settings.randomize)
      if(typeof payload === 'boolean') {
        newValue = payload
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          randomize: newValue
        }
      }
    }
    case SIMPLE_LIST_SKIPMEI: {
      let newValue = !(state.settings.skipMissingExpired)
      if(typeof payload === 'boolean') {
        newValue = payload
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          skipMissingExpired: newValue
        }
      }
    }
    case SIMPLE_LIST_INVISIBLE: {
      let newValue = !(state.settings.invisible)
      if(typeof payload === 'boolean') {
        newValue = payload
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          invisible: newValue
        }
      }
    }
    case SIMPLE_LIST_MUTE: {
      let newValue = !(state.settings.mute)
      if(typeof payload === 'boolean') {
        newValue = payload
      }
      return {
        ...state,
        settings: {
          ...state.settings,
          mute: newValue
        }
      }
    }
    default:
      return state
  }
}

// ACTIONS FOR TABBING
export const changeTab = (index) => ({
  type: SIMPLE_LIST_CHANGE_TAB,
  payload: index
})

export const createTab = (path) => {
  let payload = {}
  if(path) {
    payload = {
      listPath: path.slice(0, -1),
      listName: path.slice(-1).join()
    }
  }
  return {
    type: SIMPLE_LIST_CREATE_TAB,
    payload
  }
}

export const onTabCreated = () => {
  return (dispatch, getState) => {
    let {listPath, listName} = getState().local.present
    if(listPath.length > 0 && listName) {
      let fullpath = [...listPath, listName]
      dispatch(actions.loadSimplePlaylist(fullpath))
    }
  }
}

export const deleteTab = (index) => ({
  type: SIMPLE_LIST_DELETE_TAB,
  payload: index
})

const TAB_DISPLAY = (props) => {
  if(props.present.listName) {
    return props.present.listName
  } else {
    return '(NEW PLAYLIST)'
  }
}

const TAB_IS_UNSAVED = (props) => {
  if(props.lastSaved) {
    if(props.past.length <= 0) {
      return true
    }
    return props.past[props.past.length - 1] !== props.lastSaved
  } else {
    return (props.past.length > 0)
  }
}

// ASSEMBLE REDUCER
const undoReducer = undoable(reducer, {
  limit: 25,
  undoType: SIMPLE_LIST_UNDO,
  redoType: SIMPLE_LIST_REDO,
  filter: includeAction([
    ADD_LIST_ITEM,
    REMOVE_LIST_ITEM,
    MOVE_LIST_ITEM
  ])
})

const libraryReducer = SimplePlaylistLibraryManager.wrap(undoReducer, {present: initialState, past: [], future: []})

const loadingReducer = loaderReducer(libraryReducer, {present: initialState, past: [], future: []})

export default tabbedReducer('simple_playlist_editor', loadingReducer,
  {present: initialState, past: [], future: []},
  {
    changeTab: SIMPLE_LIST_CHANGE_TAB,
    createTab: SIMPLE_LIST_CREATE_TAB,
    deleteTab: SIMPLE_LIST_DELETE_TAB,
    display: TAB_DISPLAY,
    tabActions: actions,
    isUnsaved: TAB_IS_UNSAVED,
    onTabCreated
  }
)
