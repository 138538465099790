const SECOND = 1000

const MAGNET_PIXELS = 10

/**
 * Gets the length of a given playlist
 * @params {array} the list of frames in the playlist
 * @returns The length of the playlist as a number of milliseconds
 */
export function getLengthOfPlaylist(frames) {
  let endTime = -1
  frames.forEach((frame) => {
    if(frame.timeline) {
      frame.timeline.forEach((clip) => {
        if(clip.end > endTime) {
          endTime = clip.end
        }
      })
    }
  })
  return endTime
}

/**
 * Converts the cursor position over the playlist editor's timeline to the corresponding time within the playlist
 * @param {number} mouseX The X position of the mouse cursor, as given by the mouse move event's clientX property
 * @param {number} left The number of pixels that the timeline component is from the left of the screen
 * @param {number} zoomLevel The zoom level of the timeline, in pixels/second
 * @param {number} timelineViewStart The current time that the timeline view starts at, in milliseconds
 * @returns The time of the playlist that the cursor is hovering over, in milliseconds
 */
export function cursorTime(mouseX, left, zoomLevel, timelineViewStart) {
  return Math.max((((mouseX - left) / zoomLevel) * SECOND) + timelineViewStart, 0)
}

/**
 * Gets the start and end times of all clips in all frames in a given list of frames
 * @param {array} frameList An array of frame objects
 * @returns An array of start and end times of clips as numbers of milliseconds
 */
export function getTimesFromFrames(frameList) {
  let toReturn = []
  frameList.forEach((frame) => {
    if(frame.timeline) {
      frame.timeline.forEach((clip) => {
        if(clip.start) {
          toReturn.push(clip.start)
        }
        if(clip.end) {
          toReturn.push(clip.end)
        }
      })
    }
  })
  return toReturn
}

/**
 * Magnetizes a given time to the nearest magnet time within MAGNET_PIXELS range
 * @param {number} time The time to be magnetized in milliseconds
 * @param {number} zoomLevel The conversion ratio to use for converting between pixels and seconds
 * @param {array} magnetTimes An array of times to magnetize to, as numbers of milliseconds
 * @returns If time is within MAGNET_PIXELS (as converted via zoomLevel) of one or more magnetTimes,
 * the closest magnetTime to time will be returned. Otherwise, time will be returned
 */
export function magnetize(time, zoomLevel, magnetTimes=[]) {
  let magnetRange = pixelsToMilliseconds(MAGNET_PIXELS, zoomLevel)
  let closestTime = [...magnetTimes].sort((magA, magB) => (Math.abs(magB - time) - Math.abs(magA - time))).pop()
  if(Math.abs(closestTime - time) < magnetRange) {
    return closestTime
  } else {
    return time
  }
}

/**
 * Magnetizes a given pixel measurement to the nearest magnet pixel within MAGNET_PIXELS range
 * @param {number} pixels The pixel measurement to be magnetized in milliseconds
 * @param {array} magnetPixels An array of pixel measurements to magnetize to
 * @returns If pixels is within MAGNET_PIXELS of one or more magnetPixels,
 *  the closest magnetPixel to pixels will be returned. Otherwise, pixels will be returned
 */
export function magnetizePixels(pixels, magnetPixels=[]) {
  let closestPixel = [...magnetPixels].sort((magA, magB) => (Math.abs(magB - pixels) - Math.abs(magA - pixels))).pop()
  if(Math.abs(closestPixel - pixels) < MAGNET_PIXELS) {
    return closestPixel
  } else {
    return pixels
  }
}

/**
 * Magnetizes a given clip to the nearest magnet time within MAGNET_PIXELS range
 * @param {number} start The start time of the clip in milliseconds
 * @param {number} duration The duration of the clip in milliseconds
 * @param {number} zoomLevel The conversion ratio to use for converting between pixels and seconds
 * @param {array} magTimes An array of times to magnetize to, as numbers of milliseconds
 * @returns If the start or end of the clip are within MAGNET_PIXELS of one or more magnetTimes,
 * then the time difference between either the start or the end of the clip and their closest magnet time
 * (whichever is closer) will be returned. If no magnet times are within range, then 0 will be returned.
 */
export function magnetizeClip(start, duration, zoomLevel, magTimes=[]) {
  let end = start + duration
  // Magnetize the start and end to their closest magnet times
  let magStart = magnetize(start, zoomLevel, magTimes)
  let magEnd = magnetize(end, zoomLevel, magTimes)
  // Get the difference in milliseconds between the start and end of the clip
  // and their closest magnet times (or 0 if no magnet times are in range)
  let magStartDifference = magStart - start
  let magEndDifference = magEnd - end
  // Return the smallest non-zero difference that would not be certain to result in an overlap conflict.
  // Return 0 if both differences are 0.
  if(magEndDifference === 0 || (magStartDifference !== 0 && Math.abs(magStartDifference) <= Math.abs(magEndDifference))) {
    return magStartDifference
  } else {
    return magEndDifference
  }
}

/**
 * Converts milliseconds to pixels based on a given zoomLevel
 * @param {number} millis Millsecond value to convert
 * @param {number} zoomLevel The current zoom level as pixels/second
 * @returns The pixel value of the given millisecond time
 */
export var millisecondsToPixels = (millis, zoomLevel) => ((millis / SECOND) * zoomLevel)

/**
 * Converts pixels to milliseconds based on a given zoomLevel
 * @param {number} pixels Pixel value to convert
 * @param {number} zoomLevel The current zoom level as pixels/second
 * @returns The millisecond value of the given pixel measurement
 */
export var pixelsToMilliseconds = (pixels, zoomLevel) => ((pixels / zoomLevel) * SECOND)

/**
 * Tests if a clip with a given start and duration would collide with any clips
 * in a given timeline
 * @param {array} timeline An array of clips to test against
 * @param {number} start The start of the clip to test in milliseconds
 * @param {number} duration The duration of the clip to test in milliseconds
 * @returns Boolean indicating whether the clip would be colliding or not
 */
export function isColliding (timeline, start, duration) {
  return !!timeline.find((clip) => (
    start < clip.end &&
    start + duration > clip.start
  ))
}

/**
 * Gets the first clip within a frame's timeline that overlaps with the given time
 * @param {object} frame The frame to search within
 * @param {number} time The time to search for a clip at in milliseconds
 * @returns The first clip object overlapping the given time, or undefined if no clip is
 *  overlapping
 */
export function getClipAtTime(frame, time) {
  return frame.timeline.find((clip) => {
    if(typeof clip.start === 'number' && clip.start <= time) {
      // If the clip doesn't have a given end time, it is assumed
      // to be infinite in duration (as would be the case in the GlobalMediaEditor)
      if(typeof clip.end === 'number') {
        return clip.end >= time
      }
      return true
    }
    return false
  })
}

/**
 * PLAYLIST PARSER
 */

/**
 * Takes playlist json from playlist file and parses it to internal data
 * @param {object} playlist The playlist json data from the playlist file
 * @returns {object} An object containing the internal playlist data to be added to the store state
 */
export function parsePlaylistJSON(playlist) {
  let fps;
  let toReturn = {
    skipMissingExpired: playlist['auto remove'],
    randomize: (playlist['play mode'] === 'random'),
    overrideComingUpNext: playlist['override cun'],
    runtimeScripts: playlist['runtime scripts'],
    invisible: false,
    mainRegion: -1,
    mute: false
  }
  if(playlist['aspect ratio']) {
    toReturn.aspectRatio = [
      playlist['aspect ratio'].n,
      playlist['aspect ratio'].d
    ]
    toReturn.enforceAspectRatio = playlist['aspect ratio'].enforce
  }
  if(playlist['mute']) toReturn.mute = playlist['mute'] === true || playlist['mute'] > 0;
  if(playlist['invisible']) toReturn.invisible = playlist['invisible'] === true || playlist['invisible'] > 0;
  if(playlist['timeline rate'] && playlist['timeline rate'].d) {
    toReturn.frameRate = playlist['timeline rate']
    fps = playlist['timeline rate']
    /*
    let framesps = (playlist['timeline rate'].n / playlist['timeline rate'].d)
    if(framesps) {
      framesps = Math.floor(framesps * 100) / 100
      toReturn.frameRate = framesps
      fps = framesps
    }
    */
  }
  if(fps && playlist['editor view']) {
    toReturn.previewTime = Math.floor(framesToMilliseconds(playlist['editor view']['cursor frame'], fps))
    toReturn.timelineViewStart = framesToMilliseconds(playlist['editor view']['view start'], fps)
  }
  if(playlist.sections) {
    let main = -1
    toReturn.frames = playlist.sections.map((section, section_ind) => {
      let frame = {
        name: section.name,
        position: {
          x: section.region ? section.region.left : 0,
          y: section.region ? section.region.top : 0
        },
        size: {
          width: section.region ? section.region.width : 100,
          height: section.region ? section.region.height : 100
        },
        options: {
          video: !section.invisible,
          audio: !section.mute
        }
      }
      if(section.list) {
        frame.timeline = section.list.map((item) => {
          let clip = {
            item: [],
            data: {}
          }
          Object.entries(item).forEach(([key, val]) => {
            switch (key) {
              case 'path':
                if(typeof val === 'string') {
                  clip.item = val.split('/')
                }
                break
              case 'start':
                clip.start = Math.floor(val * SECOND)
                break
              case 'end':
                clip.end = Math.floor(val * SECOND)
                break
              case 'offset':
                clip.in = Math.floor(val * SECOND)
                break
              case "association": {
                let association = clip.association || {}
                association.id = val
                clip.association = association
                break
              }
              case "association name": {
                let association = clip.association || {}
                association.name = val
                clip.association = association
                break
              }
              default:
                clip.data[key] = val
                break
            }
          })
          return clip
        })
      }
      if(section.main) {
        if(main >= 0) {
          console.warn("There are multiple regions designated as main in the file. Only the first one will be designated main in the editor.")
        }
        main = section_ind
      }
      return frame
    })
    toReturn.mainRegion = main
  }
  if(fps && playlist.duration) {
    toReturn.playlistScrollEnd = framesToMilliseconds(playlist.duration, fps)
  }
  return toReturn
}

/**
 * Serializes playlist data from store state into a JSON object to be written to a playlist file
 * @param {object} data Playlist data from the store state to be serialized.
 */
export function serializePlaylist(data) {
  let toReturn = serializeSettings(data)
  let fps = data.frameRate
  let totalDuration = 0
  toReturn['sections'] = data.frames.map((frame, frame_ind) => {
    let sectionDurationFrames = 0
    let section = {
      mute: frame.options ? (frame.options.audio === false) : false,
      invisible: frame.options ? (frame.options.video === false) : false,
      name: frame.name ? frame.name : `Region ${frame_ind + 1}`,
      region: {
        left: frame.position.x,
        top: frame.position.y,
        width: frame.size.width,
        height: frame.size.height
      },
      main: (data.mainRegion === frame_ind)
    }
    section.list = frame.timeline.map((clip) => {
      let item = serializeClip(clip, fps)
      if(item.endFrame > sectionDurationFrames) {
        sectionDurationFrames = item.endFrame
      }
      return item
    })
    section.durationFrames = sectionDurationFrames
    let sectionDuration = framesToMilliseconds(sectionDurationFrames, fps) / SECOND
    section.duration = sectionDuration
    if(sectionDuration > totalDuration) {
      totalDuration = sectionDuration
    }
    return section
  })
  toReturn.duration = totalDuration
  return toReturn
}

/**
 * Serializes single-region playlist data from store state into a JSON object to be written to a playlist file
 * @param {object} data Playlist data from the store state to be serialized
 */
export function serializeSingleRegionPlaylist(data) {
  let toReturn = serializeSettings(data)
  let fps = data.frameRate
  let totalDuration = 0
  toReturn['list'] = data.list.map((clip) => {
    let clipDuration = clip.end - clip.start
    let parseClip = {
      ...clip,
      start: totalDuration,
      end: (clipDuration + totalDuration)
    }
    let item = serializeClip(parseClip, fps)
    totalDuration = parseClip.end
    return item
  })
  toReturn.duration = (totalDuration / SECOND)
  toReturn.invisible = data.invisible
  toReturn.mute = data.mute
  return toReturn
}

/**
 * Serializes various settings that are global to a given playlist (rather than being associated with a particular region)
 * @param {object} data The playlist's data from the store
 * @returns An object containing the settings in the format needed to be saved to file
 */
export const serializeSettings = (data) => {
  let toReturn = {
    title: "",
    author: "",
    "play mode": data.randomize ? "random" : "sequential",
    "auto remove": data.skipMissingExpired,
    "override cun": data.overrideComingUpNext,
    "runtime scripts": data.runtimeScripts
  }
  let fps = data.frameRate
  toReturn['editor view'] = {
    'cursor frame': millisecondsToFrames(data.previewTime, fps),
    'view start': millisecondsToFrames(data.timelineViewStart, fps),
    'view end': millisecondsToFrames(
      data.timelineViewStart + pixelsToMilliseconds(SECOND, data.zoomLevel),
      fps
    )
  }
  toReturn['aspect ratio'] = {
    'n': data.aspectRatio[0],
    'd': data.aspectRatio[1],
    'enforce': data.enforceAspectRatio
  }
  if(typeof(fps) === "object") {
    toReturn['timeline rate'] = fps
  } else {
    if(fps > 29.96 && fps < 29.98) {
      toReturn['timeline rate'] = {
        'n': 30000,
        'd': 1001
      }
    } else {
      toReturn['timeline rate'] = {
        'n': fps * SECOND,
        'd': SECOND
      }
    }
  }
  return toReturn
}

/**
 * Serializes an individual playlist clip for saving
 * @param {object} clip The clip to serialize
 * @param {number} fps The fps of the playlist containing the clip
 * @returns A object representation of the clip, in the format needed to be saved to file
 */
export const serializeClip = (clip, fps) => {
  let item = {}
  // Convert from millis to frames and floor, then convert back to millis
  // This is to ensure that the frame times and second times match up
  item.startFrame = Math.round(millisecondsToFrames(clip.start, fps));
  item.endFrame = Math.round(millisecondsToFrames(clip.end, fps));
  item.offsetFrame = Math.round(millisecondsToFrames(clip.in, fps));
  item.start = framesToMilliseconds(item.startFrame, fps) / SECOND;
  item.end = framesToMilliseconds(item.endFrame, fps) / SECOND;
  item.offset = framesToMilliseconds(item.offsetFrame, fps) / SECOND;
  item.durationFrame = item.endFrame - item.startFrame;
  item.duration = item.end - item.start;
  item.isSelected = false;
  if(clip.item instanceof Array) {
    if(clip.item[0] !== '') {
      clip.item = ['', ...clip.item]
    }
    item.path = clip.item.join('/')
  } else {
    item.path = clip.item
  }
  if(clip.data) {
    Object.entries(clip.data).forEach(([key, val]) => {
      if(item[key] === undefined) {
        item[key] = val
      }
    })
  }
  if(clip.association) {
    item.association = clip.association.id
    item["association name"] = clip.association.name
  }
  return item
}

/**
 * Converts frames to milliseconds based on a given fps
 * @param {number} frames Time in frames to be converted
 * @param {number} fps The frames per second of the playlist
 * @returns The millisecond value of the given frame duration
 */
export const framesToMilliseconds = (frames, fps) => {
  if(typeof fps === "object") {
    return frames * (SECOND * fps.d / fps.n)
  } else {
    return frames * (SECOND / fps)
  }
}

/**
 * Converts milliseconds to frames based on a given fps
 * @param {number} millis Time in milliseconds to be converted
 * @param {number} fps The frames per second of the playlist
 * @returns The frames value of the given millisecond duration
 */
export const millisecondsToFrames = (millis, fps) => {
  if(typeof fps === "object") {
    return millis * fps.n / (SECOND * fps.d)
  } else {
    return millis * fps / SECOND
  }
}
