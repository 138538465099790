import React, {PureComponent} from 'react'
import { millisecondsToPixels } from 'helpers/playlist_helpers'

import './ViewIndicator.css'

export default class ViewIndicator extends PureComponent {

  render() {
    let {startTime, previewTime, zoomLevel, preview, hide} = this.props

    if(previewTime < startTime || hide) {
      return null
    }

    let cls = 'PlaylistViewIndicator'

    if(preview) {
      cls = cls + ' preview'
    }

    let left = millisecondsToPixels(previewTime - startTime, zoomLevel) - 3
    let style = {left}

    return (
      <div style={style} className={cls}>
        <div className='PlaylistViewIndicatorLine'/>
      </div>
    )
  }

}
