import { createSelector } from 'reselect'

const getScheduleSwap = (state) => state.scheduleSwap ? state.scheduleSwap.schedule : null
const getScheduleType = (state) => state.scheduleSwap ? state.scheduleSwap.type : ""

export default createSelector([
  getScheduleSwap,
  getScheduleType
], (schedule, type) => {
  if(schedule === null || (type !== 'monthly' && type !== 'yearly' && type !== 'weekly' && type !== "endless")) {
    return []
  }
  let toReturn = []
  for(let item of schedule) {
    let [startDate, endDate] = getItemDates(item, type)
    if(!toReturn.includes(startDate)) {
      toReturn.push(startDate)
    }
    if(endDate !== startDate && !toReturn.includes(endDate) &&
      !(item.span.end.hour() === 0 &&
      item.span.end.minute() === 0 &&
      item.span.end.second() === 0 &&
      item.span.end.millisecond() === 0)) {
      toReturn.push(endDate)
    }
  }
  return toReturn
})

const getItemDates = (item, type) => {
  switch(type) {
    case 'weekly':
      return [
        item.span.start.day(),
        item.span.end.day()
      ]
    case 'monthly':
      return [
        item.span.start.date(),
        item.span.end.date()
      ]
    case 'yearly':
      return [
        `${item.span.start.month()}/${item.span.start.date()}`,
        `${item.span.end.month()}/${item.span.end.date()}`
      ]
    case 'endless':
      return [
        `${item.span.start.year()}/${item.span.start.month()}/${item.span.start.date()}`,
        `${item.span.end.year()}/${item.span.end.month()}/${item.span.end.date()}`
      ]
    default:
      return []
  }
}
