import React, {PureComponent} from 'react'
import {Input} from 'semantic-ui-react'

export default class BlockColorInput extends PureComponent {

  handleColorChange = (e, data) => {
    let {name, recolorBlock} = this.props
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      recolorBlock(name, data.value)
    }, 300)
  }

  render() {
    let {value} = this.props
    return (
      <Input type="color"
        value={value || "#7fb3cc"}
        label="Color"
        title="Display Color of Block"
        fluid
        className="blockColorInput"
        onChange={this.handleColorChange}/>
    )
  }

}
