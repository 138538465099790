import React, {Component} from 'react'

export default (WrappedComponent) => {
  return class deferredRenderComponent extends Component {

    constructor(props) {
      super(props)
      this.state = {shouldRender: false}
    }

    componentDidMount() {
      this._isMounted = true
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(() => {
          if(this._isMounted) {
            this.setState({shouldRender: true})
          }
        })
      })
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    render() {
      return this.state.shouldRender ? <WrappedComponent {...this.props}/> : null
    }

  }
}
