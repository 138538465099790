import React, {PureComponent} from 'react'
import {Table, Icon, Image, Checkbox, Dropdown} from 'semantic-ui-react'
import {staticAsset} from 'helpers/net_helpers'
import PropTypes from 'prop-types'

import "./LibraryFileRow.css"

import LibraryItemDropdown from './LibraryItemDropdown'

class LibraryFileRow extends PureComponent {

  componentDidMount() {
    if(this.props.highlight && this.props.spanRef.current) {
      this.props.spanRef.current.scrollIntoView({block: "center"})
    }
  }

  render() {
    let {hasData,
      cc,
      hd,
      title,
      filename,
      generatingMetadata,
      date,
      size,
      filetype,
      icon,
      duration,
      active,
      compact,
      highlight,
      selectOne,
      ismntmaintv,
      associations,
      selectedAssociation,
      dropdownActions,
      closerToBottom,
      dropdownHeight,
      contentWindowData,
      selectedChannel,
      spanRef,
      onClick,
      onDblClick,
      onSelectBoxClick,
      inputAssociations,
      onAssociationDropdownChange,
      onAssociationDropdownOpenClose,
      onDropdownSelect,
      onDropdownDeselect} = this.props

    if(!hasData) {
      return <Table.Row/>
    }

    let cls = ''
    if(highlight) {
      cls = 'highlighted'
    }

    let emblem_cc = null
    if (parseInt(cc) > 0 || cc === '1' || cc === 'Y' || cc === 'T')
        emblem_cc = (<span> <img style={{ height: "0.8em" }} src={staticAsset("/icons/cclogo.png")} alt="Closed Captions" /></span>)

    let emblem_hd = null
    if (hd === '2160') {
        emblem_hd = (<span style={{fontSize: "0.75em", fontWeight: "400", color: "#00007F" }}> (4K<span style={{fontSize: "0.5em", fontWeight: "900"}}>UHD</span>)</span>)
    }
    else if (hd === '1080' || hd === '720') {
        emblem_hd = (<span style={{fontSize: "0.75em", fontWeight: "400", color: "#00007F" }}> ({hd}<span style={{fontSize: "0.75em", fontWeight: "900"}}>HD</span>)</span>)
    }
    else if (hd === '576' || hd === '480' || hd === '360') {
        emblem_hd = (<span style={{fontSize: "0.75em", fontWeight: "400", color: "#00007F" }}> ({hd}<span style={{fontSize: "0.75em", fontWeight: "900"}}>SD</span>)</span>)
    }
    else if (parseInt(hd) > 0 || hd === '1' || hd === 'Y' || hd === 'T') {
        emblem_hd = (<span style={{fontSize: "0.75em", fontWeight: "400", color: "#00007F" }}> (HD)</span>)
    }

    let nocheckbox = ismntmaintv

    let selectCell = selectOne ?
      null :
      (<Table.Cell width='1'
          textAlign='center'
          onClick={nocheckbox ? undefined : onSelectBoxClick}
          onDoubleClick={(e) => {e.stopPropagation()}}>
          {nocheckbox ? <></> : <Checkbox checked={active}/>}
        </Table.Cell>)

    let dateCell = (<Table.Cell width='2' className='cellNoOverflow'>{date}</Table.Cell>)

    let typeCell = compact ?
      null :
      (<Table.Cell width='2' className='cellNoOverflow'>{filetype}{emblem_cc}{emblem_hd}</Table.Cell>)

    let sizeCell = compact ?
      null :
      (<Table.Cell width='2' className='cellNoOverflow'>{size}</Table.Cell>)

    let contentWindowIcon;
    let rowStatus = {}
    if(contentWindowData && "closed" in contentWindowData) {
      contentWindowIcon = <Icon name="clock" title={contentWindowData.message} color={contentWindowData.closed ? "red" : "green"}/>
      title = `${title} (${contentWindowData.message})`
      if(contentWindowData.closed) {
        rowStatus.negative = true
      } else {
        rowStatus.positive = true
      }
    }

    let associationsDropdown;
    if(associations && inputAssociations) {
      let assocOptions = [
        {
          key: 0,
          text: "(none)",
          value: null
        },
        ...associations.map((assoc) => {
          return {
            key: assoc.id,
            text: assoc.name,
            value: assoc.id
          }
        })
      ]
      associationsDropdown = <Dropdown selection
        className="associationsDropdown"
        options={assocOptions}
        upward={closerToBottom}
        fluid
        placeholder="(none)"
        value={selectedAssociation}
        onOpen={onAssociationDropdownOpenClose}
        onClose={onAssociationDropdownOpenClose}
        onChange={onAssociationDropdownChange}/>
    }

    return (
      <Table.Row onDoubleClick={onDblClick}
          onClick={onClick}
          active={active}
          {...rowStatus}
          className={cls}>
        {selectCell}
        <Table.Cell width='1'
          textAlign='center'
          singleLine={!compact}
          onDoubleClick={(e) => {e.stopPropagation()}}>
          {dropdownActions && dropdownActions.length > 0 ?
            <>
              <LibraryItemDropdown options={dropdownActions}
                handleSelect={onDropdownSelect}
                handleDeselect={onDropdownDeselect}
                dropdownHeight={dropdownHeight}
                icon={icon}
                generatingMetadata={generatingMetadata}
                closerToBottom={closerToBottom}
                selectedChannel={selectedChannel}/>
              <span ref={spanRef}/>
            </> :
            (icon ?
              <Icon className='svg-icon' size='large' onDoubleClick={onDblClick}>
                <Image src={staticAsset(`/icons/${icon}.svg`)}/>
              </Icon>:
              <Icon/>)}
        </Table.Cell>
        <Table.Cell width='6' className='cellWordBreak' title={title}>{contentWindowIcon}{filename}{associationsDropdown}</Table.Cell>
        {dateCell}
        {typeCell}
        <Table.Cell width='2' className='cellNoOverflow' title={duration}>{duration}</Table.Cell>
        {sizeCell}
      </Table.Row>
    )
  }
}

LibraryFileRow.propTypes = {
  hasData: PropTypes.bool,
  title: PropTypes.string,
  filename: PropTypes.string,
  date: PropTypes.string,
  size: PropTypes.string,
  filetype: PropTypes.string,
  icon: PropTypes.string,
  duration: PropTypes.string,
  active: PropTypes.bool,
  compact: PropTypes.bool,
  selectOne: PropTypes.bool,
  dropdownActions: PropTypes.array,
  closerToBottom: PropTypes.bool,
  dropdownHeight: PropTypes.any,
  spanRef: PropTypes.object,
  onClick: PropTypes.func,
  onDblClick: PropTypes.func,
  onSelectBoxClick: PropTypes.func,
  onDropdownSelect: PropTypes.func,
  onDropdownDeselect: PropTypes.func
}

export default LibraryFileRow
