import {messages} from 'redux/messages'
import {fetchFromServer} from 'helpers/net_helpers'

const SET_VOD_CONFIG = Symbol("set_vod_config")

export const actions = {

  /**
   * Gets the user's info using the id in their vod.json config file
   */
  getVodConfig: () => {
    return async (dispatch, getState) => {
      let res = await fetchFromServer("/v2/uploads/vod-config")
      if(res.ok) {
        let config = await res.json()
        dispatch({
          type: SET_VOD_CONFIG,
          payload: config
        })
      } else if(res.status === 404) {
        dispatch({
          type: SET_VOD_CONFIG,
          payload: {_error: "Cloud vod is not configured on this unit. If you have a cloud vod account, please click the Setup Cloud Configuration button below and enter your credentials to set it up."}
        })
      }
    }
  },

  /**
   * Sets-up the user's vod.json config file
   */
  setupVodConfig: () => {
    return async (dispatch, getState) => {
      let email = await dispatch(messages.promptAsync("Please enter the email address of your CASTUS cloud account."));
      if(!email) {
        return
      }
      let password = await dispatch(messages.promptAsync("Please enter the password of your CASTUS cloud account.", {type: "password"}));
      if(!password) {
        return
      }
      let res = await fetchFromServer("/v2/uploads/vod-config", {
        method: "POST",
        body: JSON.stringify({email, password}),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if(res.ok) {
        dispatch({
          type: SET_VOD_CONFIG,
          payload: {}
        })
        dispatch(actions.getVodConfig())
      } else {
        dispatch(messages.alert("Error setting up cloud vod config", {level: "error"}))
      }
    }
  }
}

const initialState = {
  vodConfig: {}
}

export default (state=initialState, action) => {

  let {type, payload} = action

  switch(type) {
    case SET_VOD_CONFIG:
      return {
        ...state,
        vodConfig: payload
      }
    default:
      return state
  }
}
