import React, {PureComponent} from 'react';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend'
import { Route, Switch, Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {openApplication} from 'redux/menu'
import applicationList from 'applicationList'
import Home from 'components/Home'
import Create from 'components/Create'

import {checkHasAccessToApp} from 'helpers/authentication_helpers'
import {getIn} from 'helpers/general_helpers'

import './ApplicationPane.css'
class ApplicationPane extends PureComponent {

  constructor(props) {
    super(props)
    this.content = React.createRef()
    this.routes = Object.entries(applicationList).filter(([key, app]) => {
      return checkHasAccessToApp(props.appAccess, key)
    }).map(([key, app]) => {
      return (<Route key={key} path={app.path} component={app.component}/>)
    })
  }

  componentDidUpdate(prevprops) {
    if(prevprops.routing && prevprops.routing.location &&
      prevprops.routing.location.pathname !== this.props.routing.location.pathname) {
      this.content.current.scrollTop = 0
    }
  }

  render() {
    let {openApplication, appAccess, about} = this.props

    // Build type from version file
    let buildType = getIn(about, ["versions", "platform", "Build type"])
    if(buildType) {
      buildType = buildType.trim()
    }

    return (
      <div id={'content'} ref={this.content}>
        <Switch>
          <Route exact path="/" render={() => <Home openApplication={openApplication} appAccess={appAccess} buildType={buildType}/>} />
          <Route exact path="/create" render={() => <Create openApplication={openApplication} appAccess={appAccess}/>} />
          {this.routes}
          <Redirect to='/'/>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  routing: state.router,
  appAccess: state.server.appAccess,
  about: state.about
})

const mapDispatchToProps = dispatch => bindActionCreators({
  openApplication
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropContext(HTML5Backend)(ApplicationPane))
