import React, {PureComponent} from 'react'
import {millisToHourString} from 'helpers/general_helpers'
import {Button} from 'semantic-ui-react'

import './TimeHeader.css'

class TimeHeader extends PureComponent {

  handleChange = () => {
    let {setPreviewTime, messages} = this.props
    messages.prompt("What time do you want to preview?", (newTime) => {
      if(newTime === null) {
        return
      }
      newTime = newTime.asMilliseconds()
      setPreviewTime(newTime)
    }, {type: 'duration', subseconds: true})
  }

  render() {
    let {previewTime} = this.props

    let displayTime = millisToHourString(previewTime, true)

    return (
      <div id='PlaylistTimeHeader' className='noselect'>
        <Button id='PlaylistTimeHeaderDisplay' compact fluid onClick={this.handleChange}>{displayTime}</Button>
      </div>
    )

  }

}

export default TimeHeader
