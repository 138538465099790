import React, {Component} from 'react'
import {Table} from 'semantic-ui-react'
import ScheduleBlock from './ScheduleTableBlock'
import ScheduleItem from './ScheduleTableItem'

import './ScheduleTable.css'

class ScheduleTable extends Component {

  constructor(props) {
    super(props)
    this.state = {height: 0}
    this.frame = React.createRef()
  }

  componentDidMount() {
    if(this.frame.current && this.props.scrollTop) {
      this.frame.current.scrollTop = this.props.scrollTop
    }
    this.updateFrameSize()
    window.addEventListener("resize", this.updateFrameSize)
  }

  componentWillUnmount() {
    if(this.frame.current) {
      this.props.itemActions.scheduleTableRecordScroll(this.frame.current.scrollTop)
    }
    window.removeEventListener("resize", this.updateFrameSize)
  }

  updateFrameSize = () => {
    if(this.frame.current) {
      let {height} = this.frame.current.getBoundingClientRect()
      if(height !== this.state.height) {
        this.setState({height})
      }
    }
  }

  render() {
    let {timeSlotLength,
      schedule,
      showSubseconds,
      addScheduleItems,
      pasteScheduleItems,
      selectScheduleItems,
      itemActions,
      scrollEvent,
      hasSelectedFiles,
      clipboard} = this.props

    let {height} = this.state


    if(!timeSlotLength) {
      timeSlotLength = 30
    }

    let tableRows = []

    schedule.forEach((item) => {
        switch(item.type) {
          case 'empty':
            tableRows.push(<ScheduleItem {...item}
              scheduleItem={item}
              key={item.body.label + ' ' + item.span.start.toString()}
              addScheduleItems={addScheduleItems}
              pasteScheduleItems={pasteScheduleItems}
              itemActions={itemActions}
              selectScheduleItems={selectScheduleItems}
              clipboard={clipboard}
              className='empty'
              scrollEvent={scrollEvent}
              hasSelectedFiles={hasSelectedFiles}
              showSubseconds={showSubseconds}
              scrollSize={height}/>)
            break
          case 'event':
          case 'item':
            tableRows.push(<ScheduleItem {...item}
              scheduleItem={item}
              key={item.key}
              selectScheduleItems={selectScheduleItems}
              itemActions={itemActions}
              clipboard={clipboard}
              scrollEvent={scrollEvent}
              addScheduleItems={addScheduleItems}
              pasteScheduleItems={pasteScheduleItems}
              hasSelectedFiles={hasSelectedFiles}
              showSubseconds={showSubseconds}
              scrollSize={height}/>)
            break
          case 'block':
            tableRows.push(<ScheduleBlock {...item}
              scheduleBlock={item}
              key={item.key}
              itemActions={itemActions}
              selectScheduleItems={selectScheduleItems}
              clipboard={clipboard}
              addScheduleItems={addScheduleItems}
              pasteScheduleItems={pasteScheduleItems}
              hasSelectedFiles={hasSelectedFiles}
              scrollEvent={scrollEvent}
              showSubseconds={showSubseconds}
              scrollSize={height}/>)
            break
          default:
            tableRows.push(null)
            break
        }
    })

    return (
      <div className='scheduleTableContainer' ref={this.frame}>
        <Table celled compact columns={16}>
          <Table.Body id='scheduleTableBody'>
            {tableRows}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default ScheduleTable
