import React, {PureComponent} from 'react'
import {Image} from 'react-konva'

export default class FrameImage extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {image: null, x: 0, y: 0}
  }
  // Copied from example code on https://konvajs.org/docs/react/Images.html
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    if(this.image) {
      this.image.removeEventListener('load', this.handleLoad);
    }
  }
  loadImage() {
    // save to "this" to remove "load" handler on unmount
    if(this.props.src) {
      this.nextImage = new window.Image();
      this.nextImage.src = this.props.src;
      this.nextImage.addEventListener('load', this.handleLoad);
    } else {
      this.image = null
      this.handleLoad()
    }
  }
  handleLoad = () => {
    this.image = this.nextImage
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image
    });
    if (this.imageNode) { // NTS: can be null!
      // if you keep same image object during source updates
      // you will have to update layer manually:
      this.imageNode.getLayer().batchDraw();
    }
    else {
      console.trace('this.imageNode is null')
    }
  };
  // End copied code

  render() {
    let {image, x, y} = this.state
    let {scaleX, scaleY, visible} = this.props
    let {width, height} = this.props.rect
    // Keep image ratio size
    if(image) {
      let scaledWidth = scaleX * width
      let scaledHeight = scaleY * height
      let imageRatio = image.width / image.height
      let rectRatio = scaledWidth / scaledHeight
      if(imageRatio > rectRatio) {
        // Image would be squashed horizontally, so need to adjust y and height
        let newHeight = scaledWidth / imageRatio
        y = (scaledHeight - newHeight) / (2 * scaleY)
        height = newHeight / scaleY
      } else if (imageRatio < rectRatio) {
        // Image would be squashed vertically, so need to adjust x and width
        let newWidth = scaledHeight * imageRatio
        x = (scaledWidth - newWidth) / (2 * scaleX)
        width = newWidth / scaleX
      }
    }
    return (
      <Image image={image}
        opacity={visible === false ? 0 : 1}
        x={x}
        y={y}
        width={width}
        height={height}
        ref={node => {
          this.imageNode = node;
        }}/>
    )
  }

}
