import React, {PureComponent} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import ChannelSelector from 'selectors/ChannelSelector'
import OutputSelector from 'selectors/OutputSelector'
import InputSelector from 'selectors/InputSelector'

import {
  activate_channel,
  startConfidenceMonitors,
  didLoadMonitorImage,
  setChannelIntervals,
  listScheduleSets,
  createScheduleSet,
  applyScheduleSet
} from 'redux/channel'

import {openApplication} from 'redux/menu'

import SidebarSegment from 'components/Sidebar/Header.jsx'
import SetScheduleIntervalsModal from 'components/Sidebar/SetScheduleIntervalsModal.jsx'
import ScheduleSetsModal from 'components/Sidebar/ScheduleSetsModal.jsx'
import { Segment, Tab, Dropdown } from 'semantic-ui-react'

import './WidgetBar.css'

class WidgetBar extends PureComponent {

  constructor(props) {
    super(props)
    props.startConfidenceMonitors()
    this.state = {
      setScheduleIntervalsModalOpen: false,
      scheduleSetsModalOpen: false
    }
  }

  handleMonitorLoad = (id, url) => {
    this.props.didLoadMonitorImage(id, url)
  }

  goToQueue = (id) => {
    this.props.openApplication('Settings', `/channel/${id}/queue`)
  }

  goToSettings = (id, type) => {
    this.props.openApplication('Settings', `/${type}/${id}`)
  }

  goToInputControls = (id) => {
    this.props.openApplication('Settings', `/input/${id}/controls`)
  }

  create_channel_headers(channel_headers, channelClick, active_channel) {
    return channel_headers.map( (channel, index)=>(
      <SidebarSegment data={channel}
        type="channel"
        key={channel.name}
        active={active_channel===channel.key}
        onClick={channelClick}
        onLoad={this.handleMonitorLoad}
        onQueue={this.goToQueue}
        onSettings={this.goToSettings}/>
    ))
  }

  create_output_headers = (output_headers) => {
    return output_headers.map((output, index) => (
      <SidebarSegment data={output}
        type="output"
        key={output.name}
        onSettings={this.goToSettings}/>
    ))
  }

  create_input_headers = (input_headers) => {
    return input_headers.map((input, index) => (
      <SidebarSegment data={input}
        type="input"
        key={input.name}
        onSettings={this.goToSettings}
        onControls={this.goToInputControls}/>
    ))
  }

  openScheduleIntervalsModal = () => {this.setState({setScheduleIntervalsModalOpen: true})}
  closeScheduleIntervalsModal = () => {this.setState({setScheduleIntervalsModalOpen: false})}

  openScheduleSetsModal = () => {this.setState({scheduleSetsModalOpen: true})}
  closeScheduleSetsModal = () => {this.setState({scheduleSetsModalOpen: false})}

  render() {
    let {active_channel, scheduleSets} = this.props.channel
    let {activate_channel,
      channel_headers,
      output_headers,
      input_headers,
      setChannelIntervals,
      listScheduleSets,
      createScheduleSet,
      applyScheduleSet} = this.props
    let {setScheduleIntervalsModalOpen, scheduleSetsModalOpen} = this.state

    // TODO: These should probably be widgets, as there may be more than just channel headers over here?
    let ChannelHeaders = this.create_channel_headers(channel_headers, activate_channel, active_channel)
    let OutputHeaders = this.create_output_headers(output_headers)
    let InputHeaders = this.create_input_headers(input_headers)

    let channelTab = {menuItem: "Channels", render: () => (
      <Tab.Pane className="sidebar-tab-pane">
        <Segment.Group id="channel-sidebar-contents" compact>
          <Segment className="widgetBarHamburger" color={"grey"} inverted={true} style={ {borderRadius: 0, borderBottom: 'solid 1px black', borderTop: 0} } compact>
            <Dropdown button fluid icon="sidebar">
              <Dropdown.Menu>
                <Dropdown.Item text="Schedule Sets" onClick={this.openScheduleSetsModal}/>
                <Dropdown.Item text="Set Schedule Interval for All Channels" onClick={this.openScheduleIntervalsModal}/>
              </Dropdown.Menu>
            </Dropdown>
          </Segment>
          { ChannelHeaders }
        </Segment.Group>
        <SetScheduleIntervalsModal open={setScheduleIntervalsModalOpen} closeModal={this.closeScheduleIntervalsModal} setChannelIntervals={setChannelIntervals}/>
        <ScheduleSetsModal open={scheduleSetsModalOpen}
          closeModal={this.closeScheduleSetsModal}
          scheduleSets={scheduleSets}
          listScheduleSets={listScheduleSets}
          createScheduleSet={createScheduleSet}
          applyScheduleSet={applyScheduleSet}/>
      </Tab.Pane>
    )}

    let outputTab = {menuItem: "Outputs", render: () => (
      <Tab.Pane className="sidebar-tab-pane">
        <Segment.Group id="output-sidebar-contents" compact>
          { OutputHeaders }
        </Segment.Group>
      </Tab.Pane>
    )}

    let inputTab = {menuItem: "Inputs", render: () => (
      <Tab.Pane className="sidebar-tab-pane">
        <Segment.Group id="input-sidebar-contents" compact>
          { InputHeaders }
        </Segment.Group>
      </Tab.Pane>
    )}

    let panes = [
      channelTab,
      outputTab,
      inputTab
    ]

    return (
      <Tab panes={panes}
        id="sidebar-tabs"
        menu={{
          size: "tiny",
          attached: true,
          tabular: true,
          widths: 3,
          inverted: true
        }}/>
    )
  }
}

const mapStateToProps = state => ({
  channel: state.channel,
  settings: state.settings,
  channel_headers: ChannelSelector({...state.settings, confidence_thumbs: state.channel.confidence_thumbs}),
  output_headers: OutputSelector(state.settings),
  input_headers: InputSelector(state.settings)
})

const mapDispatchToProps = dispatch => bindActionCreators({
  activate_channel,
  openApplication,
  startConfidenceMonitors,
  didLoadMonitorImage,
  setChannelIntervals,
  listScheduleSets,
  createScheduleSet,
  applyScheduleSet
}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetBar)
