import React, { PureComponent } from 'react'
import {Modal, Form, Input, Icon, Dropdown, Button} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import TimeInput from 'components/TimeInput/TimeInput'

const repeatOptions = [
    { text: 'Never', value: 'never'},
    { text: 'Daily', value: 'daily',},
    { text: 'Weekly', value: 'weekly',},
];

const timeInputRegex = /^(\d{1,2}):(\d{2}):(\d{2}) ([ap]m)/

// Warning: Modifies date in place!
const addTimeInputStringToDate = (timeString, date) => {
  let timeValue = timeInputRegex.exec(timeString)
  if(!timeValue) {
    return false
  }
  let [hour, minute, second, meridian] = timeValue.slice(1)
  hour = parseInt(hour, 10)
  if(meridian === "am" && hour === 12) {
    hour = 0
  } else if (meridian === "pm" && hour !== 12) {
    hour += 12
  }
  minute = parseInt(minute, 10)
  second = parseInt(second, 10)

  date.setHours(hour, minute, second)
  return true
}

export class InputRecordingModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      startDate: new Date(),
      startTime: '',
      endDate: new Date(),
      endTime: '',
      repeat: 'never',
      errorText: false
    }
  }

  submitEventEditModal = () => {
    let { input_recording, actions } = this.props
    let {startTime, endTime, ...eventData} = this.state
    if(!eventData.name ||
      !addTimeInputStringToDate(startTime, eventData.startDate) ||
      !addTimeInputStringToDate(endTime, eventData.endDate)) {
      this.setState({errorText: true})
      return
    }
    if(input_recording.eventModalType === 'add') {
      actions.addNewEvent(eventData)
    } else if (input_recording.eventModalType === 'edit') {
      actions.editEvent(this.state)
    }
  }

  updateEvent = (data) => {
    this.setState((state) => ({...state, [data.name]: data.value}))
  }

  onClose = () => {
    // Reset state to defaults
    this.setState({
      name: "",
      startTime: "",
      endTime: "",
      startDate: new Date(),
      endDate: new Date(),
      repeat: "never",
      errorText: false
    })
    this.props.actions.eventModalClose()
  }

  render() {
    let { input_recording } = this.props
    let { name, startDate, endDate, repeat, errorText } = this.state

    return (
      <Modal size='small' open={input_recording.eventModalStatus} onClose={this.onClose} closeOnDimmerClick={false}>
        <Modal.Header>Add a New Event</Modal.Header>
            <Modal.Content>
                <Modal.Description style={{marginBottom: '10px'}}>
                    {errorText === true &&
                        <div className="scrollingText__errorText">Please fill all inputs</div>
                    }
                </Modal.Description>
                <Form>
                    <Form.Field>
                        <label>Name</label>
                        <Input
                            placeholder='New Event Name'
                            name="name"
                            value={name}
                            onChange = {(e, data) => {this.updateEvent(data)}}
                            />
                    </Form.Field>
                  <Form.Group widths='equal' style={{margin:0}}>
                    <Form.Field>
                        <label>Start</label>
                        <DatePicker
                            value={startDate}
                            name={"startDate"}
                            inline
                            onChange = {(data) => {this.updateEvent({name: "startDate", value: data})}}
                            />
                        <TimeInput onChange={(value) => {this.updateEvent({name: "startTime", value})}}/>
                    </Form.Field>
                    <Form.Field>
                      <label>End</label>
                          <DatePicker
                              name="finish_date"
                              value={endDate}
                              inline
                              onChange = {(data) => this.updateEvent({name: "endDate", value: data})}
                              />
                          <TimeInput onChange={(value) => {this.updateEvent({name: "endTime", value})}}/>
                        </Form.Field>
                      </Form.Group>
                          <Form.Field>
                              <label>Repeat</label>
                              <Dropdown
                                  placeholder='Repeat'
                                  selection
                                  name="repeat"
                                  options={repeatOptions}
                                  value={repeat}
                                  onChange = {(e, data) => this.updateEvent(data) }
                                  />
                          </Form.Field>
                      </Form>
                  </Modal.Content>
              <Modal.Actions>
                  <div>
                      <Button negative icon labelPosition='right' onClick={this.onClose}>Close<Icon name="close" style={{marginRight: '5px'}}/></Button> 
                      <Button positive icon labelPosition='right' onClick={this.submitEventEditModal}>Save<Icon name="save" style={{marginRight: '5px'}}/></Button>
                  </div>
              </Modal.Actions>
          </Modal>
    )
  }

}

export default InputRecordingModal
