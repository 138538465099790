import React, { PureComponent } from 'react'
import { Icon, TextArea, Button, Modal, Form, Input } from 'semantic-ui-react'

import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import { createTab, changeTab, deleteTab, actions } from 'redux/applications/rss_item_editor'

import tabbedComponent from 'components/higher_order_components/tabbedComponent'
import {openApplication} from 'redux/menu'
import {loadFonts} from 'redux/file_list'

import TextGrid from '../../components/TextGrid/TextGrid';
import TextPreview from '../../components/TextPreview/TextPreview';

import './RSSItemEditor.css';

export class RSSItemEditorApp extends PureComponent {

    constructor(props) {
      super(props)
      let {itemPath, itemName} = props
      props.loadFonts()
      if(itemName) {
        let fullpath = [...itemPath, itemName]
        props.loadRssFeedFile(fullpath.join('/'))
        props.openApplication('RSSItemEditor', `/${fullpath.join('/')}`)
      } else {
        props.openApplication('RSSItemEditor')
      }
    }

    handleChangeInterval = (e, data) => {
      let newValue = parseInt(data.value, 10)
      if(!isNaN(newValue)) {
        this.props.setInterval(newValue)
      }
    }

    handleRenameItem = () => {
      this.props.setTextItemName()
      this.props.saveModalClose()
      if(this.props.waitingToSave) {
        this.props.saveRssFeedFile()
        this.props.setWaitingToSave(false)
      }
    }

    handleCloseRenameModal = () => {
      this.props.saveModalClose()
      if(this.props.waitingToSave) {
        this.props.setWaitingToSave(false)
      }
    }

    render () {
        const { itemName,
          itemNameValue,
          scrollingText,
          scrollingTextPreview,
          style,
          url,
          interval,
          saveModalStatus,
          errorText,
          waitingToSave,
          fonts,
          ...actions } = this.props

        let rss_item_editor = {
          ...style,
          itemName,
          itemNameValue,
          scrollingText,
          scrollingTextPreview,
          url,
          saveModalStatus,
          errorText
        }

        if(!rss_item_editor.scrollingText) {
          rss_item_editor.scrollingText = ''
        }

        let renameText = 'Rename'
        let renameIcon = 'edit'
        if(waitingToSave) {
          renameText = 'Save'
          renameIcon = 'save'
        }

        return (
            <div className="rssItemEditor">
                <div className="rssItemEditor__MainContainer">
                    <div className="rssItemEditor__Row">
                        <div className="rssItemEditor--div">
                            <div className="rssItemEditor--url">
                                <Form>
                                    <Form.Field>
                                        <label>RSS Feed URL</label>
                                        <Input 
                                            onChange={(e, data) => actions.xmlUrl(data.value)}
                                            value={rss_item_editor.url}
                                            className="rssItemEditor--input" />
                                    </Form.Field>
                                    <Form.Group inline>
                                        <Form.Field>
                                            <label>Poll Interval</label>
                                            <Input 
                                                value={interval}
                                                label={{ basic: true, content: 'seconds' }}
                                                labelPosition='right'
                                                type='number'
                                                onChange={this.handleChangeInterval}/>
                                        </Form.Field>
                                        <Form.Field>
                                            <label></label>
                                            <Button
                                                onClick={actions.loadData}
                                                className="rssItemEditor--button"
                                                content='Test Feed' />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </div>
                            <TextArea value={rss_item_editor.scrollingText} readOnly/>
                            <div className="rssItemEditor--previewDiv">
                                <TextPreview properties={rss_item_editor}/>
                            </div>
                        </div>
                        <div className="rssItemEditor--div">
                            <TextGrid properties={rss_item_editor} actions={actions} fonts={fonts}/>
                            <div className="rssItemEditor__buttonGroup">
                                <Button color="green" onClick={actions.saveRssFeedFile}><Icon name="save" style={{marginRight: '5px'}}/>Save</Button>
                                <Button color="green" onClick={actions.saveRssFeedFileAs}>
                                  <Icon.Group style={{marginRight: '5px'}}>
                                    <Icon name="save"/>
                                    <Icon name="pencil" corner/>
                                  </Icon.Group>
                                  Save As
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal size='mini' open={rss_item_editor.saveModalStatus} onClose={actions.saveModalClose}>
                    <Modal.Header>Text Item Name</Modal.Header>
                    <Modal.Content>
                        <Modal.Description style={{marginBottom: '10px'}}>
                            {rss_item_editor.errorText === true &&
                                <div className="rssItemEditor__errorText">Please enter a RSS Feed name</div>
                            }
                            What should I name the RSS Feed?
                        </Modal.Description>
                        <Form onSubmit={this.handleRenameItem}>
                            <Form.Field>
                                <Input 
                                    placeholder='RSS Feed Name'
                                    onChange = {(e, data) => actions.setItemNameValue(data.value) }
                                    />
                            </Form.Field>
                        </Form>                        
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative icon labelPosition='right' onClick={this.handleCloseRenameModal}>Close<Icon name="close" style={{marginRight: '5px'}}/></Button> 
                        <Button positive icon labelPosition='right' onClick={this.handleRenameItem}>{renameText}<Icon name={renameIcon} style={{marginRight: '5px'}}/></Button>
                    </Modal.Actions>
                </Modal>                
            </div>
        );
    };
}

const initialize = (props) => {
  if(props.match.params.path) {
    let {_tabs, _tabData} = props
    let path = decodeURIComponent(props.match.params.path)
    let tabId = Object.entries(_tabData).find(([key, value]) => {
      return value.props.itemPath.join('/') === path
    })
    if(tabId instanceof Array) {
      tabId = tabId[0]
    }
    let tabInd = _tabs.findIndex((tab) => tab === tabId)
    if(tabInd > -1) {
      props._changeTab(tabInd);
    } else {
      props._createTab(path.split('/'));
    }
    return
  }
  if(props._tabs.length === 0) {
    props._createTab();
  }
}

let mapStateToProps = state => ({
    _tabs: state.rss_item_editor.tabs,
    _tabData: state.rss_item_editor.tabData,
    _activeTab: state.rss_item_editor.activeTab,
    _initialize: initialize,
    _tabActions: actions,
    fonts: state.file_list.fonts,
    state,
})

let mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators({
      _changeTab: changeTab,
      _createTab: createTab,
      _deleteTab: deleteTab,
      openApplication,
      loadFonts
    }, dispatch),
    _dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(tabbedComponent(RSSItemEditorApp))
