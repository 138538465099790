// This file is reponsible for returning a boolean value which will 
// help in returning a "Basic" or "Advanced" list of settings.

// This is used in ServiceSettings, SettingPanel, SettingsGroup and SettingsArray

const SETTINGS_VIEW = Symbol('settings view')

export const initialState = {
    basicView: true
}

export const settingsView = (view) => {
    return {
        type: SETTINGS_VIEW,
        payload: view
    }
}

// Reducer
export default (state = initialState, action) => {
    switch(action.type){
        case SETTINGS_VIEW: 
            return {
                ...state,
                basicView: action.payload,
            }

        default: return state;
    }
}