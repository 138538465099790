import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import tabbedComponent from 'components/higher_order_components/tabbedComponent'
import Library from 'containers/components/LibraryComponent'
import {changeTab, createTab, deleteTab} from 'redux/applications/library'
import {libraryActions} from 'redux/file_list'
import {actions as settingsActions} from 'redux/settings'
import {openApplication} from 'redux/menu'
import {messages} from 'redux/messages'
import {Menu, Icon, Image, Dropdown} from 'semantic-ui-react'
import {slashesToUnicodeSlashes} from 'helpers/library_helpers'

import {staticAsset} from 'helpers/net_helpers'
import {encodeURIFilepath} from 'helpers/general_helpers'

import './Library.css'

const TRASH_BIN = ['mnt', 'main', 'tv', 'trash']

class LibraryApp extends PureComponent {

  componentDidUpdate(prevprops) {
    if (prevprops.navPath === this.props.navPath &&
      prevprops.match.params.path !== this.props.match.params.path) {
      let {path} = this.props.match.params
      if(path) {
        path = decodeURIComponent(path)
        this.props.libraryNavigate(path.split('/'))
      }
    }
  }

  // Update function to get state from child component
  libraryUpdate = (selectedFiles=null, navPath=null) => {
    let {libraryFileSelect, libraryNavigate, openApplication} = this.props
    if(selectedFiles) {
      libraryFileSelect(selectedFiles)
    }
    if(navPath) {
      openApplication('Library', `/${encodeURIFilepath(navPath).join('/')}`)
      libraryNavigate(navPath)
    }
  }

  // Handler for copy button
  handleFileCopy = () => {
    this.props.grabFiles('copy', this.props.selectedFiles)
    this.props.libraryFileDeselect()
  }

  // Handler for cut button
  handleFileCut = () => {
    this.props.grabFiles('move', this.props.selectedFiles)
    this.props.libraryFileDeselect()
  }

  // Handler for paste button
  handleFilePaste = async () => {
    let {clipboard} = this.props.file_list
    let {navPath} = this.props
    if(clipboard.mode === 'copy') {
      await this.props.copyFiles(clipboard.files.map(file => [file, navPath.concat(file[file.length - 1])]))
    } else if(clipboard.mode === 'move') {
      await this.props.moveFiles(clipboard.files.map(file => [file, navPath.concat(file[file.length - 1])]))
    }
    this.props.grabFiles('', [])
    this.props.loadFileData(navPath, {forceLoading: true})
  }

  // Handler for creating directories
  handleCreateFolder = () => {
    let {navPath} = this.props
    this.props.prompt('What do you want the directory to be called?', (dirName) => {
      if(dirName !== null) {
        this.props.createFolder(navPath, dirName)
      }
    }, {initialValue: 'NEW_FOLDER'})
  }

  // Handler for opening a new file in the current directory
  handleNewFile = (type) => {
    let {navPath} = this.props
    let application = null
    switch(type) {
      case 'schedule':
        application = 'Schedule'
        break
      case 'project':
        application = 'Playlist'
        break
      case 'playlist':
        application = 'SimplePlaylist'
        break
      case 'text item':
        application = 'ScrollingText'
        break
      case 'rss feed':
        application = 'RSSItemEditor'
        break
      default:
        break
    }
    this.props.prompt(`What do you want the ${type} to be called?`, (filename) => {
      if(filename !== null && application !== null) {
        let xfilename = slashesToUnicodeSlashes(filename)
        let path = `/${navPath.join('/')}/${xfilename}`
        this.props.openApplication(application, path)
      }
    })
  }

  // Handler for delete button
  handleFileDelete = () => {
    let {selectedFiles, libraryFileDeselect, confirmThenDeleteFiles, navPath} = this.props
    confirmThenDeleteFiles(selectedFiles)
    .then(() => {
      if(selectedFiles.find(path => path.join("/") === navPath.join("/"))) {
        this.libraryUpdate(null, navPath.slice(0, -1))
      }
      libraryFileDeselect()
    })
  }

  // Handler for refresh button
  handleRefresh = () => {
    let {loadFileData, navPath} = this.props
    loadFileData(navPath, {forceLoading: true});
  }

  // if any forbidden files are selected
  containsUnselectable = (selectedFiles,what) => {
    let path,res = false

    selectedFiles.forEach((patha) => {
      if (patha instanceof Array)
        path = patha.join('/')
      else
        path = patha

      if (path === "mnt/main/tv")
        res = true
    })

    return res
  }

  render() {
    let {file_list,
      match,
      loadFileData,
      setSearchNext,
      libraryNavigate,
      selectedFiles,
      appAccess,
      openApplication,
      ...library} = this.props
    let {deselect} = library

    let initialPath = library.navPath

    return (
      <div id='libraryApplicationPane'>
        <Menu id='library-navbar' fluid size='tiny' icon='labeled' widths={9} attached='top'>
          <Menu.Item title="Refresh" onClick={this.handleRefresh}>
            <Icon name='refresh'/>
            Refresh
          </Menu.Item>
          <Dropdown trigger={
              <Menu.Item fitted>
                <Icon name='add'/>
                Create
              </Menu.Item>
            }
            item
            icon={null}
            title="Create File/Folder">
            <Dropdown.Menu>
              <Dropdown.Item text="Folder" icon='folder' onClick={this.handleCreateFolder}/>
              <Dropdown.Item text="Schedule" onClick={() => {this.handleNewFile('schedule')}} icon={
                <Icon className='svg-icon'>
                  <Image src={staticAsset("/icons/schedule_black.svg")}/>
                </Icon>
              }/>
              <Dropdown.Item text="Cuts Project" onClick={() => {this.handleNewFile('project')}} icon={
                <Icon className='svg-icon'>
                  <Image src={staticAsset("/icons/castus_cuts_black.svg")}/>
                </Icon>
              }/>
              <Dropdown.Item text="Playlist" onClick={() => {this.handleNewFile('playlist')}} icon={
                <Icon className='svg-icon'>
                  <Image src={staticAsset("/icons/simpleplaylist_black.svg")}/>
                </Icon>
              }/>
              <Dropdown.Item text="Text Item" onClick={() => {this.handleNewFile('text item')}} icon={
                <Icon className='svg-icon'>
                  <Image src={staticAsset("/icons/scrollingtext_black.svg")}/>
                </Icon>
              }/>
              <Dropdown.Item text="RSS Feed" onClick={() => {this.handleNewFile('rss feed')}} icon={
                <Icon className='svg-icon'>
                  <Image src={staticAsset("/icons/rssfeed_black.svg")}/>
                </Icon>
              }/>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item title='Delete' onClick={this.handleFileDelete} disabled={selectedFiles.length === 0 || this.containsUnselectable(selectedFiles,'delete')}>
            <Icon name='remove'/>
            Delete
          </Menu.Item>
          <Menu.Item title='Move' onClick={this.handleFileCut} disabled={selectedFiles.length === 0 || this.containsUnselectable(selectedFiles,'cut')}>
            <Icon name='cut'/>
            Move
          </Menu.Item>
          <Menu.Item title='Copy' onClick={this.handleFileCopy} disabled={selectedFiles.length === 0 || this.containsUnselectable(selectedFiles,'copy')}>
            <Icon name='copy'/>
            Copy
          </Menu.Item>
          <Menu.Item title='Paste' onClick={this.handleFilePaste} disabled={file_list.clipboard.files.length === 0 || this.containsUnselectable(selectedFiles,'paste')}>
            <Icon name='paste'/>
            Paste
          </Menu.Item>
          <Menu.Item title='Upload Here' onClick={() => {openApplication("Upload", `/${library.navPath.join('/')}`, "?to=")}}>
            <Icon className='svg-icon'>
              <Image src={staticAsset("/icons/upload_black.svg")}/>
            </Icon>
            Upload
          </Menu.Item>
          <Menu.Item title='Browse Trash' onClick={() => {libraryNavigate(TRASH_BIN)}}>
            <Icon name='trash'/>
            Browse Trash
          </Menu.Item>
          <Menu.Item title='Change View Mode' onClick={() => {library.libraryViewMode(library.viewMode === 'tile' ? 'list' : 'tile')}}>
            <Icon name={library.viewMode === 'tile' ? 'list layout' : 'grid layout'}/>
            {library.viewMode === 'tile' ? 'List View' : 'Tile View'}
          </Menu.Item>
        </Menu>
        <div id='libraryFileList'>
          <Library update={this.libraryUpdate}
            deselect={deselect}
            overridePath={initialPath}
            viewMode={library.viewMode}/>
        </div>
      </div>
    )
  }
}

const initialize = (props) => {
  if(props.match.params.path) {
    let {_tabs, _tabData} = props
    let path = props.match.params.path
    let tabId = Object.entries(_tabData).find(([key, value]) => {
      return value.props.navPath.join('/') === path
    })
    if(tabId instanceof Array) {
      tabId = tabId[0]
    }
    let tabInd = _tabs.findIndex((tab) => tab === tabId)
    if(tabInd > -1) {
      props._changeTab(tabInd);
    } else {
      props._createTab(path.split('/'));
    }
    return
  }
  if(props._tabs.length === 0) {
    props._createTab();
  }
}

const mapStateToProps = state => {
  return {
    _tabs: state.library.tabs,
    _tabData: state.library.tabData,
    _activeTab: state.library.activeTab,
    _initialize: initialize,
    file_list: state.file_list,
    active_channel: state.channel.active_channel,
    appAccess: state.server.appAccess
  }
}

const mapDispatchToProps = dispatch => ({...(bindActionCreators({
    ...libraryActions,
    openApplication,
    saveServiceSettings: settingsActions.saveServiceSettings,
    ...messages,
    _changeTab: changeTab,
    _createTab: createTab,
    _deleteTab: deleteTab
  }, dispatch)),
  _dispatch: dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(tabbedComponent(LibraryApp))
