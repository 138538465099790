import React, {PureComponent} from 'react'

import './TimeIndicatorBar.css'

export default class TimeIndicatorBar extends PureComponent {

  render() {
    let {pixelMargin, level, startMargin} = this.props
    let transparent = 'rgba(0, 0, 0, 0)'
    let bottom = '0'
    switch(level) {
      case 0:
        bottom = '60%'
        break
      case 1:
        bottom = '75%'
        break
      case 2:
        bottom = '85%'
        break
      default:
        break
    }
    let style = {
      backgroundImage: `linear-gradient(to right, ${transparent}, ${transparent} 1px, black 2px, black 3px, ${transparent} 1px, ${transparent} 100%)`,
      backgroundSize: `${pixelMargin}px 100%`,
      bottom,
      left: `${startMargin}px`
    }
    return (
      <div className="timeMarkerBar" style={style}/>
    )
  }

}
