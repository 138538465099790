import undoable, {includeAction} from 'redux-undo'
import tabbedReducer from 'redux/higher_order_reducers/tabbedReducer'
import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'
import LibraryManager from 'redux/higher_order_reducers/libraryManager'
import {saveFile, saveMetadata} from 'redux/file_list'
import {messages} from 'redux/messages'
import {getIn, setIn} from 'helpers/general_helpers'
import {fetchFromServer, fetchFileFromServer} from 'helpers/net_helpers'
import {fileTypeInfo, dataPath} from 'helpers/library_helpers'
import {createFrame,
  removeFrame,
  addClipToFrame,
  removeClipFromFrame,
  modifyClip} from 'helpers/frame_helpers'
import {parsePlaylistJSON,
  serializePlaylist,
  isColliding,
  millisecondsToPixels,
  pixelsToMilliseconds,
  getClipAtTime,
  framesToMilliseconds} from 'helpers/playlist_helpers'
import SettingsSelector from 'selectors/SettingsSelector'

const CutsLibraryManager = new LibraryManager()

//NOTE: If you add more actions and you want them to be undoable/redoable,
//make sure you add them to the undoable includeAction param at the bottom of this file.
export const LOAD_PLAYLIST = Symbol('load playlist')
export const ADD_FRAME = Symbol('add new frame')
export const REMOVE_FRAME = Symbol('remove frame')
export const MOVE_FRAME = Symbol('move frame')
export const RESIZE_FRAME = Symbol('resize frame')
export const REORDER_FRAME = Symbol('reorder frame')
export const ADD_FRAME_CLIP = Symbol('add frame clip')
export const MOVE_FRAME_CLIP = Symbol('move frame clip')
export const REMOVE_FRAME_CLIP = Symbol('remove frame clip')
export const MODIFY_FRAME_CLIP = Symbol('modify frame clip')
export const CUT_FRAME_CLIP = Symbol('cut frame clip')
export const SET_FRAME_NAME = Symbol('set frame name')
export const SET_FRAME_OPTIONS = Symbol('set frame options')
export const SET_SNAP_TO_GRID = Symbol('set snap to grid')
export const SET_ZOOM_LEVEL = Symbol('change zoom level')
export const SET_PREVIEW_TIME = Symbol('set view time')
export const SET_TIMELINE_VIEW_START = Symbol('set timeline view start')
export const SET_PLAYLIST_SCROLL_END = Symbol('set playlist scroll end')
export const SET_PREVIEW_INDICATOR_FOLLOW = Symbol('set preview indicator follow')
export const SET_MAGNETIC = Symbol('set magnetic')
export const PLAYLIST_LIBRARY_SELECT = Symbol('playlist library select')
export const SELECT_PLAYLIST_TOOL = Symbol('select playlist tool')
export const SET_ASPECT_RATIO = Symbol('set aspect ratio')
export const SET_FRAME_RATE = Symbol('set frame rate')
export const SET_ENFORCE_ASPECT_RATIO = Symbol('set enforce aspect ratio')
export const SET_SKIP_MISSING_EXPIRED = Symbol('set skip missing expired')
export const SET_RANDOMIZE = Symbol('set randomize')
export const SET_OVERRIDE_COMING_UP_NEXT = Symbol('set override coming up next')
export const SELECT_FRAME = Symbol('select frame')
export const DESELECT_ALL_FRAMES = Symbol('deselect all frames')
export const SELECT_FRAME_CLIP = Symbol('select frame clip')
export const SELECT_TIMELINE_RANGE = Symbol('select timeline range')
export const DESELECT_FRAME_CLIPS = Symbol('deselect frame clips')
export const CLIPBOARD_SELECTED_CLIPS = Symbol('clipboard selected clips')
export const PASTE_CLIPS_AT_PREVIEW = Symbol('paste clips at preview')
export const REMOVE_SELECTED_CLIPS = Symbol('remove selected clips')
export const TOGGLE_PLAYLIST_PLAYING = Symbol('toggle playlist playing')
export const SET_MAIN_REGION = Symbol('set main region')
export const RENAME_PLAYLIST = Symbol('rename playlist')

export const PREVIEW_TICK = Symbol('preview tick')
export const SET_IS_FETCHING = Symbol('set is fetching')
export const FILL_PREVIEW_FRAME = Symbol('fill preview frame')
export const NO_THUMBNAIL = Symbol('no thumbnail')
export const RELOAD_PREVIEW_BUFFER = Symbol('reload preview buffer')

const PREVIEW_FRAME_LIMIT = 1
const PREVIEW_FRAME_RATE = 4

export const PLAYLIST_UNDO = Symbol('playlist undo')
export const PLAYLIST_REDO = Symbol('playlist redo')

export const PLAYLIST_CHANGE_TAB = Symbol('playlist change tab')
export const PLAYLIST_CREATE_TAB = Symbol('playlist create tab')
export const PLAYLIST_DELETE_TAB = Symbol('playlist delete tab')

/*
const TEST_FRAME_DATA = [
  {
    name: 'Test Frame 1',
    options: {},
    position: {
      x: 25,
      y: 25
    },
    size: {
      width: 50,
      height: 50,
    },
    timeline: [
      {
        item: 'Patern_test.jpg',
        start: 1000,
        end: 3000
      },
      {
        item: 'coolracecar.jpg',
        start: 6000,
        end: 12000
      }
    ]
  },
  {
    name: 'Test Frame 2',
    options: {},
    position: {
      x: 0,
      y: 0
    },
    size: {
      width: 25,
      height: 25,
    },
    timeline: [
      {
        item: 'thesun.jpg',
        start: 0,
        end: 120000
      },
      {
        item: 'themoon.jpg',
        start: 120000,
        end: 240000
      }
    ]
  },
]
*/

export const MOVE_TOOL = 0;
export const CUT_TOOL = 1;
export const TOOLS = [
  {
    id: 'move',
    name: "Move",
    icon: "hand paper"
  },
  {
    id: 'cut',
    name: 'Cut',
    icon: 'cut'
  }
]

export const actions = {

  /**
   * Loads a playlist from the given path
   * @param {string} path The path of the playlist to be loaded
   */
  loadPlaylist: (path) => {
    return async (dispatch, getState) => {
      let jobId = dispatch(loading.startLoading(getState().local._loaderID))
      let data = await fetchFileFromServer(path);
      if(!data.ok) {
        dispatch(loading.finishLoading(getState().local._loaderID, jobId))
        return
      }
      data = await data.text();
      data = JSON.parse('{' + data + '}');
      if(data['multiregion playlist description']) {
        data = data['multiregion playlist description']
      } else if (data['playlist description']) {
        data = data['playlist description']
        data.sections = [{
          list: data.list
        }]
      }
      dispatch(loading.finishLoading(getState().local._loaderID, jobId))
      dispatch({
        type: LOAD_PLAYLIST,
        payload: {
          data,
          path
        }
      })
    }
  },

  /**
   * Saves the currently edited playlist to the server
   */
  savePlaylist: (rename=false) => {
    return async (dispatch, getState) => {
      let {playlistPath, playlistName} = getState().local.present
      let name = playlistName
      let dest = playlistPath
      if(!name || rename) {
        let value = await dispatch.global(messages.promptAsync("Save Project", {
          type: 'path',
          validate: (result) => (result.filename !== ''),
          invalidText: 'That is not a valid project name.',
          initialValue: {filename: name, directory: dest}
        }))
        if(value === null) {
          return
        }
        name = value.filename
        dest = value.directory
      }
      if(!name) {
        dispatch.global(messages.alert("Error Saving Project! Somehow, the project was trying to save without having a name.", {level: 'error'}))
        return
      }
      let playlists = getIn(getState().global.file_list.fileData, dataPath(playlistPath, true))
      if(playlists) {
        playlists = Object.keys(playlists)
      }
      if(playlists.includes(name)) {
        let overwrite = await dispatch.global(messages.confirmAsync(`${name} already exists. Should it be overwritten?`))
        if(!overwrite) {
          return
        }
      }
      if(name !== playlistName || dest !== playlistPath) {
        dispatch(actions.renamePlaylist(name, dest))
      }
      let playlist = serializePlaylist(getState().local.present)
      let duration = playlist.duration;
      playlist = '"multiregion playlist description":' + JSON.stringify(playlist, null, 2)
      dest = dest.join('/')
      if(!dest) {
        dest = 'mnt/main/Projects'
      }
      let source = new File([playlist], name, {type: 'text/plain'})
      let jobId = dispatch(loading.startLoading(getState().local._loaderID))
      dispatch.global(saveFile(source, dest, {createMetadata: true,
        onSuccess: () => {
          let fullpath = dest.split('/').concat([name])
          getState().local.lastSaved = getState().local.past[getState().local.past.length - 1]
          dispatch.global(saveMetadata({duration, 'file type': 'application/x-castus-multiregion-playlist'}, fullpath)).then(() => {
            dispatch(loading.finishLoading(getState().local._loaderID, jobId))
            dispatch.global(messages.alert(`${name} successfully saved! It was saved at /${dest}`, {level: "success"}))
          })
        },
        onError: (res) => {
          dispatch(loading.finishLoading(getState().local._loaderID, jobId))
          res.test().then((err) => {
            dispatch.global(messages.alert(`There was an error saving the playlist ${name}: ${err}`, {level: "error"}))
          })
        }}
      ));
    }
  },

  /**
   * Adds a new frame to the end of the list of frames.
   * @param {object} frame Optional object containing any parameters to set on the newly created frame.
   *  Any parameters that are not specified will be given default values.
   */
  addFrame: (frame={}) => ({
    type: ADD_FRAME,
    payload: frame
  }),

  /**
   * Removes a frame from the list of frames.
   * @param {number} index The index of the frame to be removed within the list of frames
   */
  removeFrame: (index) => ({
    type: REMOVE_FRAME,
    payload: index
  }),

  /**
   * Changes a frame's position
   * @param {number} index The index of the frame to act on
   * @param {number} x The new x coordinate of the frame, relative to the upper-left corner of the frame editor
   * @param {number} y The new y coordinate of the frame, relative to the upper-left corner of the frame editor
   */
  moveFrame: (index, x, y) => ({
    type: MOVE_FRAME,
    payload: {
      index,
      x,
      y
    }
  }),

  /**
   * Changes a frame's size
   * @param {number} index The index of the frame to act on
   * @param {number} width The new width of the frame in percent of screen size
   * @param {number} height The new height of the frame in percent of screen size
   */
  resizeFrame: (index, width, height) => ({
    type: RESIZE_FRAME,
    payload: {
      index,
      width,
      height
    }
  }),

  /**
   * Changes a frame's position in the frame list
   * @param {number} index The current index of the frame to move
   * @param {number} new_index The index to move the frame to
   */
  reorderFrame: (index, new_index) => ({
    type: REORDER_FRAME,
    payload: {
      index,
      new_index
    }
  }),

  /**
   * Adds a new clip to a frame
   * @param {number} index The index of the frame to add a clip to
   * @param {object} clip Optional parameter, object containing any initial values for the clip.
   *  If any initial values are not provided, defaults will be used.
   */
  addFrameClip: (index, clip={}) => ({
    type: ADD_FRAME_CLIP,
    payload: {
      index,
      clip
    }
  }),

  /**
   * Moves a clip to another time and/or another frame
   * @param {number} index The index of the frame containing the clip to be moved
   * @param {number} clip_index The index of the clip to move
   * @param {number} new_time Optional parameter, the new start time for the clip in milliseconds
   * @param {number} new_frame Optional parameter, the index of the frame to move the clip to.
   *  Passing -1 or an index greater beyond the length of the frame array will create a new frame instead
   */
  moveFrameClip: (index, clip_index, new_time=null, new_frame=null) => ({
    type: MOVE_FRAME_CLIP,
    payload: {
      index,
      clip_index,
      new_time,
      new_frame
    }
  }),

  /**
   * Cuts a clip into two other clips on a given time
   * @param {number} index The index of the frame containing the clip to be cut
   * @param {number} clip_index The index of the clip to cut
   * @param {number} cut_time The time to cut the clip on (absolute time, NOT relative to the start of the clip)
   */
  cutFrameClip: (index, clip_index, cut_time) => ({
    type: CUT_FRAME_CLIP,
    payload: {
      index,
      clip_index,
      cut_time
    }
  }),

  /**
   * Removes a clip from a frame
   * @param {number} index The index of the frame to remove a clip from
   * @param {number} clip_index The index of the clip within the frame's timeline
   */
  removeFrameClip: (index, clip_index) => ({
    type: REMOVE_FRAME_CLIP,
    payload: {
      index,
      clip_index
    }
  }),

  /**
   * Changes a clip's properties
   * @param {number} index The index of the frame to change a clip within
   * @param {number} clip_index The index of the clip within the frame's timeline
   * @param {object} properties An object containing changes to the clip's properties,
   *  which will be applied on top of the clip's existing properties
   */
  modifyFrameClip: (index, clip_index, properties) => ({
    type: MODIFY_FRAME_CLIP,
    payload: {
      index,
      clip_index,
      properties
    }
  }),

  /**
   * Changes a frame's name
   * @param {number} index The index of the frame to rename
   * @param {string} name The new name for the frame
   */
  setFrameName: (index, name) => ({
    type: SET_FRAME_NAME,
    payload: {
      index,
      name
    }
  }),

  /**
   * Adds, changes, or removes properties from a frame's options
   * @param {number} index The index of the frame to be changed
   * @param {object} options Optional param. The new options values, which will be merged into the frame's current options object.
   * @param {array} delete_keys Optional param. An array of keys to be removed from the frame's options. They will be removed
   *  after the passed in options have been merged
   */
  setFrameOptions: (index, options={}, delete_keys=[]) => ({
    type: SET_FRAME_OPTIONS,
    payload: {
      index,
      options,
      delete_keys
    }
  }),

  /**
   * Sets whether frames in the frame editor should snap to a grid during move/resize or not
   * @param {boolean} value Whether to snap to grid or not
   */
  setSnapToGrid: (value) => ({
    type: SET_SNAP_TO_GRID,
    payload: value
  }),

  /**
   * Sets the zoom level of the playlist timeline
   * @param {number} value New zoom level, in number of pixels used to represent one second (floating point values ok)
   */
  setZoomLevel: (value) => ({
    type: SET_ZOOM_LEVEL,
    payload: value
  }),

  /**
   * Sets the current preview time of frames (for scrubbing)
   * @param {number} value New preview time, in milliseconds
   */
  setPreviewTime: (value) => ({
    type: SET_PREVIEW_TIME,
    payload: value
  }),

  /**
   * Sets the what time the currently viewed segment of the timeline starts at
   * @param {number} value New timeline view start, in milliseconds
   */
  setTimelineViewStart: (value) => ({
    type: SET_TIMELINE_VIEW_START,
    payload: value
  }),

  /**
   * Sets what time the playlist viewer should be able to scroll to
   * @param {number} value The maximum time to scroll to, in milliseconds
   */
  setPlaylistScrollEnd: (value) => ({
    type: SET_PLAYLIST_SCROLL_END,
    payload: value
  }),

  /**
   * Sets whether the preview time is being set based on the mouse cursor or not
   * @param {boolean} value If true, preview time will change based on the time indicator tracking the mouse's x position
   */
  setPreviewIndicatorFollow: (value) => ({
    type: SET_PREVIEW_INDICATOR_FOLLOW,
    payload: value
  }),

  /**
   * Sets whether clips and indicators will stick to nearby clips/indicators or not
   * @param {boolean} value If true, clips/indicators will stick
   */
  setMagnetic: (value) => ({
    type: SET_MAGNETIC,
    payload: value
  }),

  /**
   * Sets the currently selected library file
   * @param {array} selected The array of selected files from the library component
   */
  playlistLibrarySelect: (selected) => ({
    type: PLAYLIST_LIBRARY_SELECT,
    payload: selected
  }),

  /**
   * Sets the currently selected tool
   * @param {number/string} identifier If a string, will select tool with that string name. If a number, will select tool
   *  from TOOLS with that number index. All other parameters will be ignored.
   */
  selectPlaylistTool: (identifier) => ({
    type: SELECT_PLAYLIST_TOOL,
    payload: identifier
  }),

  promptAspectRatio: () => {
    return (dispatch, getState) => {
      dispatch.global(messages.prompt('Enter custom aspect ratio.', (result) => {
        if(result) {
          dispatch(actions.setAspectRatio(result))
        }
      }, {type: 'aspectRatio', invalidText: 'Invalid Aspect Ratio.'}))
    }
  },

  /**
   * Sets the playlist's aspect ratio
   * @param {array} value An array of two numbers where the first number is the aspect width and the second is the aspect height
   */
  setAspectRatio: (value) => ({
    type: SET_ASPECT_RATIO,
    payload: value
  }),

  promptFrameRate: (placeholder=60) => {
    return (dispatch, getState) => {
      dispatch.global(messages.prompt('Enter custom frame rate.', (result) => {
        if(result) {
          dispatch(actions.setFrameRate(result))
        }
      }, {type: 'number',
        invalidText: 'Invalid Frame Rate. Please ensure it is a number greater than 0.',
        initialValue: placeholder,
        decimal: true
      }))
    }
  },

  /**
   * Sets the frame rate of the playlist
   * @param {number} value The new framerate for the playlist as a floating point number
   */
  setFrameRate: (value) => ({
    type: SET_FRAME_RATE,
    payload: value
  }),

  /**
   * Sets whether aspect ratio should be enforced or not
   * @param {bool} value The new value for this toggle
   */
  setEnforceAspectRatio: (value) => ({
    type: SET_ENFORCE_ASPECT_RATIO,
    payload: value
  }),

  /**
   * Sets whether missing and expired items should be skipped or not
   * @param {bool} value The new value for this toggle
   */
  setSkipMissingExpired: (value) => ({
    type: SET_SKIP_MISSING_EXPIRED,
    payload: value
  }),

  /**
   * Sets whether items should be randomized or not
   * @param {bool} value The new value for this toggle
   */
  setRandomize: (value) => ({
    type: SET_RANDOMIZE,
    payload: value
  }),

  /**
   * Sets whether coming up next should be overridden or not
   * @param {bool} value The new value for this toggle
   */
  setOverrideComingUpNext: (value) => ({
    type: SET_OVERRIDE_COMING_UP_NEXT,
    payload: value
  }),

  /**
   * Selects a single clip in the timeline editor
   * @param {number} frame The index number of the frame that the clip belongs to
   * @param {number} clip The index of the clip within the timeline of its parent frame
   */
  selectFrameClip: (frame, clip) => ({
    type: SELECT_FRAME_CLIP,
    payload: {
      frame,
      clip
    }
  }),

  /**
   * Selects a frame in the editor to constrain editing to
   * @param {number} frame The index number of the frame that the clip belongs to
   * @param {[boolean=false]} exclusive If true, select only the given frame and deselect any others
   */
  selectFrame: (frame, exclusive=false) => ({
    type: SELECT_FRAME,
    payload: {frame, exclusive}
  }),

  /**
   * Deselects all currently selected frames
   */
  deselectAllFrames: () => ({
    type: DESELECT_ALL_FRAMES
  }),

  /**
   * Selects all clips in multiple frames that overlap with a given time range
   * @param {number} start The start of the time range to select, in milliseconds
   * @param {number} end The end of the time range to select, in milliseconds
   * @param {number} frameStart The index of the frame to start selecting from
   * @param {number} frameEnd The index of the frame to end selecting at (will select from this frame)
   */
  selectTimelineRange: (start, end, frameStart, frameEnd) => ({
    type: SELECT_TIMELINE_RANGE,
    payload: {
      start,
      end,
      frameStart,
      frameEnd
    }
  }),

  /**
   * Deselects all currently selected clips
   */
  deselectFrameClips: () => ({
    type: DESELECT_FRAME_CLIPS
  }),

  clipboardSelectedClips: () => ({
    type: CLIPBOARD_SELECTED_CLIPS
  }),

  pasteClipsAtPreview: () => {
    return (dispatch, getState) => {
      let {clipboard,
        previewTime,
        frames} = getState().local.present
      if(clipboard.length <= 0) {
        return
      }
      let valid = true
      clipboard.forEach(([frame, clip]) => {
        let copyClip = {
          ...clip,
          start: previewTime + clip.start,
          end: previewTime + clip.end
        }
        if(!isColliding(frames[frame].timeline, copyClip.start, copyClip.end - copyClip.start)) {
          frames = addClipToFrame(frame, frames, copyClip)
        } else {
          valid = false
        }
      })
      if(!valid) {
        dispatch.global(messages.alert("One or more clips were not pasted, as they would have been colliding with pre-existing clips", {level: 'error'}))
      }
      dispatch({
        type: PASTE_CLIPS_AT_PREVIEW,
        payload: frames
      })
    }
  },

  removeSelectedClips: () => ({
    type: REMOVE_SELECTED_CLIPS
  }),

  togglePlaylistPlaying: () => {
    return (dispatch, getState) => {
      let state = getState().local.present
      if(state.playInterval === null) {
        let intervalID = setInterval(() => {
          dispatch(actions.previewTick())
        }, framesToMilliseconds(1, PREVIEW_FRAME_RATE))
        dispatch({
          type: TOGGLE_PLAYLIST_PLAYING,
          payload: intervalID,
        })
      } else {
        clearInterval(state.playInterval)
        dispatch({
          type: TOGGLE_PLAYLIST_PLAYING,
          payload: null,
        })
      }
    }
  },

  setMainRegion: (index) => ({
    type: SET_MAIN_REGION,
    payload: index - 1
  }),

  renamePlaylist: (name, dir) => ({
    type: RENAME_PLAYLIST,
    payload: {
      name,
      dir
    }
  }),

  previewTick: () => ({
    type: PREVIEW_TICK
  }),

  checkPreviewFrames: () => {
    return (dispatch, getState) => {
      let {previewTime, frameThumbBuffer, previewFollow} = getState().local.present
      let previewGap = framesToMilliseconds(1, PREVIEW_FRAME_RATE)
      for(let i = 0; i < PREVIEW_FRAME_LIMIT; i++) {
        let checkTime = previewTime + (i * previewGap)
        if(i > 0) {
          if(previewFollow) {
            break
          }
          checkTime -= (checkTime % framesToMilliseconds(1, PREVIEW_FRAME_RATE))
        }
        if(!frameThumbBuffer[checkTime]) {
          dispatch(actions.fetchPreviewFrames(checkTime));
        }
      }
    }
  },

  checkClipTimes: (times) => {
    return (dispatch, getState) => {
      let {frameThumbBuffer} = getState().local.present
      for(let t of times) {
        if(!frameThumbBuffer[t]) {
          dispatch(actions.fetchPreviewFrames(t));
        }
      }
    }
  },

  fetchPreviewFrames: (time) => {
    return (dispatch, getState) => {
      dispatch(actions.setIsFetching(true))
      let {frames} = getState().local.present
      frames.forEach((frame, ind) => {
        let clip = getClipAtTime(frame, time)
        if(!clip) {
          dispatch(actions.fillPreview(time, ind, null))
          return
        }
        let baseTime = time - clip.start
        if(clip.in) {
          baseTime += clip.in
        }
        let clipPath = clip.item.join('/')
        if(!clip.data) {
          dispatch(actions.fillPreview(time, ind, null))
          return
        }
        let w = ''
        let h = ''
        if(fileTypeInfo(clip.data['file type']).indeterminateSize) {
          let {aspectRatio} = getState().local.present
          let height = 240
          let width = Math.floor(height / aspectRatio[1]) * aspectRatio[0]
          w = `&w=${(frame.size.width / 100) * width}`
          h = `&h=${(frame.size.height / 100) * height}`
        }
        fetchFromServer(`/v2/files/grab-thumbnail/${clipPath}?t=${baseTime / 1000}${w}${h}`).then((res) => {
          if(!res.ok) {
            dispatch(actions.fillPreview(time, ind, null))
            return
          }
          res.blob().then((thumb) => {
            if(thumb.size === 0) {
              dispatch(actions.fillPreview(time, ind, null))
              return
            }
            dispatch(actions.fillPreview(time, ind, thumb))
          })
        })
      })
    }
  },

  fillPreview: (time, frame, thumb) => ({
    type: FILL_PREVIEW_FRAME,
    payload: {
      what: thumb,
      where: frame,
      when: time
    }
  }),

  setIsFetching: (isFetching) => ({
    type: SET_IS_FETCHING,
    payload: isFetching
  }),

  reloadPreviewBuffer: () => ({
    type: RELOAD_PREVIEW_BUFFER
  }),

  /**
   * Undo playlist actions
   */
  playlistUndo: () => ({type: PLAYLIST_UNDO}),

  /**
   * Redo playlist actions
   */
  playlistRedo: () => ({type: PLAYLIST_REDO}),

  libraryUpdate: CutsLibraryManager.libraryUpdate,

}

export const initialState = {
  /**
   * Array of frame objects, in order of z-index
   * Frame objects have the following properties:
   * {object} position Object containing x/y position
   * {number} position.x horizontal position of frame's upper left corner
   * {number} position.y vertical position of frame's upper left corner
   * {object} size Object containing height/width values
   * {number} size.width frame width in percent of screen size
   * {number} size.height frame height in percent of screen size
   * {string} name The name of the frame
   * {object} options Any options attached to the frame by the frame editor or its parent
   * {string} display A string indicating what is to be displayed in this frame
   * {array} timeline An array of clip objects that occur within this frame
   * {object} timeline[#] A single clip object is defined with the following properties
   * {array} timeline[#].item Array path of item this clip refers to
   * {object} timeline[#].data An object containing any data about the clip that should be kept for the playlist file, but is not used by the editor
   * {number} timeline[#].start The start time of the item in milliseconds
   * {number} timeline[#].in The time into the item that the clip should start
   * {number} timeline[#].end The end time of the item in milliseconds
   */
  frames: [],
  isFetching: false,                                    // Whether preview thumbnails are being fetched or not
  frameThumbBuffer: {},                                 // Map of times and of thumbnails at those times to be rendered when playing playlist
  playlistPath: ['mnt', 'main', 'Projects'],           // The path of the directory containing the playlist to edit
  playlistName: '',           // The name of the playlist being edited
  snapToGrid: false,          // Whether frames should snap to a grid when resizing/moving or not
  previewTime: 5000,          // The current time being viewed in milliseconds, for time scrubbing
  timelineViewStart: 0,       // The starting time of the time segment being viewed in the timeline
  zoomLevel: 100,             // Zoom level of timeline, expressed in pixels/second
  playlistScrollEnd: 0,       // The maximum time that the playlist editor should scroll to, in milliseconds.
                              // If the maximum time is before the end of the playlist, the end of the playlist will be used instead.
  previewFollow: false,       // If true, the preview time indicator is following the mouse cursor
  magnetic: true,             // If true, clips and time indicators will magnetize to each other
  selectedLibraryFile: null,  // When set to string, that is the rpath of the currently selected library file
  libraryDeselect: 0,         // When changed, library will deselect currently selected file
  selectedTool: 0,            // Which tool is selected (see const TOOLS above)
  aspectRatio: [16, 9],       // The aspect ratio of the playlist
  frameRate: 29.97,           // Frame rate of the playlist
  enforceAspectRatio: false,  // Enforce aspect ratio toggle state
  skipMissingExpired: true,   // Skip missing and expired items toggle state
  randomize: false,           // Randomize toggle state
  overrideComingUpNext: false,// Coming up next override toggle state
  selectedFrames: [],         // The currently selected frame in the editor
  selectedClips: [],          // Array of selected clips in the timeline editor
  clipboard: [],              // Array of clips that are copied
  playInterval: null,         // If not null, it is the interval id for the playlist playing function
  mainRegion: -1,             // The index of the main region
}

export const reducer = (state=initialState, action) => {
  let {type, payload} = action

  switch(type) {
    case LOAD_PLAYLIST: {
      let {data, path} = payload
      let newState = parsePlaylistJSON(data)
      return Object.assign({}, state, newState, {path, isFetching: false})
    }
    case ADD_FRAME: {
      return {
        ...state,
        frames: createFrame(state.frames, payload)
      }
    }
    case REMOVE_FRAME: {
      return {
        ...state,
        frames: removeFrame(state.frames, payload)
      }
    }
    case MOVE_FRAME: {
      let {index, x, y} = payload
      let newFramesList = state.frames.map((frame, frame_ind) => {
        if(frame_ind === index) {
          return {
            ...frame,
            position: {x, y}
          }
        }
        return frame
      })
      return {
        ...state,
        frames: newFramesList
      }
    }
    case RESIZE_FRAME: {
      let {index, width, height} = payload
      let newFramesList = state.frames.map((frame, frame_ind) => {
        if(frame_ind === index) {
          return {
            ...frame,
            size: {width, height}
          }
        }
        return frame
      })
      return {
        ...state,
        frames: newFramesList
      }
    }
    case REORDER_FRAME: {
      let {index, new_index} = payload
      let newFramesList = [...state.frames]
      let reorderFrame = newFramesList.splice(index, 1)
      if(reorderFrame.length > 0) {
        newFramesList.splice(new_index, 0, ...reorderFrame)
      }
      return {
        ...state,
        frames: newFramesList
      }
    }
    case ADD_FRAME_CLIP: {
      let {index, clip} = payload
      return {
        ...state,
        frames: addClipToFrame(index, state.frames, clip),
        selectedClips: []
      }
    }
    case REMOVE_FRAME_CLIP: {
      let {index, clip_index} = payload
      return {
        ...state,
        frames: removeClipFromFrame(index, clip_index, state.frames),
        selectedClips: []
      }
    }
    case MOVE_FRAME_CLIP: {
      let {index, clip_index, new_time, new_frame} = payload
      let clip = state.frames[index].timeline[clip_index]
      let start = (new_time || new_time === 0) ? new_time : clip.start
      let end = start + (clip.end - clip.start)
      let newFrame = (new_frame || new_frame === 0) ? new_frame : index
      let newClip = {...clip, start, end}
      let framesList = removeClipFromFrame(index, clip_index, state.frames)
      if(newFrame >= 0 && newFrame < state.frames.length) {
        framesList = addClipToFrame(newFrame, framesList, newClip)
      } else {
        framesList = createFrame(framesList, {timeline: [newClip]})
      }
      return {
        ...state,
        frames: framesList
      }
    }
    case CUT_FRAME_CLIP: {
      let {index, clip_index, cut_time} = payload
      let clip = state.frames[index].timeline[clip_index]
      console.log("CUT")
      console.log(`START: ${clip.start}`)
      console.log(`IN: ${clip.in}`)
      console.log(`CUT TIME: ${cut_time}`)
      let secondHalf = {...clip, start: cut_time, in: (cut_time - (clip.start - (clip.in || 0)))}
      let framesList = modifyClip(index, clip_index, state.frames, {end: cut_time})
      framesList = addClipToFrame(index, framesList, secondHalf)
      return {
        ...state,
        frames: framesList
      }
    }
    case MODIFY_FRAME_CLIP: {
      let {index, clip_index, properties} = payload
      return {
        ...state,
        frames: modifyClip(index, clip_index, state.frames, properties)
      }
    }
    case SET_FRAME_NAME: {
      let {index, name} = payload
      let newFramesList = state.frames.map((frame, frame_ind) => {
        if(frame_ind === index) {
          return {
            ...frame,
            name
          }
        }
        return frame
      })
      return {
        ...state,
        frames: newFramesList
      }
    }
    case SET_FRAME_OPTIONS: {
      let {index, options, delete_keys} = payload
      let newFramesList = state.frames.map((frame, frame_ind) => {
        if(frame_ind === index) {
          let newOptions = {...frame.options, ...options}
          for(let toDelete of delete_keys) {
            if(newOptions[toDelete] !== undefined) {
              delete newOptions[toDelete]
            }
          }
          return {
            ...frame,
            options: newOptions
          }
        }
        return frame
      })
      return {
        ...state,
        frames: newFramesList
      }
    }
    case SET_SNAP_TO_GRID: {
      return {
        ...state,
        snapToGrid: payload
      }
    }
    case SET_ZOOM_LEVEL: {
      let previewDistance = millisecondsToPixels(state.previewTime - state.timelineViewStart, state.zoomLevel)
      let newStart = Math.max(state.previewTime - pixelsToMilliseconds(previewDistance, payload), 0)
      let scrollMargin = ((document.body.getBoundingClientRect().width / payload) * 1000)
      return {
        ...state,
        zoomLevel: payload,
        timelineViewStart: newStart,
        playlistScrollEnd: newStart + scrollMargin
      }
    }
    case SET_PREVIEW_TIME: {
      return {
        ...state,
        previewTime: payload,
        isFetching: false
      }
    }
    case SET_TIMELINE_VIEW_START: {
      payload = Math.max(payload, 0)
      let scrollMargin = ((document.body.getBoundingClientRect().width / state.zoomLevel) * 1000)
      return {
        ...state,
        timelineViewStart: payload,
        playlistScrollEnd: payload + scrollMargin
      }
    }
    case SET_PLAYLIST_SCROLL_END: {
      return {
        ...state,
        playlistScrollEnd: payload
      }
    }
    case SET_PREVIEW_INDICATOR_FOLLOW: {
      return {
        ...state,
        previewFollow: payload
      }
    }
    case SET_MAGNETIC: {
      return {
        ...state,
        magnetic: payload
      }
    }
    case PLAYLIST_LIBRARY_SELECT: {
      let selectedLibraryFile = payload[0] || null
      return {
        ...state,
        selectedLibraryFile
      }
    }
    case SELECT_PLAYLIST_TOOL: {
      let toSelect = -1
      if(typeof payload === 'number') {
        toSelect = payload
      } else if(typeof payload === 'string') {
        toSelect = TOOLS.findIndex((tool) => (tool.id === payload))
      }
      let deselect = state.libraryDeselect
      if(toSelect >= 0 && state.selectedLibraryFile) {
        deselect = deselect ? 0 : 1
      }
      return {
        ...state,
        selectedTool: toSelect,
        libraryDeselect: deselect
      }
    }
    case SET_ASPECT_RATIO:
      return {...state, aspectRatio: payload}
    case SET_FRAME_RATE:
      return {...state, frameRate: payload}
    case SET_ENFORCE_ASPECT_RATIO:
      return {...state, enforceAspectRatio: payload}
    case SET_SKIP_MISSING_EXPIRED:
      return {...state, skipMissingExpired: payload}
    case SET_RANDOMIZE:
      return {...state, randomize: payload}
    case SET_OVERRIDE_COMING_UP_NEXT:
      return {...state, overrideComingUpNext: payload}
    case SELECT_FRAME: {
      let {selectedFrames} = state
      let {frame, exclusive} = payload
      if(exclusive) {
        if(selectedFrames.length === 1 && selectedFrames[0] === frame) {
          selectedFrames = []
        } else {
          selectedFrames = [frame]
        }
      } else if(selectedFrames.includes(frame)) {
        selectedFrames = selectedFrames.filter((val) => val !== frame)
      } else {
        selectedFrames = [...selectedFrames, frame]
      }
      return {...state, selectedFrames}
    }
    case DESELECT_ALL_FRAMES: {
      return {
        ...state,
        selectedFrames: []
      }
    }
    case SELECT_FRAME_CLIP: {
      let {frame, clip} = payload
      if(getIn(state.frames, [frame, 'timeline', clip])) {
        return {
          ...state,
          selectedClips: [[frame, clip]]
        }
      }
      return state
    }
    case SELECT_TIMELINE_RANGE: {
      let {start, end, frameStart, frameEnd} = payload
      if(start > end) {
        let temp = start
        start = end
        end = temp
      }
      if(frameStart > frameEnd) {
        let temp = frameStart
        frameStart = frameEnd
        frameEnd = temp
      }
      let frames = []
      for(let i = frameStart; i <= frameEnd; i++) {
        frames.push(i)
      }
      let toSelect = []
      frames.forEach((frame, frameInd) => {
        let timeline = getIn(state.frames, [frame, 'timeline'])
        if(timeline) {
          timeline.forEach((clip, clipInd) => {
            if(!(clip.start > end) && !(clip.end < start)) {
              toSelect.push([frame, clipInd])
            }
          })
        }
      })
      return {
        ...state,
        selectedClips: toSelect
      }
    }
    case DESELECT_FRAME_CLIPS: {
      return {...state, selectedClips: []}
    }
    case CLIPBOARD_SELECTED_CLIPS: {
      if(state.selectedClips.length > 0) {
        let adjustTime = 0
        let clipboard = state.selectedClips.map(([frame, clip]) => {
          let copyClip = getIn(state.frames, [frame, 'timeline', clip])
          return [frame, copyClip]
        }).sort((a, b) => a[1].start - b[1].start).map(([frame, clip], index) => {
          if(index === 0) {
            adjustTime = clip.start
          }
          let copyClip = {
            ...clip,
            start: clip.start - adjustTime,
            end: clip.end - adjustTime
          }
          return [frame, copyClip]
        })
        return {
          ...state,
          clipboard
        }
      }
      return state
    }
    case PASTE_CLIPS_AT_PREVIEW: {
      return {
        ...state,
        frames: payload
      }
    }
    case REMOVE_SELECTED_CLIPS: {
      if(state.selectedClips.length > 0) {
        let {frames} = state
        state.selectedClips.forEach(([frame, clip]) => {
          frames = removeClipFromFrame(frame, clip, frames)
        })
        return {
          ...state,
          frames,
          selectedClips: []
        }
      }
      return state
    }
    case TOGGLE_PLAYLIST_PLAYING: {
      return {
        ...state,
        playInterval: payload
      }
    }
    case SET_MAIN_REGION: {
      return {
        ...state,
        mainRegion: payload
      }
    }
    case RENAME_PLAYLIST: {
      return {
        ...state,
        playlistName: payload.name,
        playlistPath: payload.dir
      }
    }
    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: payload
      }
    }
    case FILL_PREVIEW_FRAME: {
      let {when, where, what} = payload
      if(what) {
        what = URL.createObjectURL(what)
      }
      return {
        ...state,
        frameThumbBuffer: setIn(state.frameThumbBuffer, [when, where], what)
      }
    }
    case PREVIEW_TICK: {
      // If there is no data for the current frame, delay
      let missingData = state.frames.reduce((missing, frame, ind) => {
        if(missing ||
          !state.frameThumbBuffer[state.previewTime] ||
          state.frameThumbBuffer[state.previewTime][ind] === undefined) {
          return true
        } else {
          return missing
        }
      }, false)
      if(missingData) {
        return state
      }
      // Update current render time
      let previewTime = state.previewTime + framesToMilliseconds(1, PREVIEW_FRAME_RATE)
      previewTime = previewTime - (previewTime % framesToMilliseconds(1, PREVIEW_FRAME_RATE))
      // Set isFetching to false
      let isFetching = false
      return {
        ...state,
        previewTime,
        isFetching
      }
    }
    case RELOAD_PREVIEW_BUFFER: {
      Object.values(state.frameThumbBuffer).forEach((time) => {
        if(time) {
          Object.values(time).forEach((url) => {
            if(url) {
              URL.revokeObjectURL(url)
            }
          })
        }
      })
      return {
        ...state,
        isFetching: false,
        frameThumbBuffer: {}
      }
    }
    default: {
      return state
    }
  }
}

const undoReducer = undoable(reducer, {
  limit: 25,
  undoType: PLAYLIST_UNDO,
  redoType: PLAYLIST_REDO,
  filter: includeAction([
     ADD_FRAME,
     REMOVE_FRAME,
     MOVE_FRAME,
     RESIZE_FRAME,
     REORDER_FRAME,
     ADD_FRAME_CLIP,
     REMOVE_FRAME_CLIP,
     MOVE_FRAME_CLIP,
     MODIFY_FRAME_CLIP,
     CUT_FRAME_CLIP,
     SET_FRAME_NAME,
     PASTE_CLIPS_AT_PREVIEW,
     REMOVE_SELECTED_CLIPS
  ])
})

const libraryReducer = CutsLibraryManager.wrap(undoReducer, {present: initialState, past: [], future: []})

const loadingReducer = loaderReducer(libraryReducer, {present: initialState, past: [], future: []})

const TAB_DISPLAY = (props) => {
  if(props.present.playlistName) {
    return props.present.playlistName;
  } else {
    return '(NEW PROJECT)'
  }
}

const PLAYLIST_UNSAVED = (props) => {
  return (props.past.length > 0 &&
    props.lastSaved !== props.past[props.past.length - 1])
}

export const changeTab = (index) => ({
  type: PLAYLIST_CHANGE_TAB,
  payload: index
})

export const createTab = (path) => {
  return (dispatch, getState) => {
    let payload = {}
    // Get aspect ratio of currently selected channel
    let {active_channel} = getState().channel
    let channel = SettingsSelector({...getState().settings, type: 'channel', id: active_channel})
    let aspectRatio = getIn(channel, ['settings', 'video', 'aspect'])
    if(aspectRatio) {
      payload = {
        ...payload,
        aspectRatio: [aspectRatio.width, aspectRatio.height]
      }
    }
    if(path) {
      payload = {
        ...payload,
        playlistPath: path.slice(0, -1),
        playlistName: path.slice(-1).join()
      }
    }
    dispatch({
      type: PLAYLIST_CREATE_TAB,
      payload
    })
  }
}

export const onTabCreated = () => {
  return (dispatch, getState) => {
    let {playlistPath, playlistName} = getState().local.present
    if(playlistPath.length > 0 && playlistName) {
      let fullpath = [...playlistPath, playlistName]
      dispatch(actions.loadPlaylist(fullpath.join('/')))
    }
  }
}

export const deleteTab = (index) => ({
  type: PLAYLIST_DELETE_TAB,
  payload: index
})

export default tabbedReducer('playlist_editor', loadingReducer,
  {present: initialState, past: [], future: []},
  {changeTab: PLAYLIST_CHANGE_TAB,
  createTab: PLAYLIST_CREATE_TAB,
  deleteTab: PLAYLIST_DELETE_TAB,
  display: TAB_DISPLAY,
  isUnsaved: PLAYLIST_UNSAVED,
  tabActions: actions,
  onTabCreated})
