import React, {Component} from 'react'
import applicationList from 'applicationList'

import AppIcon from './AppIcon'

export default class ApplicationIcons extends Component {

  shouldComponentUpdate(nextProps) {
    let shouldUpdate = (this.props.openApplications !== nextProps.openApplications ||
      this.props.pinnedApplications !== nextProps.pinnedApplications ||
      this.props.activeApplication !== nextProps.activeApplication)
    if(shouldUpdate) {
      return shouldUpdate
    }
    for(let item of nextProps.openApplications) {
      if(this.props.tabCounts[item] !== nextProps.tabCounts[item]) {
        return true
      }
    }
    return false
  }

  render() {
    let {openApplications,
      pinnedApplications,
      pinApplication,
      openApplication,
      closeApplication,
      activeApplication,
      tabCounts} = this.props

    let menuIcons = openApplications.map((item, ind) => {
      let app = applicationList[item]
      let numberTabs = tabCounts[item]

      return (
        <AppIcon key={item}
          app={app}
          appName={item}
          index={ind}
          tabs={numberTabs}
          active={activeApplication === ind}
          pinned={pinnedApplications.includes(item)}
          openApplication={openApplication}
          closeApplication={closeApplication}
          pinApplication={pinApplication}/>
      )
    })

    return (
      <div id='menu-icons'>
        {menuIcons}
      </div>
    )

  }

}
