import React, {PureComponent} from 'react'
import {Table, Icon, Checkbox} from 'semantic-ui-react'

import LibraryFile from './LibraryFile'

import './LibraryFileTable.css'

class LibraryFileTable extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {y: 0}
    this.frame = React.createRef()
  }

  componentDidMount() {
    if(this.frame.current) {
      this.setState({y: this.frame.current.getBoundingClientRect().y})
    }
  }

  render() {
    let {
      filelist,
      fileData,
      selectedFiles,
      navpath,
      changeLibraryPath,
      selectOne,
      compact,
      noOpen,
      isSearching,
      highlightFile,
      sortColumn,
      sortAsc,
      sortLibrary,
      selectFile,
      selectFileOnly,
      selectFileRange,
      selectAllFiles,
      previewFile,
      handleOpenFile,
      handleSetInputAssociation,
      inputAssociations,
      actionDropdown,
      selectedChannel
    } = this.props
    let {y} = this.state

    let columns = 16
    let ismnt = false
    let ismntmain = false

    if(compact) {
      columns -= 4
    }
    if(selectOne) {
      columns -= 1
    }

    columns = `${columns}`

    if((navpath instanceof Array && navpath.join('/') === "mnt") || navpath === "mnt")
      ismnt = true
    else if((navpath instanceof Array && navpath.join('/') === "mnt/main") || navpath === "mnt/main")
      ismntmain = true

    // populate table rows from filelist if it exists
    let tableRows = filelist ? filelist.map((item) => {
      let path = navpath.concat(item)
      if(isSearching) {
        path = fileData[item].path
        if(path.startsWith('/')) {
          path = path.slice(1)
        }
        path = path.split('/').concat(item)
      }
      let active = selectedFiles.reduce((active, val) => {
        if(val.join('/') === path.join('/')) {
          return true
        }
        return active
      }, false)
      let highlight = false
      let doNotModify = false
      if(highlightFile) {
        highlight = (highlightFile === item)
      }
      if (ismntmain) {
        if (item === "tv") doNotModify = true
      }
      return (<LibraryFile displayType={'row'}
                key={item}
                filename={item}
                path={path}
                data={fileData[item]}
                changeLibraryPath={changeLibraryPath}
                active={active}
                selectOne={selectOne}
                compact={compact}
                noOpen={noOpen}
                selectFile={selectFile}
                selectFileOnly={selectFileOnly}
                selectFileRange={selectFileRange}
                ismntmain={ismntmain}
                ismntmaintv={ismntmain && item === "tv"}
                doNotModify={doNotModify}
                previewFile={previewFile}
                handleOpenFile={handleOpenFile}
                inputAssociations={inputAssociations}
                handleSetInputAssociation={handleSetInputAssociation}
                actionDropdown={actionDropdown}
                highlight={highlight}
                yAdjustment={y}
                selectedChannel={selectedChannel}/>)
    }) : []

    let sortIcon = <Icon name={sortAsc ? 'triangle up' : 'triangle down'}/>

    let allSelected = (selectedFiles.length > 0 && filelist.length === selectedFiles.length)
    let selectHeader = selectOne ? null :
      (
        <Table.HeaderCell width='1' textAlign='center' onClick={selectAllFiles}>
          <Checkbox checked={allSelected}/>
        </Table.HeaderCell>
      )
    let dateHeader = (<Table.HeaderCell onClick={() => {sortLibrary('date')}} width='2'>
      {sortColumn === 'date' ? sortIcon : null}
      {ismnt ? "Device" : "Date"}
    </Table.HeaderCell>)
    let typeHeader = compact ? null : (<Table.HeaderCell onClick={() => {sortLibrary('type')}} width='2'>
      {sortColumn === 'type' ? sortIcon : null}
      Type
    </Table.HeaderCell>)
    let sizeHeader = compact ? null : (<Table.HeaderCell onClick={() => {sortLibrary('size')}} width='2'>
      {sortColumn === 'size' ? sortIcon : null}
      Size
    </Table.HeaderCell>)

    return (
      <div className='libraryTablePanel'>
        <Table className='libraryTableHeader noselect' striped attached='top' columns={columns}>
          <Table.Header>
            <Table.Row>
              {selectHeader}
              <Table.HeaderCell width='1'/>
              <Table.HeaderCell onClick={() => {sortLibrary('')}} width='6'>
                {(sortColumn === '' || sortColumn === 'filename') ? sortIcon : null}
                {ismnt ? "Partition or disk" : "Filename"}
              </Table.HeaderCell>
              {dateHeader}
              {typeHeader}
              <Table.HeaderCell onClick={() => {sortLibrary('duration')}} width='2'>
                {sortColumn === 'duration' ? sortIcon : null}
                {ismnt ? "" : "Duration"}
              </Table.HeaderCell>
              {sizeHeader}
            </Table.Row>
          </Table.Header>
        </Table>
        <div className='libraryTableBody' ref={this.frame}>
          <Table striped attached='bottom' columns={columns}>
            <Table.Body>
              {tableRows}
            </Table.Body>
          </Table>
        </div>
      </div>
    )
  }
}

export default LibraryFileTable
