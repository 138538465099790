import React, {PureComponent} from 'react'
import applicationList from 'applicationList'
import {staticAsset} from 'helpers/net_helpers'

import {Dropdown, Icon, Image, Menu} from 'semantic-ui-react'

import "./MenuDropdown.css"

export default class MenuDropdown extends PureComponent {

  componentDidMount() {
    window.addEventListener("resize", this.resizeMenu)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeMenu)
  }

  resizeMenu = () => {
    requestAnimationFrame(() => {
      let menu = document.getElementById("menuDropdown")
      let needWidth = 0
      for(let child of menu.children) {
        let {right} = child.getBoundingClientRect()
        if(right > needWidth) {
          needWidth = right
        }
      }
      menu.setAttribute("style", `width:${needWidth}px`)
    })
  }

  render() {
    let {menuList,
      setActiveApplication,
      openApplication,
      logOut,
      push} = this.props

    let dropdownItems = menuList.map((item, index) => {
      if(typeof item === 'string') {
        return (<Menu.Item
                 name={applicationList[item].name}
                 icon={applicationList[item].noSvg ?
                  <Icon name={applicationList[item].icon}/> :
                  <Icon className='svg-icon'>
                    <Image src={staticAsset(`/icons/${applicationList[item].icon}_black.svg`)}/>
                  </Icon>
                 }
                 key={item + index}
                 onClick={() => {openApplication(item)}}/>)
      } else if(typeof item === 'object') {
        if(item.directLink) {
          return (<Menu.Item name={item.name}
                   icon={item.noSvg ?
                    <Icon name={item.icon}/> :
                    <Icon className='svg-icon'>
                      <Image src={staticAsset(`/icons/${item.icon}_black.svg`)}/>
                    </Icon>
                   }
                   key={item + index}
                   onClick={() => {
                    setActiveApplication(-1)
                    push(item.directLink)
                  }}/>)
        }
      }
      return null
    })

    dropdownItems.push(<Dropdown.Item text="Log Out"
      icon={<Icon className='sign-out'/>}
      key={'sign-out'}
      onClick={logOut}/>)

    return (
      <Dropdown item icon={false}
          text='CASTUS'
          id='applications_menu'
          index={-1}
          onOpen={this.resizeMenu}>
        <Menu id="menuDropdown">
          {dropdownItems}
        </Menu>
      </Dropdown>
    )
  }

}
