import { createSelector } from 'reselect'
import {getIn} from 'helpers/general_helpers'
import {dataPath} from 'helpers/library_helpers'
import Moment from 'moment'

const getFileData = (state) => state.fileData
const getNavPath = (state) => state.navpath
const getSearchParam = (state) => state.searchParam
const getSortColumn = (state) => state.sortColumn
const getSortAsc = (state) => state.sortAsc

export default createSelector([
  getFileData,
  getNavPath,
  getSearchParam,
  getSortColumn,
  getSortAsc
], (fileData, navpath, searchParam, sortColumn, sortAsc) => {

  let filelist;

  if(searchParam) {
    filelist = getIn(fileData, ['search', searchParam])
  } else {
    let data = getIn(fileData, dataPath(navpath));
    if(data && data.type === "file") {
      filelist = getIn(fileData, dataPath(navpath.slice(0, -1), true));
    } else {
      filelist = getIn(fileData, dataPath(navpath, true));
    }
  }

  if(!filelist) {
    return [];
  }

  let ismnt = false
  if((navpath instanceof Array && navpath.join('/') === "mnt") || navpath === "mnt")
    ismnt = true

  if (ismnt) {
    filelist = Object.keys(filelist).sort((a, b) => {
      a = getIn(filelist, [a, 'data', 'dev']) || ""
      b = getIn(filelist, [b, 'data', 'dev']) || ""
      return a.localeCompare(b)
    })
  }
  else {
    filelist = Object.keys(filelist).sort((a, b) => {
      if(filelist[a].type === 'folder' && filelist[b].type !== 'folder') {
        return -1
      }
      if(filelist[a].type !== 'folder' && filelist[b].type === 'folder') {
        return 1
      }
      if(sortAsc) {
        let temp = a
        a = b
        b = temp
      }
      switch(sortColumn) {
        case 'date':
          let aDate = new Moment(filelist[a].mtime)
          let bDate = new Moment(filelist[b].mtime)
          return bDate.diff(aDate)
        case 'type':
          let aType = getIn(filelist, [a, 'metadata', 'file type']) || ''
          let bType = getIn(filelist, [b, 'metadata', 'file type']) || ''
          return aType.localeCompare(bType)
        case 'duration':
          let aDur = getIn(filelist, [a, 'metadata', 'duration']) || 0
          let bDur = getIn(filelist, [b, 'metadata', 'duration']) || 0
          if(typeof aDur === 'string') {
            aDur = parseFloat(aDur)
          }
          if(typeof bDur === 'string') {
            bDur = parseFloat(bDur)
          }
          return bDur - aDur
        case 'size':
          let aSize = filelist[a].size
          let bSize = filelist[b].size
          if(typeof aSize === 'string') {
            aSize = parseFloat(aSize)
          }
          if(typeof bSize === 'string') {
            bSize = parseFloat(bSize)
          }
          return bSize - aSize
        default:
          return a.localeCompare(b)
      }
    })
  }

  return filelist

})


