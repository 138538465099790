import React, {PureComponent} from 'react'
import {Stage, Layer} from 'react-konva'
import Meter from './Meter'

import "./AudioMeters.css"

export default class AudioMeters extends PureComponent {

  constructor(props) {
    super(props)
    this.metersContainer = React.createRef();
    this.state = {containerWidth: 260, meterBuffers: []}
  }

  componentDidUpdate(prevProps) {
    if(this.metersContainer.current) {
      let containerWidth = this.metersContainer.current.getBoundingClientRect().width
      if(containerWidth !== this.state.containerWidth) {
        this.setState({containerWidth})
      }
    }
    if(this.props.vuMeters.seq !== prevProps.vuMeters.seq) {
      let {vuMeters} = this.props
      let meterBuffers = []
      for(let i = 0; i < vuMeters.ch; i++) {
        meterBuffers.push(vuMeters[i])
      }
      this.setState({meterBuffers})
    }
  }

  render() {
    let {vuMeters={}} = this.props
    let {containerWidth, meterBuffers} = this.state

    let meters = null;
    if(meterBuffers) {
      meters = meterBuffers.map((val, index) => {
        return (
          <Meter key={index}
            index={index}
            width={containerWidth}
            seq={vuMeters.seq}
            rate={vuMeters.r}
            buffer={val}/>
        )
      })
    }

    let height = 10
    if(vuMeters && vuMeters.ch) {
      height = vuMeters.ch * 8
    }

    return (
      <div className="audioMetersContainer" ref={this.metersContainer}>
        <Stage width={containerWidth}
          height={height}>
          <Layer>
            {meters}
          </Layer>
        </Stage>
      </div>
    )
  }

}
