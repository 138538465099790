import Moment from 'moment'
import path from "path"
import {extendMoment} from 'moment-range'
import {saveFile, copyFiles} from 'redux/file_list'
import {messages} from 'redux/messages'
import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'
import {clipboardScheduleItems} from 'redux/applications/schedule_clipboard'
import {loadScheduleFromPath,
  getItemMetadata,
  validateSchedule,
  convertDurationString,
  convertViewTimeToJSON,
  checkForEventActions,
  constructItemsToAdd,
  constructItemsFromClipboard} from 'helpers/schedule_helpers'
import Schedule from "helpers/Schedule/Schedule"
import {writeJSONScheduleToText} from 'helpers/scheduleParse'
import {setIn, getIn, millisToHourString} from 'helpers/general_helpers'
import {dataPath} from 'helpers/library_helpers'
import {fetchFromServer} from 'helpers/net_helpers'
import undoable, {includeAction} from 'redux-undo'
import tabbedReducer from 'redux/higher_order_reducers/tabbedReducer'
import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
import {v4 as uuidv4} from 'uuid'
import IntervalTime from "helpers/IntervalTime/IntervalTime"

//NOTE: If you add more actions and you want them to be undoable/redoable,
//make sure you add them to the undoable includeAction param at the bottom of this file.
export const SCHEDULE_LIBRARY_UPDATE = Symbol('schedule library update')
export const CHANGE_SCHEDULE_VIEW = Symbol('change schedule view')
export const SET_SCHEDULE_SWAP = Symbol('set schedule swap')
export const SET_SCHEDULE_SWAP_NO_UNDO = Symbol('set schedule swap no undo')
export const ADD_SCHEDULE_ITEMS = Symbol('add schedule items')
export const ADD_EMPTY_TIMESLOT = Symbol('add empty timeslot')
export const CLIPBOARD_SCHEDULE_ITEMS = Symbol('clipboard schedule items')
export const PASTE_CLIPBOARD_ITEMS = Symbol('paste clipboard items')
export const REMOVE_SCHEDULE_ITEMS = Symbol('remove schedule items')
export const REMOVE_BLOCK = Symbol('remove block')
export const LOAD_SCHEDULE_DATA = Symbol('load schedule data')
export const SELECT_SCHEDULE_ITEM = Symbol('select schedule item')
export const SELECT_SCHEDULE_NONE = Symbol('select schedule none')
export const SCROLL_TO_TIME = Symbol('scroll to time')
export const FINISH_TIME_SCROLL = Symbol('finish time scroll')
export const SHIFT_SCHEDULE_ITEM = Symbol('shift schedule item')
export const CHANGE_SCHEDULE_ITEM_DURATION = Symbol('change schedule item duration')
export const EXPAND_SCHEDULE_BLOCK = Symbol('expand schedule block')
export const CLOSE_SCHEDULE_GAP = Symbol('close schedule gap')
export const TOGGLE_ITEM_LOOP = Symbol('toggle item loop')
export const TOGGLE_SCHEDULE_ITEM_LOCK = Symbol('toggle schedule item lock')
export const CREATE_SCHEDULE_BLOCK = Symbol('create schedule block')
export const REPLICATE_BLOCK_ITEMS = Symbol('replicate block items')
export const SET_DEFAULT_ITEM = Symbol('set daily default')
export const REMOVE_DEFAULT_ITEM = Symbol('remove default item')
export const CHANGE_TIME_SLOT_LENGTH = Symbol('change time slot length')
export const SCHEDULE_UNDO = Symbol('schedule undo')
export const SCHEDULE_REDO = Symbol('schedule redo')
export const SCHEDULE_REVERT = Symbol('schedule revert')
export const CLEAR_SCHEDULE_DAY = Symbol('clear schedule day')
export const CLEAR_SCHEDULE_ALL = Symbol('clear schedule all')
export const SET_SUBSECOND_VISIBILITY = Symbol('set subsecond visibility')
export const CREATE_NEW_SCHEDULE = Symbol('create new schedule')
export const CHANGE_INTERVAL_DURATION = Symbol('change interval duration')
export const CHANGE_INTERVAL_BASIS = Symbol('change interval basis')
export const CREATE_EVENT = Symbol('create event')
export const CHANGE_EVENT = Symbol('change event')
export const REMOVE_EVENT = Symbol('remove event')
export const SELECT_EVENTS = Symbol('select events')
export const SELECT_BLOCKS = Symbol('select blocks')
export const CHANGE_SIDEBAR_TAB = Symbol('change sidebar tab')
export const SAVE_SCHEDULE = Symbol('save schedule')
export const UPDATE_EVENTS_FILLED = Symbol('update events filled')
export const SET_VIEWED_ERROR = Symbol('set viewed error')
export const CHANGE_SCHEDULE_FILEPATH = Symbol('change schedule filepath')
export const UPDATE_MISSING_FILES = Symbol('update missing files')
export const SCHEDULE_LIBRARY_DESELECT = Symbol('schedule library deselect')
export const RECORD_SCROLL = Symbol('record scroll')
export const SET_ITEM_SCTE35 = Symbol('set item scte-35')

export const SCHEDULE_CHANGE_TAB = Symbol('schedule change tab')
export const SCHEDULE_CREATE_TAB = Symbol('schedule create tab')
export const SCHEDULE_DELETE_TAB = Symbol('schedule delete tab')

const EVENT_UPLOAD_FOLDER_PATH = ['mnt', 'main', 'Events', 'Upload']

export const changeTab = (index) => ({
  type: SCHEDULE_CHANGE_TAB,
  payload: index
})

export const createTab = (path=[]) => {
  let payload = {}
  if(path && path.length > 0) {
    payload = {filepath: path.slice(0, -1), filename: path.slice(-1).pop()}
  }
  return {
    type: SCHEDULE_CREATE_TAB,
    payload
  }
}

export const deleteTab = (index) => ({
  type: SCHEDULE_DELETE_TAB,
  payload: index
})

const onTabCreated = () => {
  return (dispatch, getState) => {
    let {filepath, filename} = getState().local.present
    if(filepath.length > 0 && filename) {
      let fullpath = [...filepath, filename]
      dispatch(actionCreators.loadScheduleData(fullpath.join('/')))
    }
  }
}


const moment = extendMoment(Moment)

export const actionCreators = {

  /**
   * Loads data of a given schedule
   * @param {string} path Path of the schedule to load
   */
  loadScheduleData: (path) => {
    return async (dispatch, getState) => {
      if(path) {
        let jobID = dispatch(loading.startLoading(getState().local._loaderID))
        try {
          let schedule = await loadScheduleFromPath(path)
          schedule = new Schedule(schedule)
          schedule.schedule = await getItemMetadata(schedule.schedule)
          schedule = await schedule.checkFilesExist()
          schedule = await schedule.checkEventsExist()
          schedule = await schedule.updateEvents()
          dispatch({
            type: LOAD_SCHEDULE_DATA,
            payload: {
              data: {schedule},
              path
            }
          })
        } catch(err) {
          if(err.type !== 'ENOENT') {
            console.error(err)
            dispatch.global(messages.alert(`Error loading schedule: ${err.message}`, {level: 'error'}))
          }
        }
        dispatch(loading.finishLoading(getState().local._loaderID, jobID))
      }
    }
  },

  /**
   * Saves the current schedule swap to the server
   */
  saveScheduleData: (saveAs=false) => {
    return async (dispatch, getState) => {
      let schedState = getState().local.present
      let {scheduleSwap, schedule} = schedState
      // Get filename/filepath
      let name = schedState.filename
      let dest = schedState.filepath
      if(!name || saveAs) {
        let value = await dispatch.global(messages.promptAsync("Save Schedule", {
          type: 'path',
          validate: (result) => (result.filename !== '' && result.directory),
          invalidText: 'That is not a valid schedule name.',
          initialValue: {filename: name, directory: dest}
        }))
        if(value === null) {
          return
        }
        name = value.filename
        dest = value.directory
        dispatch(actionCreators.changeScheduleFilepath(name, dest))
      }
      // Should have a name by now. If not, something's gone wrong
      if(!name) {
        dispatch.global(messages.alert("Error Saving Schedule! Somehow, the schedule was trying to save without having a name.", {level: 'error'}))
        return
      }
      dest = dest.join('/')
      if(!dest) {
        dest = 'mnt/main/Schedules'
      }
      let jobID = dispatch(loading.startLoading(getState().local._loaderID))
      try {
        let JSONSchedule = {
          ...scheduleSwap.serializeToObject(),
          "time slot length": schedState.timeSlotLength,
          ...convertViewTimeToJSON(schedState.viewTime, scheduleSwap.type)
        }
        let scheduleText = writeJSONScheduleToText(JSONSchedule)
        // Double check schedule text is not empty
        if(scheduleSwap.length) {
          let firstItem = getIn(scheduleSwap, ["schedule", 0])
          if(firstItem) {
            let firstLabel = firstItem.association ? firstItem.association.name : getIn(firstItem, ["body", "label"])
            if(firstLabel && !scheduleText.includes(firstLabel)) {
              dispatch.global(messages.alert("Error Saving Schedule! Somehow, the schedule file to be saved was empty, even though the current schedule is not empty.", {level: 'error'}))
              dispatch(loading.finishLoading(getState().local._loaderID, jobID))
              return
            }
          }
        }
        let copyingFiles = false;
        let eventsList = checkForEventActions(scheduleSwap.eventsList, schedule.eventsList)
        let toCopy = []

        // Push all events to rather than one at a time to prevent a
        // race condition when creating/renaming several events.
        /* Commented out for use with replacement event daemon
        fetchFromServer('/v2/events/event', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(eventsList)
        }).then((res) => {
          if(!res.ok) {
            res.text().then((err) => {
              dispatch(messages.alert(`There was an error creating or renaming an event: ${err}.  (Make sure that the "Watchlist/event scheduling" under the System Services tab of the Services app is running.)`))
            })
          }
        }).catch((err) => {
          dispatch(messages.alert(`There was an error creating or renaming an event: ${err}.  (Make sure that the "Watchlist/event scheduling" under the System Services tab of the Services app is running.)`))
        })
        */

        eventsList.forEach((action) => {
          if(action.action === 'CREATE' || action.action === 'RENAME') {
          } else if(action.action === 'COPY_FILE') {
            copyingFiles = true;
            toCopy.push([action.path, [...EVENT_UPLOAD_FOLDER_PATH, action.name, action.path[action.path.length - 1]]])
          }
        })
        if(toCopy.length) {
          // Don't wait for files to finish copying. They can finish in the background.
          dispatch.global(copyFiles(toCopy))
        }
        let source = new File([scheduleText], name, {type: 'text/plain'})
        dispatch.global(saveFile(source, dest, {
          createMetadata: true,
          onSuccess: () => {
            dispatch(loading.finishLoading(getState().local._loaderID, jobID))
            if(copyingFiles) {
              dispatch.global(messages.alert(`${name} successfully saved! It was saved at ${dest}. One or more files are being copied to event folders. The status of the copy operation can be checked from the File Transfers section of the Upload application.`, {level: 'success'}))
            } else {
              dispatch.global(messages.alert(`${name} successfully saved! It was saved at ${dest}`, {level: 'success'}))
            }
            dispatch({type: SAVE_SCHEDULE})
          },
          onError: () => {
            dispatch(loading.finishLoading(getState().local._loaderID, jobID))
          }
        }))
      } catch (err) {
        if(jobID) {
          dispatch(loading.finishLoading(getState().local._loaderID, jobID))
        }
        console.error(err);
        dispatch.global(messages.alert(`There was an error saving the schedule ${name}: ${err}`, {level: 'error'}))
      }
    }
  },

  exportSchedule: (type) => {
    return async (dispatch, getState) => {
      let {filename, filepath} = getState().local.present
      if(!filename) {
        dispatch.global(messages.alert("Error Exporting Schedule! Somehow, an export attempt was made for a schedule with no name.", {level: 'error'}))
        return
      }
      filepath = filepath.join('/')
      if(!filepath) {
        filepath = 'mnt/main/Schedules'
      }
      let query = ''
      if(type === "html" || type === "json" || type === "xml" || type === "gracenote" || type === "rovi") {
        query = `?type=${type}`
      }
      // NTS: We cannot URL escape the forward slashes because the server-side Express API will fail to match
      //      the schedfeed endpoint entirely, for some stupid reason. But we must escape everything else if
      //      we want the web UI to properly support schedules with characters like question marks, pound
      //      signs, etc.
      //
      //      For example, /v2/other/schedfeed/mnt/main/blah is perfectly fine with Express routing, but
      //      /v2/other/schedfeed/mnt%2Fmain%2Fblah will pretend that the endpoint never existed. Why???
      //      For an endpoint named "/schedfeed/*" it should match anything starting with /schedfeed/,
      //      right???
      let fullpath = encodeURIComponent(`${filepath}/${filename}`).replace(/%2F/g,"/")
      let jobID = dispatch(loading.startLoading(getState().local._loaderID))
      let res = await fetchFromServer(`/v2/other/schedfeed/${fullpath}${query}`, {
        headers: {
          "Cache-Control": "no-cache"
        }
      })
      if(res.ok) {
        let disposition = res.headers.get('Content-Disposition')
        let sched = await res.blob()
        let name = /filename="([^"]+)"/.exec(disposition)
        if(name && name.length === 2) {
          name = name[1]
        } else {
          if (type === "rovi" || type === "gracenote")
            name = `${filename}.csv`
          else
            name = `${filename}.${type ? type : "xml"}`

          console.error("Couldn't get name from Content-Disposition header")
        }
        let file = new File([sched], name, {type: res.headers.get('Content-Type')})
        let url = URL.createObjectURL(file)
        dispatch(loading.finishLoading(getState().local._loaderID, jobID))
        let dl_element = document.createElement('a')
        dl_element.href = url
        dl_element.download = name
        dl_element.click()
        URL.revokeObjectURL(url)
      } else {
        dispatch(loading.finishLoading(getState().local._loaderID, jobID))
        let err = await res.text()
        dispatch.global(messages.alert(`There was an error exporting the schedule: ${err}`, {level: 'error'}))
      }
    }
  },

  /**
   * Updates the navpath and the list of selected files from the schedule app's library component
   * @param {array} selectedFiles An array of selected files
   * @param {array} navpath The currently viewed navpath of the library
   */
  scheduleLibraryUpdate: (selectedFiles=null, navpath=null) => ({
    type: SCHEDULE_LIBRARY_UPDATE,
    payload: {
      selectedFiles,
      navpath
    }
  }),

  /**
   * Changes the currently viewed schedule time
   * @param {object/number} time A moment object or numerical day representing the date to be viewed. Schedule types use:
   *  daily: view cannot be changed
   *  weekly: 0-indexed numerical day of the week
   *  monthly/yearly: moment object
   *  interval: 1-indexed numerical interval day
   */
  changeScheduleView: (time) => {
    return (dispatch, getState) => {
      dispatch({
        type: CHANGE_SCHEDULE_VIEW,
        payload: time
      })
      dispatch({
        type: SELECT_SCHEDULE_NONE
      })
    }
  },

  /**
   * Selects given schedule items
   * @param {array} toSelect array of item/block keys
   * @param {object} options Object containing select options.
   * @param {boolean} options.exclusive If set to true, all currently selected items that are not passed in to toSelect will be deselected. Defaults to false.
   * @param {boolean} options.range If set to true, apply selection to all items from the previously selected item to the first item in toSelect. Defaults to false.
   * @param {object} options.rangeStart Use only with options.range = true. Sets the start of the selection range to something other than the last selected item.
   *    Defaults to null.
   * @param {string} options.method How the selection should be performed:
   *    'toggle': Default value. Items in toSelect that are selected will be deselected, and vice versa.
   *    'select': All items in toSelect will be selected.
   *    'deselect': All items in toSelect will be deselected.
   */
  selectScheduleItems: (toSelect, options={}) => ({
    type: SELECT_SCHEDULE_ITEM,
    payload: {
      toSelect,
      options,
    }
  }),

  /**
   * Deselect all schedule items
   */
  selectNone: () => ({type: SELECT_SCHEDULE_NONE}),

  /**
   * Attempts to replace the current swap schedule with a new one.
   * Will check for errors in the swap schedule first, and will confirm with the user
   * if additional errors are introduced
   * @param {object} newSwap The new swap schedule
   * @param {string} [message='One or more errors will be introduced in the schedule by doing this. Proceed?'] Custom message if errors are encountered
   */
  setScheduleSwap: (newSwap, message, opts={}) => {
    return (dispatch, getState) => {
      let {noUndo=false, changed=null} = opts
      let {schedule, scheduleSwap, showSubseconds} = getState().local.present
      if(newSwap === scheduleSwap) {
        dispatch({
          type: SET_SCHEDULE_SWAP_NO_UNDO,
          payload: newSwap
        })
      }
      let {scheduleErrors} = scheduleSwap || schedule
      if(!message) {
        message = 'One or more errors will be introduced in the schedule by doing this. Proceed?'
      }
      newSwap.validate({noMillis: !showSubseconds, changed})
      let type = noUndo ? SET_SCHEDULE_SWAP_NO_UNDO : SET_SCHEDULE_SWAP
      if(newSwap.scheduleErrors.length > scheduleErrors.length) {
        dispatch.global(messages.confirm(message, (result) => {
          if(result) {
            dispatch({
              type,
              payload: newSwap
            })
          }
        }))
      } else {
        dispatch({
          type,
          payload: newSwap
        })
      }
    }
  },

  /**
   * Adds all selected files to the current schedule. Items are added in selection order.
   * @param {object} time IntervalTime or Moment object representing the time at which the items should be added.
   */
  addScheduleItems: (time) => {
    return async (dispatch, getState) => {
      let {fileData} = getState().global.file_list
      let handleNamelessEvent = () => {
        dispatch.global(messages.alert("Unnamed events cannot be assigned to the schedule. Please give the event a unique name.", {level: "error"}))
        return null
      }
      let handleNoDuration = async ({name, showSubseconds}) => {
        return dispatch.global(messages.promptAsync(`The duration of ${name} is unknown.${"\n"}How long should this item run?`, {type: 'duration', subseconds: showSubseconds}))
      }
      // Should only need to pick block once
      let blockChoices = {}
      let handleMultipleBlocks = async (name, blocks) => {
        if(blockChoices[name]) {
          return blockChoices[name]
        } else {
          blockChoices[name] = dispatch.global(messages.promptAsync(`There are multiple different blocks with the name ${name}. Which one do you want to add?`,
            {type: 'multipleChoice', choices: blocks.map(block  => ({label: block.getSignature().split("\n"), returnValue: block}))}))
          return blockChoices[name]
        }
      }
      let scheduleItems = []
      // Handler for adding to multiple times at once
      if(time instanceof Array) {
        let scheduleItemConstructors = await Promise.all(time.map((addTime) => constructItemsToAdd({
          ...getState().local.present,
          ...getState().local.present.scheduleSwap,
          fileData,
          time: addTime,
          handleNamelessEvent,
          handleNoDuration,
          handleMultipleBlocks
        })))
        scheduleItems = scheduleItemConstructors.flat(1)
      // Old behavior
      } else {
        scheduleItems = await constructItemsToAdd({
          ...getState().local.present,
          ...getState().local.present.scheduleSwap,
          fileData,
          time,
          handleNamelessEvent,
          handleNoDuration,
          handleMultipleBlocks
        })
      }
      if(scheduleItems && scheduleItems.length) {
        dispatch(actionCreators.addItems(scheduleItems))
      }
    }
  },

  /**
   * Inserts either selected files or files from the clipboard into the current schedule at a given time, displacing existing items
   * @param {object} time IntervalTime or Moment object representing the time at which the items should be added.
   */
  insertIntoSchedule: (time) => {
    return async (dispatch, getState) => {
      let {fileData} = getState().global.file_list
      let scheduleItems = []
      let {selectedFiles, selectedEvents, selectedBlocks, tabIndex} = getState().local.present
      let {clipboard} = getState().global.schedule_clipboard
      let selected = []
      switch(tabIndex) {
        // LIBRARY TAB
        case 0:
          selected = selectedFiles
          break;
        // BLOCK TAB
        case 1:
          selected = selectedBlocks
          break;
        // EVENT TAB
        case 2:
          selected = selectedEvents
          break;
        default:
          break;
      }
      /*
      let selected = tabIndex ?
        selectedEvents :
        selectedFiles
      */
      // Figure out what items are going to be inserted
      if(selected.length) {
        let handleNamelessEvent = () => {
          dispatch.global(messages.alert("Unnamed events cannot be assigned to the schedule. Please give the event a unique name.", {level: "error"}))
          return null
        }
        let handleNoDuration = async ({name, showSubseconds}) => {
          return dispatch.global(messages.promptAsync(`The duration of ${name} is unknown.${"\n"}How long should this item run?`, {type: 'duration', subseconds: showSubseconds}))
        }
        let handleMultipleBlocks = async (name, blocks) => {
          return dispatch.global(messages.promptAsync(`There are multiple different blocks with the name ${name}. Which one do you want to add?`,
            {type: 'multipleChoice', choices: blocks.map(block  => ({label: block.getSignature().split("\n"), returnValue: block}))}))
        }
        scheduleItems = await constructItemsToAdd({
          ...getState().local.present,
          ...getState().local.present.scheduleSwap,
          fileData,
          time,
          handleNamelessEvent,
          handleNoDuration,
          handleMultipleBlocks
        })
      } else if (clipboard) {
        scheduleItems = clipboard
      }
      // Next, clear space for the items
      if(scheduleItems && scheduleItems.length) {
        let {scheduleSwap, type, intervalDuration, intervalBasis} = getState().local.present
        // Need to clear space for the whole group of files to be added
        let placeholder;
        if(type === "endless") {
          placeholder = {
            type: "item",
            start: scheduleItems[0].span.start.clone(),
            end: scheduleItems[scheduleItems.length - 1].span.end.clone(),
            placeholder: true
          }
        } else {
          placeholder = {
            type: "item",
            start: new IntervalTime(scheduleItems[0].span.start, type, {basis: intervalBasis, days: intervalDuration}),
            end: new IntervalTime(scheduleItems[scheduleItems.length - 1].span.end, type, {basis: intervalBasis, days: intervalDuration}),
            placeholder: true
          }
        }
        scheduleSwap = scheduleSwap.addItems([placeholder])
        let placeholderItem;
        scheduleSwap.forEachItem(item => {
          if(!placeholderItem && item.extraKeys && item.extraKeys.placeholder) {
            placeholderItem = item
          }
        })
        if(!placeholderItem) {
          throw new Error(`Error inserting items into schedule: Tried to create space for the items being inserted, but the space that was to be cleared seems to have disappeared?`)
        }
        scheduleSwap = scheduleSwap.shoveItem(placeholderItem.key, 0)
        scheduleSwap = scheduleSwap.shoveItem(placeholderItem.key, 0, true)
        // Expand containing schedule block if necessary
        if(scheduleSwap.findParentOf(placeholderItem.key).type === "block") {
          scheduleSwap = scheduleSwap.fitBlockToContents(scheduleSwap.findParentOf(placeholderItem.key).key, {dontContract:true})
        }
        scheduleSwap = scheduleSwap.removeItem(placeholderItem.key)
        // Now add the items
        scheduleSwap = scheduleSwap.addItems(scheduleItems)
        dispatch(actionCreators.setScheduleSwap(scheduleSwap))
      }
    }
  },

  /**
   * Adds an empty timeslot at the given time
   * @param {object} time A moment.js time object indicating when the empty slot is to be added
   */
  addEmptyTimeslot: (time) => {
    return (dispatch, getState) => {
      console.log("ADD EMPTY")
      let {viewTime, scheduleSwap} = getState().local.present
      let timeslotTime = viewTime.clone()
        .hours(time.hours())
        .minutes(time.minutes())
        .seconds(time.seconds())
        .milliseconds(time.milliseconds())
      if(scheduleSwap.type !== "endless") {
        timeslotTime = new IntervalTime(timeslotTime, scheduleSwap.type, {days: scheduleSwap.intervalDuration, basis: scheduleSwap.intervalBasis})
      }
      dispatch({type: ADD_EMPTY_TIMESLOT, payload: timeslotTime})
    }
  },

  addItems: (items) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.addItems(items)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap),
        'One or more of the items being added are overlapping with items that are already in the schedule. Continue?')
    }
  },

  replaceItem: (toReplace) => {
    return async (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      let replaceItem = scheduleSwap.findByKey(toReplace)
      if(!replaceItem) {
        dispatch.global(messages.alert(`Tried to replace the item with key ${toReplace}, but no such item could be found in the schedule.`, {level: "error"}))
        return
      }
      if(replaceItem.lock) {
        dispatch.global(messages.alert(`Locked schedule items can not be replaced.`, {level: "error"}))
        return
      }
      let addTime = replaceItem.span.start
      let {fileData} = getState().global.file_list
      let handleNamelessEvent = () => {
        dispatch.global(messages.alert("Unnamed events cannot be assigned to the schedule. Please give the event a unique name.", {level: "error"}))
        return null
      }
      let handleNoDuration = async ({name, showSubseconds}) => {
        return dispatch.global(messages.promptAsync(`The duration of ${name} is unknown.${"\n"}How long should this item run?`, {type: 'duration', subseconds: showSubseconds}))
      }
      let scheduleItems = await constructItemsToAdd({
        ...getState().local.present,
        ...getState().local.present.scheduleSwap,
        fileData,
        time: addTime,
        handleNamelessEvent,
        handleNoDuration
      })
      // If no items selected, but we have a clipboard, use that instead
      if(!scheduleItems || scheduleItems.length === 0) {
        let {clipboard} = getState().global.schedule_clipboard
        if(clipboard.length === 0) {
          return
        }
        scheduleItems = constructItemsFromClipboard([addTime], clipboard, scheduleSwap)
      }
      scheduleSwap = scheduleSwap.removeItem(toReplace)
      scheduleSwap = scheduleSwap.addItems(scheduleItems)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap),
        'One or more of the items being added are overlapping with items that are already in the schedule. Continue?')
    }
  },

  replaceAllInstances: (targetKey, opts={}) => {
    return async (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      let {replaceAll=true} = opts
      let replaceTarget = scheduleSwap.findByKey(targetKey)
      let allReplaceTargets = []
      // Replace schedule items
      if((replaceTarget.type === "item" || replaceTarget.type === "event")) {
        let replaceItem = replaceTarget.originalItem
        let replaceTargetParent = scheduleSwap.findParentOf(targetKey)
        scheduleSwap.schedule = scheduleSwap.schedule.map((item) => {
          // Replace item in top level if replaceTarget was at top level or replaceAll is true
          if((item.type === "item" || item.type === "event") &&
            item.originalItem === replaceItem &&
            (replaceAll || replaceTargetParent === scheduleSwap)) {
            allReplaceTargets.push(item.key)
          // Replace items in blocks if replaceTarget was in a block with the same name or replaceAll is true
          } else if (item.type === "block" &&
            (replaceAll || (replaceTargetParent.type === "block" && item.body.label === replaceTargetParent.body.label))) {
            let replacementBlock = item.clone()
            replacementBlock.body.contains = replacementBlock.body.contains.map((child) => {
              if(child.originalItem === replaceItem) {
                allReplaceTargets.push(child.key)
              }
              return child
            })
            return replacementBlock
          }
          return item
        })
      // Replace schedule blocks
      } else if(replaceTarget.type === "block") {
        let replaceName = replaceTarget.body.label
        scheduleSwap.schedule = scheduleSwap.schedule.map((item) => {
          if(item.type === "block" &&
            item.body.label === replaceName) {
            allReplaceTargets.push(item.key)
          }
          return item
        })
      }
      // Now replace
      let rememberDurations = {}
      for(let toReplace of allReplaceTargets) {
        let replaceItem = scheduleSwap.findByKey(toReplace)
        if(!replaceItem) {
          dispatch.global(messages.alert(`Tried to replace the item with key ${toReplace}, but no such item could be found in the schedule.`, {level: "error"}))
          return
        }
        if(replaceItem.lock) {
          dispatch.global(messages.alert(`Locked schedule items can not be replaced.`, {level: "error"}))
          return
        }
        let addTime = replaceItem.span.start
        let {fileData} = getState().global.file_list
        let handleNamelessEvent = () => {
          dispatch.global(messages.alert("Unnamed events cannot be assigned to the schedule. Please give the event a unique name.", {level: "error"}))
          return null
        }
        let handleNoDuration = async ({name, showSubseconds}) => {
          // We're remembering the durations the client entered for certain items so that they don't have to enter it for every item being replaced.
          if(rememberDurations[name] || rememberDurations[name] === 0) {
            return rememberDurations[name]
          }
          let duration = await dispatch.global(messages.promptAsync(`The duration of ${name} is unknown.${"\n"}How long should this item run?`, {type: 'duration', subseconds: showSubseconds}))
          rememberDurations[name] = duration
          return duration
        }
        let scheduleItems = await constructItemsToAdd({
          ...getState().local.present,
          ...getState().local.present.scheduleSwap,
          fileData,
          time: addTime,
          handleNamelessEvent,
          handleNoDuration
        })
        // If no items selected, but we have a clipboard, use that instead
        if(!scheduleItems || scheduleItems.length === 0) {
          let {clipboard} = getState().global.schedule_clipboard
          if(clipboard.length === 0) {
            return
          }
          scheduleItems = constructItemsFromClipboard([addTime], clipboard, scheduleSwap)
        }
        scheduleSwap = scheduleSwap.removeItem(toReplace)
        scheduleSwap = scheduleSwap.addItems(scheduleItems)
      }
      dispatch(actionCreators.setScheduleSwap(scheduleSwap),
        'One or more of the items being added are overlapping with items that are already in the schedule. Continue?')
    }
  },

  /**
   * Adds items to the clipboard, then deselects all items.
   * @param {array} items An array of keys indicating what items to add to the clipboard.
   *  If an empty array or anything other than an array are passed in, it will add all currently selected items to clipboard.
   */
  copyScheduleItems: (items=[]) => {
    return (dispatch, getState) => {
      if(!(items instanceof Array) || items.length === 0) {
        items = getState().local.present.selectedScheduleItems
      }
      let {scheduleSwap} = getState().local.present
      items = items.map((selectedItem) => {
        return scheduleSwap.findByKey(selectedItem)
      }).filter((item) => item)
      items = items.sort((a, b) => {
        return a.span.start.diff(b.span.start)
      })
      items = items.map(item => item.clone())
      dispatch.global(clipboardScheduleItems(items));
      dispatch({
        type: SELECT_SCHEDULE_NONE
      })
      dispatch(actionCreators.scheduleLibraryDeselect())
    }
  },

  /**
   * Adds items to the clipboard, then deletes those items from the schedule and deselects all items.
   * @param {array} items An array of selection objects indicating what items to add to the clipboard and then delete.
   *  If an empty array or anything other than an array are passed in, it will add all currently selected items to clipboard and then delete them.
   */
  moveScheduleItems: (items=[]) => {
    return async (dispatch, getState) => {
      if(!(items instanceof Array) || items.length === 0) {
        items = getState().local.present.selectedScheduleItems
      }
      let {scheduleSwap} = getState().local.present
      items = items.map((selectedItem) => {
        return scheduleSwap.findByKey(selectedItem)
      }).filter((item) => item)
      items = items.sort((a, b) => {
        return a.span.start.diff(b.span.start)
      })
      let toRemove = items.map(item => item.key)
      items = items.map(item => item.clone())
      dispatch.global(clipboardScheduleItems(items));
      dispatch(actionCreators.removeItems(toRemove))
      dispatch({
        type: SELECT_SCHEDULE_NONE
      })
      dispatch(actionCreators.scheduleLibraryDeselect())
    }
  },

  /**
   * Adds all items in the clipboard to a given time. The earliest item in the clipboard will be added at
   * time, and then positions of other items in the clipboard will be maintained relative to that earliest item.
   * @param {object} time An IntervalTime or moment.js time object indicating what time to paste the items to
   */
  pasteClipboardItems: (time) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      let {clipboard} = getState().global.schedule_clipboard
      if(clipboard.length === 0) {
        return
      }
      if(!(time instanceof Array)) {
        time = [time]
      }
      let toAdd = constructItemsFromClipboard(time, clipboard, scheduleSwap)
      scheduleSwap = scheduleSwap.addItems(toAdd)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap),
        'One or more of the items being added are overlapping with items that are already in the schedule. Continue?')
    }
  },

  /**
   * Moves the items/blocks which have the corresponding keys directly to a timeslot without cutting it to the clipboard (and overriding the current clipboard).
   * @param {array} keys An array of string keys of items to be moved
   * @param {object} time An IntervalTime or moment.js time object indicating what time to move the item to
   */
  moveKeysToTime: (keys, time) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      if(!keys) {
        return
      }
      let firstTime = scheduleSwap.findByKey(keys[0]).span.start.add(0)
      for(let key of keys) {
        let item = scheduleSwap.findByKey(key)
        let timeDiff = item.span.start.diff(firstTime)
        let newTime = time.clone().add(timeDiff, "milliseconds")
        scheduleSwap = scheduleSwap.moveItem(key, newTime);
      }
      dispatch(actionCreators.setScheduleSwap(scheduleSwap),
        'The moved item will be overlapping with other items that are in the schedule. Continue?')
    }
  },

  /**
   * Moves selected items directly to a timeslot without cutting it to the clipboard (and overriding the current clipboard).
   * @param {object} time An IntervalTime or moment.js time object indicating what time to move the item to
   */
  moveSelectedToTime: (time) => {
    return (dispatch, getState) => {
      let {scheduleSwap, selectedScheduleItems} = getState().local.present
      if(!selectedScheduleItems) {
        return
      }
      let firstTime = scheduleSwap.findByKey(selectedScheduleItems[0]).span.start.add(0)
      for(let key of selectedScheduleItems) {
        let item = scheduleSwap.findByKey(key)
        let timeDiff = item.span.start.diff(firstTime)
        let newTime = time.clone().add(timeDiff, "milliseconds")
        scheduleSwap = scheduleSwap.moveItem(key, newTime);
      }
      dispatch(actionCreators.setScheduleSwap(scheduleSwap),
        'The moved item will be overlapping with other items that are in the schedule. Continue?')
    }
  },

  /**
   * Removes items from the schedule, then deselects all schedule items.
   * @param {array} Array of selection objects indicating what items to delete. If an empty array or non-array is
   *  passed in, then all currently selected items will be deleted.
   */
  removeScheduleItems: (toDelete=[]) => {
    return (dispatch, getState) => {
      if(!(toDelete instanceof Array) || toDelete.length === 0) {
        toDelete = getState().local.present.selectedScheduleItems
      }
      dispatch(actionCreators.removeItems(toDelete))
      dispatch({
        type: SELECT_SCHEDULE_NONE
      })
    }
  },

  clearMissingFiles: () => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      let errors = scheduleSwap.scheduleMissingFiles
      if(!errors || !errors.length) {
        return
      }
      scheduleSwap = scheduleSwap.clearMissingFiles()
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
      //let toDelete = errors.map(err => err.itemKey).filter(item => item !== null)
      //dispatch(actionCreators.removeItems(toDelete))
      //dispatch(actionCreators.checkScheduleMissingFiles())
    }
  },

  removeItems: (toDelete) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      if(toDelete.length === 0) {
        return
      }
      let encounteredLockedItems = false
      for(let key of toDelete) {
        scheduleSwap = scheduleSwap.removeItem(key)
      }
      if(encounteredLockedItems) {
        dispatch.global(messages.alert('One or more items to be deleted are locked, so nothing was deleted.', {level: 'error'}))
        return
      }
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
    }
  },

  /**
   * Removes a schedule block while keeping its children (children will be moved out of the block).
   * If you want to delete a schedule block and its children, use removeScheduleItems instead.
   * @param {number} toDelete The index of the schedule block within scheduleSwap in the state
   */
  removeBlock: (toDelete) => {
    return async (dispatch, getState) => {
      let result = await dispatch.global(messages.confirmAsync('Should the block\'s items be deleted or kept?', {confirmText: 'Delete Items', cancelText: 'Keep Items'}))
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.removeItem(toDelete, {keepChildren: !result})
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
    }
  },

  /**
   * Removes all schedule blocks with a given name from the schedule.
   * @param {string} blockName The name of the block(s) to be removed
   */
  removeBlocksByName: (blockName) => {
    return async (dispatch, getState) => {
      let result = await dispatch.global(messages.confirmAsync('Should the block\'s items be deleted or kept?', {confirmText: 'Delete Items', cancelText: 'Keep Items'}))
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.removeBlock(blockName, !result)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
    }
  },

  /**
   * Renames all schedule blocks with a given name to a new name.
   * @param {string} blockName The name of the block(s) to be renamed
   * @param {string} newName The new name for the block(s)
   */
  renameBlocksByName: (blockName, newName) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.renameBlock(blockName, newName)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
    }
  },

  /**
   * Recolors all schedule blocks with a given name to a new color
   * @param {string} blockName The name of the block(s) to be recolored
   * @param {string} newColor The new color for the block(s)
   */
  recolorBlocksByName: (blockName, newColor) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.changeBlockColor(blockName, newColor)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
    }
  },

  /**
   * Sets the announce attribute of schedule blocks with a given name
   * @param {string} blockName The name of the block(s) to set announce on
   * @param {string} announce The new value to set the announce attribute to
   */
  setBlocksAnnounceByName: (blockName, announce) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.changeBlockAnnounce(blockName, announce)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
    }
  },

  /**
   * Sends an event to the schedule table telling it to scroll a given time into view.
   * @param {object} time A moment.js time object indicating what time should be scrolled into view
   */
  scrollToTime: (time) => {
    return {
      type: SCROLL_TO_TIME,
      payload: time
    }
  },

  /**
   * Changes view and scrolls to a given time
   * @param {object} time A moment.js time object indicating what time to go to
   */
  goToTime: (time) => {
    return (dispatch, getState) => {
      dispatch({
        type: CHANGE_SCHEDULE_VIEW,
        payload: time
      })
      dispatch({
        type: SCROLL_TO_TIME,
        payload: time
      })
    }
  },

  /**
   * Sends an event to the schedule table telling it to scroll to the time of the first error in the schedule.
   * If the error is on a different day, the schedule's view will be changed to that day before the event is sent.
   */
  scrollToError: () => {
    return (dispatch, getState) => {
      let {viewTime, viewedError, scheduleSwap} = getState().local.present
      let {errors, type} = scheduleSwap
      if(errors.length === 0) {
        return
      }
      viewedError = (viewedError + 1) % errors.length
      if(viewedError < 0) {
        viewedError = 0
      }
      let toRetrieve = errors[viewedError]
      let firstError = null;
      for(let item of scheduleSwap) {
        if(item.key === toRetrieve.itemKey) {
          firstError = item;
        } else if(item.type === "block") {
          for(let child of item.body.contains) {
            if(child.key === toRetrieve.itemKey) {
              firstError = child;
            }
          }
        }
        if(firstError) {
          break;
        }
      }
      if(!firstError) {
        return
      }
      let changeView = null
      switch(type) {
        case 'weekly':
          changeView = (viewTime.day() === firstError.span.start.day()) ?
            null :
            firstError.span.start.day()
          break
        case 'interval':
          changeView = (viewTime.dayOfYear() === firstError.span.start.dayOfYear()) ?
            null :
            firstError.span.start.dayOfYear()
          break
        case 'monthly':
          changeView = (viewTime.date() === firstError.span.start.date()) ?
            null :
            firstError.span.start
          break
        case 'yearly':
          changeView = (viewTime.month() === firstError.span.start.month() &&
            viewTime.date() === firstError.span.start.date()) ?
            null :
            firstError.span.start
          break
        case 'endless':
          changeView = (viewTime.year() === firstError.span.start.year() &&
            viewTime.month() === firstError.span.start.month() &&
            viewTime.date() === firstError.span.start.date()) ?
            null :
            firstError.span.start
          break
        default:
          break
      }
      if(changeView !== null) {
        dispatch({
          type: CHANGE_SCHEDULE_VIEW,
          payload: changeView
        })
      }
      dispatch({
        type: SET_VIEWED_ERROR,
        payload: viewedError
      })
      dispatch({
        type: SCROLL_TO_TIME,
        payload: firstError.span.start
      })
    }
  },

  /**
   * Called when the item being scrolled to is scrolled into view to stop the scroll event
   */
  finishTimeScroll: () => ({type: FINISH_TIME_SCROLL}),

  /**
   * Shifts an item in the schedule, pushing items it runs into along with it so that there
   * are no collisions between items after the shift (Think about cars on a railroad in terms of how it shifts.)
   * @param {number} shiftTime The number of milliseconds to shift the item (can be 0)
   * @param {boolean} shiftDirection If true, shove item into the past. Otherwise, shove item into the future.
   * @param {string} shiftSelect A uuid key indicating what item to shift. If null and there is only one
   * selected item, then that item will be shifted. Otherwise, no items will be shifted.
   */
  shiftItem: (shiftTime, shiftDirection, shiftSelect=null) => {
    return async (dispatch, getState) => {
      let {selectedScheduleItems,
        scheduleSwap} = getState().local.present
      if(shiftSelect === null) {
        if(selectedScheduleItems.length !== 1) {
          return
        }
        shiftSelect = selectedScheduleItems[0]
      }
      scheduleSwap = scheduleSwap.shoveItem(shiftSelect, shiftTime, shiftDirection)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
      dispatch({
        type: SELECT_SCHEDULE_NONE
      })
    }
  },

  /**
   * Shifts an item so that it no longer overlaps with another item in the schedule, pushing items it runs into along with it so that there
   * are no collisions between items after the shift (Think about cars on a railroad in terms of how it shifts.)
   * @param {boolean} shiftDirection If true, shove item into the past. Otherwise, shove item into the future.
   * @param {string} shiftSelect A uuid key indicating what item to shift.
   */
  itemBeShifted: (shiftDirection, shiftSelect) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      if(!shiftSelect) {
        return
      }
      if(shiftDirection) {
        shiftSelect = scheduleSwap.nextSiblingOf(shiftSelect)
      } else {
        shiftSelect = scheduleSwap.previousSiblingOf(shiftSelect)
      }
      if(!shiftSelect) {
        console.error(`No sibling ${shiftDirection ? "in front of" : "behind"} the given item.`)
        return
      }
      dispatch(actionCreators.shiftItem(0, shiftDirection, shiftSelect.key))
    }
  },

  /**
   * Changes the duration of a schedule item
   * @param {string} item The uuid key of the item to change the duration of
   * @param {number} newDuration The number of milliseconds to set the new duration of the item to.
   */
  changeScheduleItemDuration: (item, newDuration) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      let target = scheduleSwap.findByKey(item)
      if(target.lock) {
        dispatch.global(messages.alert('Cannot change the duration of locked items', {level: 'error'}))
        return
      }
      let newEnd
      // Moments are mutated in-place and do NOT return themselves
      if(scheduleSwap.type === "endless") {
        newEnd = target.span.start.clone()
        newEnd.add(newDuration)
      } else {
        newEnd = target.span.start.add(newDuration)
      }
      scheduleSwap = scheduleSwap.changeItemSpan(item, null, newEnd)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap), 'There is not enough room. Continue?')
    }
  },

  /**
   * Changes the label of a schedule item
   * @param {string} item The uuid key indicating which item to change the label of
   * @param {string} initialValue The item's current label, if it has one. Optional.
   */
  editScheduleItemLabel: (item, initialValue) => {
    return async (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      let target = scheduleSwap.findByKey(item)
      if(!target) {
        dispatch.global(messages.alert(`Tried to change the label of the item with key ${item}, but could not find such an item in the schedule.`, {level: 'error'}))
        return
      }
      if(target.lock) {
        dispatch.global(messages.alert('Cannot change the label of locked items', {level: 'error'}))
        return
      }
      let newLabel = await dispatch.global(messages.promptAsync("What should the label of this item be?", {initialValue: target.label}))
      if(newLabel === null) {
        return
      }
      scheduleSwap = scheduleSwap.changeItemLabel(item, newLabel)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
    }
  },

  /**
   * Expands a schedule block
   * @param {number/object} time The number of milliseconds to expand the schedule block, or a moment.js duration object indicating how much to expand the block by
   * @param {object} options Options for schedule block expansion
   * @param {string} [options.key=null] The string key of the block to expand within scheduleSwap, or the key of an item within a block. If not set,
   *  only one item is selected, and that item is within a block, then that item's parent block will be exapnded. Otherwise
   *  no block will be expanded
   * @param {boolean} [options.backwards=false] If true, expand start of block into the past, otherwise expand end of block into the future. Defaults to false.
   * @param {boolean} [options.munch=false] If true, block will take in any items it collides with after expanding as children. If set to false, it will push any items it collides
   * with so that they are no longer colliding. Defaults to false.
   */
  expandScheduleBlock: (time, options={}) => {
    return (dispatch, getState) => {
      let {key=null, backwards=false, munch=false} = options
      let {selectedScheduleItems, scheduleSwap} = getState().local.present
      if(!key) {
        if(selectedScheduleItems.length === 0) {
          return
        }
        key = selectedScheduleItems[0]
      }
      let target = scheduleSwap.findByKey(key)
      if(target.type !== "block") {
        target = scheduleSwap.findParentOf(key)
        if(!target || target instanceof Schedule) {
          dispatch.global(messages.alert(`The item with key ${key} is not a schedule block, nor is it contained by a schedule block.`, {level: 'error'}))
          return
        }
        key = target.key
      }
      if(!target) {
        dispatch.global(messages.alert(`Tried to expand the schedule block with key ${key}, but a block with that key could not be found`, {level: 'error'}))
        return
      }
      if(target.lock) {
        dispatch.global(messages.alert("Cannot expand locked schedule blocks", {level: 'error'}))
        return
      }
      if(backwards) {
        scheduleSwap = scheduleSwap.changeItemSpan(key, target.span.start.subtract(time), null, {blockMunch: munch})
      } else {
        scheduleSwap = scheduleSwap.changeItemSpan(key, null, target.span.end.add(time), {blockMunch: munch})
      }
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
      dispatch({type: SELECT_SCHEDULE_NONE})
    }
  },

  /**
   * Fits a schedule block to its contents
   * @param {string} key The uuid key of the schedule block within the schedule
   */
  fitScheduleBlock: (key) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.fitBlockToContents(key)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
      dispatch({type: SELECT_SCHEDULE_NONE})
    }
  },

  /**
   * Closes all gaps between all selected items. Items will be moved inwards towards a given anchor.
   * @param {string} anchor String uuid indicating which item to use as the anchor. The anchor will not
   *  move, and other selected items will be moved towards in so that all selected items, and any items in between selected items,
   *  are in one contiguous block with no time gaps.
   */
  collapseScheduleGap: (anchor) => {
    return (dispatch, getState) => {
      let {selectedScheduleItems, scheduleSwap} = getState().local.present
      let selectedItems = [...selectedScheduleItems, anchor]
      try {
        let start, end;
        scheduleSwap.forEachItem((item) => {
          if(selectedItems.includes(item.key)) {
            if(!start) {
              start = item.key
            }
            end = item.key
          }
        })
        if(start && end) {
          scheduleSwap = scheduleSwap.closeGap(anchor, start, end)
        }
      } catch (err) {
        if(err.type === 'ERR_LOCKED') {
          dispatch.global(messages.alert(err.message, {level: 'error'}))
        } else {
          throw err
        }
      }
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
      dispatch({type: SELECT_SCHEDULE_NONE})
    }
  },

  /**
   * Toggles whether one or more items should loop or not
   * @param {boolean} setTo Boolean value to set items' loop value to
   * @param {array} items An array of selection objects indicating what items to toggle. If no items are passed in,
   * then all selected items will be toggled.
   */
  toggleItemLoop: (setTo, items=[]) => {
    return {
      type: TOGGLE_ITEM_LOOP,
      payload: {
        setTo,
        items
      }
    }
  },

  /**
   * Toggles whether one or more items should be locked or not. Locked items cannot be moved from their positions in the schedule, nor
   * can they be deleted.
   * @param {boolean} setTo Boolean value indicating whether to lock (true) or unlock (false) items.
   * @param {array} items An array of selection objects indicating what items to lock/unlock. If no items are passed in,
   * then all selected items will be locked/unlocked.
   */
  toggleScheduleItemLock: (setTo, items=[]) => {
    return {
      type: TOGGLE_SCHEDULE_ITEM_LOCK,
      payload: {
        setTo,
        items
      }
    }
  },

  /**
   * Sets or removes the scte=35 event for one or more items.
   * @param {string} scte35 The scte35 event data to set on the items as a comma-separated list of key=value pairs, or an empty string.
   *  If a valid string list of pairs is passed, all given items will have their scte-35 event set to that string
   *  If an empty string is passed, all given items will have their scte-35 event removed
   * @param {array} items An array of selection objects indicating what items to set the scte-35 event of. If no items are passed in,
   *  then all selected items will be affected.
   */
  setItemSCTE35: (setTo, items=[]) => {
    return {
      type: SET_ITEM_SCTE35,
      payload: {
        setTo,
        items
      }
    }
  },

  /**
   * Creates a new schedule block. If the new block overlaps with items, they will be added as its children.
   * @param {string} name The name to give the schedule block
   * @param {object|array} times An array of IntervalTimes indicating the start of the schedule block. Passing a single IntervalTime
   *  is also acceptable
   * @param {number} duration The duration of the block in milliseconds
   */
  createScheduleBlock: (name, times, duration) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      if(!(times instanceof Array)) {
        times = [times]
      }
      for(let start of times) {
        let blockToAdd = {
          block: name,
          start,
          end: start.clone().add(duration)
        }
        scheduleSwap = scheduleSwap.addBlock(blockToAdd)
      }
      dispatch(actionCreators.setScheduleSwap(scheduleSwap), 'The schedule block being added conflicts with existing items/blocks. Continue?')
    }
  },

  /**
   * Replicates a number of items to multiple given times
   * @param {array} times An array of times to replicate to.
   * @param {array} [items] An array of string keys of items to be replicated. If not passed,
   *  then the currently selected schedule items will be used
   */
  replicate: (times, items=null) => {
    return (dispatch, getState) => {
      let {scheduleSwap, selectedScheduleItems} = getState().local.present
      if(!items) {
        items = selectedScheduleItems
      }
      scheduleSwap = scheduleSwap.replicate(items, times)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap), 'There are additional conflicts after replication. Continue?')
    }
  },

  /**
   * Replicates all items on a given day to multiple other given days
   * @param {IntervalTime} sourceDay The day to replicate from
   * @param {array} targetDays An array of interval days indicating what days to replicate to
   * @param {boolean} [includeBlocks=false] If true, replicate blocks normally. If false, only replicate
   *  the children of blocks without the parent block itself
   */
  replicateDay: (sourceDay, targetDays, includeBlocks=false) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.replicateScheduleDay(sourceDay, targetDays, includeBlocks)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap), 'There are additional conflicts after replication. Continue?')
    }
  },

  /**
   * Replicates items and properties within a schedule block to other blocks of the same name, then deselects all items
   * @param {string} direction Which direction to replicate items in. Can be either 'forward', 'backward', or 'both'
   * @param {string} key The key of the schedule block to be replicated
   */
  replicateBlock: (direction, key) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      if(!key) {
        return
      }
      scheduleSwap = scheduleSwap.copyScheduleBlock(key, direction)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap), 'There are additional conflicts after replication. Continue?')
      dispatch({type: SELECT_SCHEDULE_NONE})
    }
  },

  /**
   * Sets the value of a default schedule item
   * @param {string} defaultType The type of default to set, with the following values:
   *  'global': The global default in a schedule
   *  'daily': The daily default for the currently viewed day. If the schedule is a daily schedule, can be used interchangeably with
   *    global.
   *  'overlayOne': The first overlay item for the currently viewed day
   *  'overlayTwo': The second overlay item for the currently viewed day
   */
  setDefaultItem: (defaultType) => {
    return (dispatch, getState) => {
      let selectedItem = getState().local.present.selectedFiles[0]
      if(!selectedItem) {
        return
      }
      let libraryItem = getIn(getState().global.file_list.fileData, dataPath(selectedItem))
      if(!libraryItem) {
        return
      }
      dispatch({
        type: SET_DEFAULT_ITEM,
        payload: {
          defaultType,
          itemToSet: selectedItem.join('/'),
          item: libraryItem
        }
      })
    }
  },

  /**
   * Deletes the value of a default schedule item
   * @param {string} defaultType The type of default to set, with the following values:
   *  'global': The global default in a schedule
   *  'daily': The daily default for the currently viewed day. If the schedule is a daily schedule, can be used interchangeably with
   *    global.
   *  'overlayOne': The first overlay item for the currently viewed day
   *  'overlayTwo': The second overlay item for the currently viewed day
   */
  removeDefaultItem: (defaultType) => ({
    type: REMOVE_DEFAULT_ITEM,
    payload: defaultType
  }),

  /**
   * Changes the interval length of the default time slots.
   * @param {number} value The new length of the default time slots in minutes.
   */
  changeTimeSlotLength: (value) => ({
    type: CHANGE_TIME_SLOT_LENGTH,
    payload: value
  }),

  /**
   * Undoes the last undoable schedule action (undoable actions are any action defined in the filter param of undoable defined below the reducer)
   */
  undoScheduleChange: () => ({
    type: SCHEDULE_UNDO
  }),

  /**
   * Repeats the last undoable schedule action that was previously undone
   */
  redoScheduleChange: () => ({
    type: SCHEDULE_REDO
  }),

  /**
   * Reverts all changes made to the schedule
   */
  revertSchedule: () => {
    return (dispatch, getState) => {
      let {schedule, type, showSubseconds} = getState().local.present
      let errors = validateSchedule(schedule, type, null, {noMillis: !showSubseconds})
      dispatch({type: SCHEDULE_REVERT, payload: {
        scheduleSwap: schedule,
        scheduleErrors: errors,
        selectedScheduleItems: []
      }})
      dispatch(actionCreators.checkScheduleMissingFiles())
    }
  },

  /**
   * Clears the currently viewed day in the schedule of all items
   */
  clearScheduleDay: () => {
    return (dispatch, getState) => {
      let {scheduleSwap, viewTime} = getState().local.present
      scheduleSwap = scheduleSwap.clearDay(viewTime)
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
      dispatch({type: SELECT_SCHEDULE_NONE})
    }
  },

  /**
   * Clears the schedule of all items
   */
  clearScheduleAll: () => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.clearSchedule()
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
      dispatch({type: SELECT_SCHEDULE_NONE})
    }
  },

  /**
   * Sets whether to show/handle time values in milliseconds
   * @param {boolean} value If true, millisecond values of times will be shown. If false, milliseconds will not be shown.
   */
  setSubsecondVisibility: (value) => ({
    type: SET_SUBSECOND_VISIBILITY,
    payload: value
  }),

  /**
   * Navigates the library component of the schedule application to the folder containing the given file.
   * @param {string} path The path of the file to navigate to
   */
  navigateToFile: (path) => {
    let navpath = null
    if(path) {
      navpath = path.split('/')
    }
    return {
      type: SCHEDULE_LIBRARY_UPDATE,
      payload: {
        navpath
      }
    }
  },

  /**
   * Creates a new schedule of a given type, changing the schedule editor over to editing this new schedule.
   * Unsaved changes to the current schedule will be lost.
   * @param {string} name The name to be given to the new schedule (defaults to NEW_SCHEDULE)
   * @param {string} type String indicating the type of new schedule to create. Must be a valid schedule type. Defaults to 'daily'.
   */
  createNewSchedule: (name='NEW_SCHEDULE', type='daily') => ({
    type: CREATE_NEW_SCHEDULE,
    payload: {name, type}
  }),

  /**
   * Sets the interval duration for interval schedules.
   * @param {number} newDuration The new interval duration in days
   */
  changeIntervalDuration: (newDuration) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.setInterval({duration: newDuration})
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
    }
  },

  /**
   * Sets the starting day for interval schedules.
   * @param {object} newBasis A moment.js object indicating what day the interval starts at.
   */
  changeIntervalBasis: (newBasis) => {
    return (dispatch, getState) => {
      let {scheduleSwap, viewTime} = getState().local.present
      let oldBasis = scheduleSwap.intervalBasis
      newBasis = newBasis.hours(0).minutes(0).seconds(0).milliseconds(0)
      let newView = moment(newBasis)
      scheduleSwap = scheduleSwap.setInterval({basis: newBasis})
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
      let dayNumber = (Math.trunc(viewTime.diff(oldBasis, "days")) % scheduleSwap.intervalDuration)
      if(dayNumber < 0) {
        dayNumber += scheduleSwap.intervalDuration
      }
      newView.add(dayNumber, "days")
      dispatch(actionCreators.changeScheduleView(newView))
    }
  },

  /**
   * Creates a new event, which can be optionally given parameters.
   * @param {object} params Optional. Any parameters given will override default parameters
   */
  createEvent: (params={}) => {
    return (dispatch, getState) => {
      let {eventsList} = getState().local.present.scheduleSwap
      if(params.name && Object.entries(eventsList).find(([key, data]) => (data.name === params.name))) {
        dispatch.global(messages.alert("That event name is already in use. Event names must be unique.", {level: 'error'}))
        return
      }
      if(params.uuid) {
        delete params.uuid
      }
      let newID = ''
      do {
        newID = uuidv4()
      } while (eventsList[newID])
      let newEvent = Object.assign({}, {name: '', duration: moment.duration(0), uuid: newID, assigned: false}, params)
      let newEventsList = setIn(eventsList, [newID], newEvent)
      dispatch({
        type: CREATE_EVENT,
        payload: newEventsList
      })
      dispatch(actionCreators.checkEventsFilled())
    }
  },

  /**
   * Changes the info of a given event.
   * @param {string} id The guid of the event to change
   * @param {object} newInfo Key/value pairs to be merged into the existing event's data
   */
  changeEvent: (id, newInfo) => {
    return async (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      let {eventsList} = scheduleSwap
      if(newInfo.name && Object.entries(eventsList).find(([key, data]) => (key !== id && data.name === newInfo.name))) {
        dispatch.global(messages.alert("That event name is already in use. Event names must be unique.", {level: 'error'}))
        return
      }
      if(newInfo.duration && !Moment.isDuration(newInfo.duration)) {
        try {
          newInfo.duration = convertDurationString(newInfo.duration)
        } catch (err) {
          if(err.message === 'INVALID_DURATION') {
            console.warn('Invalid Duration')
            return
          }
        }
      }
      let newEventsList = setIn(eventsList, [id], Object.assign({}, eventsList[id], newInfo))
      scheduleSwap = scheduleSwap.clone()
      scheduleSwap.eventsList = newEventsList
      if(newInfo.name) {
        scheduleSwap = await scheduleSwap.checkEventsExist()
      }
      scheduleSwap = scheduleSwap.updateEvents()
      dispatch(actionCreators.setScheduleSwap(scheduleSwap, 'The change being made to the event introduces conflicts. Continue?'))
      /*
      let errors = validateSchedule(tempSched, type, null, {noMillis: !showSubseconds})
      let changeAction = {
        type: CHANGE_EVENT,
        payload: {
          newEventsList,
          tempSched,
          errors
        }
      }
      if(errors.length > scheduleErrors.length) {
        let result = await dispatch.global(messages.confirmAsync('The change being made to the event introduces conflicts. Continue?'))
        if(result) {
          dispatch(changeAction)
        }
      } else {
        dispatch(changeAction)
      }
      */
    }
  },

  /**
   * Deletes the event with the given id
   * @param {string} id The guid of the event to delete
   */
  removeEvent: (id) => {
    return async (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      let {eventsList} = scheduleSwap
      let newEventsList = {...eventsList}
      if(newEventsList[id]) {
        delete newEventsList[id]
      }
      scheduleSwap.eventsList = newEventsList
      scheduleSwap = scheduleSwap.updateEvents()
      dispatch(actionCreators.setScheduleSwap(scheduleSwap, 'The change being made to the event introduces conflicts. Continue?'))
      /*
      let tempSched = updateEvents(scheduleSwap, newEventsList, type)
      let errors = validateSchedule(tempSched, type, null, {noMillis: !showSubseconds})
      let removeAction = {
        type: REMOVE_EVENT,
        payload: {
          newEventsList,
          tempSched,
          errors
        }
      }
      if(errors.length > scheduleErrors.length) {
        let result = await dispatch.global(messages.confirm('The change being made to the event introduces conflicts. Continue?'))
        if(result) {
          dispatch(removeAction)
        }
      } else {
        dispatch(removeAction)
      }
      */
    }
  },

  /**
   * Selects or deselects one or more events
   * @param {array} ids An array of event uuids to be selected
   * @param {object} options Optional arguments
   * @param {bool} options.exclusive If true, any uuids not in ids that are currently selected will be deselected.
   * @param {string} options.mode Valid values are 'select', 'deselect', and 'default'. Defaults to 'default'.
   *  'select' will ensure all elements in ids are selected
   *  'deselect' will ensure all elements in ids are not selected
   *  'default' will act as 'select', unless ALL items in ids are already selected, in which case it will deselect them instead.
   */
  selectEvents: (ids, options={}) => ({
    type: SELECT_EVENTS,
    payload: {
      ids,
      options
    }
  }),

  /**
   * Selects or deselects one or more blocks
   * @param {array} names An array of block names to be selected
   * @param {object} options Optional arguments
   * @param {bool} options.exclusive If true, any blocks not in "names" that are currently selected will be deselected.
   * @param {string} options.mode Valid values are 'select', 'deselect', and 'default'. Defaults to 'default'.
   *  'select' will ensure all elements in names are selected
   *  'deselect' will ensure all elements in names are not selected
   *  'default' will act as 'select', unless ALL items in names are already selected, in which case it will deselect them instead.
   */
  selectBlocks: (names, options={}) => ({
    type: SELECT_BLOCKS,
    payload: {
      names,
      options
    }
  }),

  /**
   * Checks to see if the events have items assigned to them or not, and then updates the events that have changed.
   */
  checkEventsFilled: () => {
    return async (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      if(!scheduleSwap) {
        return
      }
      /*
      let {eventsList, type} = scheduleSwap
      // Load/reload event play folder contents
      await dispatch.global(loadFileData(EVENT_PLAY_FOLDER_PATH))
      let didChange = false
      let newEventsList = {...eventsList}
      Object.entries(eventsList).forEach(([id, evt]) => {
        // The full getState path for fileData is used again here in case file data was updated by the above loadFileData call
        let assignedItem = getIn(getState().global.file_list.fileData, dataPath([...EVENT_PLAY_FOLDER_PATH, evt.name]))
        let assigned = !!assignedItem
        if(assigned !== evt.assigned) {
          newEventsList = setIn(newEventsList, [id, 'assigned'], assigned)
          didChange = true
        }
        if(assigned) {
          let duration = getIn(assignedItem, ['metadata', 'duration'])
          if(duration) {
            let newDuration = moment.duration(parseFloat(duration) * 1000)
            newEventsList = setIn(newEventsList, [id, 'duration'], newDuration)
            didChange = true
          }
        }
      })
      // Avoid rerender if nothing changed
      if(!didChange) {
        return
      }
      scheduleSwap.eventsList = newEventsList
      */
      scheduleSwap = await scheduleSwap.checkEventsExist()
      scheduleSwap = scheduleSwap.updateEvents()
      dispatch(actionCreators.setScheduleSwap(scheduleSwap))
    }
  },

  assignSelectedItemToEvent: (id) => {
    return async (dispatch, getState) => {
      if(getState().local.present.selectedFiles[0]) {
        let res = await dispatch.global(messages.confirmAsync(`Assign /${getState().local.present.selectedFiles[0].join('/')} to this event?`))
        if(res) {
          dispatch(actionCreators.assignItemToEvent(id, getState().local.present.selectedFiles[0]))
        }
      }
    }
  },

  /**
   * Assigns a library item to the event, and then adjusts the event's duration to match the item.
   * @param {string} id The id of the event to be assigned to
   * @param {array} fpath The path of the file to be assigned to the event
   */
  assignItemToEvent: (id, fpath) => {
    return async (dispatch, getState) => {
      let evt = getIn(getState(), ['local', 'present', 'scheduleSwap', 'eventsList', id])
      let libraryItem = getIn(getState().global.file_list.fileData, dataPath(fpath))
      if(!evt || !libraryItem) {
        return
      }
      let changes = {toCopy: fpath}
      // If the library item has a duration, change the duration of the event to match it
      if(libraryItem.metadata.duration) {
        let span = parseFloat(libraryItem.metadata.duration)
        span = millisToHourString(span * 1000)
        changes = {...changes, duration: span}
      }
      dispatch(actionCreators.changeEvent(id, changes))
    }
  },

  /**
   * Changes the active tab of the sidebar
   * @param {number} index The index of the tab to become active
   */
  changeSidebarTab: (index) => ({
    type: CHANGE_SIDEBAR_TAB,
    payload: index
  }),

  changeScheduleFilepath: (name, dir) => ({
    type: CHANGE_SCHEDULE_FILEPATH,
    payload: {
      name,
      dir
    }
  }),

  checkScheduleMissingFiles: () => {
    return async (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      if(scheduleSwap && scheduleSwap.length) {
        let fileErrors = await scheduleSwap.getFileExistence()
        let eventExistence
        if(scheduleSwap.eventsList) {
          eventExistence = await scheduleSwap.getEventExistence()
        }
        dispatch(actionCreators.updateScheduleMissingFiles(fileErrors, eventExistence))
      }
    }
  },

  updateScheduleMissingFiles: (fileErrors, eventExistence=null) => {
    return (dispatch, getState) => {
      let {scheduleSwap} = getState().local.present
      scheduleSwap = scheduleSwap.setMissingFileErrors(fileErrors)
      if(eventExistence) {
        scheduleSwap = scheduleSwap.setEventExistence(eventExistence)
      }
      dispatch(actionCreators.setScheduleSwap(scheduleSwap, null, {noUndo: true, changed: []}))
    }
  },

  scheduleLibraryDeselect: () => ({
    type: SCHEDULE_LIBRARY_DESELECT,
    payload: Date.now()
  }),

  /**
   * Records the current scroll position of the schedule table, so that it can be restored when navigating back
   * @param {number} scrollTop The value of scrollTop for the scheduleTableContainer
   */
  scheduleTableRecordScroll: (scrollTop) => ({
    type: RECORD_SCROLL,
    payload: scrollTop
  })

}

export const initialState = {
  viewTime: Moment(),                     // Time of schedule being viewed
  timeSlotLength: 30,                     // Maximum length for empty timeslots in minutes
  filterScript: '',                       // Name of filter script for this schedule, or blank if none assigned
  schedule: new Schedule(),               // initial state of the schedule before edits as a Schedule object
  scheduleSwap: null,                     // current state of edited schedule as a Schedule object
  emptyTimeslots: [],                     // Empty timeslot times to display, as an array of IntervalTimes
  filepath: ['mnt', 'main', 'Schedules'], // Path of current schedule being edited
  filename: '',                           // Name of current schedule being edited
  selectedFiles: [],                      // List of files selected in library
  selectedEvents: [],                     // List of events selected in the event tab
  selectedBlocks: [],                     // List of blocks selected in the block tab
  selectedScheduleItems: [],              // List of items selected in schedule
  viewedError: -1,                        // Current error scrolled to by scroll to error, or -1 if an error has not been scrolled to yet
  scrollEvent: {time: null, id: 0},       // Event for scrolling to a given time in the schedule
  clipboard: [],                          // Clipboard for schedule copy/paste
  showSubseconds: false,                  // Whether or not to show milliseconds
  libraryPath: ['mnt', 'main'],           // Navpath for the library, if it needs to be overridden
  libraryDeselect: 0,                     // Change value in order to deselect in library
  tabIndex: 0,                            // Which tab is currently selected in the side pane
  scrollTop: 0,                           // The recorded scrollTop value for the schedule table
}

export const reducer = (state=initialState, action) => {

  let {type, payload} = action

  switch(type) {
    case LOAD_SCHEDULE_DATA: {
      let {data, path} = payload
      if(!data) {
        if(!state.scheduleSwap) {
          return {
            ...state,
            scheduleSwap: state.schedule
          }
        } else {
          return state
        }
      }
      let swap = state.scheduleSwap
      if(swap === null) {
        swap = data.schedule.clone()
      }
      let fullpath = path.split('/')
      if (data.schedule.timeSlotLength && data.schedule.timeSlotLength > 0)
        state.timeSlotLength = data.schedule.timeSlotLength
      return {
        ...state,
        ...data,
        scheduleSwap: swap,
        filepath: fullpath.slice(0, -1),
        filename: fullpath.slice(-1).pop(),
      }
    }
    case SCHEDULE_LIBRARY_UPDATE:
      let selectedFiles = payload.selectedFiles ? payload.selectedFiles : state.selectedFiles
      let libraryPath = payload.navpath ? payload.navpath : state.libraryPath
      if(libraryPath instanceof Array) {
        libraryPath = libraryPath.join("/")
      }
      libraryPath = path.normalize(libraryPath)
      libraryPath = libraryPath.split("/")
      return {
        ...state,
        selectedFiles,
        libraryPath
      }
    case CHANGE_SCHEDULE_VIEW: {
      if(payload instanceof IntervalTime) {
        payload = payload.closestMoment(state.viewTime)
      }
      let {type} = state.scheduleSwap || state.schedule
      switch(type) {
        case 'weekly':
          return {
            ...state,
            viewTime: state.viewTime.clone().day(Moment.isMoment(payload) ?
              payload.day() :
              payload)
          }
        case 'monthly':
        case 'yearly':
        case 'endless':
          return {
            ...state,
            viewTime: payload
          }
        case 'interval':
          return {
            ...state,
            viewTime: state.viewTime.clone().dayOfYear(Moment.isMoment(payload) ?
              payload.dayOfYear() :
              payload)
          }
        default:
          return state
      }
    }
    case SET_SCHEDULE_SWAP_NO_UNDO:
    case SET_SCHEDULE_SWAP: {
      return {
        ...state,
        scheduleSwap: payload,
      }
    }
    case ADD_EMPTY_TIMESLOT: {
      let {emptyTimeslots} = state
      emptyTimeslots = [...emptyTimeslots, payload]
      emptyTimeslots = emptyTimeslots.sort((a, b) => a.diff(b))
      console.log(emptyTimeslots)
      return {
        ...state,
        emptyTimeslots
      }
    }
    case CLIPBOARD_SCHEDULE_ITEMS:
      let items = payload
      items = items.map((selectedItem) => {
        if(selectedItem.child === -1) {
          return {...state.scheduleSwap[selectedItem.index]}
        } else {
          return {...state.scheduleSwap[selectedItem.index].body.contains[selectedItem.child]}
        }
      })
      items = items.sort((a, b) => {
        return a.span.start.diff(b.span.start)
      })
      return {
        ...state,
        clipboard: items,
      }
    case PASTE_CLIPBOARD_ITEMS: {
      let {tempSched, errors} = payload
      return {
        ...state,
        scheduleSwap: tempSched,
        scheduleErrors: errors
      }
    }
    case REMOVE_SCHEDULE_ITEMS: {
      let {tempSched, errors} = payload
      return {
        ...state,
        scheduleSwap: tempSched,
        scheduleErrors: errors
      }
    }
    case REMOVE_BLOCK: {
      return {
        ...state,
        scheduleSwap: payload.scheduleSwap,
        scheduleErrors: payload.scheduleErrors
      }
    }
    case SELECT_SCHEDULE_ITEM:
      let {toSelect, options} = payload
      let {exclusive=false, range=false, rangeStart=null, method='toggle'} = options
      let {scheduleSwap, selectedScheduleItems} = state
      if(range && (rangeStart || selectedScheduleItems.length)) {
        let start = rangeStart ? rangeStart : selectedScheduleItems[selectedScheduleItems.length - 1]
        let end = toSelect[0]
        toSelect = []
        let selecting = false
        scheduleSwap.forEachItem((item, [i, j]) => {
          // start and end could be in either order
          if(item.key === start || item.key === end) {
            toSelect.push(item.key)
            selecting = !selecting
          } else if (selecting) {
            toSelect.push(item.key)
          }
        })
        /*
        if(end.index < start.index) {
          let temp = end
          end = start
          start = temp
        }
        for(let i = start.index; i <= end.index; i++) {
          if(scheduleSwap[i].type === 'block') {
            let j = 0;
            let jMax = scheduleSwap[i].body.contains.length
            if(i === start.index && start.child > -1) {
              j = start.child
            }
            if(i === end.index && end.child > -1) {
              jMax = end.child + 1
            }
            for(; j < jMax; j++) {
              toSelect.push({index: i, child: j})
            }
          } else {
            toSelect.push({index: i, child: -1})
          }
        }
        */
      }
      let newSelections = toSelect.filter((newSelection) => {
        return !(selectedScheduleItems.includes(newSelection))
      })
      if(newSelections.length > 0  && (method === 'toggle' || method === 'select')) {
        if(!exclusive) {
          toSelect = selectedScheduleItems.concat(newSelections)
        }
      } else if(method === 'select' && !exclusive) {
        return state
      } else if(method === 'toggle' || method === 'deselect') {
        if(exclusive) {
          toSelect = []
        } else {
          toSelect = selectedScheduleItems.filter((existingSelection) => {
            return !toSelect.includes(existingSelection)
          })
        }
      }
      return {
        ...state,
        selectedScheduleItems: toSelect
      }
    case SCROLL_TO_TIME:
      if (payload instanceof IntervalTime) {
        payload = payload.closestMoment(state.viewTime)
      }
      payload = payload.clone().date(state.viewTime.date()).month(state.viewTime.month())
      return {
        ...state,
        scrollEvent: {time: payload, id: Date.now()}
      }
    case FINISH_TIME_SCROLL:
      return {
        ...state,
        scrollEvent: {
          ...state.scrollEvent,
          id: 0
        }
      }
    case SET_VIEWED_ERROR:
     return {
      ...state,
      viewedError: payload
     }
    case SHIFT_SCHEDULE_ITEM: {
      let {tempSched, errors} = payload
      return {
        ...state,
        scheduleSwap: tempSched,
        scheduleErrors: errors,
      }
    }
    case CHANGE_SCHEDULE_ITEM_DURATION: {
      let {errors, tempSched} = payload
      return {
        ...state,
        scheduleSwap: tempSched,
        scheduleErrors: errors
      }
    }
    case EXPAND_SCHEDULE_BLOCK: {
      let {tempSched, errors} = payload
      return {
        ...state,
        scheduleSwap: tempSched,
        scheduleErrors: errors
      }
    }
    case CLOSE_SCHEDULE_GAP: {
      let {tempSched, errors} = payload
      return {
        ...state,
        scheduleSwap: tempSched,
        scheduleErrors: errors
      }
    }
    case TOGGLE_ITEM_LOOP: {
      let {setTo, items} = payload
      let {scheduleSwap, selectedScheduleItems} = state
      if(!items || !items.length) {
        items = selectedScheduleItems
      }
      for(let item of items) {
        scheduleSwap = scheduleSwap.setItemLoop(item, setTo)
      }
      return {
        ...state,
        scheduleSwap
      }
    }
    case TOGGLE_SCHEDULE_ITEM_LOCK: {
      let {setTo, items} = payload
      let {scheduleSwap, selectedScheduleItems} = state
      if(!items || !items.length) {
        items = selectedScheduleItems
      }
      for(let item of items) {
        scheduleSwap = scheduleSwap.setItemLock(item, setTo)
      }
      return {
        ...state,
        scheduleSwap
      }
    }
    case SET_ITEM_SCTE35: {
      let {setTo, items} = payload
      let {scheduleSwap, selectedScheduleItems} = state
      if(!items || !items.length) {
        items = selectedScheduleItems
      }
      for(let item of items) {
        scheduleSwap = scheduleSwap.setSCTE35(item, setTo)
      }
      return {
        ...state,
        scheduleSwap
      }
    }
    case CREATE_SCHEDULE_BLOCK: {
      let {tempSched, errors} = payload
      return {
        ...state,
        scheduleSwap: tempSched,
        scheduleErrors: errors
      }
    }
    case REPLICATE_BLOCK_ITEMS: {
      let {tempSched, errors} = payload
      return {
        ...state,
        scheduleSwap: tempSched,
        scheduleErrors: errors
      }
    }
    case SET_DEFAULT_ITEM: {
      let {itemToSet, item, defaultType} = payload
      let {scheduleSwap, viewTime} = state
      scheduleSwap = scheduleSwap.setDefault(defaultType, viewTime, {fpath: itemToSet, ...item})
      return {
        ...state,
        scheduleSwap
      }
    }
    case REMOVE_DEFAULT_ITEM: {
      let {scheduleSwap, viewTime} = state
      scheduleSwap = scheduleSwap.removeDefault(payload, viewTime)
      return {
        ...state,
        scheduleSwap
      }
    }
    case SELECT_SCHEDULE_NONE:
      return {
        ...state,
        selectedScheduleItems: []
      }
    case CHANGE_TIME_SLOT_LENGTH:
      return {
        ...state,
        timeSlotLength: payload
      }
    case SCHEDULE_REVERT: {
      return {
        ...state,
        ...payload
      }
    }
    case CLEAR_SCHEDULE_DAY: {
      return {
        ...state,
        ...payload
      }
    }
    case CLEAR_SCHEDULE_ALL: {
      return {
        ...state,
        scheduleSwap: [],
        scheduleErrors: []
      }
    }
    case SET_SUBSECOND_VISIBILITY: {
      let {scheduleSwap, type} = state
      let errors = validateSchedule(scheduleSwap, type, null, {noMillis: !payload})
      return {
        ...state,
        scheduleErrors: errors,
        showSubseconds: payload
      }
    }
    case CREATE_NEW_SCHEDULE: {
      let {schedule,
        defaults,
        scheduleErrors,
        scheduleBlocks,
        selectedScheduleItems,
        intervalDuration,
        intervalBasis} = initialState
      let {name, type} = payload
      let viewTime = moment()
      if(type === 'interval') {
        intervalBasis = moment(Date.now()).startOf("day")
        viewTime = intervalBasis.clone()
        intervalDuration = 3
        schedule = new Schedule({type, 'interval duration': intervalDuration, 'interval basis': intervalBasis})
      } else {
        schedule = new Schedule({type})
      }
      let scheduleSwap = schedule
      return {
        ...state,
        schedule,
        scheduleSwap,
        defaults,
        scheduleErrors,
        scheduleBlocks,
        selectedScheduleItems,
        intervalDuration,
        intervalBasis,
        filepath: ['mnt', 'main', 'Schedules'],
        filename: name,
        type,
        viewTime
      }
    }
    case CHANGE_INTERVAL_DURATION: {
      return {
        ...state,
        ...payload
      }
    }
    case CHANGE_INTERVAL_BASIS: {
      return {
        ...state,
        ...payload
      }
    }
    case CREATE_EVENT: {
      let {scheduleSwap} = state
      scheduleSwap.eventsList = payload
      return {
        ...state,
        scheduleSwap
      }
    }
    case CHANGE_EVENT: {
      let {newEventsList, tempSched, errors} = payload
      return {
        ...state,
        eventsList: newEventsList,
        scheduleSwap: tempSched,
        scheduleErrors: errors
      }
    }
    case REMOVE_EVENT: {
      let {newEventsList, tempSched, errors} = payload
      return {
        ...state,
        eventsList: newEventsList,
        scheduleSwap: tempSched,
        scheduleErrors: errors
      }
    }
    case SELECT_EVENTS: {
      let {ids, options} = payload
      let {
        exclusive = false,
        mode = 'default'
      } = options
      if(exclusive && mode === 'deselect') {
        return {
          ...state,
          selectedEvents: []
        }
      }
      let newSelect = [...state.selectedEvents]
      switch(mode) {
        case 'select':
          ids.forEach((id) => {
            if(!newSelect.includes(id)) {
              newSelect.push(id)
            }
          })
          if(exclusive) {
            newSelect = newSelect.filter((id) => ids.includes(id))
          }
          break
        case 'deselect':
          newSelect = newSelect.filter((id) => !(ids.includes(id)))
          break
        default:
          let deselect = true
          ids.forEach((id) => {
            if(!newSelect.includes(id)) {
              deselect = false
              newSelect.push(id)
            }
          })
          if(deselect) {
            newSelect = newSelect.filter((id) => !(ids.includes(id)))
          } else if(exclusive) {
            newSelect = newSelect.filter((id) => ids.includes(id))
          }
          break
      }
      return {
        ...state,
        selectedEvents: newSelect
      }
    }
    case SELECT_BLOCKS: {
      let {names, options} = payload
      let {
        exclusive = false,
        mode = 'default'
      } = options
      if(exclusive && mode === 'deselect') {
        return {
          ...state,
          selectedBlocks: []
        }
      }
      let newSelect = [...state.selectedBlocks]
      switch(mode) {
        case 'select':
          names.forEach((name) => {
            if(!newSelect.includes(name)) {
              newSelect.push(name)
            }
          })
          if(exclusive) {
            newSelect = newSelect.filter((name) => names.includes(name))
          }
          break
        case 'deselect':
          newSelect = newSelect.filter((name) => !(names.includes(name)))
          break
        default:
          let deselect = true
          names.forEach((name) => {
            if(!newSelect.includes(name)) {
              deselect = false
              newSelect.push(name)
            }
          })
          if(deselect) {
            newSelect = newSelect.filter((name) => !(names.includes(name)))
          } else if(exclusive) {
            newSelect = newSelect.filter((name) => names.includes(name))
          }
          break
      }
      return {
        ...state,
        selectedBlocks: newSelect
      }
    }
    case UPDATE_EVENTS_FILLED: {
      let {scheduleSwap} = state
      scheduleSwap.events = payload
      return {
        ...state,
        scheduleSwap
      }
    }
    case CHANGE_SIDEBAR_TAB: {
      return {
        ...state,
        tabIndex: payload
      }
    }
    case SAVE_SCHEDULE: {
      let {scheduleSwap} = state
      // Update the event items from "toCopy" to "Queue" now that they are being copied
      scheduleSwap = scheduleSwap.queueAssignedEventItems()
      return {
        ...state,
        scheduleSwap,
        schedule: scheduleSwap
      }
    }
    case CHANGE_SCHEDULE_FILEPATH: {
      return {
        ...state,
        filename: payload.name,
        filepath: payload.dir
      }
    }
    case UPDATE_MISSING_FILES: {
      return {
        ...state,
        scheduleSwap: payload
      }
    }
    case SCHEDULE_LIBRARY_DESELECT: {
      return {
        ...state,
        libraryDeselect: payload
      }
    }
    case RECORD_SCROLL: {
      return {
        ...state,
        scrollTop: payload
      }
    }
    default:
      return state
  }

}

const undoReducer = undoable(reducer, {
  limit: 10,
  undoType: SCHEDULE_UNDO,
  redoType: SCHEDULE_REDO,
  filter: includeAction([
    SET_SCHEDULE_SWAP,
    ADD_SCHEDULE_ITEMS,
    ADD_EMPTY_TIMESLOT,
    PASTE_CLIPBOARD_ITEMS,
    REMOVE_SCHEDULE_ITEMS,
    REMOVE_BLOCK,
    SHIFT_SCHEDULE_ITEM,
    CHANGE_SCHEDULE_ITEM_DURATION,
    EXPAND_SCHEDULE_BLOCK,
    CLOSE_SCHEDULE_GAP,
    TOGGLE_ITEM_LOOP,
    TOGGLE_SCHEDULE_ITEM_LOCK,
    SET_ITEM_SCTE35,
    CREATE_SCHEDULE_BLOCK,
    REPLICATE_BLOCK_ITEMS,
    SET_DEFAULT_ITEM,
    REMOVE_DEFAULT_ITEM,
    SCHEDULE_REVERT,
    CLEAR_SCHEDULE_DAY,
    CLEAR_SCHEDULE_ALL,
    CREATE_EVENT,
    CHANGE_EVENT,
    REMOVE_EVENT
  ])
})

const loadingReducer = loaderReducer(undoReducer, {present: initialState, past: [], future: []})

const SCHEDULE_DISPLAY = (props) => {
  if(props.present.filename) {
    return props.present.filename
  } else {
    return "(NEW SCHEDULE)"
  }
}

const SCHEDULE_UNSAVED = (props) => {
  return (props.present.schedule && props.present.scheduleSwap && props.present.schedule.versionKey !== props.present.scheduleSwap.versionKey)
}

export default tabbedReducer('schedule', loadingReducer, {present: initialState, past: [], future: []}, {
  changeTab: SCHEDULE_CHANGE_TAB,
  createTab: SCHEDULE_CREATE_TAB,
  deleteTab: SCHEDULE_DELETE_TAB,
  display: SCHEDULE_DISPLAY,
  isUnsaved: SCHEDULE_UNSAVED,
  tabActions: actionCreators,
  onTabCreated
})
