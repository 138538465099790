import {v4 as uuidv4} from 'uuid'
import {getIn, setIn} from 'helpers/general_helpers'

/**
 * TO USE:
 *
 * Import reducer and actions into the duck that needs loading indicators:
 *  import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
 *  import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'
 *
 * Wrap reducer function and initial state with the imported loaderReducer.
 * Generally, wrap should be applied after undoable but before tabbedReducer
 * For undoable reducers (can be exported instead of assinged to a constant unless further passing to tabbed reducer):
 *  const loadingReducer = loaderReducer(undoReducer, {present: initialState, past: [], future: []})
 * For other reducers (same note as above):
 *  loaderReducer(reducer, initialState)
 *
 * Dispatch startLoading action from within thunk action creators to begin loading.
 * It will return a job id; make sure you assign it to a variable for later use
 * Tabbed reducer:
 *  let jobId = dispatch(loading.startLoading(getState().local._loaderID))
 * Other reducer (replace {REDUCER NAME} with the name of the reducer):
 *  let jobId = dispatch(loading.startLoading(getState().{REDUCER NAME}._loaderID))
 *
 * Dispatch finishLoading when async is finished. You will need to pass in the job id from startLoading.
 * Do this in all cases including when the async operation fails, or you will get an infinite loading screen
 * Tabbed reducer:
 *  dispatch(loading.finishLoading(getState().local._loaderID, jobId))
 * Other reducer (replace {REDUCER NAME} with the name of the reducer):
 *  dispatch(loading.finishLoading(getState().{REDUCER NAME}._loaderID, jobId))
 *
 * You will also need to wrap the component that you want the loading indicator to be displayed in with
 * the LoadingIndicator higher order component located at: src/components/higher_order_components/Loader/LoadingIndicator.jsx
 * See that file for its use instructions
 */

export const initialLoaderState = {
  loadingJobs: []  // List of ongoing job uuids
}

export const actions = {

  startLoading: (id) => {
    return (dispatch, getState) => {
      let jobID = uuidv4();
      dispatch({
        type: START_LOADING,
        id,
        payload: jobID
      });
      return jobID;
    }
  },

  finishLoading: (id, jobId) => ({
    type: FINISH_LOADING,
    id,
    payload: jobId
  }),

}

export const START_LOADING = Symbol('start loading');
export const FINISH_LOADING = Symbol('stop loading');

export default (reducer, initialState) => {

  let initState = {
    _loader: initialLoaderState,
    ...initialState
  }

  return function(state=initState, action) {
    let {type, id, payload} = action

    if(!('_loaderID' in state)) {
      state = {
        ...state,
        _loaderID: uuidv4()
      }
    }

    switch(type) {
      case START_LOADING: {
        if(id === state._loaderID) {
          let jobs = []
          if(getIn(state, ['_loader', 'loadingJobs'])) {
            jobs = getIn(state, ['_loader', 'loadingJobs'])
          }
          return setIn(state,
            ['_loader', 'loadingJobs'],
            [...jobs, payload]);
        } else {
          return state
        }
      }
      case FINISH_LOADING: {
        if(id === state._loaderID) {
          let jobs = getIn(state, ['_loader', 'loadingJobs']).filter(jobId => jobId !== payload);
          return setIn(state, ['_loader', 'loadingJobs'], jobs);
        } else {
          return state
        }
      }
      default: {
        return reducer(state, action);
      }
    }
  }

}
