/**
 * Selector that returns the current framelist with the display param of each frame set based on the current preview time.
 */
import { createSelector } from 'reselect'
const getFrameThumbBuffer = (state) => (state.frameThumbBuffer)
const getFrames = (state) => (state.frames)
const getPreviewTime = (state) => (state.previewTime)
const getSelectedFrames = (state) => (state.selectedFrames)

export default createSelector([getFrames, getPreviewTime, getFrameThumbBuffer, getSelectedFrames],
(frames, previewTime, frameThumbBuffer, selectedFrames) => {
  let thumbCache = frameThumbBuffer[previewTime]
  return frames.map((frame, ind) => {
    let thumb, selected;
    if(selectedFrames) {
      selected = (selectedFrames.includes(ind))
    }
    if(thumbCache) {
      thumb = thumbCache[ind]
    }
    return {...frame, display: thumb, selected}
  })
})
