import React, {PureComponent} from 'react'
import Resizable from 're-resizable'
import {DragSource} from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { millisecondsToPixels, pixelsToMilliseconds, magnetize } from 'helpers/playlist_helpers'
import {CUT_TOOL} from 'redux/applications/playlist_editor'

import ClipImages from './ClipImages'

import {CLIP_DRAG} from './DragConstant'

import './Clip.css'

const RESIZE_CLIP = {
  top: false,
  right: true,
  bottom: false,
  left: true,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false
}

class Clip extends PureComponent {

  componentDidMount() {
    let {connectDragPreview} = this.props
    if(connectDragPreview) {
      connectDragPreview(getEmptyImage())
    }
  }

  handleClick = (e) => {
    let {selectedTool,
      zoomLevel,
      clip,
      magnetic,
      magnetTimes,
      previewTime,
      parentIndex,
      index,
      cutFrameClip} = this.props
    if(selectedTool === CUT_TOOL) {
      let left = e.currentTarget.getBoundingClientRect().left
      let mouseX = e.clientX
      let cutTime = clip.start + pixelsToMilliseconds(mouseX - left, zoomLevel)
      if(magnetic) {
        cutTime = magnetize(cutTime, zoomLevel, magnetTimes.concat([previewTime]))
      }
      cutFrameClip(parentIndex, index, cutTime)
    }
  }

  handleMouseDown = (e) => {
    let {selectFrameClip,
      index,
      parentIndex} = this.props
    e.stopPropagation()
    selectFrameClip(parentIndex, index)
  }

  resize = (e, direction, ref, delta) => {
    let {clip,
      zoomLevel,
      viewStart,
      startNeighborTime,
      endNeighborTime,
      previewTime,
      magnetic,
      magnetTimes} = this.props
    let newWidth = parseFloat(ref.style.width)
    let oldWidth = millisecondsToPixels(clip.end - clip.start, zoomLevel)
    let leftPos = millisecondsToPixels(clip.start - viewStart, zoomLevel)
    let leftMin = millisecondsToPixels(startNeighborTime - viewStart, zoomLevel)
    let rightMax = millisecondsToPixels(endNeighborTime - viewStart, zoomLevel)
    if(/[lL]eft/.exec(direction)) {
      let newLeft = leftPos + (oldWidth - newWidth)
      if(leftMin >= 0 && newLeft < leftMin) {
        newLeft = leftMin
        newWidth = leftPos + oldWidth - newLeft
      } else if(magnetic) {
        /*
        let magnetPixels = [0, previewSpace]
        if(leftMin >= 0) {
          magnetPixels.push(leftMin)
        }
        */
        let magTimes = [...magnetTimes, previewTime]
        let newLeftTime = pixelsToMilliseconds(newLeft, zoomLevel)
        let magLeftTime = magnetize(newLeftTime, zoomLevel, magTimes)
        if(magLeftTime !== newLeftTime) {
          newLeft = millisecondsToPixels(magLeftTime, zoomLevel)
        }
        newWidth = leftPos + oldWidth - newLeft
      }
      ref.style.left = `${newLeft}px`
    } else if (/[rR]ight/.exec(direction)) {
      let newRight = leftPos + newWidth;
      if(rightMax >= 0 && newRight > rightMax) {
        newRight = rightMax
      } else if(magnetic) {
        let magTimes = [...magnetTimes, previewTime]
        let newRightTime = pixelsToMilliseconds(newRight, zoomLevel)
        let magRightTime = magnetize(newRightTime, zoomLevel, magTimes)
        if(magRightTime !== newRightTime) {
          newRight = millisecondsToPixels(magRightTime, zoomLevel)
        }
        //newRight = magnetizePixels(newRight, [previewSpace, rightMax]);
      }
      newWidth = newRight - leftPos
    }
    ref.style.width = `${newWidth}px`
    /*
    let width = millisecondsToPixels(clip.end - clip.start, zoomLevel)
    let leftPx = millisecondsToPixels(clip.start - viewStart, zoomLevel)
    if(/[lL]eft/.exec(direction)) {
      let oldWidth = width
      let leftPos = leftPx
      let newLeft = leftPos + (oldWidth - newWidth)
      if(startNeighborTime >= 0) {
        let leftMin = millisecondsToPixels(startNeighborTime, zoomLevel);
        if(newLeft < leftMin) {
          newWidth = leftPos + oldWidth - leftMin
          ref.style.width = `${newWidth}px`
          newLeft = leftMin
        }
      }
      ref.style.left = `${newLeft}px`
    } else if (endNeighborTime >= 0) {
      let rightMax = millisecondsToPixels(endNeighborTime, zoomLevel);
      if(leftPx + newWidth > rightMax) {
        newWidth = rightMax - leftPx;
        ref.style.width = `${newWidth}px`
      }
    }
    */
    ref.style.height = "100%";
  }

  resizeEnd = (e, direction, ref, delta) => {
    let {clip,
      zoomLevel,
      modifyFrameClip,
      index,
      parentIndex,
      startNeighborTime,
      endNeighborTime,
      previewTime,
      magnetic,
      magnetTimes} = this.props
    let newWidth = parseFloat(ref.style.width)
    if(direction === 'left') {
      let newDuration = pixelsToMilliseconds(newWidth, zoomLevel)
      let newStart = clip.end - newDuration
      if(startNeighborTime >= 0 && newStart < startNeighborTime) {
        newStart = startNeighborTime
      } else if (magnetic) {
        let magTimes = [...magnetTimes, previewTime]
        newStart = magnetize(newStart, zoomLevel, magTimes)
      }
      newStart = Math.max(newStart, 0)
      modifyFrameClip(parentIndex, index, {start: newStart})
    } else if(direction === 'right') {
      let newDuration = pixelsToMilliseconds(newWidth, zoomLevel)
      let newEnd = clip.start + newDuration
      if(endNeighborTime >= 0 && newEnd > endNeighborTime) {
        newEnd = endNeighborTime
      } else if (magnetic) {
        let magTimes = [...magnetTimes, previewTime]
        newEnd = magnetize(newEnd, zoomLevel, magTimes)
      }
      modifyFrameClip(parentIndex, index, {end: newEnd})
    }
  }

  render() {
    let {clip,
      viewStart,
      zoomLevel,
      lineWidth,
      index,
      parentIndex,
      startNeighborTime,
      endNeighborTime,
      selectedClips,
      playInterval,
      connectDragSource} = this.props

    let width = millisecondsToPixels(clip.end - clip.start, zoomLevel)
    let leftPx = millisecondsToPixels(clip.start - viewStart, zoomLevel)
    let left = '' + leftPx + 'px'

    let style = {width, left, position: 'absolute', height: '100%'}

    let labelStyle = {}
    if(leftPx < 0) {
      labelStyle.left = `${(-1 * leftPx)}px`
    }

    let visibleStart = viewStart
    let visibleEnd = viewStart + pixelsToMilliseconds(lineWidth, zoomLevel)

    let clipCls = 'PlaylistFrameClip noselect'

    if(selectedClips.find((clip) => clip[0] === parentIndex && clip[1] === index)) {
      clipCls = clipCls + ' selected'
    }
    if(clip.start === startNeighborTime) {
      clipCls = clipCls + ' touchStart'
    }
    if(clip.end === endNeighborTime) {
      clipCls = clipCls + ' touchEnd'
    }

    let size = {width, height: '100%'}

    let label = clip.item
    if(label instanceof Array) {
      label = label[label.length - 1]
    }
    if(clip.association) {
      label = `${label} (${clip.association.name})`
    }

    let item = clip.item.filter(pathComponent => pathComponent).join('/')

    let contents = connectDragSource(
      <div className={clipCls}
        title={`/${item}`}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}>
        <ClipImages source={item}
          clipStart={clip.start}
          clipEnd={clip.end}
          visibleStart={visibleStart}
          visibleEnd={visibleEnd}
          zoomLevel={zoomLevel}
          index={parentIndex}
          shouldLoad={playInterval === null}/>
        <span className='PlaylistFrameClipLabel' style={labelStyle}>{label}</span>
      </div>
    )

    return (<Resizable size={size}
      className='PlaylistFrameClipContainer'
      style={style}
      onResizeStart={(e) => {e.stopPropagation()}}
      onResize={this.resize}
      onResizeStop={this.resizeEnd}
      enable={RESIZE_CLIP}>
      {contents}
    </Resizable>)
  }

}

const beginDrag = (props, monitor, component) => {
  props.setIsDragging(true)
  return {
    clip: props.clip,
    startTime: props.viewStart
  }
}

const endDrag = (props, monitor, component) => {
  props.setIsDragging(false)
  if((monitor.didDrop() &&
    (monitor.getDropResult().addTime || monitor.getDropResult().addTime === 0))) {
    let {addTime, addIndex} = monitor.getDropResult()
    if(addIndex || addIndex === 0) {
      props.moveFrameClip(props.parentIndex, props.index, addTime, addIndex)
    } else {
      props.moveFrameClip(props.parentIndex, props.index, addTime, -1)
    }
  } else if(!monitor.didDrop()) {
    props.removeFrameClip(props.parentIndex, props.index)
  }
}

const canDrag = (props, monitor) => {
  return true;
}

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
})

export default DragSource(CLIP_DRAG, {beginDrag, endDrag, canDrag}, collect)(Clip)
