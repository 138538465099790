import React, {PureComponent} from 'react'
import {Header,
  Form,
  Input,
  Icon,
  Image,
  Dropdown,
  Button,
  Divider} from 'semantic-ui-react'
import {dataPath} from 'helpers/library_helpers'
import PopulateMetadata from './PopulateMetadata'
import RequestForm from './RequestForm'

import {staticAsset} from 'helpers/net_helpers'

import {getIn} from 'helpers/general_helpers'

import './Container.css'

export default class UploadContainer extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      uploadFormOpen: false
    }
    this.source = React.createRef()
  }

  handleChangeDestination = (e, data) => {
    this.props.changeDestination(data.value)
  }

  handleChangeMetadataTemplate = (e, data) => {
    if(data.value) {
      this.props.changeUploadMetadataTemplate(data.value.split('/'))
    } else {
      this.props.changeUploadMetadataTemplate([])
    }
  }

  handleSubmit = (e) => {
    let {form} = this.props
    e.preventDefault()
    let source = getIn(this.source, ['current', 'inputRef', 'current', 'files', 0])
    if(!source) {
      this.props.alert("Please input a file to be uploaded.", {level: "error"})
      return
    }
    if(form) {
      this.openUploadForm()
    } else {
      this.upload()
    }
  }

  upload = (options={}) => {
    let {destination, template, newFileTransfer} = this.props
    let source = getIn(this.source, ['current', 'inputRef', 'current', 'files', 0])
    if(source) {
      newFileTransfer(source, destination, {template, ...options})
    } else {
      this.props.alert("Please input a file to be uploaded.", {level: "error"})
    }
  }

  handleSelectDestination = (e) => {
    let {destination, changeDestination} = this.props
    e.preventDefault()
    this.props.prompt("Upload To...", (val) => {
      if(val === null) {
        return
      }
      let newDest = `${val.directory.join('/')}`
      changeDestination(newDest)
    }, {
      type: 'path',
      initialValue: {filename: '', directory: destination},
      noFilename: true
    })
  }

  openUploadForm = () => {this.setState({uploadFormOpen: true})}
  closeUploadForm = () => {this.setState({uploadFormOpen: false})}

  render() {
    let {destination,
      template,
      metadata,
      fileData,
      templatePath,
      metadataTags,
      form,
      changeUploadMetadata,
      revertUploadMetadata,
      openApplication} = this.props
    let {uploadFormOpen} = this.state

    let destinationText = destination.join('/')
    let templateText = ((template instanceof Array && template.length > 0) ? template.slice(-1)[0] : '')

    let templates = getIn(fileData, dataPath(templatePath, true))
    if(templates) {
      templates = Object.keys(templates).map((template) => (
        <Dropdown.Item key={template} text={template} value={[...templatePath, template].join('/')} onClick={this.handleChangeMetadataTemplate}/>
      ))
      templates.unshift(
        <Dropdown.Item key={"blank"} text={"(none)"} value={''} onClick={this.handleChangeMetadataTemplate}/>
      )
    }

    return (
      <div id='UploadControlsContainer'>
        <Header as='h2' content="Upload" icon={
          <Icon className='svg-icon'>
            <Image src={staticAsset("/icons/upload_black.svg")}/>
          </Icon>
        } id='uploadHeader'/>
        <Divider fitted/>
        <Form id='UploadForm' onSubmit={(e) => {this.handleSubmit(e)}}>
          <Form.Field>
            <label>Destination</label>
            <Input type='text'
              name='destination'
              value={destinationText}
              label={
                <Button onClick={this.handleSelectDestination}>
                  ...
                </Button>
              }
              labelPosition='right'
              onChange={this.handleChangeDestination}/>
          </Form.Field>
          <Form.Field>
            <label>File</label>
            <Input type='file'
              name='source'
              ref={this.source}/>
          </Form.Field>
          <Form.Field>
            <label>Metadata Template</label>
            <Dropdown className='selection' text={templateText}>
              <Dropdown.Menu>
                <Dropdown.Header>
                  <Button fluid onClick={() => {openApplication('MetadataTemplate', '/')}}>New Template</Button>
                </Dropdown.Header>
                {templates}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>
          <Form.Field>
            <Button type='submit'>Upload</Button>{' '}
            <RequestForm form={form}
              open={uploadFormOpen}
              closeModal={this.closeUploadForm}
              upload={this.upload}/>
            <PopulateMetadata template={template}
              metadata={metadata}
              fileData={fileData}
              metadataTags={metadataTags}
              changeMetadata={changeUploadMetadata}
              resetMetadata={revertUploadMetadata}
              prompt={this.props.prompt}/>
          </Form.Field>
        </Form>
      </div>
    )

  }

}
