import React, {PureComponent} from 'react'
import {Table} from 'semantic-ui-react'

export default class VersionTab extends PureComponent {

  render() {
    let {versions} = this.props
    if(typeof versions !== "object") {
      return null;
    }
    let softwareVersions = Object.entries(versions)
      .sort(([akey, avalue], [bkey, bvalue]) => avalue.Name.localeCompare(bvalue.Name)).map(([key, value]) => {
      return (<Table.Row key={key}>
        <Table.Cell>{value.Name}</Table.Cell>
        <Table.Cell>{value.Revision}</Table.Cell>
      </Table.Row>)
    })

    return (
      <Table>
        <Table.Body>
          {softwareVersions}
        </Table.Body>
      </Table>
    )
  }

}
