import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {actions} from 'redux/applications/about'
import {Tab} from 'semantic-ui-react'
import LicenseTab from 'components/About/LicenseTab'
import VersionTab from 'components/About/VersionTab'
import {version, SERVER_URL} from 'config'
import {staticAsset} from 'helpers/net_helpers'

import {getIn} from 'helpers/general_helpers'

import './About.css'

class About extends PureComponent {

  constructor(props) {
    super(props)
    props.fetchAboutInformation();
  }

  render() {
    let {versions, license} = this.props

    let v4changelog = `${SERVER_URL}/quickroll4/development.html`
    let v5changelog = staticAsset("/development.html")

    let serverType = license.quickcast ? "QuickCast" : "QuickRoll"
    let tabPanes = [
      {menuItem: "License", render: () => (
        <Tab.Pane className="AboutTabPane">
          <LicenseTab license={license}/>
        </Tab.Pane>
      )},
      {menuItem: "Versions", render: () => (
        <Tab.Pane className="AboutTabPane">
          <VersionTab versions={versions}/>
        </Tab.Pane>
      )},
      {menuItem: "Version 5 Interface Changelog", render: () => (
        <Tab.Pane className="AboutTabPane">
          <iframe src={v5changelog} width="100%" title="v5changelog"></iframe>
        </Tab.Pane>
      )},
      {menuItem: "System Changelog", render: () => (
        <Tab.Pane className="AboutTabPane">
          <iframe src={v4changelog}
            title="Changelog"
            id="AboutChangelog"/>
        </Tab.Pane>
      )}
    ]

    let v5Version = version
    let buildType = getIn(versions, ["platform", "Build type"])
    if (!buildType || buildType === "" || (buildType && buildType.trim() === "release"))
      v5Version = `${version}`
    else
      v5Version = `${version} ${buildType.trim().toUpperCase()}`

    if (process.env.NODE_ENV === "production")
      { }
    else
      v5Version = `${v5Version} [dev mode]`

    return (
      <div id="AboutApplication">
        <div id="AboutHeader" className="noselect">CASTUS {serverType}</div>
        <div id="AboutVersion" className="noselect">Version {v5Version}</div>
        <div id="AboutCopyright" className="noselect">
          <div>© 2010-2022 CASTUS Corporation, ALL RIGHTS RESERVED</div>
          <div>For more information visit <a href="https://castus.tv" target="_blank" rel="noopener noreferrer">castus.tv</a></div>
        </div>
        <Tab panes={tabPanes} id="AboutTabs"/>
      </div>
    )
  }

}

const mapStateToProps = (state) => ({...state.about})

const mapDispatchToProps = actions

export default connect(mapStateToProps, mapDispatchToProps)(About)
