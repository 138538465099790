import { createSelector } from 'reselect'

const getScheduleSwap = (state) => state.scheduleSwap ? state.scheduleSwap.schedule : []

export default createSelector([getScheduleSwap], (schedule) => {
  let toReturn = []
  if(schedule) {
    for(let item of schedule) {
      if(item.type === "block") {
        let ind = toReturn.findIndex((block) => block.name === item.body.label)
        if(ind === -1) {
          toReturn.push({
            name: item.body.label,
            color: item.body.color,
            announce: item.announce,
            times: [
              item.span
            ]
          })
        } else {
          toReturn[ind].times.push(item.span)
        }
      }
    }
  }
  return toReturn
})
