import React from 'react'
import {connect} from 'react-redux'
import {actions} from 'redux/applications/user_list'
import {messages} from 'redux/messages'
import {Table, Button, Checkbox, Icon, Confirm} from 'semantic-ui-react'
import applicationList from 'applicationList'
import { Field, reduxForm, Form } from 'redux-form'
import {checkHasAccessToApp} from 'helpers/authentication_helpers'

import UserRow from 'components/UserList/UserRow'

let CreateUserForm = props => {
    const { onClick, onCancel, checkboxes } = props

    let boxes = checkboxes.map( (name, i)=>{
        let appName = applicationList[name].name
        return <div className="field" key={i}>
            <span className="ui checkbox"key={i}>
                <Field name={name} component="input" type="checkbox" />
                <label>{appName}</label>
            </span>
         </div>
    })

    return <Form onSubmit={ ()=>false } className="ui form">
        <h4>Username:</h4>
        <Field name="username" component="input" type="text" />
        <label>Password</label>
        <Field name="password" component="input" type="password" />
        <br/><br/>
        { boxes }
        <Button primary onClick={onClick}>Create</Button>
        <Button onClick={onCancel}>Cancel</Button>
    </Form>
  }

  CreateUserForm = reduxForm({
    // a unique name for the form
    form: 'new_user'
  })(CreateUserForm)

class UserList extends React.PureComponent {
    constructor(props) {
        super(props)
        props.get_users()
        this.state = {
            displayUserForm: false,
            confirmDeleteForm: false,
	    confirmUpdateForm: false,
            delete_id: -1,
            checkboxes: {}
        }
    }

    /**
     * \return An array of the internal permission names
     */
    permissionKeys = () => {
        return Object.keys(applicationList)
    }

    /**
     *
     * @param {*} cells This will be modified as the result
     * @param {*} user The user to create checkboxes for
     * @param {boolean} admin Whether the current user is an admin or not
     */
    addPermissionCheckboxes = (cells, user, admin) => {
        this.permissionKeys().forEach( app=>{
            let app_name  = applicationList[app].name
            let checked = checkHasAccessToApp(user.access, app)
            let cell = !admin && user.admin ? '' : <Checkbox
                onClick={ ()=>this.props.togglePermission(user.id,app) }
                defaultChecked={checked}
            />
            cells.push(
                <Table.Cell key={app_name}>
                    {cell}
                </Table.Cell>
            )

        })
    }

    closeUserForm = () => this.setState({displayUserForm:false})
    createUser = ()=> {
        this.setState({ displayUserForm: true })
    }
    onCreateUser = () => {
        let {values} = this.props.form
        let { username, password} = values

        let access   = this.permissionKeys().filter( key=>{
          console.log(key)
          return values[key]
        })
        let admin    = values.admin===true

        let payload = { username, password, access, admin }
        this.props.new_user(payload)
        this.closeUserForm();
    }
    closeConfirmDeleteForm = () => this.setState({ confirmDeleteForm: false })
    confirmDeleteUser = id => this.setState({
        delete_id: id,
        confirmDeleteForm: true
    })
    closeConfirmUpdateForm = () => this.setState({ confirmUpdateForm: false })
    confirmUpdateUser = id => this.setState({
        update_id: id,
	confirmUpdateForm: true
    })

    onDeleteUser = () =>{
//        alert(`Delete ${this.state.delete_id}`)
        this.props.delete_user(this.state.delete_id)
        this.closeConfirmDeleteForm()
    }

    onUpdateUser = () => {
        this.props.update_user(this.state.update_id)
    	this.closeConfirmUpdateForm()
    }

    changeUserPassword = async (user) => {
      let go = true;
      let confirmFailed = false;
      let newPassword;
      while(go) {
        if(confirmFailed) {
          newPassword = await this.props.promptAsync(`Passwords did not match. Please enter a new password for ${user.username}`, {type: "password"})
        } else {
          newPassword = await this.props.promptAsync(`Please enter a new password for ${user.username}`, {type: "password"})
        }
        if(!newPassword) {
          return
        }
        let confirmPassword = await this.props.promptAsync(`Please confirm the new password for ${user.username}`, {type: "password"})
        if(newPassword === confirmPassword) {
          go = false;
          break;
        } else {
          confirmFailed = true;
        }
      }
      await this.props.change_user_password(user.id, newPassword);
      this.props.alert(`Password for user ${user.username} has been changed.`, {level: 'success'})
    }

    /**
     *
     * @param {Object} cells This will be modified as the result
     * @param {Object} user The user to create action icons for
     * @param {boolean} admin Whether the current user is an admin or not
     */
    addActionIcons = (cells, user, admin) => {
        let {dirtyUsers} = this.props;
        let dirty = dirtyUsers[user.id]
        let cell = !admin && user.admin ? '' : <>
            <Icon onClick={()=>this.changeUserPassword(user)} name="pencil" title={`Change Password for ${user.username}`} className="cursor-pointer" />
            <Icon onClick={()=>this.confirmDeleteUser(user.id)} name="trash" color="red" title={`Delete ${user.username}`} className="cursor-pointer" />
            <Icon onClick={dirty ? ()=>this.confirmUpdateUser(user.id) : ''} name="save"  color={dirty ? 'green' : null} disabled={!dirty} title={`Save ${user.username}`}  className={dirty ? "cursor-pointer" : null}  />
        </>
        cells.push( <Table.Cell key="icons">{cell}</Table.Cell> )
    }

    render() {
        let { users, currentlyAdmin } = this.props
        let header_cells = ["Name"]
        this.permissionKeys().forEach( name=>header_cells.push(applicationList[name].name) )
        header_cells.push("Action")

        header_cells = header_cells.map(name=><Table.HeaderCell key={name}>{name}</Table.HeaderCell>)
        let body_rows = users.map( user => (
          <UserRow key={user.id}
            user={user}
            currentlyAdmin={currentlyAdmin}
            addPermissionCheckboxes={this.addPermissionCheckboxes}
            addActionIcons={this.addActionIcons}
            permissionKeys={this.permissionKeys}
            changeUserPassword={this.changeUserPassword}/>
        ))

        let form = (!this.state.displayUserForm) ? '' : (
            <CreateUserForm
                onClick={this.onCreateUser}
                onCancel={this.closeUserForm}
                checkboxes={this.permissionKeys()}
            />
        )

        return (
            <>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            { header_cells }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { body_rows }
                    </Table.Body>
                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan={header_cells.length}>
                                <Button onClick={this.createUser}>Add User</Button>
                                { form }
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Confirm open={this.state.confirmDeleteForm} content='Delete this user?' onCancel={this.closeConfirmDeleteForm} onConfirm={this.onDeleteUser} />
		        <Confirm open={this.state.confirmUpdateForm} content='Save this user?' onCancel={this.closeConfirmUpdateForm} onConfirm={this.onUpdateUser} />
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    dirtyUsers: state.user_list.dirtyUsers,
    users: state.user_list.users,
    menu: state.menu.menuList,
    form: state.form.new_user,
    currentlyAdmin: state.server.admin
})

const mapDispatchToProps = {...actions, ...messages}

export default connect(mapStateToProps, mapDispatchToProps)(UserList)


