
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

// Global Reducers
import channel from 'redux/channel'
import menu from 'redux/menu'
import file_list from 'redux/file_list'
import settings from 'redux/settings'
import cookies from 'redux/cookies'
import server from 'redux/server'
import messages from 'redux/messages'
import system from 'redux/system'
import alerts from 'redux/alerts'

// Application Reducers
import library from 'redux/applications/library'
import global_media from 'redux/applications/global_media'
import global_music from 'redux/applications/global_music'
import scrolling_text from 'redux/applications/scrolling_text'
import rss_item_editor from 'redux/applications/rss_item_editor'
import metadata_editor from 'redux/applications/metadata_editor'
import template_editor from 'redux/applications/template_editor'
import schedule from 'redux/applications/schedule'
import schedule_clipboard from 'redux/applications/schedule_clipboard'
import playlist_editor from 'redux/applications/playlist_editor'
import simple_playlist_editor from 'redux/applications/simple_playlist_editor'
import about from 'redux/applications/about'
import upload from 'redux/applications/upload'
import user_list from 'redux/applications/user_list'
import cloud from 'redux/applications/cloud'

// Component Reducers
import { reducer as formReducer } from 'redux-form'
import input_recording from 'redux/components/input_recording'
import output_recording from 'redux/components/output_recording'
import logs from 'redux/components/logs'
import settings_list_render from './redux/components/settings_list_render'

export const createRootReducer = (history) => combineReducers({
  // router
  router: connectRouter(history),
  channel,
  menu,
  file_list,
  settings,
  cookies,
  server,
  messages,
  system,
  alerts,
  // application
  library,
  global_media,
  global_music,
  scrolling_text,
  rss_item_editor,
  metadata_editor,
  template_editor,
  schedule,
  schedule_clipboard,
  playlist_editor,
  simple_playlist_editor,
  about,
  upload,
  user_list,
  cloud,
  // component
  form: formReducer,
  input_recording,
  output_recording,
  logs,
  settings_list_render
})

export default createRootReducer;
