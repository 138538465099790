/** Selector that uses the current time to get the currently playing item on the viewed date, if there is one. Returns one of the following:
 *  {type: item, index: <index>, child: -1} The item in the schedule at index <index> is currently playing
 *  {type: item, index: <index>, child: <childIndex>} Within the schedule block located at index <index>, the child item with index <childIndex> is currently playing
 *  {type: default, daily: <isToday>} A default item is playing if there is one. If <isToday> is true, it should be the daily default (again, if there is one). If
 *    <isToday> is false, or if it is true and there is no daily default, then the global default should be playing (once again, if there is one).
 */
import { createSelector } from 'reselect'
import Moment from 'moment-timezone'
import {getIn} from 'helpers/general_helpers'
import {isOnScheduleDay, checkTimeInSlot} from 'helpers/schedule_helpers'
const getScheduleSwap = (state) => state.scheduleSwap || state.schedule
const getViewTime = (state) => state.viewTime
const getCurrentTime = (state) => {
  let {timezone} = state
  let now = getIn(state, ['timeSync', 'now']) || Date.now
  if(timezone) {
    return Moment.tz(now(), timezone)
  } else {
    return Moment(now())
  }
}

export default createSelector([
  getScheduleSwap,
  getViewTime,
  getCurrentTime
], (scheduleSwap, viewTime, currentTime) => {
  if(!scheduleSwap || !scheduleSwap.schedule) {
    return {type: "default", isToday: false}
  }
  let {schedule, type} = scheduleSwap
  let isToday = isOnScheduleDay({
      span: {
        start: currentTime,
        end: currentTime
      }
    }, viewTime, type)
  let itemIndex = schedule.findIndex((item) => {
    return checkTimeInSlot(currentTime, item.span, type)
  })
  if(itemIndex === -1) {
    return {type: "default", isToday}
  }
  if(schedule[itemIndex].type === "block") {
    let childIndex = schedule[itemIndex].body.contains.findIndex((child) => {
      return checkTimeInSlot(currentTime, child.span, type)
    })
    if(childIndex === -1) {
      return {type: "default", isToday}
    } else {
      return {type: "item", index: itemIndex, child: childIndex}
    }
  } else {
    return {type: "item", index: itemIndex, child: -1}
  }
})
