
/*
 * Component that displays a channel preview.  It displayed
 * the file name, if the channel is online, a progress bar
 * and the preview image
*/

import React, {PureComponent} from 'react'
import {staticAsset} from 'helpers/net_helpers'
import {encodeURIFilepath} from 'helpers/general_helpers'

import {
  Segment,
  Label,
  Button,
  Icon,
  Image
} from 'semantic-ui-react'

import StatusLines from './StatusLines'
import ConfidenceMonitor from './ConfidenceMonitor'
import AudioMeters from './AudioMeters'

import './Header.css'

class SidebarSegment extends PureComponent {

  handleClick = () => {
    let {onClick} = this.props
    let {key} = this.props.data
    if(onClick) {
      onClick(key)
    }
  }

  createLinkLabel = (file, label='',fileType='') => {
    if (fileType === undefined || fileType === null) fileType = ''
    let filePath = file.split('/')
    let fileName = filePath[filePath.length - 1]
    let fileLink;
    if (fileType === 'application/x-castus-daily-schedule' ||
        fileType === 'application/x-castus-endless-schedule' ||
        fileType === 'application/x-castus-weekly-schedule' ||
        fileType === 'application/x-castus-monthly-schedule' ||
        fileType === 'application/x-castus-yearly-schedule' ||
        fileType === 'application/x-castus-interval-schedule') {
      fileLink = `/schedule${encodeURIFilepath(filePath.join('/'))}`
    }
    else {
      fileLink = `/library${encodeURIFilepath(filePath.join('/'))}`
      if(fileLink.includes('mnt/main/tv/inputs')) {
        fileLink = `/services/input/${encodeURIFilepath(fileName)}/controls`
      }
    }
    return {label, value: fileName, title: file, link: fileLink}
  }

  render() {
    let {active, type} = this.props
    let {name, running, file, assigned, fileType, assignedType, image, key, meters, vuMeters} = this.props.data
    let {onLoad, onQueue, onSettings, onControls} = this.props
    let channelNumberBadge = ''
    let color;

    // FIXME: c4mi_chctl is not providing item leaf mime type!

    if(this.props.data.on_air) {
      running = true
    }

    let activeChannel = (type === "channel" && active)
    if(!activeChannel) {
      color = "grey"
    }

    let onAirColor = running ? "green" : "red"
    let onAirMessage = (type === "channel") ?
      (running ? "On Air" : "Off Air") :
      (running ? "Running" : "Stopped")

    let confidenceMonitor = null;
    let controlsButton = null;
    let audioMeters = null;

    let statusLines = []

    if(type === "channel") {
      // FIXME: If channel name != ''
      if (!name.match(/^Channel #\d+$/)) {
        channelNumberBadge = "#" + key.replace(/^channel/,'')
      }

      confidenceMonitor = (<ConfidenceMonitor onAir={running} currentImage={image.current} backupImage={image.backup} onLoad={onLoad} channelKey={key}/>)

      audioMeters = (<AudioMeters meters={meters} vuMeters={vuMeters}/>)

      if(assigned) {
        let assignedLabel = this.createLinkLabel(assigned, "Assigned", assignedType)
        statusLines.push(assignedLabel)
      }
      if(file && file !== assigned) {
        let nowPlayingLabel = this.createLinkLabel(file, "Now Playing", fileType)
        statusLines.push({...nowPlayingLabel, onQueue})
      }
    } else if(type === "output" && file) {
      let itemLabel = this.createLinkLabel(file, "Item")
      statusLines = [itemLabel]
    } else if(type === "input") {
      controlsButton = (<Button compact icon="keyboard" onClick={() => {onControls(key)}} title="Controls"/>)
    }

    return (
      <Segment color={color} style={ {borderRadius: 0, borderBottom: 'solid 1px black', borderTop: 0} } inverted={!activeChannel} compact onClick={this.handleClick}>
        <Label color={color} size='large'>{name}{channelNumberBadge !== "" ? <Label color={color} style={{fontSize: 0.6+'em', fontWeight: 300, paddingRight: 0}}>{channelNumberBadge}</Label> : ''}</Label>
        <span className="sidebarSegmentControls">
          <Label color={onAirColor} size='large'>{onAirMessage}</Label>
          <Button compact icon onClick={() => {onSettings(key, type)}} title="Settings">
            <Icon className="svg-icon">
              <Image src={staticAsset(`/icons/settings_black.svg`)}/>
            </Icon>
          </Button>
          {controlsButton}
        </span>
        {confidenceMonitor}
        {audioMeters}
        <StatusLines lines={statusLines}
          data={this.props.data}/>
      </Segment>
    )
  }
}

export default SidebarSegment
