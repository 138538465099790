import React, {PureComponent, Fragment} from 'react'
import {millisecondsToPixels, pixelsToMilliseconds} from 'helpers/playlist_helpers'
import TimeIndicator from './TimeIndicator'
import TimeIndicatorBar from './TimeIndicatorBar'

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60

export default class TimeIndicatorGroup extends PureComponent {

  render() {
    let {startTime, zoomLevel, barWidth} = this.props
    let intervals = calculateTimeIntervals(zoomLevel)
    let primaryInterval = intervals[0]
    let timeIndicators = calculateTimeMarkers(primaryInterval, startTime, zoomLevel, barWidth).map((marker) => (
      <TimeIndicator {...marker} key={marker.time}/>
    ))
    let timeIndicatorBars = intervals.map((interval, index) => (
      <TimeIndicatorBar key={'bar' + interval}
        pixelMargin={millisecondsToPixels(interval, zoomLevel)}
        level={index}
        startMargin={millisecondsToPixels(nextInterval(startTime - interval, interval), zoomLevel)}/>
    ))

    return (
      <Fragment>
        {timeIndicators}
        {timeIndicatorBars}
      </Fragment>
    )
  }

}

function nextInterval(time, interval) {
  let toReturn = time + interval
  toReturn = toReturn - (toReturn % interval)
  return toReturn
}

function calculateTimeMarkers(timeInterval, startTime, zoomLevel, barWidth) {
  let toReturn = []
  let startMargin = millisecondsToPixels(startTime, zoomLevel)
  let endTime = pixelsToMilliseconds(startMargin + barWidth, zoomLevel)
  for(let currentTime = nextInterval(startTime, timeInterval); currentTime < endTime; currentTime += timeInterval) {
    let nextMargin = millisecondsToPixels(currentTime, zoomLevel)
    toReturn.push({time: currentTime, margin: nextMargin})
  }
  return toReturn
}

function calculateTimeIntervals(zoomLevel, maxLevels=3) {
  let toReturn = []
  let baseInterval = Math.max(roundTimeInterval(pixelsToMilliseconds(200, zoomLevel)), 1000)
  toReturn.push(baseInterval)
  while(baseInterval > 0 && toReturn.length < maxLevels) {
    baseInterval = roundTimeInterval(baseInterval / 2)
    toReturn.push(baseInterval)
  }
  return toReturn
}

function roundTimeInterval(interval) {
  let intervalHours = Math.floor(interval / HOUR)
  let intervalMinutes = Math.floor((interval % HOUR) / MINUTE)
  let intervalSeconds = Math.floor((interval % MINUTE) / SECOND)
  let intervalMilliseconds = Math.floor(interval % SECOND)
  let intervalRep = intervalMilliseconds + (intervalSeconds * 1000) + (intervalMinutes * 100000) + (intervalHours * 10000000)
  let power = -3
  while(intervalRep >= 10) {
    intervalRep = Math.floor(intervalRep / 10)
    power += 1
  }
  let first = (Math.round(intervalRep * 0.2) / 0.2)
  if(first === 0) {
    first = calculateIntervalRepMult(power)
  } else if(first === 5) {
    first = (calculateIntervalRepMult(power + 1) / 2)
  } else if(first === 10) {
    first = (calculateIntervalRepMult(power + 1))
  }
  return first
}

function calculateIntervalRepMult(power) {
  if(power < 0) {
    return (10 ** (power + 3))
  } else if(power === 0 || power === 1) {
    return SECOND * (10 ** power)
  } else if(power === 2 || power === 3) {
    return MINUTE * (10 ** (power - 2))
  } else {
    return HOUR * (10 ** (power - 4))
  }
}
