import React, {PureComponent} from 'react'
import {Table, Checkbox, Icon} from 'semantic-ui-react'

class UserRow extends PureComponent {

  render() {
    let {
      user,
      currentlyAdmin,
      addPermissionCheckboxes,
      addActionIcons,
      permissionKeys,
      changeUserPassword
    } = this.props
    let cells = [
      <Table.Cell key="name">{user.username}</Table.Cell>
    ]
    if(user.username !== "root") {
      addPermissionCheckboxes(cells, user, currentlyAdmin)
      addActionIcons(cells, user, currentlyAdmin)
    } else {
      for(let name of permissionKeys()) {
        cells.push(<Table.Cell key={name} title="root account permissions cannot be changed."><Checkbox checked={true} disabled/></Table.Cell>)
      }
      cells.push(<Table.Cell key={"actions"}>
          <Icon onClick={()=>changeUserPassword(user)} name="pencil" title={`Change Password for ${user.username}`} className="cursor-pointer" />
        </Table.Cell>)
    }
    return (<Table.Row key={user.id}>{cells}</Table.Row>)
  }

}

export default UserRow
