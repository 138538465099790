import React, {PureComponent} from 'react'

import {Tab} from 'semantic-ui-react'

import Library from 'containers/components/LibraryComponent'
import EventTab from 'components/Schedule/EventTab/Container'
import BlockTab from 'components/Schedule/BlockTab/Container'
import ScheduleToggle from 'components/Schedule/ScheduleToggle'
import ScheduleGapSelector from 'components/Schedule/ScheduleGapSelector'

import eventSelector from 'selectors/ScheduleEventSelector'
import blockSelector from 'selectors/ScheduleBlockListSelector'

export default class ScheduleSidePane extends PureComponent {

  render() {
    let {
      tabIndex,
      changeSidebarTab,
      activeSchedule,
      showSubseconds,
      goToTime,
      scheduleSwap,
      scheduleType,
      /*LIBRARY*/
      scheduleLibraryUpdate,
      libraryDeselect,
      libraryPath,
      handleOpenFile,
      file_list,
      /*EVENTS*/
      selectedEvents,
      createEvent,
      changeEvent,
      removeEvent,
      selectEvents,
      /*BLOCKS*/
      selectedBlocks,
      removeBlock,
      renameBlock,
      recolorBlock,
      createBlock,
      setBlockAnnounce,
      selectBlocks,
      /*OPTIONS*/
      setSubsecondVisibility,
      timeSlotLength,
      changeTimeSlotLength,
    } = this.props

    let scheduleLibrary = (
      <Tab.Pane className='filePanelTab'>
        <div id='scheduleLibraryContainer'>
          <Library update={scheduleLibraryUpdate}
                deselect={libraryDeselect}
                overridePath={libraryPath}
                handleOpenFile={handleOpenFile}
                inputAssociations={true}
                {...file_list}
                compact/>
        </div>
      </Tab.Pane>
    )

    let panes = [
      {menuItem: 'Library', render: () => scheduleLibrary}
    ]

    if(activeSchedule) {
      // Convert object eventsList into an array for display purposes
      let eventsList = eventSelector({scheduleSwap})
      let scheduleEvents = (
        <Tab.Pane className='filePanelTab'>
          <EventTab eventsList={eventsList}
            scheduleType={scheduleType}
            selectedEvents={selectedEvents}
            showSubseconds={showSubseconds}
            goToTime={goToTime}
            createEvent={createEvent}
            changeEvent={changeEvent}
            removeEvent={removeEvent}
            selectEvents={selectEvents}/>
        </Tab.Pane>
      )

      // Get a list of the schedule blocks with what times they occur at
      let blocksList = blockSelector({scheduleSwap})
      let blocksTab = (
        <Tab.Pane className='filePanelTab'>
          <BlockTab
            blocksList={blocksList}
            selectedBlocks={selectedBlocks}
            removeBlock={removeBlock}
            renameBlock={renameBlock}
            recolorBlock={recolorBlock}
            createBlock={createBlock}
            setBlockAnnounce={setBlockAnnounce}
            selectBlocks={selectBlocks}
            scheduleType={scheduleType}
            showSubseconds={showSubseconds}
            goToTime={goToTime}/>
        </Tab.Pane>
      )

      let optionsTab = (
        <Tab.Pane className='filePanelTab'>
          <ScheduleToggle label='Show Subseconds' value={showSubseconds} changeFunction={setSubsecondVisibility}/>
          <ScheduleGapSelector timeSlotLength={timeSlotLength}
            changeTimeSlotLength={changeTimeSlotLength}/>
        </Tab.Pane>
      )
      panes = [...panes,
        {menuItem: 'Blocks', render: () => blocksTab},
        {menuItem: 'Events', render: () => scheduleEvents},
        {menuItem: 'Options', render: () => optionsTab}
      ]
    }

    return (
      <Tab id='scheduleFilePanel'
        panes={panes}
        activeIndex={tabIndex}
        onTabChange={(e, {activeIndex}) => {changeSidebarTab(activeIndex)}}/>
    )
  }

}
