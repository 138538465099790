import React, {PureComponent} from 'react'
import {Dimmer, Loader} from 'semantic-ui-react'

/**
 * TO USE:
 *
 * Import loadind indicator function into component to be wrapped
 *  import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'
 *
 * When exporting component, export the class wrapped in the imported function.
 * If using other wrappers, the loading wrapper should generally be called first (it should be the innermost wrapper):
 *  loadingIndicator(WRAPPEDCOMPONENT)
 * With other wrappers:
 *  connect(mapStateToProps, mapDispatchToProps)(tabbedComponent(loadingIndicator(WRAPPEDCOMPONENT)))
 *
 * Use this alongside the higher order reducer located at: src/redux/higher_order_reducers/loaderReducer.js
 * See that file for its use instructions
 *
 * You must ensure that the _loader and _loaderID state added by the loaderReducer reach this wrapper with those keys.
 * So for example, if using mapStateToProps, you could do one of these two things
 * (_loader and _loaderID are already part of the wrapped state with those keys, so destructuring it works)
 * mapStateToProps = state => ({...wrappedState})
 * (If you don't want to destructure for whatever reason, you may need to pass them manually)
 * mapStateToProps = state => ({state: wrappedState, _loader: wrappedState._loader, _loaderID: wrappedState._loaderID})
 */

export default (WrappedComponent) => {

  return class LoadIndicator extends PureComponent {

    render() {
      let {_loader, _loaderID, ...rest} = this.props

      let active = false;
      if(_loader && _loader.loadingJobs) {
        active = (_loader.loadingJobs.length > 0)
      }

      return (
        <>
          <WrappedComponent {...rest}/>
          <Dimmer active={active}>
            <Loader>Working...</Loader>
          </Dimmer>
        </>
      )

    }

  }

}
