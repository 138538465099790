import React, {PureComponent} from 'react'
import {Menu, Icon} from 'semantic-ui-react'

import './Toolbar.css'

export default class PlaylistToolbar extends PureComponent {

  toggleMagnetic = () => {
    this.props.setMagnetic(!this.props.magnetic)
  }

  setTool = (e, data) => {
    this.props.selectPlaylistTool(data.number)
  }

  preventSpace = (e) => {
    if(e.key === ' ') {
      e.preventDefault()
    }
  }

  render() {
    let {toolsList,
      selectedTool,
      magnetic,
      isPlaying,
      shouldClipboard,
      shouldPaste,
      clipboardSelectedClips,
      pasteClipsAtPreview,
      togglePlaylistPlaying} = this.props

    let toolButtons = toolsList.map((tool, index) => {
      return (
        <Menu.Item key={index}
          title={tool.name}
          icon
          active={selectedTool === index}
          onClick={this.setTool}
          onKeyDown={this.preventSpace}
          number={index}>
          <Icon name={tool.icon}/>
        </Menu.Item>
      )
    })

    return (
      <Menu size='tiny' icon attached='top' id="PlaylistToolbar">
        <Menu.Menu>
          {toolButtons}
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item active={magnetic} onClick={this.toggleMagnetic} onKeyDown={this.preventSpace} title='Magnetize'>
            <Icon name="magnet"/>
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item disabled={!shouldClipboard} onClick={clipboardSelectedClips} onKeyDown={this.preventSpace} title='Copy'>
            <Icon name="copy"/>
          </Menu.Item>
          <Menu.Item disabled={!shouldPaste} onClick={pasteClipsAtPreview} onKeyDown={this.preventSpace} title='Paste'>
            <Icon name="paste"/>
          </Menu.Item>
        </Menu.Menu>
        <Menu.Menu>
          <Menu.Item onClick={togglePlaylistPlaying} onKeyDown={this.preventSpace} title={isPlaying ? 'Pause' : 'Play'}>
            <Icon name={isPlaying ? 'pause' : 'play'}/>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    )

  }

}
