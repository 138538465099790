import React, {Component} from 'react'
import {Input} from 'semantic-ui-react'

class EventInput extends Component {

  constructor(props) {
    super(props)
    this.state = {currentValue: null}
  }

  render() {
    let {name, value, changeEventInfo, id, inputProps} = this.props
    let {currentValue} = this.state

    if(name === 'duration') {
      value = durationToString(value)
    }
    let inputValue = currentValue === null ? value : currentValue

    return (
      <Input type='text'
        onKeyDown={handleKeyDown}
        onChange={(e) => {this.setState({currentValue: e.target.value})}}
        onBlur={(e) => {
          this.setState({currentValue: null});
          if(e.target.value) {
            changeEventInfo(id, {[name]: e.target.value})
          }
        }}
        value={inputValue}
        {...inputProps}/>
    )
  }

}

function durationToString(duration) {
  return `${duration.hours()}:${('' + duration.minutes()).padStart(2, '0')}:${('' + duration.seconds()).padStart(2, '0')}`
}

function handleKeyDown(e) {
  if(e.key === 'Enter') {
    e.preventDefault()
    e.target.blur()
  }
}

export default EventInput
