import React, {PureComponent} from 'react'
import {Checkbox} from 'semantic-ui-react'

import './ScheduleToggle.css'

class ScheduleToggle extends PureComponent {

  render() {
    let {label, value, changeFunction} = this.props

    let inputFunction = (e, {checked}) => {changeFunction(checked)}

    return (
      <div className='scheduleToggle'>
        {label}: <Checkbox checked={value} onChange={inputFunction}/>
      </div>
    )
  }

}

export default ScheduleToggle
