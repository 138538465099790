import React, {PureComponent} from 'react'
import {getIn} from 'helpers/general_helpers'

import SettingIdSelector from 'selectors/SettingIdSelector'

import {Header,
  List,
  Checkbox,
  Segment,
  Label,
  Button} from 'semantic-ui-react'

import './Queue.css'

export default class QueueControls extends PureComponent {

  render() {
    let channelId = SettingIdSelector(this.props)
    let queueInfo = getIn(this.props, ['services', 'channel', channelId, 'settings', 'queue'])
    let name = getIn(this.props, ['services', 'channel', channelId, 'settings', 'name'])
    if(!queueInfo) {
      return (<div>No Queue Info for this channel.</div>)
    }
    let {loop, assigned, queue} = queueInfo
    queue = queue.filter((item) => !!item)
    return (
      <div id='QueuePage'>
        <Button onClick={() => {this.props.openApplication('Settings', '/channel')}} icon='arrow left' content='Back'/>
        <Header size='large'>{name || channelId}</Header>
        <Segment.Group>
          <Segment>
            <Checkbox toggle label='Loop Queue Items' checked={loop} onChange={(e, data) => {this.props.setQueueItemLooping(channelId, data.checked)}}/>
          </Segment>
          <Segment>
            <Button onClick={() => {this.props.nextQueueItem(channelId)}}>Next Item</Button>
            <Button onClick={() => {this.props.endQueue(channelId)}}>Return to Assigned Item</Button>
          </Segment>
          <Segment>
            <Label attached='top left'>Item Queue</Label>
            <List divided relaxed items={queue}/>
          </Segment>
          <Segment>
            <Label horizontal>Assigned Item:</Label>{assigned}
          </Segment>
        </Segment.Group>
      </div>
    )
  }

}
