import React, {PureComponent} from 'react'
import {Table, Input, Menu, Button, Popup} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import TimeInput from 'components/TimeInput/TimeInput'

import "./RecordingEventRow.css"

const repeatOptions = [
  { key: "Never", name: 'Never', value: 'never'},
  { key: "Daily", name: 'Daily', value: 'daily',},
  { key: "Weekly", name: 'Weekly', value: 'weekly',},
];

const timeInputRegex = /^(\d{1,2}):(\d{2}):(\d{2}) ([ap]m)/

// Warning: Modifies date in place!
const addTimeInputStringToDate = (timeString, date) => {
  let timeValue = timeInputRegex.exec(timeString)
  if(!timeValue) {
    return false
  }
  let [hour, minute, second, meridian] = timeValue.slice(1)
  hour = parseInt(hour, 10)
  if(meridian === "am" && hour === 12) {
    hour = 0
  } else if (meridian === "pm" && hour !== 12) {
    hour += 12
  }
  minute = parseInt(minute, 10)
  second = parseInt(second, 10)
  date.setHours(hour, minute, second)
  return true
}

export default class RecordingEventRow extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {editing: "", editValue: ""}
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOut)
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOut)
  }

  componentDidUpdate(prevprops, prevstate) {
    if(this.state.editing === "recording name" && prevstate.editing !== "recording name") {
      document.getElementById("recordingNameInput").focus()
    }
  }

  startEditing = (field) => {
    // we already are; don't override eventValue
    if(this.state.editing === field) {
      return
    }
    // handle output "name" field vs input "recording name" field
    let editValue = this.props.recordingEvent[field]
    if(!editValue && field === "recording name" && this.props.recordingEvent.name) {
      editValue = this.props.recordingEvent.name
    }
    if((field === "start" || field === "end") && !(editValue instanceof Date) && editValue.toDate) {
      if(this.props.timezone) {
        let tz = editValue.clone().tz(this.props.timezone)
        editValue = new Date(tz.year(), tz.month(), tz.date(), tz.hour(), tz.minute(), tz.second(), tz.millisecond())
      } else {
        editValue = editValue.toDate()
      }
    }
    this.setState({
      editing: field,
      editValue
    })
  }

  finishEditing = (overrideValue) => {
    let {onChange, recordingEvent} = this.props
    let {editing, editValue} = this.state
    if(overrideValue) {
      editValue = overrideValue
    }
    if(editValue && editing) {
      onChange(recordingEvent.id, editing, editValue)
    }
    this.setState({editing: "", editValue: ""})
  }

  handleClickOut = (e) => {
    let {recordingEvent} = this.props
    let close = !(document.getElementById("recordingEventRow" + recordingEvent.id).contains(e.target))
    if(!close) {
      return
    }
    let popups = document.getElementsByClassName("recordingEventPopup" + recordingEvent.id)
    for(let popup of popups) {
      if(popup.contains(e.target)) {
        close = false
        break;
      }
    }
    if(close) {
      this.finishEditing()
    }
  }

  handleChangeTime = (value) => {
    let {editValue} = this.state
    if(editValue.clone) {
      editValue = editValue.clone()
    }
    if(addTimeInputStringToDate(value, editValue)) {
      this.setState({editValue})
    }
  }

  render() {
    let {recordingEvent, onDelete, timezone} = this.props
    let {editing, editValue} = this.state

    let recordingName = recordingEvent["recording name"] || recordingEvent.name

    let recordingNameContents = ''
    if(editing === "recording name") {
      recordingNameContents = (<Input value={editValue}
        id="recordingNameInput"
        onKeyPress={(e) => {if(e.key === "Enter") { e.target.blur() }}}
        onChange={(e) => {this.setState({editValue: e.target.value})}}
        onBlur={(e) => {this.finishEditing()}}/>)
    }

    let repeatContents = ""
    if(editing === "repeat") {
      repeatContents = (<Menu vertical items={repeatOptions} onItemClick={(e, data) => {e.stopPropagation(); this.finishEditing(data.value)}}/>)
    }

    let startContents = ""
    if(editing === "start") {
      startContents = (
        <div className="recordingEventTimeEdit">
          <DatePicker selected={editValue}
            onChange={(value) => {this.setState((state) => ({...state, editValue: value}))}}
            inline/>
          <div className="recordingEventTimeEditConfirmLine">
            <TimeInput onChange={this.handleChangeTime} defaultValue={editValue instanceof Date ? editValue.toLocaleTimeString("en-US") : editValue.tz(timezone).format("h:mm:ss a")}/>
            <Button positive icon="check" onClick={() => this.finishEditing()}/>
          </div>
        </div>
      )
    }

    let endContents = ""
    if(editing === "end") {
      endContents = (
        <div className="recordingEventTimeEdit">
          <DatePicker selected={editValue}
            onChange={(value) => {this.setState((state) => ({...state, editValue: value}))}}
            inline/>
          <div className="recordingEventTimeEditConfirmLine">
            <TimeInput onChange={this.handleChangeTime} defaultValue={editValue instanceof Date ? editValue.toLocaleTimeString("en-US") : editValue.tz(timezone).format("h:mm:ss a")}/>
            <Button positive icon="check" onClick={() => this.finishEditing()}/>
          </div>
        </div>
      )
    }

    let repeatCell = ""
    if('repeat' in recordingEvent) {
      repeatCell = (
        <Table.Cell textAlign='center' singleLine onClick={() => {this.startEditing("repeat")}} selectable>
          <Popup trigger={<span/>}
            className={"recordingEventPopup" + recordingEvent.id}
            open={editing === "repeat"}
            content={repeatContents}/>
          {recordingEvent['repeat']}
        </Table.Cell>
      )
    }

    let {start, end} = recordingEvent
    if(timezone) {
      start = start.tz(timezone).format("MMM Do YYYY, h:mm:ss a")
      end = end.tz(timezone).format("MMM Do YYYY, h:mm:ss a")
    } else {
      start = start.format("MMM Do YYYY, h:mm:ss a")
      end = end.format("MMM Do YYYY, h:mm:ss a")
    }

    return (<Table.Row key={recordingEvent.id} id={"recordingEventRow" + recordingEvent.id}>
      <Table.Cell style={{paddingLeft: "1em"}} onClick={() => {this.startEditing("recording name")}} selectable>
        <Popup trigger={<span/>}
          className={"recordingEventPopup" + recordingEvent.id}
          open={editing === "recording name"}
          content={recordingNameContents}/>
        {recordingName}
      </Table.Cell>
      {repeatCell}
      <Table.Cell textAlign='center' singleLine onClick={() => {this.startEditing("start")}} selectable>
        <Popup trigger={<span/>}
          className={"recordingEventPopup" + recordingEvent.id}
          open={editing === "start"}
          content={startContents}/>
        {start}
      </Table.Cell>
      <Table.Cell textAlign='center' singleLine onClick={() => {this.startEditing("end")}} selectable>
        <Popup trigger={<span/>}
          className={"recordingEventPopup" + recordingEvent.id}
          open={editing === "end"}
          content={endContents}/>
        {end}
      </Table.Cell>
      <Table.Cell textAlign='center' singleLine>
        {/*<Icon bordered inverted name='edit'
          onClick = {(e) => actions.editEventModal(recordingEvent)}
          className="inputRecording--icon" />*/}
        <Button icon='trash' color='red' className="inputRecording--icon"
          onClick = {() => onDelete(recordingEvent)}/>
      </Table.Cell>
    </Table.Row>)
  }

}
