import React, {PureComponent} from 'react'
import {Breadcrumb, Button} from 'semantic-ui-react'

class LibraryNavBar extends PureComponent {

  constructor(props) {
    super(props)
    this.handlePathClick = this.handlePathClick.bind(this)
    this.handleBackClick = this.handleBackClick.bind(this)
  }

  handlePathClick(e, data) {
    let index = data.index
    this.props.changeLibraryPath(this.props.navpath.slice(0, index + 1))
  }

  handleBackClick() {
    let {navpath, searchParam, changeLibraryPath} = this.props
    if(searchParam) {
      changeLibraryPath(navpath)
    } else {
      changeLibraryPath(navpath.slice(0, navpath.length - 1))
    }
  }

  render() {
    let {navpath, searchParam} = this.props

    let backButton = (searchParam || navpath.length > 1) ?
      <Button basic icon='chevron left' size='tiny' onClick={this.handleBackClick}/> :
      ''

    let sections = navpath.map((level, index) => {
      return (index === navpath.length - 1 && !searchParam) ?
        {key: (index + level), content: level, active: true} :
        {key: (index + level), content: level, index, onClick: this.handlePathClick}
    })

    if(navpath[0] !== '') {
      sections = [
        {key: 'blank', content: '', active: true},
        ...sections,
      ]
    }

    if(searchParam) {
      sections.push({key: ('_search'), content: ('Search: ' + searchParam), active: true})
    }

    return (
      <React.Fragment>
      {
        (searchParam || navpath.length > 0) ?
      <div className='LibraryBreadcrumbs'>
        {backButton}
        <Breadcrumb sections={sections} divider='/' size='large'/>
      </div> :
      ''
      }
      </React.Fragment>
    )
  }

}

export default LibraryNavBar
