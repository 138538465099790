import React, {PureComponent} from 'react'

import './SelectionBox.css'

export default class SelectionBox extends PureComponent {

  render() {
    let style = this.props.position

    return (
      <div className="SelectionBox" style={style}/>
    )
  }

}
