import React, {PureComponent} from 'react';
import {Modal, Button, Header, Message} from 'semantic-ui-react'
import StringPrompt from './StringPrompt'
import TimePrompt from './TimePrompt'
import DurationPrompt from './DurationPrompt'
import AspectRatioPrompt from './AspectRatioPrompt'
import NumberPrompt from './NumberPrompt'
import PathPrompt from './PathPrompt'
import PasswordPrompt from './PasswordPrompt'
import MultipleChoicePrompt from './MultipleChoicePrompt'
import ScheduleDatePrompt from './ScheduleDatePrompt'
import DateTimeInput from 'components/TimeInput/DateTimeInput'
import moment from 'moment'

export default class PromptModal extends PureComponent {

  constructor(props) {
    super(props)
    let initialValue = props.options.initialValue || ''
    this.state = {value: initialValue, invalid: false}
  }

  handleCancel = (e, data) => {
    this.props.dismiss()
    this.props.onSubmit(null)
  }

  handleKeyPress = (e, data) => {
    if(e.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = (e, data) => {
    let {dismiss, onSubmit, options} = this.props
    let {value} = this.state
    value = submitType(value, options)
    if(value === null || ('validate' in options &&
      !options.validate(value))) {
      this.setState({invalid: true})
      return
    }
    dismiss()
    onSubmit(value)
  }

  handleValueChange = (newValue) => {
    this.setState({value: newValue})
  }

  render() {
    let {message, options} = this.props
    let {value, invalid} = this.state

    let callProps = { changeValue: this.handleValueChange }

    let PromptForm = StringPrompt
    switch(options.type) {
      case 'time':
        PromptForm = TimePrompt
        break
      case 'duration':
        PromptForm = DurationPrompt
        break
      case 'aspectRatio':
        PromptForm = AspectRatioPrompt
        break
      case 'number':
        PromptForm = NumberPrompt
        break
      case 'path':
        PromptForm = PathPrompt
        break
      case 'password':
        PromptForm = PasswordPrompt
        break
      case 'multipleChoice':
        PromptForm = MultipleChoicePrompt
        break
      case 'scheduleDate':
        PromptForm = ScheduleDatePrompt
        break
      case 'dateTime':
        PromptForm = DateTimeInput
        callProps = { onChange: this.handleValueChange }
        break
      default: {
        break
      }
    }

    let invalidMessage = options.invalidText || 'Invalid Input'

    return (
      <Modal open={true} closeOnDimmerClick={false} onKeyPress={this.handleKeyPress}>
        <Modal.Content>
          <Header size='small'>
            {message}
          </Header>
          <Message error hidden={!invalid}>
            {invalidMessage}
          </Message>
          <Modal.Description>
            <PromptForm value={value}
              {...callProps}
              options={options}/>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel}>
            Cancel
          </Button>
          <Button primary onClick={this.handleSubmit}>
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

}

function submitType(value, options={}) {
  switch(options.type) {
    case 'time':
      return submitTime(value, options)
    case 'duration':
      return submitDuration(value, options)
    case 'aspectRatio':
      return submitAspectRatio(value, options)
    case 'number':
      return submitNumber(value, options)
    case 'path':
      return submitPath(value, options)
    case 'multipleChoice':
      return submitMultipleChoice(value, options)
    default:
      return value
  }
}

function submitTime(value, options={}) {
  if(moment.isMoment(value) && value.isValid()) {
    return value
  }
  let formats = ['h:mm:ss a', 'h:mm a']
  if(options.subseconds) {
    formats = ['h:mm:ss.SSS a', ...formats]
  }
  let returnValue = moment(value, formats, true)
  if(returnValue.isValid()) {
    return returnValue
  } else {
    return null
  }
}

function submitDuration(value, options={}) {
  return moment.duration(value)
}

function submitAspectRatio(value, options={}) {
  if(!value || !(value instanceof Array)) {
    return null
  }
  return value.map((num) => {
    if(typeof num === 'string') {
      num = parseInt(num, 10)
    }
    return Math.max(1, num)
  })
}

function submitNumber(value, options={}) {
  if(typeof value === 'string') {
    if(options.decimal) {
      return parseFloat(value)
    } else {
      return parseInt(value, 10)
    }
  } else {
    if(options.decimal) {
      return value
    } else {
      return Math.floor(value)
    }
  }
}

function submitPath(value, options={}) {
  if(typeof value !== 'object' ||
    typeof value.filename !== 'string' ||
    !(value.directory instanceof Array)) {
    return null
  }
  return value
}

function submitMultipleChoice(value, options={}) {
  let {choices} = options
  if(!value && value !== 0) {
    return null
  }
  if(choices[value]) {
    return choices[value].returnValue || choices[value]
  }
  return value
}
