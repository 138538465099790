import {fetchFromServer} from 'helpers/net_helpers'
import {getIn, setIn} from 'helpers/general_helpers'

export const FETCH_LOGS = Symbol("fetch logs")
export const CHANGE_LOGS_TYPE = Symbol("change logs type")

export const VALID_LOG_TYPES = [
  "apache_error",
  "apache_access",
  "messages"
]

export const VALID_LOG_LABELS = {
  "apache_error": "Apache Errors",
  "apache_access": "Apache Access",
  "messages": "System Log"
}

/**
 * Fetches a number of entries from the system log
 * @param {string} [opts.logType=null] The type of log to be fetched, either "apache_error", "apache_access", or "messages".
 *  If none of these are passed, then the currently viewed log type will be used by default.
 * @param {boolean} [from_end=true] If true, get a number of entries from the end of the log file. If false, they
 *  will be fetched from the beginning instead.
 * @param {number} [count=null] The number of entries to fetch. If null or 0 is passed, then it will default to the
 *  current number of entries for that log type that are in memory plus 1000. If the direction being read from changes,
 *  then the number will be 1000 by default.
 */
export const fetchLogs = (opts={logType:null, from_end:null, count:null}) => {
  return async (dispatch, getState) => {
    let {logType, from_end, count} = opts
    if(!(VALID_LOG_TYPES.includes(logType))) {
      logType = getState().logs.currentlyViewedLog
    }
    if(!count) {
      count = getIn(getState(), ['logs', 'logs', logType, 'entries', 'length']) || 0
      count = count + 1000
    }
    if(from_end === null) {
      from_end = getIn(getState(), ['logs', 'logs', logType, 'from_end'])
    }
    if(!from_end) {
      count = -1 * count
    }
    let res = await fetchFromServer(`/v2/other/logs/${logType}?count=${count}`)
    if(!res.ok) {
      let errMsg = await res.text();
      console.error(`Error fetching ${count} entries from ${logType}:`)
      console.error(errMsg);
      return
    }
    let entries = await res.text();
    entries = entries.split("\n");
    dispatch({
      type: FETCH_LOGS,
      payload: {
        logType,
        from_end,
        entries
      }
    })
  }
}

/**
 * Changes the currently viewed log type
 * @param {string} logType The type of logs to view; either "apache_error", "apache_access", or "messages". If none
 *  of these are passed, then nothing will happen.
 */
export const changeLogsType = (logType) => {
  if(!(VALID_LOG_TYPES.includes(logType))) {
    return
  }
  return {
    type: CHANGE_LOGS_TYPE,
    payload: logType
  }
}

export const initialState = {
  logs: {
    apache_error: {
      from_end: true,
      entries: []
    },
    apache_access: {
      from_end: true,
      entries: []
    },
    messages: {
      from_end: true,
      entries: []
    }
  },
  currentlyViewedLog: "apache_error" // Currently viewed log type
}

export default (state=initialState, action) => {
  let {type, payload} = action

  switch(type) {
    case FETCH_LOGS: {
      let {logs} = state
      let {logType, entries, from_end} = payload
      logs = setIn(logs, [logType, "entries"], entries)
      logs = setIn(logs, [logType, "from_end"], from_end)
      return {
        ...state,
        logs
      }
    }
    case CHANGE_LOGS_TYPE: {
      return {
        ...state,
        currentlyViewedLog: payload
      }
    }
    default:
      return state
  }
}
