export const CHANGE_LIBRARY_PATH = Symbol('change library path')
export const SET_SELECTED_FILES = Symbol('set selected files')
export const SELECT_FILE = Symbol('select file')
export const SELECT_FILE_ONLY = Symbol('select file only')
export const SEARCH_LIBRARY = Symbol('search library')
export const SORT_LIBRARY = Symbol('sort library')
export const PREVIEW_FILE = Symbol('preview file')
export const VIEW_CONSENT_FORM = Symbol('view consent form')

/**
 * Changes which path the library is viewing.
 * @param {array} path The new path to view in array form ('foo/bar/baz/' would become ['foo', 'bar', 'baz'])
 */
export const changeLibraryPath = (path) => {
  return {
    type: CHANGE_LIBRARY_PATH,
    payload: path
  }
}

/**
 * Selects a file in the library, or deselects it if it is already selected
 * @param {string} rpath The rpath of the file to select/deselect
 */
export const selectFile = (rpath) => {
  return {
    type: SELECT_FILE,
    payload: rpath
  }
}

/**
 * Selects only a given file in the library, ensuring other files are deselected. If the file is currently the only
 * selected file, it is deselected leaving no files selected.
 * @param {string} rpath The rpath of the file to select/deselect
 */
export const selectFileOnly = (rpath) => {
  return {
    type: SELECT_FILE_ONLY,
    payload: rpath
  }
}

/**
 * Sets the list of selected files
 * @param {array} files The new list of selected files
 */
export const setSelectedFiles = (files) => {
  return {
    type: SET_SELECTED_FILES,
    payload: files
  }
}

/**
 * Searches the files in the library by the name value of each file from fileData
 * @param {string} param The value to search by
 */
export const searchLibrary = (param) => {
  return {
    type: SEARCH_LIBRARY,
    payload: param
  }
}

export const sortLibrary = (column) => ({
  type: SORT_LIBRARY,
  payload: column
})

export const previewFile = (path=null, type='') => ({
  type: PREVIEW_FILE,
  payload: {
    path,
    type
  }
})

export const viewConsentForm = (path=null) => ({
  type: VIEW_CONSENT_FORM,
  payload: path
})

export const initialState = {
  navpath: ['mnt', 'main'],          // The path currently being viewed in array form
  selectedFiles: [],      // Array of selected file rpaths
  searchParam: '',        // Value to search by.
  sortColumn: '', // The column to sort by
  sortAsc: false,         // If true, sort ascending. If false, sort descending
  previewPath: null,      // Path of file to preview, or null if not previewing a file
  previewType: '',        // Type of file to preview
  consentFormPath: null,  // Path of file to view consent form of, or null if not viewing consent form of a file
}

export default (state=initialState, action) => {

  let {type, payload} = action

  switch(type) {
    case CHANGE_LIBRARY_PATH:
      return {
        ...state,
        navpath: payload,
        searchParam: '',
        selectedFiles: []
      }
    case SELECT_FILE: {
      let indexSelected = state.selectedFiles.findIndex((file) => {
        return(file.join('/') === payload.join('/'))
      })
      if(indexSelected > -1) {
        return {
          ...state,
          selectedFiles: state.selectedFiles.filter((item => item.join('/') !== payload.join('/')))
        }
      } else {
        return {
          ...state,
          selectedFiles: state.selectedFiles.concat([payload])
        }
      }
    }
    case SELECT_FILE_ONLY:
      if(state.selectedFiles.length !== 1 || state.selectedFiles[0].join('/') !== payload.join('/')) {
        return {
          ...state,
          selectedFiles: [payload]
        }
      } else {
        return {
          ...state,
          selectedFiles: []
        }
      }
    case SET_SELECTED_FILES: {
      return {
        ...state,
        selectedFiles: payload
      }
    }
    case SEARCH_LIBRARY:
      return {
        ...state,
        searchParam: payload
      }
    case SORT_LIBRARY:
      if(state.sortColumn === payload) {
        return {
          ...state,
          sortAsc: !state.sortAsc
        }
      } else {
        return {
          ...state,
          sortColumn: payload,
          sortAsc: false
        }
      }
    case PREVIEW_FILE:
      return {
        ...state,
        previewPath: payload.path,
        previewType: payload.type
      }
    case VIEW_CONSENT_FORM:
      return {
        ...state,
        consentFormPath: payload
      }
    default:
      return state
  }

}
