import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import {actions} from 'redux/settings'
import {fetchLogs, changeLogsType} from 'redux/components/logs'
import {openApplication} from 'redux/menu'
import {downloadFile} from 'redux/file_list'
import LocalSettings from 'selectors/LocalSettingsSelector'
import {getIn} from 'helpers/general_helpers'

import './Settings.css'

import ServicesPage from 'components/Settings/ServicePage'
import ServiceSettings from 'components/Settings/ServiceSettings'
import QueueControls from 'components/Settings/Queue/Queue'
import RoutingControls from 'components/Settings/RoutingControls/RoutingControls'
import InputControls from 'components/Settings/InputControls/InputRecording'
import OutputControls from 'components/Settings/OutputControls/OutputRecording'

class Settings extends PureComponent {

  render() {
    return (
      <Switch>
        <Route path="/services/:type/:id" exact render={props => (
          <ServiceSettings {...this.props} {...props}/>
        )}/>
        <Route path="/services/channel/:id/queue" exact render={props => (
          <QueueControls {...this.props} {...props}/>
        )}/>
        <Route path="/services/input/:id/controls" exact render={props => (
          <InputControls {...this.props} {...props}/>
        )}/>
        <Route path="/services/output/:id/controls" exact render={props => (
          <OutputControls {...this.props} {...props}/>
        )}/>
        <Route path="/services/router/:id/controls" exact render={props => (
          <RoutingControls {...this.props} {...props}/>
        )}/>
        <Route path="/services/:type" exact render={props => (
          <ServicesPage {...this.props} {...props}/>
        )}/>
        <Redirect to="/services/channel"/>
      </Switch>
    )
  }

}

const mapStateToProps = (state) => ({
  ...state.settings,
  origServices: state.settings.services,
  services: {
    ...LocalSettings(state.settings),
    support: getIn(state, ['system', 'services', 'supportStatus']) || {},
    system: getIn(state, ['system', 'services', 'serviceStatusExt']) || {},
    serviceDisplayOrder: getIn(state, ['system', 'services', 'serviceStatusDisplayOrder']) || [],
    logs: state.logs
  },
  routing: state.router
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
  openApplication,
  downloadFile,
  fetchLogs,
  changeLogsType,
  ...actions
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
