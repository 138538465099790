import React from 'react'

import {List} from 'semantic-ui-react'

export default props => {
  let statusLines = assembleLines(props)

  return (
    <List items={statusLines}/>
  )
}

function assembleLines(props) {
  let {arch,
    coreLoads,
    cores,
    cpuTemp,
    cpuLoadPercent,
    diskSpacePercent,
    diskSpaceTotal,
    diskSpaceUsed,
    fileDescriptorCount,
    fileDescriptorLoad,
    fileDescriptorTotal,
    memoryLoadPercent,
    memoryTotal,
    memoryUsed,
    raidStatus,
    uptime} = props
  let toReturn = []
  if(arch || uptime) {
    let line = `System: ${arch ? arch : ''}, uptime: ${formatUptime(uptime)}`
    toReturn.push({key: 'system', content: line})
  }
  if(cores || cpuLoadPercent || coreLoads) {
    let line = `CPU: ${cpuLoadPercent} used across ${cores} cores ${formatCpuUsage(coreLoads)}`
    toReturn.push({key: 'cpu', content: line})
  }
  if(cpuTemp) {
    toReturn.push({key: 'temp', content: formatTemperature(cpuTemp)})
  }
  if(memoryLoadPercent || memoryTotal || memoryUsed) {
    let line = `Memory: ${memoryLoadPercent} used ( ${memoryUsed} out of ${memoryTotal} )`
    toReturn.push({key: 'memory', content: line})
  }
  if(fileDescriptorCount || fileDescriptorLoad || fileDescriptorTotal) {
    let line = `File Descriptors: ${fileDescriptorLoad} % used ( ${fileDescriptorCount} out of ${fileDescriptorTotal} )`
    toReturn.push({key: 'fd', content: line})
  }
  if(diskSpacePercent || diskSpaceUsed || diskSpaceTotal) {
    let line = `Disk Space: ${diskSpacePercent} used ( ${diskSpaceUsed} out of ${diskSpaceTotal} )`
    toReturn.push({key: 'disk', content: line})
  }
  if(raidStatus) {
    let line = <span>Raid Status: {formatRaidStatus(raidStatus)}</span>
    toReturn.push({key: 'raid', content: line})
  }
  return toReturn
}

function formatUptime(uptime) {
  let toReturn = 'Unknown'
  if(uptime) {
    let days = uptime.days || '??'
    let hours = uptime.hours || '??'
    let minutes = `${uptime.minutes}` || '??'
    let seconds = `${uptime.seconds}` || '??'
    toReturn = `${days} days ${hours}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`
  }
  return toReturn
}

function formatCpuUsage(coreLoads) {
  let toReturn = '(Unknown)'
  if(coreLoads) {
    toReturn = `( ${coreLoads.join(', ')} )`
  }
  return toReturn
}

function formatTemperature(cpuTemp) {
  let coreTemps = cpuTemp.cores.map(formatCpuTemp).join(', ')
  let avgTemp = formatCpuTemp(cpuTemp.main)
  return `CPU Temperature: ${avgTemp}, Cores: ( ${coreTemps} )`
}

function formatCpuTemp(temp) {
  if(temp || temp === 0) {
    return `${temp}°C/${Math.floor(temp * (9/5)) + 32}°F`
  } else {
    return "Unknown"
  }
}

function formatRaidStatus(raidStatus) {
  let toReturn = 'none'
  if(raidStatus) {
    if(raidStatus.length > 1) {
      let raidDevices = raidStatus.map((raid) => (
        <List.Item key={raid.name} content={`${raid.name}: ${formatRaidDeviceStatus(raid)}`}/>
      ))
      toReturn = (<List.List>
        {raidDevices}
      </List.List>)
    } else if (raidStatus.length === 1) {
      toReturn = formatRaidDeviceStatus(raidStatus[0])
    }
  }
  return toReturn
}

function formatRaidDeviceStatus(raid) {
  let statusLine = ''
  if(!raid.ok) {
    let devices = raid.devices || raid.drives || []
    let down = devices.filter((device) => !device.active)
      .map(device => device.name)
    statusLine = `RAID DEGRADED!!! ${down.length} failed disks out of ${raid.total} disks: ${down.join(', ')}`
  } else {
    if(raid.recoveryStatus) {
      statusLine = `Rebuilding ${raid.type}, ${raid.recoveryStatus}`
    } else {
      statusLine = `${raid.status} ${raid.type} with ${raid.total} disks`
    }
  }
  return statusLine
}
