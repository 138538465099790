import React, {Component} from 'react'
import {Checkbox,
  Dropdown,
  Label} from 'semantic-ui-react'

const ASPECT_RATIO_OPTIONS = [
  {key: '16:9', value: '16:9', text: '16:9'},
  {key: '4:3', value: '4:3', text: '4:3'},
  {key: '1:1', value: '1:1', text: '1:1'},
  {key: '3:4', value: '3:4', text: '3:4'},
  {key: '9:16', value: '9:16', text: '9:16'},
  {key: 'custom', value: 'custom', text: 'custom...'}
]

const FRAME_RATE_OPTIONS = [
  {key: '1000', value: '1000', text: '1000fps (Milliseconds)'},
  {key: '100', value: '100', text: '100fps (Centiseconds)'},
  {key: '60', value: '60', text: '60fps (NTSC)'},
  {key: '59.94', value: "60000/1001", text: '59.94fps (NTSC)'},
  {key: '50', value: '50', text: '50fps (PAL)'},
  {key: '30', value: '30', text: '30fps (NTSC)'},
  {key: '29.97', value: "30000/1001", text: '29.97fps (NTSC)'},
  {key: '25', value: '25', text: '25fps (PAL)'},
  {key: '24', value: '24', text: '24fps (film)'},
  {key: '23.976', value: "24000/1001", text: '60fps (film telecine)'},
  {key: '15', value: '15', text: '15fps'},
  {key: '10', value: '10', text: '10fps'},
  {key: 'custom', value: 'custom', text: 'custom...'}
]

class PlaylistControls extends Component {

  shouldComponentUpdate(nextProps) {
    let {frames,
      snapToGrid,
      aspectRatio,
      frameRate,
      skipMissingExpired,
      overrideComingUpNext,
      enforceAspectRatio,
      mainRegion} = this.props
    return (snapToGrid !== nextProps.snapToGrid ||
      aspectRatio !== nextProps.aspectRatio ||
      frameRate !== nextProps.frameRate ||
      skipMissingExpired !== nextProps.skipMissingExpired ||
      overrideComingUpNext !== nextProps.overrideComingUpNext ||
      enforceAspectRatio !== nextProps.enforceAspectRatio ||
      mainRegion !== nextProps.mainRegion ||
      !compareFrames(frames, nextProps.frames))
  }

  handleAspectRatio = (e, data) => {
    if(data.value === 'custom') {
      this.props.promptAspectRatio()
    } else {
      let newRatio = data.value.split(':')
      if(newRatio) {
        newRatio = newRatio.map(value => parseInt(value, 10))
        this.props.setAspectRatio(newRatio)
      }
    }
  }

  handleFrameRate = (e, data) => {
    let newFrameRate = data.value
    if(newFrameRate === 'custom') {
      let placeholder = parseFloat(data.text)
      this.props.promptFrameRate(placeholder)
    } else {
      if(newFrameRate.includes("/")) {
        let [n, d] = newFrameRate.split("/")
        newFrameRate = {n, d}
      } else {
        newFrameRate = parseFloat(newFrameRate)
      }
      if(newFrameRate) {
        this.props.setFrameRate(newFrameRate)
      }
    }
  }

  handleChangeMainRegion = (e, data) => {
    this.props.setMainRegion(data.value);
  }

  render() {
    let {frames,
      snapToGrid,
      aspectRatio,
      frameRate,
      skipMissingExpired,
      overrideComingUpNext,
      enforceAspectRatio,
      mainRegion,
      setSnapToGrid,
      setSkipMissingExpired,
      setOverrideComingUpNext,
      setEnforceAspectRatio} = this.props

    let mainRegionOptions = [ { text: "(None)", value: 0 } ].concat(frames.map((frame, index) => {
      return {
        text: frame.name ? frame.name : `Region ${index + 1}`,
        value: index + 1
      }
    }))

    let frameRateText = `${frameRate} fps`
    if(typeof(frameRate) === "object") {
      // Do reduction to single number here for display purposes only
      let framesps = Math.floor((frameRate.n / frameRate.d) * 100) / 100
      frameRateText = `${framesps} fps`
    }

    return (
      <div id='PlaylistControls'>
        <Checkbox checked={snapToGrid} onChange={(e, data) => {setSnapToGrid(data.checked)}} label='Snap To Grid'/><br/>
        <Label className='noselect'>Aspect Ratio</Label>
        <Dropdown text={aspectRatio.join(':')}
          options={ASPECT_RATIO_OPTIONS}
          onChange={this.handleAspectRatio}/><br/>
        <Label className='noselect'>Frame Rate</Label>
        <Dropdown text={frameRateText}
          options={FRAME_RATE_OPTIONS}
          onChange={this.handleFrameRate}/><br/>
        <Label className='noselect'>Main Region</Label>
        <Dropdown text={mainRegionOptions[mainRegion+1] ? mainRegionOptions[mainRegion+1].text : `Region ${mainRegion}`}
          options={mainRegionOptions}
          onChange={this.handleChangeMainRegion}/><br/>
        <Checkbox checked={skipMissingExpired} onChange={(e, data) => {setSkipMissingExpired(data.checked)}} label='Skip Missing and Expired Items'/><br/>
        <Checkbox checked={overrideComingUpNext} onChange={(e, data) => {setOverrideComingUpNext(data.checked)}} label='Override Coming Up Next'/><br/>
        <Checkbox checked={!!enforceAspectRatio} onChange={(e, data) => {setEnforceAspectRatio(data.checked)}} label='Enforce Aspect Ratio'/>
      </div>
    )
  }

}

function compareFrames(frames, nextFrames) {
  if(frames.length !== nextFrames.length) {
    return false
  }
  for(let i = 0; i < frames.length; i++) {
    if(frames[i].name !== nextFrames[i].name) {
      return false
    }
  }
  return true
}

export default PlaylistControls
