/**
 * Check if a user is allowed access to a given application based on their accessList
 * @param {string/array} accessList An array of string application names that the user is allowed to access, or string "*" if the
 *  user has access to all apps.
 * @param {string} appName The name of the application to check access to
 * @returns Boolean true/false indicating whether the user may access the given app or not
 */
export const checkHasAccessToApp = (accessList, appName) => {
  return accessList && (accessList === '*' ||
    accessList.includes("*") ||
    accessList.includes(appName))
}
