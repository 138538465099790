import React, {PureComponent} from 'react'
import { NavLink } from 'react-router-dom';
import {Menu, Icon, Image, Label} from 'semantic-ui-react'
import {staticAsset} from 'helpers/net_helpers'

export default class AppIcon extends PureComponent {

  componentDidUpdate(prevprops) {
    if(prevprops.tabs > 0 && this.props.tabs === 0) {
      this.props.closeApplication(null, this.props.index)
    }
  }

  handleClick = (e) => {
    let {appName, openApplication} = this.props
    e.preventDefault();
    openApplication(appName);
  }

  render() {
    let {app,
      appName,
      index,
      tabs,
      active,
      pinned,
      pinApplication,
      closeApplication} = this.props

    let clsName = 'pin-icon'
    if(pinned) {
      clsName = clsName + ' pinned'
    }

    return (
      <Menu.Item as={NavLink}
          active={active}
          to={app.link}
          index={index}
          onClick={this.handleClick}>
        {app.noSvg ?
          <Icon name={app.icon}/> :
          <Icon className={'svg-icon'}>
            <Image src={staticAsset(`/icons/${app.icon}_white.svg`)}/>
          </Icon>
        }
        {app.name}
        <Icon name='pin' className={clsName} onClick={(e) => {pinApplication(e, appName)}}/>
        <Icon name='remove' className='remove-icon' onClick={(e) => {closeApplication(e, index)}}/>
        {tabs > 1 ? <Label circular size='mini' className='tabNumbers' color='red'>
          {tabs}
        </Label> : ''}
      </Menu.Item>
    )
  }

}
