import { createSelector } from 'reselect'
import { getIn } from 'helpers/general_helpers'
import {numberawareLocaleCompare} from 'helpers/numawaresorting'

const getChannels = (state) => state.services.channel
const getThumbs = (state) => state.confidence_thumbs

export default createSelector([getChannels, getThumbs], (channels, thumbs) => {

  return Object.entries(channels).map(([key, chan]) => {
    return selectChannel({key, settings: chan.settings, thumbs})
  }).sort((a, b) => numberawareLocaleCompare(a.key,b.key))

})

// key
const getKey = (state) => state.key
// name
const getName = (state) => {
  let channelNumber = state.key.replace(/^channel/,'')
  return getIn(state, ['settings', 'name']) || `Channel #${channelNumber}`
}
// running
const getRunning = (state) => (getIn(state, ['settings', 'running']))
// queue
const getQueue = (state) => (getIn(state, ['settings', 'queue']))
// regions
const getRegions = (state) => (getIn(state, ['settings', 'regions']))
// audio, vuMeter
const getVuMeters = (state) => {
  if(!getIn(state, ['settings', 'running'])) {
    return []
  } else {
    return getIn(state, ['settings', 'audio', 'vuMeter'])
  }
}
// thumb
const getThumb = (state) => ((state.thumbs && state.thumbs[state.key]) ? state.thumbs[state.key] : '')

const selectChannel = createSelector([getKey, getName, getRunning, getQueue, getRegions, getThumb, getVuMeters],
(key, name, running, queue, regions, image, vuMeters) => {
  let hasQueue = (queue && queue.queue && queue.queue.filter((item) => !!item).length > 0)
  let {file, assigned, fileType, assignedType} = getAssignedItem(queue, regions)
  let on_air = !!running
  return {
    name,
    key,
    on_air,
    file,
    assigned,
    fileType,
    assignedType,
    image,
    percent_played: 0,
    hasQueue,
    vuMeters
  }
})

const getAssignedItem = (queueInfo, regions) => {
  let file = ''
  let assigned = ''
  let fileType = ''
  let assignedType = ''
  if(!regions) {
    return {file, assigned, fileType, assignedType}
  }
  if(queueInfo) {
    let queue = queueInfo.queue.filter((item) => !!item)
    if(queue.length > 0) {
      file = queue[0]
    }
    assigned = queueInfo.assigned
  }
  let main = getIn(regions, ['main'])
  // Before, main region could vary, but we are moving towards having only the "item" region be main at all times.
  if(!main) {
    main = {kind: "item", part: "", index: ""}
  } else if(typeof main === "string") {
    main = {kind: main, part: "", index: ""}
  }
  let {kind, part, index} = main
  let region = getIn(regions, [kind])
  if(kind === 'global') {
    region = region[index]
  } else if (kind === 'alert') {
    region = region[part]
  }
  let assignedPath = getIn(region, ['media', 'path'])
  assignedType = getIn(region, ['media', 'mimeType'])
  let filePath = getIn(region, ['media', 'leafPath'])
  fileType = getIn(region, ['media', 'leafMimeType'])
  if(assignedPath) {
    assigned = assignedPath
    if(assigned instanceof Array) {
      assigned = assigned.join("/")
    }
  }
  if(filePath && file === '') {
    file = filePath
    if(file instanceof Array) {
      file = file.join("/")
    }
  }
  return {file, assigned, fileType, assignedType}
}
