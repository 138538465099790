/**
 * Creates a new frame in a list of frames, with either default or given values
 * @param {array} frames An array of frame objects to add a new frame to
 * @param {object} newFrame Optional object containing any properties that the new frame should have.
 *  Any properties given in newFrame will override the default properties
 * @returns A copy of frames with a new frame added to the end of the array.
 */
export function createFrame(frames, newFrame={}) {
  return [
    ...frames,
    Object.assign({}, {name: '', position: {x: 0, y: 0}, size: {width: 100, height: 100}, options: {}, timeline: []}, newFrame)
  ]
}

/**
 * Removes a frame from a list of frames
 * @param {array} frames An array of frame objects
 * @param {number} index The index of the frame to be removed
 * @returns A copy of frames with the frame at index removed. If index is outside the range
 *  of the frames array, then an unchanged copy of the frames array will be returned.
 */
export function removeFrame(frames, index) {
  return frames.filter((frame, ind) => ind !== index)
}

/**
 * Adds a new clip to the timeline of a frame within a list of frames
 * @param {number} index The index of the frame to add the clip to
 * @param {array} frames The list of frames containing the frame to add the clip to
 * @param {object} Optional object containing any properties that the new clip should have.
 *  Properties given will override the default clip properties
 * @returns A copy of the frames array with a new clip added to the timeline of the frame at
 *  the given index. The timeline will be sorted by start times of clips.
 */
export function addClipToFrame(index, frames, clip={}) {
  return frames.map((frame, frame_ind) => {
    if(frame_ind === index) {
      return {
        ...frame,
        timeline: frame.timeline.concat(Object.assign({}, {item: '', start: 0}, clip)).sort((a, b) => a.start - b.start)
      }
    }
    return frame
  })
}

/**
 * Removes a clip from the timeline of a frame within a list of frames
 * @param {number} index The index of the frame to remove the clip from
 * @param {number} clip_index The index of the clip to be removed within the frame's timeline
 * @param {array} frames The list of frames containing the frame to remove the clip from
 * @returns A copy of the frames array with the clip at clip_index removed from the timeline of the frame at
 *  the given index
 */
export function removeClipFromFrame(index, clip_index, frames) {
  return frames.map((frame, frame_ind) => {
    if(frame_ind === index) {
      return {
        ...frame,
        timeline: frame.timeline.filter((time, time_ind) => time_ind !== clip_index)
      }
    }
    return frame
  })
}

/**
 * Modifies the properties of a clip in the timeline of a frame within a list of frames
 * @param {number} index The index of the frame containing the clip to modify within the list of frames
 * @param {number} clip_index The index of the clip to be modified within the frame's timeline
 * @param {array} frames The list of frames containing the frame whose clip is being modified
 * @param {object} Any properties that should replace the current properties of the clip.
 * @returns A copy of the frames array with the identified clip having any changed properties.
 *  The timeline will be sorted by start times of clips.
 */
export function modifyClip(index, clip_index, frames, properties) {
  return frames.map((frame, frame_ind) => {
    if(frame_ind === index) {
      return {
        ...frame,
        timeline: frame.timeline.map((time, time_ind) => {
          if(time_ind === clip_index) {
            return {...time, ...properties}
          }
          return time
        }).sort((a, b) => a.start - b.start)
      }
    }
    return frame
  })
}
