import { createSelector } from 'reselect'
import deepmerge from 'deepmerge'

const getSettings = (state) => state.services
const getChanges = (state) => state.serviceChanges
const getRouting = (state) => state.routingChanges

export default createSelector([getSettings, getChanges, getRouting], (settings, changes, routingChanges) => {
  return deepmerge.all([settings, changes, {router: routingChanges}], {arrayMerge: combineMerge})
})

const emptyTarget = value => Array.isArray(value) ? [] : {}
const clone = (value, options) => deepmerge(emptyTarget(value), value, options)

function combineMerge(target, source, options) {
	const destination = target.slice()

  let hasObject = destination.findIndex((item) => {
    return options.isMergeableObject(item)
  })

  if(hasObject === -1) {
    return source
  }

	source.forEach(function(e, i) {
		if (typeof destination[i] === 'undefined') {
			const cloneRequested = options.clone !== false
			const shouldClone = cloneRequested && options.isMergeableObject(e)
			destination[i] = shouldClone ? clone(e, options) : e
		} else if (options.isMergeableObject(e)) {
			destination[i] = deepmerge(target[i], e, options)
		} else {
			destination[i] = e
		}
	})
	return destination
}
