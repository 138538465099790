import React, { PureComponent } from 'react';
import './Transfer.css';
// import 'semantic-ui-css/semantic.min.css';
import {camelToCapitalized, encodeURIFilepath} from 'helpers/general_helpers'
import { Progress, Header, Segment} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import TransferButtons from './TransferButtons'

const STATUS_COLORS = {
  error: 'red',
  ongoing: 'yellow',
  paused: 'yellow',
  success: 'green'
}

const STATUS_MESSAGES = {
  error: 'Failed',
  ongoing: 'In Progress',
  paused: 'Paused',
  success: 'Completed'
}

class FileTransfer extends PureComponent {
  render() {
    let {type,
      state,
      from,
      to,
      summary,
      progress,
      id,
      pauseFileTransfer,
      resumeFileTransfer,
      cancelFileTransfer,
      clearFileTransfer,
      restartFileTransfer,
      editMetadata} = this.props

    let actions = {
      pauseFileTransfer,
      resumeFileTransfer,
      cancelFileTransfer,
      clearFileTransfer,
      restartFileTransfer,
      editMetadata
    }

    let message = summary || `${camelToCapitalized(type ? type : '')} ${STATUS_MESSAGES[state]}`

    let statusColor = STATUS_COLORS[state]

    let fromText = null;
    let toText = null;

    if(type !== "upload" && from) {
      let fromLocation = from.join('/')
      fromText = (<div>
        From: <Link className='transferLink' to={encodeURI(`/library${encodeURIFilepath(fromLocation)}`)}>{from.join('/')}</Link>
      </div>)
    }

    if(to) {
      let toLocation = to.join('/')
      if(type === 'vod' && to.length === 1) {
        toLocation = `/mnt/main/tv/vod/${to[0]}`
      } else if (type === 'upload') {
        toLocation = to.join('/')
      }
      toLocation = encodeURI(toLocation)
      toText = (<div>
        To: <Link className='transferLink' to={`/library${encodeURIFilepath(toLocation)}`}>{to.join('/')}</Link>
      </div>)
    }

    return (
      <div className="FileTransfer">
        <Segment id="download-from">
          <Header as="h3" color={statusColor}>
            {message}
          </Header>
          {fromText}
          {toText}
          {summary}
          <Progress percent={progress.percent}
            progress
            indicating
            success={state === 'success'}
            warning={state === 'paused'}
            error={state === 'error'}/>
          <TransferButtons id={id}
            type={type}
            state={state}
            to={to}
            {...actions}/>
        </Segment>
      </div>
    );
  }
}

export default FileTransfer;
