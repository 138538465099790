import React, {PureComponent} from 'react'
import {Search} from 'semantic-ui-react'

import './LibrarySearchBar.css'

class LibrarySearchBar extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {loading: false}
  }

  handleSearch = (e, data) => this.props.searchLibrary(data.value)

  render() {
    let {searchParam} = this.props
    let {loading} = this.state

    return (
      <Search className="librarySearch"
        loading={loading}
        value={searchParam}
        onSearchChange={this.handleSearch}
        showNoResults={false}
        size='small'/>
    )
  }
}

export default LibrarySearchBar
