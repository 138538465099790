import React, { Component } from 'react';

import './TextPreview.css';

function doMakeRGBAColor(c,alpha) {
	return `${c}${Math.floor(255 * Math.min(1, alpha)).toString(16).toUpperCase()}`
}

export default class TextPreview extends Component {

    constructor(props) {
      super(props)
      this.textPreview = React.createRef()
      this.testText = React.createRef()
      this.state = {previewWidth: 0, textLength: 0, previewHeight: 0}
    }

    componentDidMount() {
      this.updateSize()
      window.addEventListener("resize", this.updateSize)
    }

    componentDidUpdate(prevprops) {
      if(prevprops.properties.scrollingTextPreview !== this.props.properties.scrollingTextPreview ||
        prevprops.properties.scrollSpeed !== this.props.properties.scrollSpeed ||
        prevprops.properties.space !== this.props.properties.space) {
        this.startPreview();
      }
    }

    componentWillUnmount() {
      window.removeEventListener("resize", this.updateSize)
    }

    updateSize = () => {
      if(this.textPreview.current) {
        let {height, width} = this.textPreview.current.getBoundingClientRect()
        this.setState({previewWidth: width, previewHeight: height})
        this.restartPreview()
      }
    }

    /**
     * Copied from Version 4 GUI
     */
    redrawPreviewText = () => {
      if(this.textPreview.current) {
        let {scrollingTextPreview,
          scrollSpeed,
          space,
          justify,
          backgroundOpacity,
          backgroundColor,
          shadowOpacity,
          shadowColor,
          shadowBlur,
          shadowX,
          shadowY,
          fontColor,
          fontOpacity
        } = this.props.properties
        let {previewWidth, previewHeight} = this.state
        var tw = previewWidth;
        var th = previewHeight;
        var fh = Math.floor(th * 9 / 10);
        var again = false;
        var textWidth;
        var ty = 0;
        var metric;

        /*
        if (!this.isPageActive)
          return;
        */

        do {
          var px;
          var ctx = this.textPreview.current.getContext("2d");

          ctx.font = fh+'px \'Liberation Sans\'';
          ctx.textBaseline = 'top';
          ctx.fontAlign = 'left';

          metric = ctx.measureText(scrollingTextPreview);
          textWidth = metric.width;

          if (scrollSpeed > 0)
            px = this.prevOffset != null ? this.prevOffset / this.scrollScale : 0;
          else {
            if (justify === 'right')
								px = this.prevOffset = -(tw - textWidth);
							else if (justify === 'center')
								px = this.prevOffset = -Math.floor((tw - textWidth) / 2);
							else
								px = this.prevOffset = 0;
						}

						/* FIXME: Firefox 11 seems to have an understandable rendering issue
						 *        with alpha transparency in the background when drawing the
						 *        shadow of the text, which makes the drop shadow seem much
						 *        dimmer than it should when the background opacity is between
						 *        0 and 100 percent. */

						again = false;
						ctx.clearRect(0,0,tw,th);
						if (this.prevOffset != null) {
							if (backgroundOpacity > 0) {
								ctx.fillStyle = doMakeRGBAColor(backgroundColor, backgroundOpacity);
								ctx.fillRect(0,0,tw,th);
							}
						}

						if (this.prevOffsetMax === null) {
							again = true;
							this.prevOffsetMin = -tw * this.scrollScale * space;
							if (this.prevOffset === null) this.prevOffset = this.prevOffsetMin;
							this.prevOffsetMax = textWidth * this.scrollScale;
							continue;
						}

						if (shadowOpacity > 0) {
							ctx.shadowColor = doMakeRGBAColor(shadowColor, shadowOpacity);
							ctx.shadowBlur = (th * shadowBlur) / 100;
							ctx.shadowOffsetX = (th * shadowX) / 100;
							ctx.shadowOffsetY = (th * shadowY) / 100;
						}
						else {
							ctx.shadowColor = '';
							ctx.shadowBlur = 0;
							ctx.shadowOffsetX = 0;
							ctx.shadowOffsetY = 0;
						}

						var patience = 8;
						ctx.fillStyle = doMakeRGBAColor(fontColor,fontOpacity);
						while ((-px) < tw && --patience >= 0) {
							try { /* Ugh what the fuck Firefox? */
								ctx.fillText(scrollingTextPreview,-px,ty);
							} catch (e) { }

							/* do NOT repeat if static text */
							if (scrollSpeed <= 0)
								break;

							px -= textWidth;
							px -= (space * tw);
						}

						ctx.shadowColor = '';
						ctx.shadowBlur = 0;
						ctx.shadowOffsetX = 0;
						ctx.shadowOffsetY = 0;
					} while (again);
				}
      }

    previewTick = () => {
      var d = new Date();
      var t;
      let {scrollSpeed} = this.props.properties

      if (scrollSpeed !== 0) {
        t = d.getTime() - this.prevTimeStart;
        this.prevOffset = (((t * 30) / 1000) % (this.prevOffsetMax - this.prevOffsetMin)) + this.prevOffsetMin;
      }

      this.redrawPreviewText();
      if (scrollSpeed === 0) this.stopPreview();
    }
    restartPreview = () => {
      if (this.prevInterval != null) {
        this.stopPreview();
        this.startPreview();
      }
    }
    togglePreview = () => {
      if (this.prevInterval != null)
        this.stopPreview();
      else
        this.startPreview();
    }
    startPreview = () => {
      let {scrollSpeed} = this.props.properties
      let {previewHeight} = this.state
      var d = new Date();

      this.stopPreview();
      this.prevTimeStart = d.getTime();
      this.prevOffset = null;
      this.prevOffsetMax = null;
      this.scrollScale = 20 / previewHeight / scrollSpeed;
      this.prevInterval = setInterval(() => { this.previewTick(); },1000 / 30);
    }
    stopPreview = () => {
      if (this.prevInterval != null) {
        clearInterval(this.prevInterval);
        this.prevInterval = null;
      }
    }

    /**
     * End copied code
     */

    render() {
      let {
        previewWidth,
        previewHeight
      } = this.state
      return (
        <canvas className="scrollingTextPreview" ref={this.textPreview} width={previewWidth} height={previewHeight}/>
      )
        /*
        return (
            <div className="scrollingTextPreview" ref={this.textPreview}>
                <div className="scrollingTextPreview__test" ref={this.testText}>{properties.scrollingTextPreview}</div>
                <div className="scrollingTextPreview__background" style={previewBackStyle}/>
                <div className="scrollingTextPreview__marquee noselect" style={previewStyle}>
                  {previews}
                </div>
            </div>
        );
        */
    }
}
