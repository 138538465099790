import React, {PureComponent} from 'react'
import {Form, Input} from 'semantic-ui-react'

export default class AspectRatioPrompt extends PureComponent {

  widthInput = React.createRef()

  componentDidMount() {
    if(this.widthInput.current) {
      this.widthInput.current.focus()
    }
  }

  handleChange = (e, data) => {
    let {value, changeValue} = this.props
    if(!value || !(value instanceof Array)) {
      value = [1, 1]
    }
    if(data.name === 'width') {
      value = [data.value, value[1]]
    } else if(data.name === 'height') {
      value = [value[0], data.value]
    }
    changeValue(value)
  }

  render() {
    let {value} = this.props

    if(!value || !(value instanceof Array)) {
      value = [1, 1]
    }

    let [width, height] = value

    return (
      <Form>
        <Form.Group>
          <Form.Field>
            <Input type='number'
              label='Width'
              name='width'
              value={width}
              ref={this.widthInput}
              onChange={this.handleChange}
              min={1}/>
          </Form.Field>
          <Form.Field>
            <Input type='number'
              label='Height'
              name='height'
              value={height}
              onChange={this.handleChange}
              min={1}/>
          </Form.Field>
        </Form.Group>
      </Form>
    )
  }

}
