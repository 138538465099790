import { createSelector } from 'reselect'
import {getIn} from 'helpers/general_helpers'

const getId = (props) => (getIn(props, ["match", "params", "id"]))

export default createSelector([getId], (id) => {
  if(id && typeof id === "string") {
    id = decodeURIComponent(id)
  }
  return id
})
