import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {
  Form,
  Button,
  Message
} from 'semantic-ui-react'
import {authenticateUser, authenticationError, fetchInterfaceConfig} from 'redux/server'
import history from 'history.js'
import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'
import {serverPath} from 'helpers/net_helpers'

import './Authentication.css'

class Authentication extends PureComponent {

  constructor(props) {
    super(props)
    this.props.fetchInterfaceConfig()
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.state = {showLogo: true}
  }

  handleFormSubmit(e) {
    this.props.authenticateUser(e.target.username.value, e.target.password.value)
    .catch((err) => {console.warn(err)})
  }

  componentDidMount() {
    if(this.props.location.search.includes("error=401")) {
      history.push("/admin")
      this.props.authenticationError("You do not have permission to access this. " +
        "If you were already logged in, then your authentication token may have expired, and you will need to enter your login credentials again.")
    }
  }

  componentDidUpdate() {
    if(this.props.accessToken) {
      if(history.length) {
        history.goBack();
      } else {
        history.push("/");
      }
    }
  }

  render() {
    let {errorMessage, config} = this.props
    let hideMessage = errorMessage ? false : true

    return (
      <div id='authenticationPage'>
        <div id='authenticationForm'>
          {this.state.showLogo ? <img id="clientImage" alt="" src={serverPath("/local/client-logo.png")} onError={(e) => {this.setState({showLogo: false})}}/> : ""}
          <h1 id='authenticationHeader'>{config.loginTitle || "CASTUS Control Panel"}</h1>
          <Message header='Log in failed.'
            content={errorMessage}
            hidden={hideMessage}
            error/>
          <Form onSubmit={this.handleFormSubmit}>
            <Form.Input className='authenticationInput'
              name='username'
              type='text'
              label='Username'
              placeholder='Username'/>
            <Form.Input className='authenticationInput'
              name='password'
              type='password'
              label='Password'
              placeholder='Password'/>
            <Button type='submit'>Log In</Button>
          </Form>
        </div>
      </div>
    )
  }

}

let mapStateToProps = state => state.server

let mapDispatchToProps = (dispatch) => bindActionCreators({
  authenticateUser,
  authenticationError,
  fetchInterfaceConfig
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(loadingIndicator(Authentication))
