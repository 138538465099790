/**
 * Class representing a schedule block
 */
import moment from 'moment'
import ScheduleItem from './ScheduleItem'
import IntervalPeriod from 'helpers/IntervalTime/IntervalPeriod'
import ExactPeriod from 'helpers/ExactPeriod/ExactPeriod'
import {millisToHourString} from 'helpers/general_helpers'
import {serializeEndlessDate} from 'helpers/schedule_helpers'
import {v4 as uuidv4} from 'uuid'

const scheduleBlockProxy = {
  get(target, prop) {
    if("body" in target && "contains" in target.body && !(prop in target) && prop in target.body.contains) {
      return target.body.contains[prop]
    }
    return target[prop]
  }
}

export default class ScheduleBlock {

  constructor(block, container, {newKey = false} = {}) {
    if(block instanceof ScheduleBlock) {
      return this.copyOf(block, {newKey})
    }
    let {start, end, color, announce} = block
    let {scheduleType: type, intervalDuration, intervalBasis} = container
    let span;
    this.valid = true;
    try {
      if(type === "interval") {
        span = new IntervalPeriod(start, end, type, {days: intervalDuration, basis: intervalBasis})
      } else if(type === "endless") {
        span = new ExactPeriod(start, end)
      } else {
        span = new IntervalPeriod(start, end, type)
      }
    } catch (err) {
      console.error(err)
      this.valid = false;
    }
    this.scheduleType = type
    this.intervalDuration = intervalDuration
    this.intervalBasis = intervalBasis
    this.type = 'block'
    this.span = span
    this.loop = (block.loop && (block.loop === '1' || block.loop === 1)) ? true : false
    this.lock = (block.lock && (block.lock === '1' || block.lock === 1)) ? true : false
    this.announce = announce
    this.body = {
      label: block.block,
      color: color,
      contains: []
    }
    this.key = uuidv4()
    return new Proxy(this, scheduleBlockProxy)
  }

  copyOf(block, {newKey = false} = {}) {
    if(!(block instanceof ScheduleBlock)) {
      throw new Error(`Trying to make a ScheduleBlock into a copy of something that isn't a ScheduleBlock: ${block}. ScheduleBlock.copyOf only accepts other ScheduleBlocks.`);
    }
    // Copy from block
    Object.keys(block).forEach((key) => {
      if(key === "span") {
        if(block.scheduleType === "endless" || block[key] instanceof ExactPeriod) {
          this[key] = new ExactPeriod(block[key].start, block[key].end)
        } else {
          this[key] = new IntervalPeriod(block[key].start, block[key].end)
        }
      } else if(key === "body") {
        this[key] = {
          label: block[key].label,
          contains: block[key].contains.map((item) => new ScheduleItem(item, this, {newKey})),
          color: block[key].color
        }
      } else if(block[key] instanceof Array) {
        this[key] = [...block[key]]
      } else if(moment.isMoment(block[key])) {
        this[key] = moment(block[key])
      } else if(typeof block[key] === "object" && block[key] !== null) {
        this[key] = {...block[key]}
      } else {
        this[key] = block[key]
      }
    })
    if(newKey) {
      this.key = uuidv4()
    }
    return new Proxy(this, scheduleBlockProxy)
  }

  clone({newKey = false} = {}) {
    return new ScheduleBlock(this, null, {newKey})
  }

  * iterator() {
    let index = 0
    while(index < this.body.contains.length) {
      yield this.body.contains[index++]
    }
  }

  [Symbol.iterator]() {
    return this.iterator()
  }

  sortByTime() {
    this.body.contains = this.body.contains.sort((a, b) => a.span.start.valueOf() - b.span.start.valueOf())
    return this
  }

  /**
   * Locates a child by its key and returns it.
   * @param {string} key uuid key of the child to be returned
   * @returns The child ScheduleItem with key, or null if a child with a matching key
   *  is not found
   */
  findByKey(key) {
    for(let child of this) {
      if(child.key === key) {
        return child
      }
    }
    return null
  }

  nextSiblingOf(key) {
    let currentIndex = this.body.contains.findIndex((item) => item.key === key)
    if(currentIndex === -1 || currentIndex === (this.body.contains.length - 1)) {
      return null
    }
    return this.body.contains[currentIndex + 1]
  }

  previousSiblingOf(key) {
    let currentIndex = this.body.contains.findIndex((item) => item.key === key)
    if(currentIndex < 1) {
      return null
    }
    return this.body.contains[currentIndex - 1]
  }

  addItems(items=[]) {
    if(!items.length) {
      return
    }
    items = items.map((item) => {
      if(!(item instanceof ScheduleItem)) {
        return new ScheduleItem(item, this)
      }
      return item
    })
    this.body.contains = this.body.contains.concat(items)
    return this.sortByTime()
  }

  removeItem(key) {
    this.body.contains = this.body.contains.filter((item) => {
      if(item.key === key && !item.lock) {
        item.parent = null
        return false
      }
      return true
    })
    return this
  }

  /**
   * Gets a string that can be used to uniquely identify a block based on the name, duration, contents, and when they occur within the block,
   *  without consideration for the block's start time (for comparing multiple instances of a given block)
   */
  getSignature() {
    let signature = `${this.body.label}: ${millisToHourString(this.span.duration())}`
    for(let child of this.body.contains) {
      signature += `${"\n" + child.body.label}: ${millisToHourString(child.span.start.diff(this.span.start))} - ${millisToHourString(child.span.duration())}`
    }
    return signature
  }

  /**
   * Add overlapping non-block items in parent schedule to own contents, and then remove them from parent
   */
  /*
  munch() {
    let toAdd = []
    let toRemove = []
    for(let item of this.parent) {
      if(item.type === "block" || item.lock) {
        continue;
      }
      if(this.span.overlaps(item.span)) {
        toRemove.push(item.key)
        toAdd.push(item)
      }
    }
    let tempSchedule = [...this.parent.schedule]
    this.addItems(toAdd)
    tempSchedule = tempSchedule.filter((item) => {
      return !(toRemove.includes(item.key))
    })
    this.parent.schedule = tempSchedule
    return this
  }
  */

  /**
   * Move schedule block and all of its children to a different time
   * @param {object} time The new start time of this ScheduleBlock; can be an IntervalTime, or anything
   *  that can be passed to the constructor of IntervalTime
   * @param {object} opts Optional arguements
   * @param {boolean} opts.endAtTime Defaults to false. If true, the item will end at the given time instead of starting at it
   *  moving multiple children at once)
   * @returns this
   */
  moveToTime(time, opts={}) {
    let {endAtTime=false} = opts
    this.body.contains = this.body.contains.map((child) => {
      let timeDifferential = child.span.start.diff(this.span.start)
      if(endAtTime) {
        child.span = child.span.moveToEnd(time).add(timeDifferential)
      } else {
        child.span = child.span.moveToStart(time).add(timeDifferential)
      }
      return child
    })
    if(endAtTime) {
      this.span = this.span.moveToEnd(time)
    } else {
      this.span = this.span.moveToStart(time)
    }
    return this
  }

  /**
   * Expands a schedule block
   * @param {number/object} expandBy The number of milliseconds to expand the schedule block, or a moment.js duration object indicating how much to expand the block by
   * @param {object} opts Options for schedule block expansion
   * @param {boolean} opts.backwards If true, expand start of block into the past, otherwise expand end of block into the future. Defaults to false.
   * @param {boolean} opts.munch If true, block will take in any items it collides with after expanding as children. If set to false, it will push any items it collides
   * with so that they are no longer colliding. Defaults to false.
   */
  /*
  expand(expandBy, opts={}) {
    let {backwards=false, munch=false} = opts
    if(backwards) {
      this.span = new IntervalPeriod(this.span.start.subtract(expandBy), this.span.end)
    } else {
      this.span = new IntervalPeriod(this.span.start, this.span.end.add(expandBy))
    }
    if(munch) {
      this.munch()
    }
    this.shove(0, backwards)
    return this
  }

  /**
   * Changes this ScheduleBlock's start and end so that it starts at the beginning of its first child and ends at the end of its last child
   * @param {object} opts Optional arguements
   * @param {boolean} opts.dontContract If set to true, then the block will only expand to cover children that are outside of its start and end.
   *  If the first child begins after this block's beginning, then the beginning of the block will not change. If the last child ends before this
   *  block's end, then the end of the block will not change. Defaults to false.
   * @returns this
   */
  /*
  fitToContents(opts={}) {
    let {dontContract=false} = opts
    let firstChild = this.body.contains[0]
    let lastChild = this.body.contains[this.body.contains.length - 1]
    if(!firstChild || !lastChild) {
      return
    }
    let startMove = this.span.start.diff(firstChild.span.start)
    if(dontContract && startMove < 0) {
      startMove = 0
    }
    let endMove = lastChild.span.end.diff(this.span.end)
    if(dontContract && endMove < 0) {
      endMove = 0
    }
    if(startMove) {
      this.expand(startMove, {backwards: true})
    }
    if(endMove) {
      this.expand(endMove, {backwards: false})
    }
    return this
  }
  */

  /*
  shoveItem(key, time, backwards=false) {
    // Check to make sure item exists within schedule
    let target = this.findByKey(key)
    if(!target || (time > 0 && target.lock)) {
      return this
    }
    // 1. Add item to a toPush array
    let toPush = [target]
    // 2. Check for any collisions with items that are not in toPush between the start (or end if backwards) of the first item in toPush and
    //  the end (or start if backwards) of an item block of duration equal to the sum of the durations of all items in toPush at time
    //  milliseconds in a direction indicated by backwards.
    // 3. If a colliding item is detected, add it to toPush and repeat from 3.
    if(backwards) {
      let previous = target.previousSibling()
      let end = target.span.end
      while(previous) {
        if(previous.lock) {
          let err = new Error('Encountered a locked item while pushing, so nothing was pushed.')
          err.type = 'ERR_LOCKED'
          throw err
        }
        let duration = toPush.reduce((accumulator, item) => {
          return accumulator + item.span.duration()
        }, 0)
        let start = end.subtract(duration).subtract(time)
        if(new IntervalPeriod(start, end).contains(previous.span.end, {exclusiveStart: true})) {
          toPush.push(previous)
          previous = previous.previousSibling()
        } else {
          break
        }
      }
    } else {
      let next = target.nextSibling()
      let start = target.span.start
      while(next) {
        if(next.lock) {
          let err = new Error('Encountered a locked item while pushing, so nothing was pushed.')
          err.type = 'ERR_LOCKED'
          throw err
        }
        let duration = toPush.reduce((accumulator, item) => {
          return accumulator + item.span.duration()
        }, 0)
        let end = start.add(duration).add(time)
        if(new IntervalPeriod(start, end).contains(next.span.start, {exclusiveEnd: true})) {
          toPush.push(next)
          next = next.nextSibling()
        } else {
          break
        }
      }
    }
    // 4. Once no collisions are detected, set currentTime variable to the start of the block at its new position.
    let currentTime = backwards ?
      target.span.end.subtract(time) :
      target.span.start.add(time)
    // 5. Iterate through toPush and move each item:
    toPush.forEach((item) => {
      currentTime = backwards ? item.span.start : item.span.end
    })
    return this;
  }
  */

  updateEvents(eventsList) {
    let toReturn = this.clone()
    toReturn.body.contains = toReturn.body.contains.map((item) => {
      if (item.type === 'event') {
        let match = eventsList[item.guid]
        if(match) {
          let end = item.span.start.add(Math.floor(match.duration.asMilliseconds()))
          let newItem = item.clone()
          newItem.body.label = match.name
          if(this.scheduleType === "endless") {
            newItem.span = new ExactPeriod(item.span.start, end)
          } else {
            newItem.span = new IntervalPeriod(item.span.start, end, toReturn.span.start.type, undefined, {zeroOk: true})
          }
          newItem.originalItem = `/mnt/main/Events/Play/${match.name}`
          newItem["error status"] = match.assigned ? "" : "ENOENT"
          return newItem
        } else {
          return null
        }
      }
      return item
    }).filter((item) => item !== null)
    return toReturn
  }

  /**
   * Serializes child items into a json object for use in saving to a schedule file
   * @returns {Array} An array of child item objects
   */
  serializeChildrenToObject() {
    let toReturn = []
    for(let child of this.body.contains) {
      let objectChild = child.serializeToObject()
      if(objectChild.block !== this.body.label) {
        objectChild.block = this.body.label
      }
      toReturn.push(objectChild)
    }
    return toReturn
  }

  /**
   * Serializes the block data to a json object for use in saving to a schedule file
   * @returns {Object} The block's data in object form
   */
  serializeToObject() {
    let start, end
    if(moment.isMoment(this.span.start)) {
      start = serializeEndlessDate(this.span.start)
    } else {
      start = this.span.start.toScheduleString()
    }
    if(moment.isMoment(this.span.end)) {
      end = serializeEndlessDate(this.span.end)
    } else {
      let next = this.span.start.diff(this.span.end) > 0 ?
        " next" :
        ""
      end = this.span.end.toScheduleString() + next
    }
    return {
      block: this.body.label,
      start,
      end,
      color: this.body.color,
      announce: this.announce
    }
  }

}
