import React, {PureComponent} from 'react'
import {Form, Input} from 'semantic-ui-react'

export default class PasswordPrompt extends PureComponent {

  inputRef = React.createRef()

  componentDidMount() {
    if(this.inputRef.current) {
      this.inputRef.current.focus()
    }
  }

  render() {
    let {value, changeValue} = this.props
    return (
      <Form>
        <Form.Field>
          <Input value={value}
            type="password"
            ref={this.inputRef}
            onChange={(e, data) => {changeValue(data.value)}}/>
        </Form.Field>
      </Form>
    )
  }

}
