import { createSelectorCreator, defaultMemoize } from 'reselect'
import { getIn } from 'helpers/general_helpers'
import isEqual from 'lodash.isequal'

// We're doing most of the work here, yes, but that is so that we get more cache hits from the selector
const getChannels = (state) => {
  return Object.entries(state.services.channel).map(([key, chan]) => {
    let {file, assigned, fileType, assignedType} = getAssignedItem(getQueue(chan), getRegions(chan))
    return {
      key,
      name: getName({key, ...chan}),
      onAir: getRunning(chan),
      file,
      assigned,
      fileType,
      assignedType
    }
  })
}

const createChannelPlayingSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
)

export default createChannelPlayingSelector([getChannels], (channels) => {

  let toReturn = {}
  channels.forEach((channel) => {
    let {key, ...chan} = channel
    toReturn[key] = chan
  })
  return toReturn

})

// queue
const getQueue = (state) => (getIn(state, ['settings', 'queue']))
// regions
const getRegions = (state) => (getIn(state, ['settings', 'regions']))
// name
const getName = (state) => {
  return getIn(state, ['settings', 'name']) || state.key
}
// running
const getRunning = (state) => (getIn(state, ['settings', 'running']))

const getAssignedItem = (queueInfo, regions) => {
  let file = ''
  let assigned = ''
  let fileType = ''
  let assignedType = ''
  if(!regions) {
    return {file, assigned, fileType, assignedType}
  }
  if(queueInfo) {
    let queue = queueInfo.queue.filter((item) => !!item)
    if(queue.length > 0) {
      file = queue[0]
    }
    assigned = queueInfo.assigned
  }
  let main = getIn(regions, ['main'])
  // Before, main region could vary, but we are moving towards having only the "item" region be main at all times.
  if(!main) {
    main = {kind: "item", part: "", index: ""}
  } else if(typeof main === "string") {
    main = {kind: main, part: "", index: ""}
  }
  let {kind, part, index} = main
  let region = getIn(regions, [kind])
  if(kind === 'global') {
    region = region[index]
  } else if (kind === 'alert') {
    region = region[part]
  }
  let assignedPath = getIn(region, ['media', 'path'])
  assignedType = getIn(region, ['media', 'mimeType'])
  let filePath = getIn(region, ['media', 'leafPath'])
  fileType = getIn(region, ['media', 'leafMimeType'])
  if(assignedPath) {
    assigned = assignedPath
    if(assigned instanceof Array) {
      assigned = assigned.join("/")
    }
  }
  if(filePath && file === '') {
    file = filePath
    if(file instanceof Array) {
      file = file.join("/")
    }
  }
  return {file, assigned, fileType, assignedType}
}
