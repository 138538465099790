import applicationList from 'applicationList'
import nodepath from "path"
import history from 'history.js'
import {bakeCookie, TAKE_COOKIE} from 'redux/cookies'
import {messages} from 'redux/messages'
import {create as timesync_create} from 'timesync'
import {fetchFromServer, connectToServerSocket} from 'helpers/net_helpers'
import {checkHasAccessToApp} from 'helpers/authentication_helpers'

import ChannelPlayingSelector from 'selectors/ChannelPlayingSelector'

import {createTab, changeTab} from 'redux/applications/schedule'
import {getIn, encodeURIFilepath} from 'helpers/general_helpers'
import {fileTypeInfo} from 'helpers/library_helpers'

import {AUTHENTICATE_USER} from 'redux/server'

const baseMenuList = [
  {
    name: 'Home',
    icon: 'home',
    directLink: '/'
  },
  'Upload',
  'Settings',
  'Schedule',
  'Library',
  'Playlist',
  'SimplePlaylist',
  'MetadataTemplate',
  'ScrollingText',
  'RSSItemEditor',
  'GlobalMedia',
  'GlobalMusic',
  'Reporting',
  //'EmbeddedSchedule',
  'CloudServices',
  //'Preferences',
  'About',
  'UserList'//,
  //'Support'
]

export const OPEN_APPLICATION = Symbol('open application')
export const OPEN_PINNED_APPLICATIONS = Symbol('open pinned applications')
export const PIN_APPLICATION = Symbol('pin application')
export const CLOSE_APPLICATION = Symbol('close application')
export const SET_ACTIVE_APPLICATION = Symbol('set active application')
export const TIME_OFFSET_CHANGED = Symbol('time offset changed')
export const TIMESYNC_SOCKET_CONNECTION = Symbol('timesync socket connection')
export const SET_DID_REQUEST_TIMECHANGE = Symbol('set did request time change')
export const SET_TIMESYNC_RECEIVER = Symbol('set timesync receiver')
export const SET_TIMEZONE = Symbol('set timezone')
export const GET_TIMEZONE_LIST = Symbol('get timezone list')

export const SET_CHANGE_TIME_DATA = Symbol('set change time data')
export const CLEAR_CHANGE_TIME_DATA = Symbol('clear change time data')

export const getTimezoneListFromServer = () => {
  return async (dispatch, getState) => {
    let response = await fetchFromServer('/v2/other/timezones')
    if(response.ok) {
      let timezones = await response.json()
      dispatch({type: GET_TIMEZONE_LIST, payload: timezones})
    } else {
      console.error("Could not fetch timezones")
    }
  }
}

export const startTimeSync = () => {
  return async (dispatch, getState) => {
    if(getState().menu.timeSync !== null) {
      return
    }
    var socket = getState().menu.socketConnection
    if(socket === null) {
      socket = connectToServerSocket('')
      if(!socket) {
        return
      }
      dispatch(setSocketConnection(socket))
    }
    var ts = timesync_create({
      server: socket,
      // Needs to be long, as the frontend lags during a time sync
      interval: 600000
    })

    ts.on("change", (offset) => {
      dispatch({type: TIME_OFFSET_CHANGED, payload: offset})
    })

    ts.send = function (socket, data, timeout) {
      return new Promise((resolve, reject) => {
        if(!socket) {
          console.warn("Attempted to perform time sync, but there is no socket connection to the server!")
          reject()
        }
        var ts_timeout = setTimeout(reject, timeout)
        socket.emit("timesync", data, () => {
          clearTimeout(ts_timeout)
          resolve()
        })
      })
    }

    socket.on("timesync", (data) => {
      ts.receive(null, data)
    })

    socket.on("forcetimesync", () => {
      ts.sync()
      if(getState().menu.didRequestTimeChange) {
        dispatch(messages.alert("The server time has successfully changed, and the locally displayed time is being resynced.", {level: 'success'}))
        dispatch(setDidRequestTimeChange(false))
      } else {
        dispatch(messages.alert("The server time has changed! The locally displayed time is being resynced.", {level: 'warning'}))
      }
    })

    socket.on("settime_error", (err) => {
      dispatch(messages.alert(`Error setting the time: #{err}`, {level: 'error'}))
      dispatch(setDidRequestTimeChange(false))
    })

    socket.on("timezone", (data) => {
      if(data.timezone && data.timezone !== getState().menu.timezone) {
        dispatch({type: SET_TIMEZONE, payload: data.timezone})
      }
    })

    dispatch(setTimesyncReceiver(ts))
  }
}

export const changeSystemTime = () => {
  return async (dispatch, getState) => {
    let {socketConnection} = getState().menu
    let {time, timezone} = getState().menu.changeTimeFormData
    let data = {}
    if(time) {
      //time.utc()
      data.time = time.toISOString()
    }
    if(timezone) {
      data.zone = timezone
    }
    if(!data.time && !data.zone) {
      return
    }
    socketConnection.emit("settime", data)
    dispatch(setDidRequestTimeChange(true))
    dispatch({type: CLEAR_CHANGE_TIME_DATA})
    dispatch(messages.alert("Server time change request sent."))
  }
}

const setSocketConnection = (socket) => ({
  type: TIMESYNC_SOCKET_CONNECTION,
  payload: socket
})

const setTimesyncReceiver = (timesync) => ({
  type: SET_TIMESYNC_RECEIVER,
  payload: timesync
})

const setDidRequestTimeChange = (value) => ({
  type: SET_DID_REQUEST_TIMECHANGE,
  payload: value
})

/**
 * Opens a new instance of a given application
 * @param {string} appName The string key of the application within applicationList to be opened
 * @param {string} [extraUrl=""] Additional path/args to add to url being opened (such as file to open, etc.). Will be normalized and escaped.
 * @param {string} [before=""] Optional string args to add before extraUrl. Will NOT be escaped.
 * @param {string} [after=""] Optional string args to add after extraUrl. Will not be escaped.
 * @returns adds a new instance of application appName to openApplications
 */
export const openApplication = (appName, extraUrl='', before="", after="") => {
  return (dispatch, getState) => {
    console.log(`openApplication(${appName}, ${extraUrl}, ${before}, ${after}) Running...`)
    if(!checkHasAccessToApp(getState().server.appAccess, appName)) {
      console.log(`openApplication(${appName}, ${extraUrl}, ${before}, ${after}) Failed app access check!`)
      return
    }

    console.log(`openApplication(${appName}, ${extraUrl}, ${before}, ${after}) Access check completed!`)

    if(Object.getOwnPropertyNames(applicationList).includes(appName)) {
      console.log(`openApplication(${appName}, ${extraUrl}, ${before}, ${after}) checking for app completed!`)
      if(appName === "Schedule" &&
        getState().router.location.pathname.startsWith("/schedule") &&
        !extraUrl) {
        dispatch(openScheduleApplication())
      }
      dispatch({
        type: OPEN_APPLICATION,
        payload: appName
      })
      let toPush = applicationList[appName].link
      if(extraUrl && typeof extraUrl === 'string') {
        extraUrl = encodeURIFilepath(nodepath.normalize(extraUrl))
      }
      toPush = toPush + `${before}${extraUrl}${after}`
      history.push(toPush)
    } else {
      console.log(`openApplication(${appName}, ${extraUrl}, ${before}, ${after}) checking for app failed!`)
    }
  }
}

export const openScheduleApplication = () => {
  return (dispatch, getState) => {
    let {tabs, tabData} = getState().schedule
    let {active_channel: activeChannel} = getState().channel
    let channelItems = ChannelPlayingSelector(getState().settings)
    let path = []
    // Get schedule of currently selected channel
//  let itemData = getIn(channelItems, [activeChannel, 'assignedData'])
    let item = getIn(channelItems, [activeChannel, 'assigned'])
    let itemType = getIn(channelItems, [activeChannel, 'assignedType'])
    if(item &&
      fileTypeInfo(itemType).type === 'schedule') {
      path = item
      if(!(path instanceof Array)) {
        path = path.split("/")
      }
      // Avoid double slash in schedule url, which causes issues with reloading
      if(path[0] === "") {
        path = path.slice(1)
      }
    }
    if(path.length > 0) {
      let tabId = Object.entries(tabData).find(([key, value]) => {
        let fullpath = [...value.props.present.filepath, value.props.present.filename]
        return fullpath.join('/') === path.join('/');
      })
      if(tabId instanceof Array) {
        tabId = tabId[0]
      }
      let tabInd = tabs.findIndex((tab) => tab === tabId)
      if(tabInd > -1) {
        dispatch(changeTab(tabInd));
      } else {
        dispatch(createTab(path));
      }
    }
  }
}

/**
 * Opens all pinned applications
 */
export const openPinnedApplications = () => {
  return (dispatch, getState) => {
    let pinnedApplications = getState().menu.pinnedApplications.filter((app) =>
      checkHasAccessToApp(getState().server.appAccess, app)
    )
    dispatch({
      type: OPEN_PINNED_APPLICATIONS,
      payload: pinnedApplications
    })
  }
}

/**
 * Pins or Unpins an application
 * @param {string} appName The string key of the application within applicationList to be pinned/unpinned
 */
export const pinApplication = (appName) => {
  return (dispatch, getState) => {
    let pinned = [].concat(getState().menu.pinnedApplications)
    let pIndex = pinned.findIndex(item => item === appName)
    if(pIndex === -1) {
      pinned.push(appName)
    } else {
      pinned.splice(pIndex, 1)
    }
    dispatch(bakeCookie('pinnedApplications', pinned))
    dispatch({
      type: PIN_APPLICATION,
      payload: pinned
    })
  }
}

/**
 * Removes the application's icon from the top menu
 * @param {number} index The index of the application within the menu
 */
export const closeApplication = (index) => {
  return (dispatch, getState) => {
    let activeApp = getState().menu.activeApplication
    if(activeApp === index) {
      if(activeApp < (getState().menu.openApplications.length - 1)) {
        history.push(applicationList[getState().menu.openApplications[activeApp + 1]].link)
      } else if(activeApp > 0) {
        history.push(applicationList[getState().menu.openApplications[activeApp - 1]].link)
      } else {
        history.push('/')
      }
    }
    if((activeApp === index && index >= (getState().menu.openApplications.length - 1)) ||
      activeApp > index) {
      activeApp = activeApp - 1
    }
    dispatch({
      type: CLOSE_APPLICATION,
      payload: {
        index,
        activeApp
      }
    })
  }
}

/**
 * Closes an application by application name, if it is open
 * @param {string} applicationName The name of the application to close
 */
export const closeApplicationName = (applicationName) => {
  return (dispatch, getState) => {
    let index = getState().menu.openApplications.findIndex((app) => {
      return app === applicationName
    })
    if(index === -1) {
      return
    } else {
      dispatch(closeApplication(index))
    }
  }
}

export const setActiveApplication = (index) => ({
  type: SET_ACTIVE_APPLICATION,
  payload: index
})

export const setChangeTimeData = (name, value) => ({
  type: SET_CHANGE_TIME_DATA,
  payload: {
    name,
    value
  }
})

const initialState = {

  menuList: baseMenuList,           // Array of options to be displayed in the dropdown menu of the menu bar
  socketConnection: null, // Current connection to server socket, if one exists
  timeSync: null, // Timesync server
  timeOffset: 0,  // Offset in ms between server time and client time
  timezone: null, // Current timezone of server
  timezoneList: [],  // List of available timezones from the server
  changeTimeFormData: {
    time: null,         // The new time to change to, if any
    timezone: null      // The new timezone to change to, if any
  }, // Contains data for the change time form.
  openApplications: [], // Array of open applications
  pinnedApplications: ['Upload', 'Library'], // Applications that are pinned to the menu
  activeApplication: -1, // Currently active application
  didRequestTimeChange: false // If true, the client sent a time change request to the server, and a forced resync of server time is expected to occur.
}

export default (state=initialState, action) => {

  let {type, payload} = action

  switch(type) {
    case AUTHENTICATE_USER: {
      let {access} = payload
      let menuList = baseMenuList.filter((item) => (
        typeof item === 'object' ||
        checkHasAccessToApp(access, item)
      ))
      return {
        ...state,
        menuList
      }
    }
    case GET_TIMEZONE_LIST:
      return {
        ...state,
        timezoneList: payload
      }
    case TIMESYNC_SOCKET_CONNECTION:
      return {
        ...state,
        socketConnection: payload
      }
    case SET_TIMESYNC_RECEIVER:
      return {
        ...state,
        timeSync: payload
      }
    case SET_TIMEZONE:
      return {
        ...state,
        timezone: payload
      }
    case TIME_OFFSET_CHANGED:
      return {
        ...state,
        timeOffset: payload
      }
    case OPEN_APPLICATION:
      let openApplications = state.openApplications
      let activeApplication = openApplications.findIndex(item => item === payload)
      if(activeApplication === -1) {
        activeApplication = openApplications.push(payload) - 1
      }
      return {
        ...state,
        openApplications,
        activeApplication
      }
    case OPEN_PINNED_APPLICATIONS:
      return {
        ...state,
        openApplications: [].concat(payload)
      }
    case PIN_APPLICATION:
      return {
        ...state,
        pinnedApplications: payload
      }
    case TAKE_COOKIE:
      return {
        ...state,
        pinnedApplications: payload.pinnedApplications ? payload.pinnedApplications : state.pinnedApplications
      }
    case CLOSE_APPLICATION:
      let {index, activeApp} = payload
      if(activeApp > payload || activeApp === state.openApplications.length - 1) {
        activeApp = activeApp - 1
      }
      return {
        ...state,
        openApplications: state.openApplications.filter((app, ind) => ind !== index),
        activeApplication: activeApp
      }
    case SET_ACTIVE_APPLICATION:
      return {
        ...state,
        activeApplication: payload
      }
    case SET_CHANGE_TIME_DATA:
      return {
        ...state,
        changeTimeFormData: {
          ...state.changeTimeFormData,
          [payload.name]: payload.value
        }
      }
    case CLEAR_CHANGE_TIME_DATA:
      return {
        ...state,
        changeTimeFormData: {
          time: null,
          timezone: null
        }
      }
    case SET_DID_REQUEST_TIMECHANGE:
      return {
        ...state,
        didRequestTimeChange: payload
      }
    default:
      return state
  }

}
