import React, {PureComponent} from 'react'
import DurationInput from 'components/TimeInput/DurationInput'
import {Form} from 'semantic-ui-react'

export default class DurationPrompt extends PureComponent {

  durationInput = React.createRef()

  componentDidMount() {
    if(this.durationInput.current) {
      this.durationInput.current.focus()
    }
  }

  handleChange = (value) => {
    let {changeValue} = this.props
    changeValue(value)
  }

  handleBlur = (value) => {
    let {changeValue} = this.props
    changeValue(value)
  }

  render() {
    let {options} = this.props

    return (
      <Form>
        <Form.Field>
          <DurationInput showMilliseconds={options.subseconds}
            defaultValue={options.defaultValue}
            onChange={this.handleChange}
            ref={this.durationInput}/>
        </Form.Field>
      </Form>
    )
  }

}
