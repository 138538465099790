import React, {PureComponent} from 'react'
import { DragLayer, DropTarget } from 'react-dnd'
import { pixelsToMilliseconds, magnetizeClip, isColliding } from 'helpers/playlist_helpers'

import {CLIP_DRAG} from './DragConstant'

import Clip from './Clip'
import ClipDragPreview from './ClipDragPreview'

class RegionTimeline extends PureComponent {

  componentDidUpdate() {
    let {dragItem,
      cursorPos,
      setDragUpdateRequest} = this.props
    if(setDragUpdateRequest &&
      dragItem &&
      cursorPos) {
      setDragUpdateRequest(cursorPos)
    }
  }

  handleAddClip = async () => {
    let {libraryPreview,
      libraryOffset,
      frame,
      zoomLevel,
      viewStart,
      previewTime,
      magnetic,
      magnetTimes,
      index,
      isDragging,
      addFrame,
      addFrameClip,
      messages} = this.props
    if(!isDragging && libraryPreview) {
      let adjustedStart = Math.max(pixelsToMilliseconds(libraryOffset, zoomLevel) + viewStart, 0)
      let itemWidth = libraryPreview.end - libraryPreview.start
      if(!itemWidth) {
        if(frame && isColliding(frame.timeline, adjustedStart, itemWidth)) {
          return
        }
        let duration = await messages.promptAsync("The duration of this item is not known. How long should the item be?", {type: 'duration', subseconds: true});
        if(duration === null) {
          return
        }
        itemWidth = duration.asMilliseconds();
      }
      if(frame) {
        if(isColliding(frame.timeline, adjustedStart, itemWidth)) {
          return
        }
      }
      if(magnetic) {
        let magTimes = [0, ...magnetTimes, previewTime]
        let magOffset = magnetizeClip(adjustedStart, itemWidth, zoomLevel, magTimes)
        adjustedStart += magOffset
      }
      let newClip = {
        ...libraryPreview,
        start: adjustedStart,
        end: adjustedStart + itemWidth
      }
      if(frame) {
        addFrameClip(index, newClip)
      } else {
        addFrame({timeline: [newClip]})
      }
    }
  }

  handleSelectRange = () => {
    let {index, startSelectRange} = this.props
    startSelectRange(index)
  }

  render() {
    let {frame,
      viewStart,
      zoomLevel,
      index,
      lineWidth,
      magnetic,
      magnetTimes,
      previewTime,
      connectDropTarget,
      isHovering,
      dragOffset,
      dragItem,
      dragStart,
      selectedTool,
      libraryPreview,
      libraryOffset,
      selectedClips,
      playInterval,
      removeFrame,
      addFrameClip,
      modifyFrameClip,
      removeFrameClip,
      moveFrameClip,
      cutFrameClip,
      setIsDragging,
      selectFrameClip} = this.props
    let clips = []
    let dragPreview = ''
    if(frame) {
      let viewEnd = viewStart + pixelsToMilliseconds(lineWidth, zoomLevel)
      let clipCount = frame.timeline.length
      clips = frame.timeline.map((clip, clip_index) => {
        if(!(clip.start > viewEnd || clip.end < viewStart) ||
          dragItem === clip) {
          // See if the clip touches another clip in the same region for styling purposes
          let startNeighborTime = -1
          let endNeighborTime = -1
          if(clip_index > 0) {
            startNeighborTime = frame.timeline[clip_index - 1].end
          }
          if(clip_index < clipCount - 1) {
            endNeighborTime = frame.timeline[clip_index + 1].start
          }
          return (<Clip clip={clip}
            viewStart={viewStart}
            zoomLevel={zoomLevel}
            parentIndex={index}
            clipCount={clipCount}
            lineWidth={lineWidth}
            index={clip_index}
            magnetic={magnetic}
            magnetTimes={magnetTimes}
            previewTime={previewTime}
            startNeighborTime={startNeighborTime}
            endNeighborTime={endNeighborTime}
            key={clip_index}
            selectedClips={selectedClips}
            playInterval={playInterval}
            selectedTool={selectedTool}
            removeFrame={removeFrame}
            addFrameClip={addFrameClip}
            modifyFrameClip={modifyFrameClip}
            removeFrameClip={removeFrameClip}
            moveFrameClip={moveFrameClip}
            cutFrameClip={cutFrameClip}
            setIsDragging={setIsDragging}
            selectFrameClip={selectFrameClip}/>)
        }
        return null
      }).filter((clipItem) => {
        return clipItem !== null
      })
    }
    let hovering = null
    if(dragOffset && dragItem) {
      hovering = isHovering
    }
    let previewOffset = dragOffset && dragOffset.x ? dragOffset.x : libraryOffset
    let previewItem = dragItem || libraryPreview || null
    let previewStart = dragStart || 0
    if(previewItem) {
      let adjustedStart = Math.max(pixelsToMilliseconds(previewOffset, zoomLevel) + previewItem.start + (viewStart - previewStart), 0)
      let itemWidth = previewItem.end - previewItem.start
      let colliding = false
      if(frame) {
        let otherClips = frame.timeline.filter((clip) => (clip !== previewItem))
        colliding = isColliding(otherClips, adjustedStart, itemWidth)
      }
      if(magnetic) {
        let magTimes = [0, ...magnetTimes, previewTime]
        let magOffset = magnetizeClip(adjustedStart, itemWidth, zoomLevel, magTimes)
        adjustedStart += magOffset
      }
      dragPreview = <ClipDragPreview start={adjustedStart}
        length={itemWidth}
        viewStart={viewStart}
        zoomLevel={zoomLevel}
        colliding={colliding}
        hovering={hovering}/>
    }

    return connectDropTarget(
      <div className='PlaylistRegion Timeline noselect' onClick={this.handleAddClip} onMouseDown={this.handleSelectRange}>
        {clips}
        {dragPreview}
      </div>
    )

  }

}

let drop = (props, monitor, component) => {
  let {frame,
    viewStart,
    zoomLevel,
    previewTime,
    magnetic,
    magnetTimes,
    index} = props
  let item = monitor.getItem().clip
  let initialStart = monitor.getItem().startTime
  let timeAdjustment = (monitor.getDifferenceFromInitialOffset().x / zoomLevel) * 1000
  timeAdjustment = timeAdjustment + (viewStart - initialStart)
  let adjustedStart = Math.max(item.start + timeAdjustment, 0)
  let itemWidth = (item.end - item.start)
  if(frame) {
    let otherClips = frame.timeline.filter((clip) => (clip !== item))
    if(magnetic) {
      let magTimes = [...magnetTimes, previewTime]
      let magOffset = magnetizeClip(adjustedStart, itemWidth, zoomLevel, magTimes)
      adjustedStart += magOffset
    }
    let colliding = isColliding(otherClips, adjustedStart, itemWidth)
    if(!colliding) {
      return {addTime: adjustedStart, addIndex: index}
    }
  } else {
    return {addTime: adjustedStart}
  }
  return {}
}

let collect = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isHovering: monitor.isOver(),
})

let dragCollect = (monitor) => {
  if(monitor.getItemType() === CLIP_DRAG) {
    let offset = monitor.getDifferenceFromInitialOffset()
    let item = monitor.getItem().clip
    let initialStart = monitor.getItem().startTime
    let clientOffset = monitor.getClientOffset()
    let cursorPos = null
    if(clientOffset) {
      cursorPos = clientOffset.x
    }
    return {
      dragOffset: offset,
      dragItem: item,
      dragStart: initialStart,
      cursorPos: cursorPos
    }
  }
  return {
    dragOffset: null,
    dragItem: null,
    dragStart: null,
    cursorPos: null
  }
}

export default DragLayer(dragCollect)(DropTarget(CLIP_DRAG, {drop}, collect)(RegionTimeline))
