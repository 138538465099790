import React, {PureComponent} from 'react';
import {Modal, Button, Header, Checkbox} from 'semantic-ui-react'
import IntervalTime from 'helpers/IntervalTime/IntervalTime'
import moment from "moment"

import ScheduleReplicateTimeSelector from './ScheduleReplicateTimeSelector'

const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
]

const WEEKDAYS_SHORT = [
  "Su",
  "Mo",
  "Tu",
  "We",
  "Th",
  "Fr",
  "Sa"
]

export default class ScheduleDayReplicateModal extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {selectedDates: [], open: false, keepBlocks: true}
  }

  onOpen = () => {
    this.setState((state) => ({...state, open: true}))
  }

  onClose = () => {
    this.setState((state) => ({...state, open: false, selectedDates: []}))
  }

  toggleDate = (selectedDate) => {
    let addDate = true
    let {type, viewTime, intervalDuration, intervalBasis} = this.props
    let {selectedDates} = this.state
    switch(type) {
      case "yearly":
      case "monthly":
        selectedDate = new IntervalTime(selectedDate, type)
        break;
      case "weekly":
        selectedDate = new IntervalTime({weekday: selectedDate}, type)
        break;
      case "interval":
        selectedDate = new IntervalTime(selectedDate, type, {days: intervalDuration, basis: intervalBasis})
        break;
      case "endless":
        // selectedDate is already a moment, so don't need to convert
        break
      default:
        console.log(selectedDate)
        return
    }
    if(selectedDate instanceof IntervalTime && selectedDate.isOnSameDay(viewTime)) {
      return
    }
    selectedDates = selectedDates.filter((date) => {
      // ew, this is messy...
      if ((type === "interval" && date.isOnSameDay(selectedDate.closestDate(new Date()))) ||
      (type === "endless" && date.isSame(selectedDate, "day")) ||
      (type !== "endless" && date.printDay() === selectedDate.printDay())) {
        addDate = false
        return false
      }
      return true
    })
    if(addDate) {
      selectedDates = [...selectedDates, selectedDate]
    }
    this.setState((state) => ({...state, selectedDates}))
  }

  selectMonthWeekday = (e) => {
    let {viewTime} = this.props
    // Detect when the day of week headers are clicked in the date selector
    if(`${e.target.tagName}.${e.target.className}` === "TH.dow") {
      let selectWeekday = WEEKDAYS_SHORT.findIndex((dow) => dow === e.target.innerHTML)
      let viewDate = document.querySelector("div.scheduleReplicateTimeSelector th.rdtSwitch").innerHTML
      if(viewDate) {
        viewTime = moment(viewDate, "MMMM YYYY")
      }
      this.selectAllInPeriod("month", selectWeekday, viewTime)
    }
  }

  selectAllYear = () => {
    let {type} = this.props
    if(type === "yearly") {
      this.selectAllInPeriod("year")
    }
  }

  selectYearWeekday = (weekday) => {
    this.selectAllInPeriod("year", weekday)
  }

  selectAllInPeriod = (period, weekday=null, overrideDate=null) => {
    let {type, viewTime, intervalDuration, intervalBasis} = this.props
    let {selectedDates} = this.state
    if(period !== "year" && period !== "month") {
      throw new Error(`Period for selectAllInPeriod in ScheduleDayReplicateModal must be exactly either year or month, but got ${period} instead.`)
    }
    if(overrideDate) {
      viewTime = overrideDate
    }
    // First, get the days that we are selecting as interval times
    let periodStart = viewTime.clone().startOf(period)
    let periodEnd = viewTime.clone().endOf(period)
    let toSelect = []
    for(; periodStart.valueOf() < periodEnd.valueOf(); periodStart.add(1, 'd')) {
      // Skip viewTime
      if(periodStart.dayOfYear() === viewTime.dayOfYear()) {
        continue
      }
      if(type === "interval" && toSelect.find((alreadySelected) => alreadySelected.isOnSameDay(periodStart))) {
        continue
      }
      if(weekday === null || periodStart.day() === weekday) {
        if(type === "endless") {
          toSelect.push(periodStart.clone())
        } else {
          toSelect.push(new IntervalTime(periodStart, type, {days: intervalDuration, basis: intervalBasis}))
        }
      }
    }
    // Now we need to figure out if any of them are already selected
    let toSelectFiltered = toSelect.filter((day) => {
      if(type === "interval") {
        // ew, this is messy...
        return !(selectedDates.find((selectedDay) => day.isOnSameDay(selectedDay.closestDate(new Date()))))
      }
      return !(selectedDates.find((selectedDay) => selectedDay.printDay() === day.printDay()))
    })
    // They are ALL selected, so deselect instead
    if(toSelectFiltered.length === 0) {
      selectedDates = selectedDates = selectedDates.filter((selectedDay) => {
        if(type === "interval") {
          // ew, this is messy...
          return !(toSelect.find((day) => day.isOnSameDay(selectedDay.closestDate(new Date()))))
        }
        return !(toSelect.find((day) => day.printDay() === selectedDay.printDay()))
      })
    // Otherwise, add ones that aren't already selected
    } else {
      selectedDates = [...selectedDates, ...toSelectFiltered]
    }
    this.setState((state) => ({...state, selectedDates}))
  }

  submit = () => {
    let {viewTime, type, intervalDuration, intervalBasis, replicateDay} = this.props
    let {selectedDates, keepBlocks} = this.state
    let sourceDay
    if(type === "endless") {
      sourceDay = viewTime.clone()
    } else {
      sourceDay = new IntervalTime(viewTime, type, {days: intervalDuration, basis: intervalBasis})
    }
    replicateDay(sourceDay, selectedDates, keepBlocks)
    this.onClose()
  }

  toggleKeepBlocks = () => {
    this.setState((state) => ({...state, keepBlocks: !state.keepBlocks}))
  }

  render() {
    let {trigger,
      type,
      viewTime,
      intervalBasis,
      intervalDuration} = this.props
    let {open, selectedDates, keepBlocks} = this.state

    let intervalView, intervalHeader;
    if(type === "endless") {
      intervalView = moment(viewTime)
      intervalHeader = intervalView.format("MMMM Do, YYYY")
    } else {
      intervalView = new IntervalTime(viewTime, type, {days: intervalDuration, basis: intervalBasis})
      intervalHeader = intervalView.printDay({noIntervalTime: true})
    }

    let fullButtonGroup = ""
    if(type === "yearly") {
      let fullButtons = []
      fullButtons.push(<Button key="selectAll" onClick={this.selectAllYear}>
        All
      </Button>)
      WEEKDAYS_SHORT.forEach((day, index) => {
        fullButtons.push(<Button key={`${day}Button`} title={`Select every ${WEEKDAYS[index]} of the entire current year`} onClick={() => {this.selectYearWeekday(index)}}>
          {day}
        </Button>)
      })
      fullButtonGroup = (
      <>
        <Header size="tiny">Select All (Based on year of day being replicated from):</Header>
        <Button.Group>
          {fullButtons}
        </Button.Group>
      </>
      )
    }

    return (
      <Modal trigger={trigger} closeIcon onClose={this.onClose} onOpen={this.onOpen} open={open} size={"tiny"}>
        <Modal.Header>
          Replicate Schedule Day
        </Modal.Header>
        <Modal.Content>
          <Header size="small">Replicating From: {intervalHeader}</Header>
          <Checkbox onClick={this.toggleKeepBlocks} checked={keepBlocks} label="Keep Schedule Blocks"/>
          <ScheduleReplicateTimeSelector viewDate={viewTime}
            intervalView={intervalView}
            type={type}
            selectedDates={selectedDates}
            toggleDate={this.toggleDate}
            datelist={selectedDates}
            intervalBasis={intervalBasis}
            intervalDuration={intervalDuration}
            selectWeekday={this.selectMonthWeekday}/>
          {fullButtonGroup}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.onClose}>
            Cancel
          </Button>
          <Button primary onClick={this.submit}>
            Replicate
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

}
