import { createSelector } from 'reselect'
import { getIn } from 'helpers/general_helpers'

const getOutputs = (state) => state.services.output

const isRunning = (rs) => {
  // FIXME: Yechh... clean this up so we don't have to check around like this
  if (rs === "0" || rs === "" || rs === false) return false;
  if (rs === true) return true;
  return parseInt(rs) > 0;
}

export default createSelector([getOutputs], (outputs) => {

  return Object.entries(outputs).map(([key, output]) => {
    return {
      name: key,
      key,
      running: isRunning(getIn(output, ['settings', 'running'])),
      file: getIn(output, ['settings', 'item'])
    }
  }).sort((a, b) => a.key.toUpperCase().localeCompare(b.key.toUpperCase()))

})
