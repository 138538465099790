import React, {Component} from 'react'
import {Dropdown, Icon, Image, Loader} from 'semantic-ui-react'
import {staticAsset} from 'helpers/net_helpers'

import deferRender from 'components/higher_order_components/deferredRenderComponent'

class LibraryItemDropdown extends Component {

  shouldComponentUpdate(nextProps) {
    let {closerToBottom,
      dropdownHeight,
      icon,
      selectedChannel,
      generatingMetadata} = this.props
    return (closerToBottom !== nextProps.closerToBottom ||
      dropdownHeight !== nextProps.dropdownHeight ||
      icon !== nextProps.icon ||
      selectedChannel !== nextProps.selectedChannel ||
      generatingMetadata !== nextProps.generatingMetadata)
  }

  // This can be done a lot better and should definitely be reviewed. I'm rushing to get stuff done so this is what you get!
  createParentMenus(dropdownOptions) {
    // Organize submenus into array
    let parents = []
    for (const item of dropdownOptions) {
      if (item.dropdownParent == null)
        continue

      if (parents[item.dropdownParent] == null) {
        parents[item.dropdownParent] = []
      }

      parents[item.dropdownParent] = parents[item.dropdownParent].concat(<Dropdown.Item {...item} onClick={item.onClick}/>)
    }

    // Create array of parent menu names
    let keys = Object.keys(parents)

    // Create final array to be returned
    let dropdownFinal = []
    let i = 0

    for (const item of Object.values(parents)) {
      dropdownFinal.push(
        <Dropdown.Item onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if(e.currentTarget !== e.target.children[0]) {
            e.target.children[0].click()
          }
        }}>
          <Dropdown fluid text={keys[i]}>
            <Dropdown.Menu>{item}</Dropdown.Menu>
          </Dropdown>
        </Dropdown.Item>
      )
      i = i + 1
    }
    
    for(const item of dropdownOptions) {
      if (item.dropdownParent == null)
        dropdownFinal.push(<Dropdown.Item text={item.text} onClick={item.onClick}/>)
    }
    
    return dropdownFinal
  }

  render() {
    let {options,
      handleSelect,
      handleDeselect,
      //closerToBottom,
      //dropdownHeight,
      generatingMetadata,
      icon} = this.props

    let parentMenus = this.createParentMenus(options)

    //let upward = closerToBottom ? true : false

    /*let menuStyle = {}
    if(dropdownHeight) {
      menuStyle = {maxHeight: `${dropdownHeight}px`}
    }*/

    let iconComponent;

    if(icon) {
      iconComponent = (
        <Icon.Group className='svg-icon-group'>
          <Icon className='svg-icon' size='large'>
            <Image src={staticAsset(`/icons/${icon}.svg`)}/>
          </Icon>
          <Icon corner name="caret down"/>
        </Icon.Group>
      )
    } else if (generatingMetadata) {
      iconComponent = (
        <Icon.Group className='svg-icon-group'>
          <Icon className='svg-icon' size='large'>
            <Loader active inline size="small"/>
          </Icon>
          <Icon corner name="caret down"/>
        </Icon.Group>
      )
    }

    return ( 
      <Dropdown onOpen={handleSelect}
        onClose={handleDeselect}
        icon={iconComponent}>
        <Dropdown.Menu>
          {parentMenus}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default deferRender(LibraryItemDropdown)
