import React, {PureComponent} from 'react'
import {Popup, Menu, Icon} from 'semantic-ui-react'

import SystemStatus from 'components/SystemStatus'

import './MenuSystemStatus.css'

export default class MenuSystemStatus extends PureComponent {

  render() {
    let {systemStatus, hide} = this.props
    if(hide) {
      return ""
    }
    let className = ''
    if(systemStatus && 'raidStatus' in systemStatus) {
      for(let raid of systemStatus.raidStatus) {
        if(!raid.ok) {
          className = 'panic'
          break
        }
      }
    }

    return (
      <Popup wide='very' trigger={
        <Menu.Item className={className}>
          <Icon name='server'/>
          System Status
        </Menu.Item>
        } on="click">
        <SystemStatus {...systemStatus}/>
      </Popup>
    )
  }

}
