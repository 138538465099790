import React, {PureComponent} from 'react'
import {Rect, Group} from 'react-konva'

export default class Meter extends PureComponent {

  bufferIndex = 0;

  componentDidMount() {
    this.animateMeter()
  }

  componentDidUpdate(prevProps) {
    if(this.props.seq !== prevProps.seq) {
      this.bufferIndex = 0
    }
  }

  animateMeter = () => {
    let {buffer, rate, width} = this.props
    if(!buffer) {
      return
    }
    let val = buffer[this.bufferIndex++]
    if(!val && val !== 0) {
      val = 0
    } else {
      val = Math.min(Math.max(((val + 30) / 30) * 100, 0), 100).toFixed(2)
    }
    let newWidth = width * (val / 100)
    let duration = 1001 / 60000
    if(rate) {
      let [a, b] = this.props.rate.split("/").map(val => parseInt(val, 10))
      duration = b / a
    }
    if(this.fill) {
      this.fill.to({
        width: newWidth,
        duration,
        onFinish: this.animateMeter
      })
    }
  }

  render() {
    let {index, width} = this.props

    return (
      <Group x={0} y={index * 8} height={8} width={width}>
        <Rect x={0} y={0} width={width} height={8} fillEnabled={true} stroke={"black"}/>
        <Rect x={0}
          y={1}
          width={0.1}
          height={6}
          fillLinearGradientStartPoint={{x: 0, y: 4}}
          fillLinearGradientEndPoint={{x: width, y: 4}}
          fillLinearGradientColorStops={[0, "blue", 0.33, "green", 0.64, "yellow", 1, "red"]}
          ref={node => this.fill = node}/>
      </Group>
    )
  }

}
