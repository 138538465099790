export function tzToUTC(tzDate) {
  return new Date(Date.UTC(tzDate.getFullYear(), tzDate.getMonth(), tzDate.getDate(), tzDate.getHours(), tzDate.getMinutes(), tzDate.getSeconds(), tzDate.getMilliseconds()))
}

export function UTCToTz(UTCDate) {
  return new Date(UTCDate.getUTCFullYear(), UTCDate.getUTCMonth(), UTCDate.getUTCDate(), UTCDate.getUTCHours(), UTCDate.getUTCMinutes(), UTCDate.getUTCSeconds(), UTCDate.getUTCMilliseconds())
}

export function addPeriodUTC(UTCDate, period) {
  let afterDate = new Date(UTCDate.valueOf())
  switch(period) {
    case "yearly":
      if(UTCDate.getUTCMonth() === 1 && UTCDate.getUTCDate() === 29) {
        afterDate.setUTCFullYear(afterDate.getUTCFullYear() + 4)
      } else {
        afterDate.setUTCFullYear(afterDate.getUTCFullYear() + 1)
      }
      break;
    case "monthly":
      afterDate.setUTCMonth(afterDate.getUTCMonth() + 1)
      while(afterDate.getUTCDate() !== UTCDate.getUTCDate()) {
        afterDate.setUTCDate(UTCDate.getUTCDate())
      }
      break;
    case "weekly":
      afterDate.setUTCDate(afterDate.getUTCDate() + 7)
      break;
    case "daily":
      afterDate.setUTCDate(afterDate.getUTCDate() + 1)
      break;
    default:
      break;
  }
  return afterDate
}

export function subtractPeriodUTC(UTCDate, period) {
  let beforeDate = new Date(UTCDate.valueOf())
  switch(period) {
    case "yearly":
      if(UTCDate.getUTCMonth() === 1 && UTCDate.getUTCDate() === 29) {
        beforeDate.setUTCFullYear(beforeDate.getUTCFullYear() - 4)
      } else {
        beforeDate.setUTCFullYear(beforeDate.getUTCFullYear() - 1)
      }
      break;
    case "monthly":
      beforeDate.setUTCMonth(beforeDate.getUTCMonth() - 1)
      while(beforeDate.getUTCDate() !== UTCDate.getUTCDate()) {
        beforeDate.setUTCMonth(beforeDate.getUTCMonth() - 2)
        beforeDate.setUTCDate(UTCDate.getUTCDate())
      }
      break;
    case "weekly":
      beforeDate.setUTCDate(beforeDate.getUTCDate() - 7)
      break;
    case "daily":
      beforeDate.setUTCDate(beforeDate.getUTCDate() - 1)
      break;
    default:
      break;
  }
  return beforeDate
}
