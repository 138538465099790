import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import {bindActionCreators} from 'redux'
import {getIn} from 'helpers/general_helpers'
import {actions as SettingsActions} from 'redux/settings'
import {loadFileData, setSearchNext} from 'redux/file_list'
import {openApplication} from 'redux/menu'
import {
    audioPolicy,
    defaultPolicy,
    volume,
    currentlyEditedChannel,
    selectedMediaItem,
    setBackgroundMusic,
    closeErrorModal,
    changeSettings,
    libraryUpdate,
    clearChanges
} from 'redux/applications/global_music'
import SettingsSelector from 'selectors/SettingsSelector'
import SchemaSelector from 'selectors/SchemaSelector'
import LocalSettingsSelector from 'selectors/LocalSettingsSelector'
import { Button, Checkbox, Dropdown, Icon, Image, Modal, Input, Label } from 'semantic-ui-react'

import {staticAsset} from 'helpers/net_helpers'

import Library from 'containers/components/LibraryComponent'

import './GlobalMusic.css';

export class GlobalMusicApp extends PureComponent {

    handleAudioEnable = (e, data) => {
      let {changeSettings, active_channel} = this.props
      changeSettings(['channel', active_channel, 'settings', 'regions', 'music', 'audio', 'enable'], data.checked)
    }

    handleAudioPolicyChange = (e, data) => {
      let {changeSettings, active_channel} = this.props
      changeSettings(['channel', active_channel, 'settings', 'regions', 'music', 'policy'], `${data.value}`)
    }

    handleVolumeChange = (e, data) => {
      let {changeSettings, active_channel} = this.props
      changeSettings(['channel', active_channel, 'settings', 'regions', 'music', 'audio', 'volume'], e.currentTarget.valueAsNumber)
    }

    handleSetBackgroundMusicItem = (e, data) => {
      let {changeSettings, active_channel} = this.props
      let {selectedFiles} = this.props.global_music._library
      let selectedMediaItem = selectedFiles[0]
      if(selectedMediaItem instanceof Array) {
        selectedMediaItem = selectedMediaItem.join("/")
      }
      if(selectedMediaItem) {
        if(selectedMediaItem[0] !== "/") {
          selectedMediaItem = `/${selectedMediaItem}`
        }
      }
      changeSettings(['channel', active_channel, 'settings', 'regions', 'music', 'media', 'path'],
        selectedMediaItem ? selectedMediaItem : "")
    }

    handleApplyChanges = () => {
      let {saveServiceSettings,
        clearChanges,
        active_channel} = this.props
      let {changes} = this.props.global_music
      let toSave = getIn(changes, ['channel', active_channel])
      saveServiceSettings('channel', active_channel, toSave)
      clearChanges()
    }

    handleOpenFile = (path, type) => {
      if(type.application) {
        this.props.openApplication(type.application, `/${path.join('/')}`)
      }
    }

    render() {
        let {
            audioPolicy,
            volume,
            errorModalOpen,
        } = this.props.global_music
        let {settings, active_channel, currentSettings} = this.props
        let audioData = {
          settings: SettingsSelector({...settings, type: 'channel', id: active_channel}),
          targetSchema: SchemaSelector({...settings, type: 'channel', id: active_channel})
        }

        let currentlyEnabled = getIn(currentSettings, ['services', 'channel', active_channel, 'settings', 'regions', 'music', 'enable'])

        let currentlyEditedChannel = getIn(audioData, ['settings', 'name'])
        let audioSchema = getIn(audioData, ['targetSchema', 'regions', 'properties', 'music', 'properties'])
        let audioSettings = getIn(audioData, ['settings', 'regions', 'music'])

        let audioEnabled = getIn(audioSettings, ['audio', 'enable'])
        audioEnabled = audioEnabled && audioEnabled !== "0"
        if(audioEnabled === null) {
          audioEnabled = getIn(audioSchema, ['enable', 'default']) || false
        }
        audioPolicy = getIn(audioSettings, ['policy']) || getIn(audioSchema, ['policy', 'default']) || ''

        volume = (getIn(audioSettings, ['audio', 'volume']) !== null && getIn(audioSettings, ['audio', 'volume']) !== undefined) ?
          getIn(audioSettings, ['audio', 'volume']) :
          (getIn(audioSchema, ['audio', 'properties', 'volume', 'default']) || 100)
        if(typeof volume === 'string') {
          volume = parseInt(volume, 10)
        }

        let currentItem = 'none'
        if(audioSettings &&
          getIn(audioSettings, ['media', 'path'])) {
          let assignedItem = getIn(audioSettings, ['media', 'path'])
          if(assignedItem instanceof Array) {
            assignedItem = assignedItem.join("/")
          }
          if(assignedItem) {
            currentItem = assignedItem
          }
        }

        let activeStatus = ''
        let currentlyPlaying = ''
        if(currentlyEnabled) {
          activeStatus = 'Audio is active.'
          if(getIn(audioSettings, ['media', 'leafPath'])) {
            let playingItem = getIn(audioSettings, ['media', 'leafPath']).join('/')
            if(playingItem) {
              currentlyPlaying = (<span><b>Playing:</b> {playingItem}</span>)
            }
          }
        }

        const defaultPolicyOptions = [
            { text: 'Always', value: 'on',},
            { text: 'Never', value: 'off',},
            { text: 'If No Audio', value: 'if no audio',},
        ];

        const audioVolumeOptions = [];
        for (let i = 1; i <= 1; i++) {
            let newNumber = i;
            audioVolumeOptions.push({ text: `${newNumber}%`, value: newNumber, });
        }

        return (
            <div className="globalMusicContent">
                <div className="globalMusicContent__title noselect">
                    <h2>
                      <Icon style={{ marginRight: '10px', marginLeft: '10px'}} className='svg-icon'>
                        <Image src={staticAsset("/icons/globalmusic_black.svg")}/>
                      </Icon>
                      <span> {currentlyEditedChannel}</span>
                    </h2>
                </div>
                <div className="globalMusicContent__buttons noselect">
                    Enabled
                    <Checkbox checked={audioEnabled}
                      style={{ marginRight: '10px', marginLeft: '10px', }}
                      toggle
                      onChange={this.handleAudioEnable}/>
                    Default Policy
                    <Dropdown
                        onChange= { this.handleAudioPolicyChange }
                        style={{ marginRight: '10px', marginLeft: '10px', }}
                        inline
                        value={audioPolicy}
                        options={defaultPolicyOptions} />
                    Audio Volume
                    <Input
                        type="range"
                        min="0"
                        max="100"
                        value={volume}
                        onChange={this.handleVolumeChange}
                        className="labeled">
                        <input style={{ border: '0px', flex: 'initial', margin: 'auto' }}/>
                        <Label basic style={{ marginLeft: '-1px', border: '0px', paddingLeft: '0' }}>{volume}%</Label>
                    </Input>
                    <Button content='Apply' icon='save' onClick={this.handleApplyChanges}/>
                </div>
                <div className="globalMusicContent__details">
                    <b>Background item:</b> { currentItem } <br />
                    <b>{activeStatus}</b> {currentlyPlaying}
                </div>
                <Library update={this.props.libraryUpdate} overridePath={this.props.global_music._library.navpath}/>

                <div className="globalMusicContent__footer noselect">
                    <button className="ui button" onClick={ this.handleSetBackgroundMusicItem }>Select item as background music</button>
                    <button className="ui button">Refresh</button>
                </div>

                <Modal size={'mini'} open={errorModalOpen} onClose={this.props.closeErrorModal}>
                    <Modal.Header><Icon name="attention"/> Attention</Modal.Header>
                    <Modal.Content><p>Please select a file</p></Modal.Content>
                    <Modal.Actions><Button color='red' onClick={this.props.closeErrorModal}>Ok</Button></Modal.Actions>
                </Modal>

            </div>
        )
    }
}

let mapStateToProps = state => ({
    global_music: state.global_music,
    currentSettings: state.settings,
    settings: {
      ...state.settings,
      services: LocalSettingsSelector({services: state.settings.services, serviceChanges: state.global_music.changes})
    },
    active_channel: state.channel.active_channel,
    state,
    file_list: state.file_list
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    audioPolicy,
    defaultPolicy,
    volume,
    currentlyEditedChannel,
    selectedMediaItem,
    setBackgroundMusic,
    closeErrorModal,
    loadFileData,
    changeSettings,
    openApplication,
    setSearchNext,
    libraryUpdate,
    clearChanges,
    ...SettingsActions
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(GlobalMusicApp)
