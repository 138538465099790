import React, {PureComponent} from 'react'
import {Modal, Button, Segment, Loader, Dimmer, List, Message} from 'semantic-ui-react'
import {fetchFromServer} from 'helpers/net_helpers'

import "./ExistingEventModal.css"

export default class ExistingEventModal extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {open: false, loading: false, eventsList: [], error: null}
  }

  handleOpen = () => {this.setState({open: true})}
  handleClose = () => {this.setState({open: false})}

  fetchExistingEvents = async () => {
    this.setState((state) => ({...state, open: true, loading: true, eventsList: [], error: null}))
    let res = await fetchFromServer("/v2/files/list/mnt/main/Events/Upload")
    if(!res.ok) {
      let err = await res.text()
      this.setState((state) => ({...state, error: err, loading: false}))
    } else {
      let eventsList = await res.json()
      let res_tsu = await fetchFromServer("/v2/events/identify", {
        method: "POST",
        body: eventsList.map((evt) => evt.name).join("\n")
      })
      if(res_tsu.ok) {
        let eventExistence = await res_tsu.json()
        eventsList = eventsList.map((evt) => ({
          ...evt,
          existence: eventExistence[evt.name]
        }))
      }
      this.setState((state) => ({...state, loading: false, eventsList}))
    }
  }

  addEvent = (evnt) => {
    this.props.createEvent({name: evnt})
    this.handleClose()
  }

  render() {
    let {open, loading, eventsList, error} = this.state
    let content = null

    if(error) {
      content = (<Message error>
        <Message.Header>Error: {error.code ? `Code ${error.code}` : "Unknown"}</Message.Header>
        <Message.Content>{error.message}</Message.Content>
      </Message>)
    } else {
      let listItems = eventsList.map((evt) => {
        let existColor
        let existTitle = "Could not get existence information for this event."
        if(evt.existence) {
          if(evt.existence.play) {
            existColor = "green"
            existTitle = `Assigned in Play Folder: ${evt.existence.play["original filename"] || "unknown"}`
          } else if (evt.existence.upload) {
            let uploadItems = Object.keys(evt.existence.upload).join(", ")
            existColor = "blue"
            existTitle = `Assigned in Upload Folder: ${uploadItems}`
          } else {
            existColor = "red"
            existTitle = "Nothing assigned"
          }
        }
        return (<List.Item key={evt.name} onClick={() => {this.addEvent(evt.name)}}>
          <List.Icon name="calendar" color={existColor} title={existTitle}/>
          <List.Content>
            <List.Header>
              {evt.name}
            </List.Header>
          </List.Content>
        </List.Item>)
      })
      content = (<List selection size="large">
        {listItems}
      </List>)
    }
    return (
      <Modal trigger={<Button content='Add Existing Event' primary onClick={this.handleOpen}/>}
        open={open}
        onOpen={this.fetchExistingEvents}
        onClose={this.handleClose}>
        <Modal.Header>
          Select Event to Add
        </Modal.Header>
        <Modal.Content>
          <Segment id="ExistingEventModalContents">
            <Dimmer active={loading}>
              <Loader/>
            </Dimmer>

            {content}
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button icon="close" content="Cancel" onClick={this.handleClose}/>
        </Modal.Actions>
      </Modal>
    )
  }

}
