import React, {PureComponent} from 'react'

import "./TimeInput.css"

const timeInputRegex = /(\d{1,2})?(?::(\d{1,2}))?(?::(\d{1,2}))?(?:\.(\d{1,3}))?(?: ([ap]m))?/i

function timeStringToState(timeString) {
  let timeInput = timeInputRegex.exec(timeString)
  if(!timeInput) {
    return {
      hours: '',
      minutes: '',
      seconds: '',
      milliseconds: '',
      period: ''
    }
  }
  let [hours, minutes, seconds, milliseconds, period] = timeInput.slice(1).map((val) => val || "")
  if(period) {
    period = period.toLowerCase()
  }
  if(minutes) {
    minutes = minutes.padStart(2, "0")
  }
  if(seconds) {
    seconds = seconds.padStart(2, "0")
  }
  return {
    hours,
    minutes,
    seconds,
    milliseconds,
    period
  }
}

function stateToTimeString(state, showMilliseconds) {
  let {hours, minutes, seconds, milliseconds, period} = state
  if(showMilliseconds) {
    return `${hours}:${minutes}:${seconds}.${milliseconds} ${period}`
  } else {
    return `${hours}:${minutes}:${seconds} ${period}`
  }
}

export default class TimeInput extends PureComponent {

  constructor(props) {
    super(props)
    this.hourInput = React.createRef()
    if(props.value && typeof props.value === "string") {
      this.state = timeStringToState(props.value)
    } else if(props.defaultValue && typeof props.defaultValue === "string") {
      this.state = timeStringToState(props.defaultValue)
    } else {
      this.state = {
        hours: '',
        minutes: '',
        seconds: '',
        milliseconds: '',
        period: ''
      }
    }
    this.focus = this.focus.bind(this)
  }

  componentDidUpdate = (prevprops, prevstate) => {
    let {onChange, value, showMilliseconds} = this.props
    // State changed, and does not match value any longer
    if(onChange &&
      prevstate !== this.state &&
      value !== stateToTimeString(this.state, showMilliseconds)) {
      onChange(stateToTimeString(this.state, showMilliseconds))
    // Synchronize value and state
    } else if(prevprops.value !== value &&
      value !== stateToTimeString(this.state, showMilliseconds)) {
      this.setState(timeStringToState(value))
    }
  }

  focus() {
    if(this.hourInput.current) {
      this.hourInput.current.focus()
    }
  }

  handleChange = (e) => {
    let {name, value} = e.target
    if(value) {
      value = value.match(/\d+/)
    }
    if(!value) {
      value = ''
    } else {
      value = value[0]
    }
    switch(name) {
      case "hours":
        this.handleChangeHours(e, value);
        break;
      case "minutes":
      case "seconds":
        this.handleChangeMinutesSeconds(e, value);
        break;
      case "milliseconds":
        this.handleChangeMilliseconds(e, value);
        break;
      default:
        break;
    }
  }

  handlePeriodChange = (e) => {
    this.setState({"period": e.target.value})
  }

  handlePeriodKeyDown = (e) => {
    if(e.key === "a" || e.key === "A") {
      this.setState({"period": "am"})
    } else if (e.key === "p" || e.key === "P") {
      this.setState({"period": "pm"})
    }
  }

  handleChangeHours = (e, value) => {
    let {name} = e.target
    let originalValue = value
    if(value) {
      value = parseInt(value, 10)
      value = Math.max(Math.min(value, 12), 0)
    }
    this.setState({[name]: `${value}`})
    if(value > 1 || originalValue.length > 1) {
      this.goToNextInput(e)
    }
  }

  handleChangeMinutesSeconds = (e, value) => {
    let {name} = e.target
    if(value.length > 2 && value.startsWith("00")) {
      value = value.substring(2)
    }
    let originalValue = value
    if(value) {
      value = parseInt(value, 10)
      value = Math.max(Math.min(value, 59), 0)
    }
    this.setState({[name]: `${value}`})
    if(value > 5 || originalValue.length > 1) {
      this.goToNextInput(e)
    }
  }

  handleChangeMilliseconds = (e, value) => {
    let {name} = e.target
    let originalValue = value
    if(value) {
      value = parseInt(value, 10)
      value = Math.max(Math.min(value, 999), 0)
    }
    this.setState({[name]: `${value}`.padStart(Math.min(originalValue.length, 3), "0")})
    if(value > 99 || originalValue.length > 2) {
      this.goToNextInput(e)
    }
  }

  handleFocus = (e) => {
    if(e.target.value !== "") {
      e.target.setSelectionRange(0, e.target.value.length)
    }
  }

  handleBlur = (e) => {
    let {name, value} = e.target
    if(value) {
      value = parseInt(value, 10)
    }
    let strValue = `${value}`
    if(name === "minutes" || name === "seconds") {
      strValue = strValue.padStart(2, "0")
    } else if (name === "milliseconds") {
      strValue = strValue.padStart(3, "0")
    } else if (name === "hours" && (!value || value === "0" || value === "00")) {
      strValue = "12"
    }
    this.setState({[name]: strValue})
  }

  handleKeyDown = (e) => {
    if(e.key === "Enter" ||
      ((e.key === ":" || e.key === ".") && e.currentTarget.value)) {
      e.preventDefault()
      e.stopPropagation()
      this.goToNextInput(e)
    }
    if(e.key === "a" || e.key === "p") {
      e.preventDefault()
      e.stopPropagation()
      // Go directly to select element
      this.skipToSelect(e)
    }
  }

  skipToSelect = (e) => {
    for(let child of e.target.parentElement.children) {
      if(child.tagName.toLowerCase() === "input") {
        this.handleBlur({target: child})
      } else if(child.tagName.toLowerCase() === "select") {
        child.focus()
      }
    }
    // Set period based on triggering key value
    if(e.key === "a" || e.key === "A") {
      this.setState({"period": "am"})
    } else if (e.key === "p" || e.key === "P") {
      this.setState({"period": "pm"})
    }
  }

  goToNextInput = (e) => {
    let next = e.target.nextElementSibling
    // Skip seperators
    if(next.tagName.toLowerCase() === "span") {
      next = next.nextElementSibling
    }
    if(!next) {
      return
    }
    if(next.tagName.toLowerCase() === "input" ||
      next.tagName.toLowerCase() === "select") {
      next.focus()
    }
  }

  render() {
    let {
      hours,
      minutes,
      seconds,
      milliseconds,
      period
    } = this.state
    let {showMilliseconds, inline} = this.props

    let millisecondsInput = ''
    if(showMilliseconds) {
      millisecondsInput = (
        <>
          <span className="unitDivider">.</span>
          <input
            className="millisecondsInput"
            placeholder="---"
            autoComplete="off"
            value={milliseconds}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown}
            name="milliseconds"
            size={3}
            maxLength={3}/>
        </>
      )
    }

    let cls = inline ? " inline" : ""

    return (
      <div className={"timeInput" + cls}>
        <input className="hoursInput"
          ref={this.hourInput}
          placeholder="--"
          name="hours"
          autoComplete="off"
          value={hours}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          size={2}
          maxLength={2}/>
        <span className="unitDivider">:</span>
        <input
          className="minutesInput"
          placeholder="--"
          autoComplete="off"
          value={minutes}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          name="minutes"
          size={3}
          maxLength={3}/>
        <span className="unitDivider">:</span>
        <input
          className="secondsInput"
          placeholder="--"
          autoComplete="off"
          value={seconds}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onKeyDown={this.handleKeyDown}
          name="seconds"
          size={3}
          maxLength={3}/>
        {millisecondsInput}
        <select className="periodInput"
          required
          value={period}
          onChange={this.handlePeriodChange}
          onKeyDown={this.handlePeriodKeyDown}>
          <option value='' disabled>--</option>
          <option value='am'>AM</option>
          <option value='pm'>PM</option>
        </select>
        <span className="spacer"/>
      </div>
    )
  }

}
