import React, {Component} from 'react'
import {bindActionCreators} from 'redux'
import {Tab, Icon, Menu} from 'semantic-ui-react'
import {messages} from 'redux/messages'

import './tabbedComponent.css'

/**
 * Takes a given component/container and renders a tabbed window that contains
 * one or more instances of the given component/container as tabs.
 * Props:
 *   _initialize {function} A function that takes the props, and is called on construction. Optional.
 *   _tabs {array} An array of ids of tabs to render. Required.
 *   _tabData {object} Data for each tab, with key tab id and value an object containing that tab's info
 *   _tabData[n].display {string/function} What to use as a display name for the given tab. If a string is passed,
 *      then that string will be used. If a function is passed, then it will be passed _tabs[n].props as an argument, and its
 *      return value will be used as the display.
 *   _tabData[n].props {object} The props to pass down to the specific tab's inner component
 *   _activeTab {number} The currently active tab.
 *   _noNewTabButton {boolean} If true, don't render the new tab button
 *   _changeTab {function} A function for changing the active tab. Will be passed the index of the tab being changed to as an integer.
 *      Both _activeTab and _changeTab must be passed for the active tab to be controlled. If either is not passed, then
 *      the active tab will be handled internally
 *   _createTab {function} A function for creating a new tab at the end of the current tab list.
 *   _deleteTab {function} A function for deleting a given tab. Will be passed the index of the tab to be deleted as an integer.
 *      If not passed, then close tab buttons will not be rendered for each tab
 *   _dispatch {function} If using _tabActions, store dispatch must be passed in as this _dispatch prop
 * Any other props will be passed to the inner components of every tab. These props may be overriden by props in tabs[n].props.
 */
export default (WrappedComponent) => {

  return class tabbedComponent extends Component {

    constructor(props) {
      super(props)
      this.state = {
        controlled: (typeof props._activeTab === 'number' &&
          typeof props._changeTab === 'function')
      }
      if(props._initialize) {
        props._initialize(props)
      }
      if(props.location.search.includes("new=1") && props._createTab && props._tabs.length) {
        props._createTab()
      }
    }

    componentDidUpdate(prevProps) {
      if(this.props._tabs.length > prevProps._tabs.length) {
        let {_tabs, _tabData, _dispatch} = this.props
        _tabs.forEach((id) => {
          if(_tabData[id].tabCreated === false) {
            _dispatch(_tabData[id].onCreate())
          }
        })
      }
    }

    handleTabChange = (e, data) => {
      let {controlled} = this.state
      let {_changeTab, _createTab} = this.props
      if(_createTab && data.panes[data.activeIndex].add) {
        e.preventDefault();
        _createTab();
      } else if(controlled) {
        _changeTab(data.activeIndex);
      }
    }

    // Find the first tab for which comparisonFunc returns a truthy value and change to it.
    // If it doesn't exist, then create a new tab with createArgs used as the _createTab args.
    openTab = (comparisonFunc, createArgs=[]) => {
      let {_tabs, _tabData, _changeTab, _createTab} = this.props
      let tabId = Object.entries(_tabData).find(comparisonFunc);
      if(tabId instanceof Array) {
        tabId = tabId[0]
      }
      let tabInd = _tabs.findIndex((tab) => tab === tabId)
      if(tabInd > -1) {
        _changeTab(tabInd);
      } else {
        _createTab(...createArgs);
      }
      return
    }

    closeTab = (tab, tabInd) => {
      let {_dispatch, _deleteTab} = this.props
      if(tab.isUnsaved && tab.isUnsaved(tab.props)) {
        _dispatch(messages.confirm("This tab contains unsaved changes! If you close it, you will lose these unsaved changes. Are you sure you want to close it?",
          (res) => {
            if(res) {
              _deleteTab(tabInd);
            }
          }))
      } else {
        _deleteTab(tabInd);
      }
    }

    render() {
      let {_tabs, _tabData, _activeTab, _changeTab, _createTab, _deleteTab, _dispatch, _noNewTabButton, ...globalProps} = this.props
      if(globalProps._initialize) {
        delete globalProps._initialize
      }
      let {controlled} = this.state
      if(!_tabs || !(_tabs instanceof Array)) {
        console.error("_tabs prop for tabbedComponent must be an array, but it was not! Wrapped component was " + WrappedComponent)
        return <div/>
      }
      let tabProps = {
        className: 'tabbedComponentContainer'
      }
      tabProps.panes = _tabs.map((tab, tabInd) => {
        if(typeof tab !== 'object' && typeof _tabData === 'object') {
          tab = _tabData[tab]
        }
        let unsaved = (tab.isUnsaved && tab.isUnsaved(tab.props)) ?
          (<Icon.Group title='Has Unsaved Changes' className='tabbedMenuItemUnsaved'>
            <Icon name='save'/>
            <Icon corner name='exclamation triangle' color='red'/>
          </Icon.Group>) :
          null
        return {
          key: tabInd,
          menuItem: <Menu.Item key={tabInd} active={_activeTab === tabInd} className='tabbedMenuItem'>
            {typeof _deleteTab === 'function' ?
            <Icon name='remove' className='tabbedCloseButton' onClick={(e) => {e.stopPropagation(); this.closeTab(tab, tabInd)}}/> :
            null}
            {typeof tab.display === 'function' ?
            tab.display(tab.props) :
            tab.display}
            {unsaved}
          </Menu.Item>,
          render: () => (
            <Tab.Pane className='tabbedComponentPane' key={tabInd}>
              <WrappedComponent {...globalProps}
                {...tab.props}
                {...(bindActionCreators(tab.actions, _dispatch))}
                openTab={this.openTab}
                global={globalProps}
                index={tabInd}
                unsaved={unsaved}/>
            </Tab.Pane>
          )
        }
      })
      if(!(_noNewTabButton) && typeof _createTab === 'function') {
        tabProps.panes.push({
          key: tabProps.panes.length,
          add: true,
          menuItem: <Menu.Item key='add' color='green' active={false}><Icon name='plus'/></Menu.Item>,
          pane: ''
        })
      }
      tabProps.onTabChange = this.handleTabChange
      if(controlled) {
        tabProps.activeIndex = _activeTab
      }
      return (
        <Tab {...tabProps}/>
      )
    }

  }

}
