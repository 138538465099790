import React, { Component } from 'react';
import { Dropdown, Input, Segment } from 'semantic-ui-react'

import './TextGrid.css';

class TextGridApp extends Component {

    render () {

        let { properties, fonts, actions } = this.props;

        const justifyProperties = [
            { text: 'left', value: 'left', },
            { text: 'right', value: 'right', },
            { text: 'center', value: 'center', },
        ];

        const spaceProperties = [
            { text: '0%', value: 0 },
            { text: '50%', value: 0.5, },
            { text: '100%', value: 1, },
            { text: '150%', value: 1.5, },
        ];

        const scrollSpeedOption = [
            { text: 'Static, Not Moving', value: 0 },
            { text: 'Slower', value: 0.2 },
            { text: 'Slow', value: 0.5 },
            { text: 'Normal', value: 1 },
            { text: 'Fast', value: 1.5 },
            { text: 'Faster', value: 2 },
            { text: '3x Faster', value: 3 },
        ];

        const smoothScrollOptions = [
            { text: 'Smooth Scroll', value: 1},
            { text: 'Accurate Scroll', value: 0}
        ];

        const colorOptions = [
            { text: 'White', value: '#ffffff' },
            { text: 'Red', value: '#ff0000' },
            { text: 'Orange', value: '#ffa500' },
            { text: 'Yellow', value: '#ffff00' },
            { text: 'Green', value: '#008000' },
            { text: 'Cyan', value: '#00ffff' },
            { text: 'Blue', value: '#0000ff' },
            { text: 'Magenta', value: '#ff00ff' },
            { text: 'Light Gray', value: '#d3d3d3' },
            { text: 'Gray', value: '#808080' },
            { text: 'Dark Gray', value: '#a9a9a9' },
            { text: 'Black', value: '#000000' },
            { text: 'Maroon', value: '#800000' },
            { text: 'Brown', value: '#a52a2a' },
            { text: 'Dark Yellow', value: '#cccc00' },
            { text: 'Dark Green', value: '#006400' },
            { text: 'Dark Cyan', value: '#008b8b' },
            { text: 'Dark Blue', value: '#00008b' },
            { text: 'Dark Purple', value: '#800080' },
        ];

        const opacityOptions = [
            { text: '0%', value: 0 },
            { text: '25%', value: 0.25, },
            { text: '50%', value: 0.50, },
            { text: '75%', value: 0.75, },
            { text: '100%', value: 1, },
        ];

        const dropShadowBlurOptions = [
            { text: '0%', value: 0, },
            { text: '0.5%', value: 0.50, },
            { text: '1%', value: 1, },
            { text: '2%', value: 2, },
            { text: '3%', value: 3, },
            { text: '4%', value: 4, },
            { text: '5%', value: 5, },
            { text: '6%', value: 6, },
            { text: '7%', value: 7, },
            { text: '8%', value: 8, },
            { text: '9%', value: 9, },
            { text: '10%', value: 10, },
        ];

        const shadowXOffsetOptions = [
            { text: '1', value: 1, },
            { text: '2', value: 2, },
            { text: '3', value: 3, },
            { text: '4', value: 4, },
            { text: '5', value: 5, },
        ];

        const shadowYOffsetOptions = [
            { text: '1', value: 1, },
            { text: '2', value: 2, },
            { text: '3', value: 3, },
            { text: '4', value: 4, },
            { text: '5', value: 5, },
        ];

        let fontOptions = []
        if(fonts) {
          fontOptions = Object.keys(fonts).map((font) => ({text: font, value: font}))
        }

        let fontStyleOptions = []
        if(properties.fontFamily &&
          fonts[properties.fontFamily]) {
          fontStyleOptions = fonts[properties.fontFamily].map((style) => ({text: style, value: style}))
        }

        const emojiSetOptions = [
            { text: "Default", value: '' },
            { text: "Monochrome", value: "monochrome" },
            { text: "Color", value: "color" }
        ]

        const fontScaleOptions = [
            {text: "Auto-Fit", value: 0},
            {text: "100%", value: 1},
            {text: "98%", value: 0.98},
            {text: "95%", value: 0.95},
            {text: "92%", value: 0.92},
            {text: "90%", value: 0.90},
            {text: "85%", value: 0.85},
            {text: "80%", value: 0.80},
            {text: "75%", value: 0.75},
            {text: "70%", value: 0.70},
        ];
        const multiLineOptions = [
            {text: "Auto", value: 0},
            {text: "1 Line", value: 1},
            {text: "2 Lines", value: 2},
            {text: "3 Lines", value: 3},
            {text: "4 Lines", value: 4},
            {text: "5 Lines", value: 5},
        ];

        return (
            <div className='textGridContainer'>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Justify</Segment>
                    <Segment className="textGrid--segment-right justify" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.justify}
                            onChange= {(e, data) =>  actions.justify(data.value) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={justifyProperties}
                            onChange= {(e, data) =>  actions.justify(data.value) }
                            value={properties.justify}
                            className="scrollinText--dropdown"
                        />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Space</Segment>
                    <Segment className="textGrid--segment-right space" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.space}
                            type="number"
                            onChange= {(e, data) => actions.space(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={spaceProperties}
                            onChange= {(e, data) => actions.space(data.value) }
                            value={properties.space}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Scroll Speed</Segment>
                    <Segment className="textGrid--segment-right scrollSpeed" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.scrollSpeed}
                            type="number"
                            onChange= {(e, data) => actions.scrollSpeed(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={scrollSpeedOption}
                            onChange= {(e, data) => actions.scrollSpeed(data.value) }
                            value={properties.scrollSpeed}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Smooth Scroll</Segment>
                    <Segment className="textGrid--segment-right scrollSpeed" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.smoothScroll}
                            type="number"
                            onChange= {(e, data) => actions.smoothScroll(parseInt(data.value, 10)) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={smoothScrollOptions}
                            onChange= {(e, data) => actions.smoothScroll(data.value) }
                            value={properties.smoothScroll}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Font Color</Segment>
                    <Segment className="textGrid--segment-right fontColor" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.fontColor}
                            onChange= {(e, data) => actions.fontColor(data.value) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={colorOptions}
                            onChange= {(e, data) => actions.fontColor(data.value) }
                            value={properties.fontColor}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Font Opacity</Segment>
                    <Segment className="textGrid--segment-right fontOpacity" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.fontOpacity}
                            type="number"
                            onChange= {(e, data) => actions.fontOpacity(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={opacityOptions}
                            onChange= {(e, data) => actions.fontOpacity(data.value) }
                            value={properties.fontOpacity}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Background Color</Segment>
                    <Segment className="textGrid--segment-right backgroundColor" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.backgroundColor}
                            onChange= {(e, data) => actions.backgroundColor(data.value) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={colorOptions}
                            onChange= {(e, data) => actions.backgroundColor(data.value) }
                            value={properties.backgroundColor}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Background Opacity</Segment>
                    <Segment className="textGrid--segment-right backgroundOpacity" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.backgroundOpacity}
                            type="number"
                            onChange= {(e, data) => actions.backgroundOpacity(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={opacityOptions}
                            onChange= {(e, data) => actions.backgroundOpacity(data.value) }
                            value={properties.backgroundOpacity}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Drop Shadow Color</Segment>
                    <Segment className="textGrid--segment-right dropShadowColor" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.shadowColor}
                            onChange= {(e, data) => actions.shadowColor(data.value) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={colorOptions}
                            onChange= {(e, data) => actions.shadowColor(data.value) }
                            value={properties.shadowColor}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Drop Shadow Opacity</Segment>
                    <Segment className="textGrid--segment-right dropShadowOpacity" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.shadowOpacity}
                            type="number"
                            onChange= {(e, data) => actions.shadowOpacity(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={opacityOptions}
                            onChange= {(e, data) => actions.shadowOpacity(data.value) }
                            value={properties.shadowOpacity}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Drop Shadow Blur</Segment>
                    <Segment className="textGrid--segment-right dropShadowBlur" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.shadowBlur}
                            type="number"
                            onChange= {(e, data) => actions.shadowBlur(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={dropShadowBlurOptions}
                            onChange= {(e, data) => actions.shadowBlur(data.value) }
                            value={properties.shadowBlur}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Shadow X Offset</Segment>
                    <Segment className="textGrid--segment-right shadowXOffset" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.shadowX}
                            type="number"
                            onChange= {(e, data) => actions.shadowX(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={shadowXOffsetOptions}
                            onChange= {(e, data) => actions.shadowX(data.value) }
                            value={properties.shadowX}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Shadow Y Offset</Segment>
                    <Segment className="textGrid--segment-right shadowYOffset" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.shadowY}
                            type="number"
                            onChange= {(e, data) => actions.shadowY(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating
                            options={shadowYOffsetOptions}
                            onChange= {(e, data) => actions.shadowY(data.value) }
                            value={properties.shadowY}
                            className="scrollinText--dropdown"
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Font Family</Segment>
                    <Segment className="textGrid--segment-right" textAlign='right'>
                        <Dropdown
                            className="textGrid--w100"
                            basic selection scrolling floating upward
                            options={fontOptions}
                            onChange= {(e, data) => actions.fontFamily(data.value) }
                            value={properties.fontFamily}
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Font Style</Segment>
                    <Segment className="textGrid--segment-right" textAlign='right'>
                        <Dropdown
                            className="textGrid--w100"
                            basic selection scrolling floating upward
                            options={fontStyleOptions}
                            onChange= {(e, data) => actions.fontStyle(data.value) }
                            value={properties.fontStyle}
                            />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Font Scale</Segment>
                    <Segment className="textGrid--segment-right" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.fontScale}
                            type="number"
                            onChange= {(e, data) => actions.fontScale(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating upward
                            options={fontScaleOptions}
                            onChange= {(e, data) => actions.fontScale(data.value) }
                            value={properties.fontScale}
                            className="scrollinText--dropdown"
                        />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Multi Line</Segment>
                    <Segment className="textGrid--segment-right" textAlign='right'>
                        <Input
                            inline="true"
                            className="scrollinText--input"
                            value={properties.multiLine}
                            type="number"
                            onChange= {(e, data) => actions.multiLine(parseFloat(data.value)) }
                        />
                        <Dropdown
                            basic selection scrolling floating upward
                            options={multiLineOptions}
                            onChange= {(e, data) => actions.multiLine(data.value) }
                            value={properties.multiLine}
                            className="scrollinText--dropdown"
                        />
                    </Segment>
                </Segment.Group>
                <Segment.Group horizontal className="textGrid--segments">
                    <Segment className="textGrid--segment-left noselect">Emoji Set</Segment>
                    <Segment className="textGrid--segment-right" textAlign='right'>
                        <Dropdown
                            className="textGrid--w100"
                            basic selection scrolling floating upward
                            options={emojiSetOptions}
                            onChange= {(e, data) => actions.emoji(data.value) }
                            value={properties.emoji}
                            defaultValue=''
                            />
                    </Segment>
                </Segment.Group>
            </div>
        );
    }
}

export default TextGridApp
