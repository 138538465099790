
import React, { Component } from 'react'
import { connect } from 'react-redux'
import store from 'store'
import { Menu } from 'semantic-ui-react'
import { refresh } from 'redux/alerts'
import { openApplication } from 'redux/menu'
import { bindActionCreators } from 'redux'

class AlertPage extends Component {
    items = []

    // Open library with item selected
    redirect = (path) => {
        console.log(`Redirecting to ${path}`)
        this.props.openApplication('Library', path)
    }

    buildList = (items) => {
        let array = []

        for (const item of items) {
            array.push(<Menu onClick={() => this.redirect(item.path)} style={
                {
                    flexDirection: 'row',
                    margin: '0'
                }
            }>
                <Menu.Item style={{flex: 1}} >{item.text}</Menu.Item>
                <Menu.Item style={{flex: 1}} >{item.path}</Menu.Item>
                <Menu.Item style={{flex: 1}} >{item.action}</Menu.Item>
                <Menu.Item style={{flex: 1}} >{item.time}</Menu.Item>
                </Menu>)
        }

        return array
    }

    refresh = async () => {
        await this.props.refresh() // Refresh data
        this.props.openApplication('Alerts') // Refresh UI
    }

    render() {
        // Grab state from reducer
        let state = store.getState().alerts

        // Build array of items to list
        let items = this.buildList(state.alertQueue)

        // Build page
        return (
            <div id="alertsPage">
                <Menu>
                    <Menu.Item
                        name='refresh'
                        onClick={this.refresh}
                    >
                        Refresh
                    </Menu.Item>
                </Menu>

                <Menu style={
                    {
                        flexDirection: 'row',
                        margin: '0'
                    }
                }>
                    <Menu.Item style={{flex: 1, fontWeight: 'bold'}}>Name</Menu.Item>
                    <Menu.Item style={{flex: 1, fontWeight: 'bold'}}>Location</Menu.Item>
                    <Menu.Item style={{flex: 1, fontWeight: 'bold'}}>Type</Menu.Item>
                    <Menu.Item style={{flex: 1, fontWeight: 'bold'}}>Time</Menu.Item>
                </Menu>

                {items}

            </div>
        )
    }
}

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => bindActionCreators({
    refresh,
    openApplication
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AlertPage)
