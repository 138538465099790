import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {actions, changeTab, createTab, deleteTab} from 'redux/applications/template_editor'
import {loadFileData} from 'redux/file_list'
import {openApplication} from 'redux/menu'
import {messages} from 'redux/messages'
import {mergeMetadata, capitalizeMetadata} from 'helpers/metadata_helpers'
import {Table, Button, Dropdown, Header, Divider} from 'semantic-ui-react'
import MetadataEntry from 'components/Metadata/MetadataEntry'

import tabbedComponent from 'components/higher_order_components/tabbedComponent'
import loadingIndicator from 'components/higher_order_components/Loader/LoadingIndicator'

import './MetadataEditor.css'

class TemplateEditor extends Component {

  constructor(props) {
    super(props)
    let {filepath, filename} = props
    let fullpath = [...filepath, filename]
    // Open file based on url path, if there is no url path change the url path to match the currently opened file
    if(filename) {
      props.loadMetadataTemplate(fullpath)
      props.openApplication('MetadataTemplate', `/${fullpath.join('/')}`)
    }
  }

  componentDidUpdate(prevprops) {
    let {filepath} = this.props
    if(prevprops.filepath.join('/') !== filepath.join('/')) {
      this.props.loadFileData(filepath)
    }
  }

  addMetadata = (tag='') => {
    let {changeTemplateData} = this.props
    if(tag) {
      changeTemplateData(tag, '')
    } else {
      this.props.prompt('Add which metadata tag?', (tag) => {
        if(tag) {
          changeTemplateData(tag, '')
        }
      })
    }
  }

  render() {

    let {filepath, filename, swapData, templateData, timezone} = this.props
    let {metadataTags} = this.props.file_list
    let {changeTemplateData, saveMetadataTemplate, resetSwapTemplateData} = this.props

    let data = mergeMetadata(templateData, swapData)

    let MetadataItems = Object.entries(data).map(([name, value]) => {
      let tag = metadataTags[name]
      return (
        <MetadataEntry key={filepath + name}
          name={name}
          value={value}
          metadataTag={tag}
          onChange={changeTemplateData}
          timezone={timezone}/>
      )
    })

    let MetadataOptions = Object.entries(metadataTags).map(([key, value]) => {
      if(key === "guid") {
        return null
      }
      let tagDisplayName = capitalizeMetadata(value.display)
      let handleMetaClick = () => {this.addMetadata(key)}
      return <Dropdown.Item key={key} onClick={handleMetaClick}>{tagDisplayName}</Dropdown.Item>
    }).filter((item) => item !== null)

    let displayName = filename ?
      [...filepath, filename].join('/') :
      '(NEW METADATA TEMPLATE)'

    let handleSaveMetadata = () => {
      saveMetadataTemplate()
    }

    let handleRevert = () => {
      resetSwapTemplateData()
    }

    return (
      <div id='metadataEditorPane'>
        <Header as='h2' content='Metadata Template' icon='pencil' id='metadataHeader'/>
        <Divider fitted/>
        <Header as='h3' content={displayName} id='metadataFileDisplay'/>
        <div id='metadataButtonPane'>
          <Dropdown icon='plus' title='Add Metadata Field' scrolling button>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => this.addMetadata()}>Custom Tag...</Dropdown.Item>
              {MetadataOptions}
            </Dropdown.Menu>
          </Dropdown>
          <Button icon='save' title='Save Metadata' onClick={handleSaveMetadata}/>
          <Button icon='undo' title='Revert' onClick={handleRevert}/>
        </div>
        <Table columns='15' attached='top'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width='3'>Field</Table.HeaderCell>
              <Table.HeaderCell width='11' textAlign='center'>Value</Table.HeaderCell>
              <Table.HeaderCell width='1'/>
            </Table.Row>
          </Table.Header>
        </Table>
        <div id='metadataTablePane'>
          <Table attached='bottom' columns='15'>
            <Table.Body>
              {MetadataItems}
            </Table.Body>
          </Table>
        </div>
      </div>
    )
  }
}

const initialize = (props) => {
  if(props.match.params.path) {
    let {_tabs, _tabData} = props
    let path = decodeURIComponent(props.match.params.path)
    let tabId = Object.entries(_tabData).find(([key, value]) => {
      let fullPath = [...value.props.filepath, value.props.filename]
      return fullPath.join('/') === path
    })
    if(tabId instanceof Array) {
      tabId = tabId[0]
    }
    let tabInd = _tabs.findIndex((tab) => tab === tabId)
    if(tabInd > -1) {
      props._changeTab(tabInd);
    } else {
      props._createTab(path.split('/'));
    }
    return
  }
  if(props._tabs.length === 0) {
    props._createTab();
  }
}

let mapStateToProps = (state) => ({
  _tabs: state.template_editor.tabs,
  _tabData: state.template_editor.tabData,
  _activeTab: state.template_editor.activeTab,
  _initialize: initialize,
  _tabActions: actions,
  file_list: state.file_list,
  timezone: state.menu.timezone
})

let mapDispatchToProps = (dispatch) => {
  return {
    ...(bindActionCreators({
      loadFileData,
      openApplication,
      ...messages,
      _changeTab: changeTab,
      _createTab: createTab,
      _deleteTab: deleteTab
    }, dispatch)),
    _dispatch: dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(tabbedComponent(loadingIndicator(TemplateEditor)))
