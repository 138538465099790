import {SOURCE} from 'config'
import {setIn} from 'helpers/general_helpers'
import {uploadFile, checkFileTypes} from 'redux/file_list'
import {messages} from 'redux/messages'
import {fetchFromServer, fetchFileFromServer, connectToServerSocket} from 'helpers/net_helpers'
import {parseMetadataTemplate, mergeMetadata} from 'helpers/metadata_helpers'
import {fileTypeInfo} from 'helpers/library_helpers'

const DUMMY_TRANSFER_DATA = {
  1234: {
    type: 'upload',
    state: 0,
    to: ['Star', 'Oy'],
    progress: {
      percent: 53
    }
  },
  5678: {
    type: 'copy',
    state: 2,
    from: ['Sun'],
    to: ['Star'],
    progress: {
      percent: 100,
      size: {
        current: 10000000000,
        total: 10000000000
      }
    }
  }
}

export const ESTABLISH_UPLOADS_SOCKET = Symbol('establish uploads socket')
export const LOAD_FILE_TRANSFERS = Symbol('load file transfers');
export const LOAD_UPLOAD_TEMPLATE = Symbol('load upload template');
export const NEW_FILE_TRANSFER = Symbol('new file transfer');
export const CHANGE_FILE_TRANSFER_STATE = Symbol('change file transfer state');
export const DELETE_FILE_TRANSFER = Symbol('clear file transfer');
export const CHANGE_UPLOAD_DESTINATION = Symbol('change upload destination');
export const CHANGE_UPLOAD_METADATA_TEMPLATE = Symbol('change upload metadata template');
export const CHANGE_UPLOAD_METADATA = Symbol('change upload metadata');
export const REVERT_UPLOAD_METADATA = Symbol('revert upload metadata');
export const SET_ABORT_CONTROLLER = Symbol('set abort controller');

export const actions = {

  /**
   * Loads/Refreshes file transfer information from the server
   */
  loadFileTransfers: () => {
    return async (dispatch, getState) => {
      // TODO: Load transfer data from server here
      if(SOURCE === 'server') {
        if(getState().upload.serverSocket !== null) {
          return
        }
        var connection = connectToServerSocket('/uploads');
        dispatch({
          type: ESTABLISH_UPLOADS_SOCKET,
          payload: connection
        })
        connection.on("initialize", (data) => {
          dispatch({
            type: LOAD_FILE_TRANSFERS,
            payload: data
          })
        })
        connection.on("new_job", (data) => {
          dispatch(actions.newFileTransfer(data.id, data.job))
        })
        connection.on("update_job", (data) => {
          if(getState().upload.fileTransfers[data.id].updatedTime < data.time) {
            dispatch(actions.updateFileTransfer(data.id, data.job))
          }
        })
        connection.on("clear_job", (data) => {
          dispatch(actions.deleteFileTransfer(data))
        })
      } else {
        dispatch({
          type: LOAD_FILE_TRANSFERS,
          payload: DUMMY_TRANSFER_DATA
        })
      }
    }
  },

  startUpload: (source, destination, options={}) => {
    return async(dispatch, getState) => {
      let dest = destination.join('/')
      if(!dest.startsWith("/")) {
        dest = `/${dest}`
      }
      try {
        let types = await checkFileTypes([dest])
        if(types && types[dest]) {
          let {type} = fileTypeInfo(types[dest])
          // For now, just upload to the same directory as the file. Maybe support overwrite later?
          if(type !== "folder") {
            if(await dispatch(messages.confirmAsync(`Upload destination ${dest} is a file. Upload file to the containing directory ${dest.split("/").slice(0, -1).join("/")} instead?`))) {
              return dispatch(actions.startUpload(source, destination.slice(0, -1), options))
            } else {
              return dispatch(messages.alert(`Cannot replace ${dest} by uploading. If you wish to replace ${dest}, you must move/rename/delete the file ${dest} first, and then upload.`))
            }
          }
        }
      } catch (err) {
        console.error(err)
      }
      let form
      if(options.formData) {
        form = Object.entries(options.formData).map(([key, val]) => {
          if(typeof val === "object") {
            if(val instanceof Date) {
              // Datetime handling
              val = val.toLocaleString()
            } else {
              // select_multiple handling
              val = Object.entries(val).filter(([option, checked]) => (checked))
                .map(([option, checked]) => option)
                .join(", ")
            }
          }
          return `${key}=${val}`
        }).join("\n")
      }
      let body = {
        size: source.size,
        destination: dest,
        filename: source.name,
        form: form
      }
      let id = await fetchFromServer('/v2/uploads/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      if(!id.ok) {
        let err = await id.text()
        dispatch(messages.alert(`Error uploading file: ${err}`, {level: "error"}))
        console.error(`Error uploading file: ${err}`)
        return
      }
      id = await id.text()
      let uploadController = new AbortController();
      let signal = uploadController.signal
      dispatch(actions.setTransferAbort(id, uploadController));
      dispatch(uploadFile(source, dest, {id, abortSignal: signal}))
      let {metadata, templateData} = getState().upload
      dispatch(actions.populateMetadata([...destination, source.name], mergeMetadata(templateData, metadata)))
      dispatch({type: REVERT_UPLOAD_METADATA})
    }
  },

  populateMetadata: (path, metadata) => {
    return async(dispatch, getState) => {
      let saveData = JSON.stringify(metadata)
      let res = await fetchFromServer(`/v2/other/metadata-editor-save/${path.join('/')}`, {
        method: 'POST',
        body: saveData,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if(!res.ok) {
        dispatch(messages.alert("Error saving metadata!", {level: 'error'}))
      }
    }
  },

  setTransferAbort: (id, abort) => {
    return {
      type: SET_ABORT_CONTROLLER,
      payload: {
        id,
        abort
      }
    }
  },

  newFileTransfer: (id, job) => {
    return {
      type: NEW_FILE_TRANSFER,
      payload: {
        id,
        job
      }
    }
  },

  updateFileTransfer: (id, job) => {
    return {
      type: CHANGE_FILE_TRANSFER_STATE,
      payload: {
        key: [id],
        value: job
      }
    }
  },

  updateFileTransferProgress: (id, progress) => {
    return {
      type: CHANGE_FILE_TRANSFER_STATE,
      payload: {
        key: [id, 'progress'],
        value: progress
      }
    }
  },

  updateFileTransferState: (id, state) => {
    return {
      type: CHANGE_FILE_TRANSFER_STATE,
      payload: {
        key: [id, 'state'],
        value: state
      }
    }
  },

  deleteFileTransfer: (id) => {
    return {
      type: DELETE_FILE_TRANSFER,
      payload: id
    }
  },

  /**
   * Pauses an ongoing file transfer
   * @param id The identifier of the file transfer to pause
   */
  pauseFileTransfer: (id) => {
    return async (dispatch, getState) => {
      let {type} = getState().upload.fileTransfers[id]
      if(type === 'vod') {
        return fetchFromServer(`/v2/uploads/vod-ctl?command=pause`, {
          method: 'POST',
          body: id
        });
      } else {
        // Only transcodes can be paused/resumed
        return
      }
    }
  },

  /**
   * Resumes a paused file transfer
   * @param id The identifier of the file transfer to resume
   */
  resumeFileTransfer: (id) => {
    return async (dispatch, getState) => {
      let {type} = getState().upload.fileTransfers[id]
      if(type === 'vod') {
        return fetchFromServer(`/v2/uploads/vod-ctl?command=resume`, {
          method: 'POST',
          body: id
        });
      } else {
        // Only transcodes can be paused/resumed
        return
      }
    }
  },

  /**
   * Cancels an ongoing/paused file transfer
   * @param id The identifier of the file transfer to cancel
   */
  cancelFileTransfer: (id) => {
    return async (dispatch, getState) => {
      let {type} = getState().upload.fileTransfers[id]
      if(type === 'vod') {
        return fetchFromServer(`/v2/uploads/vod-ctl?command=cancel`, {
          method: 'POST',
          body: id
        });
      } else {
        if(getState().upload.abortControllers[id]) {
          getState().upload.abortControllers[id].abort();
        }
        return fetchFromServer(`/v2/uploads/stop?id=${id}`, {
          method: 'POST',
        });
      }
    }
  },

  /**
   * Clears a stopped file transfer from the list
   * @param id The identifier of the file transfer to clear
   */
  clearFileTransfer: (id) => {
    return async (dispatch, getState) => {
      let {type} = getState().upload.fileTransfers[id]
      if(type === 'vod') {
        return fetchFromServer(`/v2/uploads/vod-ctl?command=clear`, {
          method: 'POST',
          body: id
        });
      } else {
        return fetchFromServer(`/v2/uploads/clear?id=${id}`, {
          method: 'POST',
        });
      }
    }
  },

  /**
   * Restarts a stopped file transfer
   * @param id The identifier of the file transfer to restart
   */
  restartFileTransfer: (id) => {
    return async (dispatch, getState) => {
      let {type} = getState().upload.fileTransfers[id]
      if(type === 'vod') {
        return fetchFromServer(`/v2/uploads/vod-ctl?command=restart`, {
          method: 'POST',
          body: id
        });
      } else {
        // Only transcodes can be paused/resumed
        return
      }
    }
  },

  /**
   * Changes the upload destination
   * @param {string/array} destination The new destination as either a string or array (string will be converted to array)
   */
  changeDestination: (destination) => {
    if(typeof destination === 'string')
    destination = destination.split('/')
    return {
      type: CHANGE_UPLOAD_DESTINATION,
      payload: destination
    }
  },

  /**
   * Changes the path of the metadata template to be used for the upload,
   *  and asynchronously loads that template's data from the server
   * @param {array} path The path of the metadata template to use as an array
   */
  changeUploadMetadataTemplate: (path) => {
    return async (dispatch, getState) => {
      if(path.length === 0) {
        dispatch({
          type: LOAD_UPLOAD_TEMPLATE,
          payload: {}
        })
        dispatch({
          type: CHANGE_UPLOAD_METADATA_TEMPLATE,
          payload: []
        })
        return
      }
      let data = await fetchFileFromServer(path.join('/'))
      if(data.ok) {
        data = await data.text()
        try {
          data = parseMetadataTemplate(data)
        } catch (err) {
          if(err.type === 'NOT_METADATA_TEMPLATE') {
            console.error(`File ${path.join('/')} is not a metadata template.`)
            return
          } else {
            throw err
          }
        }
        dispatch({
          type: LOAD_UPLOAD_TEMPLATE,
          payload: data
        })
        dispatch({
          type: CHANGE_UPLOAD_METADATA_TEMPLATE,
          payload: path
        })
        dispatch({type: REVERT_UPLOAD_METADATA})
      } else {
        console.error("Error Loading Metadata Template")
      }
    }
  },

  /**
   * Changes the metadata to be added to uploaded files
   * @param {string} key The key of the metadata tag to change
   * @param {string} value The value to change the tag to
   */
  changeUploadMetadata: (key, value) => ({
    type: CHANGE_UPLOAD_METADATA,
    payload: {
      key,
      value
    }
  }),

  /**
   * Reverts all changed metadata for file uploads
   */
  revertUploadMetadata: () => {
    return (dispatch) => {
      dispatch(messages.confirm('Are you sure you want to revert all changes?', (result) => {
        if(result) {
          dispatch({type: REVERT_UPLOAD_METADATA})
        }
      }))
    }
  }

}

const initialState = {
  /**
   * fileTransfer objects have the following properties:
   * type: The type of file operation. Valid types are 'upload', 'copy', and 'transcode'
   * state: A number indicating success/failure state. Options are "success", "paused", "ongoing", and "error".
   * from: Optional. A path array indicating where the file is being transfered from.
   *  For file uploads, this will be undefined.
   * to: A path array indicating where the file is being transfered to.
   * progress: An object containing transfer progress information
   * progress.percent: A number indicating what percent of the transfer is complete
   * progress.size: Optional. An object containing information about the transfer progress in terms of file size
   * progress.size.current: A number indicating the number of bytes that have finished being transfered
   * progress.size.total: A number indicating the total size of the file being transfered in bytes
   */
  fileTransferList: [], // Array of file transfer ids
  fileTransfers: {},   // Object mapping file transfer ids to their respective information objects
  destination: ['', 'mnt', 'main', 'Incoming uploads'],   // Path array of upload destination
  template: [],        // Path array of currently selected metadata template
  templateData: {},    // Metadata provided by the selected metadata template
  metadata: {},        // Metadata for uploaded files, in addition to the metadata template
  serverSocket: null,  // Communication socket between the server and client for coordinating file uploads
  abortControllers: {} // Abort controllers for ongoing requests
}

export default (state=initialState, action) => {
  let {type, payload} = action
  switch(type) {
    case ESTABLISH_UPLOADS_SOCKET:
      return {
        ...state,
        serverSocket: payload
      }
    case LOAD_FILE_TRANSFERS:
      let transfers = Object.entries(payload).sort(([keya, vala], [keyb, valb]) => {
        return valb.startTime - vala.startTime
      }).map(([key, value]) => {
        return key
      })
      return {
        ...state,
        fileTransfers: payload,
        fileTransferList: transfers
      }
    case LOAD_UPLOAD_TEMPLATE:
      return {
        ...state,
        templateData: payload
      }
    case NEW_FILE_TRANSFER:
      return {
        ...state,
        fileTransferList: [payload.id, ...state.fileTransferList],
        fileTransfers: {...state.fileTransfers, [payload.id]: payload.job}
      }
    case SET_ABORT_CONTROLLER:
      return {
        ...state,
        abortControllers: {...state.abortControllers, [payload.id]: payload.abort}
      }
    case CHANGE_FILE_TRANSFER_STATE:
      return {
        ...state,
        fileTransfers: setIn(state.fileTransfers, payload.key, payload.value)
      }
    case DELETE_FILE_TRANSFER:
      return {
        ...state,
        fileTransferList: state.fileTransferList.filter((id, index) => id !== payload)
      }
    case CHANGE_UPLOAD_DESTINATION:
      return {
        ...state,
        destination: payload
      }
    case CHANGE_UPLOAD_METADATA_TEMPLATE:
      return {
        ...state,
        template: payload
      }
    case CHANGE_UPLOAD_METADATA: {
      let {key, value} = payload
      return {
        ...state,
        metadata: {
          ...state.metadata,
          [key]: value
        }
      }
    }
    case REVERT_UPLOAD_METADATA:
      return {
        ...state,
        metadata: {}
      }
    default:
      return state
  }
}
