import { createSelector } from 'reselect'
import {getIn} from 'helpers/general_helpers'
import applicationList from 'applicationList'

const getOpenApplications = (state) => getIn(state, ['menu', 'openApplications'])
const getReducers = (state) => state

export default createSelector([
  getOpenApplications,
  getReducers
], (openApplications, reducers) => {
  if(!openApplications || !reducers) {
    return {}
  }
  let tabCounts = {}
  openApplications.forEach((item) => {
    let app = applicationList[item]
    if('reducer' in app && reducers[app.reducer] && reducers[app.reducer].tabs) {
      tabCounts[item] = reducers[app.reducer].tabs.length
    } else {
      tabCounts[item] = -1
    }
  })
  return tabCounts
})
